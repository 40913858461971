import React, { useState } from 'react';
import { Container, Grid, Button, makeStyles, IconButton, Popover, List,
    ListItem, ListItemText, Divider } from '@material-ui/core';
import { useHistory } from 'react-router-dom'
import Polls from './Polls';
import './PollList.css';
import EligoSnackBar from '../../common/EligoSnackBar';
import { useTranslation } from 'react-i18next';
import { BrowserView, MobileView } from 'react-device-detect';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles((theme) => ({
    margin: {
        marginLeft: theme.spacing(1),
    },
    listhead: {
        fontSize: 24,
        color: '#2a83ad',
    },
}));


const PollList = (props) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedList, setSelectedList] = useState(
        (props.location.state != undefined && (props.location.state.pollType != null && props.location.state.pollType != undefined)) ? 
            props.location.state.pollType : 'open');
    const [loading, setLoading] = useState(false);
    const classes = useStyles();

    const opens = Boolean(anchorEl);
    const id = opens ? 'list-options' : undefined;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClosed = () => {
        setAnchorEl(null);
    }

    const listChangeHandler = (listType) => {
        handleClosed()
        setLoading(true);
        setSelectedList(listType);
        setInterval(() => {
            setLoading(false);
        }, 1000)
    }

    let history = useHistory();
    const loadSetup = () => {
        handleClosed()
        history.push({pathname: "/polls/poll-details/setup", state: {poll: null, pollType:selectedList}})
    }

    return (
        <div className="loading-container content">
            <Grid container className="poll-list">
                <Grid item xs={12}>
                    <Container maxWidth="lg">
                        <span style={{ float: "left" }}>
                            {selectedList == 'open' && <span className={classes.listhead}>{t('Polls_Open')}</span>}
                            {selectedList == 'closed' && <span className={classes.listhead}>{t('Polls_Closed')}</span>}
                            {selectedList == 'upcoming' && <span className={classes.listhead}>{t('Polls_Upcoming')}</span>}
                            {/* {selectedList == 'deleted' && <span className={classes.listhead}>{t('Polls_Deleted')}</span>} */}
                            {selectedList == 'canceled' && <span className={classes.listhead}>{t('Polls_Canceled')}</span>}
                            {/* {selectedList == 'new' && <span className={classes.listhead}>{t('Polls_In_Progress')}</span>} */}
                            {selectedList == 'scheduled' && <span className={classes.listhead}>{t('Polls_Scheduled')}</span>}
                        </span>
                        <BrowserView>
                            <span style={{ float: "right" }}>
                                {selectedList != 'open' &&
                                    <span>
                                        <span>|</span>
                                        <Button size="small" color="primary" onClick={() => listChangeHandler('open')}>{t('Open')}</Button>
                                    </span>
                                }
                                {/* {selectedList != 'upcoming' &&
                                    <span>
                                        <span>|</span>
                                        <Button size="small" color="primary" onClick={() => listChangeHandler('upcoming')}>Upcoming</Button>
                                    </span>
                                } */}
                                {/* {selectedList != 'new' &&
                                    <span>
                                        <span>|</span>
                                        <Button size="small" color="primary" onClick={() => listChangeHandler('new')}>{t('In_Progress')}</Button>
                                    </span>
                                } */}
                                {selectedList != 'scheduled' &&
                                    <span>
                                        <span>|</span>
                                        <Button size="small" color="primary" onClick={() => listChangeHandler('scheduled')}>{t('Scheduled')}</Button>
                                    </span>
                                }
                                {selectedList != 'closed' &&
                                    <span>
                                        <span>|</span>
                                        <Button size="small" color="primary" onClick={() => listChangeHandler('closed')}>{t('Closed')}</Button>
                                    </span>
                                }
                                {/* {selectedList != 'deleted' &&
                                <span>
                                    <span>|</span>
                                    <Button size="small" color="primary" onClick={() => listChangeHandler('deleted')}>{t('Deleted')}</Button>
                                </span>
                            } */}
                            {selectedList != 'canceled' &&
                                <span>
                                    <span>|</span>
                                    <Button size="small" color="primary" onClick={() => listChangeHandler('canceled')}>{t('Cancelled')}</Button>
                                </span>
                            }
                                |
                                <Button size="small" variant="contained" color="primary" className={classes.margin} onClick={() => loadSetup()}>
                                    {t('Setup_Poll')}
                                </Button>
                            </span>
                        </BrowserView>
                        <MobileView>
                            <IconButton aria-label="filter-polls" color="primary" className="float-right"
                                    onClick={handleClick}>
                                <MoreVertIcon />
                            </IconButton>
                            <Popover
                                id={id}
                                open={opens}
                                anchorEl={anchorEl}
                                onClose={handleClosed}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left'
                                }}>
                                <List component="nav" aria-label="poll types">
                                    {selectedList != 'open' && <ListItem button onClick={() => listChangeHandler('open')}>
                                        <ListItemText primary="Open" />
                                        </ListItem>
                                    }
                                    {selectedList != 'closed' && <ListItem button onClick={() => listChangeHandler('closed')}>
                                        <ListItemText primary="Closed" />
                                        </ListItem>
                                    }
                                    {/* {selectedList != 'new' && <ListItem button onClick={() => listChangeHandler('new')}>
                                        <ListItemText primary="In Progress" />
                                        </ListItem>
                                    } */}
                                    {selectedList != 'scheduled' && <ListItem button onClick={() => listChangeHandler('scheduled')}>
                                        <ListItemText primary="Scheduled" />
                                        </ListItem>
                                    }
                                    {selectedList != 'canceled' && <ListItem button onClick={() => listChangeHandler('canceled')}>
                                        <ListItemText primary="Canceled" />
                                        </ListItem>
                                    }
                                </List>
                                <Divider />
                                <List component="nav" aria-label="setup poll">
                                    <ListItem button color="primary" onClick={() => loadSetup()}>
                                        <ListItemText primary={t('Setup_Poll')} />
                                    </ListItem>
                                </List>
                            </Popover>
                        </MobileView>
                    </Container>
                </Grid>
                <Grid item xs={12}>
                    <Container maxWidth="lg">
                        <div>
                            {!loading ? <Polls pollType={selectedList} ></Polls> : null}
                        </div>
                    </Container>
                </Grid>
            </Grid>
            { props.location.state != null && props.location.state != undefined ? 
                <EligoSnackBar show={props.location.state.show} message={props.location.state.message}
                        severity={props.location.state.severity}></EligoSnackBar> : null
            }
        </div>
    )
}

export default PollList;