import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Divider, FormControl, FormHelperText, IconButton, Input, InputLabel, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import EligoSnackBar from './EligoSnackBar';
import WarningIcon from '@material-ui/icons/Warning';
import { Stack } from '@mui/system';

const useStyles = makeStyles((theme) => ({
    ramarksHelperText: {
        color: `${theme.palette.primary.main} !important`,
        textAlign: 'right !important'
    }
}))

const EligoConfirmDialog = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [open, setOpen] = useState(props.show);
    const [title] = useState(props.title);
    const [message] = useState(props.message);
    const [isCancel] = useState(props.isCancel != null ? props.isCancel : true)
    const [agreeBtnLabel] = useState(props.agreeBtnLabel != null ? props.agreeBtnLabel : 'Yes');
    const [disAgreeBtnLabel] = useState(props.disAgreeBtnLabel != null ? props.disAgreeBtnLabel : 'No');
    const [withConfirmText] = useState(props.withConfirmText ? props.withConfirmText : false);
    const [isConfirmTextMandatory] = useState(props.isConfirmTextMandatory ? props.isConfirmTextMandatory : false);
    const [maxConfirmTextLength] = useState(props.maxConfirmTextLength ? props.maxConfirmTextLength : 250);
    const [confirmText, setConfirmText] = useState('');
    const [confirmTextLabel] = useState(props.confirmTextLabel ? props.confirmTextLabel : 'Remarks');
    const [snackbarMsg, setSnackbarMsg] = useState({
        show: false,
        message: '',
        severity: ''
    })

    const handleDisAgree = () => {
        setOpen(false);
        props.onDisAgree();
    };

    const confirmTextAdd = (e) => {
        let value = e.target.value.trimStart();
        setConfirmText(value.replace(/\s+/g, ' '));
    }

    const handleAgree = () => {
        if (isConfirmTextMandatory && confirmText === '') {
            setSnackbarMsg({ show: true, message: `${confirmTextLabel} ${t('Is_Mandatory')}`, severity: 'error' });
        } else {
            setOpen(false);
            props.onAgree(confirmText);
        }
        setTimeout(() => {
            setSnackbarMsg({ show: false, message: '', severity: '' });
        }, 2500);
    };

    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
                PaperProps={{ style: { borderTop: props.ConfirmFor && props.showGreen ? '4px solid green' : '4px solid red' } }}
            >
                <DialogTitle id="dialog-title">{title}</DialogTitle>
                <DialogContent>
                    {!props.ConfirmFor && <DialogContentText id="dialog-description">{message}</DialogContentText>}
                    {props.ConfirmFor && <Stack direction='row'>
                        {/* <IconButton style={{ marginTop: '-23px' }}> */}
                        <WarningIcon style={{ fontSize: '50px', marginTop: '-10px', color: props.showGreen ? 'green' : 'red' }} />
                        {/* </IconButton> */}
                        <Typography style={{ margin: "0px 0px 0px 10px " }}>{message}</Typography>
                    </Stack>}
                    {withConfirmText &&
                        <FormControl
                            margin="dense"
                            autoFocus
                            fullWidth required={isConfirmTextMandatory}
                            variant="standard"
                        >
                            <InputLabel htmlFor="remarks-input">{t(confirmTextLabel)}</InputLabel>
                            <Input type="text" id="remarks-input" aria-describedby="remarks-input-text"
                                inputProps={{ maxLength: maxConfirmTextLength }} multiline
                                onChange={confirmTextAdd} value={confirmText} />
                            <FormHelperText id="reamrks-helper-text" className={classes.ramarksHelperText}>
                                {confirmText.length}/{maxConfirmTextLength}
                            </FormHelperText>
                        </FormControl>
                    }
                </DialogContent>
                {props.ConfirmFor && <Divider />}
                <DialogActions>
                    {isCancel && props.ConfirmFor && <Button onClick={handleDisAgree} variant='contained'>
                        {disAgreeBtnLabel}
                    </Button>}
                    {props.ConfirmFor && <Button onClick={handleAgree} style={{background: props.showGreen ? 'green' : 'red', color:'white'}} autoFocus variant='contained'>
                        {agreeBtnLabel}
                    </Button>}
                    {isCancel && !props.ConfirmFor && <Button onClick={handleDisAgree} color='primary'>
                        {disAgreeBtnLabel}
                    </Button>}
                    {!props.ConfirmFor && <Button onClick={handleAgree} color="primary" autoFocus >
                        {agreeBtnLabel}
                    </Button>}
                </DialogActions>
            </Dialog>
            {snackbarMsg.show && <EligoSnackBar show={snackbarMsg.show} message={snackbarMsg.message} severity={snackbarMsg.severity} />}
        </div>
    );
}

export default EligoConfirmDialog;
