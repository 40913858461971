import React, { Fragment, useState, useEffect } from 'react'
import { TableRow, TableCell, makeStyles, TableContainer, Table, TableHead, TableBody, FormControlLabel, IconButton, Grid} from '@material-ui/core'
import GenericCodeTableRow from './GenericCodeTableRow'
import EligoBackdrop from '../../common/EligoBackdrop';
import AddIcon from '@material-ui/icons/Add';
import AddGenericCode from './AddGenericCode';
import RefreshIcon from '@material-ui/icons/RefreshOutlined';

const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
    container: {
        maxHeight: 430,
    },
    tableHeader: {
        fontWeight: 'bold'
    }
  });

  const Btns = (props) => {
    const clickEvent = () => {
        props.click()
    }
    return <FormControlLabel
        aria-label={props.label} className='float-right'
        onClick={(event) => event.stopPropagation()}
        control={<IconButton aria-label="edit-code" color={props.color} onClick={clickEvent} disabled={props.disable}>
            {props.btn}
        </IconButton>}
    />
}
  
const GenericCodeTable = (props) => {
    let codeResource = {
        description: '',
        abbreviation: '',
        start_date_time: null,
        end_date_time: null,
        event_id: null
    }
    const classes = useRowStyles();
    const [rowsData, setRowsData] = useState([]);
    const [loading, setLoading] = useState(false)
    const [showAdd, setShowAdd] = useState(false);
    useEffect(() => {
        if(props.codeCds != null) {
            let sampleData = []
            props.codeCds.properties.map(n => {
                sampleData.push(n)
            })
            setRowsData(sampleData);
        }
    }, [props.codeCds])
    
    
    let columnData = [
        // { headerName: 'Code'},
        { headerName: 'Abbreviation'}, 
        { headerName: 'Description'}, 
        { headerName: 'Start Date Time'}, 
        { headerName: 'End Date Time'}, 
        { headerName: 'Actions'}
    ];

    const setLoadingData = (event) => {
        setLoading(event)
    }

    const hideAddBtn = () => {
        setShowAdd(false)
    }
    const showAddBtn = () => {
        setShowAdd(true)
    }

    const refreshMainList = () => {
        props.refresh();
    }

    return (
        <Fragment >
            <Grid container>
                {!showAdd ? <Grid item xs={12}><Btns btn={<><AddIcon /></>} click={showAddBtn} label="add" color="primary"/>
                    <Btns btn={<><RefreshIcon /></>} click={refreshMainList} label="refresh" color="primary"/></Grid> : 
                    <Grid item xs={12} style={{paddingBottom: '10px'}}> 
                        <AddGenericCode label={props.label}  close={hideAddBtn} codeResource={codeResource}/> 
                    </Grid> 
                }
                <Grid item xs={12} className="loading-container">
                    <TableContainer className={classes.container}>
                    <EligoBackdrop show={loading} invisible={false} />
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columnData.map((col, index)=> (
                                        <TableCell key={index} component="th" scope="row" className={classes.tableHeader}>
                                            {col.headerName}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rowsData.length == 0 ? 
                                    <TableRow> 
                                        <TableCell style={{ paddingBottom: 8, paddingTop: 8 }} colSpan={6}>
                                            No Records
                                        </TableCell>
                                    </TableRow> : null}
                                {rowsData.map((row, index) => (
                                    <GenericCodeTableRow key={index} row={row} label={props.label} loadBackdrop={(e) => setLoadingData(e) }/>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default GenericCodeTable
