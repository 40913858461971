import {
    Button, Dialog, DialogActions, DialogTitle, Grid, IconButton, InputAdornment, InputLabel, MenuItem,
    Paper, Select, TextField, makeStyles
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Card, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack } from '@mui/material';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EligoBackdrop from '../../../../common/EligoBackdrop';
import EligoSnackBar from '../../../../common/EligoSnackBar';
import FieldHelperText from '../../../../common/FieldHelperText';
import { APIEvotingAxiosDownloadInterceptor } from '../../../../config/axios.interceptor';

const useStyles = makeStyles(() => ({
    withVoterName: {
        borderRadius: '10px',
        padding: "0px 0px 0px 10px",
        border: "1px solid blue",
        background: '#f7f9fa'
    },
    withoutVoterName: {
        borderRadius: '10px',
        padding: "0px 0px 0px 10px",
    }
}))

const GenerateResult = ({ show, onClose, pollId, userId, pollDetails, setResultResponseWithPassword }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(show);
    const classes = useStyles();
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false)
    const [reportDetail, setReportDetail] = useState({
        reportType: 'PDF_A',
        password: '',
        digitalSign: false,
        watermarkText: '',
        withVoterName: false,
        basedOnNumberOfVotes: false
    })
    const [message, setMessage] = useState({
        showMsg: false,
        message: null,
        severity: null
    })

    const reportTypes = [
        { name: 'PDF_A', value: 'PDF_A', fileType: 'pdf' },
        { name: 'PDF_UA', value: 'PDF_UA', fileType: 'pdf' },
        // { name: 'EXCEL', value: 'EXCEL', fileType: 'xls' },
        // {name: 'DOCX', value: 'DOCX', fileType: 'docx'},
        // {name: 'DOC', value: 'DOC', fileType: 'doc'}
    ]

    const closeHandler = () => {
        setOpen(false);
        onClose(false);
    }

    const handleChange = (fieldName, event) => {
        setReportDetail(prevState => ({
            ...prevState,
            [fieldName]: event.target.value
        }))
    }

    const handleDataBoolean = (event, stateVariable) => {
        let id = null;
        if (event.target.id != "") {
            id = event.target.id;
        } else if (event.target.parentElement.id != "") {
            id = event.target.parentElement.id;
        } else if (event.target.parentElement.parentElement.id != "") {
            id = event.target.parentElement.parentElement.id;
        }
        if (id != null) {
            setReportDetail(prevState => ({
                ...prevState,
                [id]: !prevState[id]
            }))
        }
    }

    const toggleShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const handleBooleanChange = (field, event) => {
        setReportDetail(prevState => ({
            ...prevState,
            [field]: event.target.value === 'true' ? true : false
        }))
    };

    const generateResult = () => {
        setMessage({ showMsg: false, message: '', severity: '' });
        setLoading(true)
        let payload = {
            reportType: reportDetail.reportType,
            password: reportDetail.password,
            watermark: {
                text: reportDetail.watermarkText, opaque: 0,
                alignment: 360
            },
            appName: "VOTING",
            template: reportDetail.withVoterName === true ? "result" : "resultBrief",
            password: reportDetail.password,
            barCode: false,
            digitalSign: true,
            reportOf: pollId,
            applicationId: 1,
            presignedUrl: false,
            reportCd: reportDetail.withVoterName === true ? 52 : 53,
            versionId: null
        }
        APIEvotingAxiosDownloadInterceptor.post(`poll/result/${pollId}/generate-result-by/${reportDetail.basedOnNumberOfVotes}/expose-voter-name/${reportDetail.withVoterName}`,
            payload).then((response) => {
                setResultResponseWithPassword(response, reportDetail.password)
                const fileType = (reportTypes.find(report => report.value === reportDetail.reportType)).fileType;
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${pollDetails.title}.${fileType}`);
                document.body.appendChild(link);
                link.click();
                setLoading(false);
                closeHandler()
            }).catch((error) => {
                setLoading(false);
                if (error) {
                    setMessage({ showMsg: true, message: error.message, severity: 'error' });
                } else if (error.request) {
                    setMessage({ showMsg: true, message: 'Try Again', severity: 'error' });
                }
            })
    }

    return (
        <Dialog maxWidth='sm' open={open} aria-labelledby="generate-result-dialog" aria-describedby="dialog-generate-result-description"
            PaperProps={{
                style: {
                    minWidth: '50%'
                }
            }}
        >
            {/* <Wave fill='#f79902'
                paused={false}
                options={{
                    height: 30,
                    amplitude: 50,
                    speed: 0.15,
                    points: 7
                }}
            /> */}
            <DialogTitle style={{ borderBottom: '1px solid gray', background: '#d2f0fa', color: 'black' }}>{t('Generate_Result')}</DialogTitle>
            <Card style={{ margin: '10px', padding: '10px' }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        {reportDetail.withVoterName}
                        <FormControl>
                            <Stack direction='row' spacing={2}>
                                <FormLabel id="result-with-voter-name-label" style={{ marginBottom: '10px', fontWeight: 'bold', marginTop: "15px", color: 'black' }}>
                                    {t('Result_With_Voter_Name')}
                                </FormLabel>
                                <RadioGroup
                                    row
                                    id='radio'
                                    aria-labelledby="result-with-voter-name-label"
                                    name="result-based-with-voter-name-radio-buttons"
                                    value={reportDetail.withVoterName}
                                    onChange={(event) => handleBooleanChange('withVoterName', event)}
                                >
                                    <Paper className={reportDetail.withVoterName === true ? classes.withVoterName : classes.withoutVoterName}
                                        onChange={(event) => handleBooleanChange('withVoterName', event)}>
                                        <FormControlLabel id='with-name' value={true} control={<Radio />} label={t('Yes')} style={{ marginTop: '4px' }} />
                                    </Paper>&nbsp;&nbsp;
                                    <Paper className={reportDetail.withVoterName === false ? classes.withVoterName : classes.withoutVoterName}
                                        onChange={(event) => handleBooleanChange('withVoterName', event)}>
                                        <FormControlLabel id='without-name' value={false} control={<Radio style={{ marginBottom: '4px' }} />} label={t('No')}
                                            style={{ marginTop: '4px' }} />
                                    </Paper>
                                </RadioGroup>
                            </Stack>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        {reportDetail.basedOnNumberOfVotes}
                        <FormControl>
                            <Stack direction='row' spacing={2}>
                                <FormLabel id="result-based-no-of-votes-label" style={{ marginBottom: '10px', fontWeight: 'bold', marginTop: "15px", color: 'black' }}>{t('Result_Based_On')}</FormLabel>
                                <RadioGroup
                                    row
                                    id='result'
                                    aria-labelledby="result-based-no-of-votes-label"
                                    name="result-based-no-of-votes-radio-buttons"
                                    value={reportDetail.basedOnNumberOfVotes}
                                    onChange={(event) => handleBooleanChange('basedOnNumberOfVotes', event)}
                                >
                                    <Paper className={reportDetail.basedOnNumberOfVotes === true ? classes.withVoterName : classes.withoutVoterName}
                                        onChange={(event) => handleBooleanChange('basedOnNumberOfVotes', event)}>
                                        <FormControlLabel id='Number-of-voters' value={true} control={<Radio />} label={t('Number_of_Votes')} />
                                    </Paper>&nbsp;&nbsp;
                                    <Paper className={reportDetail.basedOnNumberOfVotes === false ? classes.withVoterName : classes.withoutVoterName}
                                        onChange={(event) => handleBooleanChange('basedOnNumberOfVotes', event)}>
                                        <FormControlLabel id='weightage' value={false} control={<Radio />} label={t('Weightage')} />
                                    </Paper>
                                </RadioGroup>
                            </Stack>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl fullWidth required>
                            <InputLabel id="select-report-type-label">{t('Report_Type')}</InputLabel>
                            <Select
                                labelId="select-report-type-label"
                                id="reportType"
                                value={reportDetail.reportType}
                                variant='outlined'
                                onChange={(event) => handleChange('reportType', event)}
                            >
                                {
                                    reportTypes.map(reportType => {
                                        return <MenuItem key={reportType.value} value={reportType.value}>{reportType.name}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl >
                    </Grid>
                    {(reportDetail.reportType === 'PDF_A' || reportDetail.reportType === 'PDF_UA') && <Fragment>
                        <Grid item xs={12}>
                            <TextField id="password" label={t('Password')} value={reportDetail.password} fullWidth variant='outlined' placeholder={t('Enter_Password_to_protect_your_page')}
                                onChange={(event) => handleChange('password', event)} type={showPassword ? "text" : "password"}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                id="icon"
                                                aria-label="toggle password visibility"
                                                onClick={toggleShowPassword}
                                            >
                                                {showPassword ? <Visibility id="visible" /> : <VisibilityOff id="visibleOff" />}
                                            </IconButton>
                                            <FieldHelperText helperText={t('Result_With_Password_Helper_Text')} />
                                        </InputAdornment>
                                    )
                                }} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField id="water-mark-text" label={t('Watermark_Text')} variant='outlined' placeholder={t("Enter_Water_Mark")}
                                value={reportDetail.watermarkText} fullWidth onChange={(event) => handleChange('watermarkText', event)}
                            />
                        </Grid>
                    </Fragment>
                    }
                </Grid>
            </Card>
            <DialogActions>
                <Button onClick={closeHandler} id='close' color="primary" variant='outlined' size='small'>
                    {t('Close')}
                </Button>
                <Button onClick={generateResult} id='generate' variant='contained' color='primary' size='small'>
                    {t('Generate')}
                </Button>
            </DialogActions>

            {loading && <EligoBackdrop show={loading} />}
            {message.showMsg && <EligoSnackBar show={message.showMsg} message={message.message}
                severity={message.severity} />}
        </Dialog>
    )
}

export default GenerateResult;