import { makeStyles } from "@material-ui/core";
import { BottomNavigation, Chip, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import EligoBackdrop from "../../common/EligoBackdrop";
import EligoSnackBar from "../../common/EligoSnackBar";
import EligoPagination from "../../common/pagination/EligoPagination";
import axios from "axios";
import { AxiosEvotingInterceptor } from "../../config/axios.interceptor";

const useStyles = makeStyles((theme) => ({
    tableRow: {
        background: '#dff0f1 !important',
    },
    tableHead: {
        minWidth: 170,
        background: '#dff0f1 !important',
        fontWeight: 'bold !important',
        color: '#03585d !important',
        padding: '10px 20px !important'
    },
    tableData: {
        padding: '10px 20px !important'
    },
    tableDataRow: {
        // '&:nth-of-type(even)': {
        //     backgroundColor: '#add8e612 !important',
        // }
    },
    pagination: {
        borderTop: '1px solid #ebe9e9',
        padding: '8px 12px !important'
    },
    successChip: {
        background: 'rgb(230 252 229) !important',
        color: `${theme.palette.success.main} !important`,
        padding: '0px 1px',
        height: '28px !important'
    },
    failedChip: {
        background: 'rgb(255 225 224) !important',
        color: `${theme.palette.error.main} !important`,
        padding: '0px 6px',
        height: '28px !important'
    }
}))

const LoginHistory = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');
    const [loginHistory, setLoginHistory] = useState([])
    const [loading, setLoading] = useState(false);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(1);
    };

    const listLoginHistory = async () => {
        AxiosEvotingInterceptor.get(`login-histories?page=${page}&size=${rowsPerPage}`).then(response => {
            if (response) {
                setErrorMessage('');
                setCount(response.count);
                setLoginHistory(response.loginHistories);
            }
            setLoading(false)
        }).catch(error => {
            if (error.message) {
                setErrorMessage(error.message);
            } else {
                setErrorMessage(t('Try_Again'));
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        setLoading(true);
        listLoginHistory();
    }, [page, rowsPerPage])

    return (
        <React.Fragment>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 'calc(100vh - 300px)' }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow className={classes.tableRow}>
                                <TableCell align="left" className={classes.tableHead}>
                                    {t('Login Date & Time')}
                                </TableCell>
                                <TableCell align="center" className={classes.tableHead}>
                                    {t('Login Status')}
                                </TableCell>
                                {/* <TableCell align="left" className={classes.tableHead}>
                                    {t('Logout Date & Time')}
                                </TableCell> */}
                            </TableRow>
                        </TableHead>
                        {!loading && <TableBody>
                            {loginHistory
                                .map((row) => (
                                    <TableRow key={row.loginHistoryId} className={classes.tableDataRow}>
                                        <TableCell align="left" className={classes.tableData}>
                                            {row.rowCreatedOn === null ? '-' : format(new Date(row.rowCreatedOn), process.env.REACT_APP_DATE_FORMAT_TO_DISPLAY)}
                                        </TableCell>
                                        <TableCell align="center" className={classes.tableData}>
                                            <Chip label={row.successLogin === true ? t('Success') : t('Failed')}
                                                className={row.successLogin === true ? classes.successChip : classes.failedChip}
                                            />
                                        </TableCell>
                                        {/* <TableCell align="left" className={classes.tableData}>
                                            {row.logoutTime === null ? '-' : format(new Date(row.logoutTime), 'dd MMM yyy hh:mm:ss a')}
                                        </TableCell> */}
                                    </TableRow>
                                ))}
                            {count === 0 && <TableRow>
                                <TableCell id='norecord' colSpan={9}> {t('No_Record_Found')} </TableCell>
                            </TableRow>
                            }
                        </TableBody>}
                    </Table>
                </TableContainer>
                {!loading && count > 10 &&
                    <BottomNavigation className={classes.pagination} sx={{height: 'auto !important'}}>
                        <EligoPagination rowsPerPage={rowsPerPage} page={page} totalItems={count}
                            onRowsPerPageChange={handleChangeRowsPerPage} onPageChange={handleChangePage} />
                    </BottomNavigation>
                }
            </Paper>
            {loading &&
                <EligoBackdrop show={loading} invisible={false} />}
            {errorMessage !== '' && errorMessage !== undefined ?
                <EligoSnackBar show={true} message={errorMessage} severity="error" reset={() => setErrorMessage(null)} /> : null}
        </React.Fragment>
    );
}

export default LoginHistory;