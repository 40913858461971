import React                    from 'react';
import '../Error.css';
import { useTranslation }       from 'react-i18next';

const Unauthorized = () => {
    const { t } = useTranslation();

    return (
        <div id="notfound">
            <div class="notfound">
                <div class="notfound-404">
                    <h1>401</h1>
                </div>
                <h2>{t('Access_Denied')}</h2>
                <p>{t('Unauthorized_Page_Msg')}</p>
                <a href="/">{t('Go_To_Home')}</a>
            </div>
        </div>
    )
}

export default Unauthorized;
