import { Card, CardContent, Checkbox, Container, FormControlLabel, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip, makeStyles, withStyles } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import EligoBackdrop from '../../../common/EligoBackdrop';
import EligoConfirmDialog from '../../../common/EligoConfirmDialog';
import EligoSnackBar from '../../../common/EligoSnackBar';
import { Status } from '../../../common/GenericCodes';
import { UserContext } from '../../../common/context/UserContext';
import EligoPagination from '../../../common/pagination/EligoPagination';
import { AxiosEvotingInterceptor } from '../../../config/axios.interceptor';
import { ColoredHeader } from '../../file-upload-download/styles/FileStyle';
import AssignRole from '../assign-role/AssignRole';
import RoleDetail from '../role-detail/RoleDetail';


const useStyles = makeStyles((theme) => ({
    margin: {
        marginTop: theme.spacing(2),
    }
}));

const BlueOnGreenTooltip = withStyles({
    tooltip: {
        color: "white",
        backgroundColor: "#2a83ad",
        fontWeight: 'bold'
    }
})(Tooltip);

const RoleCheckbox = withStyles({
    root: {
        color: blue[400],
        '&$checked': {
            color: blue[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const PollRoles = (props) => {
    const { t } = useTranslation();
    let history = new useHistory()
    let accessPollStatus = [Status.open, Status.new, Status.scheduled]
    const classes = useStyles();
    const context = useContext(UserContext);
    const [page, setPage] = useState(1);
    const [itemsPerPage, setItemPerPage] = useState(5);
    const [totalNumberOfUsers, setTotalNumberOfUsers] = useState(0);
    const [users, setUsers] = useState([]);
    const [seekConfirmation, setSeekConfitmation] = useState({
        show: false,
        title: '',
        message: '',
        onAgree: '',
        onDisAgree: ''
    })
    const [showRoleDetails, setShowRoleDetails] = useState({
        show: false,
        user: '',
        userName: ''
    })
    const [message, setMessage] = useState({
        show: false,
        msg: '',
        severity: ''
    })
    const [loading, setLoading] = useState(false);

    const columns = [
        { id: 'name', label: t('Name'), minWidth: 30, textAlign: 'left', description: '' },
        { id: 'organizer', label: t('Organizer'), minWidth: 20, textAlign: 'center', icon: <InfoOutlinedIcon fontSize='small' />, description: t("Organizer_Description") },
        { id: 'observer', label: t('Observer'), minWidth: 20, textAlign: 'center', icon: <InfoOutlinedIcon fontSize='small' />, description: t("Observer_Description") },
        { id: 'scrutinizer', label: t('Scrutinizer'), minWidth: 20, textAlign: 'center', icon: <InfoOutlinedIcon fontSize='small' />, description: t("Scrutinizer_Description") },
        { id: 'action', label: '', minWidth: 10, textAlign: 'center' ,description: ''}
    ];

    const getPollRoleList = () => {
        AxiosEvotingInterceptor.get(`role/${props.pollDetails.id}?page=${page}&limit=${itemsPerPage}&asc=true`).then(response => {
            setUsers([]);
            response.userRole.map((role) => {
                setUsers(prevState => ([
                    ...prevState,
                    {
                        name: role.name,
                        email: role.email,
                        phone_number: role.phoneNumber,
                        login_id: role.loginId,
                        user_id: role.userId,
                        roles: [],
                        roleDetails: role.roleDetails.map(roleDetail => {
                            return {
                                role_id: roleDetail.roleId,
                                granted_by: roleDetail.grantedBy,
                                revoked_by: roleDetail.revokedBy,
                                role: roleDetail.role,
                                start_date_time: roleDetail.startTime,
                                // end_date_time: new Date(roleDetail.endTime).getFullYear() === 9999 ? '' : roleDetail.endTime,
                                end_date_time: new Date(roleDetail.endTime) > new Date() ? '' : roleDetail.endTime,
                                sequence: roleDetail.sequence,
                                remarks: roleDetail.remarks,
                                active: roleDetail.active
                            }
                        })
                    }
                ]));
            })
            resetConfirmation()
            setTotalNumberOfUsers(response.count);
            setLoading(false)
        }).catch(error => {
            setLoading(false)
            setMessage({ show: true, message: error.rmessage, severity: 'error' });
        })
    }

    const navigateToPolls = () => {
        history.push("/polls")
    }

    const assignNewRoleMutation = (userId, role) => {
        setMessage({ show: false, message: '', severity: '' });
        setLoading(true)
        let payload = {
            pollId: props.pollDetails.id,
            userId: userId,
            roleDetails: [
                { role: role }
            ]
        }
        AxiosEvotingInterceptor.post(`role/${props.pollDetails.id}`, payload).then(response => {
            getPollRoleList()
            // resetConfirmation()
            setMessage({ show: true, message: t("Role_Assigned_Successfully"), severity: 'success' });
            // setLoading(false)
        }).catch(error => {
            setMessage({ show: true, message: error.message, severity: 'error' });
            setLoading(false)
            resetConfirmation()
        })
    }

    const revokeRoleMutation = (existingRole, remarks, login_id, role) => {
        setMessage({ show: false, message: '', severity: '' });
        setLoading(true)
        AxiosEvotingInterceptor.patch(`role/${props.pollDetails.id}/${existingRole.role_id}`, remarks).then(response => {
            if (login_id === context.userSession.session.userId && role === 'organizer') {
                navigateToPolls()
            } else {
                getPollRoleList()
                // setLoading(false)
                // resetConfirmation()
                setMessage({ show: true, message: t("Role_Revoked_Successfully"), severity: 'success' });
            }
        }).catch(error => {
            setMessage({ show: true, message: error.message, severity: 'error' });
            setLoading(false)
            resetConfirmation()
        })

    }

    useEffect(() => {
        setLoading(true)
        // if (page === 0) {
        //     setUsers([]);
        // }
        getPollRoleList()
    }, [page, itemsPerPage]);

    const backToPolls = () => {
        history.push({
            pathname: '/polls',
            state: {
                pollType: props.pollType
            }
        });
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setItemPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };

    const refreshRoles = () => {
        setUsers([]);
    }

    const confirmRoleAssignRevoke = (existingRole, userId, role, login_id) => {
        setSeekConfitmation(prevState => ({
            ...prevState,
            show: true,
            title: t("Confirmation"),
            message: existingRole === undefined ? `${t('Are_You_Sure_That_You_Want_To_Assign')} ${role} ${t('role')} ?` : `${t('Are_You_Sure_That_You_Want_To_Revoke')} ${role} ${t('role')} ?`,
            onDisAgree: resetConfirmation,
            onAgree: existingRole === undefined ? () => { assignNewRoleMutation(userId, role) } : (remarks) => { revokeRoleMutation(existingRole, remarks, login_id, role) },
            withConfirmText: existingRole === undefined ? false : true,
            isConfirmTextMandatory: existingRole === undefined ? false : true
        }))
    }

    const resetConfirmation = () => {
        setSeekConfitmation(prevState => ({
            ...prevState,
            show: false,
            title: '',
            message: '',
            onDisAgree: '',
            onAgree: ''
        }))
    }

    return (
        <div className="loading-container">
            <Grid container className={classes.margin}>
                <Grid item xs={12}>
                    <Container maxWidth="lg">
                        <Grid container spacing={3}>
                            {((props.pollStatus != Status.closed && props.pollStatus != Status.canceled) || (props.hasSystemAdmineOrSupportRole)) && <Grid item xs={4}>
                                <AssignRole pollId={props.pollDetails.id} page={page} limit={itemsPerPage}
                                    pollStatus={props.pollDetails.status} refresh={refreshRoles} getPollRoleList={getPollRoleList}
                                />
                            </Grid>}
                            <Grid item xs={(props.pollStatus != Status.closed && props.pollStatus != Status.canceled) || (props.hasSystemAdmineOrSupportRole) ? 8 : 12}>
                                <Card>
                                    <CardContent>
                                        <TableContainer>
                                            <Table id='table' stickyHeader={true} aria-label="sticky-table-header">
                                                <ColoredHeader>
                                                    <TableRow >
                                                        {columns.map((column, index) => (
                                                            <BlueOnGreenTooltip title={column.description} arrow>
                                                                <TableCell id='table-cell' key={index} style={{ width: column.minWidth + "%", textAlign: column.textAlign, cursor: 'pointer' }} >
                                                                    {column.label}{column.icon}
                                                                </TableCell>
                                                            </BlueOnGreenTooltip>
                                                        ))}
                                                    </TableRow>
                                                </ColoredHeader>
                                                <TableBody>
                                                    {users.length === 0 && <TableRow>
                                                        <TableCell id='no-record' colSpan={5}> {t('No_Record_Found')} </TableCell>
                                                    </TableRow>
                                                    }
                                                    {users.length > 0 && users.map((user, index) => {
                                                        return (
                                                            <TableRow hover key={user.user_id} id={`table-data${index}`}>
                                                                <TableCell id={`table-name${index}`}>
                                                                    <span id={`user-name${index}`}>{user.name}</span>
                                                                    <div id={`user-email${index}`} style={{ color: 'grey' }}><em>{user.email}</em></div>
                                                                </TableCell>
                                                                <TableCell style={{ textAlign: 'center' }} id={`organizer${index}`}>
                                                                    <Tooltip title={user.roleDetails.some(e => e.role == 'organizer' && e.active) ? t("Click_Here_To_Revoke") : t("Click_Here_To_Assign")}
                                                                        disableHoverListener={!props.assignRolePermission}>
                                                                        <FormControlLabel
                                                                            disabled={!(props.hasSystemAdmineOrSupportRole) && (props.pollStatus === Status.closed || props.pollStatus === Status.canceled)}
                                                                            control={<Checkbox id={`organizer-check${index}`}
                                                                                checked={user.roleDetails.some(e => e.role == 'organizer' && e.active)}
                                                                                name="organizer" color="primary"
                                                                                onClick={() => confirmRoleAssignRevoke(user.roleDetails.find(e => e.role == 'organizer' && e.active), user.user_id, 'organizer', user.login_id)} />}
                                                                        />
                                                                    </Tooltip>
                                                                </TableCell>
                                                                <TableCell style={{ textAlign: 'center' }} id={`table-observer${index}`}>
                                                                    <Tooltip title={user.roleDetails.some(e => e.role == 'observer' && e.active) ? t("Click_Here_To_Revoke") : t("Click_Here_To_Assign")}
                                                                        disableHoverListener={!props.assignRolePermission}>
                                                                        {/* <Tooltip title={t('Under_Development')} disableHoverListener={!props.assignRolePermission}> */}
                                                                        <FormControlLabel
                                                                            disabled={!(props.hasSystemAdmineOrSupportRole) && (props.pollStatus === Status.closed || props.pollStatus === Status.canceled)}
                                                                            // disabled={true}
                                                                            control={<Checkbox id={`observer-check${index}`} checked={user.roleDetails.some(e => e.role == 'observer' && e.active)} name="observer" color="primary"
                                                                                onClick={() => confirmRoleAssignRevoke(user.roleDetails.find(e => e.role == 'observer' && e.active), user.user_id, 'observer', user.login_id)} />}
                                                                        />
                                                                        {/* </Tooltip> */}
                                                                    </Tooltip>
                                                                </TableCell>
                                                                <TableCell style={{ textAlign: 'center' }} id={`table-scrutinizer${index}`}>
                                                                    <Tooltip title={user.roleDetails.some(e => e.role == 'scrutinizer' && e.active) ? t("Click_Here_To_Revoke") : t("Click_Here_To_Assign")}
                                                                        disableHoverListener={!props.assignRolePermission}>
                                                                        {/* <Tooltip title={t('Under_Development')} disableHoverListener={!props.assignRolePermission}> */}
                                                                        <FormControlLabel
                                                                            disabled={!(props.hasSystemAdmineOrSupportRole) && (props.pollStatus === Status.closed || props.pollStatus === Status.canceled)}
                                                                            // disabled={true}
                                                                            control={<Checkbox id={`scrutinizer-check${index}`} checked={user.roleDetails.some(e => e.role == 'scrutinizer' && e.active)} name="scrutinizer" color="primary"
                                                                                onClick={() => confirmRoleAssignRevoke(user.roleDetails.find(e => e.role == 'scrutinizer' && e.active), user.user_id, 'scrutinizer', user.login_id)} />}
                                                                        />
                                                                        {/* </Tooltip> */}
                                                                    </Tooltip>
                                                                </TableCell>
                                                                <TableCell id={`table-view${index}`}>
                                                                    <IconButton id='button' edge="start" disabled={!(props.hasSystemAdmineOrSupportRole) && (props.pollStatus === Status.closed || props.pollStatus === Status.canceled)} color="inherit" aria-label="action" onClick={() => { setShowRoleDetails({ show: true, user: user, userName: user.name }) }}>
                                                                        <InfoOutlinedIcon />
                                                                    </IconButton>
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        {/* (props.pollType != Status.closed && props.pollType != Status.canceled) && */}
                                        {<div style={{ marginTop: '10px' }}>
                                            <EligoPagination rowsPerPage={itemsPerPage} page={page} totalItems={totalNumberOfUsers}
                                                onRowsPerPageChange={handleChangeRowsPerPage} onPageChange={handleChangePage}
                                                rowsText={t('Users')}>
                                            </EligoPagination>
                                        </div>}
                                        {/* <TablePagination
                                            id='pagination'
                                            rowsPerPageOptions={[5, 10, 25]}
                                            component="div"
                                            count={totalNumberOfUsers}
                                            rowsPerPage={itemsPerPage}
                                            page={page}
                                            onChangePage={handleChangePage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                        /> */}
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
            </Grid>
            {seekConfirmation.show && <EligoConfirmDialog show={seekConfirmation.show}
                title={seekConfirmation.title} message={seekConfirmation.message}
                onAgree={seekConfirmation.onAgree} onDisAgree={seekConfirmation.onDisAgree} withConfirmText={seekConfirmation.withConfirmText}
                isConfirmTextMandatory={seekConfirmation.isConfirmTextMandatory} />}
            {loading && <EligoBackdrop show={true} />}
            {showRoleDetails.show && <RoleDetail show={showRoleDetails.show} pollId={props.pollDetails.id}
                user={showRoleDetails.user} userName={showRoleDetails.userName} setShowRoleDetails={setShowRoleDetails}></RoleDetail>}
            {message.show && <EligoSnackBar show={message.show} message={message.message} severity={message.severity} />}
        </div>
    )
}

export default PollRoles;
