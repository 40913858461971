import { AccountCircle, Business, History } from '@material-ui/icons';
import { Box, Divider, Stack, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { createBrowserHistory } from "history";
import qs from "qs";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import AvatarName from "../common/avatarname/AvatarName";
import { UserContext } from "../common/context/UserContext";
import TabPanel, { a11yProps } from "../common/tabPanel/TabPanel";
import './UserProfile.css';
import { styled } from '@material-ui/styles';
import { Breadcrumbs, Card, Link } from '@material-ui/core';
import { MdPassword } from 'react-icons/md';
import AccountInfo from './account-info/AccountInfo';
import LoginHistory from './login-history/LoginHistory';
import Addresses from './address/Addresses';
import ChangePassword from './change-password/ChangePassword';
import { useHistory } from 'react-router-dom';

export const VotingCard = styled(Card)(() => ({
    boxShadow: 'inset 0 1px 1px 0 hsl(0deg 0% 100% / 10%), 0 46px 50px -22px rgb(50 50 93 / 25%), 0 10px 40px -15px rgb(0 0 0 / 30%)',
    borderRadius: '0.5rem'
}))


const useStyles = makeStyles(() => ({
    wrapIcon: {
        justifyContent: 'flex-start !important',
        display: 'flex',
        gap: '6px',
        textTransform: 'none'
    },
    alignStart: {
        alignItems: 'flex-start !important',
        whiteSpace: 'none'
    },
    // profileHeaders: {
    // fontWeight: 'bold !important'
    // borderBottom: `1px solid ${theme.palette.text.secondary}`
    // }
}));

const UserProfileHeader = (props) => {
    const { title, className } = props;
    return (
        <Stack mb={2} justifyContent='center' alignItems='center'>
            <Typography className={className} variant='h6'>{title}</Typography>
        </Stack>
    )
}

const UserProfile = () => {
    const { t } = useTranslation();
    const [value, setValue] = useState(0);
    const classes = useStyles();
    const userContext = useContext(UserContext);
    const mediumViewport = useMediaQuery('(min-width:957px)');
    const history = createBrowserHistory();
    const pathHistory = useHistory();

    useEffect(() => {
        const filterParams = history.location.search.substring(1);
        const filtersFromParams = qs.parse(filterParams);
        if (filtersFromParams.value) {
            setValue(Number(filtersFromParams.value));
        }
    }, []);

    useEffect(() => {
        history.push(`?value=${value}`);
    }, [value]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const redirectToHome = () => {
        pathHistory.push("/polls");
    }

    return (
        <div>
            <Stack spacing={1} sx={{ px: 2, py: 1 }}>
                <Breadcrumbs aria-label="Client-breadcrumb">
                    <Link id='home' underline="hover"
                        color="primary"
                        style={{ cursor: 'pointer', fontSize: '0.875rem', marginLeft: '8px' }}
                        onClick={redirectToHome}
                    >
                        {t('Home')}
                    </Link>
                    <Typography id='myClient' color="text.primary" variant='subtitle2'>{t('My_Profile')}</Typography>
                </Breadcrumbs>
                {/* <PageHeader title="My Profile" icon={<Person fontSize="medium" />} /> */}
                <Typography variant='h6' sx={{ px: 1, mt: '0px' }}>{t('My_Profile')}</Typography>
                <VotingCard elevation={2} style={{ minHeight: '250px' }}>
                    <Stack spacing={1} direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row' }}>
                        <Stack width={{ xs: '100%', sm: '100%', md: '20%', lg: '20%' }}>
                            <Box p={2}>
                                <div>
                                    {/* <Avatar src={process.env.PUBLIC_URL + '/profileavatar.jpg'}  /> */}
                                    <div className="ma-lr avatar-mb">
                                        <AvatarName width='140px' height='140px' fontSize='70px' />
                                    </div>
                                    <Typography style={{ textAlign: 'center' }}>{userContext.userSession.session.name}</Typography>
                                </div>
                            </Box>
                            <Box p={2}>
                                <Tabs
                                    orientation={mediumViewport ? "vertical" : "horizontal"}
                                    variant="scrollable"
                                    value={value}
                                    onChange={handleChange}
                                    textColor="primary"
                                    indicatorColor="primary"
                                    aria-label="Profile tabs "
                                    sx={{
                                        justifyContent: 'center',
                                        '& .Mui-selected': { backgroundColor: 'background.default' },
                                    }}
                                >
                                    <Tab className={classes.alignStart} label={
                                        <React.Fragment>
                                            <Typography className={classes.wrapIcon}>
                                                <AccountCircle />{t('Profile')}
                                            </Typography>
                                        </React.Fragment>
                                    } {...a11yProps(0)} />
                                    <Tab className={classes.alignStart} label={
                                        <React.Fragment>
                                            <Typography className={classes.wrapIcon}>
                                                <MdPassword style={{fontSize: "1.5rem"}}/>{t('Change_Password')}
                                            </Typography>
                                        </React.Fragment>
                                    } {...a11yProps(1)} />
                                    <Tab className={classes.alignStart} label={
                                        <React.Fragment>
                                            <Typography className={classes.wrapIcon}>
                                                <Business />{t('Address')}
                                            </Typography>
                                        </React.Fragment>
                                    } {...a11yProps(2)} />
                                    <Tab className={classes.alignStart} label={
                                        <React.Fragment>
                                            <Typography className={classes.wrapIcon}>
                                                <History />{t('Login_History')}
                                            </Typography>
                                        </React.Fragment>
                                    } {...a11yProps(3)} />
                                </Tabs>
                            </Box>
                        </Stack>
                        <Divider flexItem orientation={mediumViewport ? "vertical" : "horizontal"} sx={{ borderRightWidth: 1, borderBottomWidth: 1 }} />
                        <Stack width={{ xs: '100%', sm: '100%', md: '80%', lg: '80%' }} sx={{ maxHeight: 'calc(100vh - 135px)', overflow: 'auto' }}>
                            <Box p={2}>
                                <TabPanel value={value} index={0}>
                                    <UserProfileHeader title={t('Profile')} />
                                    <AccountInfo />
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <UserProfileHeader title={t('Change_Password')} />
                                    <ChangePassword email={userContext.userSession.session.email} />
                                </TabPanel>
                                <TabPanel value={value} index={2}>
                                    <UserProfileHeader title={t('Address')} />
                                    <Addresses userId={userContext.userSession.session.userId} isLoginUser={true} isClient={false}/>
                                </TabPanel>
                                <TabPanel value={value} index={3}>
                                    <UserProfileHeader title={t('Login_History')} />
                                    <LoginHistory />
                                </TabPanel>
                            </Box>
                        </Stack>
                    </Stack>
                </VotingCard>
            </Stack>
        </div>
    );
}

export default UserProfile;