import React, {
    useState
}                                       from 'react';
import { Grid, 
    TextField,
    MenuItem,
    Typography,
    Button,
    Popover
}                                       from '@material-ui/core';
import { useTranslation }               from 'react-i18next';
import { PersonType }                   from '../../../common/GenericCodes';
import SearchUser                       from '../../../common/search/SearchUser';
import AddOrganizer                     from './AddOrganizer';
import { AxiosEvotingInterceptor } from '../../../config/axios.interceptor';
import { Stack } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    popoverClass: {
       '& .MuiPopover-paper': {
        width:'70%'
       }
    }
}));
export const DisplayOrganizerDetails = (props) => {
    const {  title, value} = props;
    return (
        <Stack direction='row' alignItems='center' spacing={2}
        // className={className}
        sx={(theme) => ({
            borderRadius: '5px', padding: '0.25rem 0.6rem',
            background: theme.palette.type === 'light' ? 'white' : '#f5f9fa',
            borderLeft: theme.palette.type === 'light' ? '4px solid #cfdee8' : '4px solid #d1e6eb',
        })}>
            <Stack direction='column'>
                <Typography color='textSecondary' variant='subtitle2'>{title}</Typography>
                {value}
            </Stack>
        </Stack>
    )
}

const PollOrganizerDetail = ({pollDetail, onSelect,setMessage}) => {
    const { t } = useTranslation();
    const classes   = useStyles()
    const [partyType, setPartyType] = useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'add-organizer-popover' : undefined;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const setSelectedUser = (selectedOrganizer) => {
        if (selectedOrganizer != null && selectedOrganizer != '') {
            AxiosEvotingInterceptor.get(`user/${selectedOrganizer.id}`).then((response) => {
                onSelect(response);
            }).catch((error) => {
                console.log("error,,", error.message)
            })
            
        }
    }

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={1}>
                    <TextField id="organizer-type" select size="small" variant="outlined" value={partyType}
                        onChange={(event) => setPartyType(event.target.value)} label={t('Type')}>
                        {PersonType.map((type, index) => (
                            <MenuItem key={index} value={type.value}>
                                {type.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={4} style={{padding: '12px 12px 12px 32px'}}>
                    <SearchUser onSelect={setSelectedUser} registeredUsers={true} partyType={partyType} placeholderForAdmine={true}/>
                </Grid>
                <Grid item xs={7}>
                    <Button aria-describedby={id} id="add-organizer" size="small" variant="outlined" 
                            style={{float:'right'}} color='primary' onClick={handleClick}>
                        {t('Add_Organizer')}
                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={4}>
                    <DisplayOrganizerDetails title={t('Name')} value={pollDetail.organizerName ? pollDetail.organizerName : '-'}/>
                    {/* <Typography variant="subtitle2" style={{color:'gray'}}>{t('Name')}</Typography>
                    <Typography variant="body1" gutterBottom>{pollDetail.organizerName ? pollDetail.organizerName : '-'}</Typography> */}
                </Grid>
                <Grid item xs={4}>
                    <DisplayOrganizerDetails title={t('Email')} value={pollDetail.organizerEmail ? pollDetail.organizerEmail : '-'}/>
                    {/* <Typography variant="subtitle2" style={{color:'gray'}}>{t('Email')}</Typography>
                    <Typography variant="body1" gutterBottom>{pollDetail.organizerEmail ? pollDetail.organizerEmail : '-'}</Typography> */}
                </Grid>
                <Grid item xs={4}>
                    <DisplayOrganizerDetails title={t('Mobile_Number')} value={pollDetail.organizerPhoneNumber ? pollDetail.organizerPhoneNumber : '-'}/>
                    {/* <Typography variant="subtitle2" style={{color:'gray'}}>{t('Mobile_Number')}</Typography>
                    <Typography variant="body1" gutterBottom>{pollDetail.organizerPhoneNumber ? pollDetail.organizerPhoneNumber : '-'}</Typography> */}
                </Grid>
            </Grid>
            <Popover
                className={classes.popoverClass}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                }}
            >
                <AddOrganizer cancel={handleClose} onCreate={setSelectedUser} setSuccessMessage={setMessage}/>
            </Popover>
        </div>
    )
}

export default PollOrganizerDetail;