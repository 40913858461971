import { Card, CardContent, Divider, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import EligoBackdrop from '../../../common/EligoBackdrop';
import EligoConfirmDialog from '../../../common/EligoConfirmDialog';
import EligoSnackBar from '../../../common/EligoSnackBar';
import * as Validators from '../../../common/form-validators.js';
import { AxiosEvotingInterceptor } from '../../../config/axios.interceptor';
import PollQuestionDetail from '../poll-question-detail/PollQuestionDetail';

const useStyles = makeStyles((theme) => ({
    actions: {
        backgroundColor: 'white'
    },
    cardContent: {
        borderRadius: '0px',
        maxHeight: '490px',
        minHeight: '490px',
        padding: '10px !important',
        overflowY: 'auto',
    },
    // nextBtn: {
    //     marginLeft: theme.spacing(1),
    //     float: 'right',
    //     marginRight: "15px",

    // },
    // backBtn: {
    //     marginLeft: theme.spacing(1),
    //     float: 'left'
    // },
    // stack: {
    //     // padding: '10px 6px',
    //     borderRadius: '5px',
    //     boxShadow: 'rgb(32 40 45 / 8%) 0px 2px 14px 0px !important'
    // }
}));

function AddPollQuestion(props) {
    const { t } = useTranslation();
    const classes = useStyles();
    const [isEdit, setIsEdit] = React.useState(props.isEdit ? props.isEdit : false);
    const [errors, setErrors] = React.useState({});
    const [touched, setTouched] = React.useState({});
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = React.useState({
        showMsg: false,
        message: null,
        severity: null
    });
    const [seekConfirmation, setSeekConfirmation] = useState({
        title: '',
        message: '',
        show: false,
        agreeBtnLabel: '',
        disAgreeBtnLabel: '',
        onAgree: ''
    });
    const [pollRoundQuestionDetail, setPollRoundQuestionDetail] = useState({
        poll_question_id: props.pollRoundQuestionDetails.poll_question_id,
        // sequence: props.pollRoundQuestionDetails.sequence,
        poll_id: props.pollRoundQuestionDetails.poll_id,
        question: props.pollRoundQuestionDetails.question,
        question_description: props.pollRoundQuestionDetails.question_description,
        // allow_vote_change: props.pollRoundQuestionDetails.allow_vote_change,
        // display_organizer_name: props.pollRoundQuestionDetails.display_organizer_name,
        // allow_multiple_vote: props.pollRoundQuestionDetails.allow_multiple_vote,
        // allow_question_skip: props.pollRoundQuestionDetails.allow_question_skip,
        // shuffle_options: props.pollRoundQuestionDetails.shuffle_options,
        threshold: props.pollRoundQuestionDetails.threshold,
        PollQuestionResponses: props.pollRoundQuestionDetails.PollQuestionResponses.map(pollQuestionResponses => {
            return {
                poll_question_response_id: pollQuestionResponses.poll_question_response_id,
                option: pollQuestionResponses.option,
                poll_question_id: pollQuestionResponses.poll_question_id,
                // sequence: pollQuestionResponses.sequence
            }
        }),
        previousState: ''
    })

    const validate = {
        question: question => Validators.requiredValidation(t('Question'), question) || Validators.maxLengthValidation(t('Question'), question, 1000),
        question_description: question_description => Validators.maxLengthValidation(t('Question_Description'), question_description, 10000),
        option: option => Validators.requiredValidation(t('Option'), option),
        threshold: threshold => Validators.percentageValidation(t('Threshold'), threshold)
    };

    const onJsonInputChange = (event) => {
        setPollRoundQuestionDetail(prevState => ({
            ...prevState,
            [event.target.id]: { en: event.target.value, fr: '' }
        }));
        setTouched(prevState => ({
            ...prevState,
            [event.target.id]: true,
        }))
    }

    const onInputChange = event => {
        setPollRoundQuestionDetail(prevState => ({
            ...prevState,
            [event.target.id]: event.target.value
        }));
        setTouched(prevState => ({
            ...prevState,
            [event.target.id]: true,
        }))
    }

    const handleDataBoolean = (event, stateVariable) => {
        let id = null;
        if (event.target.id != "") {
            id = event.target.id;
        } else if (event.target.parentElement.id != "") {
            id = event.target.parentElement.id;
        } else if (event.target.parentElement.parentElement.id != "") {
            id = event.target.parentElement.parentElement.id;
        }
        if (id != null) {
            setPollRoundQuestionDetail(prevState => ({
                ...prevState,
                [id]: !prevState[id]
            }))
            setTouched(prevState => ({
                ...prevState,
                [id]: true,
            }))
        }
    }

    const onJsonInputChangeArray = (event, arrayIndex) => {
        setPollRoundQuestionDetail(prevState => ({
            ...prevState,
            PollQuestionResponses: prevState.PollQuestionResponses.map(
                (el, index) => index === arrayIndex ? {
                    ...el, [event.target.id]: event.target.value
                } : el
            )
        }))
        setTouched(prevState => ({
            ...prevState,
            [event.target.id + arrayIndex]: true,
        }))
    }

    const handleSlider = (event, value) => {
        setPollRoundQuestionDetail(prevState => ({
            ...prevState,
            [event.target.id]: value
        }));
        setTouched(prevState => ({
            ...prevState,
            [event.target.id]: true,
        }))
    }

    const handleAddOption = () => {
        setPollRoundQuestionDetail(prevState => ({
            ...prevState,
            PollQuestionResponses: [...prevState.PollQuestionResponses, {
                poll_question_response_id: '',
                option: "",
                poll_question_id: '',
                // sequence: 0
            }]
        }))
    }

    const handleDeleteOption = (option, index) => {
        setMessage({ showMsg: false, message: '', severity: '' });
        var options = pollRoundQuestionDetail.PollQuestionResponses;
        if (options.length > 2 && index !== -1) {
            if (option.poll_question_response_id !== '') {
                setLoading(true);
                AxiosEvotingInterceptor.delete(`poll-question/delete-option/${pollRoundQuestionDetail.poll_id}/${pollRoundQuestionDetail.poll_question_id}/${option.poll_question_response_id}`)
                .then(response => {
                    if (response) {
                        if (!props.stepper) {
                            setIsEdit(false);
                        }
                        props.handleCreatePollRoundQuestion(props.index);
                        setMessage({ showMsg: true, message: t('Option_Deleted_Successfully'), severity: 'success' });
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    if (error.message) {
                        setMessage({ showMsg: true, message: error.message, severity: 'error' });
                    }
                })
            } else {
                options.splice(index, 1);
                setPollRoundQuestionDetail(prevState => ({
                    ...prevState,
                    PollQuestionResponses: options
                }))
            }
        } else {
            //To Do
        }
    }

    const handleBlur = (name, value, index) => {
        setErrors(prevState => ({
            ...prevState,
            [index !== undefined ? name + index : name]: ''
        }))
        const error = validate[name](value);
        setErrors(prevState => ({
            ...prevState,
            ...(error && { [index !== undefined ? name + index : name]: touched[index !== undefined ? name + index : name] && error })
        }))
    }

    const validateForm = async () => {
        setErrors({})
        setTouched({})
        let formErrors = { errors: {}, touched: {} };
        Object.entries(pollRoundQuestionDetail).map(([key]) => {
            if (key === 'question' || key === 'threshold') {
                const newError = validate[key](pollRoundQuestionDetail[key]);
                const newTouched = { [key]: true };
                return formErrors = {
                    errors: {
                        ...formErrors.errors,
                        ...(newError && { [key]: newError }),
                    },
                    touched: {
                        ...formErrors.touched,
                        ...newTouched
                    }
                }
            } else if (key === 'PollQuestionResponses') {
                pollRoundQuestionDetail.PollQuestionResponses.map((option, index) => {
                    const newError = validate['option'](option.option);
                    const newTouched = { ['option' + index]: true };
                    return formErrors = {
                        errors: {
                            ...formErrors.errors,
                            ...(newError && { ['option' + index]: newError }),
                        },
                        touched: {
                            ...formErrors.touched,
                            ...newTouched
                        }
                    }
                })
            }
        })
        setErrors(formErrors.errors);
        setTouched(formErrors.touched);

        return formErrors;
    }

    const createPollRoundQuestion = () => {
        setMessage({ showMsg: false, message: '', severity: '' });
        validateForm().then(data => {
            if (Object.values(data.errors).length === 0) {
                setLoading(true);
                setPollRoundQuestionDetail(prevState => ({
                    ...prevState,
                    poll_id: props.pollId
                }))
                let payload = {
                    question: pollRoundQuestionDetail.question,
                    questionDescription: pollRoundQuestionDetail.question_description,
                    // allow_vote_change: pollRoundQuestionDetail.allow_vote_change,
                    // allow_multiple_vote: pollRoundQuestionDetail.allow_multiple_vote,
                    // display_organizer_name: pollRoundQuestionDetail.display_organizer_name,
                    // allow_question_skip: pollRoundQuestionDetail.allow_question_skip,
                    // shuffle_options: pollRoundQuestionDetail.shuffle_options,
                    threshold: pollRoundQuestionDetail.threshold,
                    pollQuestionResponses:
                        pollRoundQuestionDetail.PollQuestionResponses.map(pollQuestionResponses => {
                            return { option: pollQuestionResponses.option }
                        })
                }
                AxiosEvotingInterceptor.post(`poll-question/${props.pollId}`, payload)
                        .then(response => {
                    console.log('response', response);
                    if (response != null) {
                        setMessage({ showMsg: true, message: t('Question_Created_Successfully'), severity: 'success' });
                        setPollRoundQuestionDetail(prevState => ({
                            ...prevState,
                            id: response.id
                        }));
                        if (!props.stepper) {
                            props.setIsAddNew(false);
                        }
                        props.handleCreatePollRoundQuestion(props.index
                            // poll_question_id: pollRoundQuestion.createPollQuestion,
                            // sequence: pollRoundQuestion.createPollQuestion.sequence
                        );
                    }
                    setLoading(false);
                }).catch((error) => {
                    if (error.message) {
                        setMessage({ showMsg: true, message: error.message, severity: 'error' });
                    }
                    setLoading(false);
                })
            }
        })
    }

    const updatePollRoundQuestion = () => {
        setMessage({ showMsg: false, message: '', severity: '' });
        validateForm().then(data => {
            if (Object.values(data.errors).length === 0) {
                setLoading(true);
                let payload = {
                    id: pollRoundQuestionDetail.poll_question_id,
                    question: pollRoundQuestionDetail.question,
                    questionDescription: pollRoundQuestionDetail.question_description,
                    // allow_vote_change: pollRoundQuestionDetail.allow_vote_change,
                    // display_organizer_name: pollRoundQuestionDetail.display_organizer_name,
                    // allow_multiple_vote: pollRoundQuestionDetail.allow_multiple_vote,
                    // allow_question_skip: pollRoundQuestionDetail.allow_question_skip,
                    // shuffle_options: pollRoundQuestionDetail.shuffle_options,
                    threshold: pollRoundQuestionDetail.threshold,
                    pollQuestionResponses:
                        pollRoundQuestionDetail.PollQuestionResponses.map(pollQuestionResponses => {
                            return {
                                id: pollQuestionResponses.poll_question_response_id,
                                option: pollQuestionResponses.option,
                            }
                        })
                }

                AxiosEvotingInterceptor.put(`poll-question/update/${props.pollId}`, payload)
                        .then(response => {
                    console.log('response', response);
                    if (response != null) {
                        setMessage({ showMsg: true, message: t('Question_Updated_Successfully'), severity: 'success' });
                        setPollRoundQuestionDetail(prevState => ({
                            ...prevState,
                            id: response.id,
                        }));
                        if (!props.stepper) {
                            props.setIsAddNew(false);
                        }
                        props.handleCreatePollRoundQuestion(props.index
                            // poll_question_id: pollRoundQuestion.createPollQuestion,
                            // sequence: pollRoundQuestion.createPollQuestion.sequence
                        );
                    }
                    setLoading(false);
                }).catch((error) => {
                    if (error.message) {
                        setMessage({ showMsg: true, message: error.message, severity: 'error' });
                    }
                    setLoading(false);
                })
            }
        })
    }

    const confirmDeletePollQuestion = () => {
        setSeekConfirmation({
            title: t('Confirmation'),
            message: `${t('Are_You_Sure_That_You_Want_To_Delete_The_Poll_Question')} #${props.index + 1}?`,
            show: true,
            agreeBtnLabel: null,
            disAgreeBtnLabel: null,
            onAgree: deletePollRoundQuestion
        })
    }

    const resetSeekConfirmation = () => {
        setSeekConfirmation({
            title: '',
            message: '',
            show: false,
            agreeBtnLabel: null,
            disAgreeBtnLabel: null,
            onAgree: ''
        })
    }

    const deletePollRoundQuestion = () => {
        setMessage({ showMsg: false, message: '', severity: '' });
        props.setIndex(0)
        if (pollRoundQuestionDetail.poll_question_id != '') {
            setLoading(true);
            AxiosEvotingInterceptor.delete(`poll-question/delete/${pollRoundQuestionDetail.poll_id}/${pollRoundQuestionDetail.poll_question_id}`)
            .then(response => {
                if (response) {
                    setMessage({ showMsg: true, message: t('Question_Deleted_Successfully'), severity: 'success' });
                    props.handleDeletePollQuestion(props.index);
                    resetSeekConfirmation();
                }
                setLoading(false);
            })
            .catch((error) => {
                if (error.message) {
                    setMessage({ showMsg: true, message: error.message, severity: 'error' });
                }
                setLoading(false);
            })
        }
    }

    const editPollRoundQuestion = () => {
        setPollRoundQuestionDetail(prevState => ({
            ...prevState,
            previousState: {
                poll_question_id: pollRoundQuestionDetail.poll_question_id,
                // sequence: pollRoundQuestionDetail.sequence,
                poll_id: pollRoundQuestionDetail.poll_id,
                question: pollRoundQuestionDetail.question,
                question_description: pollRoundQuestionDetail.question_description,
                allow_vote_change: pollRoundQuestionDetail.allow_vote_change,
                display_organizer_name: pollRoundQuestionDetail.display_organizer_name,
                allow_multiple_vote: pollRoundQuestionDetail.allow_multiple_vote,
                allow_question_skip: pollRoundQuestionDetail.allow_question_skip,
                shuffle_options: pollRoundQuestionDetail.shuffle_options,
                threshold: pollRoundQuestionDetail.threshold,
                PollQuestionResponses: pollRoundQuestionDetail.PollQuestionResponses.map(pollQuestionResponses => {
                    return {
                        poll_question_response_id: pollQuestionResponses.poll_question_response_id,
                        option: pollQuestionResponses.option,
                        poll_question_id: pollQuestionResponses.poll_question_id,
                        // sequence: pollQuestionResponses.sequence
                    }
                })
            }
        }));
        setIsEdit(true);
    }

    const handleNext = () => {
        props.handleNext();
    }

    const handleCancel = () => {
        if (pollRoundQuestionDetail.poll_question_id != '') {
            setPollRoundQuestionDetail(prevState => ({
                ...prevState,
                poll_question_id: pollRoundQuestionDetail.previousState.poll_question_id,
                // sequence: pollRoundQuestionDetail.previousState.sequence,
                poll_id: pollRoundQuestionDetail.previousState.poll_id,
                question: pollRoundQuestionDetail.previousState.question,
                question_description: pollRoundQuestionDetail.previousState.question_description,
                allow_vote_change: pollRoundQuestionDetail.previousState.allow_vote_change,
                display_organizer_name: pollRoundQuestionDetail.display_organizer_name,
                allow_multiple_vote: pollRoundQuestionDetail.previousState.allow_multiple_vote,
                allow_question_skip: pollRoundQuestionDetail.previousState.allow_question_skip,
                shuffle_options: pollRoundQuestionDetail.previousState.shuffle_options,
                threshold: pollRoundQuestionDetail.previousState.threshold,
                PollQuestionResponses: pollRoundQuestionDetail.previousState.PollQuestionResponses.map(pollQuestionResponses => {
                    return {
                        poll_question_response_id: pollQuestionResponses.poll_question_response_id,
                        option: pollQuestionResponses.option,
                        poll_question_id: pollQuestionResponses.poll_question_id,
                        // sequence: pollQuestionResponses.sequence
                    }
                }),
                previousState: ''
            }));
            setErrors({})
            setTouched({})
            setIsEdit(false);
        } else {
            props.handleCancel(props.index);
        }
    }

    const handleClose = () => {
        props.handleCancel();
    }

    const handleBack = () => {
        props.handleBack();
    }

    return (
        <Card className={classes.stack}>
            <CardContent className={classes.cardContent}>
                <PollQuestionDetail pollRoundQuestionDetail={pollRoundQuestionDetail}
                    handleAddOption={handleAddOption} handleDeleteOption={handleDeleteOption}
                    onJsonInputChange={onJsonInputChange}
                    onJsonInputChangeArray={onJsonInputChangeArray}
                    onInputChange={onInputChange}
                    handleDataBoolean={handleDataBoolean} handleSlider={handleSlider}
                    handleBlur={handleBlur} errors={errors} touched={touched}
                    index={props.index}
                    pollId={props.pollId}
                    status={props.status}
                    createPollRoundQuestion={createPollRoundQuestion}
                    hasPermission={props.hasPermission}
                    updatePollRoundQuestion={updatePollRoundQuestion}
                    editPollRoundQuestion={editPollRoundQuestion}
                    uploadPermission={props.uploadPermission}
                    systemAdmine={props.systemAdmine}
                    handleNext={handleNext}
                    handleClose={handleClose}
                    handleBack={handleBack}
                    handleCancel={handleCancel}
                    confirmDeletePollQuestion={confirmDeletePollQuestion}
                    isEdit={isEdit || (props.isAddNew && pollRoundQuestionDetail.poll_question_id === '')} />
            </CardContent>
            <Divider />
            {/* {(props.hasPermission || props.systemAdmine) && < CardActions className={classes.actions}>
                <Grid item xs={12}>
                    {pollRoundQuestionDetail.poll_question_id === '' && !props.stepper && <Button id="create-poll-round-question" type="submit" size="small" variant="contained" color="primary"
                        className={classes.nextBtn} onClick={createPollRoundQuestion}>
                        {t('Save')}
                    </Button>}
                    {pollRoundQuestionDetail.poll_question_id === '' && props.stepper && <Button id="create-poll-round-question-save" type="submit" size="small" variant="contained" color="primary"
                        className={classes.nextBtn} onClick={createPollRoundQuestion}>
                        {t('Save_&_Next')}
                    </Button>}
                    {isEdit && pollRoundQuestionDetail.poll_question_id !== '' && !props.stepper && <Button id="update-poll-round-question" type="submit" size="small" variant="contained" color="primary"
                        className={classes.nextBtn} onClick={updatePollRoundQuestion}>
                        {t('Update')}
                    </Button>}
                    {!isEdit && pollRoundQuestionDetail.poll_question_id !== '' && !props.stepper && <Button id="edit-poll-round-question"
                        type="submit" size="small" variant="contained" color="primary"
                        className={classes.nextBtn} onClick={editPollRoundQuestion}>
                        {t('Edit')}
                    </Button>}
                    {pollRoundQuestionDetail.poll_question_id !== '' && props.stepper && <Button id="update-poll-round-question-next" type="submit" size="small" variant="contained" color="primary"
                        className={classes.nextBtn} onClick={updatePollRoundQuestion}>
                        {t('Update_&_Next')}
                    </Button>}
                    {pollRoundQuestionDetail.poll_question_id !== '' && props.stepper && <Button id="next" type="submit" size="small" variant="contained" color="primary"
                        className={classes.nextBtn} onClick={handleNext}>
                        {t('Next')}
                    </Button>}
                    {props.pollId !== '' && props.stepper && <Button type="submit" size="small" variant="contained" id='closePage'
                        className={classes.nextBtn} onClick={handleClose}>
                        {t('Close')}
                    </Button>
                    }
                    {(isEdit || (props.isAddNew && pollRoundQuestionDetail.poll_question_id === '')) && props.pollId != '' && !props.stepper && <Button id='cancelPage' type="submit" size="small" variant="outlined" color='primary'
                        className={classes.nextBtn} onClick={handleCancel}>
                        {t('Cancel')}
                    </Button>
                    }
                    {props.stepper && <Button id="back" type="submit" size="small" variant="contained"
                        className={classes.backBtn} onClick={handleBack} >
                        {t('Back')}
                    </Button>}
                    {pollRoundQuestionDetail.poll_question_id !== '' && !props.stepper && <Button id='deletePoll' type="submit" size="small" variant="contained" color="secondary"
                        className={classes.backBtn} onClick={confirmDeletePollQuestion} >
                        {t('Delete')}
                    </Button>}
                </Grid>
            </CardActions>} */}
            {loading && <EligoBackdrop show={loading} />}
            {
                seekConfirmation.show && <EligoConfirmDialog show={seekConfirmation.show} title={seekConfirmation.title}
                    message={seekConfirmation.message} onAgree={seekConfirmation.onAgree} onDisAgree={resetSeekConfirmation} />
            }
            {message.showMsg && <EligoSnackBar show={message.showMsg} message={message.message}
                severity={message.severity}></EligoSnackBar>}
        </Card >
    )
}

export default AddPollQuestion;
