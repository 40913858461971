import React, {
    useState
} from 'react';
import {
    makeStyles,
    Box,
    Typography,
    Tabs,
    Tab,
    useTheme,
    AppBar,
    Grid
} from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { useTranslation } from 'react-i18next';
import PollRoundVoterList from '../poll-round-voter-list/PollRoundVoterList';

const useStyles = makeStyles(() => ({
    voterTab: {
        textTransform: 'none !important',
        fontWeight: 'bold !important'
    }
}))

function TabPanel(props) {
    const { children, value, index } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const VotersTab = (props) => {
    const [activeTab, setActiveTab] = useState(0);
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();

    const tabs = [
        { value: 'all', labelName: t('All') },
        { value: 'voted', labelName: t('Voted') },
        { value: 'not-voted', labelName: t('UnVoted') },
    ]

    const handleTabChange = (event, newValue) => {
        if (newValue != null) {
            setActiveTab(newValue);
        }
    };

    const handleTabChangeIndex = (index) => {
        setActiveTab(index);
    };

    return (
        <div>
            <Grid container style={{ padding: '8px' }}>
                <Grid item xs={12}>
                    <AppBar position="static" color="default">
                        <Tabs
                            value={activeTab}
                            onChange={handleTabChange}
                            textColor="primary"
                            indicatorColor="primary"
                            aria-label="voter details tab"
                        >
                            {
                                tabs.map((tab, index) => {
                                    return <Tab className={classes.voterTab} label={tab.labelName}
                                        id={`voter-list-${index}`} aria-controls={`voter-list-${index}`} />
                                })
                            }
                        </Tabs>
                    </AppBar>
                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={activeTab}
                        onChangeIndex={handleTabChangeIndex}
                    >
                        <TabPanel value={activeTab} index={0} dir={theme.direction}>
                            <PollRoundVoterList key="all-voters" pollId={props.pollId}
                                pollDetails={props.pollDetails}
                                hasPermission={props.hasPermission}
                                pollStatus={props.pollStatus}
                                paidVoter={props.paidVoter}
                                paymentStatus={props.paymentStatus}
                                pollType={props.pollType}
                                setMessage={props.setMessage}
                                hasSystemAdmineOrSupportRole={props.hasSystemAdmineOrSupportRole}
                                setNoOfVoters={props.setNoOfVoters}
                                canAddAfterPollOpen={props.canAddAfterPollOpen}
                                listBy={tabs[activeTab].value} 
                                pollClass={props.pollClass}/>
                        </TabPanel>
                        <TabPanel value={activeTab} index={1} dir={theme.direction}>
                            <PollRoundVoterList key="voted-voters" pollId={props.pollId}
                                pollDetails={props.pollDetails}
                                hasPermission={props.hasPermission}
                                pollStatus={props.pollStatus}
                                paidVoter={props.paidVoter}
                                paymentStatus={props.paymentStatus}
                                pollType={props.pollType}
                                setMessage={props.setMessage}
                                hasSystemAdmineOrSupportRole={props.hasSystemAdmineOrSupportRole}
                                setNoOfVoters={props.setNoOfVoters}
                                canAddAfterPollOpen={props.canAddAfterPollOpen}
                                listBy={tabs[activeTab].value} 
                                pollClass={props.pollClass}/>
                        </TabPanel>
                        <TabPanel value={activeTab} index={2} dir={theme.direction}>
                            <PollRoundVoterList key="not-voted-voters" pollId={props.pollId}
                                pollDetails={props.pollDetails}
                                hasPermission={props.hasPermission}
                                pollStatus={props.pollStatus}
                                paidVoter={props.paidVoter}
                                paymentStatus={props.paymentStatus}
                                pollType={props.pollType}
                                hasSystemAdmineOrSupportRole={props.hasSystemAdmineOrSupportRole}
                                setMessage={props.setMessage}
                                setNoOfVoters={props.setNoOfVoters}
                                canAddAfterPollOpen={props.canAddAfterPollOpen}
                                listBy={tabs[activeTab].value} 
                                pollClass={props.pollClass}/>
                        </TabPanel>
                    </SwipeableViews>
                </Grid>
            </Grid>
        </div>
    )
}

export default VotersTab;