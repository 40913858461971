import React from "react";
import { UserContext } from "../context/UserContext";
import { Redirect } from 'react-router-dom';

export function requireAuthentication(Component, isOTPLogin) {
    return class AuthenticatedComponent extends React.Component {
        static contextType = UserContext;

        handleRender = isAuthenticated => {
            if (!isAuthenticated) {
                return <Redirect to="/sign-in" />
            } else {
                return <Component {...this.props} />
            }
        }

        render() {
            const context = this.context;
            return (
                isOTPLogin ? <div> <Component {...this.props} /> </div> : <div>{this.handleRender(context.userSession.isAuthenticated)}</div>
            );
        }
    };
}

export default requireAuthentication;