import { Divider, makeStyles } from '@material-ui/core'
import { BallotOutlined, FormatListBulletedRounded } from '@material-ui/icons'
import { Button, Slide, Stack, Typography } from '@mui/material'
import React from 'react'
import { BsFillFileEarmarkTextFill } from 'react-icons/bs'
import { FaChartPie } from 'react-icons/fa'
import { IoMdArrowRoundBack } from "react-icons/io"
import { MdGroups, MdManageAccounts } from 'react-icons/md'
import { RiSecurePaymentFill } from "react-icons/ri"
import ForumIcon from '@material-ui/icons/Forum';
import { MenuBar, MenuListIcon, MenuListItem, MenuListText, SideNavBar, SideNavHeader } from '../../../nav-bar/side-nav/side-nav-style/SideNavStyle';
import SettingsIcon from '@material-ui/icons/Settings';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { TypeOfPoll } from '../../../common/GenericCodes'
import { useTranslation } from 'react-i18next'

export const PollInfoMenu = {
    POLL_DETAILS: 'polldetails',
    MATTERS: 'matters',
    VOTERS: 'voters',
    RESULT: 'result',
    FILES: 'files',
    PAYMENT: 'payment',
    ROLES: 'roles',
    AUDIT_TRAIL: 'audit',
    AUDIT_SMS_EMAIL: 'audit-sms-email',
    SETTINGS: 'settings'
    // AUDIT_TRAIL: 'audit'
}

const useStyles = makeStyles((theme) => ({
    menu: {
        backgroundColor: `${theme.palette.primary.main} !important`,
        '& .icon': {
            color: 'rgb(249 241 241) !important'
        },
        '& .text': {
            color: 'rgb(249 241 241) !important'
        },
    },

}))

const PollInfoSideBar = props => {
    const { width, setSelectedMenu, selectedMenu, backToPollRounds, showResult, showPayment, questionTitle, showRoles,
        pollClass } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const pollInfoMenuItems = [
        { id: 'pollDetail', title: pollClass === TypeOfPoll.POLL ? t('Poll_Details') : t('Survey_Details'), menuInfo: PollInfoMenu.POLL_DETAILS, icon: <BallotOutlined style={{ fontSize: '1.5rem' }} />, show: true },
        { id: 'matter', title: pollClass === TypeOfPoll.POLL ? questionTitle : t('Survey_Questions'), menuInfo: PollInfoMenu.MATTERS, icon: <FormatListBulletedRounded style={{ fontSize: '1.5rem' }} />, show: true },
        { id: 'voter', title: pollClass === TypeOfPoll.POLL ? t('Voters') : t('Survey_Participants'), menuInfo: PollInfoMenu.VOTERS, icon: <MdGroups style={{ fontSize: '1.5rem' }} />, show: true },
        { id: 'file', title: 'Files', menuInfo: PollInfoMenu.FILES, icon: <BsFillFileEarmarkTextFill style={{ fontSize: '1.5rem', height: '1.25rem' }} />, show: true },
        // { id: 'payment', title: 'Payment', menuInfo: PollInfoMenu.PAYMENT, icon: <RiSecurePaymentFill style={{ fontSize: '1.5rem' }} />, show: showPayment },
        { id: 'role', title: 'Roles', menuInfo: PollInfoMenu.ROLES, icon: <MdManageAccounts style={{ fontSize: '1.5rem' }} />, show: showRoles },
        { id: 'communication', title: 'Communications', menuInfo: PollInfoMenu.AUDIT_SMS_EMAIL, icon: <ForumIcon style={{ fontSize: '1.5rem' }} />, show: true },
        { id: 'result', title: 'Result', menuInfo: PollInfoMenu.RESULT, icon: <FaChartPie style={{ fontSize: '1.5rem' }} />, show: showResult },
        { title: 'Audit Trail', menuInfo: PollInfoMenu.AUDIT_TRAIL, icon: <DateRangeIcon style={{ fontSize: '1.5rem' }} />, show: true },

        // { id: 'settings', title: 'Settings', menuInfo: PollInfoMenu.SETTINGS, icon: <SettingsIcon style={{ fontSize: '1.5rem' }} />, show: true }
        // { title: 'Audit Trail',menuInfo: PollInfoMenu.AUDIT_TRAIL, icon: <FaChartPie style={{ fontSize: '1.5rem' }} />, show: true }

    ];

    return (
        <Slide direction="right" in={true} timeout={500} mountOnEnter unmountOnExit>
            <SideNavBar width={width}>
                <SideNavHeader>
                    <Button id='poll-info' aria-label="back-to-poll" color="primary" size="large" sx={{ m: '0.5rem 0rem', textTransform: 'none', width: '100%' }}
                        onClick={() => backToPollRounds(null)}>
                        <IoMdArrowRoundBack style={{ fontSize: 'larger' }} />&nbsp; Back to Polls
                    </Button>
                    {/* <MenuSvgIcon component={KeyboardArrowRightRounded} style={{ top: '58px' }} /> */}
                </SideNavHeader>
                <Divider />
                <MenuBar style={{ marginTop: '0px' }}>
                    {pollInfoMenuItems.map(item => (
                        <>
                            {item.show &&
                                // <Tooltip title={item.title} arrow>
                                <MenuListItem button id={item.id} className={selectedMenu === item.menuInfo ? classes.menu : ''}
                                    onClick={() => setSelectedMenu(item.menuInfo)}>
                                    <Stack direction='row' margin='0px' justifyContent='center' alignItems='center' style={{ gap: '1.3rem' }}>
                                        <MenuListIcon className='icon'>
                                            {item.icon}
                                        </MenuListIcon>
                                        <MenuListText className='text'>
                                            <Typography >{item.title}</Typography>
                                        </MenuListText>
                                    </Stack>
                                </MenuListItem>
                            }
                        </>
                    ))}
                </MenuBar>
            </SideNavBar>
        </Slide>
    )
}

export default PollInfoSideBar
