import React, { useState, useContext, useEffect } from 'react';
import { Grid, 
    TextField, 
    InputAdornment, 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle,
    FormControl,
    InputLabel,
    Select,
    MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { UserContext } from '../../common/context/UserContext';
import GraphQLErrors from '../../common/GraphQLErrors';
import EligoBackdrop from '../../common/EligoBackdrop';
import EligoSnackBar from '../../common/EligoSnackBar'
import axios from 'axios';

const RecordPayment = (props) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(props.show);
    const context = useContext(UserContext);
    const [message, setMessage] = React.useState({
        showMsg: false,
        message: null,
        severity: null
    });
    const [payment, setPayment] = useState({
        id: '',
        payment_date: new Date(),
        payment_ref: '',
        mode_of_payment: '',
        no_of_voters: 0,
        service_charge: 0,
        amount_paid: 0,
        tax: 0,
        balance_amount: 0
    });

    // const [recordOfflinePayment, { loading: recordingPayment, error: errorOnRecordingPayment }] = useMutation(RECORD_OFFLINE_PAYMENT, {
    //     errorPolicy: 'all',
    //     onCompleted: (data) => {
    //         console.log(data);
    //         if (data.recordOfflinePayment) {
    //             props.close(false);
    //             downloadInvoice(data.recordOfflinePayment);
    //             setOpen(false);
    //         }
    //     },
    //     refetchQueries: [ LIST_OFFLINE_PAYMENTS ]
    // });

    const handleDisAgree = () => {
        props.close(false)
        setOpen(false);
    };

    const onInputChange = (value, fieldName, isAmount) => {
        setPayment(prevState => ({
            ...prevState,
            [fieldName]: isAmount ? Number(value).toFixed(2) : value
        }))
    }

    const calculateTax = (amount) => {
        setPayment(prevState => ({
            ...prevState,
            tax: (amount / 100) * 18
        }))
    }

    useEffect(() => {
        setPayment(prevState => ({
            ...prevState,
            balance_amount: ((Number(payment.service_charge) + Number(payment.tax)) - Number(payment.amount_paid)).toFixed(2)
        }))
    }, [payment.tax, payment.amount_paid])
    

    const save = () => {
        setMessage({showMsg: false, message: null, severity: null})
        if (Number(payment.amount_paid) > Number(payment.service_charge) + Number(payment.tax)) {
            setTimeout(() => {
                setMessage({showMsg: true, message: t('Paid_Amount_Cannot_Be_Greater_Than_Total_Invoice_Amount'), severity: 'error'});
            }, 100);
        } else {
            // recordOfflinePayment({
            //     variables: {
            //         data: {
            //             poll_id: props.poll_id,
            //             mode_of_payment: payment.mode_of_payment, 
            //             no_of_voters: payment.no_of_voters, 
            //             service_charge: payment.service_charge, 
            //             amount_paid: payment.amount_paid,
            //             payment_date: payment.payment_date, 
            //             payment_reference_number: payment.payment_ref,
            //             tax: payment.tax
            //         }
            //     }
            // })
        }
    }

    const downloadInvoice = (invoiceUrl) => {
        axios({
            url: invoiceUrl,
            method:'GET',
            responseType: 'blob'
        }).then((response) => {
            const url = window.URL .createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Invoice.pdf');
            document.body.appendChild(link);
            link.click();
        })
    }

    return (
        <div className='loading-container'>
            <Dialog open={open} aria-labelledby="record-payment-model-title"
                    aria-describedby="record-payment-model-description">
                <DialogTitle id="record-payment-model-title">{t('Record_Payment')}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    fullWidth
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    id="payment_date"
                                    label={t("Payment_Date")}
                                    value={payment.payment_date}
                                    onChange={(event) => onInputChange(event, 'payment_date', false)}
                                    required
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="mode-of-payment-label">{t("Mode_of_Payment")}</InputLabel>
                                <Select
                                    labelId="mode-of-payment-label"
                                    id="mode_of_payment"
                                    value={payment.mode_of_payment}
                                    onChange={(event) => onInputChange(event.target.value, "mode_of_payment", false)}
                                >
                                    <MenuItem value={'Bank Transfer'}>Bank Transfer</MenuItem>
                                    <MenuItem value={'Online'}>Online</MenuItem>
                                    <MenuItem value={'Cash'}>Cash</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField id="payment_ref" label={t("Payment_Reference")}
                                value={payment.payment_ref} 
                                onChange={(event) => onInputChange(event.target.value, "payment_ref", false)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <NumberFormat id="no_of_voters" label={t("No_of_Voter")} 
                                value={payment.no_of_voters} 
                                customInput={TextField} 
                                thousandSeparator={true} 
                                onValueChange={({ value }) => {
                                    let voters = value ? value : 1;
                                    onInputChange(voters, 'no_of_voters', false)
                                }}
                                thousandsGroupStyle="lakh"
                                allowNegative={false}
                                required 
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <NumberFormat id="service_charge" label={t("Service_Charge")} 
                                value={payment.service_charge} 
                                customInput={TextField} 
                                thousandSeparator={true} 
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">{t('Rs')}</InputAdornment>,
                                }}
                                onValueChange={({ value }) => {
                                    let amount = value ? value : 0;
                                    onInputChange(amount, 'service_charge', true)
                                }}
                                onBlur={() => calculateTax(payment.service_charge)}
                                thousandsGroupStyle="lakh"
                                allowNegative={false}
                                required 
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <NumberFormat id="tax" label={t("Tax")} 
                                value={payment.tax} 
                                customInput={TextField} 
                                thousandSeparator={true} 
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">{t('Rs')}</InputAdornment>,
                                }}
                                onValueChange={({ value }) => {
                                    onInputChange(value, 'tax', true)
                                }}
                                thousandsGroupStyle="lakh"
                                allowNegative={false}
                                required 
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <NumberFormat id="amount_paid" label={t("Amount_Paid")} 
                                value={payment.amount_paid} 
                                customInput={TextField} 
                                thousandSeparator={true} 
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">{t('Rs')}</InputAdornment>,
                                }}
                                onValueChange={({ value }) => {
                                    let amount = value ? value : 0;
                                    onInputChange(amount, 'amount_paid', true)
                                }}
                                thousandsGroupStyle="lakh"
                                allowNegative={false}
                                required 
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <NumberFormat id="balance_amount" label={t("Balance_Amount")} 
                                value={payment.balance_amount} 
                                customInput={TextField} 
                                thousandSeparator={true} 
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">{t('Rs')}</InputAdornment>,
                                }}
                                thousandsGroupStyle="lakh"
                                allowNegative={false}
                                required 
                                fullWidth
                                disabled
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDisAgree} color="default" variant='contained'>
                        {t('Cancel')}
                    </Button>
                    <Button onClick={save} color="primary" autoFocus variant='contained'>
                        {t('Save')}
                    </Button>
                </DialogActions>
                {/* {errorOnRecordingPayment && <GraphQLErrors error={errorOnRecordingPayment} show={false} />} */}
                {/* {recordingPayment && <EligoBackdrop show={recordingPayment} />} */}
                {message.showMsg && <EligoSnackBar show={message.showMsg} message={message.message}
                severity={message.severity} ></EligoSnackBar>}
            </Dialog>
        </div>
    )
}

export default RecordPayment