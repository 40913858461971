import React , {
    useState,
    useEffect
}                                           from 'react';
import {
    Typography,
    TextField,
    CircularProgress
}                                           from '@material-ui/core';
import Autocomplete                         from '@material-ui/lab/Autocomplete';
import { ToggleButton, ToggleButtonGroup }  from '@mui/material';
import { useTranslation }                   from 'react-i18next';
import { AxiosEvotingInterceptor }          from '../../config/axios.interceptor'; 

const SearchUser = (props) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [searchFilter, setSearchFilter] = useState('');
    const [options, setOptions] = useState([]);
    const [selectedUser, setSelectedUser] = useState('');
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState('name')

    const executeSearch = () => {
        let payload = {
            searchBy: value,
            searchText: searchFilter.trim(),
            loginUser: props.registeredUsers,
            individual: props.partyType
        }
        AxiosEvotingInterceptor.post(`search/user`, payload).then(response => {
            setOptions(Object.keys(response).map((key) => {
                return {
                    id: response[key].id,
                    name: response[key].name,
                    phone_number: response[key].phone_number,
                    email: response[key].email
                }
            }));
            setLoading(false)
        }).catch(error => {
            console.log("ERRE", error)

        })
    }

    useEffect(() => {
        if (searchFilter != '' && open) {
            setLoading(true)
            executeSearch()
        }
    }, [searchFilter]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    useEffect(() => {
        if (selectedUser != '' && selectedUser != null) {
            setSearchFilter(selectedUser.name);
            props.onSelect(selectedUser);
        }
    }, [selectedUser]);

    const handleClick = (event, newValue) => {
        setValue(newValue === null ? value : newValue);
        setSearchFilter('');
        props.onSelect('');
    };

    return (
        <div>
            {/* <ToggleButtonGroup
                size="small"
                color='primary'
                value={value}
                style={{ float: 'right' }}
                exclusive
                onChange={handleClick}
                aria-label="text value"
            >
                <ToggleButton value="email" aria-label="left aligned" >
                    <Typography>email</Typography>
                </ToggleButton>
                <ToggleButton value="name" aria-label="centered">
                    <Typography>name</Typography>
                </ToggleButton>
            </ToggleButtonGroup> */}
            <Autocomplete
                id="search-user"
                open={open}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                fullWidth={props.fullWidth}
                style={{ marginTop: props.marginTop ? props.marginTop : '' }}
                disableClearable={true}
                onChange={(event, data) => {
                    setSelectedUser(data);
                }}
                getOptionLabel={(option) => option.name + ' (' + option.email + ') '}
                options={options}
                loading={loading}
                inputValue={searchFilter}
                disabled={props.disable ? props.disable : false}
                renderInput={(params) => (
                    <TextField {...params}
                        onChange={(e) => setSearchFilter(e.target.value)}
                        required={props.required}
                        value={searchFilter}
                        style={{ width: props.width ? props.width : '' }}
                        error={props.error}
                        placeholder={props.placeholderForAdmine ? t("Search_By_Name_For_Existing_Users") : t('Search')}
                        onBlur={(e) => props.setName ? props.setName(e.target.value) : {}}
                        helperText={props.helperText ? props.helperText : value === 'email' ? t("Search_By_Email_For_Existing_Users") : !props.placeholderForAdmine &&
                        t("Search_By_Name_For_Existing_Users")}
                        // label={props.label ? props.label : t("User")}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {(loading) ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                            startAdornment: (
                                props.InputProps
                            )
                        }}
                    />
                )}
            />
        </div>
    )
}

export default SearchUser;