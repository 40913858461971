import { Business, Clear, Close, Done, Edit, InfoRounded, Person } from '@material-ui/icons';
import {
    Box, Button,
    Dialog, DialogActions, DialogContent, DialogTitle,
    Divider, Fab, Grid, Radio, Stack, TextField,
    Tooltip, Typography
} from '@mui/material';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EligoSnackBar from '../../../common/EligoSnackBar';
import * as Validators from '../../../common/form-validators';
import PartySearch from '../../../common/party-search/PartySearch';
import { AxiosEvotingInterceptor } from '../../../config/axios.interceptor';
import AddressDetails from '../../address/AddressDetails';
import Addresses from '../../address/Addresses';
import { ClientFields, IdentificationTypes } from '../ClientStaticVar';
import { getEmail, getIdentificationByType, getPhoneNumber } from '../Clients';
import { AuctionFieldSet, AuctionLengend, RadioCard, useStyles } from '../client-style/ClientStyle';
import ClientEmail from './ClientEmail';
import ClientIdentification from './ClientIdentification';
import ClientPhone from './ClientPhone';
import { PartyFieldSearch } from './party-field-search/PartyFieldSearch';
import EligoBackdrop from '../../../common/EligoBackdrop';

const ClientDetails = (props) => {
    const { open, handleClose, isNew, client, setListMessage, handleCancel } = props;
    const { t } = useTranslation();
    const classes = useStyles();
    const [errors, setErrors] = useState({});
    const [addressErrors, setAddressErrors] = useState({});
    const [touched, setTouched] = useState({});
    // const context = useContext(UserContext)
    const [isEditGstID, setIsEditGstID] = useState(false);
    const [isEditPanID, setIsEditPanID] = useState(false);
    const [isEditEmail, setIsEditEmail] = useState(false);
    const [isEditPhone, setIsEditPhone] = useState(false);
    const [isEditClientDetails, setIsEditClientDetails] = useState(false);
    const [addressTrigger, setAddressTrigger] = useState(1);
    const [loading, setLoading] = useState(props.loading);
    const [message, setMessage] = useState({
        showMsg: false,
        message: null,
        severity: null
    })
    const [disableOnAutoFill, setDisableOnAutoFill] = useState(false);
    const [tempClientDetails, setTempClientDetails] = useState(
        {
            name: '',
            individual: '',
            personId: '',
            identification: [],
            email: [],
            phoneNumber: [],
        }
    );
    const [clientDetails, setClientDetails] = useState(client);
    const [selectedValue, setSelectedValue] = useState(client.individual);
    const [userDetail, setUserDetail] = useState(null);
    const [isButtonClicked, setIsButtonClicked] = useState(false);
    const [isClientFocused, setIsClientFocused] = useState(false);

    const validate = {
        attention: attention => Validators.requiredValidation('Name', attention),
        addressLine1: addressLine1 => Validators.requiredValidation('Address Line 1', addressLine1),
        country: country => Validators.requiredValidation('Country', country),
        postalCode: postalCode => (Validators.requiredValidation('Pincode', postalCode) || Validators.postalCodeValidation('postalCode', postalCode, 6)),
        state: state => Validators.requiredValidation(t('State'), state),
        cityTown: cityTown => (Validators.requiredValidation(t('Town/City/Taluk'), cityTown) || Validators.cityTownValidation('Town/City/Taluk', cityTown, 50)),
        phone: Validators.addressMobileNumberValidation
    };

    const onInputChange = (event) => {
        setIsClientFocused(true);
        let value = event.target.value;
        setClientDetails(prevState => ({
            ...prevState,
            [event.target.id]: value
        }))
        setErrors(prevState => ({
            ...prevState,
            [event.target.id]: Validators.requiredValidation(t('Client_Name'), value)
        }))
    }

    const validateInputOnChange = (field, value, id) => {
        setIsClientFocused(true);
        let error = '';
        if (value !== '' || (id !== '' && id !== null)) {
            switch (field) {
                case ClientFields.email:
                    if (value === '') {
                        break
                    }
                    error = Validators.emailValidation(value);
                    break;
                case ClientFields.phoneNumber:
                    error = Validators.mobileNumberValidation(value);
                    break;
                case ClientFields.identificationGst:
                    error = Validators.gstValidation(value);
                    break;
                case ClientFields.identificationPan:
                    error = Validators.panValidation(value);
                    break;
                default: break;
            }
            setErrors(prevState => ({
                ...prevState,
                [field]: error
            }))
        }
        else
            setErrors(prevState => ({
                ...prevState,
                [field]: ''
            }))
    }

    const resetOtherEdits = (field) => {
        switch (field) {
            case ClientFields.email:
                setIsEditClientDetails(false);
                setIsEditPhone(false);
                setIsEditGstID(false);
                setIsEditPanID(false);
                break;
            case ClientFields.phoneNumber:
                setIsEditClientDetails(false);
                setIsEditEmail(false);
                setIsEditGstID(false);
                setIsEditPanID(false);
                break;
            case ClientFields.identificationGst:
                setIsEditClientDetails(false);
                setIsEditPhone(false);
                setIsEditEmail(false);
                setIsEditPanID(false);
                break;
            case ClientFields.identificationPan:
                setIsEditClientDetails(false);
                setIsEditPhone(false);
                setIsEditGstID(false);
                setIsEditEmail(false);
                break;
            default: break;
        }
    }

    const setEditClientDetails = () => {
        setTempClientDetails({
            name: clientDetails.name,
            individual: clientDetails.individual,
            personId: clientDetails.personId,
            email: {
                id: clientDetails.email.id,
                email: clientDetails.email.email,
                previousEmail: clientDetails.email.email
            },
            phoneNumber: {
                id: clientDetails.phoneNumber.id,
                phoneNumber: clientDetails.phoneNumber.phoneNumber,
                previousPhoneNumber: clientDetails.phoneNumber.phoneNumber
            },
            identificationGst: clientDetails.identificationGst,
            identificationPan: clientDetails.identificationPan,
        })
        setIsEditClientDetails(true);
    }

    const cancelEditClientDetails = () => {
        setClientDetails(prevState => ({
            ...prevState,
            name: tempClientDetails.name,
            individual: tempClientDetails.individual,
            personId: tempClientDetails.personId,
            // identification: clientDetails.email.previousEmail,
            email: {
                id: tempClientDetails.email.id,
                email: tempClientDetails.email.email,
                previousEmail: tempClientDetails.email.email
            },
            phoneNumber: {
                id: tempClientDetails.phoneNumber.id,
                phoneNumber: tempClientDetails.phoneNumber.phoneNumber,
                previousPhoneNumber: tempClientDetails.phoneNumber.phoneNumber
            },
            identificationGst: tempClientDetails.identificationGst,
            identificationPan: tempClientDetails.identificationPan,
            // phoneNumber: tempClientDetails.phoneNumber,
        }))
        setIsEditClientDetails(false);
        setErrors({});
    }


    const validateIDAndUpdate = () => {
        setMessage({ showMsg: false, message: null, severity: null })
        setErrors({})
        if ((clientDetails.identificationPan.identification === '' && clientDetails.identificationGst.identification === '')) {
            setLoading(true);
            setMessage({ showMsg: false, message: '', severity: '' });
            setTimeout(() => {
                setMessage({ showMsg: true, message: t('GSTIN_Or_PAN_Is_Mandatory'), severity: 'error' });
                setLoading(false);
            }, 100)
        }
        else {
            updateClient();
        }
    }

    const updateClientDetails = async () => {
        validateIDAndUpdate()
        // updateClient();
    }

    const createClient = (identifications, clientDetails) => {
        setLoading(true);
        setMessage({ showMsg: false, message: null, severity: null })
        let payload = {
            name: clientDetails.name,
            personId: clientDetails.personId,
            individual: clientDetails.individual,
            identification: identifications,
            email: clientDetails.email.email.trim() !== '' ? [clientDetails.email] : [],
            phoneNumber: clientDetails.phoneNumber.phoneNumber.trim() !== '' ? [clientDetails.phoneNumber] : [],
            address: [
                {
                    sourceId: null,
                    addressLine1: clientDetails.address.addressLine1,
                    addressLine2: clientDetails.address.addressLine2,
                    phone: clientDetails.address.phone,
                    landmark: clientDetails.address.landmark,
                    addressType: clientDetails.address.addressType,
                    cityTown: clientDetails.address.cityTown,
                    postalCode: clientDetails.address.postalCode,
                    state: clientDetails.address.state,
                    country: clientDetails.address.country,
                    phoneNumber: clientDetails.address.phoneNumber,
                    attention: clientDetails.address.attention,
                }
            ]
        }
        AxiosEvotingInterceptor.post(`client`, payload).then(response => {
            if (response) {
                handleClose();
                setListMessage({ showMsg: true, message: t('Added_Client_Successfully'), severity: 'success' });
            }
            resetButtonInputActions();
            setLoading(false);
        }).catch(error => {
            if (error.message) {
                setMessage({ showMsg: true, message: error.message, severity: 'error' })
            } else {
                setMessage({ showMsg: true, message: t('Try_Again'), severity: 'error' })
            }
            resetButtonInputActions();
            setLoading(false);
        })

    }

    const getIDsToUpdate = () => {
        let identifications = [];
        if (clientDetails.identificationGst.identification !== '') {
            identifications.push({
                identification: clientDetails.identificationGst.identification,
                identificationType: clientDetails.identificationGst.identificationType,
                id: (clientDetails.identificationGst.id !== '') ? clientDetails.identificationGst.id : null,
                otherIdentification: null
            })
        }
        if (clientDetails.identificationPan.identification !== '') {
            identifications.push({
                identification: clientDetails.identificationPan.identification,
                identificationType: clientDetails.identificationPan.identificationType,
                id: (clientDetails.identificationPan.id !== '') ? clientDetails.identificationPan.id : null,
                otherIdentification: null
            })
        }
        return identifications;
    }

    const updateClient = () => {
        setLoading(true);
        setMessage({ showMsg: false, message: null, severity: null })
        let identifications = getIDsToUpdate();
        let payload = {
            personId: clientDetails.personId,
            name: clientDetails.name,
            individual: clientDetails.individual,
            identification: identifications,
            email: clientDetails.email.email.trim() !== '' ? [clientDetails.email] : [],
            phoneNumber: clientDetails.phoneNumber.phoneNumber.trim() !== '' ? [clientDetails.phoneNumber] : [],
            address: [
                {
                    sourceId: null,
                    addressLine1: clientDetails.address.addressLine1,
                    addressLine2: clientDetails.address.addressLine2,
                    phone: clientDetails.address.phone,
                    landmark: clientDetails.address.landmark,
                    addressType: clientDetails.address.addressType,
                    cityTown: clientDetails.address.cityTown,
                    postalCode: clientDetails.address.postalCode,
                    state: clientDetails.address.state,
                    country: clientDetails.address.country,
                    phoneNumber: clientDetails.address.phoneNumber,
                    attention: clientDetails.address.attention,
                }
            ]
        }
        AxiosEvotingInterceptor.put(`client/update`, payload).then(response => {
            if (response) {
                setIsEditClientDetails(false);
                setMessage({ showMsg: true, message: t('Updated_Client_Details_Successfully'), severity: 'success' });
            }
            setLoading(false);
        }).catch(error => {
            if (error.message) {
                setMessage({ showMsg: true, message: error.message, severity: 'error' })
            } else {
                setMessage({ showMsg: true, message: t('Try_Again'), severity: 'error' })
            }
            setLoading(false);
        })
    }

    const getIDsToSave = (clientDetail) => {
        let identifications = [];
        if (clientDetail.identificationGst.identification !== '') {
            identifications.push({
                identification: clientDetail.identificationGst.identification,
                identificationType: clientDetail.identificationGst.identificationType,
                id: clientDetail.identificationGst.id,
                otherIdentification: null
            })
        }
        if (clientDetail.identificationPan.identification !== '') {
            identifications.push({
                identification: clientDetail.identificationPan.identification,
                identificationType: clientDetail.identificationPan.identificationType,
                id: clientDetail.identificationPan.id,
                otherIdentification: null
            })
        }
        return identifications;
    }

    const handleChange = (fieldName, type) => {
        setSelectedValue(type);
        setClientDetails(prevState => ({
            ...prevState,
            [fieldName]: type
        }))
    }

    const setJsonData = (event) => {
        setClientDetails(prevState => ({
            ...prevState,
            address: {
                ...prevState.address,
                [event.target.id]: event.target.value
            }
        }))
    }
    const setJsonCountryStateCityData = (fieldName, value) => {
        setClientDetails(prevState => ({
            ...prevState,
            address: {
                ...prevState.address,
                [fieldName]: value
            }
        }))
    }

    const setPhoneNumber = (fieldName, event) => {
        setClientDetails(prevState => ({
            ...prevState,
            address: {
                ...prevState.address,
                [fieldName]: event
            }
        }))
    }

    const checkErrorsOnCreate = () => {
        let valid = true;
        if (Object.values(errors).length > 0) {
            Object.entries(errors).map(([key, value]) => {
                if ((key === 'name' || key === 'email' || key === 'phoneNumber') && value !== null && value !== '') {
                    valid = false;
                }
            })
        }
        if (clientDetails.name === '') {
            setErrors(prevState => ({
                ...prevState,
                name: Validators.requiredValidation(t('Client_Name'), clientDetails.name)
            }))
            valid = false;
        }
        let formErrors = { errors: {} }
        setAddressErrors({});
        Object.entries(clientDetails.address).map(([key]) => {
            if (key === 'addressLine1' || key === 'country' || key === 'postalCode' || key === 'state'
                || key === 'cityTown') {
                const newError = validate[key](clientDetails.address[key])
                const newTouched = { [key]: true };
                formErrors = {
                    errors: {
                        ...formErrors.errors,
                        ...(newError && { [key]: newError }),
                    },
                    touched: {
                        ...formErrors.touched,
                        ...newTouched
                    }
                }
            }
        })
        setAddressErrors(prevState => ({
            ...prevState,
            ...formErrors.errors
        }));
        setTouched(prevState => ({
            ...prevState,
            ...formErrors.touched
        }));
        if (Object.values(formErrors.errors).length !== 0) {
            return valid = false;
        }
        return valid;
    }

    const onMouseDown = (e) => {
        setIsButtonClicked(true);
        if (!isClientFocused) {
            save(clientDetails);
        }
    }

    const handleSave = async (e) => {
        e.preventDefault();
        setIsButtonClicked(true);
        save(clientDetails);
    }

    const save = (clientDetail) => {
        setMessage({ showMsg: false, message: '', severity: '' })
        let identifications = getIDsToSave(clientDetail);
        if (identifications.length > 0) {
            let valid = checkErrorsOnCreate();
            if (valid) {
                createClient(identifications, clientDetail);
            } else {
                resetButtonInputActions();
            }
        }
        else {
            resetButtonInputActions();
            setMessage({ showMsg: true, message: t('GSTIN_Or_PAN_Is_Mandatory'), severity: 'error' })
        }
    }

    const handleResetOnClose = () => {
        setMessage({ showMsg: false, message: '', severity: '' })
    }

    const resetButtonInputActions = () => {
        setIsButtonClicked(false);
        setIsClientFocused(false);
    }

    const onSelect = (user) => {
        setDisableOnAutoFill(true);
        setUserDetail(user);
        let userAddress = (user.address !== undefined && user.address.length !== 0) ? user.address?.map((address) => {
            return {
                addressLine1: address.addressLine1,
                addressLine2: address.addressLine2,
                addressType: address.addressType,
                attention: address.attention,
                phone: address.phone,
                country: address.country,
                addressId: address.addressId,
                landmark: address.landmark,
                state: address.state,
                cityTown: address.cityTown,
                postalCode: address.postalCode,
            }
        }) : [{
            addressLine1: '',
            addressLine2: '',
            addressType: 'billing',
            attention: '',
            cityTown: '',
            country: 'India',
            addressId: '',
            landmark: '',
            phone: '',
            postalCode: '',
            state: '',
        }]
        setSelectedValue(user.individual);
        let clientDetailsOnSearch = {
            name: user.name + (user.restOfName === null ? '' : ` ${user.restOfName}`),
            individual: user.individual,
            personId: user.personId,
            loginUser: user.loginUser,
            address: userAddress[0],
            identificationGst: getIdentificationByType(user.identification, IdentificationTypes.gstin),
            identificationPan: getIdentificationByType(user.identification, IdentificationTypes.pan),
            email: getEmail(user.email),
            phoneNumber: getPhoneNumber(user.phoneNumber)
        };
        setClientDetails(clientDetailsOnSearch)
        setAddressTrigger(addressTrigger + 1);
        setErrors({});
        if(isButtonClicked) {
            save(clientDetailsOnSearch);
        }
        setLoading(false);
    }

    const clearDetails = () => {
        setClientDetails({
            name: '',
            personId: '',
            individual: true,
            loginUser: false,
            identificationGst: {
                id: null,
                identification: '',
                identificationType: IdentificationTypes.gstin,
                previousIdentification: ''
            },
            identificationPan: {
                id: null,
                identification: '',
                identificationType: IdentificationTypes.pan,
                previousIdentification: ''
            },
            email: {
                id: null,
                email: '',
            },
            phoneNumber: {
                id: null,
                phoneNumber: '',
            },
            address: {
                addressLine1: '',
                addressLine2: '',
                addressType: 'billing',
                attention: '',
                cityTown: '',
                country: '',
                addressId: '',
                landmark: '',
                phone: '',
                postalCode: '',
                state: '',
                stateId: '',
                cityTownId: '',
            }
        })
        setSelectedValue(client.individual);
        setAddressTrigger(addressTrigger + 1);
        setDisableOnAutoFill(false);
    }

    const handleBlurSearch = (searchBy, value, isErrorPresent) => {
        setIsClientFocused(false);
        if (value !== '' && !isErrorPresent) {
            setLoading(true);
            let payload = {
                searchBy: searchBy.includes('identification') ? 'identification' : searchBy,
                searchText: value,
                identificationType: searchBy === 'identificationGst' && 'gstin' ||
                    searchBy === 'identificationPan' && 'pan' ||
                    ''
            }
            PartyFieldSearch(payload, setLoading, (user) => {
                if (user !== null) {
                    onSelect(user);
                } else if (isButtonClicked) {
                    save(clientDetails);
                }
                setLoading(false);
            })
        }
    }
    
    return (
        <Fragment>
            {<Dialog open={open} maxWidth="md" fullWidth scroll='paper' classes={{ paper: classes.dialogWrapper }}>
                <DialogTitle className={classes.dialogTitle}>
                    <Grid container>
                        <Grid item xs={12}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }} id='billingdetail'>
                                <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
                                    {t('Client_Details')}
                                </Typography>
                                <Tooltip title={t('Close')} arrow>
                                    <Close onClick={handleClose} size='large' id="close-button" color='error' style={{ float: 'right', cursor: 'pointer' }} />
                                </Tooltip>
                            </div>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    {/* {isNew &&
                        <Grid container spacing={1}>
                            <Grid item xs={10}>
                                <PartySearch
                                    style={{ width: '100%' }}
                                    individual={clientDetails.individual}
                                    defaultValue={SearchCriteria.email}
                                    setDropDownValue={[SearchCriteria.email, SearchCriteria.name, SearchCriteria.phoneNumber]}
                                    onSelect={onSelectParty}
                                    cognitoUsers={false} />
                            </Grid>
                            <Grid item xs={2}>
                                <Button onClick={clearAll} variant='outlined' style={{ marginLeft: '30px', marginTop: '5px' }}>clear</Button>
                            </Grid>
                        </Grid>}<br /> */}
                    <Stack spacing={3}>
                        {isNew && <PartySearch registeredUsers={true} personType={clientDetails.individual} onSelect={onSelect} isDetails={true}
                            clearDetails={clearDetails} />}
                        {clientDetails.loginUser &&
                            <Stack direction='row' justifyContent='center' alignItems='center' spacing={1.2} className={classes.loginInfo}
                                sx={isNew ? {marginTop: '15px !important'}: {}}>
                                <InfoRounded />
                                <Typography variant='subtitle2' style={{ fontWeight: 'bold' }}>
                                    {clientDetails.name}&nbsp;{t('Is_A_Registered_User_Please_Contact_Support_To_Update_GSTIN_PAN')}
                                </Typography>
                        </Stack>
                        }
                        <AuctionFieldSet>
                            {!clientDetails.loginUser && !isEditClientDetails && !isNew &&
                                <Tooltip title={t('Edit')}>
                                    <Fab className={classes.editFab} id='edit-client' style={{ float: 'right' }} onClick={() => setEditClientDetails()}
                                        disabled={(isEditEmail || isEditPhone || isEditGstID || isEditPanID)}>
                                        <Edit color='primary' />
                                    </Fab>
                                </Tooltip>
                            }
                            {isEditClientDetails &&
                                <><Tooltip title={t('Save')}>
                                    <Fab className={classes.checkFab} id='save-client' style={{ float: 'right' }} onClick={() => updateClientDetails()}
                                        disabled={
                                            (errors.name !== undefined && errors.name !== '' && errors.name !== null) ||
                                            errors.email !== undefined && errors.email !== null && errors.email !== '' || errors.phoneNumber !== undefined && errors.phoneNumber !== null && errors.phoneNumber !== '' ||
                                            (errors['identificationGst'] !== undefined && errors['identificationGst'] !== null && errors['identificationGst'] !== '') ||
                                            (errors['identificationPan'] !== undefined && errors['identificationPan'] !== null && errors['identificationPan'] !== '')}>
                                        <Done style={{ color: 'green' }} />
                                    </Fab>
                                </Tooltip>
                                    <Tooltip title={t('Cancel')}>
                                        <Fab className={classes.closeFab} id='cancel-client' style={{ float: 'right' }} onClick={() => cancelEditClientDetails()}>
                                            <Clear color='error' />
                                        </Fab>
                                    </Tooltip>
                                </>
                            }
                            <Stack
                                spacing={3}
                                direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row' }} alignItems="flex-end">
                                <Stack direction={{ xs: 'row' }}>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                                        <RadioCard selected={!selectedValue} onClick={() => handleChange('individual', false)}
                                            disabled={(!isNew && !isEditClientDetails) || (disableOnAutoFill && clientDetails.personId !== '')}>
                                            <Radio
                                                checked={selectedValue === false}
                                                value={false}
                                                id="institute"
                                                inputProps={{ 'aria-label': 'A' }}
                                                style={{ marginLeft: '80px' }}
                                            />
                                            <Box className="icon">
                                                <Business />
                                            </Box>
                                            <Typography className="text">{t('Institution')}</Typography>
                                        </RadioCard>
                                        <RadioCard selected={selectedValue} onClick={() => handleChange('individual', true)}
                                            disabled={(!isNew && !isEditClientDetails) || (disableOnAutoFill && clientDetails.personId !== '')}>
                                            <Radio
                                                checked={selectedValue === true}
                                                value={true}
                                                id="individual"
                                                inputProps={{ 'aria-label': 'A' }}
                                                style={{ marginLeft: '80px' }}
                                            />
                                            <Box className="icon">
                                                <Person />
                                            </Box>
                                            <Typography className="text">{t('Individual')}</Typography>
                                        </RadioCard>
                                    </div>
                                </Stack>
                                <Stack justifyContent='center' alignItems='center' spacing={3}
                                    direction={{ xs: 'column' }} width='100%' paddingBottom='20px'>
                                    <TextField id="name" label={t("Client_Name")}
                                        value={clientDetails.name}
                                        helperText={errors.name}
                                        // onBlur={() => handleBlurSearch('name', clientDetails.name)}
                                        error={errors.name !== undefined && errors.name !== null && errors.name !== ''}
                                        onChange={onInputChange}
                                        disabled={(!isNew && !isEditClientDetails) || (disableOnAutoFill && clientDetails.personId !== '')}
                                        required={(props.isNew || isEditClientDetails) && !disableOnAutoFill && !clientDetails.loginUser} fullWidth variant="standard"
                                    />
                                </Stack>
                            </Stack>
                            <Stack spacing={3} direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row' }} marginTop={'25px'}>
                                <ClientEmail
                                    t={t}
                                    isEditEmail={isEditEmail}
                                    isNew={isNew}
                                    setErrors={setErrors}
                                    errors={errors}
                                    classes={classes}
                                    setIsEditEmail={setIsEditEmail}
                                    clientDetails={clientDetails}
                                    setClientDetails={setClientDetails}
                                    validateInputOnChange={validateInputOnChange}
                                    resetOtherEdits={resetOtherEdits}
                                    disabled={(!isNew && !isEditClientDetails) || (disableOnAutoFill && clientDetails.email.id !== '')}
                                    loading={loading}
                                    setLoading={setLoading}
                                    onBlur={() => handleBlurSearch('email', clientDetails.email.email, errors.email !== undefined && errors.email !== null && errors.email !== '')}
                                />
                                <ClientPhone
                                    t={t}
                                    isEditPhone={isEditPhone}
                                    isNew={isNew}
                                    setErrors={setErrors}
                                    errors={errors}
                                    classes={classes}
                                    setIsEditPhone={setIsEditPhone}
                                    clientDetails={clientDetails}
                                    setClientDetails={setClientDetails}
                                    validateInputOnChange={validateInputOnChange}
                                    resetOtherEdits={resetOtherEdits}
                                    disabled={(!isNew && !isEditClientDetails) || (disableOnAutoFill && clientDetails.phoneNumber.id !== '')}
                                    loading={loading}
                                    setLoading={setLoading}
                                    onBlur={() => handleBlurSearch('phone_number', clientDetails.phoneNumber.phoneNumber, errors.phoneNumber !== undefined && errors.phoneNumber !== null && errors.phoneNumber !== '')}
                                // phoneDisable={addressGetValues('personId')}
                                />
                            </Stack>
                            {
                                (isNew || isEditClientDetails) ? <Typography color="primary" variant='subtitle2' marginTop={3} marginBottom={1.5}
                                // sx={{color: '#ff9800'}}
                                >{t('Either_GSTIN_Or_PAN_Is_Mandatory')}</Typography> :
                                    <Fragment></Fragment>
                            }

                            <Stack spacing={3} direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row' }} sx={(isNew || isEditClientDetails) ? { marginTop: '0px' } : { marginTop: '25px' }} >
                                <ClientIdentification
                                    t={t}
                                    isEditID={isEditGstID}
                                    isNew={isNew}
                                    setErrors={setErrors}
                                    errors={errors}
                                    classes={classes}
                                    setIsEditID={setIsEditGstID}
                                    clientDetails={clientDetails}
                                    setClientDetails={setClientDetails}
                                    validateInputOnChange={validateInputOnChange}
                                    type={IdentificationTypes.gstin}
                                    identificationKey='identificationGst'
                                    resetOtherEdits={resetOtherEdits}
                                    disabled={(!isNew && !isEditClientDetails) || (disableOnAutoFill && (clientDetails['identificationGst'].id !== null && clientDetails['identificationGst'].id !== ''))}
                                    loading={loading}
                                    setLoading={setLoading}
                                    onBlur={() => handleBlurSearch('identificationGst', clientDetails.identificationGst.identification, errors.identificationGst !== undefined && errors.identificationGst !== null && errors.identificationGst !== '')}
                                // gstDisable={addressGetValues('gst')}
                                />
                                <ClientIdentification
                                    t={t}
                                    isEditID={isEditPanID}
                                    isNew={isNew}
                                    setErrors={setErrors}
                                    errors={errors}
                                    classes={classes}
                                    setIsEditID={setIsEditPanID}
                                    clientDetails={clientDetails}
                                    setClientDetails={setClientDetails}
                                    validateInputOnChange={validateInputOnChange}
                                    type={IdentificationTypes.pan}
                                    identificationKey='identificationPan'
                                    resetOtherEdits={resetOtherEdits}
                                    disabled={(!isNew && !isEditClientDetails) || (disableOnAutoFill && (clientDetails['identificationPan'].id !== null && clientDetails['identificationPan'].id !== ''))}
                                    loading={loading}
                                    setLoading={setLoading}
                                    onBlur={() => handleBlurSearch('identificationPan', clientDetails.identificationPan.identification, errors.identificationPan !== undefined && errors.identificationPan !== null && errors.identificationPan !== '')}
                                // panDisable={addressGetValues('pan')}
                                />
                            </Stack>
                        </AuctionFieldSet>
                    </Stack>
                    {isNew && <Stack mt={2}>
                        <Typography variant="h6" style={{ textTransform: 'capitalize' }}>{clientDetails.address.addressType} Address</Typography>
                        <AuctionFieldSet>
                            <AddressDetails data={clientDetails.address} disableOnAutoFill={disableOnAutoFill}
                                touched={touched} errors={addressErrors} setErrors={setErrors} key={addressTrigger}
                                setJsonData={setJsonData} setPhoneNumber={setPhoneNumber}
                                isEdit={(disableOnAutoFill && clientDetails.address.addressId !== '' && clientDetails.address.addressId !== undefined) ? false : true}
                                setJsonCountryStateCityData={setJsonCountryStateCityData} />
                        </AuctionFieldSet>
                    </Stack>}
                    {!isNew && <Stack marginTop={2}>
                        <AuctionFieldSet>
                            <AuctionLengend>{t('Address')}</AuctionLengend>
                            <Addresses sourceId={clientDetails?.personId} isLoginUser={false} hasPermission={true} address={clientDetails.address} atlestOneMandatory={true} isClient={true}/>
                        </AuctionFieldSet>
                    </Stack>}
                </DialogContent>
                <Divider />
                <DialogActions sx={{ justifyContent: disableOnAutoFill ? 'space-between' : 'end' }}>
                    {disableOnAutoFill && <Stack>
                        <Button id='client-cancel' variant="outlined" color="primary"
                            onClick={clearDetails}>
                            {t('Clear_Details')}
                        </Button>
                    </Stack>}
                    <Stack direction='row' spacing={2}>
                        {!isNew && <Button id='client-close' variant="outlined" color="primary"
                            onClick={handleClose}>
                            {t('Close')}
                        </Button>}
                        {isNew && <Fragment>
                            <Button id='client-cancel' variant="outlined" color="primary"
                                onClick={handleCancel}>
                                {t('Cancel')}
                            </Button>
                            <Button id='client-save' variant="contained" color="primary"
                                onClick={handleSave} onMouseDown={onMouseDown}>
                                {t('Save')}
                            </Button>
                        </Fragment>}
                    </Stack>
                </DialogActions>
                {(loading || isButtonClicked) && <EligoBackdrop show={(loading || isButtonClicked)} invisible={true} />}
                {message.showMsg && <EligoSnackBar show={message.showMsg} message={message.message}
                    severity={message.severity} handleResetOnClose={handleResetOnClose} />}
            </Dialog>}
        </Fragment>
    )
}

export default ClientDetails;