import { Clear, Done, Edit } from '@material-ui/icons';
import { Fab, Tooltip } from '@mui/material';
import MuiPhoneNumber from 'material-ui-phone-number';
import React, { useState } from 'react';
import { ClientFields } from '../ClientStaticVar';
import { AuctionFieldSet } from '../client-style/ClientStyle';
import { AxiosEvotingInterceptor } from '../../../config/axios.interceptor';
import EligoSnackBar from '../../../common/EligoSnackBar';
import EligoBackdrop from '../../../common/EligoBackdrop';

const ClientPhone = (props) => {
    const { t, isEditPhone, isNew, setErrors, errors, classes, resetOtherEdits, loading, setLoading,
        setIsEditPhone, clientDetails, setClientDetails, validateInputOnChange, disabled, phoneDisable, onBlur } = props;

    const [message, setMessage] = useState({
        show: false,
        message: '',
        severity: ''
    })

    const addPhoneNumber = (clientPhone) => {
        let payload = {
            id: '',
            phoneNumber: clientPhone.phoneNumber,
            preferred: true
        }
        AxiosEvotingInterceptor.post(`phone-number/${clientDetails.personId}`, payload).then(response => {
            if (response) {
                setClientDetails(prevState => ({
                    ...prevState,
                    phoneNumber: {
                        id: response.id,
                        phoneNumber: response.phoneNumber,
                        previousPhoneNumber: ''
                    }
                }))
                setIsEditPhone(false);
                setMessage({ showMsg: true, message: t('Added_Client_Phone_Number_Successfully'), severity: 'success' });
            }
            setLoading(false);
        }).catch(error => {
            if (error.message) {
                setMessage({ showMsg: true, message: error.message, severity: 'error' })
            } else {
                setMessage({ showMsg: true, message: t('Try_Again'), severity: 'error' })
            }
            setLoading(false);
        })
    }

    const updatePhoneNumber = (clientPhone) => {
        let payload = {
            id: clientPhone.id,
            phoneNumber: clientPhone.phoneNumber,
            preferred: true
        }
        AxiosEvotingInterceptor.put(`phone-number/update/${clientDetails.personId}`, payload).then(response => {
            if (response) {
                setClientDetails(prevState => ({
                    ...prevState,
                    phoneNumber: {
                        id: response.id,
                        phoneNumber: response.phoneNumber,
                        previousPhoneNumber: ''
                    }
                }))
                setIsEditPhone(false);
                setMessage({ showMsg: true, message: t('Updated_Client_Phone_Number_Successfully'), severity: 'success' });
            }
            setLoading(false);
        }).catch(error => {
            if (error.message) {
                setMessage({ showMsg: true, message: error.message, severity: 'error' })
            } else {
                setMessage({ showMsg: true, message: t('Try_Again'), severity: 'error' })
            }
            setLoading(false);
        })
    }

    const onPhoneChange = (value) => {
        setClientDetails(prevState => ({
            ...prevState,
            phoneNumber: {
                ...prevState.phoneNumber,
                phoneNumber: value.trim()
            }
        }))
        validateInputOnChange('phoneNumber', value, clientDetails.phoneNumber.id)
    }

    const setEditPhone = (phoneNumber) => {
        setClientDetails(prevState => ({
            ...prevState,
            phoneNumber: {
                ...prevState.phoneNumber,
                previousPhoneNumber: phoneNumber
            }
        }))
        setIsEditPhone(true);
        resetOtherEdits(ClientFields.phoneNumber)
    }

    const cancelEditPhoneNumber = () => {
        setErrors({})
        setClientDetails(prevState => ({
            ...prevState,
            phoneNumber: {
                ...prevState.phoneNumber,
                phoneNumber: prevState.phoneNumber.previousPhoneNumber,
                previousPhoneNumber: ''
            }
        }))
        setIsEditPhone(false);
    }

    const updateClientPhone = async (clientPhone) => {
        if (errors.phoneNumber === '' || errors.phoneNumber === null) {
            setLoading(true);
            if (clientPhone.id !== null && clientPhone.id !== undefined && clientPhone.id !== '') {
                updatePhoneNumber(clientPhone);
            }
            else {
                addPhoneNumber(clientPhone);
            }
        }
    }

    return (
        // <AuctionFieldSet style={{ width: '100%' }}>
        //     {!clientDetails.loginUser && !isEditPhone && !isNew &&
        //         <><Tooltip title={t('Edit')}>
        //             <Fab className={classes.editFab} id='edit-phone-client' style={{ float: 'right' }} onClick={() => setEditPhone(clientDetails.phoneNumber.phoneNumber)}
        //                 disabled={disabled}>
        //                 <Edit color='primary'  />
        //             </Fab>
        //         </Tooltip></>
        //     }
        //     {isEditPhone &&
        //         <>
        //             <Tooltip title={t('Save')}><Fab className={classes.checkFab} id='save-phone-client' style={{ float: 'right' }} onClick={() => updateClientPhone(clientDetails.phoneNumber)}
        //                 disabled={errors.phoneNumber !== undefined && errors.phoneNumber !== '' && errors.phoneNumber !== null}>
        //             <Done style={{color: 'green'}}  />
        //             </Fab></Tooltip>
        //             <Tooltip title={t('Cancel')}><Fab className={classes.closeFab} id='cancel-phone-client' style={{ float: 'right' }} onClick={() => cancelEditPhoneNumber()}>
        //                 <Clear color='error'  />
        //             </Fab></Tooltip>
        //         </>
        //     }
        <div style={{ width: '100%' }}>
            <MuiPhoneNumber
                id="phoneNumber"
                label={t('Phone_Number')}
                defaultCountry={"in"}
                autoFormat={false}
                countryCodeEditable={false}
                value={clientDetails.phoneNumber.phoneNumber}
                helperText={errors.phoneNumber}
                error={errors.phoneNumber !== undefined && errors.phoneNumber !== null && errors.phoneNumber !== ''}
                onChange={(value, country) => {
                    if (value != null && value !== undefined && value !== '' && value.slice(1) === country.dialCode) {
                        onPhoneChange('')
                    } else {
                        onPhoneChange(value)
                    }
                }}
                onBlur={onBlur}
                disabled={disabled || phoneDisable}
                fullWidth variant="standard"
            />
        </div>

        //     {(loading) && <EligoBackdrop show={(loading)} invisible={false} />}
        //     {message.showMsg && <EligoSnackBar show={message.showMsg} message={message.message}
        //         severity={message.severity} />}
        // </AuctionFieldSet>
    )
}

export default ClientPhone
