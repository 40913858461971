import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Toolbar, Tooltip, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import EligoBackdrop from "../../../common/EligoBackdrop";
import EligoSnackBar from "../../../common/EligoSnackBar";
import { Close, CloudUpload } from '@material-ui/icons';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { useTranslation } from "react-i18next";
import { APIEvotingAxiosInterceptor, AxiosEvotingInterceptor } from "../../../config/axios.interceptor";
import { Stack } from "@mui/material";
import DescriptionIcon from '@material-ui/icons/Description';
import UploadDialog from "../../file-upload-download/UploadDialog";

const BulkUploadFile = (props) => {
    const { open, setOpen, pollId, onSuccess } = props;
    const { t } = useTranslation();
    const [selectedFile, setFile] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [message, setMessage] = useState({
        showMsg: false,
        message: null,
        severity: null
    });

    const bulkUpload = () => {
        setUploading(true)
        const formData = new FormData();
        formData.append(
            "file", selectedFile[0]
        );
        props.setMessage({ showMsg: false, message: '', severity: '' });
        APIEvotingAxiosInterceptor.post(`voter/bulk-upload/${pollId}`, formData).then(response => {
            setOpen(false)
            if (response) {
                setFile([]);
                props.setMessage({
                    showMsg: true,
                    message: t('Excel_Uploaded_Successfully_Please_Refresh_The_Page'),
                    severity: 'success'
                });
                onSuccess();
            }
        }).catch(error => {
            setMessage({ showMsg: false, message: '', severity: '' })
            if (error.message) {
                setOpen(false)
                setFile([])
                props.setMessage({
                    showMsg: true,
                    message: error.message,
                    severity: 'error'
                });
            } else {
                props.setMessage({
                    showMsg: true,
                    message: t('Please_Check_Excel_And_Try_Again'),
                    severity: 'error'
                });
            }
        });
    }
    const excelAcceptableMimeType = {
        'application/vnd.ms-excel': ['.xls'],
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
        'application/vnd.oasis.opendocument.spreadsheet': []
    }

    return (
        <div>
            {open && <UploadDialog
                dialogOpen={open}
                setDialogOpen={setOpen}
                files={selectedFile}
                isMultiple={false}
                title={t('Upload_Voter_Excel')}
                onUploadClick={bulkUpload}
                t={t}
                setFiles={setFile}
                uploadedFile={[]}
                acceptableFiles={excelAcceptableMimeType}
            />}
            {/* <Dialog
                id='dialog' fullWidth
                open={open}
                className="loading-container" >
                <DialogTitle style={{ color: "black", background: '#e6e6ea', height: '10px' }} >
                    <Stack direction='row' spacing={3} justifyContent='center' alignItems='center'>
                        <Typography id='exel' variant="h6" component="div" style={{ marginTop: '-10px' }}>
                            Upload Excel File
                        </Typography>
                    </Stack>
                </DialogTitle>
                <br />
                <DialogContent style={{ alignSelf: 'center' }}>
                    {selectedFile ?
                        <div>  <Stack direction='row'>
                            <IconButton style={{ marginTop: '-23px' }}>
                                <DescriptionIcon color='primary' fontSize='large' />
                            </IconButton>
                            <Typography style={{ background: '#F5FBFF', borderRadius: '30px', marginBottom: '19px', padding: '0px 10px 0px 10px' }}>{selectedFile.name}</Typography>
                        </Stack>
                        </div> :
                        <div>
                            <Stack direction='row'>
                                <IconButton style={{ marginTop: '-23px' }}>
                                    <CloudUpload style={{ fontSize: '50px' }} color='primary' />
                                </IconButton>
                                <Typography style={{ marginTop: '5px', fontSize: '20px' }}>Select File Which you want to upload</Typography>
                            </Stack>
                        </div>
                    }
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Stack direction='row' spacing={2}>
                        <Button id='file' variant="outlined" color="primary" component="label" onClick={handleClose}>
                            cancel
                        </Button>
                        {selectedFile !== undefined && <Button id='file' variant="contained" component="label" color='primary'>
                            <input type="file" onChange={onFileChange} hidden />
                            Change File
                        </Button>}
                        {selectedFile !== undefined && <Button id='file' variant="contained" component="label"
                            onClick={bulkUpload} style={{ background: 'green', color: 'white' }}>
                            upload File
                        </Button>}
                        {selectedFile === undefined && <Button id='file' variant="contained" component="label" color='primary'>
                            <input type="file" onChange={onFileChange} hidden />
                            Select File
                        </Button>}
                    </Stack>
                </DialogActions>

                {uploading && <EligoBackdrop show={true} />}
                {message.showMsg && <EligoSnackBar show={message.showMsg} message={message.message}
                    severity={message.severity} />}
            </Dialog> */}
        </div >
    )
}

export default BulkUploadFile;