import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';

const ContactUs = () => {
  return (
        <Grid container justifyContent="center" alignItems="center" style={{padding: '8px'}}>
            <Grid item xs={12}>
                <Grid container spacing={3} alignItems="center">
                    <Grid item style={{width:'10%'}}>
                        <LocationOnIcon  id='location'/>
                    </Grid>
                    <Grid item style={{width:'90%'}}>
                        <Typography id='address' variant="button" display="block" gutterBottom>
                            <strong>Address</strong>
                        </Typography>
                        <Typography id='url' variant="caption" display="block" gutterBottom>
                        <a id='anchor' href="http://maps.google.com?q=12.9872587155234, 77.59195351847231" target="_blank"> 7/1, Aliaskar Road
                            Vasanth Nagar
                            Bengaluru 560 052
                            Karnataka, India</a>
                        
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={3} alignItems="center">
                    <Grid item style={{width:'10%'}}>
                        <PhoneIcon id='phoneIcon'/>
                    </Grid>
                    <Grid item style={{width:'90%'}}>
                        <Typography id='cell' variant="button" display="block" gutterBottom>
                            <strong id='mobile'>Phone</strong>
                        </Typography>
                        <Typography id='number' variant="caption" display="block" gutterBottom>
                            +91 8041218544 
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={3} alignItems="center">
                    <Grid item style={{width:'10%'}}>
                        <EmailIcon id='icon'/>
                    </Grid>
                    <Grid item style={{width:'90%'}}>
                        <Typography id='button'variant="button" display="block" gutterBottom>
                            <strong id='email'>Email</strong>
                        </Typography>
                        <Typography id='enquire' variant="caption" display="block" gutterBottom>
                        <a id='link' href="mailto:enquiries@enkindletech.com?" target="_top">enquiries@enkindletech.com</a>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
  )
}

export default ContactUs;