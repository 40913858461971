import React, {
    useState,
    useEffect
}                                   from 'react'
import Question                     from './Question';
import {
    Card,
    Grid
}                                   from '@material-ui/core';
import { APIEvotingAxiosInterceptor, AxiosEvotingInterceptor, PublicAPIAxiosInterceptor, PublicAxiosInterceptor }  from '../../../config/axios.interceptor';
import EligoBackdrop                from '../../../common/EligoBackdrop';
import EligoSnackBar                from '../../../common/EligoSnackBar';

const Vote = ({poll_id, voter_id, auth_rep_id, setVotedForAllQuestions, voteWithLink, numberOfQuestions,
        previewVote, setLoading, pollCategory}) => {
    const [question, setQuestion] = useState(null);
    // const [loading, setLoading] = useState(false);
    const [refreshQuestion, setRefreshQuestion] = useState(true);
    const [questionIndex, setQuestionIndex] = useState(true);
    const [message, setMessage] = React.useState({
        showMsg: false,
        message: null,
        severity: null
    })
    const [questions, setQuestions] = useState([]);

    useEffect(() => {
        getFirstQuestion();
    }, [])

    const getFirstQuestion = () => {
        if (voteWithLink) {
            getFirstQuestionForNonLoginUser();
        } else {
            getFirstQuestionForLoginUser();
        }
    }

    const getFirstQuestionForLoginUser = () => {
        setLoading(true);
        setMessage({ showMsg: false, message: '', severity: '' });
        if (auth_rep_id) {
            APIEvotingAxiosInterceptor.get(`question/${poll_id}/first-question/${voter_id}/${auth_rep_id}`)
            .then(response => {
                setQuestionDetails(response.currentQuestion);
                setVotedForAllQuestions(response.allQuestionVoted);
                setLoading(false);
                setQuestionIndex(0);
            })
            .catch(error => {
                if (error.message) {
                    setMessage({ showMsg: true, message: error.message, severity: 'error' });
                }
                setLoading(false);
            })
        } else {
            APIEvotingAxiosInterceptor.get(`question/${poll_id}/first-question/${voter_id}`)
            .then(response => {
                setQuestionDetails(response.currentQuestion);
                setVotedForAllQuestions(response.allQuestionVoted);
                setLoading(false);
                setQuestionIndex(0);
            })
            .catch(error => {
                if (error.message) {
                    setMessage({ showMsg: true, message: error.message, severity: 'error' });
                }
                setLoading(false);
            })
        }
    }

    const getFirstQuestionForNonLoginUser = () => {
        setLoading(true);
        setMessage({ showMsg: false, message: '', severity: '' });
        if (auth_rep_id) {
            PublicAPIAxiosInterceptor.get(`question/${poll_id}/first-question/${voter_id}/${auth_rep_id}`)
            .then(response => {
                setQuestionDetails(response.currentQuestion);
                setVotedForAllQuestions(response.allQuestionVoted);
                setLoading(false);
                setQuestionIndex(0);
            })
            .catch(error => {
                if (error.message) {
                    setMessage({ showMsg: true, message: error.message, severity: 'error' });
                }
                setLoading(false);
            })
        } else {
            PublicAPIAxiosInterceptor.get(`question/${poll_id}/first-question/${voter_id}`)
            .then(response => {
                setQuestionDetails(response.currentQuestion);
                setVotedForAllQuestions(response.allQuestionVoted);
                setLoading(false);
                setQuestionIndex(0);
            })
            .catch(error => {
                if (error.message) {
                    setMessage({ showMsg: true, message: error.message, severity: 'error' });
                }
                setLoading(false);
            })
        }
    }

    const setQuestionDetails = (question) => {
        setQuestion({
            id: question.id,
            question: question.question,
            questionDescription: question.questionDescription,
            threshold: question.threshold,
            questionType: question.questionType,
            minSelectInMultiChoice: question.minSelectInMultiChoice,
            maxSelectInMultiChoice: question.maxSelectInMultiChoice,
            allowQuestionSkip: question.allowQuestionSkip,
            shuffleOptions: question.shuffleOptions,
            dependent: question.dependent,
            timeout: question.timeout,
            displaySequence: question.displaySequence,
            votedOptionIds: question.votedOptionIds.map(votedOption => {
                return votedOption
            }),
            options: question.options.map(option => {
                return {
                    id: option.id,
                    optionType: option.optionType,
                    option: option.option,
                    rank: option.rank,
                    s3Location: option.s3Location,
                    ratingScale: option.ratingScale,
                    ratingIcon: option.ratingIcon,
                    nextQuestionId: option.nextQuestionId,
                    files: option.files,
                    displaySequence: option.displaySequence
                }
            })
        });
    }

    const saveVote = (selectedOption) => {
        if (voteWithLink) {
            saveNonLoginUserVote(selectedOption);
        } else {
            saveLoginUserVote(selectedOption);
        }
    }

    const saveLoginUserVote = (selectedOption) => {
        let payload = {
            preview: false,
            questionId: question.id,
            optionId: selectedOption,
            voterId: voter_id
        }
        requestToVoteForLoginUser(payload)
    }

    const saveNonLoginUserVote = (selectedOption) => {
        let payload = {
            preview: false,
            questionId: question.id,
            optionId: selectedOption,
            voterId: voter_id
        }
        requestToVoteForNonLoginUser(payload);
    }

    const skipQuestion = (selectedOption) => {
        if (questionIndex < (questions.length - 1)) {
            setQuestionDetails(questions[questionIndex + 1]);
            setQuestionIndex(questionIndex + 1)
        } else {
            let payload = {
                preview: false,
                questionId: question.id,
                optionId: selectedOption ? selectedOption : null,
                voterId: voter_id,
                skip: true
            }
            if (voteWithLink) {
                requestToVoteForNonLoginUser(payload);
            } else {
                requestToVoteForLoginUser(payload);
            }
        }
    }

    const requestToVoteForNonLoginUser = (payload) => {
        setMessage({ showMsg: false, message: '', severity: '' });
        setLoading(true);
        if (auth_rep_id) {
            PublicAPIAxiosInterceptor.post(`vote/${poll_id}/${auth_rep_id}`, payload)
                .then(response => {
                    setQuestionsAfterCast(payload.optionId);
                    setVotedForAllQuestions(response.allQuestionVoted);
                    if (response.currentQuestion != null) {;
                        setQuestionDetails(response.currentQuestion);
                        setQuestionIndex(questionIndex + 1);
                        setQuestionsDetails(response.currentQuestion);
                    } else {
                        previewVote()
                    }
                    setLoading(false);

                })
                .catch(error => {
                    if (error.message) {
                        setMessage({ showMsg: true, message: error.message, severity: 'error' });
                    }
                    setLoading(false);
            })
        } else {
            PublicAPIAxiosInterceptor.post(`vote/${poll_id}`, payload)
                .then(response => {
                    setQuestionsAfterCast(payload.optionId);
                    setVotedForAllQuestions(response.allQuestionVoted);
                    if (response.currentQuestion != null) {
                        setQuestionDetails(response.currentQuestion);
                        setQuestionIndex(questionIndex + 1);
                        setQuestionsDetails(response.currentQuestion);
                    } else {
                        previewVote()
                    }
                    setLoading(false);
                })
                .catch(error => {
                    if (error.message) {
                        setMessage({ showMsg: true, message: error.message, severity: 'error' });
                    }
                    setLoading(false);
            })
        }
    }

    const requestToVoteForLoginUser = (payload) => {
        setMessage({ showMsg: false, message: '', severity: '' });
        setLoading(true);
        if (auth_rep_id) {
            APIEvotingAxiosInterceptor.post(`vote/${poll_id}/${auth_rep_id}`, payload)
                .then(response => {
                    setQuestionsAfterCast(payload.optionId);
                    setVotedForAllQuestions(response.allQuestionVoted);
                    if (response.currentQuestion != null) {;
                        setQuestionDetails(response.currentQuestion);
                        setQuestionIndex(questionIndex + 1);
                        setQuestionsDetails(response.currentQuestion);
                    } else {
                        previewVote()
                    }
                    setLoading(false);
                })
                .catch(error => {
                    if (error.message) {
                        setMessage({ showMsg: true, message: error.message, severity: 'error' });
                    }
                    setLoading(false);
            })
        } else {
            APIEvotingAxiosInterceptor.post(`vote/${poll_id}`, payload)
                .then(response => {
                    setQuestionsAfterCast(payload.optionId);
                    setVotedForAllQuestions(response.allQuestionVoted);
                    if (response.currentQuestion != null) {
                        setQuestionDetails(response.currentQuestion);
                        setQuestionIndex(questionIndex + 1);
                        setQuestionsDetails(response.currentQuestion);
                    } else {
                        previewVote()
                    }
                    setLoading(false);
                })
                .catch(error => {
                    if (error.message) {
                        setMessage({ showMsg: true, message: error.message, severity: 'error' });
                    }
                    setLoading(false);
            })
        }
    }

    const setQuestionsAfterCast = (optionId) => {
        let index = questions.findIndex(q => q.id === question.id);
        if (index > -1) {
            questions[index].votedOptionIds = optionId;
            setQuestions(questions);
        } else {
            setQuestions(prevState => [
                ...prevState,
                {
                    ...question,
                    votedOptionIds: optionId
                }
            ]);
        }
    }

    const setQuestionsDetails = (ques) => {
        let index = questions.findIndex(q => q.id === ques.id);
        if (index <= -1) {
            setQuestions(prevState => [
                ...prevState,
                {
                    ...ques
                }
            ]);
        }
    }

    useEffect(() => {
        setRefreshQuestion(false);
        setTimeout(() => {
            setRefreshQuestion(true);
        }, 100);
    }, [question]);

    const previousQuestion = () => {
        console.log('previousQuestion', questions);
        if (questions.length !== 0) {
            setQuestionDetails(questions[questionIndex - 1]);
            setQuestionIndex(questionIndex - 1)
        }
    }

    return (
        <div>
            <Card style={{background: '#f2f2f28a'}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {(question && refreshQuestion) && <Question key={question.id} question={question} poll_id={poll_id} voter_id={voter_id}
                            saveVote={saveVote} skipQuestion={skipQuestion} previousQuestion={previousQuestion}
                            currentQuestionIndex={questionIndex} numberOfQuestions={numberOfQuestions}
                            showFiles={true} voteWithLink={voteWithLink} pollCategory={pollCategory}/>}
                    </Grid>
                </Grid>
            </Card>
            {message.showMsg && <EligoSnackBar show={message.showMsg} message={message.message}
                    severity={message.severity} />}
            {/* {loading && <EligoBackdrop show={true} />} */}
        </div>
    )
}

export default Vote;