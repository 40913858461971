import React, {
    Fragment,
    useState,
    useEffect
}                                   from 'react';
import {
    Card,
    CardContent,
    makeStyles,
    Button,
    CardActions,
    Grid,
    Typography
}                                   from '@material-ui/core';
import { Stack, Divider, Chip }                    from '@mui/material';
import PollSettings                 from './PollSettings';
import { useTranslation }           from 'react-i18next';
import {
    AxiosEvotingInterceptor 
}                                   from '../../../config/axios.interceptor';
import EligoSnackBar                from '../../../common/EligoSnackBar';
import EligoBackdrop                from '../../../common/EligoBackdrop';
import PollAdvanceSettings          from './PollAdvanceSettings';

const useStyles = makeStyles((theme) => ({
    card: {
        boxShadow: 'rgb(0 0 0 / 6%) 0px 3px 3px -2px, rgb(0 0 0 / 4%) 0px 3px 4px 0px, rgb(0 0 0 / 4%) 0px 1px 8px 0px !important',
        borderRadius: '8px'
    },
    btn: {
        float: 'right',
        margin: '4px'
    }
}));

const UpdatePollSettings = ({poll_id, canModify}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [isEdit, setIsEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(true);
    const [message, setMessage] = React.useState({
        showMsg: false,
        message: null,
        severity: null
    });
    const [pollDetail, setPollDetail] = useState({});

    const getPoll = () => {
        setLoading(true);
        AxiosEvotingInterceptor.get(`poll/${poll_id}`).then(poll => {
            setPollDetail({
                id: poll.id,
                referenceNumber: poll.referenceNumber,
                title: poll.title,
                description: poll.description,
                showResultsToVoters: poll.showResultsToVoters,
                weightedVotes: poll.weightedVotes,
                geoTagging: poll.geoTagging,
                geoFencing: poll.geoFencing,
                questionShuffle: poll.questionShuffle,
                websiteRedirect: poll.websiteRedirect,
                discloseVoterInResult: poll.discloseVoterInResult,
                status: poll.status,
                startTime: poll.startTime,
                endTime: poll.endTime,
                resultPostOnPollClose: poll.resultPostOnPollClose,
                startManually: poll.startManually,
                alertTime: poll.alertTime,
                sentStartAlert: poll.sentStartAlert,
                sentEndAlert: poll.sentEndAlert,
                voteWithSignUp: poll.voteWithSignUp,
                announceResultAutomatically: poll.announceResultAutomatically,
                allowVoteChange: poll.allowVoteChange,
                displayOrganizerName: poll.displayOrganizerName,
                scrutinizerHasToPpen: poll.scrutinizerHasToPpen,
                verifyWithOtp: poll.verifyWithOtp,
                pollType: poll.pollType,
                conductForPersonId: poll.conductFor ? poll.conductFor.personId : null,
                pollCategory: poll.pollCategory,
                realTimeResult: poll.realTimeResult,
                scrutinizerHasToOpen: poll.scrutinizerHasToOpen,
                secreteBallot: poll.secreteBallot,
                zoneId: poll.zoneId
            })
            setLoading(false);
            setRefresh(false);
            setTimeout(() => {
                setRefresh(true);
            }, 200);
        }).catch(error => {
            setLoading(false);
            console.log('error', error);
        })
    }

    const updatePollRoundService = (payload) => {
        setLoading(true);
        setMessage({ showMsg: false, message: '', severity: '' });
        AxiosEvotingInterceptor.put(`poll/${pollDetail.id}`, payload).then(response => {
            setMessage({ showMsg: true, message: t('Poll_Settings_Updated_Successfully'), severity: 'success' });
            setIsEdit(false);
            getPoll();
            setLoading(false);
        }).catch(error => {
            setLoading(false)
            if (error.message) {
                setMessage({ showMsg: true, message: error.message, severity: 'error' });
            }
        })

    }

    const handleDataBoolean = (event) => {
        let id = null;
        if (event.target.id != "") {
            id = event.target.id;
        } else if (event.target.parentElement.id != "") {
            id = event.target.parentElement.id;
        } else if (event.target.parentElement.parentElement.id != "") {
            id = event.target.parentElement.parentElement.id;
        }
        if (id != null) {
            setPollDetail(prevState => ({
                ...prevState,
                [id]: !prevState[id]
            }))
        }
    }

    const handleSave = (event) => {
        event.preventDefault();
        let payload = {
            referenceNumber: pollDetail.referenceNumber,
            title: pollDetail.title,
            description: pollDetail.description,
            showResultsToVoters: pollDetail.showResultsToVoters,
            weightedVotes: pollDetail.weightedVotes,
            geoTagging: pollDetail.geoTagging,
            geoFencing: pollDetail.geoFencing,
            questionShuffle: pollDetail.questionShuffle,
            anonymousVoting: pollDetail.anonymousVoting,
            websiteRedirect: pollDetail.websiteRedirect,
            discloseVoterInResult: pollDetail.discloseVoterInResult,
            status: pollDetail.status,
            startTime: pollDetail.startTime,
            endTime: pollDetail.endTime,
            resultPostOnPollClose: pollDetail.resultPostOnPollClose,
            startManually: pollDetail.startManually,
            alertTime: pollDetail.alertTime,
            alerted: pollDetail.alerted,
            voteWithSignUp: pollDetail.voteWithSignUp,
            announceResultAutomatically: pollDetail.announceResultAutomatically,
            allowVoteChange: pollDetail.allowVoteChange,
            displayOrganizerName: pollDetail.displayOrganizerName,
            scrutinizerHasToOpen: pollDetail.scrutinizerHasToOpen,
            realTimeResult: pollDetail.realTimeResult,
            verifyWithOtp: pollDetail.verifyWithOtp,
            pollType: pollDetail.pollType,
            conductFor: {
                personId: pollDetail.conductForPersonId
            },
            pollCategory: pollDetail.pollCategory,
            zoneId: pollDetail.zoneId
        }
        updatePollRoundService(payload);
    }

    const editPollSettings = () => {
        setIsEdit(true);
    }

    const cancelEditPollSettings = () => {
        getPoll();
        setIsEdit(false);
    }

    useEffect(() => {
        getPoll();
    }, [])
    
    // useEffect(() => {
    //     if (pollDetail.allowVoteChange === true) {
    //         setPollDetail(prevState => ({
    //             ...prevState,
    //             realTimeResult: false
    //         }))
    //     }
    // }, [pollDetail.allowVoteChange])

    return (
        <div>
            <Stack spacing={2} paddingX={1} paddingY={2}>
                <Stack direction='row' spacing={2}>
                    <Card className={classes.card}>
                        <CardContent>
                            {refresh && <PollSettings pollDetail={pollDetail} handleDataBoolean={handleDataBoolean} disabled={!isEdit}/>}
                            {refresh && 
                                <Fragment>
                                    <br/>
                                    <Typography variant="h6" style={{display: 'flex'}}>{t('Advance_Settings')}</Typography>
                                    <Divider/>
                                    {/* <Grid item xs={12}>
                                        <Divider textAlign="left">
                                            <Chip label={t('Advance_Settings')} />
                                        </Divider>
                                    </Grid> */}
                                    <br/>
                                    <Grid container spacing={2} style={{padding: '4px'}}>
                                        <PollAdvanceSettings pollDetail={pollDetail} handleDataBoolean={handleDataBoolean} disabled={!isEdit}/>
                                    </Grid>
                                </Fragment>
                            } 
                        </CardContent>
                        <CardActions>
                            <Grid container>
                                <Grid item xs={12}>
                                    {(!isEdit && canModify) && <Button className={classes.btn} size="small" color="primary" variant='contained'
                                            onClick={editPollSettings}>
                                        {t('Edit')}
                                    </Button>}
                                    {isEdit && 
                                        <Fragment>
                                            <Button className={classes.btn} size="small" color="primary" variant='contained'
                                                    onClick={handleSave}>
                                                {t('Save')}
                                            </Button>
                                            <Button className={classes.btn} size="small" color="primary" variant='outlined'
                                                    onClick={cancelEditPollSettings}>
                                                {t('Cancel')}
                                            </Button>
                                        </Fragment>
                                    }
                                </Grid>
                            </Grid>
                        </CardActions>
                    </Card>
                </Stack>
            </Stack>
            {message.showMsg && <EligoSnackBar show={message.showMsg} message={message.message}
                severity={message.severity} />}
            {loading && <EligoBackdrop show={loading} />}
        </div>
    )
}

export default UpdatePollSettings;