import { makeStyles } from "@material-ui/core/styles";
import { InfoRounded } from "@material-ui/icons";
import { Stack, Typography } from "@mui/material";

const useStyles = makeStyles(() => ({
    infoMultiChoice: {
        background: 'rgb(239, 245, 250)',
        color: 'rgb(7, 106, 187)',
        alignItems: 'center',
        fontWeight: 'bold !important',
        display: 'flex',
        padding: '10px',
        borderRadius: '0.4rem',
    },
    infoMultiIcon: {
        color: 'rgb(7, 106, 187)',
        marginRight: '0.4rem'
    },
    castVoteBg: {
        background: '#fff !important'
    }
}))

const MultiOptionMinMaxInfo = (props) => {
    const {minSelectOption, maxSelectOption, isCastVote} = props;
    const classes = useStyles();

    return (
        <Stack justifyContent={isCastVote ? 'right' : 'center'} alignItems='center' mt={isCastVote ? 1 : 2} direction='row' spacing={0.3}>
            <Typography color="primary"
                className={`${classes.infoMultiChoice} ${isCastVote ? classes.castVoteBg : ''}`} component='div' variant="subtitle2">
                <InfoRounded className={classes.infoMultiIcon} />
                {minSelectOption === 0 && maxSelectOption === 0 && `The Voter should select any options  `}
                {minSelectOption > 0 && maxSelectOption === '' && `The Voter should select minimum ${minSelectOption} ${minSelectOption > 1 ? `options` : `option`}`}
                {minSelectOption === '' && maxSelectOption > 0 && `The Voter should select maximum ${maxSelectOption} ${maxSelectOption > 1 ? `options` : `option`}`}
                {maxSelectOption !== '' && minSelectOption !== '' && minSelectOption === maxSelectOption && `The Voter should select exactly ${maxSelectOption} ${minSelectOption > 1 ? `options` : `option`}`}
                {minSelectOption !== '' && maxSelectOption !== '' &&
                    minSelectOption < maxSelectOption && `The Voter should select minimum ${minSelectOption} and maximum ${maxSelectOption} options`}
            </Typography>
        </Stack>
    )
}

export default MultiOptionMinMaxInfo;