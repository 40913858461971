import React, { useState } from 'react';
import {
    Card, Typography, Grid,
    TextField, Button, CircularProgress, Link,Box, ToggleButtonGroup, Tooltip, ToggleButton, InputAdornment
} from '@mui/material';
import { Auth } from 'aws-amplify';
import EligoSnackBar from '../../common/EligoSnackBar';
// import { ContentBox, RootDiv }                              from './AuthStyle';
import { useHistory } from 'react-router-dom';
import './Verify.css'
// import { RootDiv } from '../../common/evoting-styles/EvotingStyle';
import { AuthAxiosInterceptor } from '../../config/axios.interceptor';
import EligoReCaptcha from '../../common/EligoReCaptcha';
import { useTranslation } from 'react-i18next';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { RootDiv } from '../AuthStyle';
import {MdAttachEmail} from "react-icons/md"
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import MuiPhoneNumber from "material-ui-phone-number";

function VerifyEmail() {
    const { t } = useTranslation();
    const [username, setUsername] = useState('');
    const history = useHistory();
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('');
    const [errors, setError] = useState('');
    const [captcha, setCaptcha] = useState('');
    const [isRefresh, setIsRefresh] = useState(false);
    const [emailOrPhone, setEmailOrPhone] = useState('email')
    const [extension, setExtension] = useState('+91')

    const onMobileNumberChange = (value) =>{
        setExtension(value)
      }

    const handleValidation = (fieldValue) => {
        let valid = true;
        setError('')
        if (!fieldValue) {
            valid = false;
            setError("Email Id or Mobile number is required");
        } else {
            if(emailOrPhone === 'email'){
                if(!(/(([^<>()\[\]\\.,;\s@"]+(\.[^<>()\[\]\\.,;\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(fieldValue))) {
                        valid = false;
                        setError(t('Invalid_Email_Address'));
                }
            } else {
                if (fieldValue != undefined && fieldValue != '' && !isValidPhoneNumber( extension + fieldValue)) {
                        valid = false;
                        setError(t('Invalid_Mobile_number'));
                    }
            }
            // if (/^[0-9+]+$/.test(fieldValue)) {
            //     setIsnumber(true)
            //     if (fieldValue != undefined && fieldValue != '' && !isValidPhoneNumber(fieldValue)) {
            //      setIsValidForm(false);
            //     setError(t('Invalid_Mobile_number'));
            // }
            // } else if(!(/(([^<>()\[\]\\.,;\s@"]+(\.[^<>()\[\]\\.,;\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(fieldValue))) {
            //     setIsValidForm(false);
            //     setError(t('Invalid_Email_Address_CellPhone'));
            // }
        }
        return valid;
    }

    const handleInputChange = (event) => {
        let value = event.target.value;
        // if (!/^[0-9+]+$/.test(username)) {
        //     value = value.trim().toLowerCase();
        // }
        setUsername(value.trim().toLowerCase());
    }

    const sendOtp = event => {
        event.preventDefault();
        setErrorMessage('');
        let isValid = handleValidation(username);
        if (isValid) {
            setLoading(true);
            AuthAxiosInterceptor.patch(`user/request-otp?recaptcha=${captcha}&purpose=verify-signup`, {
                loginId: emailOrPhone === 'email' ? username : extension + username
            }).then(response => {
                setErrorMessage('');
                setLoading(false);
                history.push({
                    pathname: "/sign-up/success", state: {
                        emailid: emailOrPhone === 'email' && username,
                        phoneNumber: emailOrPhone === 'mobile' && extension + username
                    }
                })
            }).catch((error) => {
                setIsRefresh(true);
                setTimeout(() => {
                    setIsRefresh(false);
                }, 1000);
                if (error.message) {
                    setErrorMessage(error.message);
                }
                setLoading(false);
            })
            // try {
            //     console.log("before")
            //     await Auth.resendSignUp(username)
            //         .then(res => {
            //             console.log(res);
            //             setErrorMessage('');
            //             setLoading(false);
            //             history.push({
            //                 pathname: "/sign-up/success", state: {
            //                     emailid: username
            //                 }
            //             })
            //         })
            // } catch (error) {
            //     setLoading(false);
            //     console.log("error", error);
            //     setErrorMessage(error.message);
            // }
        }
    }

    const handleLoginDetails = (event, newValue) => {
        setError('')
        setUsername('')
        setEmailOrPhone(newValue === null ? emailOrPhone : newValue)
    }

    const handleMobileNumberChange = (fieldName, event) => {
        if(/^[0-9]+$/.test(event.target.value)){
            setUsername(event.target.value)
        }
    }

    const login = () => {
        history.push("/sign-in")
    }
    return (
        <RootDiv className='container'>
            {/* <h2>hello</h2> */}
            <Card>
                <Grid container>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box style={{ padding: '40px 32px' }}>
                            <Typography id='verify' component="h1" variant="h5" gutterBottom className="text-center">
                                Account Verification
                            </Typography>
                            <Typography id='verify-email' variant="body2" component="p" className="text-center">
                                <strong>Please provide your registered email address or Mobile Number to receive OTP</strong>
                            </Typography><br />
                            <div  style={{textAlign:'center'}}>
                            <ToggleButtonGroup
                                    value={emailOrPhone}
                                    exclusive
                                    size="small"
                                    onChange={handleLoginDetails}
                                    aria-label="text alignment"
                                  >
                                    {/* <Tooltip title="Login using Email"> */}
                                      <ToggleButton
                                        value="email"
                                        aria-label="left aligned"
                                        style={{background: emailOrPhone === "email" ? "#2a83ad" : "",color: emailOrPhone === "email" ? "white" : "",
                                          borderRadius: "50px 0px 0px 50px",fontWeight: "bold", fontSize: '12px', paddingLeft: '1em'
                                        }}
                                      >
                                        <MdAttachEmail fontSize='large'/>
                                        &nbsp; {t("Email_Address")}
                                      </ToggleButton>
                                    {/* </Tooltip> */}
                                    {/* <Tooltip title="Login using Mobile Number"> */}
                                      <ToggleButton
                                        value="mobile"
                                        aria-label="centered"
                                        style={{background: emailOrPhone === "mobile" ? "#2a83ad" : "",color: emailOrPhone === "mobile" ? "white" : "",
                                          borderRadius: "0px 50px 50px 0px", fontWeight: "bold", fontSize: '12px', paddingRight: '1em'
                                        }}
                                      >
                                        <PhoneIphoneIcon /> Mobile Number
                                      </ToggleButton>
                                    {/* </Tooltip> */}
                                  </ToggleButtonGroup>
                                  </div>
                           { emailOrPhone === 'email' ? <TextField id="email" label={t("User_Id_(Email Address)")}
                                style={{ margin: '16px 8px 8px 0px' }} fullWidth value={username} autoFocus
                                onChange={handleInputChange} helperText={errors}
                                error={ errors != ''} autoComplete="off" 
                                disabled={loading}
                            /> : <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                            <TextField
                            id="phonenumber"
                            name="phonenumber"
                            // type="number"
                            style={{margin: "16px 8px 8px 0px"}}
                            label={t("Username_Mobile_Number")}
                            variant="outlined" fullWidth
                            error={(errors)}
                            helperText={errors}
                            value={ emailOrPhone === 'mobile' && username}
                            onChange={(event, country) => {
                                    handleMobileNumberChange('phoneNumber', event, country)
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <MuiPhoneNumber
                                            id="input-with-icon-adornment"
                                            name="extension"
                                            defaultCountry={"in"}
                                            value={extension}
                                            onChange={(event) => onMobileNumberChange(event)}
                                            autoFormat={false}
                                            style={{margin: "8px 0px", color: "black !important",
                                            width: extension.length > 3 ? extension.length === 4 ? '4.8rem' : extension.length === 5 ? '5.3rem' : '6.3rem' : '4rem'}}
                                            required
                                            disableDropdown={loading}
                                            countryCodeEditable={false}
                                            disabled
                                            InputProps={{
                                                disableUnderline: true,
                                            }}
                                        />
                                    </InputAdornment>
                                ),
                            }}
                            />
                   </Box>}
                            <Grid item xs={12} className="text-center" style={{ marginTop: '15px' }}>
                                <Link id='verify-sign' onClick={login} style={{ float: 'left', fontSize: '12px', cursor: 'pointer' }}>Back to Sign In</Link>
                                <Button id='verify-otp' size="small" variant="contained" color="primary" style={{ float: 'right' }}
                                    onClick={sendOtp}
                                    disabled={loading}>
                                    Send OTP
                                    {loading && <CircularProgress style={{ position: 'absolute' }} size={14} thickness={4.5} />}
                                </Button>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>

            </Card>
            {errorMessage !== '' && errorMessage !== undefined ?
                <EligoSnackBar show={true} message={errorMessage} severity="error" reset={() => setErrorMessage(null)}></EligoSnackBar> : null}
            {!isRefresh && <EligoReCaptcha captcha={setCaptcha} refresh={setIsRefresh} />}
        </RootDiv>
    );
}




export default VerifyEmail
