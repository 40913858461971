import {
    Box,
    Checkbox, IconButton,
    TableCell, TableRow, TextField, Tooltip, Typography,
    makeStyles
} from '@material-ui/core';
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import SendIcon from '@material-ui/icons/NotificationsActive';
import { Avatar, Collapse, Stack } from '@mui/material';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-number-input';
import "react-phone-number-input/style.css";
import EligoBackdrop from '../../../common/EligoBackdrop';
import EligoSnackBar from '../../../common/EligoSnackBar';
import { Status, TypeOfPoll } from '../../../common/GenericCodes';
import GraphQLErrors from '../../../common/GraphQLErrors';
import { UserContext } from '../../../common/context/UserContext';
import * as Validators from '../../../common/form-validators.js';
import AuthRepForExistingVoter from './AuthRepForExistingVoter';
import AuthRepresenties from './AuthRepresenties';
import CustomPhoneNumber from './CustomPhoneNumber.js';
import './PollRoundVoterRow.css';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import EligoConfirmDialog from '../../../common/EligoConfirmDialog';
import { AxiosEvotingInterceptor } from '../../../config/axios.interceptor';
import VoterAdd from '../poll-round-voter-list/VoterAdd';
import MuiPhoneNumber from "material-ui-phone-number";

const useStyles = makeStyles({
    disableColor: {
        '& .MuiInputBase-root.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.7) !important'
        },
        '& .MuiIconButton-root.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.7) !important'
        },
    },
    avatar: {
        backgroundColor: `rgb(59, 175, 218) !important`,
        width: '34px !important',
        height: '34px !important',
        marginTop: "5px"

    }

});

const PollRoundVoterRow = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const context = useContext(UserContext)
    const [errors, setErrors] = useState({});
    const [touched, setTouched] = useState({});
    const [loading, setLoading] = useState(false)
    const [pollId] = useState(props.pollId);
    const [open, setOpen] = React.useState(false);
    const [pollRoundVoter, setPollRoundVoter] = useState({
        id: props.voter.id,
        voterId: props.voter.voterId,
        name: props.voter.name,
        email: props.voter.email,
        phoneNumber: props.voter.phoneNumber,
        poll_id: props.voter.poll_id,
        // sequence: props.voter.sequence,
        weightage: props.voter.weightageString,
        cognito_user: props.voter.cognito_user,
        otp_verified_on: props.voter.otpVerifiedOn,
        remainderCount: props.voter.reminderCount,
        previousState: {},
        isNew: props.voter.isNew,
        isEdit: props.voter.isEdit,
        authorizedRepresentative: props.voter.authorizedRepresentative,
        startTime: props.voter.startTime,
        pollStatus: props.pollStatus,
        countryCode: props.voter.phoneDetail? props.voter.phoneDetail.countryCode : null,
        nationalNumber: props.voter.phoneDetail ? props.voter.phoneDetail.nationalNumber : null
    })
    const [message, setMessage] = React.useState({
        showMsg: false,
        message: null,
        severity: null
    });
    const [openSinglevoter, setOpenSingleVoter] = useState(false)
    const [editSingleDetails, setEditSingleDetails] = useState({})
    const [addAuthReps, setAddAuthReps] = useState(false)
    const [seekConfirmation, setSeekConfirmation] = useState({
        show: false,
        title: t("Confirmation"),
        message: '',
        onAgree: '',
        onDisAgree: ''
    })
    const [notVotedVoters, setNotVotedVoters] = useState(false)
    const [showReminder,setShowReminder] = useState(false)

    const validate = {
        name: name => Validators.requiredValidation(t('Name'), name),
        email: Validators.emailValidation,
        phoneNumber: Validators.mobileNumberValidation,
        weightage: weightage => Validators.percentageValidation(t('Weightage'), weightage)
    };

    const handleEditVoter = (voterId, authorizedRepresentative) => {
        setEditSingleDetails(
            {
                name: pollRoundVoter.name,
                weightage: pollRoundVoter.weightage,
                email: pollRoundVoter.email,
                phoneNumber: pollRoundVoter.phoneNumber,
                nationalNumber:pollRoundVoter.countryCode === 1 ? pollRoundVoter.nationalNumber.toString().slice(3, 10) : pollRoundVoter.nationalNumber,
                countryCode:pollRoundVoter.countryCode === 1 ? pollRoundVoter.countryCode + pollRoundVoter.nationalNumber.toString().slice(0, 3) : pollRoundVoter.countryCode,
                voterId: voterId,
                authorizedRepresentativeLength: authorizedRepresentative.length > 0 ? true : false
            }
        )
        setOpenSingleVoter(true)
    }
    const sendRemainder = (voterIdForReminder) => {
        setLoading(true)
        props.setMessage({ showMsg: false, message: '', severity: '' })
        AxiosEvotingInterceptor.get(`/voter/remainder/${pollId}?voterId=${voterIdForReminder}`).then((response) => {
            if (response) {
                props.refreshVoter();
                props.setMessage({ showMsg: true, message: t("Reminder_Sent_Successfully"), severity: 'success' })
            }
        }).catch((error) => {
            setLoading(false)
            props.setMessage({ showMsg: true, message: error.message, severity: 'error' })
        })
    }

    const handleDeleteVoter = (voter) => {
        setLoading(true)
        const payload = [voter.voterId]
        AxiosEvotingInterceptor.put(`voter/delete/${pollId}`, payload).then(response => {
            if (response) {
                props.refreshVoter('voterDelete')
            }
        }).catch((error) => {
            setLoading(false)
            setMessage({
                show: true,
                message: error.message,
                severity: 'error'
            })
        })
    }


    const validateForm = async (voter) => {
        let formErrors = { errors: {}, touched: {} };
        Object.entries(voter).map(([key]) => {
            if (key === 'name' || key === 'email' || key === 'phoneNumber' || key === 'weightage') {
                const newError = validate[key](voter[key]);
                const newTouched = { [key]: true };
                return formErrors = {
                    errors: {
                        ...formErrors.errors,
                        ...(newError && { [key]: newError }),
                    },
                    touched: {
                        ...formErrors.touched,
                        ...newTouched
                    }
                }
            }
        })
        setErrors(prevState => ({
            ...prevState,
            ...formErrors.errors
        }))
        setTouched(prevState => ({
            ...prevState,
            ...formErrors.touched
        }))
        return formErrors;
    }

    const handleRowClick = () => {
        console.log("handleRowClick")
    }

    const addAuthRep = () => {
        setAddAuthReps(true)
    }

    const confirmation = (pollRoundVoter, reminder) => {
        setShowReminder(reminder)
        setSeekConfirmation(prevState => ({
            ...prevState,
            show: true,
            message: reminder ?<Fragment>{t('Are_You_Sure_That_You_Want_To')} <b>{t('Send_Reminder_For')}</b> <b>{pollRoundVoter.name}</b> {t('?')}</Fragment> : 
            <Fragment>{t('Are_You_Sure_That_You_Want_To')} <b>{t('Delete')}</b> {t('Voter')} <b>{pollRoundVoter.name}</b> {t('?')}</Fragment>,
            onAgree: reminder ? () => sendRemainder(pollRoundVoter.voterId) : () => handleDeleteVoter(pollRoundVoter),
            onDisAgree: resetConfirmation,
        }))
    }

    const resetConfirmation = () => {
        setShowReminder(false)
        setSeekConfirmation(prevState => ({
            ...prevState,
            show: false,
            title: t("Confirmation"),
            message: '',
            onDisAgree: '',
            onAgree: ''
        }))
    }

    useEffect(() => {
        const notYetVotedVoters = ((pollRoundVoter.otp_verified_on == null || pollRoundVoter.otp_verified_on == ''))
        setNotVotedVoters(notYetVotedVoters)
    }, [])

    return (
        <React.Fragment>
            <TableRow id={`table-row${props.index}`} hover={props.voter.authorizedRepresentative.length > 0} disabled={props.pollStatus === Status.closed || props.pollType === Status.canceled}
                selected={props.isItemSelected} style={{ cursor: props.voter.authorizedRepresentative.length > 0 ? 'pointer' : 'auto' }}
            >
                <TableCell align='center'>
                    {props.voter.authorizedRepresentative.length > 0 &&
                        <Tooltip title={open ? t('Close_Authorized_Representative') : t('Show_Authorized_Representative')}>
                            <IconButton
                                aria-label="expand row"
                                id={`icon-btn${props.index}`}
                                size="small"
                                disabled={props.isItemSelected}
                                onClick={() => setOpen(!open)}
                            >
                                {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                            </IconButton>
                        </Tooltip>}
                </TableCell>
                {notVotedVoters && props.pollStatus != Status.closed && props.pollStatus != Status.canceled && (props.pollStatus != Status.open ? props.listBy === 'all' : props.listBy === 'not-voted') &&
                    <TableCell padding="checkbox" >
                        <Checkbox
                            id={`check-box${props.index}`}
                            color="secondary"
                            checked={props.isItemSelected}
                            disabled={(props.pollStatus === Status.closed || props.pollType === Status.canceled)}
                            onClick={(event) => props.handleClick(event, pollRoundVoter.voterId)}
                        />
                    </TableCell>}
                <TableCell align='left' onClick={props.voter.authorizedRepresentative.length > 0 ? () => setOpen(!open) : handleRowClick} disabled={false} style={{ padding: '8px 0px 0px 0px' }}>
                    <Stack direction='row' spacing={2}>
                        <Stack>
                            {props.voter.authorizedRepresentative.length > 0 ?
                                <Avatar id={`avatar${props.index}`} className={classes.avatar}>
                                    <PeopleAltIcon />
                                </Avatar> : <Avatar id={`avatar${props.index}`} className={classes.avatar}>
                                </Avatar>}
                        </Stack>
                        <Stack>
                            <Typography id={`user-name${props.index}`}><b>{pollRoundVoter.name}</b></Typography>
                            <Typography id={`user-email${props.index}`} style={{ color: 'grey' }}>{pollRoundVoter.email ? pollRoundVoter.email : '--'}</Typography>
                        </Stack>
                    </Stack>
                </TableCell>
                <TableCell align='left' style={{ padding: '0px' }} onClick={props.voter.authorizedRepresentative.length > 0 ? () => setOpen(!open) : handleRowClick}>
                    {!pollRoundVoter.isEdit && pollRoundVoter.phoneNumber === null ?
                        <TextField id="phone-number"
                            className={classes.disableColor}
                            style={{ marginLeft: '50px' }}
                            disabled
                            value='--'
                            fullWidth InputProps={{
                                disableUnderline: true
                            }} />
                        :
                        <MuiPhoneNumber
                            id="phonenumber"
                            name="phonenumber"
                            defaultCountry={"in"}
                            value={pollRoundVoter.phoneNumber}
                            autoFormat={true} fullWidth
                            countryCodeEditable={false}
                            disableDropdown={true}
                            disabled={!pollRoundVoter.isEdit || pollRoundVoter.cognito_user}
                            InputProps={{
                                disableUnderline: !pollRoundVoter.isEdit || pollRoundVoter.cognito_user
                            }}
                        />
                    }
                </TableCell>
                {props.pollClass === TypeOfPoll.POLL && <TableCell align='left' onClick={props.voter.authorizedRepresentative.length > 0 ? () => setOpen(!open) : handleRowClick}>
                    <Typography style={{ marginLeft: '-11px' }}>{pollRoundVoter.weightage}&nbsp;%</Typography>
                </TableCell>}
                <TableCell align='left' style={{ paddingLeft: '0px', paddingRight:'0px', width:'18%' }}>
                    {((props.pollStatus != Status.closed && props.pollStatus != Status.canceled && (notVotedVoters)) ||  props.hasSystemAdmineOrSupportRole) &&
                        <Tooltip title={t("Edit")}>
                            <IconButton id={`edit${props.index}`} edge="start" aria-label="edit-poll-voter" color="primary" size="small" disabled={props.isItemSelected}
                                onClick={() => handleEditVoter(pollRoundVoter.voterId, pollRoundVoter.authorizedRepresentative)}>
                                <EditIcon id='edit-poll-voter' />
                            </IconButton>
                        </Tooltip>}
                    {((props.pollStatus != Status.closed && props.pollStatus != Status.canceled && (notVotedVoters)) ||  props.hasSystemAdmineOrSupportRole) &&
                        <Tooltip id='delete-' title={t("Delete")}>
                            <IconButton id={`delete${props.index}`} edge="end" aria-label="delete-poll-voter" color="secondary" size="small" disabled={props.isItemSelected}
                                onClick={() => confirmation(pollRoundVoter, false)}>
                                <DeleteIcon id='delete-icon' />
                            </IconButton>
                        </Tooltip>
                    }
                    {((notVotedVoters || props.hasSystemAdmineOrSupportRole) && props.listBy != 'voted') && (props.pollStatus != Status.closed && props.pollStatus != Status.canceled || props.hasSystemAdmineOrSupportRole) &&
                        <Tooltip id='delete' title={t("Add_authorized_Representative")}>
                            <IconButton id={`add${props.index}`} edge="end" aria-label="delete-poll-voter" color="primary" size="small" disabled={props.isItemSelected}
                                onClick={addAuthRep}>
                                <AddCircleOutlinedIcon id='add-icon' />
                            </IconButton>
                        </Tooltip>
                    }
                    {((props.pollStatus != Status.closed && props.pollStatus != Status.canceled && props.pollStatus === Status.open) || (props.hasSystemAdmineOrSupportRole && props.pollStatus != Status.upcoming && props.pollStatus != Status.setup)) && (notVotedVoters) &&
                        <Tooltip title={` ${t('Total_Reminders_Sent')} - ${pollRoundVoter.remainderCount ? pollRoundVoter.remainderCount : 0}`}>
                            <IconButton id='send' edge="end" aria-label="send-reminder" color="primary" size="small" disabled={props.isItemSelected}
                                onClick={() => confirmation(pollRoundVoter, true)}>
                                <SendIcon id='send-button' />
                            </IconButton>
                        </Tooltip>}
                    {(props.pollStatus != Status.canceled || props.hasSystemAdmineOrSupportRole) && !pollRoundVoter.isEdit &&
                        <React.Fragment>
                            {(!notVotedVoters) && props.listBy != 'not-voted' &&
                                <span className="voted" style={{ marginLeft: "4px", background: '#d2f7d5', borderRadius: '30px', color: 'green', fontWeight: 'bold', padding: '7px' }}>{t('VOTED')}</span>
                                // <DoneIcon className='voted'/>
                            }
                            {(notVotedVoters) && (props.pollStatus === Status.closed || (props.hasSystemAdmineOrSupportRole && props.pollStatus != Status.upcoming && props.pollStatus != Status.setup)) &&
                                <React.Fragment>
                                    {(props.pollStatus != Status.open || props.hasSystemAdmineOrSupportRole) && props.listBy != 'voted' &&
                                        <span className="not-voted" style={{ marginLeft: "4px", background: 'rgb(248 240 240)', borderRadius: '30px', color: 'red', fontWeight: 'bold', padding: '7px' }}>{t('NOT_VOTED')}</span>
                                    }
                                </React.Fragment>
                            }
                        </React.Fragment>
                    }
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                    <Collapse in={props.isItemSelected ? false : open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <AuthRepresenties pollRoundVoter={pollRoundVoter} totalNumOfAuthReps={props.totalNumOfAuthReps} editable={props.pollStatus != Status.closed && props.pollStatus != Status.canceled} notVotedVoters={notVotedVoters}
                                refreshVoter={(responseVoter) => props.refreshVoter(responseVoter)} pollId={pollId} setLoading={setLoading} hasSystemAdmineOrSupportRole={props.hasSystemAdmineOrSupportRole} 
                                pollClass={props.pollClass}/>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
            {/* {
                openSinglevoter && <SingleVoter open={openSinglevoter} setAuth={setOpenSingleVoter} refreshVoter={props.refreshVoter}
                    editSingleDetails={editSingleDetails} pollId={pollId} />
            } */}
            {
                openSinglevoter && <VoterAdd open={openSinglevoter} closeVoterDialog={setOpenSingleVoter} refreshVoter={props.refreshVoter}
                    editSingleDetails={editSingleDetails} pollId={pollId} editVoterDetails={true} pollClass={props.pollClass}/>
            }
            {/* {
                addAuthReps && <AuthRepForExistingVoter open={addAuthReps} pollId={pollId} pollRoundVoter={pollRoundVoter} setAuthReps={setAddAuthReps}
                    refreshList={props} />
            } */}
            {
                addAuthReps && <VoterAdd open={addAuthReps} pollId={pollId} editSingleDetails={editSingleDetails} pollRoundVoters={pollRoundVoter} closeVoterDialog={setAddAuthReps}
                    refreshList={props} addExisting={true} pollClass={props.pollClass}/>
            }
            {
                (loading) &&
                <EligoBackdrop show={loading} invisible={true} />
            }
            {
                message.showMsg && <EligoSnackBar show={message.showMsg} message={message.message}
                    severity={message.severity} />
            }
            {
                seekConfirmation.show && <EligoConfirmDialog show={seekConfirmation.show} showGreen={showReminder}
                    title={seekConfirmation.title} message={seekConfirmation.message}
                    onAgree={seekConfirmation.onAgree} onDisAgree={seekConfirmation.onDisAgree} ConfirmFor={true} />
            }

        </React.Fragment >
    )
}

export default PollRoundVoterRow;
