import {
    Box, Button, ButtonGroup, Chip, ClickAwayListener, Container, FormControlLabel, FormGroup, Grid, Grow, makeStyles, MenuItem, MenuList, Paper, Popper, Switch, Tooltip, Typography,
    useTheme
} from '@material-ui/core';
import AddAlertIcon from '@material-ui/icons/AddAlert';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Divider, Stack } from '@mui/material';
import axios from 'axios';
import { format } from 'date-fns';
import React, { Fragment, useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import EligoBackdrop from '../../../common/EligoBackdrop';
import EligoConfirmDialog from '../../../common/EligoConfirmDialog';
import EligoSnackBar from '../../../common/EligoSnackBar';
import { ChipPollStatus, ChipPollTitle } from '../../../common/evoting-styles/EvotingStyle';
import { Roles, Status, TypeOfPoll, TypeOfPollCategory, UserRole } from '../../../common/GenericCodes';
import { PollListContainer } from '../../../nav-bar/side-nav/side-nav-style/SideNavStyle';
import PlanDetails from '../../../payment/plan-details/PlanDetails';
import FileUploadDownload from '../../file-upload-download/FileUploadDownload';
import PollRoles from '../../manage-role/poll-roles/PollRoles';
import PollQuestions from '../../poll-question/poll-questions/PollQuestions';
import PollRoundVoterList from '../../poll-round-voter/poll-round-voter-list/PollRoundVoterList';
import EditPollRound from '../edit-poll-round/EditPollRound';
import RoundResult from '../poll-round-result/round-result/RoundResult';
import PollInfoSideBar, { PollInfoMenu } from './PollInfoSideBar';
import UpdatePollRoundsStatus from './UpdatePollRoundsStatus';
import AuditTrail from '../../audit-trail/AuditTrail';
import GenerateResult from '../poll-round-result/generate-result/GenerateResult';
import { UserContext } from '../../../common/context/UserContext';
import VotersTab from '../../poll-round-voter/voter-tab/VotersTab';
import AuditSmsEmail from '../audit-sms-and-email/AuditSmsEmail';
import PollQuestionList from '../../poll-matter/poll-question-list/PollQuestionList';
import UpdatePollSettings from '../poll-settings/UpdatePollSettings';
import PublishPoll from '../../poll-list/PublishPoll';
import { PollContext } from '../../../common/context/PollContext';
import PreviewResult from '../poll-round-result/generate-result/PreviewResult';

const useStyles = makeStyles((theme) => ({
    margin: {
        marginTop: theme.spacing(2),
    },
    listIconMargin: {
        margin: theme.spacing(0.4),
    },
    tabPanel: {
        backgroundColor: theme.palette.background.paper
    },
    btn: {
        marginLeft: theme.spacing(1),
        float: 'right'
    },
    paper: { width: '30%' },
    stickyTop: {
        position: 'sticky',
        top: '64px',
        zIndex: '1000',
        background: '#fff',
        borderRadius: '0px 0px 20px 20px',
        border: '1px solid #eeeeee',
        padding: '0px 16px'
    },
    openChipColor:{
        background: 'linear-gradient(195deg, rgb(194, 255, 226), rgb(194, 255, 226))',
        color: '#0e4d0e'
    },
    upcomingChipColor:{
        background: 'linear-gradient(195deg, rgb(254, 230, 174), rgb(255, 208, 135))',
        color: 'rgb(121,70,0)'
    },
    setupChipColor:{
        background: 'linear-gradient(195deg, rgb(223, 235, 247), rgb(225, 236, 250))',
        color: 'rgb(6, 76, 91)'
    },
    closedChipColor:{
        background: 'linear-gradient(195deg, rgb(251, 193, 193), rgb(253, 164, 164))',
        color: 'rgb(121, 0, 0)'
    },
    canceledChipColor:{
        background:'linear-gradient(195deg, rgb(254, 236, 209), rgb(253, 164, 164))',
        color: 'rgb(98, 70, 2)'
    }
}));


function TabPanel(props) {
    const { children, value, index } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
        >
            {value === index && (
                <Box p={1} style={{ minHeight: "50vh", maxHeight: "75vh" }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const PollRoundInfo = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const classes = useStyles();
    const pollContextDetails = useContext(PollContext);
    const [selectedMenu, setSelectedMenu] = useState(PollInfoMenu.POLL_DETAILS)
    const theme = useTheme();
    const [tab, setTab] = useState(0);
    const [resultWithVoters, setResultWithVoters] = useState(false);
    const [fileLength, setFileLength] = useState(0)
    const [loading, setLoading] = useState(false);
    const [noOfVoters, setNoOfVoters] = useState(props.location.state.pollDetails.voterCount ? props.location.state.pollDetails.voterCount : 0);
    const [paidVoters, setPaidVoters] = useState(props.location.state.pollDetails.paidVoter ? props.location.state.pollDetails.paidVoter : 0);
    const [pollRoundDateExpired, setPollRoundDateExpired] = useState(false);
    const [pollTitle, setPollTitle] = useState(props.location.state.pollDetails.title);
    const [generateResult, setGenerateResult] = useState(false)
    let componentRef = null;
    const [seekConfirmation, setSeekConfirmation] = useState({
        title: '',
        message: '',
        show: false,
        agreeBtnLabel: '',
        disAgreeBtnLabel: '',
        onAgree: '',
        onDisAgree: ''
    })
    const [message, setMessage] = React.useState({
        showMsg: false,
        message: null,
        severity: null
    });
    const [payment, setPayment] = useState(false);
    const anchorRef = React.useRef(null);
    const [openInvoiceVersions, setOpenInvoiceVersions] = React.useState(false);
    const [selectedIndex, setSelectedIndex] = React.useState(1);
    const [invoiceVersions, setInvoiceVersions] = React.useState([]);
    const [isAllVotersVotedToClosePoll, setIsAllVotersVotedClosePoll] = useState(false);
    const context = useContext(UserContext);
    const [pollStatus, setPollStatus] = useState('');
    const [noOfVotedNotVoted, setNoOfVotedNotVoted] = useState({
        voted: 0,
        notVoted: 0,
    })
    const [refresh, setRefresh] = useState(false)

    // pollDetail state making available for other components
    const [pollInfo, setPollInfo] = useState({});

    useEffect(() => {
        if (new Date(props.location.state.pollDetails.end_date_time) < new Date()) {
            setPollRoundDateExpired(true);
        } else {
            setPollRoundDateExpired(false)
        }
    }, [])

    const backToPollRounds = (message) => {
        props.history.push({
            pathname: '/polls',
            state: {
                message: message,
                pollType: props.location.state.pollType
            }
        });
    }

    const confirmClosePoll = () => {
        setSeekConfirmation({
            title: t('Confirmation'),
            message: `${t("Are_You_Sure_That_You_Want_To_Close_The_Poll")}`,
            show: true,
            agreeBtnLabel: null,
            disAgreeBtnLabel: null,
            onAgree: closePoll,
            onDisAgree: resetSeekConfirmation
        })
    }


    const resetSeekConfirmation = () => {
        setSeekConfirmation({
            title: '',
            message: '',
            show: false,
            agreeBtnLabel: null,
            disAgreeBtnLabel: null,
            onAgree: '',
            onDisAgree: ''
        })
    }

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    }

    const handleTabChangeIndex = (index) => {
        setTab(index);
    };

    const onPaymentSuccess = () => {
        setPayment(false);
        // backToPollRounds()
        setSelectedMenu(PollInfoMenu.POLL_DETAILS)
    }

    const confirmAnnounceResult = () => {
        setSeekConfirmation({
            title: t('Confirmation'),
            message: `${t("Are_You_Sure_That_You_Want_To_Announce_Result")}?`,
            show: true,
            agreeBtnLabel: null,
            disAgreeBtnLabel: null,
            // onAgree: props.location.state.pollDetails.disclose_voter_in_result ? () => announceResultToVoter(true)
            //     : () => announceResultToVoter(false),
            onDisAgree: resetSeekConfirmation

        })
    }

    const manageRoles = () => {
        history.push({
            pathname: "/polls/manage-roles",
            state: {
                pollType: props.location.state.pollType,
                pollDetails: props.location.state.pollDetails,
                roles: props.location.state.roles
            }
        })
    }

    const handleToggle = () => {
        setOpenInvoiceVersions((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpenInvoiceVersions(false);
    };

    const handleMenuItemClick = (event, invoice) => {
        downloadInvoice(invoice);
    };

    const downloadLatestVersion = () => {
        if (invoiceVersions.length > 0) {
            downloadInvoice(invoiceVersions[0]);
        }
    }

    const downloadInvoice = (invoice) => {
        // getFileUrl({ variables: { fileName: invoice.file_name, bucket: invoice.bucket_name, versionId: invoice.version_id, fileEvent: 'download' } })
    }

    const announceResultToVoter = (value) => {
        // announceResult({
        //     variables: {
        //         id: [props.location.state.pollDetails.id],
        //         announce_with_voter_name: value
        //     }
        // })
    }

    const closePoll = () => {
        // closePollMutation({
        //     variables: {
        //         id: props.location.state.pollDetails.id
        //     }
        // })
    }

    const confirmResultGeneration = () => {
        setSeekConfirmation({
            title: t('Confirmation'),
            message: `${t("Would_You_Like_To_Generate_Result_With_Voter_Name")}`,
            show: true,
            agreeBtnLabel: t('Yes'),
            disAgreeBtnLabel: t('Yes'),
            onAgree: () => generateResultInPdf(true),
            onDisAgree: () => generateResultInPdf(false)
        })
    }

    const generateResultInPdf = (withVoterName) => {
        let data = {
            id: props.location.state.pollDetails.id,
            user_id: context.userSession.session.userId,
            with_voter_name: withVoterName
        }
        setLoading(true);
        setMessage({ showMsg: false, message: null, severity: '' });
        try {
            axios({
                url: `${process.env.REACT_APP_AWS_GATEWAY_POLL_API_URL}/generate-result-file`,
                method: 'POST',
                data: data,
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                }
            }).then((response) => {
                axios({
                    url: response.data,
                    method: 'GET',
                    responseType: 'blob'
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Poll-Result.pdf');
                    document.body.appendChild(link);
                    link.click();
                })
                setLoading(false);
            }).catch(error => {
                setLoading(false);
                setMessage({ showMsg: true, message: error, severity: 'error' });
            })
        } catch (error) {
            setLoading(false);
            setMessage({ showMsg: true, message: error, severity: 'error' });
        }
        resetSeekConfirmation();
    }

    useEffect(() => {
        if (props.location.state.pollDetails.status === Status.ready) {
            if (new Date() > new Date(props.location.state.pollDetails.endTime)) {
                setPollStatus(Status.closed);
            } else if (new Date() > new Date(props.location.state.pollDetails.startTime)) {
                setPollStatus(Status.open);
            } else {
                setPollStatus(Status.upcoming);
            }
        } else if (props.location.state.pollDetails.status === Status.canceled) {
            setPollStatus(Status.canceled);
        } else {
            setPollStatus(Status.setup);
        }
    }, [])

    const hasSystemAdmineOrSupportRole = (context.userSession.session.roles?.includes(UserRole.Role_Admin) || context.userSession.session.roles?.includes(UserRole.Role_Support))
    console.log("Status.canceled",pollContextDetails.pollDetails.selectedStatus )
    return (
        <>
            <Stack direction='row'>
                <Stack width='15%'>
                    {/* Side bar goes here */}
                    <PollInfoSideBar width='15%' setSelectedMenu={setSelectedMenu} selectedMenu={selectedMenu} backToPollRounds={backToPollRounds}
                        showRoles={((props.location.state.isSystemAdmine || props.location.state.roleType === Roles.organizer && props.location.state.pollType === Status.closed.toLowerCase()) || (props.location.state.roleType != Roles.observer && props.location.state.roleType != Roles.scrutinizer) &&
                            (props.location.state.pollType != Status.closed.toLowerCase() && props.location.state.pollType != Status.canceled.toLowerCase()))}
                        showResult={(props.location.state.roleType != Roles.voter && (pollStatus === Status.closed)) || (hasSystemAdmineOrSupportRole && pollStatus != Status.setup && pollStatus != Status.upcoming)}
                        showPayment={(props.location.state.roles.includes(Roles.organizer) && (pollStatus == Status.new || pollStatus == Status.scheduled ||
                            pollStatus == Status.open))}
                        questionTitle={(TypeOfPollCategory.IBC === props.location.state.pollDetails.pollCategory) ? t('Resolutions') : t('Questions')}
                        pollClass={props.location.state.pollDetails.pollType}
                    />
                </Stack>
                <PollListContainer>
                    <Stack margin={0} direction='column'>
                        <Stack className={classes.stickyTop}>
                            <Stack direction='row' margin='0.4rem' alignItems='center'>
                                <Stack width={{ xs: '63%' }} direction='row' alignItems='center' spacing={1.5}>
                                    <Typography variant='h5'>
                                        {selectedMenu === PollInfoMenu.POLL_DETAILS && <>
                                        {props.location.state.pollDetails.pollType === TypeOfPoll.POLL ? t('Poll_Details') : t('Survey_Details')}</>}
                                        {selectedMenu === PollInfoMenu.MATTERS && <>
                                            {props.location.state.pollDetails.pollType === TypeOfPoll.POLL ? 
                                                ((TypeOfPollCategory.IBC === props.location.state.pollDetails.pollCategory) ? t('Resolutions') : t('Questions')) :
                                                t('Survey_Questions')}</>}
                                        {selectedMenu === PollInfoMenu.VOTERS && <>
                                            {props.location.state.pollDetails.pollType === TypeOfPoll.POLL ? t('Voters') : t('Survey_Participants')}</>}
                                        {selectedMenu === PollInfoMenu.FILES && <>{t('Files')}</>}
                                        {selectedMenu === PollInfoMenu.PAYMENT && <>{t('Payment')}</>}
                                        {selectedMenu === PollInfoMenu.ROLES && <>{t('Roles')}</>}
                                        {selectedMenu === PollInfoMenu.AUDIT_SMS_EMAIL && <>{t('Sms_And_Email')}</>}
                                        {selectedMenu === PollInfoMenu.RESULT && <>{t('Result')}</>}
                                        {/* {selectedMenu === PollInfoMenu.SETTINGS && <>{t('Settings')}</>} */}
                                        {selectedMenu === PollInfoMenu.AUDIT_TRAIL && <>{t('Audit_Trial')}</>}

                                    </Typography>
                                    <Chip className={
                                                    pollContextDetails.pollDetails.selectedStatus  === t('Upcoming')? classes.upcomingChipColor :
                                                     pollContextDetails.pollDetails.selectedStatus === t('Under Setup') ? classes.setupChipColor :
                                                     pollContextDetails.pollDetails.selectedStatus === t('Closed') ? classes.closedChipColor :
                                                    pollContextDetails.pollDetails.selectedStatus === t('Cancelled') ? classes.canceledChipColor : classes.openChipColor}
                                            label={
                                                    <Typography variant='subtitle2' style={{ maxWidth: '100%', fontWeight: '600' }} noWrap>
                                                    {pollContextDetails.pollDetails.selectedStatus ? pollContextDetails.pollDetails.selectedStatus : 'Open'} 
                                                    </Typography>
                                                }
                                            style={{ maxWidth: '70%' }}
                                            size='small'
                                        />
                                    <Tooltip title={pollTitle} arrow>
                                        <ChipPollTitle
                                            label={<Typography variant='subtitle2' style={{ maxWidth: '100%', fontWeight: '600' }} noWrap>
                                               Title: {pollTitle}
                                            </Typography>}
                                            // color='primary'
                                            style={{ maxWidth: '62%' }}
                                            size='small'
                                        />
                                    </Tooltip>
                                   
                                    {/* <Typography variant='subtitle2' >{pollContextDetails.pollDetails.selectedTitle}</Typography> */}
                                </Stack>
                                <Stack direction='row' justifyContent='end' width={{ xs: '62%' }}>
                                    {/* {props.location.state.isEdit && pollStatus != Status.scheduled && pollStatus != Status.closed && <Button aria-label="delete-poll-round" size="small" color="secondary"
                                    variant="contained" className={`float-right ${classes.listIconMargin}`}
                                    onClick={confirmDeletePollRound} >
                                    {t('Delete_Round')}
                                </Button>} */}
                                    {((props.location.state.roles.includes(Roles.organizer)) && (pollStatus == Status.scheduled) &&
                                        (props.location.state.pollDetails.start_manually))
                                        && <UpdatePollRoundsStatus id={props.location.state.pollDetails.id} textColor="green"
                                            label="open-poll" btn={t("Start_Poll")} value="open" title={props.location.state.pollDetails.title}
                                            pollId={props.location.state.pollDetails.id}
                                            page={props.location.state.page}
                                            itemsPerPage={props.location.state.itemsPerPage}
                                            afterUpdate={() => backToPollRounds(null)} action={t("start")} />
                                    }
                                    {((props.location.state.roles.includes(Roles.organizer)) || hasSystemAdmineOrSupportRole)
                                    && pollStatus === Status.setup && <PublishPoll pollDetails={props.location.state.pollDetails} refresh={refresh}
                                    setMessage={setMessage} insidePoll={true} systemAdminOrSupport={hasSystemAdmineOrSupportRole} setRefresh={setRefresh}/>}
                                    {/* {((props.location.state.roles.includes(Roles.organizer)) && (pollStatus == Status.new) && props.location.state.pollDetails.paymentStatus
                                    && props.location.state.pollType != Status.canceled)
                                    &&  <UpdatePollRoundsStatus id={props.location.state.pollDetails.id} label="sch-poll"
                                    textColor="#ea905d" btn={t("Schedule_Round")} value="scheduled" title={props.location.state.pollRoundDetails.purpose.en}
                                    pollId={props.location.state.pollDetails.event_id}
                                        page={props.location.state.page}
                                        itemsPerPage={props.location.state.itemsPerPage}
                                        afterUpdate={() => backToPollRounds(null)} action={t("schedule")} roundNumber={props.location.state.pollRoundDetails.round_number}/>
                                } */}
                                    {/* {((props.location.state.roles.includes(Roles.organizer)) && (pollStatus != Status.new
                                    && pollStatus != Status.canceled &&
                                        pollStatus != Status.closed))
                                    &&  <UpdatePollRoundsStatus id={props.location.state.pollDetails.id} label="cancel-poll"
                                    textColor="black" btn={t("Cancel_Poll")} value="canceled" title={props.location.state.pollDetails.title}
                                    pollId={props.location.state.pollDetails.id} page={props.location.state.page}
                                    itemsPerPage={props.location.state.itemsPerPage}
                                    afterUpdate={() => backToPollRounds(null)} action={t("cancel")}/>
                                } */}
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        {!props.hasPermission && <Typography id='voter' color='textSecondary' variant='subtitle2'><b>No.of Voters: </b><span style={{ color: '#003366', fontSize: '16px' }}><b>
                                            {noOfVoters}</b></span></Typography>}&nbsp;
                                        {!props.hasPermission && props.location.state.pollDetails.paymentStatus && <Typography id='upload' color='textSecondary' variant='subtitle2' style={{ marginLeft: '10px' }}><b>Paid Voters:</b> <span style={{ color: 'green', fontSize: '16px' }}><b>{paidVoters}</b></span></Typography>}
                                        {props.pollDetails && props.hasPermission && <Typography id='upload-poll-time' color='textSecondary' variant='subtitle2' style={{ display: 'inline' }}><b>No.of Voters: </b><span style={{ color: '#003366', fontSize: '16px' }}><b>
                                            {noOfVoters}</b></span></Typography>}
                                        {props.pollDetails && props.hasPermission && props.location.state.pollDetails.paymentStatus && <Typography id='datetime' color='textSecondary' variant='subtitle2' style={{ marginLeft: '15px' }}><b>Paid Voters:</b> <span style={{ color: 'green', fontSize: '16px' }}><b>{paidVoters}</b></span></Typography>}

                                    </div>
                                    <Divider sx={{ borderWidth: '1px', margin: '0px 10px' }} flexItem />
                                    {isAllVotersVotedToClosePoll && !props.location.state.pollDetails.allow_vote_change && props.location.state.roles.includes(Roles.organizer) && pollStatus == Status.open &&
                                        <Button aria-label="pre-closure-poll-round" id='pre-closure-poll' size="small" style={{ color: `red`, borderColor: `red` }}
                                            variant="outlined" className={`float-right ${classes.listIconMargin}`}
                                            onClick={confirmClosePoll} >
                                            {t('Close_Poll')}
                                        </Button>
                                    }
                                    {/* {(paidVoters < noOfVoters) && props.location.state.roles.includes(Roles.organizer) && (pollStatus == Status.new || pollStatus == Status.scheduled ||
                                        pollStatus == Status.open) &&
                                        <Button aria-label="pay-poll-round" id='makepayment' size="small" style={{ color: `green`, borderColor: `green` }}
                                            variant="outlined" className={`float-right ${classes.listIconMargin}`}
                                            onClick={() => { setSelectedMenu(PollInfoMenu.PAYMENT) }} >
                                            {t('Make_Payment')}
                                        </Button>
                                    } */}
                                    {/* {props.location.state.roles.includes(Roles.organizer) && !pollRoundDateExpired && pollStatus != Status.canceled
                                    && <Button id='manage-role' size="small" variant="outlined" color="primary"
                                        className={`float-right ${classes.listIconMargin}`} onClick={() => manageRoles()}>
                                        {t('Manage_Roles')}
                                    </Button>
                                } */}
                                    {(props.location.state.roles.includes(Roles.organizer) && props.location.state.pollDetails.paymentStatus) &&
                                        <Fragment>
                                            <ButtonGroup size="small" variant="outlined" ref={anchorRef} aria-label="split button"
                                                className={`float-right ${classes.listIconMargin}`}>
                                                <Tooltip title='Download Latest Version'>
                                                    <Button style={{ color: `#7b4397`, borderColor: `#7b4397` }} id="download-invoice" onClick={downloadLatestVersion}>{t('Download_Invoice')}</Button>
                                                </Tooltip>
                                                <Tooltip title='Download Older Versions'>
                                                    <Button
                                                        style={{ color: `#7b4397`, borderColor: `#7b4397` }}
                                                        size="small"
                                                        id="download-invoice-version"
                                                        aria-controls={openInvoiceVersions ? 'split-button-menu' : undefined}
                                                        aria-expanded={openInvoiceVersions ? 'true' : undefined}
                                                        aria-label="select merge strategy"
                                                        aria-haspopup="menu"
                                                        onClick={handleToggle}
                                                    >
                                                        <ArrowDropDownIcon />
                                                    </Button>
                                                </Tooltip>
                                            </ButtonGroup>
                                            <Popper open={openInvoiceVersions} anchorEl={anchorRef.current} role={undefined} transition disablePortal
                                                style={{ zIndex: 3, marginTop: '0.1rem', marginLeft: '5.6rem' }}>
                                                {({ TransitionProps, placement }) => (
                                                    <Grow
                                                        {...TransitionProps}
                                                        style={{
                                                            top: "1px", position: "relative", right: "35px",
                                                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                                                        }}
                                                    >
                                                        <Paper style={{ color: "#7b4397" }}>
                                                            <ClickAwayListener onClickAway={handleClose}>
                                                                <MenuList id="split-button-menu">
                                                                    {invoiceVersions.map((option, index) => (
                                                                        <MenuItem key={index} id={"version" + index}
                                                                            onClick={(event) => handleMenuItemClick(event, option)}>
                                                                            {format(new Date(option.last_modified_date), process.env.REACT_APP_DATE_FORMAT_TO_DISPLAY)}
                                                                        </MenuItem>
                                                                    ))}
                                                                </MenuList>
                                                            </ClickAwayListener>
                                                        </Paper>
                                                    </Grow>
                                                )}
                                            </Popper>
                                        </Fragment>
                                    }
                                    {/* {props.location.state.isEdit && (pollStatus == Status.new)
                                        && props.location.state.pollType != Status.canceled && (paidVoters < noOfVoters) &&
                                        <Button id='payment' aria-label="pay-poll-round" size="small"
                                            style={{ color: `green`, borderColor: `green` }}
                                            variant="outlined" className={`float-right ${classes.listIconMargin}`}
                                            onClick={() => { setPayment(true) }}>
                                            {t('Make_Payment')}
                                        </Button>} */}
                                    {/* {noOfVoters > 0 && paidVoters >= noOfVoters && pollStatus == Status.closed &&
                                        <Tooltip title='Announce Result'>
                                            <Button id="announceResult" size="small" className={`float-right ${classes.listIconMargin}`} variant='contained' color='primary'
                                                disabled={props.location.state.pollDetails.result_announcement_time != null} onClick={confirmAnnounceResult}>Announce Result ( <AddAlertIcon style={{ fontSize: '20px' }} />)
                                            </Button></Tooltip>} */}

                                </Stack>
                            </Stack>
                        </Stack>
                        <Stack width='100%' marginTop={1}>
                            {selectedMenu === PollInfoMenu.POLL_DETAILS && <>
                                <EditPollRound pollDetails={props.location.state.pollDetails}
                                    roles={props.location.state.roles}
                                    selectedRole={pollContextDetails.pollDetails.selectedRole}
                                    // pollDetails={props.location.state.pollDetails}
                                    hasPermission={(props.location.state.isEdit || context.userSession.session.roles.includes(UserRole.Role_Support))}
                                    pollId={props.location.state.pollDetails.id}
                                    page={props.location.state.page}
                                    itemsPerPage={props.location.state.itemsPerPage}
                                    pollType={props.location.state.pollType}
                                    pollStatus={pollStatus}
                                    hasSystemAdmineOrSupportRole={(context.userSession.session.roles?.includes(UserRole.Role_Admin)
                                        || context.userSession.session.roles?.includes(UserRole.Role_Support))}
                                    setPollTitle={setPollTitle}
                                    noOfVotedNotVoted={noOfVotedNotVoted}
                                    setNoOfVotedNotVoted={setNoOfVotedNotVoted}
                                    setPollInfo={setPollInfo}
                                /></>}
                            {selectedMenu === PollInfoMenu.MATTERS &&
                                // <PollQuestions pollId={props.location.state.pollDetails.id} status={pollStatus}
                                //     systemAdmine={props.location.state.systemAdmine}
                                //     hasPermission={props.location.state.roles.includes(Roles.organizer) && props.location.state.isEdit && !pollRoundDateExpired && pollStatus != Status.canceled}
                                //     uploadPermission={props.location.state.roles.includes(Roles.organizer) && !pollRoundDateExpired && pollStatus != Status.canceled}
                                // />
                                <PollQuestionList pollId={props.location.state.pollDetails.id}
                                    hasPermission={(pollStatus === Status.setup ||
                                        (pollStatus === Status.ready && new Date(props.location.state.pollDetails.startTime) > new Date())
                                    ) && pollStatus !== Status.canceled}
                                    pollType={pollInfo.pollCategory}
                                    pollClass={pollInfo.pollType}
                                    hasSystemAdmineOrSupportRole={(context.userSession.session.roles?.includes(UserRole.Role_Admin)
                                        || context.userSession.session.roles?.includes(UserRole.Role_Support))}
                                    status={pollStatus}
                                    totalVoterCount={noOfVoters}
                                    // noOfVoterNotVoted={props.location.state.pollDetails.noOfVoterNotVoted}
                                    // noOfVoterVoted={props.location.state.pollDetails.noOfVoterVoted}
                                    systemAdmine={props.location.state.systemAdmine}
                                    realTimeResult={props.location.state.pollDetails.realTimeResult}
                                    filePermission={((context.userSession.session.roles?.includes(UserRole.Role_Admin)
                                            || context.userSession.session.roles?.includes(UserRole.Role_Support)) ||
                                            props.location.state.pollDetails.status === Status.setup ||
                                            (props.location.state.pollDetails.status === Status.ready && (new Date(props.location.state.pollDetails.startTime) > new Date() ||
                                            (new Date(props.location.state.pollDetails.startTime) <= new Date() && new Date(props.location.state.pollDetails.endTime) >= new Date())))
                                            ) && props.location.state.pollDetails.status !== Status.canceled}
                                    startTime={props.location.state.pollDetails.startTime} endTime={props.location.state.pollDetails.endTime}
                                    noOfVotedNotVoted={noOfVotedNotVoted}
                                //     hasPermission={props.location.state.roles.includes(Roles.organizer) && props.location.state.isEdit && !pollRoundDateExpired && pollStatus != Status.canceled}
                                />
                            }
                            {selectedMenu === PollInfoMenu.VOTERS &&
                                <div>
                                    {(pollStatus === Status.open || pollStatus === Status.closed || pollStatus === Status.canceled) && <VotersTab pollId={props.location.state.pollDetails.id}
                                        pollDetails={props.location.state.pollDetails}
                                        hasPermission={props.location.state.isEdit && !pollRoundDateExpired && pollStatus != Status.canceled}
                                        // pollStatus={pollStatus}
                                        paidVoter={paidVoters}
                                        pollStatus={pollStatus}
                                        hasSystemAdmineOrSupportRole={(context.userSession.session.roles?.includes(UserRole.Role_Admin)
                                            || context.userSession.session.roles?.includes(UserRole.Role_Support))}
                                        paymentStatus={props.location.state.pollDetails.paymentStatus}
                                        pollType={props.location.state.pollType}
                                        setNoOfVoters={setNoOfVoters}
                                        setMessage={setMessage}
                                        pollClass={pollInfo.pollType}
                                        canAddAfterPollOpen={props.location.state.roles.includes(Roles.organizer) && pollStatus == Status.open} />}
                                    {(pollStatus === Status.upcoming || pollStatus === Status.setup) && <PollRoundVoterList pollId={props.location.state.pollDetails.id}
                                        pollDetails={props.location.state.pollDetails}
                                        pollClass={pollInfo.pollType}
                                        setMessage={setMessage}
                                        hasPermission={props.location.state.isEdit && !pollRoundDateExpired && pollStatus != Status.canceled}
                                        pollStatus={pollStatus}
                                        paidVoter={paidVoters}
                                        hasSystemAdmineOrSupportRole={(context.userSession.session.roles?.includes(UserRole.Role_Admin)
                                            || context.userSession.session.roles?.includes(UserRole.Role_Support))}
                                        paymentStatus={props.location.state.pollDetails.paymentStatus}
                                        pollType={props.location.state.pollType}
                                        setNoOfVoters={setNoOfVoters}
                                        canAddAfterPollOpen={props.location.state.roles.includes(Roles.organizer) && pollStatus == Status.open}
                                        listBy={'all'} />}
                                </div>
                            }
                            {selectedMenu === PollInfoMenu.FILES &&
                                <div style={{ padding: '0px 8px' }}>
                                    <FileUploadDownload
                                        t={t}
                                        poll_id={props.location.state.pollDetails.id}
                                        poll_question_id={null}
                                        questionTitle={(TypeOfPollCategory.IBC === props.location.state.pollDetails.pollCategory) ? t('Resolution') : t('Question')}
                                        isMultiple={true}
                                        hasSystemAdmineOrSupportRole={(context.userSession.session.roles?.includes(UserRole.Role_Admin)
                                            || context.userSession.session.roles?.includes(UserRole.Role_Support))}
                                        pollStatus={pollStatus}
                                        isUpload={props.location.state.roles.includes(Roles.organizer)}
                                        isDownload={true}
                                        deletePermission={props.location.state.roles.includes(Roles.organizer) && (pollStatus != Status.canceled && pollStatus != Status.closed)}
                                    />
                                </div>}
                            {selectedMenu === PollInfoMenu.PAYMENT && props.location.state.roles.includes(Roles.organizer) && (pollStatus == Status.new || pollStatus == Status.scheduled ||
                                props.location.state.pollDetails.status == Status.open) &&
                                <PlanDetails pollId={props.location.state.pollDetails.id}
                                    noOfVoters={noOfVoters} paidNoOfVoters={paidVoters}
                                    hasSystemAdmineOrSupportRole={(context.userSession.session.roles?.includes(UserRole.Role_Admin)
                                        || context.userSession.session.roles?.includes(UserRole.Role_Support))}
                                    cancelPayment={() => setPayment(false)} onSuccess={onPaymentSuccess} setPaidVoters={setPaidVoters} />
                            }
                            {selectedMenu === PollInfoMenu.ROLES &&
                                <PollRoles pollType={props.location.state.pollType}
                                    pollDetails={props.location.state.pollDetails}
                                    hasSystemAdmineOrSupportRole={(context.userSession.session.roles?.includes(UserRole.Role_Admin)
                                        || context.userSession.session.roles?.includes(UserRole.Role_Support))}
                                    roles={props.location.state.roles}
                                    pollStatus={pollStatus}
                                    assignRolePermission={(props.location.state.roles.includes(Roles.organizer) && new Date(props.location.state.pollDetails.endTime) > new Date())}
                                />
                            }
                            {selectedMenu === PollInfoMenu.RESULT && (props.location.state.pollDetails.status == Status.ready ||
                                (props.location.state.systemAdmine && props.location.state.pollDetails.status == Status.ready)) && <>

                                <PreviewResult  pollId={props.location.state.pollDetails.id} pollDetails={props.location.state.pollDetails}
                                 userId={context.userSession.session.userId} resultWithVoters={resultWithVoters}/>
                            </>}

                            {selectedMenu === PollInfoMenu.AUDIT_TRAIL &&
                                <AuditTrail pollDetails={props.location.state.pollDetails}
                                hasSystemAdmineOrSupportRole={(context.userSession.session.roles?.includes(UserRole.Role_Admin)
                                    || context.userSession.session.roles?.includes(UserRole.Role_Support))} />}

                            {/* {selectedMenu === PollInfoMenu.SETTINGS &&
                                <UpdatePollSettings poll_id={props.location.state.pollDetails.id}
                                    canModify={(pollStatus === Status.setup
                                        && props.location.state.roles.includes(Roles.organizer)) ||
                                        context.userSession.session.roles.includes(UserRole.Role_Support)} />
                            } */}

                            {selectedMenu === PollInfoMenu.AUDIT_SMS_EMAIL &&
                                <AuditSmsEmail pollDetail={props.location.state.pollDetails}
                                hasSystemAdmineOrSupportRole={(context.userSession.session.roles?.includes(UserRole.Role_Admin)
                                    || context.userSession.session.roles?.includes(UserRole.Role_Support))} />
                            }
                            {/* {selectedMenu === PollInfoMenu.AUDIT_TRAIL &&
                                <AuditTrail pollId ={props.location.state.pollDetails.id} />
                            } */}

                        </Stack>
                    </Stack>
                </PollListContainer>
                {/* {deletingPollRound && <EligoBackdrop show={deletingPollRound} />}
            {errorOnDeletingPollRound && <GraphQLErrors error={errorOnDeletingPollRound} show={false} />} */}
                { loading && <EligoBackdrop show={loading} />}
                {
                    seekConfirmation.show && <EligoConfirmDialog show={seekConfirmation.show} title={seekConfirmation.title}
                        message={seekConfirmation.message} onAgree={seekConfirmation.onAgree} onDisAgree={seekConfirmation.onDisAgree} />
                }
                {
                    message.showMsg && <EligoSnackBar show={message.showMsg} message={message.message}
                        severity={message.severity} />
                }
            </Stack >
        </>
    )
}

export default PollRoundInfo;