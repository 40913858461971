import React, { Suspense, useState, useEffect, Fragment, useContext } from 'react';
import axios from 'axios'
import { UserContext } from '../../common/context/UserContext';
import { Button, Card, Container, IconButton, makeStyles, Paper, Slide, Tooltip, Typography } from '@material-ui/core';
import { Stack } from '@mui/system';
import HowToVoteIcon from '@material-ui/icons/HowToVote';
import { useHistory } from 'react-router-dom';
import PollTimer from './PollTimer';
import { Status } from '../../common/GenericCodes';


const useStyles = makeStyles((theme) => ({
    centered: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        top: '50px'
        // bottom: '280px',
    },
    paper: {
        padding: '0px 20px 10px 20px',
        width: '70%',
        maxHeight: '500px',
        minHeight: '150px',
        overflowY: 'auto',
        margin: '0rem 0rem 0rem 0rem'
    },
    headerName: {
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: '23px',
        padding: '0px 0px 10px 0px'
    },
    notVotedcard: {
        padding: '10px 0px 10px 10px',
        borderLeft: '4px solid blue',
        marginBottom: '10px'
    },
    votedcard: {
        padding: '10px 0px 10px 10px',
        borderLeft: '4px solid green',
        marginBottom: '10px',
        // background: '#d1f3d1'
    },
    notVotedIcon: {
        background: 'blue',
        color: 'white',
        borderRadius: '20px',
        padding: '8px',
        border: '2px solid blue',
        margin: '0px 0px 0px 0px',
    },
    votedIcon: {
        background: 'green',
        color: 'white',
        borderRadius: '20px',
        padding: '8px',
        border: '2px solid green',
        margin: '0px 0px 0px 0px',
    },
    buttonCard: {
        borderRadius: '20px 15px 0px 20px',
        boxShadow: '0px 6px 5px #888, 0px -3px 5px #888'
    },
    notVotedTypograpy: {
        padding: '5px 20px 0px 10px',
        color: 'blue',
        fontWeight: 'bold',
        fontSize: '17px',
        margin: '3px 0px 0px 0px'
    },
    votedTypograpy: {
        padding: '5px 20px 0px 10px',
        color: 'green',
        fontWeight: 'bold',
        fontSize: '17px',
        margin: '3px 0px 0px 0px'
    }

}))

const VotersListForAuthrep = (props) => {
    const context = useContext(UserContext)
    const history = useHistory();
    const classes = useStyles()
    const [slide, setSlide] = useState(false)
    const [authRep, setAuthRepId] = useState(props.authRepId)
    const [listVotersAuthrep, setListVoterAuthRep] = useState(props.authRepVotersList)
    const [isTimerTerminated, setIsTimerTerminated] = useState(false);


    const showPollAndRoundInfo = (auth_rep_id, voterIdInList, voterEdgeId, authRepVerified, votedAuthRep, voterName) => {
        // props.setAuthRepPage(true)
        context.setUserSession(prevState => ({
            ...prevState,
            color: 'white'
        })
        )
        history.push({
            pathname: '/poll/stepper-view',
            state: {
                pollDetails: props.pollDetails,
                page: props.page,
                roles: props.roles,
                pollType: props.pollType,
                authRepId: auth_rep_id,
                voterIdInList: voterIdInList,
                voterEdgeId: voterEdgeId,
                authRepVerified: authRepVerified,
                votedAuthRep: votedAuthRep,
                AuthRepPage: true,
                // backToAuthRepNav: false,
                authRepVotersList: props.authRepVotersList,
                voterName: voterName
            }
        })
    }

    const backToPoll = () => {
        history.push({
            pathname: '/polls',
            state: {
                pollType: props.pollType
            },
        })
    }

    useEffect(() => {
        setSlide(true)
    }, [])

    const reloadPollDetails = () => {
        setIsTimerTerminated(true);
    }

    return (

        <Container maxWidth='lg'>
            <div style={{ width: '100%' }}>
                <Slide direction="up" id='slide' in={slide} mountOnEnter unmountOnExit >
                    <div className={classes.centered} elevation={12}>
                        <Paper className={classes.paper}>
                            {!props.authRepView ? <Typography className={classes.headerName}>VOTE FOR</Typography> : <Typography className={classes.headerName}>VIEW VOTES</Typography>}
                            {
                                listVotersAuthrep.map((value) => {
                                    return (
                                        (value.voter === false || value.voter === true) ? null : <Card className={value.voted ? classes.votedcard : classes.notVotedcard} elevation={12}>
                                            <Stack direction='row' justifyContent='space-between'>
                                                <div>
                                                    <Typography><b>{value.name}</b></Typography>
                                                    <Typography>{value.email}</Typography>
                                                </div>
                                                <div>
                                                    <IconButton >
                                                        <Tooltip title='vote for'>
                                                            <Card className={classes.buttonCard} >
                                                                <Stack direction='row'>
                                                                    <HowToVoteIcon className={value.voted ? classes.votedIcon : classes.notVotedIcon} />
                                                                    {props.authRepView ? <Button onClick={() => showPollAndRoundInfo(value.auth_rep_id, value.id, value.voter_edge_id, value.verified, value.voted)}
                                                                        className={classes.notVotedTypograpy}>{<span style={{ width: '80px' }} >ViewVote</span>}
                                                                    </Button> :
                                                                        <Button onClick={() => showPollAndRoundInfo(value.auth_rep_id, value.id, value.voter_edge_id, value.verified, value.voted, value.name)}
                                                                            className={value.voted ? classes.votedTypograpy : classes.notVotedTypograpy}>{value.voted ? 'Recast' : <span style={{ width: '70px' }} >Vote</span>}
                                                                        </Button>
                                                                    }
                                                                </Stack>
                                                            </Card>
                                                        </Tooltip>
                                                    </IconButton>
                                                </div>
                                            </Stack>
                                        </Card>
                                    )
                                })
                            }
                        </Paper>
                    </div>
                </Slide>
            </div>
        </Container>
    )
}

export default VotersListForAuthrep;