import React, { useState } from 'react';
import { useMutation } from '@apollo/client'; 
import { Grid, 
    TextField, 
    InputAdornment, 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogContentText, 
    DialogTitle } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { UPDATE_SPECIAL_PRICE } from '../../GraphQL/Mutations';
import GraphQLErrors from '../../common/GraphQLErrors';
import EligoBackdrop from '../../common/EligoBackdrop';

const SpecialPrice = (props) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(props.show);
    const [specialPrice, setSpecialPrice] = useState({
        poll_id: props.openSpecialPrice.poll.poll_id,
        special_price: 0,
        special_price_voter: 0
    })

    // const [updateSpecialPrice, { loading: updatingSpecialPrice, error: errorInUpdatingSpecialPrice }] = useMutation(UPDATE_SPECIAL_PRICE, {
    //     errorPolicy: 'all',
    //     onCompleted: (data) => {
    //         if (data.specialPrice) {
    //             handleAgree();
    //         }
    //     }
    // });

    const handleDisAgree = () => {
        setOpen(false);
        props.close({
            show: false,
            poll: ''
        });
    };

    const handleAgree = () => {
        setOpen(false);
        props.close({
            show: false,
            poll: ''
        });
    };

    const onInputChange = (value, fieldName) => {
        setSpecialPrice(prevState => ({
            ...prevState,
            [fieldName]: value
        }))
    }

    const save = () => {
        // updateSpecialPrice({
        //     variables: {
        //         poll_id: specialPrice.poll_id,
        //         special_price_voter: specialPrice.special_price_voter,
        //         special_price: specialPrice.special_price
        //     }
        // })
    }

    return (
        <div>
            <Dialog open={open}
                    aria-labelledby="special-price-model-title"
                    aria-describedby="special-price-model-description">
                <DialogTitle id="special-price-model-title">{t('Price')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="special-price-model-description">
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <NumberFormat id="special_price_voter" label={t("No_of_Voters")} 
                                    value={specialPrice.special_price_voter} 
                                    customInput={TextField} 
                                    thousandSeparator={true} 
                                    onValueChange={({ value }) => {
                                        let voters = value ? value : 1;
                                        onInputChange(voters, 'special_price_voter')
                                    }}
                                    thousandsGroupStyle="lakh"
                                    allowNegative={false}
                                    required 
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <NumberFormat id="special_price" label={t("Price")} 
                                    value={specialPrice.special_price} 
                                    customInput={TextField} 
                                    thousandSeparator={true} 
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">{t('Rs')}</InputAdornment>,
                                    }}
                                    onValueChange={({ value }) => {
                                        let amount = value ? value : 0;
                                        onInputChange(amount, 'special_price')
                                    }}
                                    thousandsGroupStyle="lakh"
                                    allowNegative={false}
                                    required 
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDisAgree} color="primary">
                        {t('Cancel')}
                    </Button>
                    <Button onClick={save} color="primary" autoFocus>
                        {t('Save')}
                    </Button>
                </DialogActions>
            </Dialog>
            {/* {errorInUpdatingSpecialPrice && <GraphQLErrors error={errorInUpdatingSpecialPrice} show={false} />} */}
            {/* {updatingSpecialPrice && <EligoBackdrop show={updatingSpecialPrice} />} */}
        </div>
    )
}

export default SpecialPrice;