import { TableBody, TableCell, TableRow } from '@material-ui/core'
import React from 'react'

const FileTableBody = ({ tableRows, columns, rowsPerPage, page }) => {
    return (
        <TableBody>
            {
                tableRows.map(row => {
                    return (
                        <TableRow hover tabIndex={-1}>
                            {columns.map((column, index) => {
                                let value;
                                if (column.renderCell != undefined) {
                                    value = column.renderCell(row[column.id], row)
                                } else {
                                    value = row[column.id]
                                }

                                return (
                                    <TableCell size='medium' id={`value${index}`}>
                                        {value}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    )
                })
            }
        </TableBody>
    )
}

// .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

export default FileTableBody

