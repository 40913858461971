import { HttpLink, fromPromise, ApolloLink } from '@apollo/client';
import { onError } from "@apollo/client/link/error";
import aws from '../aws-exports';
import { Auth } from 'aws-amplify';


const httpLink = () => {
    return new HttpLink({
        uri: process.env.REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT,
        // headers: {
        //     "authorization": userSession.isAuthenticated ? localStorage.getItem('token') : null
        // }
    })
};

const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: sessionStorage.getItem('token') || null,
      }
    }));
    return forward(operation);
})

const getNewToken = async () => {
    try {
        await Auth.currentSession().then(res => {
            sessionStorage.setItem('token', res.idToken.jwtToken);
            return res.idToken.jwtToken;
        });
    } catch(e) {
        if (e !== 'No current user') {
            console.log('error', e);
        }
    }
}

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
        for (let err of graphQLErrors) {
            //Token has expired.
            if (err.errorType === 'UnauthorizedException') {
                return fromPromise(
                    getNewToken().catch(error => {
                        return error;
                    }))
                    .flatMap(() => {
                        return forward(operation);
                });
            }
        }
    }

    if (networkError) {
        //TODO
    }
});

export { httpLink, errorLink, authMiddleware }