import { Button, Tooltip } from "@material-ui/core";
import { InsertPhotoRounded } from "@material-ui/icons";
import { Avatar, Stack, Typography } from "@mui/material";
import { Fragment, memo, useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { OptionType } from "../../QuestionStaticVar";
import EligoSnackBar from "../../../../common/EligoSnackBar";
import { useTranslation } from "react-i18next";

const OptionFileUpload = ({ onSelectImage, index, optionFile, optionType, error, setError, setMessage }) => {

    const {t} = useTranslation();
    const [files, setFiles] = useState([]);
    const [thumbnail, setThumbnail] = useState(null);
    const fileFocusRef = useRef(null);

    const onDrop = useCallback((acceptedFiles) => {
        setMessage({ showMsg: false, message: null, severity: null })
        const [imageFile] = acceptedFiles;
        const fileReader = new FileReader();
        fileReader.readAsDataURL(imageFile);
        fileReader.onload = (event) => {
            let fileMap = new Map();
            let imageSrc = event.target['result'];
            isImageCorrupted(imageSrc, function (isCorrupted) {
                if (isCorrupted) {
                    setMessage({ showMsg: true, message: `${t('Selected_Image_Is_Corrupted_For_Option')}
                        ${index + 1}. ${t('Please_Choose_Another_Image')}`, severity: 'error' });
                } else {
                    fileMap[acceptedFiles[0].name] = imageSrc.split(',').pop();
                    setFiles(fileMap)
                    onSelectImage(acceptedFiles[0].name, imageSrc.split(',').pop(), index)
                }
            });
        };
    }, [files])

    function isImageCorrupted(imageSrc, callback) {
        const img = new Image();
        img.src = imageSrc;
        img.onload = function () {
            // Create a canvas element
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            try {
                // Attempt to extract pixel data from the canvas
                ctx.getImageData(0, 0, 1, 1);
                callback(false); // Image is not corrupted
                return;
            } catch (error) {
                callback(true); // Image is corrupted
                return;
            }
        };
        img.onerror = function () {
            callback(true); // Image failed to load (potentially corrupted)
        };
    }


    const { getRootProps, getInputProps, open } = useDropzone({
        // Disable click and keydown behavior
        onDrop,
        multiple: false,
        noClick: true,
        noKeyboard: true,
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg'],
        },
    });

    const removeSelectedFiles = (filePath) => {
        setFiles(files.filter((file) => file.path != filePath));
    }

    useEffect(() => {
        if ((error[`file${index}`] !== undefined && error[`file${index}`] !== null)) {
            fileFocusRef.current.focus();
        }
    }, [error])

    useEffect(() => {
        if (optionFile !== null) {
            Object.entries(optionFile).map(([key, value]) => {
                let fileNameString = key.split('/').pop().split('.');
                let name = fileNameString.shift();
                let extension = fileNameString.at(0).split(' ').at(0)
                setThumbnail([{
                    preview: `data:image/png;base64,${value}`,
                    fileName: name.concat('.', extension)
                }])
            })
            setError((prevState) => ({
                ...prevState,
                ['file' + index]: null
            }))
        } else {
            setThumbnail(null);
        }
    }, [optionFile])

    return (
       <Fragment>
            {OptionType.Image !== optionType &&
                <Stack direction="row" spacing={1} width={'100%'} alignItems='center' justifyContent="space-between" sx={{ boxShadow: 'rgb(255 255 255 / 10%) 0px 5px 10px 0px inset, rgb(152 152 162 / 25%) 0px 30px 50px -20px, rgb(130 130 174 / 30%) 0px 30px 30px -30px !important', background: '#fff', border: '1px solid #eeeeee', }}>
                    <Fragment>
                        <input {...getInputProps()} />
                        <Button color="primary" variant="contained" onClick={open} style={{ padding: '6px 8px', textTransform: 'capitalize' }}>
                            Browse file
                        </Button>
                    </Fragment>
                        {thumbnail !== null ?
                            <Tooltip title={thumbnail[0].fileName} arrow><Typography variant="subtitle2" noWrap sx={{ width: '100px' }}>{thumbnail[0].fileName}</Typography></Tooltip> :
                        <Typography variant="subtitle2" noWrap sx={{width: '106px'}}>No image added</Typography>
                    }
                    {thumbnail !== null ? <Avatar src={thumbnail[0].preview}
                    // onLoad={() => { URL.revokeObjectURL(thumbnail[0].preview) }}
                    /> :
                        <Avatar><InsertPhotoRounded /></Avatar>
                    }
                </Stack>}
            {OptionType.Image === optionType &&
                <Stack direction="row" spacing={1} width={'100%'} alignItems='center' justifyContent="space-between" sx={{ boxShadow: 'rgb(255 255 255 / 10%) 0px 5px 10px 0px inset, rgb(152 152 162 / 25%) 0px 30px 50px -20px, rgb(130 130 174 / 30%) 0px 30px 30px -30px !important', background: '#fff', border: '1px solid #eeeeee',borderRadius: '12px', padding: '10px' }}>
                    <Stack direction="row" alignItems='center' justifyContent="center" spacing={2}>
                            <Typography color="textSecondary" sx={{ fontWeight: 'bold' }}>{index + 1}.</Typography>
                            {
                                thumbnail !== null ? <Avatar src={thumbnail[0].preview}
                                // onLoad={() => { URL.revokeObjectURL(thumbnail[0].preview) }}
                                sx={{ borderRadius: '6px', minWidth: '3em', minHeight: '3em' }} /> :
                                <Avatar sx={{ borderRadius: '6px', minWidth: '3em', minHeight: '3em' }}><InsertPhotoRounded /></Avatar>
                            }
                    </Stack>
                    {thumbnail !== null ? <Typography variant="subtitle2" noWrap sx={{ width: '60%' }}>{thumbnail[0].fileName}</Typography> :
                        <Typography variant="subtitle2" noWrap sx={{ width: '60%' }}
                            color={error !== undefined && (error[`file${index}`] !== undefined && error[`file${index}`] !== null) ? 'error': 'inherit'}>
                            {(error !== undefined && error[`file${index}`] !== undefined && error[`file${index}`] !== null) ? error[`file${index}`] : 'No image added'}
                        </Typography>
                    }
                    <div>
                        <input {...getInputProps()} />
                        <Button color="primary" variant="contained" onClick={open} style={{ padding: '6px 8px', textTransform: 'capitalize' }}
                            ref={fileFocusRef}>
                            Browse file
                        </Button>
                    </div>
                </Stack>
            }
       </Fragment>
    )
}

export default memo(OptionFileUpload);