import { styled } from "@mui/material/styles";
import { alpha, Avatar, Button, Card, DialogContent, TableHead } from "@mui/material";
import { makeStyles } from "@material-ui/core";

export const DashedCard = styled(Card)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    border: '2px dashed #9dc3eb',
    borderRadius: '15px !important',
    [theme.breakpoints.up("sm")]: {
        minWidth: '300px',
        minHeight: '150px',
        padding: '10px !important',
    },
    [theme.breakpoints.up("md")]: {
        minWidth: '300px',
        minHeight: '150px',
        padding: '10px !important',
    },
    [theme.breakpoints.up("lg")]: {
        minWidth: '300px',
        minHeight: '300px',
        padding: '0px !important'
    },
    boxShadow: 'none',
    background: 'rgb(243 243 250) !important',
    justifyContent: 'center',
    position: 'sticky',
    top: 0,
}))

export const ButtonStyled = styled(Button)(() => ({
    boxShadow: '0 13px 27px -5px rgba(50,50,93,0.25),0 8px 16px -8px rgba(0,0,0,0.3)',
    background: '#0a68c5 !important',
    textTransform: 'none !important',
    fontWeight: 'bold !important'
}))

export const FileUploadCard = styled(Card)(() => ({
    boxShadow: '0 13px 27px -5px rgba(50,50,93,0.25),0 8px 16px -8px rgba(0,0,0,0.3)',
    '&:hover': {
        // Animation:
    },
    padding: '1rem',
    maxWidth: '300px',
    minWidth: '300px'
}))

export const FileAvatarDelete = styled(Avatar)(({ theme, ...props }) => ({
    width: '32px',
    height: '32px',
    transition: 'all .2s ease-in-out',
    background: alpha(`${theme.palette.error.light}`, 0.2),
    color: alpha(theme.palette.error.dark, 0.9),
    '&[aria-controls="clicked-action"],&: hover':
    {
        background: alpha(theme.palette.error.dark, 0.9),
        color: alpha('#fff', 0.8),
    }
}))

export const FileAvatarDownload = styled(Avatar)(({ theme, ...props }) => ({
    width: '32px',
    height: '32px',
    transition: 'all .2s ease-in-out',
    background: alpha(`${theme.palette.primary.light}`, 0.2),
    color: alpha(theme.palette.primary.dark, 0.9),
    '&[aria-controls="clicked-action"],&: hover':
    {
        background: alpha(theme.palette.primary.dark, 0.9),
        color: alpha('#fff', 0.8),
    }
}))

export const ColoredHeader = styled(TableHead)(() => ({
    '& tr td, & tr th': {
        // color: '#0777c1 !important',
        background: 'rgb(231, 235, 240) !important',
        fontWeight: 'bold !important',
        fontSize: '16px'
    },
}))

export const CollapsedTableHeader = styled(TableHead)(() => ({
    '& tr td, & tr th': {
        // color: '#0777c1 !important',
        background: '#eae7e7 !important',
        fontWeight: 'bold !important',
        fontSize: '16px'
    },
}))

export const useStyles = makeStyles(() => ({
    dotLoading: {
        '&:after': {
            content: ' .',
            fontSize: 'xx-large',
            animation: 'dots 1s steps(5, end) infinite'
        }
    },
    '@keyframes dots': {
        '0%, 20%': {
            color: 'rgba(0, 0, 0, 0)',
            textShadow: '.25em 0 0 rgba(0, 0, 0, 0), .5em 0 0 rgba(0, 0, 0, 0)'
        },
        '40%': {
            color: '#1976d2',
            textShadow: '.25em 0 0 rgba(0, 0, 0, 0), .5em 0 0 rgba(0, 0, 0, 0)'
        },
        '60%': {
            textShadow: '.25em 0 0 #1976d2, .5em 0 0 rgba(0, 0, 0, 0)'
        },
        '80%, 100%': {
            textShadow: '.25em 0 0 #1976d2, .5em 0 0 #1976d2'
        }
    }
}))