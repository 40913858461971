import { Button, Card, Divider, Typography, makeStyles , Box} from "@material-ui/core";
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, TextField, Slide, Stack, Tooltip } from "@mui/material";
import React, { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import MultipleAuthRep from "./MultipleAuthRep";
import CloseIcon from '@material-ui/icons/Close';
import EmailIcon from '@material-ui/icons/Email';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PersonIcon from '@material-ui/icons/Person';
import { AxiosEvotingInterceptor } from "../../../config/axios.interceptor";
import SearchVoter from "../search-voter/SearchVoter";
import * as Validators from '../../../common/form-validators';
import MuiPhoneNumber from "material-ui-phone-number";
import EligoBackdrop from "../../../common/EligoBackdrop";
import EligoConfirmDialog from "../../../common/EligoConfirmDialog";
import EligoSnackBar from "../../../common/EligoSnackBar";
import { TypeOfPoll } from "../../../common/GenericCodes";
import { UserContext } from "../../../common/context/UserContext";
import { DefaultTelephoneAreaCode } from '../../../common/GenericCodesValues'; 

const useStyle = makeStyles(theme => ({
    button: {
        color: 'red',
        background: 'rgb(253, 245, 245)',
        margin: '5px 5px 5px 0px',
    },
    gridContainer: {
        marginLeft: "10%"
    },
    mainGrid: {
        marginTop: '10px !important'
    },
    votersubTitle: {
        fontWeight: 'bold',
        fontSize:"20px"
    },
    authrepTitle: {
        marginLeft: "7%",
        fontSize: '20px',
        fontWeight: 'bold'
    },
    cardContent: {
        padding: "5px",
        position: "relative",
        background: "red",
        color: "white",
        marginTop: '-20px'
    },
    typographyError: {
        fontWeight: 'bold',
        margin: '8px 0px 0px 10px'
    },
    iconButton: {
        color: 'white !important',
        marginLeft: '3px'
    },
    mainTitle: {
        fontWeight: 'bold', fontSize: '20px', margin: '6px 0px 0px 15px'
    },
    stackContent: {
        borderBottom: '1px solid gray', background: '#f0fcfb', padding: '7px'
    }
}))

const VoterAdd = (props) => {
    const { open, closeVoterDialog, addVoterwithSearch, editVoterDetails, 
        editAuthRepDetails, addExisting, pollRoundVoters, pollClass } = props
    const context = React.useContext(UserContext);
    const { t } = useTranslation();
    const classes = useStyle()
    const [addAuthRep, setAddAuthrep] = useState(addExisting ? addExisting : false)
    const [errors, setErrors] = useState({});
    const [newAuthRep, setNewAuthRep] = useState([{ name: '', 
            countryCode:`+${context?.userSession?.session?.phoneExtension === 1 ? 
                (context?.userSession?.session?.phoneExtension + DefaultTelephoneAreaCode.CANADA) : context?.userSession?.session?.phoneExtension}`, 
            email: [{ email: '' }], phoneNumber: [{ phoneNumber: '' }] }]);
    const [singleVoterDetalis, setSinglevoterDetails] = useState({
        // name: '',
        name: props.editSingleDetails.name ? props.editSingleDetails.name : '',
        email: props.editSingleDetails.email ? props.editSingleDetails.email : '',
        weightage: props.editSingleDetails.weightage ? props.editSingleDetails.weightage : 0,
        phoneNumber: props.editSingleDetails.phoneNumber ? props.editSingleDetails.phoneNumber : '',
        voterId: props.editSingleDetails.voterId,
        auth_rep: props.editSingleDetails.auth_rep ? props.editSingleDetails.auth_rep : false,
        representsById: props.editSingleDetails.representsById,
        nationalNumber:props.editSingleDetails.nationalNumber ? props.editSingleDetails.nationalNumber : null,
        authorizedRepresentativeLength: props.editSingleDetails.authorizedRepresentativeLength ? props.editSingleDetails.authorizedRepresentativeLength : false
    })
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({
        show: false,
        message: '',
        severity: ''
    })
    const [voterEmailIndex, setVoterEmailIndex] = useState(null)
    const [voterNameIdex, setvoterNameIndex] = useState(null)
    const [authRepErrors, setAuthRepErrors] = useState(false);
    const [authRepEmailErrors, setAuthRepEmailErrors] = useState(false);
    const [authRepNameErrors, setAuthRepNameErrors] = useState(false);
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemPerPage] = useState(5);
    const [phoneErrors, setPhoneErrors] = useState(false);
    const [SaveContinue, setSaveAndContinue] = useState(open);
    const [closeErrorCard, setCloseErrorCard] = useState(false);
    const [extension, setExtension] = useState(props.editSingleDetails.countryCode ? "+" + props.editSingleDetails.countryCode : 
        `+${context?.userSession?.session?.phoneExtension === 1 ? (context?.userSession?.session?.phoneExtension + DefaultTelephoneAreaCode.CANADA) : 
        context?.userSession?.session?.phoneExtension}`)
    const [searchVoters, setSearchVoters] = useState(false);

    const handleClose = () => {
        setSearchVoters(false)
        closeVoterDialog(false)
    }

    const handleCloseContinue = () => {
        setSearchVoters(false)
        setTimeout(() => {
        setSaveAndContinue(true)
        }, 200)
    }

    const addAuthRepOnClick = () => {
        setErrors({})
        setCloseErrorCard(false)
        if (addAuthRep) {
            setAuthRepEmailErrors(false)
            setAuthRepNameErrors(false)
            setVoterEmailIndex(null)
            setNewAuthRep(prev => ([...prev, { name: '',
                countryCode:`+${context?.userSession?.session?.phoneExtension === 1 ? 
                    (context?.userSession?.session?.phoneExtension + DefaultTelephoneAreaCode.CANADA) : context?.userSession?.session?.phoneExtension}`, 
                email: [{ email: '' }], phoneNumber: [{ phoneNumber: '' }] }]))
        } else {
            setAddAuthrep(true)
        }
    }

    const validate = {
        name: name => Validators.requiredValidation('Name', name),
        email: email => (Validators.requiredValidation('Email', email) || Validators.emailValidation(email)),
        nationalNumber: nationalNumber => (Validators.mobileNumberValidation( extension + nationalNumber)),
        weightage: weightage => Validators.percentageValidation('Weightage', weightage)
    };
    const [seekConfirmation, setSeekConfitmation] = useState({
        show: false,
        title: t("Confirmation"),
        message: '',
        onAgree: '',
        onDisAgree: ''
    })

    const confirmaion = (pollRoundVoter) => {
        setSeekConfitmation(prevState => ({
            ...prevState,
            show: true,
            message: <Fragment>{t('Are_You_Sure_That_You_Want_To')} <b>{t('Close')}</b> {t('?')}</Fragment>,
            onAgree: handleClose,
            onDisAgree: resetConfirmation,
        }))
    }

    const resetConfirmation = () => {
        setSeekConfitmation(prevState => ({
            ...prevState,
            show: false,
            title: pollClass === TypeOfPoll.POLL ? t("Voter_Confirmation") : t('Participant_Confirmation'),
            message: '',
            onDisAgree: '',
            onAgree: ''
        }))
    }

    const handleMobileNumberChange = (fieldName, event) => {
        if (event.target.value === '' || /^[0-9]+$/.test(event.target.value)) {
            setSinglevoterDetails(prevState => ({
                ...prevState,
                [fieldName]: event.target.value
            }))
        } 
    }

    const saveAuthRepForExisting = (AuthRepsData) => {
        setMessage({ show: false, message: '', severity: '' })
        setLoading(true)
        const payload = AuthRepsData
        AxiosEvotingInterceptor.post(`voter/auth-rep/${props.pollId}/${pollRoundVoters.voterId}?page=${page}&limit=${itemsPerPage}&asc=false`, payload).then(response => {
            props.refreshList.refreshVoter("addAuthrepForExistingVoter")
        }).catch((error) => {
            setLoading(false)
            setMessage({
                show: true,
                message: error.message,
                severity: 'error'
            })
        })
    }

    const saveVoterOrAuthrep = (AuthRepsData, onlySave) => {
        setCloseErrorCard(false)
        setMessage({ show: false, message: '', severity: '' })
        if(((AuthRepsData === "saveAndContinue" || AuthRepsData.length === 0)) && (singleVoterDetalis.email === '' && (singleVoterDetalis.nationalNumber === null || singleVoterDetalis.nationalNumber === ''))) {
            setCloseErrorCard(true)
            // setMessage({ show: true, message: "please enter email or phone number", severity: 'error' })
        } else {
            validateForm(AuthRepsData.length > 0).then(data => {
                if (Object.values(data.errors).length === 0) {
                setLoading(true)
                const payload = {
                    weightage: singleVoterDetalis.weightage ? singleVoterDetalis.weightage : 0.0,
                    phoneNumber: singleVoterDetalis.nationalNumber ? extension + singleVoterDetalis.nationalNumber : null,
                    email: singleVoterDetalis.email,
                    name: singleVoterDetalis.name,
                    authorizedRepresentative: AuthRepsData === "saveAndContinue" ? [] : AuthRepsData.length > 0 ? AuthRepsData : []
                }
                AxiosEvotingInterceptor.post(`voter/${props.pollId}?page=${page + 1}&limit=${itemsPerPage}&asc=false`, payload).then(response => {
                    if (AuthRepsData.length === 0 || onlySave) {
                        handleClose()
                        props.refreshVoter(response.count)
                    }  else {
                        setLoading(false)
                        setSaveAndContinue(false)
                        setSinglevoterDetails(prevState => ({
                            ...prevState,
                            email: '',
                            name: '',
                            phoneNumber: '',
                            weightage: 0,
                            nationalNumber:''
                        }))
                        setExtension(`+${context?.userSession?.session?.phoneExtension === 1 ? 
                            (context?.userSession?.session?.phoneExtension + DefaultTelephoneAreaCode.CANADA) : context?.userSession?.session?.phoneExtension}`)
                        handleCloseContinue()
                        props.refreshVoter(response.count)
                        if(AuthRepsData != "saveAndContinue"){
                                setNewAuthRep([{ name: '',
                                countryCode:`+${context?.userSession?.session?.phoneExtension === 1 ? 
                                    (context?.userSession?.session?.phoneExtension + DefaultTelephoneAreaCode.CANADA) : context?.userSession?.session?.phoneExtension}`, 
                                email: [{ email: '' }], phoneNumber: [{ phoneNumber: '' }] }])
                        }
                    }
                }).catch((error) => {
                    setLoading(false)
                    setMessage({ show: true, message: error.message, severity: 'error' })
                })
            }
            })
        }
    }

    const saveOrSaveAndContinue = (data) => {
        saveVoter(data)
    }

    const saveVoter = (voters, onlySave) => {
        let valid = false
        if ((addAuthRep || addExisting) === true) {
            const emails = []
            const name = []
            const AuthRepsData = []
            valid = false
            setAuthRepEmailErrors(false)
            setVoterEmailIndex(null)
            newAuthRep.map((voters) => {
                let phoneNumber = voters.countryCode + voters.phoneNumber[0].phoneNumber
                AuthRepsData.push({ name: voters.name, email: voters.email, phoneNumber: (voters.phoneNumber[0].phoneNumber === '' || voters.phoneNumber[0].phoneNumber === null) ? null : [{phoneNumber}]  })
                voters.phoneNumber[0].phoneNumber === '' && (newAuthRep.phoneNumber = null)
                emails.push(voters.email[0].email)
                name.push(voters.name)
            })
            if (emails.includes('')) {
                setVoterEmailIndex(emails.indexOf(''))
                valid = true
                setAuthRepEmailErrors(true)
                setLoading(false)
            } else {
                if (!valid) {
                    if (addExisting) {
                        saveAuthRepForExisting(AuthRepsData)
                    } else {
                        saveVoterOrAuthrep(AuthRepsData, onlySave)
                    }
                }
            }
        } else {
            saveVoterOrAuthrep(voters)
        }
    }

    const validateForm = async (auth_rep) => {
        setErrors({})
        setCloseErrorCard(false)
        let formErrors = { errors: {} };
        Object.entries(singleVoterDetalis).map(([key]) => {
            if ((!singleVoterDetalis.auth_rep &&  key === 'name') || (singleVoterDetalis.weightage && key === 'weightage') ||
                ( singleVoterDetalis.email && key === 'email') || (singleVoterDetalis.nationalNumber != null && singleVoterDetalis.nationalNumber != '') && key == 'nationalNumber' ) {
                const newError = validate[key](singleVoterDetalis[key])
                const newTouched = { [key]: true };
                formErrors = {
                    errors: {
                        ...formErrors.errors,
                        ...(newError && { [key]: newError }),
                    },
                    touched: {
                        ...formErrors.touched,
                        ...newTouched
                    }
                }
            }
        })
        setErrors(formErrors.errors);
        setLoading(false)
        return formErrors;
    }

    const editVoter = () => {
        setMessage({ show: false, message: '', severity: '' })
        validateForm(singleVoterDetalis.authorizedRepresentativeLength).then(data => {
            if (Object.values(data.errors).length === 0) {
                setLoading(true)
                const payload = {
                    name: singleVoterDetalis.name,
                    weightage: singleVoterDetalis.weightage,
                    email: singleVoterDetalis.email,
                    phoneNumber: singleVoterDetalis.nationalNumber ? "+" + extension + singleVoterDetalis.nationalNumber : null,
                    voterId: singleVoterDetalis.voterId
                }
                AxiosEvotingInterceptor.put(`voter/${props.pollId}`, payload).then(response => {
                    closeVoterDialog(false)
                    props.refreshVoter()
                    setLoading(false)
                }).catch((error) => {
                    setLoading(false)
                    setMessage({ show: true, message: error.message, severity: 'error' })
                })
            }
        })
    }

    const editAuthRep = () => {
        setMessage({ show: false, message: '', severity: '' })
        validateForm(singleVoterDetalis.authorizedRepresentativeLength).then(data => {
            if (Object.values(data.errors).length === 0) {
                setLoading(true)
                const payload = {
                    name: singleVoterDetalis.name,
                    email: [{ email: singleVoterDetalis.email }],
                    phoneNumber: singleVoterDetalis.nationalNumber ? [{ phoneNumber: extension + singleVoterDetalis.nationalNumber }] : null,
                    representsById: singleVoterDetalis.representsById
                }
                AxiosEvotingInterceptor.put(`voter/auth-rep/${props.pollId}/${singleVoterDetalis.voterId}`, payload).then(response => {
                    props.AuthRepPageChange()
                    closeVoterDialog(false)
                    setLoading(false)
                }).catch((error) => {
                    setLoading(false)
                    setMessage({ show: true, message: error.message, severity: 'error' })
                })
            }
        })
    }

    const closemultivoter = () => {
        setAddAuthrep(false)
        setNewAuthRep([{ name: '',
            countryCode:`+${context?.userSession?.session?.phoneExtension === 1 ? 
                (context?.userSession?.session?.phoneExtension + DefaultTelephoneAreaCode.CANADA) : context?.userSession?.session?.phoneExtension}`, 
            email: [{ email: '' }], phoneNumber: [{ phoneNumber: '' }] }])
    }


    const setSelectedVoter = (selectedVoter) => {
        if (selectedVoter != '') {
            setLoading(true)
            AxiosEvotingInterceptor.get(`user/${selectedVoter.id}`).then((response) => {
                setSinglevoterDetails(prevState => ({
                    ...prevState,
                    email: response.preferredEmail,
                    name: response.name,
                    phoneNumber: response.preferredPhoneNumber,
                    countryCode: selectedVoter.countryCode,
                    nationalNumber: selectedVoter.nationalNumber
                }))
                setExtension("+" + selectedVoter.countryCode)
                setSearchVoters(true)
                setLoading(false)
            }).catch((error) => {
                console.log("error,,", error.message)
            })
        }
    }

    const setName = (name) => {
        setSinglevoterDetails(prevState => ({
            ...prevState,
            name: name
        }))
    }

    const setData = (id, event) => {
        let value = event.target.value;
        if (id === 'email') {
            value = value.trim().toLowerCase();
        }
        setSinglevoterDetails(prevState => ({
            ...prevState,
            [id]: value
        }))
    }

    const handleCloseCard = () =>{
        setCloseErrorCard(false)
    }

    const onMobileNumberChange = (value) =>{
        setExtension(value)
    }
    
    return (
        <div>
            <Dialog open={SaveContinue} maxWidth='xl'
                PaperProps={{
                    style: { minWidth:'40%',width : addExisting && '75%' }
                }}
                >
                <Stack direction='row' justifyContent='space-between' className={classes.stackContent} >
                    <Stack>
                        {pollClass === TypeOfPoll.POLL && <Typography className={classes.mainTitle} >
                            {addExisting ? t("Add_authorized_Representative_For") + pollRoundVoters.name : addAuthRep ? t("Add_Voter_With_Authorize_Representatives") :
                                editVoterDetails ? t("Edit_Voter") : editAuthRepDetails ? t("Edit_Authorize_Representative") : t("Add_Voter")}
                        </Typography>}
                        {pollClass === TypeOfPoll.SURVEY && <Typography className={classes.mainTitle} >
                            {addExisting ? t("Add_authorized_Representative_For") + pollRoundVoters.name : addAuthRep ? t("Add_Participant_With_Authorize_Representatives") :
                                editVoterDetails ? t("Edit_Participant") : editAuthRepDetails ? t("Edit_Authorize_Representative") : t("Add_Participant")}
                        </Typography>}
                    </Stack>
                    <Stack justifyContent='flex-end' >
                        {!editVoterDetails && !editAuthRepDetails && <IconButton onClick={(singleVoterDetalis.name === '' && singleVoterDetalis.email === ''
                            && singleVoterDetalis.phoneNumber === '' && singleVoterDetalis.weightage === 0) ? handleClose : confirmaion} className={classes.button}>
                            <Tooltip title='Close'>
                                <CloseIcon color='error' />
                            </Tooltip>
                        </IconButton>}
                    </Stack>
                </Stack>
                <DialogContent>
                    <Grid container spacing={2} className={classes.mainGrid} justifyContent='end'>
                        {(addAuthRep && !addExisting) &&
                            <Grid container xs={12} className={classes.gridContainer}>
                                <Grid item xs={6}>
                                    <Typography className={classes.votersubTitle}>{pollClass === TypeOfPoll.POLL ? t("Add_Voter_Details") : t("Add_Participant_Details")}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography className={classes.authrepTitle}>{t("Add_Authorize_Representatives_Details")}</Typography>
                                </Grid>
                            </Grid>}
                            { closeErrorCard && <Slide direction="right" in={closeErrorCard} timeout={500} mountOnEnter unmountOnExit>
                            <Card elevation={7} className={classes.cardContent}>
                                <Stack direction='row' >
                                    <Typography  className={classes.typographyError} variant="subtitle2">{t("Please_Enter_Email_Or_Phone_Number")}</Typography>
                                    <IconButton className={classes.iconButton} onClick={handleCloseCard}>
                                        <Tooltip title='Close'>
                                            <CloseIcon />
                                        </Tooltip>
                                    </IconButton>
                                </Stack>
                            </Card>
                            </Slide>}
                        {!addExisting && <Grid item xs={addAuthRep ? 4 : 12}>
                            <Stack direction='column' spacing={2}>
                                {addVoterwithSearch ? <SearchVoter name={singleVoterDetalis.name} onSelect={setSelectedVoter} roleSearch={false}
                                    setName={setName} id="name" label={t("Individual/Organization_Name")} variant="outlined"
                                    error={errors.name}
                                    helperText={errors.name ? errors.name : 'placeholder'}
                                    placeholder={t("Search_By_Email_For_Existing_Users")}
                                    value={singleVoterDetalis.name}
                                    required={(singleVoterDetalis.auth_rep) ? false : true}
                                    InputProps={
                                        <InputAdornment position="start">
                                            <PersonIcon />
                                        </InputAdornment>
                                    } /> : <TextField id="name" label={addAuthRep ? t("Individual/Organization") : "Name"} fullWidth variant="outlined"
                                        error={errors.name}
                                        helperText={errors.name}
                                        required={!editAuthRepDetails}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <PersonIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        onChange={(event) => setData('name', event)} value={singleVoterDetalis.name} />}
                                <TextField id="email" fullWidth label="Email Address" variant="outlined" placeholder="Enter Email Address"
                                    // required={singleVoterDetalis.authorizedRepresentativeLength ? false : true && !addAuthRep}
                                    error={((props.errors ? props.errors : errors.email) || closeErrorCard)}
                                    helperText={props.errors ? props.errors : errors.email}
                                    // style={{ width: singleVoterDetalis.auth_rep ? '50%' : '100%' }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <EmailIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={(event) => setData('email', event)} value={singleVoterDetalis.email} />
                             
                                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                    <TextField 
                                    id="nationalNumber"
                                    name="nationalNumber"
                                    label={t("Phone_Number")}
                                    variant="outlined" fullWidth
                                    error={(errors.nationalNumber || closeErrorCard)}
                                    helperText={errors.nationalNumber}
                                    value={singleVoterDetalis.nationalNumber ? singleVoterDetalis.nationalNumber : (singleVoterDetalis.nationalNumber === '' ? '' : singleVoterDetalis.phoneNumber)}
                                    onChange={(event, country) => {
                                            handleMobileNumberChange('nationalNumber', event, country)
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                            <MuiPhoneNumber
                                            id="input-with-icon-adornment"
                                            name="extension"
                                            defaultCountry={"in"}
                                            value={extension}
                                            onChange={(event) => onMobileNumberChange(event)}
                                            autoFormat={false}
                                            style={{margin: "8px 0px", color: "black !important", 
                                            width: extension.length > 3 ? extension.length === 4 ? '4.8rem' : extension.length === 5 ? '5.3rem' : '6.3rem' : '4rem'}}
                                            required
                                            disableDropdown={loading}
                                            countryCodeEditable={false}
                                            disabled
                                            InputProps={{
                                                disableUnderline: true,
                                            }}
                                    />
                                            </InputAdornment>
                                        ),
                                    }}
                                    />
                                </Box>
                                {(!editAuthRepDetails && pollClass === TypeOfPoll.POLL) && <TextField id="weightage" fullWidth label={t("Weightage")} type='number'
                                    variant="outlined" placeholder='0.0'
                                    onChange={(event) => setData('weightage', event)}
                                    value={singleVoterDetalis.weightage}
                                    error={errors.weightage}
                                    helperText={errors.weightage}
                                />}
                            </Stack>
                        </Grid>}
                        {(addAuthRep || addExisting) && <Grid item xs={addExisting ? 12 : 8}>
                            <Divider orientation="vertical" flexItem />
                            <MultipleAuthRep increaseAuthreps={newAuthRep} setAuthRepErrors={setAuthRepErrors} setPhoneErrors={setPhoneErrors} closeAddAuthrep={closemultivoter} setLoading={setLoading} setNewAuthRep={setNewAuthRep} setAuthRepEmailErrors={setAuthRepEmailErrors} setAuthRepNameErrors={setAuthRepNameErrors}
                                voterEmailIndex={voterEmailIndex} voterNameIdex={voterNameIdex} authRepErrors={authRepErrors} authRepEmailErrors={authRepEmailErrors} authRepNameErrors={authRepNameErrors} />
                        </Grid>}
                    </Grid>

                </DialogContent>
                <DialogActions>
                    {(!editVoterDetails && !editAuthRepDetails) && <Button variant="contained" color="primary" onClick={() => saveVoter([], true)} disabled={authRepErrors || phoneErrors}>save</Button>}
                    {(!editVoterDetails && !editAuthRepDetails) && !addExisting &&  <Button variant="contained" color="primary" onClick={() => saveOrSaveAndContinue("saveAndContinue")} disabled={authRepErrors || phoneErrors}>save and continue</Button>}
                    {(!editVoterDetails && !editAuthRepDetails) && <Button id='delete' onClick={addAuthRepOnClick} variant='outlined' color="primary">{t("Add_authorized_Representative")}</Button>
                    }
                    {!addExisting && (editVoterDetails || editAuthRepDetails) && <Button variant='outlined' color="secondary" onClick={handleClose}>cancel</Button>}
                    {!addExisting && (editVoterDetails || editAuthRepDetails) && <Button variant='contained' color="primary" onClick={singleVoterDetalis.auth_rep ? editAuthRep : editVoter}>update</Button>}
                </DialogActions>
                {loading && <EligoBackdrop show={true} />}
                {seekConfirmation.show && <EligoConfirmDialog show={seekConfirmation.show}
                    title={seekConfirmation.title} message={seekConfirmation.message}
                    onAgree={seekConfirmation.onAgree} onDisAgree={seekConfirmation.onDisAgree} ConfirmFor={true} />}
                {message.show && <EligoSnackBar show={message.show} message={message.message} severity={message.severity} />}
            </Dialog>
        </div >
    )
}

export default VoterAdd