
import { TableCell, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types'
import React from 'react'
import { ColoredHeader } from '../styles/FileStyle';

const FileTableHeader = ({ columns }) => {
    return (
        <ColoredHeader>
            <TableRow>
                {columns.map(({ key, id, align, minWidth, label, className }) => (
                    <TableCell
                        key={id}
                        align={align}
                        style={{ minWidth: minWidth }}
                        className={`${className != undefined ? className : ''}`}
                    >
                        {label}
                    </TableCell>
                ))}
            </TableRow>
        </ColoredHeader>
    )
}

export default FileTableHeader

FileTableHeader.propType = {
    key: PropTypes.string,
    align: PropTypes.string,
    minWidth: PropTypes.string,
    label: PropTypes.string,
    className: PropTypes.string
}

FileTableHeader.defaultProps = {
    key: "key",
    align: "left",
    minWidth: "150",
    label: '',
    className: '',
};
