import React, { Fragment, useContext, useState } from 'react';
import { Button, Container, IconButton, InputAdornment, makeStyles, Paper, Tooltip, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import EmailIcon from '@material-ui/icons/Email';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PersonIcon from '@material-ui/icons/Person';
import { Dialog, Stack, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-number-input';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../../common/context/UserContext';
import EligoBackdrop from '../../../common/EligoBackdrop';
import EligoSnackBar from '../../../common/EligoSnackBar';
import * as Validators from '../../../common/form-validators';
import { AxiosEvotingInterceptor } from '../../../config/axios.interceptor';
import CustomPhoneNumber from '../poll-round-voter-row/CustomPhoneNumber';
import SearchVoter from '../search-voter/SearchVoter';
import './AuthRep.css';
import MultiVoter from './MultiVoter';
import EligoConfirmDialog from '../../../common/EligoConfirmDialog';
import MuiPhoneNumber from "material-ui-phone-number";

const useStyle = makeStyles(theme => ({
    headerBorder: {
        borderBottom: '1px solid #DEDEDE',
        background: '#EEF2F4',
    },
    paperWidth: {
        [theme.breakpoints.up("md")]: {
            maxWidth: '110%',
            margin: '10px 0px 0px 18px',
            padding: '10px'
        },
        [theme.breakpoints.down("sm")]: {
            minWidth: 'fit-content',
            background: 'red',
            margin: '10px 10px 0px 10px',
            padding: '10px'
        },
    },
    name: {
        fontSize: '1.5rem',
        color: '#5D6F78',
        fontWeight: '300px',
        padding: '10px 0px 10px 0px'
    },
    header: {
        margin: '0rem 0rem 0rem 26rem',
    },
    back: {
        color: 'green',
        border: '1px solid green'
    },
    button: {
        color: 'red',
        background: 'rgb(253, 245, 245)',
        margin: '5px 5px 5px 0px',
    }
}))

const SingleVoter = (props) => {
    const classes = useStyle()
    const { t } = useTranslation();
    const [message, setMessage] = useState({
        show: false,
        message: '',
        severity: ''
    })
    const [errors, setErrors] = useState({});
    const [touched, setTouched] = useState({});
    const [loading, setLoading] = useState(false)
    const [singleVoterDetalis, setSinglevoterDetails] = useState({
        name: props.editSingleDetails.name ? props.editSingleDetails.name : '',
        email: props.editSingleDetails.email ? props.editSingleDetails.email : '',
        weightage: props.editSingleDetails.weightage ? props.editSingleDetails.weightage : 0,
        phoneNumber: props.editSingleDetails.phoneNumber ? props.editSingleDetails.phoneNumber : '',
        voterId: props.editSingleDetails.voterId,
        auth_rep: props.editSingleDetails.auth_rep ? props.editSingleDetails.auth_rep : false,
        representsById: props.editSingleDetails.representsById,
        authorizedRepresentativeLength: props.editSingleDetails.authorizedRepresentativeLength ? props.editSingleDetails.authorizedRepresentativeLength : false
    })
    const [openMultiVoter, setOpenMultiVoter] = useState('')
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemPerPage] = useState(5);
    const validate = {
        name: name => Validators.requiredValidation('Name', name),
        email: email => (Validators.requiredValidation('Email', email) || Validators.emailValidation(email)),
        phoneNumber: phoneNumber => (Validators.mobileNumberValidation(phoneNumber)),
        weightage: weightage => Validators.percentageValidation('Weightage', weightage)
    };
    const [seekConfirmation, setSeekConfitmation] = useState({
        show: false,
        title: t("Confirmation"),
        message: '',
        onAgree: '',
        onDisAgree: ''
    })

    const confirmaion = (pollRoundVoter) => {
        setSeekConfitmation(prevState => ({
            ...prevState,
            show: true,
            message: <Fragment>{t('Are_You_Sure_That_You_Want_To')} <b>{t('Close')}{pollRoundVoter.name}</b> {t('?')}</Fragment>,
            onAgree: handleClose,
            onDisAgree: resetConfirmation,
        }))
    }

    const resetConfirmation = () => {
        setSeekConfitmation(prevState => ({
            ...prevState,
            show: false,
            title: t("Voter_Confirmation"),
            message: '',
            onDisAgree: '',
            onAgree: ''
        }))
    }

    const handleClose = () => {
        props.setAuth(false)
    }

    const setData = (id, event) => {
        let value = event.target.value;
        if (id === 'email') {
            value = value.trim().toLowerCase();
        }
        setSinglevoterDetails(prevState => ({
            ...prevState,
            [id]: value
        }))
    }

    const handleMobileNumberChange = (fieldName, value) => {
        setSinglevoterDetails(prevState => ({
            ...prevState,
            [fieldName]: value
        }))
    }

    const saveVoter = (voters) => {
        validateForm(voters.length > 0).then(data => {
            if (Object.values(data.errors).length === 0) {
                setLoading(true)
                const payload = {
                    weightage: singleVoterDetalis.weightage ? singleVoterDetalis.weightage : 0.0,
                    phoneNumber: singleVoterDetalis.phoneNumber,
                    email: singleVoterDetalis.email,
                    name: singleVoterDetalis.name,
                    authorizedRepresentative: voters.length > 0 ? voters : []
                }
                AxiosEvotingInterceptor.post(`voter/${props.pollId}?page=${page + 1}&limit=${itemsPerPage}&asc=false`, payload).then(response => {
                    handleClose()
                    props.refreshVoter(response.count)
                }).catch((error) => {
                    setLoading(false)
                    setMessage({
                        show: true,
                        message: error.message,
                        severity: 'error'
                    })
                })
            }
        })
    }

    const validateForm = async (auth_rep) => {
        setErrors({})
        setTouched({})
        let formErrors = { errors: {} };
        Object.entries(singleVoterDetalis).map(([key]) => {
            console.log("phone number", key)
            if (key === 'name' || (singleVoterDetalis.weightage && key === 'weightage') ||
                (!auth_rep ? key === 'email' : singleVoterDetalis.email && key === 'email') || key == 'phoneNumber') {
                const newError = validate[key](singleVoterDetalis[key])
                const newTouched = { [key]: true };
                formErrors = {
                    errors: {
                        ...formErrors.errors,
                        ...(newError && { [key]: newError }),
                    },
                    touched: {
                        ...formErrors.touched,
                        ...newTouched
                    }
                }
            }
        })
        setErrors(formErrors.errors);
        setTouched(formErrors.touched)
        return formErrors;
    }

    const editVoter = () => {
        validateForm(singleVoterDetalis.authorizedRepresentativeLength).then(data => {
            if (Object.values(data.errors).length === 0) {
                setLoading(true)
                const payload = {
                    name: singleVoterDetalis.name,
                    weightage: singleVoterDetalis.weightage,
                    email: singleVoterDetalis.email,
                    phoneNumber: singleVoterDetalis.phoneNumber ? singleVoterDetalis.phoneNumber : null,
                    voterId: singleVoterDetalis.voterId
                }
                AxiosEvotingInterceptor.put(`voter/${props.pollId}`, payload).then(response => {
                    props.setAuth(false)
                    props.refreshVoter('editVoter')
                    setLoading(false)
                }).catch((error) => {
                    setLoading(false)
                    setMessage({
                        show: true,
                        message: error.message,
                        severity: 'error'
                    })
                })
            }
        })
    }

    const editAuthRep = () => {
        validateForm(singleVoterDetalis.authorizedRepresentativeLength).then(data => {
            if (Object.values(data.errors).length === 0) {
                setLoading(true)
                const payload = {
                    name: singleVoterDetalis.name,
                    email: [{ email: singleVoterDetalis.email }],
                    phoneNumber: singleVoterDetalis.phoneNumber ? [{ phoneNumber: singleVoterDetalis.phoneNumber }] : null,
                    representsById: singleVoterDetalis.representsById
                }
                AxiosEvotingInterceptor.put(`voter/auth-rep/${props.pollId}/${singleVoterDetalis.voterId}`, payload).then(response => {
                    props.AuthRepPageChange()
                    props.setAuth(false)
                    setLoading(false)
                }).catch((error) => {
                    setLoading(false)
                    setMessage({
                        show: true,
                        message: error.message,
                        severity: 'error'
                    })
                })
            }
        })
    }

    const AddAuthRep = () => {
        setErrors({})
        setTouched({})
        setOpenMultiVoter(true)
    }

    const setName = (name) => {
        setSinglevoterDetails(prevState => ({
            ...prevState,
            name: name
        }))
    }

    const setSelectedVoter = (selectedVoter) => {
        if (selectedVoter != '') {
            setLoading(true)
            AxiosEvotingInterceptor.get(`user/${selectedVoter.id}`).then((response) => {
                setSinglevoterDetails(prevState => ({
                    ...prevState,
                    email: response.preferredEmail,
                    name: response.name,
                    phoneNumber: response.preferredPhoneNumber,
                }))
                setLoading(false)
            }).catch((error) => {
                console.log("error,,", error.message)
            })
        }
    }

    return (
        <Container >
            <Dialog open={props.open}
                PaperProps={{
                    style: { backgroundColor: '#f9f9f9', boxShadow: "none", minWidth: '77%' },
                }}>
                <Stack className={classes.headerBorder} justifyContent='space-between' direction='row' >
                    <Stack direction='row' justifyContent='center' alignItems='center' style={{ marginLeft: '1rem' }}>
                        {openMultiVoter && <PeopleAltIcon fontSize='large' />}
                        {!openMultiVoter && <PersonIcon fontSize='large' />}&nbsp;&nbsp;
                        <Typography className={classes.name}>
                            {props.voterOption ? singleVoterDetalis.auth_rep ? t('Add_Authorized_Representative') : t('Add_Voter') : singleVoterDetalis.auth_rep ? t('Edit_Authorized_Representative') : t('Edit_Voter')}</Typography>
                    </Stack>
                    <Stack justifyContent='flex-end' >
                        {props.voterOption && <IconButton onClick={(singleVoterDetalis.name === '' && singleVoterDetalis.email === ''
                            && singleVoterDetalis.phoneNumber === '' && singleVoterDetalis.weightage === 0) ? handleClose : confirmaion} className={classes.button}>
                            <Tooltip title='Close'>
                                <CloseIcon />
                            </Tooltip>
                        </IconButton>}
                    </Stack>
                </Stack>
                <Paper style={{ margin: '20px 15px 0px 15px', padding: '20px' }}>
                    <Stack spacing={5}>
                        <Stack direction='row' spacing={2}>
                            {props.voterOption ? <SearchVoter name={singleVoterDetalis.name} onSelect={setSelectedVoter} roleSearch={false} width={'570px'}
                                setName={setName} id="name" label={t("Individual/Organization_Name")} variant="standard" error={errors.name}
                                helperText={errors.name}
                                value={singleVoterDetalis.name}
                                required={singleVoterDetalis.auth_rep ? false : true}
                                InputProps={
                                    <InputAdornment position="start">
                                        <PersonIcon />
                                    </InputAdornment>
                                } /> : <TextField id="name" label={openMultiVoter ? t("Individual/Organization") : "Name"} fullWidth variant="standard" error={errors.name}
                                    helperText={errors.name}
                                    required={singleVoterDetalis.auth_rep ? false : true}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <PersonIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={(event) => setData('name', event)} value={singleVoterDetalis.name} />}

                            {!singleVoterDetalis.auth_rep && <TextField id="weightage" fullWidth label="Weightage" type='number'
                                variant="standard" placeholder='0.0'
                                onChange={(event) => setData('weightage', event)}
                                value={singleVoterDetalis.weightage}
                                error={errors.weightage}
                                helperText={errors.weightage}
                            />}
                            {singleVoterDetalis.auth_rep &&
                                // <PhoneInput
                                //     defaultCountry='IN'
                                //     placeholder={t("Enter_Mobile_Number")}
                                //     className={classes.disableColor}
                                //     value={singleVoterDetalis.phoneNumber}
                                //     helperText={errors.phoneNumber}
                                //     onChange={(event) => handleMobileNumberChange('phoneNumber', event)}
                                //     style={{ width: '100%' }}
                                //     error={errors.phoneNumber}
                                //     showLabel={true}
                                //     inputComponent={CustomPhoneNumber}
                                // />
                                <MuiPhoneNumber
                                    id="phonenumber"
                                    name="phonenumber"
                                    label={t("Phone_Number")}
                                    defaultCountry={"in"}
                                    value={singleVoterDetalis.phoneNumber}
                                    helperText={errors.phoneNumber}
                                    onChange={(event) => handleMobileNumberChange('phoneNumber', event)}
                                    style={{ width: '100%' }}
                                    error={errors.phoneNumber}
                                    autoFormat={false} fullWidth
                                    countryCodeEditable={false}
                                />
                            }
                        </Stack>
                        <Stack direction='row' spacing={2}>
                            <TextField id="email" fullWidth label="Email" variant="standard"
                                required={singleVoterDetalis.authorizedRepresentativeLength ? false : true && !openMultiVoter}
                                error={props.errors ? props.errors : errors.email}
                                helperText={props.errors ? props.errors : errors.email}
                                style={{ width: singleVoterDetalis.auth_rep ? '50%' : '100%' }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <EmailIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={(event) => setData('email', event)} value={singleVoterDetalis.email} />
                            {!singleVoterDetalis.auth_rep &&
                                // <PhoneInput
                                //     defaultCountry='IN'
                                //     placeholder={t("Enter_Mobile_Number")}
                                //     className={classes.disableColor}
                                //     value={singleVoterDetalis.phoneNumber}
                                //     helperText={errors.phoneNumber}
                                //     onChange={(event) => handleMobileNumberChange('phoneNumber', event)}
                                //     style={{ width: '100%' }}
                                //     error={errors.phoneNumber}
                                //     showLabel={true}
                                //     inputComponent={CustomPhoneNumber}
                                // />
                                <MuiPhoneNumber
                                    id="phonenumber"
                                    name="phonenumber"
                                    label={t("Phone_Number")}
                                    defaultCountry={"in"}
                                    value={singleVoterDetalis.phoneNumber}
                                    helperText={errors.phoneNumber}
                                    onChange={(event) => handleMobileNumberChange('phoneNumber', event)}
                                    style={{ width: '100%' }}
                                    error={errors.phoneNumber}
                                    autoFormat={false} fullWidth
                                    countryCodeEditable={false}
                                />
                            }
                        </Stack>
                    </Stack>
                </Paper>

                {/* <DialogActions> */}
                {!openMultiVoter && props.voterOption != undefined &&
                    <Stack direction='row' justifyContent='space-between' style={{ margin: '15px' }}>
                        <div>
                            <Typography style={{ fontSize: '20px' }}>{t("Authorized_Representative")}</Typography>
                        </div>
                        <Stack direction='row'>
                            <Button id='delete' onClick={AddAuthRep} variant='outlined' color="primary">{t("Add_authorized_Representative")}</Button>&nbsp;&nbsp;
                            <Button variant='contained' color="primary" onClick={() => saveVoter([])}>
                                Save
                            </Button>
                        </Stack>
                    </Stack>

                }
                <Stack direction='row' justifyContent='flex-end' spacing={2} style={{ margin: '10px 15px 10px 0px' }}>
                    {props.voterOption == undefined && <Button variant='outlined' color="secondary" onClick={handleClose}>cancel</Button>}
                    {props.voterOption == undefined && <Button variant='contained' color="primary" onClick={singleVoterDetalis.auth_rep ? editAuthRep : editVoter}>update</Button>}
                </Stack>  {/* </DialogActions> */}
                {message.show && <EligoSnackBar show={message.show} message={message.message} severity={message.severity} />}
                {loading && <EligoBackdrop show={true} />}
                {openMultiVoter && < MultiVoter AddMultiVoter={saveVoter} closeMultiVoter={setOpenMultiVoter} setLoading={setLoading} />}
                {seekConfirmation.show && <EligoConfirmDialog show={seekConfirmation.show}
                    title={seekConfirmation.title} message={seekConfirmation.message}
                    onAgree={seekConfirmation.onAgree} onDisAgree={seekConfirmation.onDisAgree} ConfirmFor={true} />}
            </Dialog>
        </Container >
    )
}

export default SingleVoter