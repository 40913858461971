import React, {
    Fragment,
    useState,
    useContext,
    useEffect
}                                   from 'react';
import {
    Grid,
    Typography,
    RadioGroup,
    FormControlLabel,
    Radio,
    Button,
    FormGroup,
    Checkbox,
    TextField,
    FormControl,
    makeStyles,
    Avatar,
    Card,
    Dialog,
    DialogTitle,
    Tooltip
}                                   from '@material-ui/core';
import { useTranslation }           from 'react-i18next';
import { AxiosEvotingInterceptor }  from '../../../config/axios.interceptor';
import EligoSnackBar                from '../../../common/EligoSnackBar';
import { Stack }                    from '@mui/material';
import { UserContext }              from '../../../common/context/UserContext';
import CheckCircleIcon              from '@material-ui/icons/CheckCircle';
import { arrayMoveImmutable }       from "array-move";
import RankingSelection             from './ranking-options/RankigSelection';
import VoteHistoryModel             from './vote-history/VoteHistoryModel';
import FileUploadDownload           from "../../file-upload-download/FileUploadDownload";
import {
    Close,
    InfoRounded
}                                   from "@material-ui/icons";
import MultiOptionMinMaxInfo from '../../poll-matter/poll-question-detail/question-section/MultiOptionMinMaxInfo';

const useStyles = makeStyles((theme) => ({
    option: {
        background: 'white',
        borderRadius: '5px',
        padding: '6px',
        margin: '7px 0px',
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)'
    },
    selectedOption: {
        color: '#2a83ad',
        border: '1px solid #2a83ad',
        background: '#2a83ad0a'
    }
}));

const Question = ({question, seqNo, saveVote, view, skipQuestion,
        previousQuestion, currentQuestionIndex, numberOfQuestions,
        poll_id, voter_id, showHistory, showFiles, preview, voteWithLink, pollCategory}) => {
    const { t } = useTranslation();
    const context = useContext(UserContext);
    const classes = useStyles()
    const [selectedOption, setSelectedOption] = useState([]);
    const [showMatterFiles, setShowMatterFiles] = useState(false);
    const [enableSave, setEnableSave] = useState(true);
    const [isView] = useState(view != undefined ? view : false);
    const [isPreview] = useState(preview != undefined ? preview : false);
    const [showHistoryModal, setShowHistoryModal] = useState(false);
    const [message, setMessage] = React.useState({
        showMsg: false,
        message: null,
        severity: null
    })
    const [options, setOptions] = useState([]);

    const onSortEnd = ({oldIndex, newIndex}) =>{
        setOptions(prevItem => (arrayMoveImmutable(prevItem, oldIndex, newIndex)));
    };

    const onChangeOption = (event) => {
        setSelectedOption([event.target.value]);
    }

    const onChecked = (event, option) => {
        let options = selectedOption;
        if (options.includes(option.toString())) {
            setSelectedOption([]);
            options.splice(options.findIndex(o => o === option.toString()), 1);
            options.map(option => {
                setSelectedOption(prevState => [
                    ...prevState,
                    option.toString(),
                ]);
            })
        } else {
            setSelectedOption(prevState => [
                ...prevState,
                option.toString(),
            ]);
        }
    };

    const onRankChange = (options) => {
        setSelectedOption(options.map(option => {
            return option.id
        }));
    }

    const handleSaveVote = () => {
        if (question.questionType === 'SINGLE_CHOICE') {
            saveVote([Number(selectedOption[0])]);
        } else if (question.questionType === 'MULTI_CHOICE') {
            let options = [];
            selectedOption.map(option => {
                options.push(Number(option));
            })
            saveVote(options);
        } else if (question.questionType === 'RANKING') {
            saveVote(selectedOption);
        }
    }

    const handleSkip = () => {
        if (question.questionType === 'SINGLE_CHOICE') {
            skipQuestion(question.votedOptionIds);
        } else if (question.questionType === 'MULTI_CHOICE') {
            // let options = [];
            // selectedOption.map(option => {
            //     options.push(Number(option));
            // })
            skipQuestion(question.votedOptionIds);
        } else if (question.questionType === 'RANKING') {
            skipQuestion(question.votedOptionIds);
        }
    }

    const handlePrevious = () => {
        previousQuestion();
    }

    useEffect(() => {
        if (question.votedOptionIds && !isPreview) {
            question.votedOptionIds.map(vote => {
                setSelectedOption(prevState => [
                    ...prevState,
                    vote.toString()
                ])
            })
        }
        if (question.questionType === "RANKING") {
            question.options.map(option => {
                setOptions(prevState => [
                    ...prevState,
                    option
                ])
            })
        }
    }, [])

    useEffect(() => {
        setEnableSave(false);
        if (selectedOption.length !== 0 && selectedOption.length < question.votedOptionIds.length) {
            setEnableSave(true);
            return;
        }
        selectedOption.map(option => {
            console.log('option', option);
            if (!question.votedOptionIds.includes(Number(option))) {
                console.log('Inside If');
                setEnableSave(true);
                return;
            }
            setEnableSave(false);
        })
    }, [selectedOption])


    return (
        <div>
            { question && <div>
                <Grid container direction="row" justifyContent="center" alignItems="center" style={{background : '#f2f2f28a'}}>
                    <Grid item xs={12} style={{padding: '4px 4px 0px 0px'}}>
                        {showFiles && <Button size="small" color='primary' variant='outlined' onClick={() => {setShowMatterFiles(true)}} style={{float: 'right'}}>{t('Files')}</Button>}
                        {showHistory && <Button size="small" color='primary' variant='outlined' onClick={() => {setShowHistoryModal(true)}} style={{float: 'right'}}>{t('History')}</Button>}
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="column" spacing={3} paddingX={2} paddingY={2}>
                            <Stack style={{width: '100%'}}>
                                <Typography style={{display:'flex'}} gutterBottom>
                                    <Typography variant="subtitle1" style={{fontWeight: 'bold'}}><pre>{question.displaySequence}.</pre></Typography>
                                    <Typography variant="subtitle1" gutterBottom style={{ fontWeight: 'bold', paddingLeft: '6px' }}><pre style={{ whiteSpace: 'pre-wrap' }}>{question.question}</pre></Typography>
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                <pre style={{ whiteSpace: 'pre-wrap'}}>{question.questionDescription}</pre>
                                    </Typography>
                                {question.questionType === 'SINGLE_CHOICE' && <Grid container>
                                    {/* <Grid item xs={12} style={{paddingTop: '20px'}} fullWidth> */}
                                        <FormControl style={{paddingTop: '20px'}} fullWidth>
                                        <Typography variant="body2" style={{color: 'gray', fontWeight: 'bold'}}>{question.optionHeader ? question.optionHeader : t('Options')}</Typography>
                                            <RadioGroup name="options" value={selectedOption.length > 0 && selectedOption[0].toString()} onChange={onChangeOption}>
                                                {question.options.length > 0 && question.options[0].optionType === 'TEXT' && question.options.map((option, index) => {
                                                    return (
                                                        <Stack direction="row" justifyContent="center"
                                                        alignItems="center"
                                                        spacing={2} key={`option${index}`}  className={`${classes.option} ${(selectedOption.length > 0 && selectedOption[0].toString() === option.id.toString()) && classes.selectedOption}`}>
                                                            <Stack style={{width: '2%', paddingRight: '4px', paddingLeft: '6px'}}>
                                                                <FormControlLabel
                                                                value={option.id.toString()}
                                                                control={<Radio color='primary' checkedIcon={<CheckCircleIcon />}/>}
                                                                disabled={view}/>
                                                            </Stack>
                                                            <Stack style={{width: '98%'}}>
                                                                {option.option}
                                                            </Stack>
                                                        </Stack>
                                                    )
                                                })}
                                                {question.options.length > 0 && (question.options[0].optionType === 'TEXT_AND_IMAGE' || question.options[0].optionType === 'IMAGE') &&
                                                    <Grid container >
                                                        {question.options.map((option, index) => {
                                                            return (
                                                                <Grid item sm={4} xs={12} style={{padding: '8px'}} key={index}>
                                                                    <Card style={{display: 'flex', flexDirection: 'row', padding: '8px 16px'}}
                                                                            className={`${classes.option} ${(selectedOption.length > 0 && selectedOption[0].toString() === option.id.toString()) ? classes.selectedOption : ''}`}
                                                                            >
                                                                            <Grid container>
                                                                                <Grid item xs={12}>
                                                                                    <Stack direction='row' justifyContent="center" alignItems="center" spacing={2} style={{height: '100%'}}>
                                                                                        <Stack width='10%'>
                                                                                            <FormControlLabel value={option.id.toString()}  control={<Radio color="primary" checkedIcon={<CheckCircleIcon />}/>}
                                                                                                disabled={view}/>
                                                                                        </Stack>
                                                                                        <Stack width='90%'>
                                                                                            {option.files && <img src={`data:image/png;base64,${option.files[option.s3Location]}`} alt="Option" style={{height: '25vh'}} />}
                                                                                            {question.options[0].optionType === 'TEXT_AND_IMAGE' && <Typography style={{textAlign: 'center', padding: '4px'}}>{option.option}</Typography>}
                                                                                        </Stack>
                                                                                    </Stack>
                                                                                </Grid>
                                                                            </Grid>
                                                                    </Card>
                                                                </Grid>
                                                            )
                                                        })}
                                                    </Grid>
                                                }
                                            </RadioGroup>
                                        </FormControl>
                                    {/* </Grid> */}
                                </Grid>}
                                {question.questionType === 'MULTI_CHOICE' && <Grid container>
                                    <Grid item xs={12} style={{paddingTop: '20px'}} fullWidth>
                                        {!view && <MultiOptionMinMaxInfo minSelectOption={question.minSelectInMultiChoice} maxSelectOption={question.maxSelectInMultiChoice} isCastVote={true}/>}
                                        <FormGroup fullWidth>
                                            <Typography variant="body2" style={{color: 'gray', fontWeight: 'bold'}}>{question.optionHeader ? question.optionHeader : t('Options')}</Typography>
                                            {question.options.length > 0 && question.options[0].optionType === 'TEXT' && question.options.map((option) => {
                                                return (
                                                    <FormControlLabel
                                                        className={`${classes.option} ${selectedOption.some(o => o.toString() === option.id.toString()) ? classes.selectedOption : ''}`}
                                                        key={option.id} checked={selectedOption.some(o => o.toString() === option.id.toString())} value={option.id}
                                                        control={<Checkbox color='primary'/>} onChange={(event) => onChecked(event, option.id)} label={option.option}
                                                        disabled={view}/>
                                                )
                                            })}
                                            {question.options.length > 0 && (question.options[0].optionType === 'TEXT_AND_IMAGE' || question.options[0].optionType === 'IMAGE') &&
                                                    <Grid container >
                                                        {question.options.map((option, index) => {
                                                            return (
                                                                <Grid item sm={4} xs={12} style={{padding: '8px'}} key={index}>
                                                                    <Card style={{display: 'flex', flexDirection: 'row', padding: '8px 16px'}}
                                                                            className={`${classes.option} ${selectedOption.some(o => o.toString() === option.id.toString()) ? classes.selectedOption : ''}`}
                                                                            >
                                                                            <Grid container>
                                                                                <Grid item xs={12}>
                                                                                    <Stack direction='row' justifyContent="center" alignItems="center" spacing={2}>
                                                                                        <Stack width='10%'>
                                                                                            <FormControlLabel value={option.id}
                                                                                                checked={selectedOption.some(o => o.toString() === option.id.toString())}
                                                                                                control={<Checkbox color='primary'/>} onChange={(event) => onChecked(event, option.id)}
                                                                                                disabled={view}/>
                                                                                        </Stack>
                                                                                        <Stack width='90%'>
                                                                                            {option.files && <img src={`data:image/png;base64,${option.files[option.s3Location]}`} alt="Option" style={{height: '25vh'}} />}
                                                                                            {(option.option && question.options[0].optionType === 'TEXT_AND_IMAGE') && <Typography style={{textAlign: 'center', padding: '4px'}}>{option.option}</Typography>}
                                                                                        </Stack>
                                                                                    </Stack>
                                                                                </Grid>
                                                                            </Grid>
                                                                    </Card>
                                                                </Grid>
                                                            )
                                                        })}
                                                    </Grid>
                                                }
                                        </FormGroup>
                                    </Grid>
                                </Grid>}
                                {/* {question.questionType === 'RANKING' && <Grid container>
                                    <Grid item xs={12} style={{paddingTop: '20px'}} fullWidth>
                                        <RankingSelection options={question.options} onRankChange={onRankChange}/>
                                    </Grid>
                                </Grid>}
                                {question.type === 'TEXT' && <Grid container>
                                    <Grid item xs={12} style={{paddingTop: '20px'}} fullWidth>
                                        <TextField
                                            id=""
                                            multiline
                                            rows={4}
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>} */}
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>

                {!isView && <Grid container>
                    <Grid item xs={12} style={{padding: '12px'}}>
                        {(currentQuestionIndex != 0) && <Button id="previous-question" color='primary' variant='outlined' size='small' style={{margin: '4px'}}
                                onClick={handlePrevious}>
                            {t('Previous')}
                        </Button>}
                        <Button id="save-question" color='primary' variant='contained' size='small' style={{float: "right", margin: '4px'}}
                                onClick={handleSaveVote} disabled={!enableSave}>
                            {t('Save')}
                        </Button>
                        {((numberOfQuestions - 1) !== currentQuestionIndex && question.votedOptionIds.length > 0) && <Button color='primary' variant='contained' size='small' style={{float: "right", margin: '4px'}}
                                onClick={handleSkip} id="next-question">
                            {t('Next')}
                        </Button>}
                        {question.allowQuestionSkip && <Button color='primary' variant='outlined' size='small' style={{float: "right", margin: '4px'}}
                                onClick={handleSkip} id="skip-question">
                            {t('Skip')}
                        </Button>}
                    </Grid>
                </Grid>}
            </div>}
            {message.showMsg && <EligoSnackBar show={message.showMsg} message={message.message}
                    severity={message.severity} />}
            {showHistoryModal && <VoteHistoryModel show={showHistoryModal} poll_id={poll_id} voter_id={voter_id} quesition={question} onClose={() => {setShowHistoryModal(false)}}
                voteWithLink={voteWithLink}/>}
            {showMatterFiles && <Dialog open={showMatterFiles} maxWidth='lg'>
                    <DialogTitle>
                        {`${question.displaySequence}. ${question.question}`} {t('Files')}
                        <Tooltip title='Close' arrow>
                            <Close onClick={() => setShowMatterFiles(false)} size='large' color='error' style={{ float: 'right', cursor: 'pointer' }} />
                        </Tooltip>
                    </DialogTitle>
                    <FileUploadDownload
                        t={t}
                        poll_id={poll_id}
                        // questionTitle={`${question.displaySequence}. ${question.question}`}
                        // quesitonTitle={pollCategory === TypeOfPollCategory.IBC ? t('Resolution') : t('Question')}
                        poll_question_id={question.id}
                        isUpload={false}
                        isDownload={true}
                        deletePermission={false}
                        otpLogin={voteWithLink}
                        pollCategory={pollCategory}
                    />
                </Dialog>
            }
        </div>
    )
}

export default Question;