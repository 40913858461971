import React                            from 'react';
import ReactDOM                         from 'react-dom';
import { BrowserRouter as Router }      from 'react-router-dom';
import './index.css';
import App                              from './App';
import reportWebVitals                  from './reportWebVitals';
import './i18n';
import {
    ApolloProvider, ApolloClient,
    InMemoryCache, from
} from '@apollo/client';
import { errorLink, httpLink, authMiddleware } from './config/apollo.config';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);


const client = new ApolloClient({
    link: from([errorLink, authMiddleware, httpLink()]),
    cache: new InMemoryCache()
})
const WithProvider = () => (
    <ApolloProvider client={client}>
        <Router>
            <App />
        </Router>
    </ApolloProvider>
)

ReactDOM.render(<WithProvider />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();