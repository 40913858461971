import React from 'react';
import { Grid, Typography, Link } from '@material-ui/core';

const PoweredBy = () => {
  return (
    <div>
        <Grid item xs={12} container justify="center">
            <Typography id="powerdby" variant="body2">
                <span id="power" style={{color: 'gray'}}>Powered by </span> 
                <span id="powerdLink" style={{color: 'gray', lineHeight: '2.43'}}>
                    <Link id="url" className="pointer" onClick={() => window.open('http://www.enkindletech.com','_blank')}>
                        <strong id="enkindle" style={{color: "orangered"}}>Enkindle Technologies Pvt. Ltd.</strong>
                    </Link>
                </span>
            </Typography>
        </Grid>
    </div>
  )
}

export default PoweredBy;