import { Checkbox, IconButton, TableCell, TableHead, TableRow, Toolbar, Tooltip, Typography } from "@material-ui/core";
import { alpha } from "@mui/material";
import React, { Fragment, useState } from "react";
import DeleteIcon from '@material-ui/icons/Delete';
import axios from 'axios'
import EligoBackdrop from "../../common/EligoBackdrop";
import { useTranslation } from "react-i18next";
import { AxiosEvotingInterceptor } from "../../config/axios.interceptor";
import EligoConfirmDialog from "../../common/EligoConfirmDialog";
import EligoSnackBar from "../../common/EligoSnackBar";

export function EnhancedTableHead(props) {
    const { onSelectAllClick, numSelected, rowCount } = props;

    return (
        <TableHead >
            <TableRow >
                {rowCount > 0 && <TableCell padding="checkbox" style={{ background: '#f0fcfb' }}>
                    <Checkbox
                        // style={{ paddingBottom: '10px', marginLeft: '4px' }}
                        color="secondary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                    />
                </TableCell>}

            </TableRow>
        </TableHead>
    );
}



export function EnhancedTableToolbar(props) {
    const { t } = useTranslation();
    const { numSelected, voter_ids, pollId, selected, listVoters,onSuccessDelete } = props;
    const [loading, setLoading] = useState(false);
    const [seekConfirmation, setSeekConfitmation] = useState({
        show: false,
        title: t("Confirmation"),
        message: '',
        onAgree: '',
        onDisAgree: ''
    })
    const [message, setMessage] = React.useState({
        show: false,
        message: null,
        severity: null
    });

    const DeleteMultiVoters = () => {
        setMessage({ show: false, message: '', severity: '' })
        setLoading(true)
        AxiosEvotingInterceptor.put(`voter/delete/${pollId}`, voter_ids).then(response => {
            selected([])
            onSuccessDelete()
        }).catch((error) => {
            setMessage({ show: true, message: error.message, severity: 'error' })
            setLoading(false)
        })
    }

    const confirmaion = (representsById, authRepName) => {
        setSeekConfitmation(prevState => ({
            ...prevState,
            show: true,
            message: <Fragment>{t('Are_You_Sure_That_You_Want_To')} <b>{t('Delete')}</b> {t('Selected Voters')} {t('?')}</Fragment>,
            onAgree: DeleteMultiVoters,
            onDisAgree: resetConfirmation,
        }))
    }

    const resetConfirmation = () => {
        setSeekConfitmation(prevState => ({
            ...prevState,
            show: false,
            title: t("Confirmation"),
            message: '',
            onDisAgree: '',
            onAgree: ''
        }))
    }

    return (
        <div
            style={{
                display: 'flex', justifyContent: 'center', border: '1px solid #dfdfdf', height:'32px', background:'white',
                borderRadius: '30px', paddingLeft: '10px', cursor: 'pointer', marginLeft: '10px'
            }} >

            <Typography style={{ color: 'red', marginTop: '5px' }} onClick={confirmaion}>
                {numSelected} {numSelected > 1 ? t("Rows_Selected") : t("Row_Selected")}
            </Typography>
            <Tooltip title={t("Delete_Selected_voter")}>
                <IconButton onClick={confirmaion}>
                    <DeleteIcon color='secondary'  />
                </IconButton>
            </Tooltip>

            {loading && <EligoBackdrop show={true} />}
            {seekConfirmation.show && <EligoConfirmDialog show={seekConfirmation.show}
                title={seekConfirmation.title} message={seekConfirmation.message}
                onAgree={seekConfirmation.onAgree} onDisAgree={seekConfirmation.onDisAgree} ConfirmFor={true} />}
            {message.show && <EligoSnackBar show={message.show} message={message.message}
                severity={message.severity} />}
        </div>
    );
}
