import React, { Fragment, useState } from 'react'
import {Button, makeStyles} from '@material-ui/core'
import EligoConfirmDialog from '../../../common/EligoConfirmDialog'
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    listIconMargin: {
        margin: theme.spacing(0.4),
    }
}));

const UpdatePollRoundsStatus = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [seekConfirmation, setSeekConfitmation] = useState({
        show: false,
        title: t("Confirmation"),
        message: `${t("Are_You_Sure_That_You_Want_To")} ${props.action} ${t("the")} ${t("poll")}?`,
        onAgree: '',
        onDisAgree: ''
    })
    // const [updateStatus, {loading:statusLoading, error:statusError}] = useMutation(MUTATIONS.UPDATE_POLL_ROUND, 
    //     {errorPolicy:"all", onCompleted: (data) => {
    //         resetConfirmation();
    //         if(data.updatePollRound != null) {
    //             props.afterUpdate();
    //         }
    //     },
    //     update(cache) {
    //         cache.evict({"__typename": "PaginatedPollRounds"})
    //         cache.gc()
    //     },
    //     refetchQueries:[{
    //         query: LIST_POLL_ROUND,
    //         variables: { page: props.page, limit: props.itemsPerPage, poll_id: props.pollId }
    //     }]
    // })

    const update = () => {
        // updateStatus({
        //     variables: {
        //         data: {
        //             event_id: props.id,
        //             status: props.value
        //         }
        //     }
        // })
    }
    const confirmaion = () => {
        setSeekConfitmation(prevState => ({
            ...prevState,
            show:true,
            onAgree: update,
            onDisAgree: resetConfirmation,
        }))
    }
    const resetConfirmation = () => {
        setSeekConfitmation(prevState => ({
            ...prevState,
            show: false,
            title: t("Confirmation"),
            message: `${t("Are_You_Sure_That_You_Want_To")} ${props.action} ${t("the")} ${t("poll")} ?`,
            onDisAgree: '',
            onAgree: ''
        }))
    }
    return (
        <Fragment>
            <Button id='confirm' aria-label={props.label} size="small" style={{color:`${props.textColor}`, borderColor:`${props.textColor}`}}
                variant="outlined" className={`float-right ${classes.listIconMargin}`} onClick={confirmaion} >
                    {props.btn}
            </Button>
            {/* {statusLoading && <EligoBackdrop show={true} />}
            {statusError && <GraphQLErrors error={statusError} show={false} />} */}
            {seekConfirmation.show && <EligoConfirmDialog show={seekConfirmation.show} 
                    title={seekConfirmation.title} message={seekConfirmation.message} 
                    onAgree={seekConfirmation.onAgree} onDisAgree={seekConfirmation.onDisAgree}/>}
        </Fragment>
    )
}

export default UpdatePollRoundsStatus
