import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, TextField, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import LoopIcon from '@material-ui/icons/Loop';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import EligoBackdrop from '../../common/EligoBackdrop';
import EligoReCaptcha from '../../common/EligoReCaptcha';
import EligoSnackBar from '../../common/EligoSnackBar';
import { AuthAxiosInterceptor } from '../../config/axios.interceptor';
import { EMAIL } from '../../profile/change-password/EmailPhoneToggle';
import { Fab, Grid, Stack } from '@mui/material';
import { BiMessageDots } from 'react-icons/bi';
import { Close } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    paper: {
        borderRadius: '10px !important', background: '#f7f9f9 !important',
        // animation: 'fadeup .5s .5s ease both',
        // transform: 'translateY(20px)',
    },
    closeFab: {
        position: 'absolute !important',
        top: '2% !important',
        left: '90% !important',
        float: 'right !important',
        boxShadow: 'none !important',
        color: '#d32f2f !important',
    },
    title: {
        background: '#fff',
        borderBottom: '1px solid rgb(240 241 241) !important'
    },
    titleIcon: {
        fontSize: '3rem !important', color: 'rgb(19 143 24) !important'
    },
    dialogActions: {
        padding: '0px 35px 15px 35px !important',
        columnGap: '1rem !important',
        justifyContent: 'center !important'
    },
    marginMask: {
        marginTop: '0.5rem'
    },
    formControl: {
        background: '#fff !important'
    }
}))

const ChangePasswordOtp = (props) => {
    const { isInsideProfile, username, isEmail } = {...props, isInsideProfile: props.isInsideProfile || false};
    const { t } = useTranslation();
    const classes = useStyles();
    const otpPattern = /^([0-9\s]{11})$/;
    const [loading, setLoading] = useState(false)
    const [restPwd, setResetPwd] = useState({
        otpVal: ''
    })
    const [message, setMessage] = useState({
        showMsg: props.message.showMsg,
        message: props.message.message,
        severity: props.message.severity
    })
    const [captcha, setCaptcha] = useState('');
    const [isRefresh, setIsRefresh] = useState(false);

    const handleClose = () => {
        // setOpen(false)
        props.closeOtp(false)
    }

    const setOtpValue = (e) => {
        setResetPwd(prevState => ({
            ...prevState,
            otpVal: e.target.value
        }))
    }

    const resendOtp = async event => {
        setMessage({ showMsg: false, message: '', severity: '' });
        event.preventDefault();
        setResetPwd(prevState => ({
            ...prevState,
            otpVal: ''
        }))
        setLoading(true)
        AuthAxiosInterceptor.patch(`user/request-otp?recaptcha=${captcha}&purpose=reset-password`, {
            loginId: isInsideProfile ? username : props.email,
            password: props.newPassword,
        }).then(response => {
            setLoading(false);
            props.setMessage({ showMsg: true, message: t('Otp_Has_Been_Sent_Successfully'), severity: 'success' });
            setIsRefresh(true);
            setTimeout(() => {
                setIsRefresh(false);
            }, 1000);
        }).catch((error) => {
            setLoading(false);
            setIsRefresh(true);
            setMessage({ showMsg: false, message: '', severity: '' });
            setTimeout(() => {
                setIsRefresh(false);
            }, 1000);
            if (error.message) {
                setMessage({ showMsg: true, message: error.message, severity: 'error' });
            }
        })
    }

    const submitOtp = () => {
        setMessage({ showMsg: false, message: '', severity: '' })
        let code = restPwd.otpVal.replaceAll(' ', '')
        setLoading(true)
        let payload = {
            loginId: isInsideProfile ? username : props.email,
            password: props.newPassword,
            otp: code
        }
        AuthAxiosInterceptor.patch(`user/reset-password?recaptcha=${captcha}`, payload).then(response => {
            setLoading(false);
            props.setMessage({ showMsg: true, message: "Password changed successfully", severity: "success" });
            // setOpen(false)
            if (props.dialog) {
                props.dialog(false)
            }
        }).catch((error) => {
            setIsRefresh(true);
            setTimeout(() => {
                setIsRefresh(false);
            }, 1000);
            setLoading(false)
            setMessage({ showMsg: true, message: error.message, severity: "error" });
        })
    }

    return (
        <div>
            <Dialog
                open={props.open}
                PaperProps={{ className: classes.paper }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='loading-container'
            // onClose={handleClose}
            >
                <Grid item xs={12}>
                    <Fab id="otp-pop-cancel" className={classes.closeFab} size='small' sx={{ backgroundColor: '#fff' }}
                        onClick={handleClose}>
                        <Close />
                    </Fab>
                </Grid>
                <Stack>
                    <Stack justifyContent='center' alignItems='center' px={3.5} py={3} spacing={1.5} className={classes.title}>
                        <BiMessageDots className={classes.titleIcon} />
                        <Typography id="alert-pop-title" variant='h5'>{'Enter OTP'}</Typography>
                    </Stack>
                    {/* <Divider flexItem /> */}
                    <Stack justifyContent='center' alignItems='center' px={5} py={2}>
                        <Typography variant='body2' id="alert-pop-msg"><strong>{t('Please_Enter_The_OTP_To_Confirm')}</strong></Typography>
                        <Typography variant='body2'>
                            {!isInsideProfile && t('A_OTP_Has_Been_Sent_To_Your_Registered_Mobile_Number_And_Email_ID')}
                            {isInsideProfile && <Fragment>
                                {isEmail == EMAIL ? t('A_OTP_Has_Been_Sent_To_Your_Registered_Email_Address') :
                                    t('A_OTP_Has_Been_Sent_To_Your_Registered_Mobile_Number')}
                            </Fragment>}
                        </Typography>
                        <Typography className={`text-center ${classes.marginMask}`}>
                            <InputMask
                                mask="9 9 9 9 9 9"
                                value={restPwd.otpVal}
                                onChange={setOtpValue}>
                                {() => <TextField
                                    id='otp'
                                    margin="normal"
                                    variant="outlined"
                                    className={classes.formControl}
                                    placeholder={t("OTP")}
                                    inputProps={{ style: { textAlign: 'center', fontSize: '18px', fontWeight: 'bold' } }}
                                    InputProps={{
                                        style: { textAlign: 'center', fontSize: '18px', fontWeight: 'bold' },
                                        endAdornment: (
                                            <Tooltip title="Resend">
                                                <InputAdornment position="end" className="pointer">
                                                    <LoopIcon
                                                        aria-label="resend-otp"
                                                        style={{ color: 'blue' }}
                                                        onClick={resendOtp}
                                                    >
                                                    </LoopIcon>
                                                </InputAdornment>
                                            </Tooltip>
                                        )
                                    }}
                                />}
                            </InputMask>
                        </Typography>
                    </Stack>
                </Stack>
                <DialogActions className={classes.dialogActions}>
                    <Button onClick={submitOtp} id='submit' color="primary" variant='contained' disabled={restPwd.otpVal === '' || restPwd.otpVal === '_ _ _ _ _ _' || !(otpPattern.test(restPwd.otpVal))}>
                        Submit
                    </Button>
                </DialogActions>
                {(loading) && <EligoBackdrop show={true} />}
            </Dialog>
            {message.showMsg && <EligoSnackBar show={true} message={message.message} severity={message.severity} />}
            {!isRefresh && <EligoReCaptcha captcha={setCaptcha} refresh={setIsRefresh} />}

        </div>
    )
}

export default ChangePasswordOtp