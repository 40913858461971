import React                            from 'react';
import './PollClosed.css';

const PollClosed = () => {
    return (
        // <div>
            <figure className="swing">
                <img src={process.env.PUBLIC_URL + '/images/closed.png'} width="200" />
            </figure> 
        // </div>
    )
}

export default PollClosed;