
import React, { Suspense, useState, useEffect, Fragment } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Amplify, Auth } from 'aws-amplify';
import Register from './authentication/Register';
import Login from './authentication/Login';
import aws from './aws-exports';
import { UserContext } from "./common/context/UserContext";
import { PollContext } from "./common/context/PollContext";
import EligoBackdrop from './common/EligoBackdrop';
import InitiatePollRound from './poll/poll-round/initiate-poll-round/InitiatePollRound';
import ConfirmOtpVerification from './authentication/ConfirmOtpVerification'
import NavBar from './nav-bar/NavBar';
import './App.css';
import Dashboard from './admin-settings/Dashboard'
import { requireAuthentication } from './common/HOC/RequireAuthetication';
import PollRoundInfo from './poll/poll-round/poll-round-info/PollRoundInfo';
import CastVoteDetails from './poll/cast-vote/CastVoteDetails';
import GraphQLCodeService from './poll/GraphQLCodeService'
import { GenericCodeNodeLabels } from './common/GenericCodesValues'
import { MuiThemeProvider, withStyles, createMuiTheme } from '@material-ui/core'
import ReviewVotes from './poll/cast-vote/ReviewVotes'
import PollRoles from './poll/manage-role/poll-roles/PollRoles';
import ForgotPassword from './authentication/forgot-password/ForgotPassword'
import ResetPassword from './authentication/forgot-password/ResetPassword'
import Error from './common/error/Error';
import Poll_table from './poll/P_list/Poll_table';
import Profile from './profile/Profile';
import Quote from './payment/quote/Quote';
import VerifyEmail from './authentication/forgot-password/VerifyEmail'
import SuccessfullCastVote from './poll/cast-vote/SuccessfullCastVote';
import Unauthorized from './common/error/unauthorized/Unauthorized';
import axios from 'axios';
import MFAVerification from './authentication/MFAVerification';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Questions from './poll/cast-vote/Questions/Questions';
import VotersListForAuthrep from './poll/cast-vote/VotersListForAuthrep';
import StepperView from './poll/cast-vote/StepperView';
import ReviewVoteForAuthRep from './poll/cast-vote/ReviewVoteForAuthRep';
import Clients from './profile/client/Clients';
import VoteMainPage from './poll/cast-vote/v2/VoteMainPage';
import { PublicAxiosInterceptor } from './config/axios.interceptor';
import UserProfile from './profile/UserProfile';
import AutoAccountPasswordChange from './authentication/forgot-password/AutoAccountPasswordChange';
// Amplify.configure({
//     Auth: {
//         region: process.env.REACT_APP_AWS_APPSYNC_REGION,
//         userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
//         userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID
//     }
// })

const formLabelsTheme = createMuiTheme({
    overrides: {
        MuiFormLabel: {
            asterisk: {
                color: "#db3131",
            }
        },
        MuiTooltip: {
            tooltip: {
                fontSize: "0.8em",
                // backgroundColor: '#252525 !important'
            },
            arrow: {
                // color: '#252525'
            }
        },
        MuiTypography: {
            root: {
                // fontFamily: `'Poppins', sans-serif`
                // fontFamily: `"sohne-var","Helvetica Neue","Arial",sans-serif`
            }
        },
        MuiTableCell: {
            root: {
                padding: '10px 15px'
            }
        }
    },
    palette: {
        primary: {
            main: '#2a83ad'
        },
    }
});

const App = () => {
    const history = useHistory();
    const [userSession, setUserSession] = useState({ isAuthenticated: false, session: null, system_admin_of: [], color: '' });
    const [pollDetails, setPollDetails] = useState({ isAuthenticated: false, session: null });
    const [isAuthenticating, setIsAuthenticating] = useState(true);
    const [categoryCdList, setCategoryCdList] = useState([])
    const [isCategory, setIsCategory] = useState(true)
    const [loading, setLoading] = useState(false);
    const [isOTPLogin, setIsOTPLogin] = useState(false);
    // const [selectors, data] = useDeviceSelectors(window.navigator.userAgent)

    useEffect(() => {
        if (!history.location.pathname.includes('/cast-vote')) {
            onLoad();
        } else {
            let path = history.location.pathname.split("/");
            let origin = window.location.origin;
            setLoading(true);
            PublicAxiosInterceptor.get(`poll/${path[2]}/voter/${path[3]}`)
                .then(response => {
                    setLoading(false);
                    setIsOTPLogin(true);
                    history.push({
                        pathname: "/poll/voting",
                        state: {
                            poll_id: path[2],
                            poll_details: response,
                            page: 0,
                            roles: [],
                            pollType: '',
                            vote_via_link: true,
                            voter_id: path[3],
                            auth_rep_id: path[4]
                        }
                    })
                }).catch(error => {
                    setLoading(false);
                    if (error.message) {
                        if (error.message === "You are no longer a voter for this poll. Please contact support or organizer" ||
                                error.message ===  "The poll does not exist") {
                            history.push({
                                pathname: '/unauthorized'
                            })
                        }
                    } else if (error.response) {
                        if (error.response.data.Message === "Forbidden not able to access" ||
                            error.response.data.Message === "Voter is not authorized to access" ||
                            error.response.data.Message === "Given poll is not exists") {
                            history.push({
                                pathname: '/unauthorized'
                            })
                        }
                    } else if (error.request) {
                        if (error.request.status === 0) {
                            //TODO
                            console.log("Try Again...");
                        }
                    }
                })
            // axios({
            //     url: `${process.env.REACT_APP_AWS_GATEWAY_POLL_API_URL}/poll-detail/${path[2]}/${path[3]}/${path[4]}`,
            //     method: 'GET'
            // }).then((response) => {
                // setLoading(false);
                // setIsOTPLogin(true);
                // history.push({
                //     pathname: "/poll/voting",
                //     state: {
                //         poll_id: path[2],
                //         page: 0,
                //         roles: [],
                //         pollType: '',

                //     }
                // })
            // }).catch((error) => {
                // setLoading(false);
                // if (error.response) {
                //     if (error.response.data.Message === "Forbidden not able to access" ||
                //         error.response.data.Message === "Voter is not authorized to access" ||
                //         error.response.data.Message === "Given poll is not exists") {
                //         history.push({
                //             pathname: '/unauthorized'
                //         })
                //     }
                // } else if (error.request) {
                //     if (error.request.status === 0) {
                //         //TODO
                //         console.log("Try Again...");
                //     }
                // } else {
                //     //TODO
                //     console.log('Error', error.message);
                // }
            // })
            setIsAuthenticating(false);
        }
        // getData();
    }, []);

    let onLoad = async () => {
        // try {
        //     setLoading(true);
        //     await Auth.currentSession().then(res => {
        //         Auth.currentUserInfo().then(userDetails => {
        //             if (userDetails.attributes) {
        //                 setUserSession({
        //                     isAuthenticated: true, session: {
        //                         accessToken: res.idToken.jwtToken,
        //                         name: userDetails.attributes.name,
        //                         email: userDetails.attributes.email,
        //                         phone_number: userDetails.attributes.phone_number,
        //                         userId: userDetails.attributes.sub,
        //                         roleCounter: 0,
        //                     }
        //                 });
        //                 setPollDetails({ session: null });
        //                 localStorage.setItem('token', res.idToken.jwtToken);
        //                 getUserDetails();
        //                 history.push("/polls");
        //                 setLoading(false);
        //             } else {
        //                 setLoading(false);
        //             }
        //         });
        //         // setUserSession({ isAuthenticated: true, session: { accessToken: res.idToken.jwtToken } });
        //     });
        // }
        // catch (e) {
        //     setLoading(false);
        //     if (e !== 'No current user') {
        //         console.log('error', e);
        //     }
        // }
        setIsAuthenticating(false);
    }



    const setCategoryCd = (e) => {
        if (e.getGenericCodes != null && e.getGenericCodes.properties.length > 0) {
            setCategoryCdList(e.getGenericCodes.properties);
        }
    }

    const resetCategoryCdList = () => {
        setCategoryCdList([])
        setIsCategory(true)
    }

    return (
        <Suspense fallback={<EligoBackdrop show={true} />}>
            <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
                <UserContext.Provider value={{ userSession, setUserSession }}>
                    {!isAuthenticating ? <div className="App">

                        <MuiThemeProvider theme={formLabelsTheme}>
                            <Switch>
                                <Route exact path="/" component={Login} />
                                <Route exact path="/sign-in" component={Login} />
                                <Route exact path="/sign-up" component={Register} />
                                {/* <Route exact path="/sign-up/success" component={ConfirmRegistration} /> */}
                                <Route exact path="/sign-up/success" component={ConfirmOtpVerification} />
                                <Route exact path="/forgot-password" component={ForgotPassword} />
                                <Route exact path="/reset-password" component={ResetPassword} />
                                <Route exact path="/get-quote" component={Quote} />
                                <Route exact path='/verify-Email' component={VerifyEmail} />
                                <Route exact path='/sign-in-verification' component={MFAVerification} />
                                <Route exact path="/change-password" component={AutoAccountPasswordChange} />
                                <Fragment>
                                    <PollContext.Provider value={{ pollDetails, setPollDetails }}>
                                        <div className="app-header">
                                            <NavBar />
                                        </div>
                                        <div className={userSession.color == 'white' ? "app-color" : "app-content"}>
                                            {/* <MuiThemeProvider theme={formLabelsTheme}> */}
                                            {/* {categoryCdList.length == 0 && <GraphQLCodeService labelName={GenericCodeNodeLabels.pollCategory} codeCds={setCategoryCd} /> } */}
                                            <Route excat path="/admin/dashboard" component={requireAuthentication(Dashboard)} />
                                            <Route exact path="/profile" component={requireAuthentication(UserProfile)} />
                                            <Route exact path="/clients" component={requireAuthentication(Clients)} />
                                            {/* <Route exact path="/polls" component={requireAuthentication(PollList)} /> */}
                                            {/* <Route exact path="/payment" component={requireAuthentication(PlanDetails)} /> */}
                                            <Route exact path="/polls/manage-roles" component={requireAuthentication(PollRoles)} />
                                            <Route excat path="/polls/setup" component={requireAuthentication(InitiatePollRound)} />
                                            {/* <Route excat path="/polls/poll-details/setup" render = {(props) => <PollDetails categoryCd={categoryCdList} {...props}/>} />
                                            <Route excat path="/polls/poll-details/update" render ={(props) => <PollDetails errorType={conflictError} errorMessage={conflictErrorMessage} categoryCd={categoryCdList} {...props}/>} /> */}
                                            {/* <Route exact path="/polls/poll-rounds" component={requireAuthentication(PollRounds)} /> */}
                                            {/* <Route exact path="/polls/poll-rounds/details" component={requireAuthentication(PollRoundInfo)} /> */}
                                            <Route exact path="/polls/details" component={requireAuthentication(PollRoundInfo)} />
                                            <Route exact path="/polls/details/review-vote" component={requireAuthentication(ReviewVotes, isOTPLogin)} />
                                            <Route exact path="/polls/details/success-cast-vote" component={requireAuthentication(SuccessfullCastVote, isOTPLogin)} />
                                            <Route exact path="/polls" component={requireAuthentication(Poll_table)} />
                                            <Route exact path="/poll/cast-vote" component={requireAuthentication(CastVoteDetails, isOTPLogin)} />
                                            {/* <Route exact path="/poll/stepper-view" component={requireAuthentication(VoteMainPage, isOTPLogin)} /> */}
                                            <Route exact path="/poll/voting" component={requireAuthentication(VoteMainPage, isOTPLogin)} />
                                            {/* <Route exact path="/poll/Voters-list-for-authrep" component={requireAuthentication(VotersListForAuthrep, isOTPLogin)} /> */}
                                            {/* <Route exact path="/poll/ReviewVoteForAuthRep" component={requireAuthentication(ReviewVoteForAuthRep, isOTPLogin)} /> */}
                                            <Route exact path="/poll/questions" component={requireAuthentication(Questions, isOTPLogin)} />
                                            <Route exact path="/error" component={Error} />
                                            <Route exact path="/unauthorized" component={Unauthorized} />

                                        </div>
                                    </PollContext.Provider>
                                </Fragment>
                                <Route exact={true} path='*' component={Error} />
                            </Switch>
                        </MuiThemeProvider>

                    </div> : <EligoBackdrop show={true} /> }
                </UserContext.Provider>
            </GoogleReCaptchaProvider>
            {loading && <EligoBackdrop show={loading} />}
        </Suspense>
    );
}

export default withStyles({ withTheme: true })(App);