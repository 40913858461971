import { makeStyles } from "@material-ui/core/styles";
import { Card, FormControlLabel, Grid, Radio, RadioGroup, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const EMAIL = 1;
export const PHONE_NUMBER = 2;

const useStyles = makeStyles(() => ({
    card: {
        boxShadow: '0px 8px 10px 0px #d8dfeb !important',
        width: '50% !important',
        padding: '1rem 1.5rem !important',
        border: '1px solid #eae7e7',
        '&:hover': {
            boxShadow: 'rgb(255 255 255 / 10%) 0px 5px 10px 0px inset, rgb(152 152 162 / 25%) 0px 30px 50px -20px, rgb(130 130 174 / 30%) 0px 30px 30px -30px !important',
            animation: '$scale 0.080s ease-out forwards 0.080s',
        }
    },
    '@keyframes scale': {
        '0%': {
            transform: 'scale(1)'
        },
        // '50%': {
        //     transform: 'scale(1.01)'
        // },
        '100%': {
            transform: 'scale(1.02)'
        }
    },
    selectedCard: {
        background: '#e6f3ff7d !important',
        border: '2px solid #1976d2',
    }
}))

const EmailPhoneToggle = (props) => {
    const {isToggle, setIsToggle, message} = props;
    const {t} = useTranslation();
    const classes = useStyles();
    const [domTrigger, setDomTrigger] = useState(1);

    const handleToggleChange = (event) =>{
        setIsToggle(event.target.value);
        setDomTrigger(domTrigger+1);
    }

    return (
        <Stack pb={3}>
            <RadioGroup row aria-label="email-phone" name="role" value={isToggle} onChange={handleToggleChange} key={domTrigger}>
                <Stack direction="row" width='100%' spacing={2}>
                    <Card className={`${classes.card} ${isToggle == EMAIL ? classes.selectedCard : ''}`}>
                        <div style={{ width: '100%' }}>
                            <FormControlLabel value={EMAIL} control={<Radio color="primary" style={{float: 'right'}}/>} style={{ fontWeight: 'bold' }}
                                label={
                                    <Stack direction='row' alignItems='center' justifyContent='start' spacing={0.8}>
                                        <img src={process.env.PUBLIC_URL + './images/email.svg'} width='100px' height='70px' />
                                        <Stack direction='column' spacing={1} mt={'5px !important'}>
                                            <Typography color='textSecondary' style={{ fontWeight: 'bold' }}>{t('Email_Address')}</Typography>
                                            <Typography color='textSecondary' variant="subtitle2">{t('Verification_Code_Send_To_Email')}</Typography>
                                        </Stack>
                                    </Stack>
                                }
                            />
                        </div>

                    </Card>
                    <Card className={`${classes.card} ${isToggle == PHONE_NUMBER ? classes.selectedCard : ''}`}>
                        <div style={{ width: '100%' }}>
                            <FormControlLabel value={PHONE_NUMBER} control={<Radio color="primary" style={{ float: 'right' }} />} style={{ fontWeight: 'bold' }}
                                label={
                                    <Stack direction='row' alignItems='center' justifyContent='start'>
                                        <img src={process.env.PUBLIC_URL + './images/phone.svg'} width='100px' height='70px' />
                                        <Stack direction='column' spacing={1} mt={'5px !important'}>
                                            <Typography color='textSecondary' style={{ fontWeight: 'bold' }}>{t('SMS')}</Typography>
                                            <Typography color='textSecondary' variant="subtitle2">{t('Verification_Code_Send_To_Mobile')}</Typography>
                                        </Stack>
                                    </Stack>
                                }
                            />
                        </div>
                    </Card>
                </Stack>
            </RadioGroup>
        </Stack>
    )
}

export default EmailPhoneToggle;