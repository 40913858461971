import React, {
    useState,
    useEffect,
    Fragment
}                                   from 'react';
import { 
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    DialogActions,
    Button,
    Typography,
    Card
}                                   from '@material-ui/core';
import { useTranslation }           from 'react-i18next';
import { 
    AxiosEvotingInterceptor, 
    PublicAxiosInterceptor 
}                                   from '../../../../config/axios.interceptor';
import { format }                   from 'date-fns';
import { Stack }                    from '@mui/material';
import EligoBackdrop                from '../../../../common/EligoBackdrop';

const VoteHistoryModel = ({show, onClose, poll_id, quesition, voter_id, voteWithLink}) => {
    const [open, setOpen] = useState(show);
    const [histories, setHistories] = useState([]);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const columns = [
        { id: 'option', label: t('Option'), minWidth: 20 },
        { id: 'chosen_on', label: t('Chosen_On'), minWidth: 20 },
        { id: 'verified_on', label: t('Verified_On'), minWidth: 20 },
        { id: 'source_ip', label: t('IP_Address'), minWidth: 20 },
        { id: 'voted_by', label: t('Voted_By'), minWidth: 20 }
    ];

    const handleClose = () => {
        setOpen(false);
        onClose()
    }

    const getPollQuestionHistory = () => {
        setHistories([]);
        if (voteWithLink) {
            setLoading(true);
            PublicAxiosInterceptor.get(`vote/histories/${poll_id}/${quesition.id}/${voter_id}`)
                .then(response => {
                    response.map(history => {
                        setHistories(prevState => [
                            ...prevState,
                            {
                                optionId: getOptionDesciptions(history.options),
                                uniqueVoteId: history.uniqueVoteId,
                                sourceIp: history.sourceIp,
                                votedBy: history.votedBy,
                                votedOn: history.votedOn,
                                deletedOn: history.deletedOn,
                                verifiedOn: history.verifiedOn,
                                votedByName: history.votedByName
                            }
                        ])
                    })
                    setLoading(false);
                })
                .catch(error => {
                    setLoading(false);
                    console.log('error', error);
                })
        } else {
            setLoading(true);
            AxiosEvotingInterceptor.get(`vote/histories/${poll_id}/${quesition.id}/${voter_id}`)
                .then(response => {
                    console.log('response', response);
                    response.map(history => {
                        setHistories(prevState => [
                            ...prevState,
                            {
                                optionId: getOptionDesciptions(history.options),
                                uniqueVoteId: history.uniqueVoteId,
                                sourceIp: history.sourceIp,
                                votedBy: history.votedBy,
                                votedOn: history.votedOn,
                                deletedOn: history.deletedOn,
                                verifiedOn: history.verifiedOn,
                                votedByName: history.votedByName
                            }
                        ])
                    })
                    setLoading(false);
                })
                .catch(error => {
                    setLoading(false);
                    console.log('error', error);
                })
        }
    }

    const getOptionDesciptions = (options) => {
        let descriptions = [];
        options.forEach(option => {
            let value = quesition.options.find(o => o.id === option.optionId);
            descriptions.push(value);
        });
        return descriptions;
    }

    useEffect(() => {
        getPollQuestionHistory();
    }, [])
    

    return (
        <Dialog id="vote-history-description-open" maxWidth='lg' open={open} scroll='paper' aria-labelledby="vote-history-title"
                aria-describedby="vote-history-description" >
            <DialogTitle id="vote-history-title">{t('Vote_History')}</DialogTitle>
            <DialogContent dividers>
                <Grid container>
                    <Grid item xs={12}>
                        <TableContainer>
                            <Table id='table' stickyHeader={true} aria-label="sticky-table-header" >
                                <TableHead id='tableHead' style={{ background: 'red' }}>
                                    <TableRow id='table' >
                                        {columns.map((column, index) => (
                                            <TableCell id='tabelCell' key={index} style={{ width: column.minWidth + "%", background: 'lightgray', fontWeight: 'bolder' }}>
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {histories.length === 0 && <TableRow>
                                        <TableCell id='norecord' colSpan={7}> {t('No_Record_Found')} </TableCell>
                                    </TableRow>
                                    }
                                    {histories.length > 0 && histories.map((history, i) => {
                                            return (<TableRow key={i}>
                                                <TableCell>{history.optionId.map((option, index) => {
                                                    return <div style={{margin: '4px'}} key={index}>
                                                            {option.optionType === 'TEXT' && option.option}
                                                            {(option.optionType === 'IMAGE' || option.optionType === 'TEXT_AND_IMAGE') && <Card style={{display: 'flex', flexDirection: 'row', padding: '8px 16px', width: '12vw'}}>
                                                                <Grid container>
                                                                    <Grid item xs={12}>
                                                                        <Stack direction='row' justifyContent="center" alignItems="center" spacing={2} style={{height: '100%'}}>
                                                                        <Stack width='100%'>
                                                                            {/* {option.optionType === 'TEXT' && option.option} */}
                                                                            {(option.optionType === 'IMAGE' && option.files) && <img src={`data:image/png;base64,${option.files[option.s3Location]}`} 
                                                                                    alt="Option" style={{height: '25vh'}} />}
                                                                            {(option.optionType === 'TEXT_AND_IMAGE') && <Fragment>
                                                                                {option.files && <img src={`data:image/png;base64,${option.files[option.s3Location]}`} 
                                                                                    alt="Option" style={{height: '25vh'}} />}
                                                                                {option.option && <Typography style={{textAlign: 'center', padding: '4px'}}>{option.option}</Typography>}
                                                                            </Fragment>}
                                                                        </Stack>
                                                                        </Stack>
                                                                    </Grid>
                                                                </Grid>
                                                            </Card>}
                                                        
                                                </div>
                                                })}</TableCell>
                                                <TableCell>{history.votedOn ? format(new Date(history.votedOn), process.env.REACT_APP_DATE_FORMAT_TO_DISPLAY) : ' - '}</TableCell>
                                                <TableCell>{history.verifiedOn ? format(new Date(history.verifiedOn), process.env.REACT_APP_DATE_FORMAT_TO_DISPLAY) : ' - '}</TableCell>
                                                <TableCell>{history.sourceIp ? history.sourceIp : ' - '}</TableCell>
                                                <TableCell>{history.votedByName}</TableCell>
                                            </TableRow>)
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container>
                    <Grid item xs={12}>
                        <Button id="close-history" color='primary' variant="outlined" 
                                onClick={handleClose} style={{float: 'right'}}>{t('Close')}</Button>
                    </Grid>
                </Grid>
            </DialogActions>
            {loading && <EligoBackdrop show={loading}/>}
        </Dialog>
    )
}

export default VoteHistoryModel;