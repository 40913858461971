
import { gql } from '@apollo/client';

const GET_POLLS = gql`
query GetPolls {
    allPolls {
        scannedCount
        polls {
            anonymous_voting
            category_cd
            id
            purpose
            question_shuffle
            show_results_to_voters
            title
            website_redirect
            weighted_votes
            geo_tagging
            geo_fencing
            start_manually
            disclose_voter_in_result
        }
    }
}
`;

const LIST_POLL = gql`
    query PollList($page: Int, $limit: Int, $status: String, $role: String) {
        listPoll(page: $page, limit: $limit, status: $status, role: $role) {
            polls {
                alert_time
                anonymous_voting
                description
                disclose_voter_in_result
                end_date_time
                geo_fencing
                geo_tagging
                has_voter_role
                number_of_question
                paid_voter
                payment_status
                poll_id
                pre_signed_url
                question_shuffle
                reference_number
                result_announcement_time
                roles
                sent_result_to_voters
                show_results_to_voters
                source_ip
                start_date_time
                start_manually
                status
                title
                verified
                voted
                voter_count
                voter_edge_id
                voter_role_sequence
                website_redirect
                weighted_votes
                sent_start_alert
                sent_end_alert
                vote_with_signUp
                announce_result_automatically
                organizer_name
                description
                allow_vote_change
                scrutinizer_has_to_open
                organizer_list {
                    name
                    email
                    phone_number
                }
                display_organizer_name
                verify_with_otp
            }
            scannedCount
        }
    }
`;

const LIST_POLL_ROUND = gql`
    query PollRoundList($page: Int!, $limit: Int!, $poll_id: String!) {
        listPollRound(page: $page, limit: $limit, poll_id: $poll_id) {
            pollRounds {
                end_date_time
                event_id
                poll_id
                purpose
                result_post_on_poll_close
                round_number
                start_date_time
                number_of_question
                status
                has_voter_role
                voter_count
                verified
                result_announcement_time
                voter_role_sequence
                voter_edge_id
                start_manually
                actual_start_date_time
                actual_end_date_time
                voted
                payment_status
                paid_voter
                source_ip
                alert_time
            }
            scannedCount
        }
    }
`;

const LIST_POLL_ROUND_VOTER = gql`
    query PollRoundVoterList($page: Int!, $limit: Int!, $poll_id: String!) {
        listVoter(limit: $limit, page: $page, poll_id: $poll_id) {
            voters {
                voter_id
                name
                email
                phone_number
                poll_id
                weightage
                cognito_user
                otp_verified_on
                remainder_count
            }
            scannedCount
        }
    }
`;

const SEARCH_USER = gql`
    query SearchUser($property_key: String!, $return_id_node: String!, $search_node_label: String!, $text: String!) {
        searchUser(data: {
            property_key: $property_key,
            return_id_node: $return_id_node,
            search_node_label: $search_node_label,
            text: $text
            }, limit: 10) {
        searchResult {
                email
                id
                name
                phone_number
            }
        }
    }
`;

const GET_POLL_QUESTION = gql`
    query GetPollQuestion($poll_question_id: String!) {
        getPollQuestion(poll_question_id: $poll_question_id) {
            PollQuestionResponses {
                poll_question_response_id
                option
                poll_question_id
                sequence
                vote_count
            }
            allow_multiple_vote
            allow_question_skip
            allow_vote_change
            display_organizer_name
            poll_question_id
            question
            sequence
            shuffle_options
            threshold
        }
    }
`;

const LIST_POLL_QUESTIONS = gql`
    query ListPollQuestions($limit: Int!, $page: Int!, $poll_id: String!) {
        listPollQuestion(limit: $limit, page: $page, poll_id: $poll_id) {
            scannedCount
            pollQuestions {
                PollQuestionResponses {
                    poll_question_response_id
                    option
                    poll_question_id
                    vote_count
                }
                allow_multiple_vote
                allow_question_skip
                allow_vote_change
                poll_question_id
                question
                question_description
                shuffle_options
                threshold
                weightage
            }
        }
    }
`
const GET_GENERIC_CODE_BY_NODE = gql`
    query MyGenericCodes($label: String) {
        getGenericCodes(label: $label) {
            label
            properties {
                end_date_time
                event_id
                start_date_time
                abbreviation
                description
            }
        }
    }
`;

const LIST_POLL_QUESTIONS_VOTE = gql`
    query QuestionsForVote (
        $pollId: String
        $limit: Int
        $page: Int
        ) {
        listPollQuestion(
            poll_id: $pollId,
            limit: $limit,
            page: $page
            ) {
            pollQuestions {
                question
                poll_question_id
                allow_question_skip
                allow_multiple_vote
                allow_vote_change
                selected_response_id
                threshold
                shuffle_options
                weightage
                question_description
                PollQuestionResponses {
                    option
                    poll_question_response_id
                }
            }
        }
    }
`

const LIST_POLL_QUESTIONS_RESULT = gql`
    query QuestionsForGraph (
        $pollRoundId: String
        $limit: Int
        $page: Int
        ) {
        listPollQuestion(
            poll_round_id: $pollRoundId,
            limit: $limit,
            page: $page
            ) {
            pollQuestions {
                question
                PollQuestionResponses {
                    option
                    vote_count
                }
            }
        }
    }
`

const LIST_POLL_ROLES = gql`
    query ListPollRoles ($limit: Int, $page: Int, $poll_id: String) {
        listPollRole(limit: $limit, page: $page, poll_id: $poll_id) {
            scannedCount
            userRoles {
                phone_number
                name
                email
                user_id
                login_id
                roleDetails {
                    end_date_time
                    role_id
                    granted_by
                    revoked_by
                    role
                    start_date_time
                }
            }
        }
    }
`

const GET_PRE_SIGNED_URL = gql`
    query GetPreSignedUrl($fileName: String,$fileEvent:String, $bucket: String, $versionId: String) {
        getPreSignedUrl(fileName: $fileName, fileEvent:$fileEvent, bucket: $bucket, versionId: $versionId)
    }
`

const GET_PRE_SIGNED_URL_FOR_EXCEL_UPLOAD = gql`
    query GetPreSignedUrlForExcel($fileName: String,$fileEvent:String, $bucket: String, $versionId: String) {
        getPreSignedUrlForExcel(fileName: $fileName, fileEvent:$fileEvent, bucket: $bucket, versionId: $versionId)
    }
`

const LIST_VOTE_HISTORY = gql`
    query ListVoteHistory($poll_question_id: String, $voter_id: String) {
        castHistories(poll_question_id: $poll_question_id, voter_id: $voter_id) {
            chosen_on
            deleted_on
            option
            verified_on
            source_ip
        }
    }
`

const GENERATE_RESULT = gql`
    query GenerateResult($poll_id: ID) {
        generateResult(poll_id: $poll_id) {
            alert_time
            anonymous_voting
            description
            disclose_voter_in_result
            end_date_time
            geo_fencing
            geo_tagging
            has_voter_role
            number_of_question
            paid_voter
            payment_status
            poll_id
            pre_signed_url
            question_shuffle
            reference_number
            result_announcement_time
            roles
            sent_result_to_voters
            show_results_to_voters
            source_ip
            start_date_time
            start_manually
            status
            title
            verified
            voted
            voter_count
            voter_edge_id
            voter_role_sequence
            website_redirect
            weighted_votes
            organizer_name
            poll_questions {
                question
                threshold
                PollQuestionResponses {
                    option
                    vote_count
                    verified_count
                    weightage
                    voters {
                        email
                        name
                        source_ip
                    }
                }
            }
        }
    }
`

const SEND_VOTE_REMAINDER = gql`
    query SendVoteRemainder($poll_id: ID, $voter_edge_id: [ID]) {
        remainder(poll_id: $poll_id, voter_edge_id: $voter_edge_id)
    }
`
const ANNOUNCE_RESULT = gql`
    query announceResult($poll_id: [ID],$announce_with_voter_name: Boolean) {
       announceResult(poll_id: $poll_id, announce_with_voter_name: $announce_with_voter_name)
    }
`

const PAYMENT_DETAILS = gql`
    query PaymentDetails {
        getPriceList {
            price
            price_by
            range_from
            range_to
        }
    }
`

const LIST_ADDRESS = gql`
    query ListAddress($user_id: String) {
        listAddress(user_id: $user_id) {
            addresses {
                address_line_1
                address_line_2
                address_type
                attention
                city_town
                city_town_id
                country
                country_id
                end_time
                id
                landmark
                phone
                postal_code
                postal_code_id
                province
                start_time
                state_id
                user_id

            }
        }
    }
`

const GET_COST = gql`
    query GetCost($number_of_user: Int, $poll_id: ID, $user_client_id: ID) {
        getCost(number_of_user: $number_of_user, poll_id: $poll_id, user_client_id: $user_client_id) {
            base_price {
                discount
                discount_percentage
                discount_type
                number_of_voter
                price
                price_after_discount
                remark
              }
            cess_amount
            cess_percentage
            gst_amount
            gst_percentage
            more_voter {
                discount
                discount_percentage
                discount_type
                number_of_voter
                price
                price_after_discount
                remark
            }
            total
        }
    }
`

const GET_PRICING = gql`
    query GetPricing {
        getPriceList {
            base_price
            discount_on_every_business_range_of
            discount_on_every_voter_range_of
            maximum_price_per_voter
            minimum_price_per_voter
            no_of_free_voter_on_base_price
            discount {
                discount_type
                end_discount_rate
                start_discount_rate
                successive_discount_rate
            }
        }
    }
`

const GET_CUSTOMERS = gql`
    query GetCustomers {
        listUserClient {
            address {
                address_line_1
                address_line_2
                address_type
                attention
                city_town
                city_town_id
                country
                country_id
                id
                landmark
                phone
                postal_code
                postal_code_id
                province
                start_time
                state_id
                user_id
            }
            id
            name
            party_type
            gst_treatment
            identification {
                end_time
                id
                identification
                identification_type
                start_time
                valid_from
                valid_to
            }
        }
    }
`

const GET_CUSTOMER_NAMES = gql`
    query GetCustomers {
        listUserClient {
            id
            name
            identification {
                id
                identification
                identification_type
            }
        }
    }
`


const LIST_COUNTRIES = gql`
query listCountry {
    listCountry{
    country_id
    name
  }
}
`

const LIST_STATES = gql`
query listState($country_id: ID) {
    listState(country_id: $country_id) {
      state_id
      name
      state_code
      zoho_code
    }
}
`

const LIST_CITIES = gql`
query listCity($state_id: ID){
    listCity(state_id: $state_id) {
    city_town_id
    name
  }
}`

const LIST_POSTALCODE = gql`
query listPostalCode($city_town_id: ID){
    listPostalCode(city_town_id: $city_town_id) {
    postal_code_id
	postal_code
  }
}`
const GET_USER_DETAILS = gql`
    query GetUserDetails{
    getUserSetting {
        system_admin_of
        banner_color
        theme
        last_login
    }
}`


const GET_GST_TREATMENTS = gql`
    query getGSTTreatment {
        dropdown(name: "gst_treatment") {
            description
            id
            value
        }
    }
`

const GET_FILE_LIST = gql`
query DownloadFile($limit: Int, $starting_token: String, $poll_id: ID, $poll_question_id: ID) {
    listPollFiles(limit:$limit, starting_token: $starting_token, poll_id: $poll_id, poll_question_id: $poll_question_id) {
        files {
            ETag
            Size
            StorageClass
            Key
            VersionId
            IsLatest
            LastModified
            Owner
            file_name
        }
        scannedCount
    }
  }
  `

const LIST_INVOICE_VESRIONS = gql`
    query listOfInvoiceVersions($poll_id: ID) {
        listInvoice(id: $poll_id) {
            bucket_name
            file_name
            version_id
            last_modified_date
        }
}
`

const GET_LOGIN_USER_GST_DETAILS = gql`
    query getGSTDetails {
        getGstDetails {
            gst_treatment
            identification {
                id
                identification
                identification_type
                valid_from,
                valid_to
            }
        }
    }
`

const GET_POLL_DETAILS = gql`
    query MyQuery($poll_id: String) {
        getPoll(poll_id: $poll_id) {
            poll_id
            title
            description
            status
            reference_number
            show_results_to_voters
            weighted_votes
            geo_tagging
            geo_fencing
            question_shuffle
            anonymous_voting
            website_redirect
            disclose_voter_in_result
            start_manually
            start_date_time
            end_date_time
            roles
            result_announcement_time
            number_of_question
            has_voter_role
            voter_edge_id
            voter_role_sequence
            voter_count
            voted
            verified
            pre_signed_url
            payment_status
            paid_voter
            source_ip
            alert_time
            sent_start_alert
            sent_end_alert
            sent_result_to_voters
            vote_with_signUp
            organizer_name
            post_poll_as_public
            announce_result_automatically
            allow_vote_change
            display_organizer_name
            scrutinizer_has_to_open
            verify_with_otp
        }
    }
`

const SEARCH_VOTERS = gql`
    query MyQuery($poll_id: ID, $search_text: String ) {
        searchVoter(poll_id: $poll_id, text: $search_text) {
        scannedCount
            voters {
                cognito_user
                email
                last_remainder_time
                name
                otp_verified_on
                phone_number
                poll_id
                voter_id
                weightage
            }
        }
    }
`
const SEARCH_POLL = gql`
query MyQuery($role_Type: String, $poll_Type: String , $search_text: String) {
    searchPoll(role: $role_Type, status: $poll_Type, text: $search_text) {
      polls {
        alert_time
        anonymous_voting
        description
        disclose_voter_in_result
        end_date_time
        geo_fencing
        geo_tagging
        has_voter_role
        number_of_question
        organizer_name
        paid_voter
        payment_status
        poll_id
        pre_signed_url
        question_shuffle
        reference_number
        result_announcement_time
        roles
        sent_end_alert
        sent_result_to_voters
        sent_start_alert
        show_results_to_voters
        source_ip
        start_date_time
        start_manually
        status
        title
        verified
        vote_with_signUp
        voted
        voter_count
        voter_edge_id
        voter_role_sequence
        website_redirect
        weighted_votes
        announce_result_automatically
        allow_vote_change
        display_organizer_name
        scrutinizer_has_to_open
        verify_with_otp
      }
    }
  }
`

const LIST_OFFLINE_PAYMENTS = gql`
    query listOfflinePayments($poll_id: ID) {
        paymentsList(poll_id: $poll_id) {
            mode_of_payment
            no_of_voters
            payment_date
            payment_reference_number
            service_charge
            tax
            amount_paid
        }
    }
`

const GET_IS_ALL_VOTERS_VOTED = gql`
    query getIsAllVotersVotedToClosePoll($poll_id: ID){
        allVotersVoted(poll_id: $poll_id)
    }
`

const CHECK_IS_ALL_VOTERS_VOTED = gql`
    query checkIsAllVotersVoted($poll_id: ID) {
        checkVotersVoted(poll_id: $poll_id)
    }
`

export {
    GET_POLLS, LIST_POLL, LIST_POLL_ROUND, LIST_POLL_ROUND_VOTER, SEARCH_USER, GET_POLL_QUESTION,
    LIST_POLL_QUESTIONS, GET_GENERIC_CODE_BY_NODE, LIST_POLL_QUESTIONS_VOTE, LIST_POLL_QUESTIONS_RESULT,
    LIST_POLL_ROLES, LIST_VOTE_HISTORY, GENERATE_RESULT, GET_PRE_SIGNED_URL, SEND_VOTE_REMAINDER,
    PAYMENT_DETAILS, LIST_ADDRESS, GET_COST, GET_PRICING, GET_CUSTOMERS, GET_CUSTOMER_NAMES, GET_USER_DETAILS, ANNOUNCE_RESULT,
    GET_GST_TREATMENTS, GET_LOGIN_USER_GST_DETAILS, GET_POLL_DETAILS, LIST_INVOICE_VESRIONS,
    LIST_POSTALCODE, LIST_STATES, LIST_CITIES, LIST_COUNTRIES, SEARCH_VOTERS, SEARCH_POLL, GET_FILE_LIST,
    LIST_OFFLINE_PAYMENTS, GET_IS_ALL_VOTERS_VOTED, CHECK_IS_ALL_VOTERS_VOTED,
    GET_PRE_SIGNED_URL_FOR_EXCEL_UPLOAD
};

