import React, { Fragment, useEffect } from 'react';
import { useCountDown } from '../../../../common/hooks/useCountDown';
import { useTranslation } from 'react-i18next';
import { Card, styled, Typography } from "@material-ui/core";
import { Divider, Stack } from '@mui/material';

const DisplayTime = ({ time, timeUnit }) => {
    const { t } = useTranslation();
    return (
        <div style={{ padding: '10px', backgroundColor: "white", borderRadius: '6px', 
                'boxShadow': '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)', marginRight: '4px'}}>
            <div style={{ fontSize: '2rem', fontWeight: "Bold" }}>
                {time > 9 ? time : '0' + time}
            </div>
            <div style={{ fontSize: '12px' }}>{t(timeUnit)}</div>
        </div>
    )
}

const PollStartTimer = ({ date, title, terminated }) => {
    const [days, hours, minutes, seconds] = useCountDown(date);

    useEffect(() => {
        if (seconds === 0 && days === 0 && hours === 0 && minutes === 0) {
            if (terminated) {
                terminated();
            }
        }
    }, [days, hours, minutes, seconds])


    return (
        <Fragment>
            {(days > 0 || hours > 0 || minutes > 0 || seconds > 0) && <div style={{ display: "flex", textAlign: "center" }}>
                <Stack direction='row' justifyContent='center' alignItems='center' spacing={1}>
                    <Typography>{title}</Typography>
                    <span style={{ display: 'flex' }}><DisplayTime time={days} timeUnit="Days" /> <span style={{ fontSize: '3em' }}>:</span> </span>
                    <span style={{ display: 'flex' }}><DisplayTime time={hours} timeUnit="Hours" /> <span style={{ fontSize: '3em' }}>:</span> </span>
                    <span style={{ display: 'flex' }}><DisplayTime time={minutes} timeUnit="Minutes" /> <span style={{ fontSize: '3em' }}>:</span> </span>
                    <span><DisplayTime time={seconds} timeUnit="Seconds" /></span>
                </Stack>
            </div>}
        </Fragment>
    )
}

export default PollStartTimer;