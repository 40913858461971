import { Button, Checkbox, Dialog, DialogActions, DialogTitle, IconButton, ListItemText, makeStyles, MenuItem, Tooltip, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Autocomplete, DialogContent, FormControl, InputLabel, Select, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from 'react';
import { AxiosEvotingInterceptor } from '../../config/axios.interceptor';
import { useTranslation } from "react-i18next";
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import AuditReport from './AuditReport';
import EligoBackdrop from "../../common/EligoBackdrop";
import OutlinedInput from '@mui/material/OutlinedInput';
import EligoSnackBar from "../../common/EligoSnackBar";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    display: 'flex', justifyContent: 'space-between', alignItems: 'center'
  },
}));

const ITEM_HEIGHT = 68;
const ITEM_PADDING_TOP = 2;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 350
    },
  },
};

function AuditFilter(props) {
  const { t } = useTranslation();
  const { dialogOpen, setDialogOpen, auditFilterList, storedActivity, storedDescription, storedEndDate, storedEvent, prevPage, asending,
    storedStartDate, storedPerform, isFilterApplied, pollCreateTime, pollId, generateRpt, zoneId } = props;
  const classes = useStyles();
  const [startDate, setStartDate] = useState(isFilterApplied ? storedStartDate : pollCreateTime)
  const [endDate, setEndDate] = useState(isFilterApplied ? storedEndDate : new Date())
  const [eventCds, setEventCds] = useState([])
  const [activityCds, setActivityCds] = useState([])
  const [eventName, setEventName] = useState(isFilterApplied ? storedEvent ? storedEvent : [] : [])
  const [activityName, setActivityName] = useState(isFilterApplied ? storedActivity ? storedActivity : [] : [])
  const [userName, setUserName] = useState(isFilterApplied ? storedPerform ? storedPerform : [] : [])
  const [arry] = useState(['asc', 'desc'])
  const [selectedDescription, setSelectedDescription] = useState(isFilterApplied ? storedDescription ? storedDescription : '' : '')
  const [userList, setUserList] = useState([])
  const [sortOrder, setSortOrder] = useState(isFilterApplied ? asending : 'asc')
  const [loading, setLoading] = useState(false)

 
  const [message, setMessage] = React.useState({
    showMsg: false,
    message: null,
    severity: null
  });

  const handleChangeEvent = (event) => {
    const {
      target: { value },
    } = event;
    setEventName(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleChangeActivity = (event) => {
    const {
      target: { value },
    } = event;
    setActivityName(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleChangeUser = (event) => {
    const {
      target: { value },
    } = event;
    setUserName(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleClose = () => {
    setDialogOpen(false);
    props.setPage(prevPage)
  };

  const changeAsc = (event, newValue) => {
    setSortOrder(newValue)
  }

  const onChangeDescription = (event) => {
    setSelectedDescription(event.target.value);
  }

  const auditEvent = (event) => {
    AxiosEvotingInterceptor.get(`audit/event-cds/1`).then(response => {
      if (response) {
        setEventCds(response)
      }
    }).catch((error) => {
      console.log("error", error)
    })
  }

  const auditActivity = (event) => {
    AxiosEvotingInterceptor.get(`audit/activity-cds`).then(response => {
      if (response) {
        setActivityCds(response)
      }
    }).catch((error) => {
      console.log("error", error)
    })
  }

  const auditUser = (event) => {
    AxiosEvotingInterceptor.get(`audit/user-list/${pollId}`).then(response => {
      if (response) {
        setUserList(response)
      }
    }).catch((error) => {
      console.log("error", error)
    })
  }

  const handleAuditFilter = (event) => {
    setLoading(true)
    auditFilterList(eventName, activityName, userName, selectedDescription, startDate, endDate, sortOrder)
    setDialogOpen(false)
  }

  const handleDateChange = (event) => {
    setStartDate(event)
  }

  const handleEndDateChange = (event) => {
    setEndDate(event)
  }

  const onClearFields = () => {
    setEventName([])
    setActivityName([])
    setUserName([])
    setStartDate(pollCreateTime)
    setEndDate(new Date())
    setSelectedDescription('')
    setSortOrder('asc')
  }

  useEffect(() => {
    auditEvent()
    auditActivity()
    auditUser()
  }, [])
  
  return (
    <div>
    <Dialog open={dialogOpen} disableEnforceFocus>
      <Stack direction='row' justifyContent='space-between' style={{ padding: '5px 0px 5px 0px', background: 'rgb(231, 235, 240)' }}>
        <Typography style={{ margin: '0.7rem 0rem 0rem 1rem', fontWeight: 'bold', fontSize: '19px' }}>{generateRpt ? t("Generate_Report") : t('Audit_Trail_Search')}</Typography>
        <IconButton onClick={handleClose}>
          <Close color="error" />
        </IconButton>
      </Stack><br />
      <DialogContent>
        <Stack direction='column' spacing={2}>
          <Stack direction='row' spacing={6}>
            <FormControl>
              <InputLabel id="emails" style={{ marginLeft: '1px' }}>{t("Event")}</InputLabel>
              <Select
                labelId="emails"
                id="emailCheckbox"
                multiple
                style={{ width: '265px' }}
                value={eventName}
                onChange={handleChangeEvent}
                input={<OutlinedInput label="Event"  />}
                renderValue={(selected) => {
                  let displayEventTypes = [];
                  for (let i = 0; i < selected.length; i++) {
                    let event = eventCds.find((eType) => eType.eventCd === selected[i]);
                    if (event) {
                      displayEventTypes.push(event.code)
                    }
                  }
                  return displayEventTypes.join(', ');
                }}
                MenuProps={MenuProps}
              >
                {eventCds.map((event) => {
                  return (
                    <MenuItem key={event} value={event.eventCd}>
                      <Checkbox checked={eventName.indexOf(event.eventCd) > -1} />
                      <ListItemText primary={event.code} />
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
            <FormControl style={{ width: 300 }}>
              <InputLabel id="status" style={{ marginLeft: '1px' }}>{t("Activity")}</InputLabel>
              <Select
                labelId="status"
                id="statusCheckbox"
                multiple
                label='Activity'
                value={activityName}
                onChange={handleChangeActivity}
                input={<OutlinedInput label="Activity"/>}
                renderValue={(selected) => {
                  let displayEventTypes = [];
                  for (let i = 0; i < selected.length; i++) {
                    let activity = activityCds.find((aType) => aType.activityCd === selected[i]);
                    if (activity) {
                      displayEventTypes.push(activity.code)
                    }
                  }
                  return displayEventTypes.join(', ');
                }}
                MenuProps={MenuProps}
              >
                {activityCds.map((activity) => (
                  <MenuItem key={activity.activityCd} value={activity.activityCd}>
                    <Checkbox checked={activityName.includes(activity.activityCd)} />
                    <ListItemText primary={activity.code} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>

          <Stack direction='row' spacing={2}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                style={{width:300}}
                inputVariant="outlined"
                format={process.env.REACT_APP_DATE_FORMAT_TO_DISPLAY}
                id="startTime"
                label={t("Start_Date_&_Time")}
                value={startDate}
                defaultValue={new Date(pollCreateTime)}
                minDate={new Date(pollCreateTime)}
                maxDate={new Date()}
                onChange={(event) => handleDateChange(event)}
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                style={{width:300}}
                inputVariant="outlined"
                format={process.env.REACT_APP_DATE_FORMAT_TO_DISPLAY}
                id="endTime"
                label={t("End_Date_&_Time")}
                defaultValue={new Date()}
                value={endDate}
                maxDate={new Date()}
                minDate={startDate}
                onChange={(event) => handleEndDateChange(event)}
                helperText={endDate < startDate ? t("End_date_should_be_greater_than_Start_date") : ""}
                error={endDate < startDate}
              />
            </MuiPickersUtilsProvider>
          </Stack>
          <Stack direction='row' spacing={6}>
            <FormControl>
              <InputLabel id="emails" style={{ marginLeft: '1px' }}>{t("Performed_By")}</InputLabel>
              <Select
                labelId="emails"
                id="emailCheckbox"
                multiple
                style={{ width: '265px' }}
                value={userName}
                onChange={handleChangeUser}
                input={<OutlinedInput label="Performed By"  />}
                renderValue={(selected) => {
                  let displayPerformTypes = [];
                  for (let i = 0; i < selected.length; i++) {
                    let user = userList.find((pType) => pType.loginId === selected[i]);
                    if (user) {
                      displayPerformTypes.push(user.name)
                    }
                  }
                  return displayPerformTypes.join(', ');

                }}
                MenuProps={MenuProps}
              >
                {userList?.map((user) => (
                  <MenuItem key={user.personId} value={user.loginId}>
                    <Checkbox checked={userName?.includes(user.loginId)} />
                    <ListItemText primary={user.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl style={{ width: 300 }}>
              {/* <InputLabel id="status" style={{ marginLeft: '1px' }}>Sort By</InputLabel> */}
              <Autocomplete
                options={arry}
                onChange={changeAsc}
                value={sortOrder}
                id="sort-by"
                renderInput={(params) => (
                  <TextField {...params} label={t("Sort_By")} variant="outlined" />
                )}
              />
            </FormControl>
          </Stack>

          {/* <Stack direction='row' spacing={2}> */}
            <TextField id="msg" variant="outlined" label='Message' value={selectedDescription}  onChange={onChangeDescription}>{t("Message")}</TextField>
          {/* </Stack> */}
        </Stack><br />
          {generateRpt && <AuditReport eventName={eventName} activityName={activityName} userName={userName} selectedDescription={selectedDescription} pollId={pollId}
            startDate={startDate} endDate={endDate} onClearFields={onClearFields} onSuccess={setMessage} 
            zoneId={zoneId}/>}
      </DialogContent>
    { !generateRpt &&  <DialogActions>
        <Button id="reset" variant="outlined" onClick={onClearFields}>reset</Button>
        <Button variant="outlined" id="search" color="primary" disabled={endDate < startDate} onClick={handleAuditFilter}>{t("Search")}</Button>
      </DialogActions>}
      {loading && <EligoBackdrop show={true} />}
      {message.showMsg && <EligoSnackBar show={message.showMsg} message={message.message}
              severity={message.severity} />}
    </Dialog>
  </div>
  );
}

export default AuditFilter
