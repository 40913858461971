
import CloseIcon from '@material-ui/icons//Close';
import {
  Button, Dialog, DialogContent, DialogContentText,Grid, Table, TableBody,
   TableCell, TableContainer, TableHead, TableRow,Typography, makeStyles
   } from "@material-ui/core";
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MarkunreadIcon from '@material-ui/icons/Markunread';
import SendIcon from '@material-ui/icons/Send';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import DraftsIcon from '@material-ui/icons/Drafts';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import EligoBackdrop from '../../../common/EligoBackdrop';
import { AxiosEvotingInterceptor } from '../../../config/axios.interceptor';
import { blue } from '@material-ui/core/colors';
import EligoSnackBar from '../../../common/EligoSnackBar';


const useStyles = makeStyles((theme) => ({
   details: {
     color: 'black, !important',
     padding: '0.8rem 0rem 0.8rem 0.8rem',
     fontSize: '20px !important',
     fontWeight:'bold'
    },
   closeBtn: {
     marginTop: '0.5rem !important',
     float: 'right',
     color: `red !important`
    }
}));

function StatusTable(props) {
   const { t } = useTranslation();
   const classes = useStyles()
   const [loading, setLoading] = useState(false);
   const [messageList, setMessageList] = useState([]);
   const columns = [
     { id: 'status', label: t('Status'), minWidth: 15 },
     { id: 'icon', minWidth: 2},
     { id: 'time', label: t('Date_And_Time'), minWidth: 25 }
   ];
    const [message, setMessage] = React.useState({
        showMsg: false,
        message: null,
        severity: null
    })
    
const handleClose = () => {
    props.setShowStatusHistory({
    show: false,
    });
}

const statusTableApi = () => {
       setMessage({ showMsg: false, message: "", severity: '' });
   setLoading(true);
   AxiosEvotingInterceptor.get(`audit/email-histories/${props.pollId}/${props.messageId}`).then((response) => {
       setMessageList(response)
       setLoading(false);
   }).catch((error) => {
       setMessage({ showMsg: true, message: error.message, severity: 'error' });
       setLoading(false);
   });
}

useEffect(() => {
  statusTableApi()
}, [])
 
return (
   <div id='status-div'>
    <Dialog  open={props.show} aria-labelledby="status-detail-title" id='dialogStatus'
        aria-describedby="status-detail-description" fullWidth  maxWidth="xs"  >
        <Grid container style={{background:'lightgray'}} >
            <Grid item xs={6} >
                <Typography id="status-detail-title" className={classes.details} >
                    {t("Status_History")}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Button id='btnClose' className={classes.closeBtn} onClick={handleClose}><CloseIcon /></Button>
            </Grid>
        </Grid>
                <DialogContent id='content'>
                    <DialogContentText id="status-detail-description" >
                        <Grid item xs={12}>
                            <TableContainer id='tablecontainer'>
                                <Table id='table' stickyHeader aria-label="sticky-table-header" >
                                    <TableHead id='tableHead'>
                                        <TableRow id='tableRow'>
                                            {columns.map((column, index) => (
                                                <TableCell id={`cell-column${index}`} key={index} style={{ width: column.minWidth + "%", fontWeight: 'bold', fontSize: '15px' }}>
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                         </TableRow>
                                            </TableHead>
                                        <TableBody id='tableBody' >
                                             {messageList.map((row, index) => (
                                                <TableRow key={index}>
                                                    <TableCell id='tableCell' size='small'>
                                                        <span>{row.statusLocale}</span>
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.statusLocale === "Click" ? <ArrowUpwardIcon /> : ''}
                                                        {row.statusLocale === "Open"? <DraftsIcon /> : ''}
                                                        {row.statusLocale === "Delivery" ? <MarkunreadIcon /> : ''}
                                                        {row.statusLocale === "Send" ? <SendIcon /> : ''}
                                                        {row.statusLocale === "Initiate" ? <MailOutlineIcon /> : ''}  
                                                    </TableCell>
                                                    <TableCell id= 'table-cell-two' size='small'>
                                                        <span>{format(new Date(row.rowCreatedOn), process.env.REACT_APP_DATE_FORMAT_TO_DISPLAY)}</span>
                                                    </TableCell>
                                                 </TableRow>   
                                                ))}        
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </DialogContentText>
                        </DialogContent>
            {loading && <EligoBackdrop show={loading} invisible={false} />}
            {message.showMsg && <EligoSnackBar show={message.showMsg} message={message.message}
                severity={message.severity} />}
            </Dialog>
            </div>
    )
}
            
export default StatusTable;