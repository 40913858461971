import { makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Description, FileCopy, FormatListBulleted, List, RemoveRedEye, Settings, ViewAgenda } from '@material-ui/icons';
import { Divider, FilledInput, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Stack, Typography, alpha } from '@mui/material';
import React, { Fragment } from 'react'
import { BsEye, BsPatchQuestion, BsPatchQuestionFill } from 'react-icons/bs';
import { IoDocument, IoOptions } from 'react-icons/io5';
import { QuestionMenu } from '../QuestionStaticVar';

const useStyles = makeStyles((theme) => ({
    menu: {
        cursor: 'pointer',
        pointerEvents: 'all',
        '&:hover': {
            background: alpha(theme.palette.primary.main, 0.12)
        },
        '&:active': {
            background: alpha(theme.palette.primary.light, 0.5)
        }
    },
    selected: {
        background: '#f6fafc',
        borderLeft: `6px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,
        transition: `borderLeft 0.7s ease-in-out`
    }
}))

const PollQuestionDetailTab = (props) => {
    const { selectedMenu, setSelectedMenu, handleMenuChange, showMatterFiles, questionTitle, showResult, title } = props;
    const classes = useStyles();

    const handleChange = (value) => {
        setSelectedMenu(value);
        handleMenuChange(value);
    }

    return (
        <Paper sx={{ width: 'fit-content', position: 'sticky', top: '8px', height: 'fit-content', minWidth: '155px !important' }} className='side-bar'>
            <div>
                <Stack justifyContent='center' alignItems='center' p={2} className={selectedMenu === QuestionMenu.matter ? classes.selected : '' + ' ' + classes.menu }
                onClick={() => handleChange(QuestionMenu.matter)}>
                    <BsPatchQuestionFill style={{fontSize: '1.3rem'}} />
                    <Typography>{questionTitle}</Typography>
                </Stack>
                <Divider flexItem />
                <Stack justifyContent='center' alignItems='center' p={2} className={selectedMenu === QuestionMenu.option ? classes.selected : '' + ' ' + classes.menu}
                onClick={() => handleChange(QuestionMenu.option)}>
                    <FormatListBulleted />
                    <Typography>Options</Typography>
                </Stack>
                <Divider flexItem />
                <Stack justifyContent='center' alignItems='center' p={2} className={selectedMenu === QuestionMenu.settings  ? classes.selected : '' + ' ' + classes.menu}
                onClick={() => handleChange(QuestionMenu.settings)}>
                    <Settings />
                    <Typography>Settings</Typography>
                </Stack>
                <Divider flexItem />
                {showMatterFiles && <Fragment>
                    <Stack justifyContent='center' alignItems='center' p={2} className={selectedMenu === QuestionMenu.files ? classes.selected : '' + ' ' + classes.menu}
                    onClick={() => handleChange(QuestionMenu.files)}>
                        <Description />
                        <Typography>{title} Files</Typography>
                    </Stack>
                    <Divider flexItem />
                </Fragment>}
                <Stack justifyContent='center' alignItems='center' p={2} className={selectedMenu === QuestionMenu.preview ? classes.selected : '' + ' ' + classes.menu}
                onClick={() => handleChange(QuestionMenu.preview)}>
                    <RemoveRedEye />
                    <Typography>Preview</Typography>
                </Stack>
                {showResult && <Fragment>
                    <Divider flexItem />
                    <Stack justifyContent='center' alignItems='center' p={2} className={selectedMenu === QuestionMenu.result ? classes.selected : '' + ' ' + classes.menu}
                        onClick={() => handleChange(QuestionMenu.result)}>
                        <RemoveRedEye />
                        <Typography sx={{ textAlign: 'center' }}>Real-Time Result</Typography>
                    </Stack>
                </Fragment>}
            </div>
        </Paper>
    )
}

export default PollQuestionDetailTab;