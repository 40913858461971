import React, { useContext, useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import {
    makeStyles,
    Container,
    Card,
    TextField,
    CardContent,
    Grid,
    Avatar,
    CardHeader,
    Breadcrumbs,
    Link,
    Typography,
    IconButton,
    InputAdornment,
    Tooltip,
    MenuItem,
    Button
} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import AttachEmailIcon from '@material-ui/icons/Email';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import { UserContext } from '../common/context/UserContext';
import { useTranslation } from 'react-i18next';
import Addresses from './address/Addresses';
import EditIcon from '@material-ui/icons/EditOutlined';
// import ClearIcon from '@material-ui/icons/ClearOutlined';
import ClearIcon from '@material-ui/icons/Clear';
// import DoneIcon from '@material-ui/icons/DoneOutlined';
import DoneIcon from '@material-ui/icons/Done';
import EligoBackDrop from '../common/EligoBackdrop';
import GraphQLErrors from '../common/GraphQLErrors';
import './Profile.css';
import * as Validators from '../common/form-validators.js';
import MuiPhoneNumber from "material-ui-phone-number";
import EligoSnackBar from '../common/EligoSnackBar';
import ChangePassword from '../authentication/changePassword/ChangePassword';
import { Auth } from 'aws-amplify';
import EligoConfirmDialog from '../common/EligoConfirmDialog';
import FieldHelperText from '../common/FieldHelperText';
import LoginHistory from './login-history/LoginHistory';
import LoginHistoryPopup from './login-history/LoginHistoryPopup';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '4vh'
    },
    avatar: {
        backgroundColor: '#67a1df',
        width: theme.spacing(14),
        height: theme.spacing(14),
        margin: '24px'
    }
}));

const Profile = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const classes = useStyles();
    const context = useContext(UserContext);
    const [loading, setLoading] = useState(false)
    const [error, setErrors] = useState({})
    const [touched, setTouched] = useState({});
    const [isEditGSTID, setIsEditGSTID] = useState(false);
    const [isEditPANID, setIsEditPANID] = useState(false);
    const [preferredMFA, setPreferredMFA] = useState('');
    const [isEditGSTTreatment, setIsEditGSTTreatment] = useState(false);
    const [viewHistoryOpen, setViewHistoryOpen] = useState(false);
    const [gstTreatments, setGstTreatments] = useState([]);
    const [message, setMessage] = useState({
        showMsg: false,
        message: null,
        severity: null
    })
    const [changePwd, setChangePwd] = useState(false)
    const [dialog, setDialog] = useState(false)
    const [errorMessage, setErrorMessage] = useState('');

    const [seekConfirmation, setSeekConfitmation] = useState({
        show: false,
        title: '',
        message: '',
        onAgree: '',
        onDisAgree: ''
    })

    const [userDetails, setUserDetails] = useState({
        identification_gst: {
            id: '',
            identification: '',
            identification_type: 'GSTIN',
            valid_from: new Date(),
            valid_to: new Date('9999-12-31'),
            previousIdentification: ''
        },
        identification_pan: {
            id: '',
            identification: '',
            identification_type: 'PAN',
            valid_from: new Date(),
            valid_to: new Date('9999-12-31'),
            previousIdentification: ''
        },
        gst_treatment: '',
        previousGSTTreatment: '',
        userName: context.userSession.session.name,
        emailId: context.userSession.session.email,
        mobileNumber: context.userSession.session.phone_number,
        isEditUserName: false,
        isEditEmail: false,
        isEditPhNo: false,
        errors: {
        },
    })

    const redirectToHome = () => {
        history.push("/polls");
    }

    const getIdentificationByType = (identificationType, identifications) => {
        let identification = null;
        identifications.forEach(element => {
            if (element.identification_type == identificationType) {
                identification = {
                    id: element.id,
                    user_id: element.user_id,
                    identification: element.identification,
                    identification_type: element.identification_type,
                    valid_from: element.valid_from,
                    valid_to: element.valid_to,
                    previousIdentification: ''
                }
            }
        });

        if (identification == null) {
            return {
                id: '',
                user_id: context.userSession.session.userId,
                identification: '',
                identification_type: identificationType,
                valid_from: new Date(),
                valid_to: new Date('9999-12-31'),
                previousIdentification: ''
            }
        } else {
            return identification;
        }
    }


    const getIDsToSave = () => {
        let identifications = [];
        if (userDetails.identification_gst.identification) {
            identifications.push({
                identification: userDetails.identification_gst.identification,
                identification_type: userDetails.identification_gst.identification_type,
                valid_from: userDetails.identification_gst.valid_from,
                valid_to: userDetails.identification_gst.valid_to
            })
        }
        if (userDetails.identification_pan.identification) {
            identifications.push({
                identification: userDetails.identification_pan.identification,
                identification_type: userDetails.identification_pan.identification_type,
                valid_from: userDetails.identification_pan.valid_from,
                valid_to: userDetails.identification_pan.valid_to
            })
        }
        return identifications;
    }

    const updateID = async (identification) => {
        setErrors({})
        setTouched({})
        setTimeout(() => {
            setMessage({ showMsg: false, message: null, severity: null })
        }, 2000);
        let identifications = getIDsToSave();
        if (identifications.length > 0) {
            await validateForms(identification).then(data => {
                if (Object.values(data.errors).length == 0) {
                    // updateIdentification({
                    //     variables: {
                    //         data: [
                    //             {
                    //                 id: identification.id,
                    //                 user_id: context.userSession.session.userId,
                    //                 identification: identification.identification,
                    //                 identification_type: identification.identification_type,
                    //                 valid_from: identification.valid_from,
                    //                 valid_to: identification.valid_to
                    //             }
                    //         ]
                    //     }
                    // })
                }
            })
        } else {
            setMessage({ showMsg: true, message: t('GSTIN_Or_PAN_Is_Mandatory'), severity: 'error' })
        }
    }

    const updateEmailId = () => {
        validateForm('emailId').then(data => {
            if (Object.values(data.errors).length === 0) {
                // updateUserEmailId({
                //     variables: {
                //         data: {
                //             email: userDetails.emailId
                //         },
                //         user_id: context.userSession.session.userId
                //     }
                // })
            }
        })
    }

    const updatePhoneNo = () => {
        validateForm('mobileNumber').then(data => {
            if (Object.values(data.errors).length === 0) {
                // updateUserEmailId({
                //     variables: {
                //         data: {
                //             phone_number: userDetails.mobileNumber
                //         },
                //         user_id: context.userSession.session.userId
                //     }
                // })
            }
        })
    }

    const updateUserName = () => {
        validateForm('userName').then(data => {
            if (Object.values(data.errors).length === 0) {
                // updateUserEmailId({
                //     variables: {
                //         data: {
                //             name: userDetails.userName.replace(/^\s+|\s+$/gm, '').replace(/\s\s+/g, ' ')
                //         },
                //         user_id: context.userSession.session.userId
                //     }
                // })
            }

        })
    }

    // const onIdentificationChange = (event) => {
    //     setUserDetails(prevState => ({
    //         ...prevState,
    //         identification: {
    //             ...prevState.identification,
    //             [event.target.id]: event.target.value
    //         }
    //     }))
    // }

    // const setEditID = (id) => {
    //     setUserDetails(prevState => ({
    //         ...prevState,
    //         identification: {
    //             ...prevState.identification,
    //             previousIdentification: id
    //         }
    //     }))
    //     // setIsEditID(true);
    // }

    // const cancelEditId = () => {
    //     setErrors({});
    //     setTouched({})
    //     setUserDetails(prevState => ({
    //         ...prevState,
    //         identification: {
    //             ...prevState.identification,
    //             identification: prevState.identification.previousIdentification,
    //             previousIdentification: ''
    //         }
    //     }))
    //     // setIsEditID(false);
    // }

    const onGSTTreatmentChange = (event) => {
        setUserDetails(prevState => ({
            ...prevState,
            gst_treatment: event.target.value
        }))
    }

    const onInputChange = (event) => {
        setUserDetails(prevState => ({
            ...prevState,
            [event.target.id]: event.target.value
        }))
    }

    // const onInputChangeEmail = (event) => {
    //     setUserDetails(prevState => ({
    //         ...prevState,
    //         emailId: event.target.value.replace(/^\s+|\s+$/gm,'').replace( /\s\s+/g, '' )
    //     }))
    // }

    const onMobileNumberChange = (fieldName, value) => {
        setUserDetails(prevState => ({
            ...prevState,
            [fieldName]: value
        }));
    }

    const onPANIdentificationChange = (event) => {
        setUserDetails(prevState => ({
            ...prevState,
            identification_pan: {
                ...prevState.identification_pan,
                [event.target.id]: event.target.value
            }
        }))
    }

    const onGSTIdentificationChange = (event) => {
        setUserDetails(prevState => ({
            ...prevState,
            identification_gst: {
                ...prevState.identification_gst,
                [event.target.id]: event.target.value
            }
        }))
    }

    const setEditGSTTreatment = (gst_treatment) => {
        setUserDetails(prevState => ({
            ...prevState,
            previousGSTTreatment: gst_treatment
        }))
        setIsEditGSTTreatment(true);
    }

    const setUserNameEdit = () => {
        setUserDetails(prevState => ({
            ...prevState,
            isEditUserName: true
        }))
    }

    const setEmailIdEdit = () => {
        setUserDetails(prevState => ({
            ...prevState,
            isEditEmail: true
        }))
    }

    const setPhoneNoEdit = () => {
        setUserDetails(prevState => ({
            ...prevState,
            isEditPhNo: true,
        }))
    }

    const setEditPANID = (id) => {
        setUserDetails(prevState => ({
            ...prevState,
            identification_pan: {
                ...prevState.identification_pan,
                previousIdentification: id
            }
        }))
        setIsEditPANID(true);
    }

    const setEditGSTID = (id) => {
        setUserDetails(prevState => ({
            ...prevState,
            identification_gst: {
                ...prevState.identification_gst,
                previousIdentification: id
            }
        }))
        setIsEditGSTID(true);
    }

    const cancelEditUserName = () => {
        setUserDetails(prevState => ({
            ...prevState,
            userName: context.userSession.session.name,
            isEditUserName: false,
            errors: {},
        }))
    }

    const cancelEditEmailId = () => {
        setUserDetails(prevState => ({
            ...prevState,
            emailId: context.userSession.session.email,
            isEditEmail: false,
            errors: {}
        }))
    }

    const cancelEditPhoneNo = () => {
        setUserDetails(prevState => ({
            ...prevState,
            mobileNumber: context.userSession.session.phone_number,
            isEditPhNo: false,
            errors: {}
        }))
    }

    const cancelEditGSTTreatment = () => {
        setUserDetails(prevState => ({
            ...prevState,
            gst_treatment: prevState.previousGSTTreatment,
            previousGSTTreatment: ''
        }))
        setIsEditGSTTreatment(false);
    }

    const cancelEditPANId = () => {
        setErrors({})
        setTouched({})
        setUserDetails(prevState => ({
            ...prevState,
            identification_pan: {
                ...prevState.identification_pan,
                identification: prevState.identification_pan.previousIdentification,
                previousIdentification: ''
            }
        }))
        setIsEditPANID(false);
    }

    const cancelEditGSTId = () => {
        setErrors({})
        setTouched({})
        setUserDetails(prevState => ({
            ...prevState,
            identification_gst: {
                ...prevState.identification_gst,
                identification: prevState.identification_gst.previousIdentification,
                previousIdentification: ''
            }
        }))
        setIsEditGSTID(false);
    }

    const validate = {
        userName: userName => Validators.requiredValidation('Name', userName),
        identification_pan: identification => Validators.panValidation(identification),
        identification_gst: identification => Validators.gstValidation(identification),
        emailId: emailId => (Validators.requiredValidation('Email', emailId) || Validators.emailValidation(emailId)),
        mobileNumber: mobileNumber => (Validators.requiredValidation('Mobile Number', mobileNumber) || Validators.mobileNumberValidation(mobileNumber))
    }

    const validateForm = async (key) => {
        setUserDetails(prevState => ({
            ...prevState,
            errors: {}
        }));
        let formErrors = { errors: {}, touched: {} };
        const newError = validate[key](userDetails[key])
        formErrors = {
            errors: {
                ...formErrors.errors,
                ...(newError && { [key]: newError }),
            }
        }
        setUserDetails(prevState => ({
            ...prevState,
            errors: formErrors.errors
        }));
        return formErrors;
    }

    const validateForms = async (identification) => {
        let formErrors = { errors: {} };
        Object.entries(identification).map(([key]) => {
            if (key === 'identification') {
                const idKey = (identification.identification_type == 'GSTIN' ? 'identification_gst' : 'identification_pan')
                const newError = validate[idKey](identification[key])
                const newTouched = { [idKey]: true };
                formErrors = {
                    errors: {
                        ...formErrors.errors,
                        ...(newError && { [idKey]: newError }),
                    },
                    touched: {
                        ...formErrors.touched,
                        ...newTouched
                    }
                }
            }
        })

        setErrors(formErrors.errors);
        setTouched(formErrors.touched)
        return formErrors;
    }
    const changePassword = async event => {
        setDialog(true)
        // handleValidation(username);
        // if(isValidForm) {
        // event.preventDefault();
        // setLoading(true);
        // try {
        //     await Auth.forgotPassword(userDetails.emailId)
        //     .then(res => {
        //         setErrorMessage('');
        //         setMessage({showMsg:false, message:null, error:null});
        //         setLoading(false)
        //         setDialog(true)
        //     })
        // } catch (error) {
        //     // setErrorMessage(error.message);
        //     setMessage({showMsg:true, message:error.message, error:'error'});
        //     setLoading(false)
        // }
        // }
    }
    console.log("profile message", message)

    const confirmToEnableTwoStepVerification = () => {
        setSeekConfitmation({
            show: true,
            title: t('Confirmation'),
            message: t('Enable_Two_Factor_Authentication_Confirmation_Msg'),
            onAgree: enableTwoStepVerification,
            onDisAgree: resetSeekConfirmation
        })
    }

    const confirmToDisableTwoStepVerification = () => {
        setSeekConfitmation({
            show: true,
            title: t('Confirmation'),
            message: t('Disable_Two_Factor_Authentication_Confirmation_Msg'),
            onAgree: disableTwoStepVerification,
            onDisAgree: resetSeekConfirmation
        })
    }

    const enableTwoStepVerification = async () => {
        setMessage({ showMsg: false, message: null, severity: null });
        try {
            setLoading(true);
            let user = await Auth.currentAuthenticatedUser();
            await Auth.setPreferredMFA(user, 'SMS').then(res => {
                setLoading(false);
                if (res = 'SUCCESS') {
                    setMessage({ showMsg: true, message: t('Two_Step_Verification_Enabled_Successfully'), severity: 'success' });
                    currentPreferredMFA();
                    resetSeekConfirmation();
                }
            })
        } catch (error) {
            setLoading(false);
            setMessage({ showMsg: true, message: error, severity: 'error' });
            resetSeekConfirmation();
        }
    }

    const disableTwoStepVerification = async () => {
        setMessage({ showMsg: false, message: null, severity: null });
        try {
            setLoading(true);
            let user = await Auth.currentAuthenticatedUser();
            await Auth.setPreferredMFA(user, 'NOMFA').then(res => {
                setLoading(false);
                if (res = 'SUCCESS') {
                    setMessage({ showMsg: true, message: t('Two_Step_Verification_Diabled_Successfully'), severity: 'success' });
                    currentPreferredMFA();
                    resetSeekConfirmation();
                }
            })
        } catch (error) {
            setLoading(false);
            setMessage({ showMsg: true, message: error, severity: 'error' });
            resetSeekConfirmation();
        }
    }

    const resetSeekConfirmation = () => {
        setSeekConfitmation({
            show: false,
            title: '',
            message: '',
            onAgree: '',
            onDisAgree: ''
        })
    }

    const currentPreferredMFA = async () => {
        await Auth.currentAuthenticatedUser().then(data => {
            setPreferredMFA(data.preferredMFA)
        });
    }

    useEffect(() => {
        currentPreferredMFA();
    }, [])


    return (
        <Container className={`${classes.root}`}>
            <Breadcrumbs aria-label="profile-breadcrumb">
                <Link underline="hover"
                    color="primary"
                    style={{ cursor: 'pointer' }}
                    onClick={redirectToHome}
                >
                    Home
                </Link>
                <Typography color="text.primary">{t('My_Profile')}</Typography>
            </Breadcrumbs>
            <Card elevation={8}>
                <CardHeader title={t('My_Profile')} />
                <div style={{ float: 'right', marginTop: '-48px' }}>
                    <Button variant="outlined" style={{ float: 'right', margin: '0px 10px' }} color='primary' size='small' onClick={changePassword}>change password</Button>
                    {preferredMFA === 'NOMFA' && <Fragment>
                        <Button size="small" variant="outlined" color="primary"
                            className={classes.margin} onClick={confirmToEnableTwoStepVerification}>
                            {t('Enable_Two_Step_Verification')}
                        </Button>
                        {/* <FieldHelperText helperText={t('Enable_Two_Step_Verification_Helper_Text')}/> */}
                    </Fragment>}
                    {preferredMFA !== 'NOMFA' && <Fragment>
                        <Button size="small" variant="outlined" color="primary"
                            className={classes.margin} onClick={confirmToDisableTwoStepVerification}>
                            {t('Disable_Two_Step_Verification')}
                        </Button>
                        {/* <FieldHelperText helperText={t('Disable_Two_Step_Verification_Helper_Text')}/> */}
                    </Fragment>}
                    <Button variant="outlined" style={{ float: 'left', margin: '0px 10px', color: `#7b4397`, borderColor: `#7b4397` }} color='secondary' size='small'
                        onClick={() => setViewHistoryOpen(true)}>
                        {t('View_Login_History')}
                    </Button>
                </div>
                <CardContent>
                    <Grid container justifyContent="center">
                        <Grid item xs={12}>

                        </Grid>
                        <Grid item xs={12} sm={3} container alignItems="center">
                            <Avatar id='avatar' alt={context.userSession.session.name} className={classes.avatar} />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <Grid container spacing={3} alignItems="flex-end">
                                <Grid item style={{ width: '10%' }}>
                                    <PersonIcon id='personicom' />
                                </Grid>
                                <Grid item style={{ width: '90%' }}>
                                    <TextField value={userDetails.userName} id="userName" label="User Name"
                                        variant='standard' fullWidth
                                        onChange={onInputChange}
                                        disabled={!userDetails.isEditUserName}
                                        error={userDetails.errors['userName'] !== undefined}
                                        helperText={userDetails.errors['userName']}
                                        inputProps={{ maxLength: 50 }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end" className="pointer">
                                                    {!userDetails.isEditUserName && <Tooltip title="Edit">
                                                        <IconButton aria-label="edit-username" color={(userDetails.isEditEmail || userDetails.isEditPhNo) ? "inherit" : "primary"} style={{ padding: '8px' }}
                                                            onClick={() => setUserNameEdit()}
                                                            disabled={userDetails.isEditPhNo || userDetails.isEditEmail || isEditGSTID || isEditPANID || isEditGSTTreatment}>
                                                            <EditIcon />
                                                        </IconButton>
                                                    </Tooltip>}
                                                    {userDetails.isEditUserName && <Tooltip title="Save">
                                                        <IconButton>
                                                            <DoneIcon aria-label="update-id" style={{ color: 'green', padding: '8px' }}
                                                                onClick={updateUserName} />
                                                        </IconButton></Tooltip>}
                                                    {userDetails.isEditUserName && <Tooltip title="Cancel">
                                                        <IconButton>
                                                            <ClearIcon aria-label="cancel-update" style={{ color: 'red', padding: '8px' }}
                                                                onClick={cancelEditUserName} />
                                                        </IconButton>
                                                    </Tooltip>}
                                                </InputAdornment>
                                            )
                                        }} />
                                </Grid>
                            </Grid>

                            <Grid container spacing={3} alignItems="flex-end">
                                <Grid item style={{ width: '10%' }}>
                                    <AttachEmailIcon id='attach' />
                                </Grid>
                                <Grid item style={{ width: '90%' }}>
                                    <TextField value={userDetails.emailId} id="emailId" label="Email"
                                        variant='standard' fullWidth
                                        onChange={onInputChange}
                                        disabled={!userDetails.isEditEmail}
                                        error={userDetails.errors['emailId'] !== undefined}
                                        helperText={userDetails.errors['emailId']}
                                        inputProps={{ maxLength: 100 }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end" className="pointer">
                                                    {!userDetails.isEditEmail && <Tooltip title="Edit">
                                                        <IconButton aria-label="edit-id" color={(userDetails.isEditPhNo || userDetails.isEditUserName) ? 'inherit' : 'primary'} style={{ padding: '8px' }}
                                                            onClick={() => setEmailIdEdit()}
                                                            disabled={userDetails.isEditPhNo || userDetails.isEditUserName || isEditGSTID || isEditPANID || isEditGSTTreatment}>
                                                            <EditIcon />
                                                        </IconButton>
                                                    </Tooltip>}
                                                    {userDetails.isEditEmail && <Tooltip title="Save">
                                                        <IconButton>
                                                            <DoneIcon aria-label="update-id" style={{ color: 'green', padding: '8px' }}
                                                                onClick={updateEmailId} />
                                                        </IconButton>
                                                    </Tooltip>}
                                                    {userDetails.isEditEmail && <Tooltip title="Cancel">
                                                        <IconButton>
                                                            <ClearIcon aria-label="cancel-update" style={{ color: 'red', padding: '8px' }}
                                                                onClick={cancelEditEmailId} />
                                                        </IconButton>
                                                    </Tooltip>}
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>

                            </Grid>

                            <Grid container spacing={3} alignItems="flex-end">
                                <Grid item style={{ width: '10%' }}>
                                    <LocalPhoneIcon id='local' />
                                </Grid>
                                <Grid item style={{ width: '90%' }}>
                                    <MuiPhoneNumber
                                        id="mobileNumber"
                                        name="mobileNumber"
                                        label="Mobile Number"
                                        defaultCountry={"in"}
                                        value={userDetails.mobileNumber}
                                        error={userDetails.errors['mobileNumber'] !== undefined}
                                        helperText={userDetails.errors['mobileNumber']}
                                        countryCodeEditable={false}
                                        // onChange={onInputChange}
                                        // onChange={(event) => onInputChange(event)}
                                        onChange={(event) => onMobileNumberChange('mobileNumber', event)}
                                        autoFormat={false}
                                        fullWidth
                                        disabled={!userDetails.isEditPhNo} disableDropdown={!userDetails.isEditPhNo}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end" className="pointer">
                                                    {!userDetails.isEditPhNo && <Tooltip title="Edit">
                                                        <IconButton aria-label="edit-id" color={(userDetails.isEditEmail || userDetails.isEditUserName) ? 'inherit' : 'primary'} style={{ padding: '8px' }}
                                                            onClick={() => setPhoneNoEdit()}
                                                            disabled={userDetails.isEditEmail || userDetails.isEditUserName || isEditGSTID || isEditPANID || isEditGSTTreatment}>
                                                            <EditIcon />
                                                        </IconButton>
                                                    </Tooltip>}
                                                    {userDetails.isEditPhNo && <Tooltip title="Save">
                                                        <IconButton>
                                                            <DoneIcon aria-label="update-id" style={{ color: 'green', padding: '8px' }}
                                                                onClick={updatePhoneNo} />
                                                        </IconButton>
                                                    </Tooltip>}
                                                    {userDetails.isEditPhNo && <Tooltip title="Cancel">
                                                        <IconButton>
                                                            <ClearIcon aria-label="cancel-update" style={{ color: 'red', padding: '8px' }}
                                                                onClick={cancelEditPhoneNo} />
                                                        </IconButton>
                                                    </Tooltip>}
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Grid container spacing={3} alignItems="flex-end">
                                <Grid item style={{ width: '10%' }}>
                                </Grid>
                                <Grid item style={{ width: '90%' }}>
                                    <TextField id="identification" label={t(userDetails.identification_pan.identification_type)}
                                        value={userDetails.identification_pan.identification}
                                        error={touched['identification_pan'] && error.identification_pan}
                                        helperText={touched['identification_pan'] && error.identification_pan}
                                        onChange={onPANIdentificationChange}
                                        disabled={!isEditPANID}
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end" className="pointer">
                                                    {!isEditPANID && <Tooltip title="Edit">
                                                        <IconButton aria-label="edit-pan" id='edit' color="primary" style={{ padding: '8px' }}
                                                            onClick={() => setEditPANID(userDetails.identification_pan.identification)}
                                                            disabled={userDetails.isEditEmail || userDetails.isEditUserName || userDetails.isEditPhNo || isEditGSTID || isEditGSTTreatment}>
                                                            <EditIcon />
                                                        </IconButton>
                                                    </Tooltip>}
                                                    {isEditPANID && <Tooltip title="Save">
                                                        <IconButton>
                                                            <DoneIcon id='done' aria-label="update-id" style={{ color: 'green', padding: '8px' }}
                                                                onClick={() => updateID(userDetails.identification_pan)} />
                                                        </IconButton>
                                                    </Tooltip>}
                                                    {isEditPANID && <Tooltip title="Cancel">
                                                        <IconButton>
                                                            <ClearIcon id='clear' aria-label="cancel-update" style={{ color: 'red', padding: '8px' }}
                                                                onClick={cancelEditPANId} />
                                                        </IconButton>
                                                    </Tooltip>}
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} alignItems="flex-end">
                                <Grid item style={{ width: '10%' }}>
                                </Grid>
                                <Grid item style={{ width: '90%' }}>
                                    <TextField id="identification" label={t(userDetails.identification_gst.identification_type)}
                                        value={userDetails.identification_gst.identification}
                                        error={touched['identification_gst'] && error.identification_gst}
                                        helperText={touched['identification_gst'] && error.identification_gst}
                                        onChange={onGSTIdentificationChange}
                                        disabled={!isEditGSTID}
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end" className="pointer">
                                                    {!isEditGSTID && <Tooltip title="Edit">
                                                        <IconButton aria-label="edit-gst" id='edit' color="primary" style={{ padding: '8px' }}
                                                            onClick={() => setEditGSTID(userDetails.identification_gst.identification)}
                                                            disabled={userDetails.isEditEmail || userDetails.isEditPhNo || userDetails.isEditUserName || isEditGSTTreatment || isEditPANID}>
                                                            <EditIcon />
                                                        </IconButton>
                                                    </Tooltip>}
                                                    {isEditGSTID && <Tooltip title="Save">
                                                        <IconButton>
                                                            <DoneIcon id='done' aria-label="update-id" style={{ color: 'green', padding: '8px' }}
                                                                onClick={() => updateID(userDetails.identification_gst)} />
                                                        </IconButton>
                                                    </Tooltip>}
                                                    {isEditGSTID && <Tooltip title="Cancel">
                                                        <IconButton>
                                                            <ClearIcon id='clear' aria-label="cancel-update" style={{ color: 'red', padding: '8px' }}
                                                                onClick={cancelEditGSTId} />
                                                        </IconButton>
                                                    </Tooltip>}
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            {/* <Grid container spacing={3} alignItems="flex-end">
                                <Grid item style={{width:'10%'}}>
                                </Grid>
                                <Grid item style={{width:'90%'}}>
                                    <TextField id="identification" label={t(userDetails.identification.identification_type)}
                                        value={userDetails.identification.identification}
                                        onChange={onIdentificationChange}
                                        disabled={!isEditID}
                                        error={touched['identification'] && error.identification}
                                helperText={touched['identification'] && error.identification}
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end" className="pointer">
                                                    {!isEditID && <Tooltip title="Edit">
                                                        <EditIcon aria-label="edit-id" color="primary" style={{padding: '8px'}}
                                                        onClick={() => setEditID(userDetails.identification.identification)}/>
                                                    </Tooltip>}
                                                    { isEditID && <Tooltip title="Save"><DoneIcon aria-label="update-id" style={{color:'green', padding: '8px'}}
                                                        onClick={updateOrUpdateID}/></Tooltip>}
                                                    { isEditID && <Tooltip title="Cancel"><ClearIcon aria-label="cancel-update" style={{color:'red', padding: '8px'}}
                                                        onClick={cancelEditId}/></Tooltip>}
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                            </Grid> */}
                            <Grid container spacing={3} alignItems="flex-end">
                                <Grid item style={{ width: '10%' }}>
                                </Grid>
                                <Grid item style={{ width: '90%' }}>
                                    <TextField
                                        id="gst-treatment"
                                        fullWidth
                                        name="gst-treatment"
                                        select
                                        label={t('GST_Treatment')}
                                        disabled={!isEditGSTTreatment}
                                        value={userDetails.gst_treatment}
                                        onChange={onGSTTreatmentChange}
                                        SelectProps={{ IconComponent: () => null }}
                                    // InputProps={{
                                    //     endAdornment: (
                                    //         <InputAdornment position="end" className="pointer">
                                    //             {!isEditGSTTreatment && <Tooltip title="Edit">
                                    //                 <IconButton aria-label="edit-gst-treatment" id='edit' color="primary" style={{ padding: '8px' }}
                                    //                     onClick={() => setEditGSTTreatment(userDetails.gst_treatment)}
                                    //                     disabled={userDetails.isEditEmail || userDetails.isEditPhNo || userDetails.isEditUserName || isEditGSTID || isEditPANID}>
                                    //                     <EditIcon />
                                    //                 </IconButton>
                                    //             </Tooltip>}
                                    //             {isEditGSTTreatment && <Tooltip title="Save">
                                    //                 <IconButton>
                                    //                     <DoneIcon aria-label="update-gst-treatment"
                                    //                         style={{ color: 'green', padding: '8px' }}
                                    //                         onClick={updateGSTTreatmentValue} />
                                    //                 </IconButton>
                                    //             </Tooltip>}
                                    //             {isEditGSTTreatment && <Tooltip title="Cancel">
                                    //                 <IconButton>
                                    //                     <ClearIcon aria-label="cancel--gst-treatment-update"
                                    //                         style={{ color: 'red', padding: '8px' }}
                                    //                         onClick={cancelEditGSTTreatment} />
                                    //                 </IconButton>
                                    //             </Tooltip>}
                                    //         </InputAdornment>
                                    //     )
                                    // }}
                                    >
                                        {
                                            gstTreatments.map((gstTreatment) => {
                                                return <MenuItem key={gstTreatment.value} value={gstTreatment.value}>
                                                    {gstTreatment.description}
                                                </MenuItem>
                                            })
                                        }
                                    </TextField>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <br /><br />
                    <Addresses userId={context.userSession.session.userId} isLoginUser={true} isClient={false}/>
                </CardContent>
            </Card>
            {loading &&
                <EligoBackDrop show={ loading} invisible={false} />}
            {message.showMsg && <EligoSnackBar show={message.showMsg} message={message.message}
                severity={message.severity} />}
            {dialog && <ChangePassword open={dialog} dialog={setDialog} email={userDetails.emailId} loading={setLoading} setMessage={setMessage} />}
            {seekConfirmation.show && <EligoConfirmDialog show={seekConfirmation.show}
                title={seekConfirmation.title} message={seekConfirmation.message}
                onAgree={seekConfirmation.onAgree} isCancel={true} onDisAgree={seekConfirmation.onDisAgree} />}
            {viewHistoryOpen && <LoginHistoryPopup open={viewHistoryOpen} setOpen={setViewHistoryOpen} />}
        </Container>
    )
}

export default Profile;
