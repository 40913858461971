import React, {
    useState,
    useEffect,
    Fragment,
    useRef
}                               from 'react';
import { useHistory }           from 'react-router-dom';
import {
    Grid,
    Paper,
    Box,
    Button,
    BottomNavigation,
    Chip,
    Divider,
    Typography
}                               from '@mui/material';
import { useTranslation }       from 'react-i18next';
import PollInstructions         from './PollInstructions';
import PollTimer                from '../PollTimer';
import Vote                     from './Vote';
import PollDetailsPage          from './PollDetailsPage';
import {
    AxiosEvotingInterceptor,
    PublicAxiosInterceptor
}                               from '../../../config/axios.interceptor';
import { Status, TypeOfPollCategory }               from '../../../common/GenericCodes';
import PreviewVote              from './PreviewVote';
import EligoReCaptcha           from '../../../common/EligoReCaptcha';
import ConfirmVoteWithOTP       from './ConfirmVoteWithOTP';
import SuccessfullCastVote      from '../SuccessfullCastVote';
import VoteOnBehalf             from './VoteOnBehalf';
import EligoBackdrop            from '../../../common/EligoBackdrop';
import EligoSnackBar            from '../../../common/EligoSnackBar';
import PollStartTimer           from './poll-timer/PollStartTimer';
import PollClosed from './poll-closed/PollClosed';

const CastVote = ({poll_id, pollDetails, voteWithLink, voter_id, auth_rep_id, setLoading}) => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState(0);
    const [voterDetails, setVoterDetails] = useState({voterId: '', voteVerified: false, name: ''});
    const [authRepId, setAuthRepId] = useState(auth_rep_id ? auth_rep_id : '');
    const [voters, setVoters] = useState('');
    const [showSelectVoteOnBehalf, setShowSelectVoteOnBehalf] = useState('');
    const [pollStatus, setPollStatus] = useState('');
    const [votedForAllQuestions, setVotedForAllQuestions] = useState(false);
    const [captcha, setCaptcha] = useState('');
    const [isRefresh, setIsRefresh] = useState(false);
    const [expandPOllDetail, setExpandPOllDetail] = useState(false);
    const history = useHistory();
    const [showOTPConfirmation, setShowOTPConfirmation] = useState(false);
    const [message, setMessage] = React.useState({
        showMsg: false,
        message: null,
        severity: null
    })
    const previewRef = useRef(null);

    const onPollEnds = () => {
        previewRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' })
        setPollStatus(Status.closed);
        setActiveTab(5);
    }

    const onPollStarts = () => {
        setPollStatus(Status.open);
    }

    const getVoters = () => {
        setLoading(true);
        setMessage({ showMsg: false, message: '', severity: '' });
        AxiosEvotingInterceptor.get(`voter/list-voter-by-auth-rep/${poll_id}`)
            .then(response => {
                setLoading(false);
                setVoters(response);
                if(response.length === 1) {
                    setVoterDetails(response[0]);
                    if (response[0].authorizedRepresentative.length > 0 && !response[0].loginUser) {
                        setAuthRepId(response[0].authorizedRepresentative[0].personId);
                    }
                } else if (response.length > 1) {
                    setShowSelectVoteOnBehalf(true);
                }
            })
            .catch(error => {
                if (error.message) {
                    setMessage({ showMsg: true, message: error.message, severity: 'error' });
                }
                setLoading(false);
            })
    }

    const cancelVoting = () => {
        history.push("/polls");
    }

    const handleClose = () => {
        history.push("/polls");
    }

    const requestOTPToConfirmVote = () => {
        if (voteWithLink) {
            requestOTPForNonLoginUser();
        } else {
            requestOTPForLoginUser();
        }
    }

    const requestOTPForLoginUser = () => {
        setLoading(true);
        setMessage({ showMsg: false, message: '', severity: '' });
        if (authRepId) {
            AxiosEvotingInterceptor.get(`vote/${poll_id}/request-otp-verify/${voterDetails.voterId}/${authRepId}?recaptcha=${captcha}`)
            .then(response => {
                setLoading(false);
                setIsRefresh(true);
                setTimeout(() => {
                    setIsRefresh(false);
                }, 200);
                setShowOTPConfirmation(true);
            })
            .catch(error => {
                setIsRefresh(true);
                setTimeout(() => {
                    setIsRefresh(false);
                }, 200);
                if (error.message) {
                    setMessage({ showMsg: true, message: error.message, severity: 'error' });
                }
                setLoading(false);
            })
        } else {
            AxiosEvotingInterceptor.get(`vote/${poll_id}/request-otp-verify/${voterDetails.voterId}?recaptcha=${captcha}`)
            .then(response => {
                setLoading(false);
                setIsRefresh(true);
                setTimeout(() => {
                    setIsRefresh(false);
                }, 200);
                setShowOTPConfirmation(true);
            })
            .catch(error => {
                setIsRefresh(true);
                setTimeout(() => {
                    setIsRefresh(false);
                }, 200);
                if (error.message) {
                    setMessage({ showMsg: true, message: error.message, severity: 'error' });
                }
                setLoading(false);
            })
        }
    }

    const requestOTPForNonLoginUser = () => {
        setLoading(true);
        setMessage({ showMsg: false, message: '', severity: '' });
        if (authRepId) {
            PublicAxiosInterceptor.get(`vote/${poll_id}/request-otp-verify/${voterDetails.voterId}/${authRepId}?recaptcha=${captcha}`)
            .then(response => {
                setLoading(false);
                setIsRefresh(true);
                setTimeout(() => {
                    setIsRefresh(false);
                }, 200);
                setShowOTPConfirmation(true);
            })
            .catch(error => {
                setIsRefresh(true);
                setTimeout(() => {
                    setIsRefresh(false);
                }, 200);
                if (error.message) {
                    setMessage({ showMsg: true, message: error.message, severity: 'error' });
                }
                setLoading(false);
            })
        } else {
            PublicAxiosInterceptor.get(`vote/${poll_id}/request-otp-verify/${voterDetails.voterId}?recaptcha=${captcha}`)
            .then(response => {
                setLoading(false);
                setIsRefresh(true);
                setTimeout(() => {
                    setIsRefresh(false);
                }, 200);
                setShowOTPConfirmation(true);
            })
            .catch(error => {
                setIsRefresh(true);
                setTimeout(() => {
                    setIsRefresh(false);
                }, 200);
                if (error.message) {
                    setMessage({ showMsg: true, message: error.message, severity: 'error' });
                }
                setLoading(false);
            })
        }
    }

    const confirmVote = () => {
        if (pollDetails.verifyWithOtp) {
            requestOTPToConfirmVote();
        } else {
            confirmWithoutOTP();
        }
    }

    const confirmWithoutOTP = () => {
        if (voteWithLink) {
            confirmWithoutLogin();
        } else {
            confirmWithLogin();
        }
    }

    const confirmWithLogin = () => {
        setLoading(true);
        setMessage({ showMsg: false, message: '', severity: '' });
        if (authRepId) {
            AxiosEvotingInterceptor.put(`vote/${poll_id}/verify-vote/${voterDetails.voterId}/${authRepId}`, {})
                .then(response => {
                    if (response) {
                        onSuccessConfirmation();
                    }
                    setLoading(false);
                })
                .catch(error => {
                    if (error.message) {
                        setMessage({ showMsg: true, message: error.message, severity: 'error' });
                    }
                    setLoading(false);
                })
        } else {
            AxiosEvotingInterceptor.put(`vote/${poll_id}/verify-vote/${voterDetails.voterId}`, {})
                .then(response => {
                    if (response) {
                        onSuccessConfirmation();
                    }
                    setLoading(false);
                })
                .catch(error => {
                    if (error.message) {
                        setMessage({ showMsg: true, message: error.message, severity: 'error' });
                    }
                    setLoading(false);
                })
        }
    }

    const confirmWithoutLogin = () => {
        setLoading(true);
        setMessage({ showMsg: false, message: '', severity: '' });
        let payload = {
            otp: null
        }
        if (authRepId) {
            PublicAxiosInterceptor.put(`vote/${poll_id}/verify-vote/${voterDetails.voterId}/${authRepId}`, payload)
                .then(response => {
                    if (response) {
                        onSuccessConfirmation();
                    }
                    setLoading(false);
                })
                .catch(error => {
                    if (error.message) {
                        setMessage({ showMsg: true, message: error.message, severity: 'error' });
                    }
                    setLoading(false);
                })
        } else {
            PublicAxiosInterceptor.put(`vote/${poll_id}/verify-vote/${voterDetails.voterId}`, payload)
                .then(response => {
                    if (response) {
                        onSuccessConfirmation();
                    }
                    setLoading(false);
                })
                .catch(error => {
                    if (error.message) {
                        setMessage({ showMsg: true, message: error.message, severity: 'error' });
                    }
                    setLoading(false);
                })
        }
    }

    const previewVote = () => {
        previewRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' })
        setActiveTab(2);
        setIsRefresh(true);
        setTimeout(() => {
            setIsRefresh(false);
        }, 200);
    }

    const viewVote = () => {
        previewRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' })
        setActiveTab(4);
    }

    const onSuccessConfirmation = () => {
        previewRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' })
        setActiveTab(3);
        setShowOTPConfirmation(false);
    }

    const setSelectedVoteOnBehalf = (voter) => {
        setVoterDetails(voter);
        if (voter.authorizedRepresentative.length > 0 && !voter.loginUser) {
            setAuthRepId(voter.authorizedRepresentative[0].personId);
        }
    }

    useEffect(() => {
        if (!voteWithLink) {
            getVoters();
        } else {
            setVoterDetails({
                voterId: voter_id,
                voteVerified: pollDetails.verified,
                name: pollDetails.voterName
            })
        }
        if (new Date().getTime() > new Date(pollDetails.endTime).getTime()) {
            setPollStatus(Status.closed);
            setActiveTab(5);
            setExpandPOllDetail(true);
        } else if (new Date().getTime() > new Date(pollDetails.startTime).getTime()) {
            setPollStatus(Status.open);
        } else {
            setPollStatus(Status.upcoming);
        }
    }, [])

    return (
        <div ref={previewRef}>
        <Box sx={{
            display: 'flex',
            flexWrap: 'wrap',
            '& > :not(style)': {
            m: 2,
            minHeight: '95vh',
            maxHeight: 'auto',
            overflow: 'scroll',
            width: '100%',
            background: '#f0f8ff3d'
            },
        }}
        >
            <Paper>
                <Grid container direction="row">
                    <Grid item xs={12} style={{padding: '8px'}}>
                    </Grid>
                </Grid>
                <Grid container direction="row" justifyContent="center" >
                    <Grid item xs={12} sm={ pollStatus === Status.open ? 8 : 12} style={{padding: '11px', background: '#2a83ad'}}>
                        <Typography variant="h5" style={{color: 'white'}}>
                            {authRepId ? <Fragment>{t('Cast_Vote')} <span>{t('as')} {t('authorized_representative_of')} {voterDetails.name}</span></Fragment> : t('Cast_Vote')}
                        </Typography>
                    </Grid>
                    {pollStatus === Status.open && <Grid item xs={12} sm={4} style={{padding: '4px', background: '#2a83ad', display: 'flex',flexDirection: 'row-reverse'}}>
                        <PollTimer style={{float: 'right'}} date={new Date(pollDetails.endTime)} terminated={onPollEnds} />
                    </Grid>}
                </Grid>
                <Grid container direction="row">
                    <Grid item xs={12}>
                        <PollDetailsPage pollDetails={pollDetails} expandPOllDetail={expandPOllDetail}/>
                    </Grid>
                </Grid>
                {activeTab === 0 &&
                    <Fragment>
                        {pollStatus === Status.open && <Grid container direction="row" style={{padding: '12px'}}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" style={{fontWeight: 'bold'}}>{t('Instructions')}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <PollInstructions pollDetails={pollDetails} otpLogin={voteWithLink} userId={voterDetails.voterId}/>
                            </Grid>
                        </Grid>}
                        {pollStatus === Status.upcoming && <Grid container direction="row" justifyContent="center" alignItems="center" 
                                style={{height: '35vh', display: 'flex', flexDirection: 'column'}}>
                            <Typography variant="h6" gutterBottom sx={{margin: '24px'}}>{t('Poll_Starts_In')}</Typography>
                            <PollStartTimer date={new Date(pollDetails.startTime)} terminated={onPollStarts} />
                        </Grid>}
                    </Fragment>
                }
                {(activeTab === 1 && pollStatus === Status.open) && <Grid container direction="row" justifyContent="center" alignItems="center"
                        style={{padding: '12px'}}>
                    <Grid item xs={12}>
                        <br/>
                            <Divider textAlign="center">
                                <Chip sx={{ fontWeight: 'bold' }} label={pollDetails.pollCategory === TypeOfPollCategory.IBC ? t('Resolutions_Items') : t('Questions')} />
                            </Divider>
                        <br/>
                    </Grid>
                    <Grid item xs={10}>
                        <Vote poll_id={poll_id} voter_id={voterDetails.voterId} auth_rep_id={authRepId}
                            setVotedForAllQuestions={setVotedForAllQuestions} voteWithLink={voteWithLink}
                            numberOfQuestions={pollDetails.numberOfQuestions} previewVote={previewVote}
                            setLoading={setLoading} pollCategory={pollDetails.pollCategory}/>
                    </Grid>
                </Grid>}
                {(activeTab === 2 && pollStatus === Status.open ) && <Grid container direction="row" justifyContent="center" alignItems="center" style={{padding: '12px'}}>
                    <Grid item xs={12}>
                        <br/>
                            <Divider textAlign="center">
                                <Chip sx={{fontWeight: 'bold'}} label={t('Preview')} />
                            </Divider>
                        <br/>
                    </Grid>
                    <Grid item xs={12}>
                        <PreviewVote poll_id={poll_id} voter_id={voterDetails.voterId} auth_rep_id={authRepId}
                            voteWithLink={voteWithLink} pollCategory={pollDetails.pollCategory}/>
                    </Grid>
                </Grid>}
                {(activeTab === 3 && pollStatus === Status.open) && <Grid item xs={12}>
                    <SuccessfullCastVote withOTP={pollDetails.verifyWithOtp} pollCategory={pollDetails.pollCategory}/>
                </Grid>}
                {activeTab === 4 && <Grid container direction="row" justifyContent="center" alignItems="center" style={{padding: '12px'}}>
                    <Grid item xs={12}>
                        <br/>
                            <Divider textAlign="center">
                                <Chip label={t('View')} />
                            </Divider>
                        <br/>
                    </Grid>
                    <Grid item xs={12}>
                        <PreviewVote poll_id={poll_id} voter_id={voterDetails.voterId} auth_rep_id={authRepId} voteWithLink={voteWithLink} showHistory={true}/>
                    </Grid>
                </Grid>}
                {activeTab === 5 && <Grid container direction="row" justifyContent="center" alignItems="center" style={{display: 'grid', height: '40vh'}}>
                    <Grid item xs={12}>
                        <PollClosed />
                    </Grid>
                </Grid>}
                <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, marginTop: 1 }} elevation={3}>
                    <BottomNavigation showLabels>
                        <Grid container direction="row" style={{padding: '6px'}} alignItems="flex-end">
                            {activeTab === 0 &&
                            <Grid item xs={12}>
                                {pollStatus === Status.open && <Fragment>
                                    {!voterDetails.voteVerified && <Button id="continue" variant='contained' size='small' style={{float: "right", margin: '4px'}}
                                            onClick={() => {setActiveTab(1)}}>
                                    {t('Continue')}
                                    </Button>}
                                    {(voterDetails.voteVerified && pollDetails.allowVoteChange) && <Button id="recast" variant='contained' size='small' style={{float: "right", margin: '4px'}}
                                            onClick={() => {setActiveTab(1)}}>
                                        {t('Recast')}
                                    </Button>}
                                    {voterDetails.voteVerified && <Button id="view-vote" variant='contained' size='small' style={{float: "right", margin: '4px'}}
                                            onClick={viewVote}>
                                        {t('View')}
                                    </Button>}
                                    <Button id="cancel" variant='outlined' size='small' style={{float: "right", margin: '4px'}}
                                            onClick={cancelVoting}>
                                        {t('Cancel')}
                                    </Button>
                                </Fragment>}

                                {(pollStatus === Status.upcoming || pollStatus === Status.closed) && <Button id="close" variant='outlined' size='small' style={{float: "right", margin: '4px'}}
                                        onClick={handleClose}>
                                    {t('Close')}
                                </Button>}
                            </Grid>}
                            {activeTab === 1 && <Grid item xs={12}>
                                <Button id="preview-vote" variant='contained' size='small' style={{float: "right", margin: '4px'}}
                                        onClick={previewVote} disabled={!votedForAllQuestions}>
                                    {t('Preview')}
                                </Button>
                                <Button id="cancel" variant='outlined' size='small' style={{float: "right", margin: '4px'}}
                                        onClick={cancelVoting}>
                                    {t('Cancel')}
                                </Button>
                            </Grid>}
                            {activeTab === 2 && <Grid item xs={12}>
                                <Button id="back" variant='outlined' size='small' style={{float: "left", margin: '4px'}}
                                        onClick={() => {setActiveTab(1)}}>
                                    {t('Back')}
                                </Button>
                                <Button id="confirm-vote" variant='contained' size='small' style={{float: "right", margin: '4px'}}
                                        onClick={confirmVote}>
                                    {t('Confirm')}
                                </Button>
                                <Button id="cancel" variant='outlined' size='small' style={{float: "right", margin: '4px'}}
                                        onClick={cancelVoting}>
                                    {t('Cancel')}
                                </Button>
                            </Grid>}
                            {activeTab === 4 && <Grid item xs={12}>
                                {<Button id="close" variant='outlined' size='small' style={{float: "right", margin: '4px'}}
                                        onClick={handleClose}>
                                    {t('Close')}
                                </Button>}
                            </Grid>}
                            {activeTab === 5 && <Grid item xs={12}>
                                <Button id="close" variant='outlined' size='small' style={{float: "right", margin: '4px'}}
                                    onClick={handleClose}>
                                {t('Close')}
                                </Button>
                                {(pollDetails.verified || pollStatus === Status.closed) && <Button id="view-closed-vote" variant='contained' size='small' style={{float: "right", margin: '4px'}}
                                        onClick={viewVote}>
                                    {t('View')}
                                </Button>}
                            </Grid>
                            }
                        </Grid>
                    </BottomNavigation>
                </Paper>
                {!isRefresh && <EligoReCaptcha captcha={setCaptcha} refresh={setIsRefresh} />}
                {showOTPConfirmation && <ConfirmVoteWithOTP show={showOTPConfirmation} poll_id={poll_id} voter_id={voterDetails.voterId}
                    onClose={setShowOTPConfirmation} onSuccess={onSuccessConfirmation} auth_rep_id={authRepId}
                    voteWithLink={voteWithLink}/>}
            </Paper>
        </Box>
        {message.showMsg && <EligoSnackBar show={message.showMsg} message={message.message}
                    severity={message.severity} />}
        {showSelectVoteOnBehalf && <VoteOnBehalf show={showSelectVoteOnBehalf}
            onSelect={setSelectedVoteOnBehalf} voters={voters} onCancel={cancelVoting}/>}
        {/* {loading && <EligoBackdrop show={true} />} */}
        </div>
    )
}

export default CastVote;