import { Button, Container, Grid, makeStyles, Paper } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import { Stack, Typography } from '@mui/material'
import React, { Fragment, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { PollContext } from '../../common/context/PollContext'
import { UserContext } from '../../common/context/UserContext'
import EligoSnackBar from '../../common/EligoSnackBar'
import { Status } from '../../common/GenericCodes'
import FileUploadDownload from '../file-upload-download/FileUploadDownload'
import './CastVote.css'
import PollTimer from './PollTimer'
import Rules from './Rules'
import StepperView from './StepperView'
import axios from 'axios'

const useStyles = makeStyles((theme) => ({
    card: {
        borderRadius: '0px',
        maxHeight: '600px',
        overflow: 'scroll',
    },
    container: {
        padding: '20px',
        margin: '0px'
    },
    paper: {
        padding: '20px'
    },
    textTitle: {
        textAlign: 'center',
        display: 'grid'
    },
    infoIcon: {
        color: 'rgb(255, 153, 31)',
    },
    btn: {
        boxShadow: '5px 5px 4px rgba(0,0,0,0.3)',
        color: 'blue',
        background: 'white',
        border: '1.8px solid blue'
    },
    cancel: {
        boxShadow: '5px 5px 4px rgba(0,0,0,0.3)',
        color: 'red',
        background: 'white',
        border: '1.5px solid red'
    },
    width: {
        maxWidth: '1500px !important'
    },
    noQuestion: {
        marginTop: '15%'
    },
    details: {
        fontSize: '1.3rem !important',
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        padding: '0px 0px 0px 0rem'
    },
    name: {
        padding: '0.3rem 1rem 0.3rem 1rem'
    },
    organizerPaper: {
        padding: '0.5rem',
        border: '1px solid #eae7e7',
        boxShadow: '#a6adab17 0px 13px 27px - 5px, #ced3d8 0px 8px 16px - 8px !important'

    }

}));

const CastVoteDetails = (props) => {
    let history = new useHistory()
    const { t } = useTranslation();
    const contexts = useContext(UserContext);
    const context = useContext(PollContext);
    const [questions, setQuestions] = useState([])
    const [isTimeExpired, setIsTimeExpired] = useState(false)
    const [loading, setLoading] = useState(false);
    const classes = useStyles();
    const [message, setMessage] = React.useState({
        showMsg: false,
        message: null,
        severity: null
    });
    const [showOrganizers, setShowOrganizer] = useState(false)
    const [isTimerTerminated, setIsTimerTerminated] = useState(false);

    const backToPoll = () => {
        setQuestions([])
        contexts.setUserSession(prevState => ({
            ...prevState,
            color: ''
        })
        )
        history.push({
            pathname: '/polls',
            state: {
                pollType: props.pollType
            },
        })
    }

    const authrepVotersList = () => {
        if (props.onlyVoter) {
            history.push({
                pathname: '/polls',
                state: {
                    pollType: props.pollType
                },
            })
        } else {
            axios({
                url: `${process.env.REACT_APP_AWS_GATEWAY_POLL_VOTER_API_URL}/list-auth-rep-voters/${props.pollDetails.poll_id}/${contexts.userSession.session.userId}`,
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem('token')
                },
            }).then((response) => {
                if (response.data.auth_rep_voter.length === 1) {
                    history.push({
                        pathname: '/polls',
                        state: {
                            pollType: props.pollType
                        },
                    })
                } else if (response.data.auth_rep_voter.length === 0) {
                    history.push({
                        pathname: '/polls',
                        state: {
                            pollType: props.pollType
                        },
                    })
                } else {
                    props.setAuthRepPage(false)
                    history.push({
                        pathname: "/poll/stepper-view",
                        state: {
                            pollDetails: props.pollDetails,
                            page: props.page,
                            roles: props.roles,
                            pollType: props.pollType,
                            AuthRepPage: false,
                            authRepVotersList: response.data.auth_rep_voter,
                        }
                    })
                }
            })
        }

    }

    const backToListAuthRep = () => {
        authrepVotersList()
    }


    const questionStepper = (quest) => {
        props.voteVar(true)
    }

    const reviewVotes = () => {
        props.setReviewVotes(true)
        props.setShowVote(true)
    }

    const reloadPollDetails = () => {
        setIsTimerTerminated(true);
    }

    return (
        <Container maxWidth='lg'>
            {props.pollDetails.number_of_question > 0 ? <Fragment>
                {props.pollDetails.status == Status.closed &&
                    <Stack justifyContent='center' alignItems='center' style={{ padding: '30px' }}>
                        <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold', textAlign: 'center' }}>
                            <span style={{ width: '30%' }}>{props.pollDetails.title}</span>
                        </Typography>
                        <Typography variant="h6" gutterBottom style={{ textAlign: 'center' }}>
                            {props.pollDetails.description}
                        </Typography><br></br>
                        <Typography id='url' variant="caption" display="block" gutterBottom>
                            <div style={{ fontSize: '1.3rem', color: 'red', marginTop: '20%' }}>{t("Poll_Is_Closed")}</div>
                        </Typography>
                        {
                            <Button size="small" className={classes.btn} onClick={() => reviewVotes()} >
                                {t('Review_Votes')}
                            </Button>
                        }
                    </Stack>
                }
                {props.pollDetails.status == Status.canceled &&
                    <Stack justifyContent='center' alignItems='center' style={{ padding: '30px' }}>
                        <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold', textAlign: 'center' }}>
                            <span style={{ width: '30%' }}>{props.pollDetails.title}</span>
                        </Typography>
                        <Typography variant="h6" gutterBottom style={{ textAlign: 'center' }}>
                            {props.pollDetails.description}
                        </Typography><br></br>
                        <Typography id='url' variant="caption" display="block" gutterBottom>
                            <div style={{ fontSize: '1.3rem', color: 'red' }}>{t("Poll_Is_Cancelled")}</div>
                        </Typography>
                    </Stack>
                }
                {props.pollDetails.status == Status.open &&
                    <Stack spacng={1}>
                        <Rules length={props.pollDetails.number_of_question} voterName={props.voterName} showOrganizers={props.pollDetails.display_organizer_name}
                            allow_vote_change={props.pollDetails.allow_vote_change} organizer_list={props.pollDetails.organizer_list}
                            verify_with_otp={props.pollDetails.verify_with_otp} />

                        <Stack direction={{ xs: 'column', sm: 'column', md: 'column', lg: 'row' }}
                            justifyContent='space-between' alignItems='center' spacing={2} width='100%'>
                            {props.pollDetails.allow_vote_change ?
                                <Typography className='info' component='div' sx={{ width: { xs: '100%', lg: 'fit-content' } }}>
                                    <Stack direction='row' spacing={1} alignItems='center'>
                                        <InfoIcon fontSize='small' className={classes.infoIcon} />
                                        <b>{t("You_may_Re-cast_your_Vote_at_any_time_before_the_Poll_ends.")}</b>
                                    </Stack>
                                </Typography> :
                                <Typography className='info' component='div' sx={{ width: { xs: '100%', lg: 'fit-content' } }}>
                                    <Stack direction='row' spacing={1} alignItems='center'>
                                        <InfoIcon fontSize='small' className={classes.infoIcon} />
                                        <b>{t("You_cannot_recast_your_Vote_once_your_Vote_is_confirmed.")}</b>
                                    </Stack>
                                </Typography>
                            }
                            <Stack direction={{ xs: 'row' }} height='fit-content'>
                                <Button size="small" className={classes.cancel} onClick={() => authrepVotersList()} style={{ marginRight: '20px' }}>
                                    {props.pollDetails.status == Status.open ? t('Home') : t('Close')}
                                </Button>
                                {!isTimeExpired && props.pollDetails.status == Status.open &&
                                    !props.authRepVerified && (!props.pollDetails.verified) &&
                                    <Button size="small" className='buttons' onClick={() => questionStepper()} style={{ marginRight: '20px' }}>
                                        <span> </span>
                                        <span> </span>
                                        <span> </span>
                                        <span> </span>
                                        {t('Vote')}
                                    </Button>
                                }
                                {!isTimeExpired && props.pollDetails.status == Status.open &&
                                    (props.pollDetails.verified || (props.authRepVerified && props.votedAuthRep)) && props.pollDetails.allow_vote_change &&
                                    <Button size="small" className='buttons' style={{ marginRight: '20px' }} onClick={() => questionStepper()} >
                                        <span> </span>
                                        <span> </span>
                                        <span> </span>
                                        <span> </span>
                                        {t('Recast')}
                                    </Button>
                                }
                                {(props.pollDetails.status == Status.closed || (props.pollDetails.verified || props.pollDetails.voted) || props.votedAuthRep) &&
                                    <Button size="small" className={classes.btn} onClick={() => reviewVotes()} >
                                        {t('Review_Votes')}
                                    </Button>
                                }
                            </Stack>
                        </Stack>
                        <Grid container style={{ marginTop: '10px' }} justify='center' alignItems='center'>
                            <Grid item xs={12}>
                                {<FileUploadDownload
                                    t={t}
                                    poll_id={props.pollDetails.poll_id}
                                    poll_question_id={null}
                                    isUpload={false}
                                    isDownload={true}
                                    deletePermission={false}
                                    otpLogin={props.otpLogin}
                                    userId={props.userId}
                                />}
                            </Grid>

                        </Grid>

                    </Stack>
                }
                {props.pollDetails.status == Status.scheduled &&
                    <Grid container justifycontent="center" alignItems="center" style={{ display: 'flex', flexDirection: 'column', marginTop: '5%' }}>
                        {!isTimerTerminated && <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                <span style={{ width: '30%' }}>{props.pollDetails.title}</span>
                            </Typography>
                            <Typography variant="h6" gutterBottom style={{ textAlign: 'center' }}>
                                {props.pollDetails.description}
                            </Typography>
                            <PollTimer date={props.pollDetails.start_date_time}
                                terminated={reloadPollDetails} title={'Poll Starts In'} />
                        </Grid>
                        }
                        {isTimerTerminated && <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <Typography id='url' variant="caption" display="block" gutterBottom>
                                <div style={{ fontSize: '1.0rem' }}>{t('Please_Refresh_The_Page_To_Continue')}</div>
                                <br />
                                <Button variant="contained" size="small" color="primary" onClick={() => window.location.replace(`${props.origin}${props.path}`)}>
                                    Refresh
                                </Button>
                            </Typography>
                        </Grid>
                        }
                    </Grid>
                }

            </Fragment> :
                <div>
                    {
                        props.pollDetails.status == Status.scheduled ?
                            <Grid container justifycontent="center" alignItems="center" style={{ display: 'flex', flexDirection: 'column', marginTop: '5%' }}>
                                {!isTimerTerminated && <Grid item xs={12} style={{ textAlign: 'center' }}>

                                    <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                        <span style={{ width: '30%' }}>{props.pollDetails.title}</span>
                                    </Typography>
                                    <Typography variant="h6" gutterBottom style={{ textAlign: 'center' }}>
                                        {props.pollDetails.description}
                                    </Typography>
                                    <PollTimer date={props.pollDetails.start_date_time}
                                        terminated={reloadPollDetails} title={'Poll Starts In'} />
                                </Grid>
                                }
                                {isTimerTerminated && <Grid item xs={12} style={{ textAlign: 'center' }}>
                                    <Typography id='url' variant="caption" display="block" gutterBottom>
                                        <br />
                                        <Stack justifyContent='center' alignItems='center'>
                                            <Stack className={classes.noQuestion} justifyContent='center' alignItems='center'>
                                                <Typography className='info' component='Stack' style={{ fontSize: '20px' }}>
                                                    <b>{t("There_are_no_Matters_added_to_the_Poll...")}</b>
                                                </Typography><br></br>
                                                {props.pollDetails.display_organizer_name &&
                                                    <div>
                                                        <Typography className='org' component='Stack' sx={{ width: { xs: '100%', lg: 'fit-content' } }}>
                                                            <b>{t("Please_contact_Organizer_for_further_details_regarding_the_Poll")}</b>
                                                        </Typography><br></br><br />

                                                        {
                                                            props.pollDetails.organizer_list.length > 0 &&
                                                            <Paper className={classes.organizerPaper}>
                                                                <Typography className={classes.details}>
                                                                    {t('Oragnizer_Details')}
                                                                </Typography>
                                                                <Stack direction='row' p={1} spacing={5}>
                                                                    <Typography>
                                                                        <b>{t('Name')}:</b>
                                                                    </Typography>
                                                                    <Typography>
                                                                        {props.pollDetails.organizer_list[0].name}
                                                                    </Typography>
                                                                </Stack>
                                                                <Stack direction='row' p={1} spacing={5}>
                                                                    <Typography>
                                                                        <b>{t('Email')}:</b>
                                                                    </Typography>
                                                                    <Typography>
                                                                        {props.pollDetails.organizer_list[0].email}
                                                                    </Typography>
                                                                </Stack>
                                                                <Stack direction='row' p={1} spacing={1}>
                                                                    <Typography>
                                                                        <b>{t('Phone No')}:</b>
                                                                    </Typography>
                                                                    <Typography>
                                                                        {props.pollDetails.organizer_list[0].phone_number}
                                                                    </Typography>
                                                                </Stack>
                                                            </Paper>
                                                        }
                                                    </div>
                                                }
                                            </Stack>
                                        </Stack>
                                    </Typography>
                                </Grid>
                                }
                            </Grid> : <Stack justifyContent='center' alignItems='center'>
                                <Stack className={classes.noQuestion} justifyContent='center' alignItems='center'>
                                    <Typography className='info' component='Stack' style={{ fontSize: '20px' }}>
                                        <b>{t("There_are_no_Matters_added_to_the_Poll...")}</b>
                                    </Typography><br></br>
                                    {props.pollDetails.display_organizer_name &&
                                        <div>
                                            <Typography className='org' component='Stack' sx={{ width: { xs: '100%', lg: 'fit-content' } }}>
                                                <b>{t("Please_contact_Organizer_for_further_details_regarding_the_Poll")}</b>
                                            </Typography><br></br><br />
                                            {
                                                props.pollDetails.organizer_list.length > 0 &&
                                                <Paper className={classes.organizerPaper}>
                                                    <Typography className={classes.details}>
                                                        {t('Oragnizer_Details')}
                                                    </Typography>
                                                    <Stack direction='row' p={1} spacing={5}>
                                                        <Typography>
                                                            <b>{t('Name')}:</b>
                                                        </Typography>
                                                        <Typography>
                                                            {props.pollDetails.organizer_list[0].name}
                                                        </Typography>
                                                    </Stack>
                                                    <Stack direction='row' p={1} spacing={5}>
                                                        <Typography>
                                                            <b>{t('Email')}:</b>
                                                        </Typography>
                                                        <Typography>
                                                            {props.pollDetails.organizer_list[0].email}
                                                        </Typography>
                                                    </Stack>
                                                    <Stack direction='row' p={1} spacing={1}>
                                                        <Typography>
                                                            <b>{t('Phone No')}:</b>
                                                        </Typography>
                                                        <Typography>
                                                            {props.pollDetails.organizer_list[0].phone_number}
                                                        </Typography>
                                                    </Stack>
                                                </Paper>}
                                        </div>
                                    }<br></br>
                                    {!props.otpLogin && <Button size="small" className={classes.cancel} onClick={() => backToPoll()}>Back To polls</Button>}

                                </Stack>
                            </Stack>
                    }
                </div>

            }
            {
                message.showMsg && <EligoSnackBar show={message.showMsg} message={message.message}
                    severity={message.severity} />
            }
        </Container >
    )
}

export default CastVoteDetails;