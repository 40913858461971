import React, {
    useState
}                                   from 'react';
import { useTranslation }           from 'react-i18next';
import { 
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    TextField,
    Tooltip,
    InputAdornment,
    Button,
    DialogActions
}                                   from '@material-ui/core';
import EligoBackdrop                from '../../../common/EligoBackdrop';
import LoopIcon                     from '@material-ui/icons/Loop';
import InputMask                    from 'react-input-mask';
import { AxiosEvotingInterceptor, PublicAxiosInterceptor } from '../../../config/axios.interceptor';
import EligoSnackBar            from '../../../common/EligoSnackBar';
import EligoReCaptcha           from '../../../common/EligoReCaptcha';


const ConfirmVoteWithOTP = ({show, onClose, poll_id, voter_id, onSuccess, auth_rep_id, voteWithLink}) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(show);
    const [loading, setLoading] = useState(false);
    const [otpVal, setOtpVal] = useState('');
    const [captcha, setCaptcha] = useState('');
    const [isRefresh, setIsRefresh] = useState(false);
    const [message, setMessage] = React.useState({
        showMsg: false,
        message: null,
        severity: null
    })

    const setOtpValue = (e) => {
        setOtpVal(e.target.value)
    }

    const getOpt = () => {
        if (voteWithLink) {
            requestOTPForNonLoginUser();
        } else {
            requestOTPForLoginUser();            
        }
    }

    const requestOTPForLoginUser = () => {
        setLoading(true);
        setMessage({ showMsg: false, message: '', severity: '' });
        if (auth_rep_id) {
            AxiosEvotingInterceptor.get(`vote/${poll_id}/request-otp-verify/${voter_id}/${auth_rep_id}?recaptcha=${captcha}`)
            .then(response => {
                setLoading(false);
            })
            .catch(error => {
                setIsRefresh(true);
                setTimeout(() => {
                    setIsRefresh(false);
                }, 1000);
                if (error.message) {
                    setMessage({ showMsg: true, message: error.message, severity: 'error' });
                }
                setLoading(false);
            })
        } else {
            AxiosEvotingInterceptor.get(`vote/${poll_id}/request-otp-verify/${voter_id}?recaptcha=${captcha}`)
            .then(response => {
                setLoading(false);
            })
            .catch(error => {
                setIsRefresh(true);
                setTimeout(() => {
                    setIsRefresh(false);
                }, 1000);
                if (error.message) {
                    setMessage({ showMsg: true, message: error.message, severity: 'error' });
                }
                setLoading(false);
            })
        }
    }

    const requestOTPForNonLoginUser = () => {
        setLoading(true);
        setMessage({ showMsg: false, message: '', severity: '' });
        if (auth_rep_id) {
            PublicAxiosInterceptor.get(`vote/${poll_id}/request-otp-verify/${voter_id}/${auth_rep_id}?recaptcha=${captcha}`)
            .then(response => {
                setLoading(false);
            })
            .catch(error => {
                setIsRefresh(true);
                setTimeout(() => {
                    setIsRefresh(false);
                }, 1000);
                if (error.message) {
                    setMessage({ showMsg: true, message: error.message, severity: 'error' });
                }
                setLoading(false);
            })
        } else {
            PublicAxiosInterceptor.get(`vote/${poll_id}/request-otp-verify/${voter_id}?recaptcha=${captcha}`)
            .then(response => {
                setLoading(false);
            })
            .catch(error => {
                setIsRefresh(true);
                setTimeout(() => {
                    setIsRefresh(false);
                }, 1000);
                if (error.message) {
                    setMessage({ showMsg: true, message: error.message, severity: 'error' });
                }
                setLoading(false);
            })
        }
    }

    const confirm = () => {
        let code = otpVal.replaceAll(' ', '');
        setMessage({ showMsg: false, message: '', severity: '' });
        setTimeout(() => {
            if(code != null && code != '') {
                let payload ={
                otp: code
                }
                if(voteWithLink) {
                    confirmVoteWithoutLogin(payload);
                } else {
                    confirmVoteWithLogin(payload);
                }
            } else {
                setMessage({ showMsg: true, message: t('Please_Enter_OTP'), severity: 'error' });
            }
        }, 200);
    }

    const confirmVoteWithLogin = (payload) => {
        setLoading(true);
        setMessage({ showMsg: false, message: '', severity: '' });
        if (auth_rep_id) {
            AxiosEvotingInterceptor.put(`vote/${poll_id}/verify-vote/${voter_id}/${auth_rep_id}`, payload)
                .then(response => {
                    if (response) {
                        onSuccess();
                    }
                    setLoading(false);
                })
                .catch(error => {
                    if (error.message) {
                        setMessage({ showMsg: true, message: error.message, severity: 'error' });
                    }
                    setLoading(false);
                })
        } else {
            AxiosEvotingInterceptor.put(`vote/${poll_id}/verify-vote/${voter_id}`, payload)
                .then(response => {
                    if (response) {
                        onSuccess();
                    }
                    setLoading(false);
                })
                .catch(error => {
                    if (error.message) {
                        setMessage({ showMsg: true, message: error.message, severity: 'error' });
                    }
                    setLoading(false);
                })
        }
    }

    const confirmVoteWithoutLogin = (payload) => {
        setLoading(true);
        setMessage({ showMsg: false, message: '', severity: '' });
        if (auth_rep_id) {
            PublicAxiosInterceptor.put(`vote/${poll_id}/verify-vote/${voter_id}/${auth_rep_id}`, payload)
                .then(response => {
                    if (response) {
                        onSuccess();
                    }
                    setLoading(false);
                })
                .catch(error => {
                    if (error.message) {
                        setMessage({ showMsg: true, message: error.message, severity: 'error' });
                    }
                    setLoading(false);
                })
        } else {
            PublicAxiosInterceptor.put(`vote/${poll_id}/verify-vote/${voter_id}`, payload)
                .then(response => {
                    if (response) {
                        onSuccess();
                    }
                    setLoading(false);
                })
                .catch(error => {
                    if (error.message) {
                        setMessage({ showMsg: true, message: error.message, severity: 'error' });
                    }
                    setLoading(false);
                })
        }
    }

    const cancelConfirmation = () => {
        onClose(false);
    }

    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="confirm-vote-with-otp-dialog-title"
                aria-describedby="confirm-vote-with-otp-dialog-description"
                className='loading-container'
            >
                <DialogTitle id="confirm-vote-with-otp-dialog-title">{t('OTP_Confirmation')}</DialogTitle>
                <DialogContent>
                    <Typography variant="body2" component="p" className="text-center">
                        <strong>{t('Please_Enter_The_OTP_To_Confirm')}</strong><br />
                        {t('A_OTP_Has_Been_Sent_To_Your_Registered_Mobile_Number_And_Email_ID')}
                    </Typography>
                    <br />
                    <Typography className="text-center">
                        <InputMask
                            mask="9 9 9 9 9"
                            value={otpVal}
                            onChange={setOtpValue}>
                            {() => <TextField
                                id='otp'
                                margin="normal"
                                variant="outlined"
                                placeholder={t("OTP")}
                                inputProps={{ style: { textAlign: 'center', fontSize: '18px', fontWeight: 'bold' } }}
                                InputProps={{
                                    style: { textAlign: 'center', fontSize: '18px', fontWeight: 'bold' },
                                    endAdornment: (
                                        <Tooltip title="Resend">
                                            <InputAdornment position="end" className="pointer">
                                                <LoopIcon
                                                    aria-label="resend-otp"
                                                    style={{ color: 'blue' }}
                                                    onClick={getOpt}
                                                >
                                                </LoopIcon>
                                            </InputAdornment>
                                        </Tooltip>
                                    )
                                }}
                            />}
                        </InputMask>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={cancelConfirmation} color="primary" autoFocus>
                        {t("Cancel")}
                    </Button>
                    <Button onClick={confirm} color="primary">
                        {t("Confirm")}
                    </Button>
                </DialogActions>
                {loading && <EligoBackdrop show={true} />}
            </Dialog>
            {!isRefresh && <EligoReCaptcha captcha={setCaptcha} refresh={setIsRefresh} />}
            {message.showMsg && <EligoSnackBar show={message.showMsg} message={message.message}
                    severity={message.severity} />}
        </div>
    )
}

export default ConfirmVoteWithOTP;