import {
    Breadcrumbs, Button,
    IconButton, Link, makeStyles, Typography
} from '@material-ui/core';
import { Business, Delete, Edit, Person } from '@material-ui/icons';
import {
    Avatar, BottomNavigation, Chip, Paper, Stack, Table,
    TableBody, TableCell, TableContainer, TableHead,
    TableRow, Tooltip
} from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import EligoPagination from '../../common/pagination/EligoPagination';
import { AxiosEvotingInterceptor } from '../../config/axios.interceptor';
import ClientDetails from './client-details/ClientDetails';
import { IdentificationTypes } from './ClientStaticVar';
import EligoBackdrop from '../../common/EligoBackdrop';
import EligoSnackBar from '../../common/EligoSnackBar';
import EligoConfirmDialog from '../../common/EligoConfirmDialog';

const useStyles = makeStyles((theme) => ({
    paper: {
        color: "rgb(26, 32, 39)",
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        borderRadius: '10px',
        border: "1px solid rgb(238, 238, 238)",
        backgroundImage: "none",
        backgroundColor: "rgb(255, 255, 255)",
        display: "block",
        width: "100%",
        overflowX: "auto",
        fontFamily: `Roboto,"Helvetica Neue",Arial,sans-serif,
            "Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol" !important`
    },
    tableCell: {
        borderBottom: "1px solid rgb(231, 235, 240) !important",
        padding: "10px 20px !important"
    },
    tableHead: {
        fontSize: '1rem',
        fontWeight: "bold !important"
    },
    tableData: {
        borderColor: "rgb(231, 235, 240) !important",
        color: "rgb(62, 80, 96) !important"
    },
    tableNoData: {
        padding: '20px !important', textAlign: 'left !important'
    },
    tableRow: {
        '&:hover': {
            background: 'rgb(129 163 186 / 4%) !important'
        }
    },
    addBtn: {
        position: 'absolute',
        right: '32px',
        marginTop: '20px !important',
        float: 'right', width: 'fit-content'
    },
    clientAvatar: {
        background: '#cdf0f4 !important',
        color: '#027583 !important',
        width: '30px !important',
        height: '30px !important',
        fontSize: '1rem !important',
        marginRight: '8px',
        boxShadow: '0px 1px 10px -1px rgb(0 0 0 / 10%), 0 0 0 -8px rgb(0 0 0 / 4%) !important'
    },
    ind: {
        background: '#e1f1e0 !important',
        color: '#098a10 !important',
        '& #indicon': {
            color: '#098a10 !important',
        }
    },
    org: {
        background: '#e4eef7 !important',
        color: '#1976d2 !important',
        '& #orgicon': {
            color: '#1976d2 !important',
        }
    },
    toolTipId: {
        textTransform: 'uppercase'
    }
}));

export const getIdentificationByType = (identifications, identificationType) => {
    let identification = { id: null, identification: '', identificationType: identificationType }
    identifications?.map((i) => {
        if (i.identificationType === identificationType)
            identification = {
                id: i.id,
                identification: i.identification,
                identificationType: i.identificationType,
            }
    })
    return identification;
}

export const getEmail = (email) => {
    if (email === null || email.length === 0 || email.length === undefined) {
        return {
            id: null,
            email: '',
            previousEmail: ''
        }
    } else {
        let clientEmail = null;
        email.map(element => {
            clientEmail = {
                id: element.id,
                email: element.email,
                previousEmail: ''
            }
        });
        return clientEmail;
    }
}

export const getPhoneNumber = (phoneNumber) => {
    if (phoneNumber === null || phoneNumber.length === undefined || phoneNumber.length === 0) {
        return {
            id: null,
            phoneNumber: '',
            previousPhoneNumber: ''
        }
    } else {
        let clientPhoneNumber = null;
        phoneNumber.map(element => {
            clientPhoneNumber = {
                id: element.id,
                phoneNumber: element.phoneNumber,
                previousPhoneNumber: ''
            }
        });
        return clientPhoneNumber;
    }
}

const Clients = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const classes = useStyles();
    const [showClientDetails, setShowClientDetails]= useState({
        show: false,
        isNew: false
    })

    const [clients, setClients] = useState([])
    const [totalClients, setTotalClient] = useState(0)
    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [count, setCount] = useState(0);
    const [openPopup, setOpenPopup] = useState(false);
    const [isNew, setIsNew] = useState(false);
    const [editRowData, setEditRowData] = useState();
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({
        showMsg: false,
        message: '',
        severity: ''
    })

    const columns = [
        // { label: "Id", id: "id", hidden: true },
        { name: t('Client_Name'), width: '20%', id: "name" },
        { name: t('Client_Person_Type'), width: '20%', id: "personType" },
        { name: t('Identification'), width: '20%', id: "identificationType" },
        { name: t('Address'), width: '30%', id: "address" }
    ]

    const [seekConfirmation, setSeekConfirmation] = useState({
        show: false,
        title: '',
        message: '',
        onAgree: '',
        onDisAgree: '',
        isCancel: true
    })

    const listClient = () => {
        setLoading(true);
        AxiosEvotingInterceptor.get(`client/list?page=${page}&size=${rowsPerPage}&asc=false`).then(response => {
            if (response) {
                setClients([])
                setErrorMessage('');
                setCount(response.count);
                response.client.map((client) => {
                    setClients(prevState => ([
                        ...prevState,
                        {
                            name: client.name + (client.restOfName === null ? '' : ` ${client.restOfName}`),
                            individual: client.individual,
                            personId: client.personId,
                            loginUser: client.loginUser,
                            address: client.address?.map((address) => {
                                return {
                                    addressLine1: address.addressLine1,
                                    addressLine2: address.addressLine2,
                                    addressType: address.addressType,
                                    attention: address.attention,
                                    phone: address.phone,
                                    country: address.country,
                                    addressId: address.addressId,
                                    landmark: address.landmark,
                                    state: address.state,
                                    cityTown: address.cityTown,
                                    postalCode: address.postalCode,
                                }
                            }),
                            identificationGst: getIdentificationByType(client.identification, IdentificationTypes.gstin),
                            identificationPan: getIdentificationByType(client.identification, IdentificationTypes.pan),
                            email: getEmail(client.email),
                            phoneNumber: getPhoneNumber(client.phoneNumber)
                        }
                    ]))
                })
            }
            setLoading(false);
        }).catch(error => {
            if (error.message) {
                setErrorMessage(error.message);
            } else {
                setErrorMessage(t('Try_Again'));
            }
            setLoading(false);
        })
    }

    const deleteClient = (clientId) => {
        setMessage({ showMsg: false, message: '', severity: '' })
        setLoading(true);
        setErrorMessage('');
        AxiosEvotingInterceptor.delete(`client/delete/${clientId}`).then(response => {
            if (response) {
                resetConfirmation();
                setMessage({ showMsg: true, message: t('Deleted_Client_Successfully'), severity: 'success'});
                if (page !== 1) {
                    let lastPageRows = count % rowsPerPage;
                    let noOfPage = ~~(count / rowsPerPage) + (lastPageRows > 0 ? 1 : 0);
                    if (lastPageRows == 1 && page == noOfPage) {
                        setPage(1);
                    } else {
                        listClient();
                    }
                } else {
                    listClient();
                }
            }
            // setLoading(false);
        }).catch(error => {
            if (error.message) {
                setErrorMessage(error.message);
            } else {
                setErrorMessage(t('Try_Again'));
            }
            setLoading(false);
        })
    }

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    }

    const onRowsPerPageChange = event => {
        setRowsPerPage(event.target.value)
        setPage(1);
    }

    const deleteConfirmation = (client) => {
        setSeekConfirmation({
            show: true,
            title: t("Confirmation"),
            message: `${t('Are_You_Sure_That_You_Want_To_Delete_Client')} - '${client.name}' ?`,
            onAgree: () => deleteClient(client.personId),
            onDisAgree: resetConfirmation,
        })
    }
    const resetConfirmation = () => {
        setSeekConfirmation({
            show: false,
            title: '',
            message: '',
            onAgree: '',
            onDisAgree: '',
        })
    }

    useEffect(() => {
        listClient();
    }, [page, rowsPerPage])

    const addClient = () => {
        setShowClientDetails({
            show: true,
            isNew: true,
            ClientDetails: {
                name: '',
                personId: '',
                individual: true,
                loginUser: false,
                identificationGst: {
                    id: null,
                    identification: '',
                    identificationType: IdentificationTypes.gstin,
                    previousIdentification: ''
                },
                identificationPan: {
                    id: null,
                    identification: '',
                    identificationType: IdentificationTypes.pan,
                    previousIdentification: ''
                },
                email: {
                    id: null,
                    email: '',
                },
                phoneNumber: {
                    id: null,
                    phoneNumber: '',
                },
                address: {
                    addressLine1: '',
                    addressLine2: '',
                    addressType: 'billing',
                    attention: '',
                    cityTown: '',
                    country: '',
                    addressId: '',
                    landmark: '',
                    phone: '',
                    postalCode: '',
                    state: '',
                }
            }
        })
    }

    const editClient = (Client) => {
        setShowClientDetails({
            show: true,
            isNew: false,
            ClientDetails: Client
        })
    }

    const resetShowClientDetails = () => {
        setShowClientDetails({
            show: false,
            isNew: false
        })
        listClient();
    }

    const handleCancel = () => {
        setShowClientDetails({
            show: false,
            isNew: false
        })
    }

    const redirectToHome = () => {
        history.push("/polls");
    }

    return (
        <Stack mx={2} mt={1} sx={{ px: 4 }}>
            <Breadcrumbs aria-label="Client-breadcrumb">
                <Link id='home' underline="hover"
                    color="primary"
                    style={{cursor: 'pointer', fontSize: '0.875rem'}}
                    onClick={redirectToHome}
                >
                    {t('Home')}
                </Link>
                <Typography id='myClient'color="text.primary" variant='subtitle2'>{t('My_Clients')}</Typography>
            </Breadcrumbs>
            <Stack spacing={1} sx={{ py: 1 }}>
                <Stack direction="row" justifyContent='space-between' alignItems='baseline'>
                    <Typography variant='h6'>{t('My_Clients')}</Typography>
                    <Button color="primary" variant="contained" onClick={addClient} size='small'>
                        {t('Add_Client')}
                    </Button>
                </Stack>
                <TableContainer component={Paper} className={classes.paper}>
                    <Table aria-label="client table" stickyHeader id="list-of-client">
                        <TableHead>
                            <TableRow>
                                {columns.map(column => {
                                    return (
                                        <TableCell key={column.name} className={`${classes.tableCell} ${classes.tableHead}`}
                                            id={column.name} width={column.width}>
                                            {column.name}
                                        </TableCell>
                                    )
                                })}
                                <TableCell id="action-column" className={`${classes.tableCell} ${classes.tableHead}`} align="center" width='10%'>{t("Actions")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {count > 0 &&
                                clients.map((client, index) =>
                                (<TableRow key={client.personId} id={`client-${index}`} className={classes.tableRow}>
                                    <TableCell className={`${classes.tableCell} ${classes.tableData}`}>
                                        <Stack direction='row' alignItems='center'>
                                            <Avatar className={classes.clientAvatar}>
                                                {client.name.substr(0, 1).toUpperCase()}
                                            </Avatar>
                                            <Fragment>{client.name}</Fragment>
                                        </Stack>
                                    </TableCell>
                                    {!client.individual &&
                                        <TableCell className={`${classes.tableCell} ${classes.tableData}`}>
                                            <Chip icon={<Business id='orgicon' />} label={t('Institution')} size='small' className={classes.org} sx={{ pl: 0.6 }} />
                                        </TableCell>
                                    }
                                    {client.individual &&
                                        <TableCell className={`${classes.tableCell} ${classes.tableData}`}>
                                            <Chip icon={<Person id='indicon' />} label={t('Individual')} size='small' className={classes.ind} sx={{ pl: 0.6 }} />
                                        </TableCell>
                                    }
                                    <TableCell className={`${classes.tableCell} ${classes.tableData}`}>
                                        {(client.identificationPan.identification !== '') ?
                                            <Tooltip title={client.identificationPan?.identificationType} arrow className={classes.toolTipId} placement='bottom-start'>
                                                <Typography variant='subtitle2' sx={{ width: 'fit-content' }}>{client.identificationPan.identification}</Typography>
                                            </Tooltip> :
                                            <Tooltip title={client.identificationGst?.identificationType} arrow className={classes.toolTipId} placement='bottom-start'>
                                                <Typography variant='subtitle2' sx={{ width: 'fit-content' }}>{client.identificationGst.identification}</Typography>
                                            </Tooltip>
                                        }
                                    </TableCell>
                                    <TableCell className={`${classes.tableCell} ${classes.tableData}`} align='left'>
                                        {client.address?.length > 0 && <div>
                                            {client.address[0]?.addressLine1} &nbsp;
                                            {client.address[0]?.addressLine2 !== '' && <Fragment>{client.address[0]?.addressLine2} &nbsp;</Fragment>}
                                            {client.address[0]?.cityTown} &nbsp;
                                            {client.address[0]?.state} &nbsp;
                                            {client.address[0]?.country} &nbsp;
                                            {client.address[0]?.postalCode}
                                        </div>}</TableCell>
                                    <TableCell className={`${classes.tableCell} ${classes.tableData}`} align='center'>
                                        <IconButton onClick={() => { editClient(client) }} size="small">
                                            <Edit color="primary" />
                                        </IconButton>
                                        <IconButton onClick={() => { deleteConfirmation(client) }} size="small">
                                            <Delete color="error" />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>)
                                )
                            }
                            {clients.length === 0 &&
                                <TableRow hover>
                                    <TableCell colSpan={5} className={`${classes.tableCell} ${classes.tableData} ${classes.tableNoData}`} id="no-clients-row">
                                        {t('No_Record_Found')}</TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                    {(count > 5) &&
                        <BottomNavigation style={{ padding: '8px 12px', height: 'auto !important' }}>
                            <EligoPagination rowsPerPage={rowsPerPage} page={page} totalItems={count}
                                onRowsPerPageChange={onRowsPerPageChange} onPageChange={handlePageChange} />
                        </BottomNavigation>
                    }
                </TableContainer>
            </Stack>
            {showClientDetails.show && <ClientDetails open={showClientDetails.show} handleClose={resetShowClientDetails} setListMessage={setMessage}
                isNew={showClientDetails.isNew} client={showClientDetails.ClientDetails} listClient={listClient} handleCancel={handleCancel}/> }
            {seekConfirmation.show && <EligoConfirmDialog show={seekConfirmation.show}
                title={seekConfirmation.title} message={seekConfirmation.message}
                onAgree={seekConfirmation.onAgree} onDisAgree={seekConfirmation.onDisAgree} />}
            {(loading) && <EligoBackdrop show={(loading)} invisible={false} />}
            {message.showMsg && <EligoSnackBar show={message.showMsg} message={message.message}
                severity={message.severity} handleResetOnClose={() => {}} />}
            {errorMessage != '' && <EligoSnackBar show={errorMessage != ''} message={errorMessage}
                severity='error'/>}


        </Stack>
    )
}

export default Clients;