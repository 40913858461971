import { Box, Button, Divider, Grid, IconButton, List, ListItem, ListItemText, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/RefreshOutlined';
import { Fab, Link, Popover, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import axios from 'axios';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../common/context/UserContext';
import EligoBackdrop from '../../../common/EligoBackdrop';
import EligoSnackBar from '../../../common/EligoSnackBar';
import { Status, TypeOfPoll, UserRoles } from '../../../common/GenericCodes';
import GraphQLErrors from '../../../common/GraphQLErrors';
import EligoPagination from '../../../common/pagination/EligoPagination';
import { AxiosEvotingUploadInterceptor, AxiosEvotingInterceptor } from '../../../config/axios.interceptor';
import { ColoredHeader } from '../../file-upload-download/styles/FileStyle';
import { EnhancedTableHead, EnhancedTableToolbar } from '../MultiDeleteTable';
import PollRoundVoterRow from '../poll-round-voter-row/PollRoundVoterRow';
import SearchVoterList from '../search-voter-list/SearchVoterList';
import BulkUploadFile from './BulkUploadFile';
import SingleVoter from './SingleVoter';
import FieldHelperText from '../../../common/FieldHelperText';
import VoterAdd from './VoterAdd';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import SendIcon from '@material-ui/icons/NotificationsActive';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import PublishRoundedIcon from '@material-ui/icons/PublishRounded';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import "./AuthRep.css"
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { RefreshRounded } from "@material-ui/icons";
import EligoConfirmDialog from '../../../common/EligoConfirmDialog';

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        // maxHeight: 550,
    },
    paper: { width: '30%' },
    search: {
        padding: '10px',
        fontSize: '20px',
    }
});

const getAuthRepArray = (responseArray) => {
    let response = [];
    responseArray.map(e => {
        response.push({
            option: e.option,
            poll_question_response_id: e.poll_question_response_id
        })
    })
    return response;
}

const AddPollRoundVoter = (props) => {
    const { t } = useTranslation();
    const [pollId] = useState(props.pollId);
    const [page, setPage] = useState(1);
    const context = useContext(UserContext)
    const [itemsPerPage, setItemPerPage] = useState(5);
    const [pollRoundVoters, setPollRoundVoters] = useState([]);
    const [totalNumberOfVoters, setTotalNumberOfVoters] = useState(0);
    const [isAddNew, setIsAddNew] = useState(false);
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [isAllVotersVoted, setIsAllVotersVoted] = useState(false);
    const [openAuth, setOpenAuth] = useState(false)
    const [editSingleDetails, setEditSingleDetails] = useState({})
    const [Edit, setEdit] = useState(false)
    const [listBy, setListBy] = useState(props.listBy ? props.listBy : 'all');
    const [value, setValue] = useState('name')
    const [anchorEl, setAnchorEl] = useState(null);
    const [sorted, setSorted] = useState(false);
    let interval;

    const [message, setMessage] = useState({
        showMsg: false,
        message: null,
        severity: null
    });
    const [selected, setSelected] = useState([]);
    const [overAllWeightage, setOverAllWeightage] = useState(0);
    const [openAddVoter, setOpenAddVoter] = useState(false)
    const [seekConfirmation, setSeekConfirmation] = useState({
        show: false,
        title: t("Confirmation"),
        message: '',
        onAgree: '',
        onDisAgree: ''
    })

    const columns = [
        { id: 'name', label: props.pollClass === TypeOfPoll.POLL ? t('Voter_Name_and_Email') : t('Participant_Name_and_Email'), 
            minWidth: 30, show: true },
        { id: 'phone_number', label: t("Phone_Number"), minWidth: 17, show: true },
        { id: 'weightage', label: t('Weightage'), minWidth: 12, show: props.pollClass === TypeOfPoll.POLL },
        { id: 'action', label: ((props.pollStatus != Status.canceled) ||  props.hasSystemAdmineOrSupportRole) && t('Actions'), 
            minWidth: 13, show: true }
    ];

    const handleClose = () => {
    setAnchorEl(null);
    };

    const openPopover = Boolean(anchorEl);
    const id = openPopover ? 'user-options-popover' : undefined;

    const handleClickSortBy = (event) => {
        setAnchorEl(event.currentTarget);
    };

    useEffect(() => {
        listVoters()
    }, [page, itemsPerPage]);

    const refresh = () => {
        listVoters()
        setIsAddNew(false);
    }

    const handleAddVoters = () => {
        setOpenAuth(true)
        setOpenAddVoter(true)
    }

    const handleCancelVoter = (arrayIndex) => {
        var voters = [...pollRoundVoters];
        if (arrayIndex !== -1) {
            voters.splice(arrayIndex, 1);
            setPollRoundVoters(voters);
            setIsAddNew(false);
            setTotalNumberOfVoters(totalNumberOfVoters - 1);
        }
    }

    const handleChangePage = (event, newPage) => {
        setSelected([])
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setItemPerPage(parseInt(event.target.value, 10));
        setSelected([])
        setPage(1);
    };

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const sendRemainder = () => {
        setLoading(true)
        props.setMessage({ showMsg: false, message: "", severity: "" })
        AxiosEvotingInterceptor.get(`/voter/remainder/${pollId}?voterId=${''}`).then((response) => {
            if (response) {
                props.setMessage({ showMsg: true, message: t("Reminder_Sent_Successfully"), severity: 'success' })
                setLoading(false)
                listVoters()
            }
            resetConfirmation()
        }).catch((error) => {
            props.setMessage({ showMsg: true, message: error.message, severity: 'error' })
        })
    }

    const setSelectedVoter = (selectedVoter, touched) => {
        setPollRoundVoters([]);
        if (selectedVoter == '' && touched) {
            setSelected([])
            setIsAddNew(false);
            listVoters()
        } else if (selectedVoter != '') {
            setTimeout(function () {
                setPollRoundVoters([{
                    email: selectedVoter.email,
                    name: selectedVoter.name,
                    phoneNumber: selectedVoter.phoneNumber,
                    weightageString: selectedVoter.weightage,
                    voterId: selectedVoter.voterId,
                    authorizedRepresentative: selectedVoter.authorizedRepresentative,
                    cognito_user: selectedVoter.cognito_user,
                    otpVerifiedOn: selectedVoter.otpVerifiedOn,
                    reminderCount: selectedVoter.reminderCount,
                    previousState: {},
                    isNew: false,
                    isEdit: false
                }])
            }, 200);
            setPage(page);
            setTotalNumberOfVoters(1);
            setOverAllWeightage(selectedVoter.weightage)
        }
    }

    const listVoters = (deletedValue, newValue) => {
        let sortByValue = newValue ? newValue : value;
        setLoading(true)
        setPollRoundVoters([])
        setMessage({ showMsg: false, message: '', severity: '' });
        AxiosEvotingInterceptor.get(`voter/list/${pollId}/${listBy}?page=${page}&limit=${itemsPerPage}&asc=${sortByValue === 'name' ? true : false}&sortBy=${sortByValue}`).then(response => {
            setOverAllWeightage(response.weightageString)
            setTotalNumberOfVoters(response.count)
            props.setNoOfVoters(response.totalCount)
            setPollRoundVoters(response.voter);
            if (deletedValue === 'AuthrepDelete') {
                setMessage({ showMsg: true, message: t('AuthRep_Deleted_Successfully'), severity: 'success' });
            } else if (deletedValue > 0) {
                setMessage({ showMsg: true, message: props.pollClass === TypeOfPoll.POLL ? t('Voter_Added_Successfully') : t('Participant_Added_Successfully'), severity: 'success' });
            } else if (deletedValue === 'voterDelete') {
                setMessage({ showMsg: true, message: props.pollClass === TypeOfPoll.POLL ? t('Voter_Deleted_Successfully') : t('Participant_Deleted_Successfully'), severity: 'success' });
                if (page != 1) {
                    setPage(1)
                }
            } else if (deletedValue === 'editVoter') {
                setMessage({ showMsg: true, message: props.pollClass === TypeOfPoll.POLL ? t('Voter Updated Successfully') : t('Participant_Updated_Successfully'), severity: 'success' });
            } else if (deletedValue === 'addAuthrepForExistingVoter') {
                setMessage({ showMsg: true, message: t('Authorized_Representative_Added_Successfully'), severity: 'success' });
            } else if(sorted && newValue === 'name'){
                setMessage({ showMsg: true, message: t('Sorted_Name_By_Ascending_Order'), severity: 'success' });
            } else if (newValue === 'weightage') {
                setMessage({ showMsg: true, message: t('Sorted_Weightage_By_Descending_Order'), severity: 'success' });
            }
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
            setMessage({ showMsg: true, message: error.message, severity: 'error' });
        })
    }

    const refreshVoter = (deletedValue) => {
        setPollRoundVoters([])
        setLoading(true)
        setTimeout(function () {
            setLoading(false)
            listVoters(deletedValue)
        }, 200);
    }

    const editSingleVoters = (voter) => {
        setEditSingleDetails(voter)
        setEdit(true)
        setOpenAuth(true)
    }

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = []
            pollRoundVoters.map((n) => {
                if (n.otpVerifiedOn === null) {
                    newSelected.push(n.voterId)
                }
            })
            setSelected(newSelected);
            return;
        } else {
                listVoters()
        }
        setSelected([]);
    };

    const handleClick = (event, voter_id) => {
        const selectedIndex = selected.indexOf(voter_id);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, voter_id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
            if (selected.length === 1) {
            listVoters()
            }
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    }

    const isSelected = (voter_id) => selected.indexOf(voter_id) !== -1;

    const download = (preSignedUrl) => {
        setMessage({ showMsg: false, message: '', severity: '' })
        setLoading(true);
        axios({
            url: preSignedUrl,
            method: 'GET',
            responseType: 'blob'
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', props.pollClass === TypeOfPoll.POLL ? 'Voter.xlsx' : 'Participant.xlsx');
            document.body.appendChild(link);
            link.click();
            setLoading(false);
        }).catch((error) => {
            setMessage({ showMsg: true, message: error.message, severity: 'error' })
            setLoading(false);
        })
    }

    const downloadExcelFile = () => {
        setLoading(true);
        const payload = {
            fileName: 'e-voting/development/Voter.xlsx',
            httpMethod: 'GET',
            bucket: process.env.REACT_APP_S3_EXCEL_BUCKET,
            pollId: pollId
        }
        AxiosEvotingUploadInterceptor.post(`file/download-link`, payload).then((response) => {
            if (response) {
                download(response)
            }
            setLoading(false);
        }).catch(function (error) {
            setLoading(false);
            setMessage({ show: false, message: '', severity: '' })
            if (error.message) {
                setMessage({
                    show: true,
                    message: error.message,
                    severity: 'error'
                });
            } else {
                setMessage({
                    show: true,
                    message: t('Try_Again'),
                    severity: 'error'
                });
            }
        })
    }
    const onSuccessDelete = () => {
        if (page === 1) {
                    listVoters()
        } else {
            setPage(1)
        }
        setMessage({ showMsg: true, message: props.pollClass === TypeOfPoll.POLL ? 
                t('Voter_Deleted_Successfully') : t('Participant_Deleted_Successfully'), severity: 'success' });
    }

    const handleSortBy = (event, newValue) => {
        setSorted(true)
        if (page === 1) {
            listVoters("", newValue)
        }
        setValue(newValue)
        handleClose();
        setPage(1)
    }

    const confirmation = () => {
        setSeekConfirmation(prevState => ({
            ...prevState,
            show: true,
            message: <Fragment>{t('Are_You_Sure_That_You_Want_To')} 
                <b>{props.pollClass === TypeOfPoll.POLL ? t('Send_Reminder_For_All_Not_Voted_Voters') : t('Send_Reminder_For_All_Not_Voted_Participants')}</b> {t('?')}</Fragment>,
            onAgree: sendRemainder,
            onDisAgree: resetConfirmation,
        }))
    }

    const resetConfirmation = () => {
        setSeekConfirmation(prevState => ({
            ...prevState,
            show: false,
            title: t("Confirmation"),
            message: '',
            onDisAgree: '',
            onAgree: ''
        }))
    }

    const handleSuccessBuldUpload = () => {
        let count = 0;
        interval = setInterval(() => {
            count++;
            listVoters();
            if (count === 3) {
                clearInterval(interval); 
            }
        }, 10000);
    }

    return (
        <Stack paddingX={1} className="loading-container">
            <Stack spacing={1} direction='column' >
                <Stack direction='row' justifyContent='space-between' spacing={2}>
                    {selected.length > 0 && <EnhancedTableToolbar numSelected={selected.length} pollId={pollId} voter_ids={selected} selected={setSelected}
                        context={context.userSession.session.userId} listVoters={listVoters} onSuccessDelete={onSuccessDelete}/>}
                    <Stack direction='row'>
                        <div>
                            {totalNumberOfVoters > 0 && selected.length === 0 &&
                                <Tooltip title={t("Sort_By_Name_Or_Weightage")} arrow>
                                        <Button variant='outlined' color='primary' onClick={handleClickSortBy} style={{background:'white'}}>
                                       {t("Sort_By")} { value }{openPopover ? <ArrowDropUpIcon /> : <ArrowDropDownIcon /> }
                                        </Button>
                                </Tooltip>
                            }
                        </div>
                        <div>
                            {totalNumberOfVoters > 0 && selected.length === 0 ?
                                <SearchVoterList pollId={pollId} onSelect={setSelectedVoter} listBy={listBy} /> : <div></div>
                            }
                        </div>
                    </Stack>

                    {/* {totalNumberOfVoters > 0 && selected.length === 0 ?
                        <SearchVoterList pollId={pollId} onSelect={setSelectedVoter} listBy={listBy} /> : <div></div>
                    } */}
                    {!(props.hasSystemAdmineOrSupportRole) &&
                        <div>
                        {
                            <Tooltip id='refresh' title={t("Refresh_Page")} color="primary">
                                {/* <IconButton onClick={refresh} id='refresh-list' > */}
                                <Fab onClick={refresh} size='small' style={{ background: 'white', margin:'0px 10px 0px 0px' ,padding: '0px' }}>
                                    <RefreshRounded style={{ color: 'orangered'}} />
                                </Fab>
                                {/* <Button onClick={refresh} id='refresh-list'  variant="contained" size="small"  color="primary" style={{ marginBottom: '6px' }}> */}
                                    {/* <RefreshIcon id='refresh-icon' />
                                    </IconButton> */}
                                {/* </Button> */}
                            </Tooltip>}

                        {pollRoundVoters.length > 0 && props.pollStatus === Status.open && listBy != 'voted' &&
                            <Tooltip id="download-bidder-excel-format" title={t('Send_Remainder')} color="primary">
                                {/* <Button id='send-reminder' variant="contained" size='small' color="primary"  style={{ marginBottom: '6px' }} */}
                                {/* <IconButton onClick={sendRemainder}  id='send-reminder'style={{ margin:'0px 10px 0px 0px',padding:'8px' }} >
                                     <SendIcon id='send-button' />
                                </IconButton> */}
                                <Fab onClick={confirmation} size='small' style={{ background: 'white', marginRight:'5px' }}>
                                    <SendIcon  style={{ color: 'blue' }} />
                                </Fab>
                            </Tooltip>}

                        {props.pollStatus != Status.closed && props.pollType != Status.canceled && listBy != 'voted' &&
                            <Tooltip id="download-bidder-excel-format" title={ props.pollClass === TypeOfPoll.POLL ? t('Bulk_Upload_Helper_Text') : t('Bulk_Upload_Participants_Helper_Text')}>
                                <Button id='download-voter' aria-label="refresh-voters" variant="contained" color="primary" size="small"  style={{ marginBottom: '6px',fontSize:'12px' }}
                                    onClick={() => downloadExcelFile('Voter.xlsx')} >
                                    <GetAppRoundedIcon id='download-icon' />{t("Download_Excel")}
                                </Button>
                            </Tooltip>}&nbsp;
                        {props.pollStatus != Status.closed && props.pollType != Status.canceled && listBy != 'voted' &&
                            <Tooltip title={props.pollClass === TypeOfPoll.POLL ? t("Upload_Voter_List") : t('Upload_Participant_List')}>
                                <Button id='upload-voter-list' color="primary" variant="contained" size="small" onClick={handleClickOpen} disabled={props.paidVoter == totalNumberOfVoters && props.paymentStatus}
                                    style={{ marginBottom: '6px',fontSize:'12px'  }} >
                                    <PublishRoundedIcon />{t("Upload_Excel")}
                                </Button>
                            </Tooltip>
                        }&nbsp;

                        {(props.pollStatus != Status.closed && props.pollType != Status.canceled) && listBy != 'voted' &&
                            <Tooltip id='add-icon' title={props.pollClass === TypeOfPoll.POLL ? t("Add_Voter") : t("Add_Participant")}>
                                <Button variant="contained" id='add-poll-voter' aria-label="add-poll-voter" color="primary" size="small" style={{ marginBottom: '6px',fontSize:'12px'  }}
                                     onClick={handleAddVoters} disabled={isAddNew}>
                                    <PersonAddIcon />{props.pollClass === TypeOfPoll.POLL ? t("Add_Voter") : t("Add_Participant")}
                                </Button>
                            </Tooltip>}
                </div>}
                { props.hasSystemAdmineOrSupportRole &&
                    <div>
                        {
                            <Tooltip id='refresh' title={t("Refresh_Voters_List")} color="primary" style={{ padding: '0px', margin:'0px 10px 0px 0px' }}>
                                {/* <IconButton onClick={refresh} id='refresh-list' >
                                 <RefreshIcon id='refresh-icon' />
                                    </IconButton> */}
                                    <Fab onClick={refresh} size='small' style={{ background: 'white', marginRight: '5px' }}>
                                        <RefreshRounded style={{ color: 'orangered' }} />
                                    </Fab>
                            </Tooltip>}

                        {listBy != 'voted' && props.pollStatus != Status.setup && props.pollStatus != Status.upcoming &&
                            <Tooltip id="download-bidder-excel-format" title={t('Send_Remainder')} color="primary">
                                {/* <Button id='send-reminder' variant="contained" size='small' color="primary"  style={{ marginBottom: '6px' }} */}
                                {/* <IconButton onClick={sendRemainder}  id='send-reminder'style={{ margin:'0px 10px 0px 0px',padding:'8px' }} >
                                     <SendIcon id='send-button' />
                                </IconButton> */}
                                  <Fab onClick={confirmation} size='small' style={{ background: 'white', marginRight:'5px' }}>
                                        <SendIcon  style={{ color: 'blue' }} />
                                    </Fab>
                            </Tooltip>}

                        {listBy != 'voted' &&
                            <Tooltip id="download-bidder-excel-format" title={ props.pollClass === TypeOfPoll.POLL ? t('Bulk_Upload_Helper_Text') : t('Bulk_Upload_Participants_Helper_Text')}>
                                <Button id='download-voter' aria-label="refresh-voters" variant="contained" color="primary" size="small"  style={{ marginBottom: '6px',fontSize:'12px' }}
                                    onClick={() => downloadExcelFile('Voter.xlsx')} >
                                    <GetAppRoundedIcon id='download-icon' />{t("Download_Excel")}
                                </Button>
                            </Tooltip>}&nbsp;
                        {listBy != 'voted' &&
                            <Tooltip title={props.pollClass === TypeOfPoll.POLL ? t("Upload_Voter_List") : t('Upload_Participant_List')}>
                                <Button id='upload-voter-list' color="primary" variant="contained" size="small" onClick={handleClickOpen} disabled={props.paidVoter == totalNumberOfVoters && props.paymentStatus}
                                    style={{ marginBottom: '6px',fontSize:'12px'  }} >
                                    <PublishRoundedIcon />{t("Upload_Excel")}
                                </Button>
                            </Tooltip>
                        }&nbsp;

                        {listBy != 'voted' &&
                            <Tooltip id='add-icon' title={props.pollClass === TypeOfPoll.POLL ? t("Add_Voter") : t("Add_Participant")}>
                                <Button variant="contained" id='add-poll-voter' aria-label="add-poll-voter" color="primary" size="small" style={{ marginBottom: '6px',fontSize:'12px'  }}
                                     onClick={handleAddVoters} disabled={isAddNew}>
                                    <PersonAddIcon />{props.pollClass === TypeOfPoll.POLL ? t("Add_Voter") : t("Add_Participant")}
                                </Button>
                            </Tooltip>}
                </div>}
                </Stack>
                <Paper  elevation={5} >
                    <TableContainer style={{ maxHeight: props.paperHeight }}>
                        <Table id='table-poll-voter' stickyHeader={true} aria-label="sticky-table-header">
                            <TableHead >
                                <TableRow >
                                    {totalNumberOfVoters > 0 && <TableCell id='collpase' style={{ background: '#f0fcfb', width: "5%" }}></TableCell>}
                                    {totalNumberOfVoters > 0 && (props.pollStatus != Status.open ? listBy === 'all' : listBy === 'not-voted') && props.pollStatus != Status.closed && props.pollStatus != Status.canceled &&
                                        <TableCell padding="checkbox" style={{ background: '#f0fcfb', width: "5%" }}>
                                            <EnhancedTableHead
                                                numSelected={selected.length}
                                                onSelectAllClick={handleSelectAllClick}
                                                rowCount={pollRoundVoters.length}
                                            />
                                        </TableCell>}
                                    {columns.map((column, index) => (
                                        column.show && <TableCell id={`column${index}`} key={index} align='left' style={{ background: '#f0fcfb', fontWeight: 'bold', fontSize: '16px', width: column.minWidth + "%", paddingLeft: totalNumberOfVoters > 0 ? '0px' : '10px' }}>
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {totalNumberOfVoters === 0 && <TableRow>
                                    <TableCell id='colspan' colSpan={7} style={{ marginTop: '4%' }}> {t('No_Record_Found')} </TableCell>
                                </TableRow>
                                }
                                {totalNumberOfVoters > 0 && pollRoundVoters.map((voter, index) => {
                                    const isItemSelected = isSelected(voter.voterId);
                                    return (
                                        <PollRoundVoterRow key={index + voter.voterId} voter={voter} pollId={pollId} hasPermission={props.hasPermission}
                                            handleCancelVoter={handleCancelVoter} index={index} refreshVoter={(response) => listVoters(response)}
                                            setIsAddNew={setIsAddNew} pollStatus={props.pollStatus} editSingleVoter={editSingleVoters}
                                            pollType={props.pollType} page={page} limit={itemsPerPage}
                                            context={props.pollContext}
                                            setAuth={setOpenAuth}
                                            hasSystemAdmineOrSupportRole={ props.hasSystemAdmineOrSupportRole}
                                            setMessage={props.setMessage}
                                            pollDetails={props.pollDetails}
                                            paidVoter={props.paidVoter}
                                            canModifyAfterPollOpen={props.canAddAfterPollOpen} refreshAfterDelete={setSelectedVoter}
                                            listBy={listBy}
                                            selected={selected} isItemSelected={isItemSelected} handleClick={handleClick}
                                            pollClass={props.pollClass}/>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {totalNumberOfVoters > 0 && <Stack padding={{ xs: 1 }}>
                        <EligoPagination rowsPerPage={itemsPerPage} page={page} totalItems={totalNumberOfVoters}
                            onRowsPerPageChange={handleChangeRowsPerPage} onPageChange={handleChangePage}
                            rowsText={props.pollClass === TypeOfPoll.POLL ? t('Voters') : t('Participants')}>
                            {props.pollClass === TypeOfPoll.POLL && <Typography variant="overline" display="block" gutterBottom
                                style={{ display: 'inherit' }}>{`${t('Total_Weightage')} : ${overAllWeightage}`}</Typography>}
                        </EligoPagination>
                    </Stack>}
                </Paper>
            </Stack>
            <Popover
                id={id}
                open={openPopover}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}>
                    <Grid container>
                    <Grid item xs={12}>
                        <List component="nav" aria-label="addresses">
                            <ListItem button key={1} onClick={(event) => handleSortBy(event, 'name')}>
                                <ListItemText id="sortby-name" primary={t('Name')}></ListItemText>
                            </ListItem>
                            {props.pollClass === TypeOfPoll.POLL && <ListItem button key={2} onClick={(event) => handleSortBy(event, 'weightage')}>
                                <ListItemText id="sortby-weightage" primary={t('Weightage')}></ListItemText>
                            </ListItem>}
                        </List>
                    </Grid>
                </Grid>
            </Popover>
            <BulkUploadFile open={open} setOpen={setOpen} pollId={pollId} setMessage={setMessage} listVoters={listVoters} 
                onSuccess={handleSuccessBuldUpload}/>
            {seekConfirmation.show && <EligoConfirmDialog show={seekConfirmation.show} showGreen={true}
                title={seekConfirmation.title} message={seekConfirmation.message}
                onAgree={seekConfirmation.onAgree} onDisAgree={seekConfirmation.onDisAgree} ConfirmFor={true} />}
            {
                openAddVoter && <VoterAdd open={openAddVoter} addVoterwithSearch={openAddVoter} closeVoterDialog={setOpenAddVoter} editSingleDetails={editSingleDetails}
                    pollId={pollId} refreshVoter={listVoters} Edit={Edit} setEdit={setEdit} 
                    pollClass={props.pollClass}/>
            }
            {loading && <EligoBackdrop show={true} />}
            {
                message.showMsg && <EligoSnackBar show={message.showMsg} message={message.message}
                    severity={message.severity} />
            }
        </Stack >

    )
}

export default AddPollRoundVoter;