import { Divider, Grid, IconButton, Input, Link, makeStyles, Paper, Popover, Slider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, withStyles } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { Stack } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaRupeeSign } from 'react-icons/fa';
import EligoBackdrop from '../../common/EligoBackdrop';
import EligoSnackBar from '../../common/EligoSnackBar';
import ContactInfo from './ContactInfo';
import { MiniCard } from './styles/PricingStyle';

const rupeeIcon = <FaRupeeSign />

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        paddingLeft: '5vw',
        paddingRight: '5vw'
    },
    priceCard: {
        margin: '8px'
    },
    cardHeader: {
        padding: '0'
    },
    cardContent: {
        padding: '16px'
    },
    amount: {
        fontSize: 36,
    },
    gst: {
        fontSize: 12,
        color: 'grey'
    },
    basePlan: {
        fontSize: 12,
        color: 'grey'
    },
    note: {
        color: 'grey'
    },
    slider: {
        margin: 24
    },
    input: {
        width: 80,
    },
    priceTitle: {
        fontSize: 10
    },
    amountTitle: {
        fontSize: 10
    },
    tableHead: {
        fontWeight: 'bold'
    }
})

const cusotmBoxShadow =
    '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

const CustomSlider = withStyles({
    root: {
        color: '#3880ff',
        height: 2,
        padding: '15px 0',
    },
    thumb: {
        height: 28,
        width: 28,
        backgroundColor: '#fff',
        boxShadow: cusotmBoxShadow,
        marginTop: -14,
        marginLeft: -14,
        '&:focus, &:hover, &$active': {
            boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
            '@media (hover: none)': {
                boxShadow: cusotmBoxShadow,
            },
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 12px)',
        top: -22,
        '& *': {
            background: 'transparent',
            color: '#000',
        },
    },
    track: {
        height: 2,
    },
    rail: {
        height: 2,
        opacity: 0.5,
        backgroundColor: '#bfbfbf',
    },
    mark: {
        backgroundColor: '#bfbfbf',
        height: 8,
        width: 1,
        marginTop: -3,
    },
    markActive: {
        opacity: 1,
        backgroundColor: 'currentColor',
    },
})(Slider);

const Pricing = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [voters, setVoters] = useState(props.noOfVoters > 0 ? props.noOfVoters : 100);
    const { t } = useTranslation();
    const classes = useStyles();
    const [gstPercentage, setGSTPercentage] = useState(18);
    const [price, setPrice] = useState(0);
    const [cessPercentage, setCessPercentage] = useState(0);
    const [gst, setGST] = useState(0);
    const [cess, setCess] = useState(0);
    const [grandTotal, setGrandTotal] = useState(0);
    const [pricing, setPricing] = useState({
        base_price: '',
        discount_on_every_business_range_of: '',
        discount_on_every_voter_range_of: '',
        maximum_price_per_voter: '',
        minimum_price_per_voter: '',
        no_of_free_voter_on_base_price: '',
        discount: []
    });
    const [priceBreakup, setPriceBreakup] = useState(null);
    const [message, setMessage] = React.useState({
        showMsg: false,
        message: null,
        severity: null
    });
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    // const [getPricing, { loading: loadingPricing, error: errorOnLoadingPricing }] = useLazyQuery(GET_PRICING, {
    //     fetchPolicy: 'network-only',
    //     errorPolicy: 'all',
    //     onCompleted: (priceList) => {
    //         if (priceList.getPriceList != null) {
    //             setPricing(priceList.getPriceList)
    //         }
    //     }
    // });

    // const [getCost, { loading: loadingCost, error: errorOnLoadingCost }] = useLazyQuery(GET_COST, {
    //     fetchPolicy: 'network-only',
    //     errorPolicy: 'all',
    //     onCompleted: (cost) => {
    //         setPrice((cost.getCost.total - cost.getCost.gst_amount) - cost.getCost.cess_amount);
    //         setGSTPercentage(cost.getCost.gst_percentage)
    //         setCessPercentage(cost.getCost.cess_percentage)
    //         setGST(cost.getCost.gst_amount);
    //         setCess(cost.getCost.cess_amount);
    //         setGrandTotal(cost.getCost.total);
    //         setPriceBreakup(cost.getCost);
    //         if (props.setGrandTotal) {
    //             props.setGrandTotal(cost.getCost.total);
    //         }
    //         if (props.setVoters) {
    //             props.setVoters(voters);
    //         }
    //     }
    // });

    const handleChange = (event, value) => {
        setVoters(value);
        if (props.setVoters) {
            props.setVoters(value);
        }
    }

    const handleInputChange = (event) => {
        setVoters(event.target.value === '' ? '' : Number(event.target.value));
        // if (props.setVoters) {
        //     props.setVoters(event.target.value === '' ? '' : Number(event.target.value));
        // }
        // getPrice();
    };

    const getPrice = () => {
        setMessage({ showMsg: false, message: '', severity: '' });
        if (((voters >= props.noOfExitingVoters && voters >= props.defaultNoOfVoters) || props.paidNoOfVoters > 0) && voters != "" && !isNaN(voters)) {
            getPriceDetails(voters);
        } else {
            setMessage({ showMsg: true, message: t("No_of_Voters_Cannot_Be_Less_Than") + " " + (props.paidNoOfVoters ? 1 : props.noOfVoters), severity: 'warning' });
            if (props.paidNoOfVoters) {
                setVoters(1);
                getPriceDetails(1);
            } else {
                setVoters(props.noOfExitingVoters > props.defaultNoOfVoters ? props.noOfExitingVoters : props.defaultNoOfVoters);
                // if (props.setVoters){
                //     props.setVoters(props.noOfVoters > 0  ? props.noOfVoters : 100 );
                // }
                getPriceDetails(props.noOfExitingVoters > props.defaultNoOfVoters ? props.noOfExitingVoters : props.defaultNoOfVoters);
            }
        }
    }

    const getPriceDetails = (totalVoters) => {
        if (props.pollId) {
            // getCost({
            //     variables: {
            //         number_of_user: totalVoters,
            //         poll_id: props.pollId,
            //         user_client_id: props.customerId
            //     }
            // })
        } else {
            setLoading(true);
            axios.post(`${process.env.REACT_APP_AWS_GATEWAY_POLL_API_URL}/cost`,
                { number_of_voters: totalVoters }).then((response) => {
                    setPrice((response.data.total - response.data.gst_amount) - response.data.cess_amount);
                    setGSTPercentage(response.data.gst_percentage)
                    setCessPercentage(response.data.cess_percentage)
                    setGST(response.data.gst_amount);
                    setCess(response.data.cess_amount);
                    setGrandTotal(response.data.total);
                    setPriceBreakup(response.data);
                    setLoading(false);
                }).catch(() => {
                    setLoading(false);
                });
        }
    }

    // useEffect(() => {
    //     getPrice();
    //     if (props.pollId) {
    //         getPricing();
    //     }
    // }, []);

    // useEffect(() => {
    //     getPrice();
    // }, [props.customerId])


    return <React.Fragment>
        {/* <Card className={`${classes.priceCard} 'loading-container`}> */}
        <div className={`${classes.cardContent} loading-container`}
            style={{ background: 'white', borderRadius: '5px', padding: '18px 36px' }}>
            <div>
                <strong id='price'> Price </strong>
            </div>
            <br />
            <Grid container spacing={2} alignItems="center">
                <Grid item className={classes.slider} style={{ width: '54%' }}>
                    <Typography id='voter'>No. of Voters</Typography>
                    <CustomSlider
                        defaultValue={voters}
                        value={typeof voters === 'number' ? voters : 0}
                        aria-labelledby="input-slider"
                        step={1}
                        max={2000}
                        onChange={handleChange}
                        onChangeCommitted={() => getPrice()}
                    // valueLabelDisplay="on"
                    />
                </Grid>
                <Grid item>
                    <Input
                        className={classes.input}
                        value={voters}
                        margin="dense"
                        onChange={handleInputChange}
                        onBlur={() => getPrice()}
                        onBlurCapture={() => getPrice()}
                        inputProps={{
                            step: 1,
                            min: voters,
                            max: 10000,
                            // type: 'number',
                            'aria-labelledby': 'input-slider',
                        }}
                    />
                </Grid>
            </Grid>
            <Typography id='total' align="center">
                <FaRupeeSign style={{ fontSize: '1.5rem' }} /> <span className={classes.amount}>
                    {price.toLocaleString() + '*'}
                </span>
                <span className={classes.gst}>+ {t('GST')}</span>
                <IconButton id='iconbutton' aria-label="price-breakup" color="primary" size="small" className="float-right"
                    onClick={handleClick}>
                    <InfoIcon />
                    <div style={{ position: 'absolute', marginTop: '46px', fontSize: '10px' }}>view breakup</div>
                </IconButton>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}>
                    <Grid container>
                        <Grid xs={12}>
                            <TableContainer component={Paper}>
                                <Table aria-label="price-breakup-table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell id='listvoter' className={classes.tableHead}>No. of Voters</TableCell>
                                            <TableCell id='amount' className={classes.tableHead} align="right">Price (Rs.)</TableCell>
                                            <TableCell id='discount' className={classes.tableHead} align="right">Discount (Rs.)</TableCell>
                                            <TableCell id='payable' className={classes.tableHead} align="right">Payable (Rs.)</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {priceBreakup != null &&
                                            <React.Fragment>
                                                {!props.paidNoOfVoters > 0 && <TableRow key={"base-price"}>
                                                    <TableCell id='pricenumber' component="th" scope="row">0 - {priceBreakup.base_price.number_of_voter.toLocaleString()}</TableCell>
                                                    <TableCell id='totalprice' align="right">{priceBreakup.base_price.price.toLocaleString()}</TableCell>
                                                    <TableCell id='discountAccount' align="right">{priceBreakup.base_price.discount != 0 ? priceBreakup.base_price.discount.toLocaleString() : "-"}</TableCell>
                                                    <TableCell id='afterdiscount' align="right">{priceBreakup.base_price.price_after_discount.toLocaleString()}</TableCell>
                                                </TableRow>}
                                                {priceBreakup.more_voter.number_of_voter != 0 && <TableRow key={2}>
                                                    <TableCell id='paidvoter' component="th" scope="row">{props.paidNoOfVoters > 0 ? priceBreakup.more_voter.number_of_voter : t('Above') + ' ' + priceBreakup.base_price.number_of_voter.toLocaleString()}</TableCell>
                                                    <TableCell id='priceBreak' align="right">{priceBreakup.more_voter.price.toLocaleString()}</TableCell>
                                                    <TableCell id='totaldiscount' align="right">{priceBreakup.more_voter.discount != 0 ? priceBreakup.more_voter.discount.toLocaleString() : "-"}</TableCell>
                                                    <TableCell id='vote' align="right">{priceBreakup.more_voter.price_after_discount.toLocaleString()}</TableCell>
                                                </TableRow>}
                                            </React.Fragment>}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Popover>
            </Typography>
            <Typography id='for' className={classes.amountTitle} align="center">
                {t('FOR')} {voters} {t('VOTERS')}
            </Typography>
            <br />
            <MiniCard>
                <Stack direction='row' justifyContent='center' alignItems='center' spacing={1}>
                    <Typography align="center" color='textPrimary' component='div'>
                        {t('Please')}
                        <Link onClick={() => setDialogOpen(true)} underline='always' style={{ cursor: 'pointer', padding: '5px' }}>
                            {t('Contact_Us')}</Link>
                        {t('For_more_than_100_voters_special_pricing')}
                    </Typography>
                </Stack>
            </MiniCard>
            <br />
            <Typography className={classes.priceTitle}>
                * Base Price of &#8377; {`${(pricing.base_price).toLocaleString()}`} upto {`${pricing.no_of_free_voter_on_base_price}`} Voters
            </Typography>
            <Typography id='novoters' className={classes.priceTitle}>
                {t('* Price is based on No. of Voters')}
            </Typography>
            <br />
            <Divider />
            <br />
            <Grid container item>
                <Grid xs={7}>
                    <Typography >Total <span className='float-right'>:</span></Typography>

                </Grid>
                <Grid xs={5}>
                    <Typography id='align' align="right">&#8377; &nbsp;{price.toLocaleString()}</Typography>
                </Grid>
                <Grid xs={7}>
                    <Typography id='gst'>GST ({gstPercentage}%) <span className='float-right'>:</span></Typography>

                </Grid>
                <Grid xs={5}>
                    <Typography id='gstalign' align="right">&#8377; &nbsp;{gst.toLocaleString()}</Typography>
                </Grid>
                <Grid xs={7}>
                    <Typography id='cess'>CESS ({cessPercentage}%) <span className='float-right'>:</span></Typography>
                </Grid>
                <Grid xs={5}>
                    <Typography id='right' align="right">&#8377; &nbsp;{cess.toLocaleString()}</Typography>
                </Grid>
                <Grid xs={7}>
                    <Typography><strong>Grand Total</strong> <span className='float-right'>:</span></Typography>

                </Grid>
                <Grid xs={5}>
                    <Typography id='grandtotal' align="right"><strong>&#8377; &nbsp;{grandTotal.toLocaleString()}</strong></Typography>
                </Grid>
            </Grid>
            <br />
        </div>
        {dialogOpen && <ContactInfo dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} />}
        {/* </Card> */}
        {message.showMsg && <EligoSnackBar show={message.showMsg} message={message.message}
            severity={message.severity} />}
    </React.Fragment>
};

export default Pricing;
