import {
    IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableRow, TextField, Tooltip, Typography
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import { Stack } from '@mui/material';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../common/context/UserContext';
import EligoConfirmDialog from '../../../common/EligoConfirmDialog';
import EligoSnackBar from '../../../common/EligoSnackBar';
import EligoPagination from '../../../common/pagination/EligoPagination';
import { AxiosEvotingInterceptor } from '../../../config/axios.interceptor';
import { CollapsedTableHeader } from '../../file-upload-download/styles/FileStyle';
import SingleVoter from '../poll-round-voter-list/SingleVoter';
import AuthRepForExistingVoter from './AuthRepForExistingVoter';
import VoterAdd from '../poll-round-voter-list/VoterAdd';
import { Avatar } from '@mui/material';
import PersonIcon from '@material-ui/icons/Person';
import MuiPhoneNumber from "material-ui-phone-number";

const useStyles = makeStyles(() => ({
    header: {
        margin: '1rem 0rem 1rem 0rem'
    },

    tablePadding: {
        width: '90%',
        margin: '0rem 0rem 1rem 4.4rem'
    },
    btn: {
        margin: '0rem 4.3rem 0rem 0rem'
    },
    avatar: {
        backgroundColor: `rgb(59, 175, 218) !important`,
        width: '34px !important',
        height: '34px !important',
        marginTop: "5px"

    }
}))

function AuthRepresenties(props) {
    const { pollRoundVoter, context, pollId, setLoading, editable, notVotedVoters, hasSystemAdmineOrSupportRole,
        pollClass} = props
    const { t } = useTranslation();
    const classes = useStyles();
    const contexts = useContext(UserContext)
    const [opengroupVoter, setOpenGroupvoter] = useState(false)
    const [editSingleDetails, setEditSingleDetails] = useState({})
    const [addAuthReps, setAddAuthReps] = useState(false)
    const [page, setPage] = useState(1);
    const [itemsPerPage, setItemPerPage] = useState(5);
    const [totalAuthRepCount, setTotalAuthrepCount] = useState(0);
    const [message, setMessage] = useState({
        show: false,
        message: '',
        severity: ''
    })
    const [authRepRows, setAuthRepRows] = useState([])
    const [seekConfirmation, setSeekConfitmation] = useState({
        show: false,
        title: t("Confirmation"),
        message: '',
        onAgree: '',
        onDisAgree: ''
    })

    const deleteAuthRep = (representsById) => {
        setLoading(true)
        const payload = [representsById]
        resetConfirmation()
        AxiosEvotingInterceptor.put(`voter/auth-rep/delete/${pollId}/${pollRoundVoter.voterId}`, payload).then(response => {
            if (response) {
                if (totalAuthRepCount === 1) {
                    props.refreshVoter('AuthrepDelete')
                } else {
                    if (page === 1) {
                        AuthRepPageChange()
                    } else {
                        setPage(1)
                        setLoading(false)
                    }
                    setMessage({
                        show: true,
                        message: t('AuthRep_Deleted_Successfully'),
                        severity: 'success'
                    })
                }
            }
        }).catch((error) => {
            setLoading(false)
        })
    }


    const confirmaion = (representsById, authRepName) => {
        setSeekConfitmation(prevState => ({
            ...prevState,
            show: true,
            message: <Fragment>{t('Are_You_Sure_That_You_Want_To')} <b>{t('Delete')}</b> {t('Authorize Representative')} <b>{authRepName}</b> {t('?')}</Fragment>,
            onAgree: () => deleteAuthRep(representsById),
            onDisAgree: resetConfirmation,
        }))
    }

    const resetConfirmation = () => {
        setSeekConfitmation(prevState => ({
            ...prevState,
            show: false,
            title: t("Confirmation"),
            message: '',
            onDisAgree: '',
            onAgree: ''
        }))
    }

    const AuthRepPageChange = () => {
        setLoading(true)
        AxiosEvotingInterceptor.get(`voter/auth-rep/${pollId}/${pollRoundVoter.voterId}?page=${page}&limit=${itemsPerPage}&asc=false`).then(response => {
            setAuthRepRows([])
            setLoading(false)
            setAuthRepRows(response.authorizedRepresentative)
            setTotalAuthrepCount(response.count)
        }).catch((error) => {
            setLoading(false)
            setMessage({
                show: true,
                message: error.message,
                severity: 'error'
            })
        })
    }

    useEffect(() => {
        AuthRepPageChange();
    }, [page, itemsPerPage])

    const handleEditAuthRep = (representsById, name, email, phoneNumber,countryCode, nationalNumber) => {
        setOpenGroupvoter(true)
        setEditSingleDetails(
            {
                name: name,
                email: email,
                phoneNumber: phoneNumber,
                nationalNumber: countryCode === 1 ? nationalNumber.toString().slice(3, 10) : nationalNumber,
                countryCode: countryCode === 1 ? countryCode + nationalNumber.toString().slice(0, 3) : countryCode,
                // countryCode:countryCode,
                // nationalNumber:nationalNumber,
                representsById: representsById,
                auth_rep: true,
                voterId: pollRoundVoter.voterId
            }
        )
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setItemPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };

    return (
        <div>
            <Stack direction='row' className={classes.header}>
                <Typography id='header' style={{ background: '#F5FBFF', marginLeft: '3.3%', fontWeight: 'bold', color: 'black', padding: '5px 10px 5px 10px', borderRadius: '15px' }}>
                    {t("Authorized_Representative")}
                </Typography>
                {totalAuthRepCount > 0 && <Typography style={{ background: '#F5FBFF', marginLeft: '3.3%', color: 'black', padding: '5px 10px 5px 10px', borderRadius: '15px' }}>
                    {t('Total')} - <b>{totalAuthRepCount}</b>
                </Typography>}
            </Stack>
            <Paper id='table' className={classes.tablePadding}>
                <Table size="small" id='table'>
                    <CollapsedTableHeader>
                        <TableRow>
                            <TableCell align='left' style={{ width: '45%' }}>{t('Auth_Rep_Name')}</TableCell>
                            {/* <TableCell align='left' style={{ width: '33%' }}>{t('Email_Id')}</TableCell> */}
                            <TableCell align='left' style={{ width: '42%' }}>{t("Phone_Number")}</TableCell>
                            {editable && notVotedVoters && <TableCell align='left'>{t('Actions')}</TableCell>}
                        </TableRow>
                    </CollapsedTableHeader>
                    <TableBody>
                        {authRepRows.map((historyRow, index) => (
                            <TableRow key={historyRow.personId}>
                                <TableCell component="th" scope="row">
                                    <Stack direction='row' spacing={2}>
                                        <Stack>
                                            <Avatar id={`avatar${props.index}`} className={classes.avatar}><PersonIcon /> </Avatar>
                                        </Stack>
                                        <Stack>
                                        <Typography id={`user-name${props.index}`}><b>{historyRow.name ? historyRow.name + " " + ((historyRow.restOfName != null) ? historyRow.restOfName : '') : '--'}</b></Typography>
                                            <Typography id={`user-email${props.index}`} style={{ color: 'grey' }}>{historyRow.email.map((value) => { return value.email })}</Typography>
                                        </Stack>
                                    </Stack>
                                </TableCell>
                                {/* <TableCell>{historyRow.email.map((value) => { return value.email })}</TableCell> */}
                                <TableCell>
                                    {historyRow.phoneNumber.length > 0 ?
                                    historyRow.phoneNumber.map((value) => {
                                        return (
                                            <MuiPhoneNumber
                                                id="phonenumber"
                                                name="phonenumber"
                                                defaultCountry={"in"}
                                                value={value.phoneNumber}
                                                autoFormat={false} fullWidth
                                                countryCodeEditable={false}
                                                disableDropdown={true}
                                                disabled={true}
                                                InputProps={{
                                                    disableUnderline: true
                                                }}
                                            />
                                        )
                                    }) : <TextField id="phone-number"
                                    className={classes.disableColor}
                                    style={{ marginLeft: '50px' }}
                                    disabled
                                    value='--'
                                    fullWidth InputProps={{
                                        disableUnderline: true
                                    }} />}</TableCell>
                                {((editable && notVotedVoters) || hasSystemAdmineOrSupportRole) && <TableCell>
                                    <Tooltip title={t("Edit")}>
                                        <IconButton id='editbutton' edge="start" aria-label="edit-poll-voter" color="primary" size="small"
                                            onClick={() => handleEditAuthRep(historyRow.representsById, historyRow.name, historyRow.email[0].email,
                                                historyRow.phoneNumber.length > 0 ? historyRow.phoneNumber[0].phoneNumber : null,
                                                historyRow.phoneNumber.length > 0 && historyRow.phoneNumber[0].phoneDetail ? historyRow.phoneNumber[0].phoneDetail.countryCode : '+91' ,
                                                historyRow.phoneNumber.length > 0 && historyRow.phoneNumber[0].phoneDetail ?  historyRow.phoneNumber[0].phoneDetail.nationalNumber  : null
                                                )}>
                                            <EditIcon id='edit-poll-voter' />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip id='deleteAuth-' title={t("Delete")}>
                                        <IconButton id='deletebutton' edge="end" aria-label="delete-poll-voter" color="secondary" size="small"
                                            onClick={() => confirmaion(historyRow.representsById, historyRow.name)}>
                                            <DeleteIcon id='deleteicon' />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {totalAuthRepCount > 5 && <Stack padding={{ xs: 1 }}>
                    <EligoPagination rowsPerPage={itemsPerPage} page={page} totalItems={totalAuthRepCount}
                        onRowsPerPageChange={handleChangeRowsPerPage} onPageChange={handleChangePage}
                        rowsText={t('AUTHORIZED-REPRESENTATIVES')}>
                    </EligoPagination>
                </Stack>}
            </Paper>
            {message.show && <EligoSnackBar show={message.show} message={message.message} severity={message.severity} />}
            {/* {
                opengroupVoter && <SingleVoter open={opengroupVoter} setAuth={setOpenGroupvoter} pollId={pollId} AuthRepPageChange={AuthRepPageChange}
                    editSingleDetails={editSingleDetails} refreshVoter={props.refreshVoter} />
            } */}
            {
                opengroupVoter && <VoterAdd open={opengroupVoter} closeVoterDialog={setOpenGroupvoter} pollId={pollId} AuthRepPageChange={AuthRepPageChange}
                    editSingleDetails={editSingleDetails} refreshVoter={props.refreshVoter} editAuthRepDetails={true} 
                    pollClass={pollClass}/>
            }
            {
                addAuthReps && <AuthRepForExistingVoter open={addAuthReps} pollId={pollId} voterId={pollRoundVoter.voterId} setAuthReps={setAddAuthReps}
                    refreshList={props} />
            }
            {seekConfirmation.show && <EligoConfirmDialog show={seekConfirmation.show}
                title={seekConfirmation.title} message={seekConfirmation.message}
                onAgree={seekConfirmation.onAgree} onDisAgree={seekConfirmation.onDisAgree} ConfirmFor={true} />}
        </div >
    )
}

export default AuthRepresenties