var TelephoneCodes = [
    { countryName: 'India', countryCode: 'IN', telephoneCode: 91 }
]

var GenericCodeNodeLabels = {
    pollCategory: 'poll_category'
}

var Roles = {
    organizer: 'organizer',
    voter: 'voter',
    scrutinizer: 'scrutinizer',
    observer: 'observer',
}

var Status = {
    open: 'OPEN',
    closed: 'CLOSED',
    upcoming: 'UPCOMING',
    new: 'NEW',
    deleted: 'DELETED',
    scheduled: 'SCHEDULED',
    canceled: 'CANCELLED',
    published: 'PUBLISHED',
    setup: 'SETUP',
    ready: 'READY',
    publish_request:'PUBLISH_REQUEST'
}

var ApplicationClientId = {
    eVoting: 'Thirumal'
}

const UserRole = {
    Role_Support: 'ROLE_SUPPORT',
    Role_User: 'ROLE_USER',
    Role_Admin: 'ROLE_ADMIN'
}

const PersonType = [
    {label: 'Individual', value: true},
    {label: 'Institution', value: false},
]

const PollType = [
    {type: 'POLL'},
    {type: 'SURVEY'},
]

const TypeOfPoll = {
    POLL: 'POLL',
    SURVEY: 'SURVEY'
}

const PollCategory = [
    {type: 'IBC', label: 'IBC'},
    {type: 'OTHER', label: 'Other'},
]

const TypeOfPollCategory = {
    IBC: 'IBC',
    OTHER: 'OTHER'
}

const SurveyCategory = [
    {type: 'SURVEY', label: 'Survey'},
    {type: 'ELECTION', label: 'Election'},
    {type: 'OTHER', label: 'Other'}
]

export {
    TelephoneCodes, GenericCodeNodeLabels, Roles, Status, ApplicationClientId, UserRole, PollType,
    TypeOfPoll, PollCategory, PersonType, TypeOfPollCategory, SurveyCategory };
