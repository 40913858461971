import React , { useCallback , useEffect}                       from 'react';
import { useGoogleReCaptcha }       from 'react-google-recaptcha-v3';

const EligoReCaptcha = ({captcha}) => {
    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            return;
        }

        const token = await executeRecaptcha();
        captcha(token);
      }, [executeRecaptcha]);

    useEffect(() => {
        handleReCaptchaVerify();
    }, [handleReCaptchaVerify]);

    
    return (
        <div></div>
    )
}

export default EligoReCaptcha;