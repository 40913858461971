import { Button, Card, CardActions, CardContent, Grid, makeStyles } from '@material-ui/core';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EligoBackdrop from '../../../common/EligoBackdrop';
import EligoSnackBar from '../../../common/EligoSnackBar';
import { Status, TypeOfPollCategory, UserRole } from '../../../common/GenericCodes';
import { UserContext } from '../../../common/context/UserContext';
import * as Validators from '../../../common/form-validators.js';
import { AxiosEvotingInterceptor } from '../../../config/axios.interceptor';
import PollOrganizerDetail from '../poll-organizer-detail/PollOrganizerDetail';
import PollRoundDetail from '../poll-round-detail/PollRoundDetail';
import dayjs                                from 'dayjs';

const useStyles = makeStyles((theme) => ({
    actions: {
        backgroundColor: 'white'
    },
    cardContent: {
        borderRadius: '0px',
        // maxHeight: '600px',
        // overflow: 'scroll'
    },
    nextBtn: {
        marginLeft: theme.spacing(1),
        float: 'right'
    },
    boxShadowCard: {
        boxShadow: 'rgb(255 255 255 / 10%) 0px 5px 10px 0px inset, rgb(152 152 162 / 25%) 0px 30px 50px -20px, rgb(130 130 174 / 30%) 0px 30px 30px -30px !important'
    }
}));

const AddPollRound = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [errors, setErrors] = React.useState({});
    const [touched, setTouched] = React.useState({});
    const [loading, setLoading] = useState(false);
    const context = useContext(UserContext);
    const [message, setMessage] = React.useState({
        showMsg: false,
        message: null,
        severity: null
    });
    const [pollDetail, setPollDetail] = useState({
        id: props.pollDetails.id,
        referenceNumber: props.pollDetails.referenceNumber,
        title: props.pollDetails.title,
        description: props.pollDetails.description,
        showResultsToVoters: props.pollDetails.showResultsToVoters,
        weightedVotes: props.pollDetails.weightedVotes,
        geoTagging: props.pollDetails.geoTagging,
        geoFencing: props.pollDetails.geoFencing,
        questionShuffle: props.pollDetails.questionShuffle,
        anonymousVoting: props.pollDetails.anonymousVoting,
        websiteRedirect: props.pollDetails.websiteRedirect,
        discloseVoterInResult: props.pollDetails.discloseVoterInResult,
        status: props.pollDetails.status,
        startTime: props.pollDetails.startTime ? dayjs.utc(props.pollDetails.startTime) : null,
        endTime: props.pollDetails.endTime ? dayjs.utc(props.pollDetails.endTime) : null,
        resultPostOnPollClose: props.pollDetails.resultPostOnPollClose,
        startManually: props.pollDetails.startManually,
        alertTime: props.pollDetails.alertTime ? dayjs.utc(props.pollDetails.alertTime) : null,
        alerted: props.pollDetails.alerted,
        voteWithSignUp: props.pollDetails.voteWithSignUp,
        announceResultAutomatically: props.pollDetails.announceResultAutomatically,
        allowVoteChange: props.pollDetails.allowVoteChange,
        scrutinizerHasToOpen: props.pollDetails.scrutinizerHasToOpen,
        displayOrganizerName: props.pollDetails.displayOrganizerName,
        verifyWithOtp: props.pollDetails.verifyWithOtp,
        organizerPersonId: props.pollDetails.organizerPersonId,
        organizerName: props.pollDetails.organizerName,
        organizerEmail: props.pollDetails.organizerEmail,
        organizerPhoneNumber: props.pollDetails.organizerPhoneNumber,
        pollType: props.pollDetails.pollType,
        conductForPersonId: props.pollDetails.conductForPersonId,
        createdOn: props.pollDetails.createdOn,
        questionShuffle: props.pollDetails.questionShuffle,
        realTimeResult: props.pollDetails.realTimeResult,
        pollCategory: props.pollDetails.pollCategory,
        secreteBallot: props.pollDetails.secreteBallot,
        zoneId: props.pollDetails.zoneId,
        zoneDisplayName: props.pollDetails.zoneDisplayName
        // organizer_name: props.pollDetails.organizer_name,
        // organizer_email: props.pollDetails.organizer_email,
        // organizer_phonenumber: props.pollDetails.organizer_phonenumber
    })

    const createPollService = (payload) => {
        setMessage({ showMsg: false, message: '', severity: '' });
        AxiosEvotingInterceptor.post('poll', payload).then(response => {
            setMessage({ showMsg: true, message: t('Poll_Created_Successfully'), severity: 'success' });
            setPollDetail(prevState => ({
                ...prevState,
                id: response.id
            }));
            props.handleCreatePoll({
                id: response.id,
                referenceNumber: response.referenceNumber,
                title: response.title,
                description: response.description,
                showResultsToVoters: response.showResultsToVoters,
                weightedVotes: response.weightedVotes,
                geoTagging: response.geoTagging,
                geoFencing: response.geoFencing,
                questionShuffle: response.questionShuffle,
                anonymousVoting: response.anonymousVoting,
                websiteRedirect: response.websiteRedirect,
                discloseVoterInResult: response.discloseVoterInResult,
                status: Status.scheduled,
                startTime: dayjs.utc(response.startTime),
                endTime: dayjs.utc(response.endTime),
                resultPostOnPollClose: response.resultPostOnPollClose,
                startManually: response.startManually,
                alertTime: dayjs.utc(response.alertTime),
                alerted: response.alerted,
                voteWithSignUp: response.voteWithSignUp,
                announceResultAutomatically: response.announceResultAutomatically,
                allowVoteChange: response.allowVoteChange,
                scrutinizerHasToOpen: response.scrutinizerHasToOpen,
                displayOrganizerName: response.displayOrganizerName,
                verifyWithOtp: response.verifyWithOtp,
                organizerPersonId: response.organizers ? response.organizers[0].personId : null,
                organizerName: response.organizers ? response.organizers[0].name : null,
                organizerEmail: (response.organizers && response.organizers[0].email) ? response.organizers[0].email[0].email : null,
                organizerPhoneNumber: (response.organizers && response.organizers[0].phoneNumber) ? response.organizers[0].phoneNumber[0].phoneNumber : null,
                pollType: response.pollType,
                conductForPersonId: response.conductFor ? response.conductFor.personId : null,
                realTimeResult: response.realTimeResult,
                pollCategory: response.pollCategory,
                secreteBallot: response.secreteBallot,
                zoneId: response.zoneId,
                zoneDisplayName: response.zoneDisplayName

                // organizer_name: pollDetail.organizer_name,
                // organizer_email: pollDetail.organizer_email,
                // organizer_phonenumber: pollDetail.organizer_phonenumber
            })
            setLoading(false);
        }).catch(error => {
            if (error.message) {
                setMessage({ showMsg: true, message: error.message, severity: 'error' });
            }
            setLoading(false);
        })
    }

    const updatePollService = (payload) => {
        AxiosEvotingInterceptor.put(`poll/${pollDetail.id}`, payload).then(response => {
            setMessage({ showMsg: true, message: t('Poll_Updated_Successfully'), severity: 'success' });
            setPollDetail(prevState => ({
                ...prevState,
                id: response.id
            }));
            props.handleCreatePoll({
                id: response.id,
                referenceNumber: response.referenceNumber,
                title: response.title,
                description: response.description,
                showResultsToVoters: response.showResultsToVoters,
                weightedVotes: response.weightedVotes,
                geoTagging: response.geoTagging,
                geoFencing: response.geoFencing,
                questionShuffle: response.questionShuffle,
                anonymousVoting: response.anonymousVoting,
                websiteRedirect: response.websiteRedirect,
                discloseVoterInResult: response.discloseVoterInResult,
                status: Status.scheduled,
                startTime: dayjs.utc(response.startTime),
                endTime: dayjs.utc(response.endTime),
                resultPostOnPollClose: response.resultPostOnPollClose,
                startManually: response.startManually,
                alertTime: dayjs.utc(response.alertTime),
                alerted: response.alerted,
                voteWithSignUp: response.voteWithSignUp,
                announceResultAutomatically: response.announceResultAutomatically,
                allowVoteChange: response.allowVoteChange,
                scrutinizerHasToOpen: response.scrutinizerHasToOpen,
                displayOrganizerName: response.displayOrganizerName,
                verifyWithOtp: response.verifyWithOtp,
                pollType: response.pollType,
                conductForPersonId: response.conductFor ? response.conductFor.personId : null,
                realTimeResult: response.realTimeResult,
                pollCategory: response.pollCategory,
                secreteBallot: response.secreteBallot,
                zoneId: response.zoneId,
                zoneDisplayName: response.zoneDisplayName
                // organizer_name: response.organizer_name,
                // organizer_email: response.organizer_email,
                // organizer_phonenumber: response.organizer_phonenumber
            });
            setLoading(false);
        }).catch(error => {
            setLoading(false)
            if (error.message) {
                setMessage({ showMsg: true, message: error.message, severity: 'error' });
            }
        })
    }

    const validate = {
        title: title => Validators.requiredValidation(t('Title'), title),
        description: description => null,
        startTime: startTime =>
            Validators.requiredValidation(t('Begin_Date_&_Time'), startTime == null ? '' : startTime) ||
            Validators.dateValidation(t('Begin_Date_&_Time'), startTime),
        endTime: endTime =>
            Validators.requiredValidation(t('Close_Date_&_Time'), endTime == null ? '' : endTime) ||
            Validators.dateValidation(t('Close_Date_&_Time'), endTime),
        alertTime: alertTime => Validators.requiredValidation(t('Alert_Date_&_Time'), alertTime == null ? '' : alertTime) ||
            Validators.dateValidation(t('Alert_Date_&_Time'), alertTime),
        referenceNumber: referenceNumber => null,
        pollType: pollType => Validators.requiredValidation(t('Poll_Type'), pollType),
        pollCategory: pollCategory => Validators.requiredValidation(t('Category'), pollCategory),
        zoneId: zoneId => null,
        zoneDisplayName: zoneDisplayName => null
    };

    const onJsonInputChange = (event) => {
        setPollDetail(prevState => ({
            ...prevState,
            [event.target.id]: { en: event.target.value, fr: '' }
        }));
        setTouched(prevState => ({
            ...prevState,
            [event.target.id]: true,
        }))
    }

    const onInputChange = event => {
        setPollDetail(prevState => ({
            ...prevState,
            [event.target.id]: event.target.value
        }));
        setTouched(prevState => ({
            ...prevState,
            [event.target.id]: true,
        }))
    }

    const onInputChangeByName = (fieldName, value) => {
        setPollDetail(prevState => ({
            ...prevState,
            [fieldName]: value
        }));
        setTouched(prevState => ({
            ...prevState,
            [fieldName]: true,
        }))
        if (fieldName === 'pollCategory' && value === TypeOfPollCategory.IBC) {
            setPollDetail(prevState => ({
                ...prevState,
                questionShuffle: false,
                realTimeResult: false
            }))
        }
    }

    const handleDataBoolean = (event, stateVariable) => {
        let id = null;
        if (event.target.id != "") {
            id = event.target.id;
        } else if (event.target.parentElement.id != "") {
            id = event.target.parentElement.id;
        } else if (event.target.parentElement.parentElement.id != "") {
            id = event.target.parentElement.parentElement.id;
        }
        if (id != null) {
            setPollDetail(prevState => ({
                ...prevState,
                [id]: !prevState[id]
            }))
            // if (id == "announceResultAutomatically") {
            //     setPollDetail(prevState => ({
            //         ...prevState,
            //         discloseVoterInResult: false
            //     }))
            // }
            setTouched(prevState => ({
                ...prevState,
                [id]: true,
            }))
        }
    }

    const handleDateChange = (fieldName, selectedDate) => {
        if (selectedDate != null && selectedDate !== "") {
            setPollDetail(prevState => ({
                ...prevState,
                [fieldName]: selectedDate
            }));
            if (fieldName == 'startTime') {
                setPollDetail(prevState => ({
                    ...prevState,
                    alertTime: dayjs.utc(new Date(selectedDate - (10 * 60000)))
                }));
            }
        } else {
            setPollDetail(prevState => ({
                ...prevState,
                [fieldName]: null
            }));
        }
        setTouched(prevState => ({
            ...prevState,
            [fieldName]: true,
        }))
    };

    const handleBlur = (name, value) => {
        setErrors(prevState => ({
            ...prevState,
            [name]: ''
        }))
        const error = validate[name](value);
        setErrors(prevState => ({
            ...prevState,
            ...(error && { [name]: touched[name] && error })
        }))
        if (name === 'startTime') {
            handleBlur('alertTime', value);
        }
    }

    const validateForm = async () => {
        setErrors({})
        setTouched({})
        let formErrors = { errors: {}, touched: {} };
        Object.entries(pollDetail).map(([key]) => {
            if (key === 'title' || key === 'description' || key === 'startTime' || key === 'endTime' || key === 'alertTime' ||
                key === 'pollCategory') {
                const newError = validate[key](pollDetail[key]);
                const newTouched = { [key]: true };
                return formErrors = {
                    errors: {
                        ...formErrors.errors,
                        ...(newError && { [key]: newError }),
                    },
                    touched: {
                        ...formErrors.touched,
                        ...newTouched
                    }
                }
            }
        })
        setErrors(formErrors.errors);
        setTouched(formErrors.touched);
        return formErrors;
    }

    const createPoll = (event) => {
        event.preventDefault();
        validateForm().then(data => {
            if (Object.values(data.errors).length === 0) {
                setLoading(true);
                let payload = {
                    referenceNumber: pollDetail.referenceNumber,
                    title: pollDetail.title,
                    description: pollDetail.description,
                    showResultsToVoters: pollDetail.showResultsToVoters,
                    weightedVotes: pollDetail.weightedVotes,
                    geoTagging: pollDetail.geoTagging,
                    geoFencing: pollDetail.geoFencing,
                    questionShuffle: pollDetail.questionShuffle,
                    anonymousVoting: pollDetail.anonymousVoting,
                    websiteRedirect: pollDetail.websiteRedirect,
                    discloseVoterInResult: pollDetail.discloseVoterInResult,
                    status: null,
                    startTime: pollDetail.startTime,
                    endTime: pollDetail.endTime,
                    resultPostOnPollClose: pollDetail.resultPostOnPollClose,
                    startManually: pollDetail.startManually,
                    alertTime: pollDetail.alertTime,
                    alerted: pollDetail.alerted,
                    voteWithSignUp: pollDetail.voteWithSignUp,
                    announceResultAutomatically: pollDetail.announceResultAutomatically,
                    allowVoteChange: pollDetail.allowVoteChange,
                    scrutinizerHasToOpen: pollDetail.scrutinizerHasToOpen,
                    displayOrganizerName: pollDetail.displayOrganizerName,
                    verifyWithOtp: pollDetail.verifyWithOtp,
                    organizers: [{
                        personId: pollDetail.organizerPersonId,
                    }],
                    pollType: pollDetail.pollType,
                    conductFor: {
                        personId: pollDetail.conductForPersonId
                    },
                    createdOn: pollDetail.createdOn,
                    realTimeResult: pollDetail.realTimeResult,
                    pollCategory: pollDetail.pollCategory,
                    secreteBallot: pollDetail.secreteBallot,
                    zoneId: pollDetail.zoneId,
                    zoneDisplayName: pollDetail.zoneDisplayName
                    // organizer_name: pollDetail.organizer_name,
                    // organizer_email: pollDetail.organizer_email,
                    // organizer_phonenumber: pollDetail.organizer_phonenumber
                }

                createPollService(payload)
            }
        })
    }

    const updatePoll = (event) => {
        event.preventDefault();
        validateForm().then(data => {
            if (Object.values(data.errors).length === 0) {
                setLoading(true);
                let payload = {
                    id: pollDetail.id,
                    referenceNumber: pollDetail.referenceNumber,
                    title: pollDetail.title,
                    description: pollDetail.description,
                    showResultsToVoters: pollDetail.showResultsToVoters,
                    weightedVotes: pollDetail.weightedVotes,
                    geoTagging: pollDetail.geoTagging,
                    geoFencing: pollDetail.geoFencing,
                    questionShuffle: pollDetail.questionShuffle,
                    anonymousVoting: pollDetail.anonymousVoting,
                    websiteRedirect: pollDetail.websiteRedirect,
                    discloseVoterInResult: pollDetail.discloseVoterInResult,
                    startTime: pollDetail.startTime,
                    endTime: pollDetail.endTime,
                    resultPostOnPollClose: pollDetail.resultPostOnPollClose,
                    startManually: pollDetail.startManually,
                    alertTime: pollDetail.alertTime,
                    alerted: pollDetail.alerted,
                    voteWithSignUp: pollDetail.voteWithSignUp,
                    announceResultAutomatically: pollDetail.announceResultAutomatically,
                    allowVoteChange: pollDetail.allowVoteChange,
                    scrutinizerHasToOpen: pollDetail.scrutinizerHasToOpen,
                    displayOrganizerName: pollDetail.displayOrganizerName,
                    verifyWithOtp: pollDetail.verifyWithOtp,
                    pollType: pollDetail.pollType,
                    conductFor: {
                        personId: pollDetail.conductForPersonId
                    },
                    realTimeResult: pollDetail.realTimeResult,
                    pollCategory: pollDetail.pollCategory,
                    secreteBallot: pollDetail.secreteBallot,
                    zoneId: pollDetail.zoneId,
                    zoneDisplayName: pollDetail.zoneDisplayName
                }
                updatePollService(payload);
            }
        })

    }

    const handleCancel = () => {
        props.handleCancel();
    }

    const handleNext = () => {
        props.handleNext();
    }

    const setOrganizerDetails = (person) => {
        setPollDetail(prevState => ({
            ...prevState,
            organizerPersonId: person.personId,
            organizerName: person.fullName,
            organizerEmail: person.preferredEmail,
            organizerPhoneNumber: person.preferredPhoneNumber
        }));
    }

    // useEffect(() => {
    //     if (pollDetail.allowVoteChange === true) {
    //         setPollDetail(prevState => ({
    //             ...prevState,
    //             realTimeResult: false
    //         }))
    //     }
    // }, [pollDetail.allowVoteChange])


    return (
        <Fragment>
            <Card className={classes.boxShadowCard}>
                <CardContent className={classes.cardContent}>
                    {(context.userSession.session.roles.includes(UserRole.Role_Support) ||
                    context.userSession.session.roles.includes(UserRole.Role_Admin)) &&
                        <Fragment>
                            <fieldset style={{ borderRadius: '5px'}}>
                                <legend align="left"><strong>  &nbsp;&nbsp;{t('Organizer_Details')}&nbsp;<span style={{color:'red', fontSize: '0.8rem'}}>*</span>&nbsp;  </strong></legend><br />
                                <PollOrganizerDetail
                                    pollDetail={pollDetail} handleInputChange={onInputChange}
                                    Blur={handleBlur} errors={errors} touched={touched}
                                    onSelect={setOrganizerDetails} setMessage={setMessage}
                                />
                            </fieldset>
                            <br/>
                        </Fragment>
                    }
                    <PollRoundDetail
                        pollDetail={pollDetail}
                        onJsonInputChange={onJsonInputChange} onInputChange={onInputChange}
                        handleDataBoolean={handleDataBoolean} handleDateChange={handleDateChange}
                        handleBlur={handleBlur} errors={errors} touched={touched}
                        style={{padding: '10px !important'}}  onInputChangeByName={onInputChangeByName}
                        hasSystemAdmineOrSupportRole={(context.userSession.session.roles.includes(UserRole.Role_Support) ||
                            context.userSession.session.roles.includes(UserRole.Role_Admin))}
                    />
                </CardContent>
                <CardActions className={classes.actions}>
                    <Grid item xs={12}>
                        {pollDetail.id === '' && <Button id="create-poll" type="submit" size="small" variant="contained" color="primary"
                            className={classes.nextBtn} onClick={createPoll}>
                            {t('Save_&_Next')}
                        </Button>}
                        {pollDetail.id !== '' && <Button id="update-poll" type="submit" size="small" variant="contained" color="primary"
                            className={classes.nextBtn} onClick={updatePoll}>
                            {t('Update_&_Next')}
                        </Button>}
                        {pollDetail.id !== '' && <Button id="next-poll" type="submit" size="small" variant="contained" color="primary"
                            className={classes.nextBtn} onClick={handleNext}>
                            {t('Next Page')}
                        </Button>}
                        {pollDetail.id === '' && <Button id='cancel-poll' type="submit" size="small" variant="outlined" color="primary"
                            className={classes.nextBtn} onClick={handleCancel}>
                            {t('Cancel')}
                        </Button>
                        }
                        {pollDetail.id !== '' && <Button id='close-poll' type="submit" size="small" variant="contained"
                            className={classes.nextBtn} onClick={handleCancel}>
                            {t('Close')}
                        </Button>
                        }
                    </Grid>
                </CardActions>
                {loading && <EligoBackdrop show={loading} position='fixed'/>}
            </Card>
            {message.showMsg && <EligoSnackBar show={message.showMsg} message={message.message}
                severity={message.severity}></EligoSnackBar>}
        </Fragment>
    )
}

export default AddPollRound;