// import Autocomplete from '@material-ui/lab/Autocomplete';
import { CircularProgress, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AxiosEvotingInterceptor } from '../../../config/axios.interceptor';
import Autocomplete from "@mui/material/Autocomplete";

const SearchVoter = (props) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [searchFilter, setSearchFilter] = useState('');
    const [options, setOptions] = useState([]);
    const [selectedUser, setSelectedUser] = useState('');
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState('email')
    const [userType, setUSerType] = useState(true)

    useEffect(() => {
        if (searchFilter != '' && open) {
            const payload = {
                searchBy: value,
                searchText: searchFilter.trim(),
                individual: userType,
                loginUser: true
            }
            AxiosEvotingInterceptor.post(`search/user`, payload).then(response => {
                setOptions(Object.keys(response).map((key) => {
                    return {
                        id: response[key].id,
                        name: response[key].name,
                        phoneNumber: response[key].phoneNumber,
                        email: response[key].email,
                        countryCode: response[key].phoneDetail.countryCode,
                        nationalNumber: response[key].phoneDetail.nationalNumber

                    }
                }));
                setLoading(false);
            }).catch((error) => {
                setLoading(false)
            })
        }
    }, [searchFilter]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    useEffect(() => {
        if (selectedUser != '' && selectedUser != null) {
            setSearchFilter(selectedUser.name.trim());
            props.onSelect(selectedUser);
        } else {
            setSearchFilter('');
            props.onSelect('');
        }
    }, [selectedUser]);

    const handleClick = (event, newValue) => {
        setValue(newValue === null ? value : newValue);
        setSearchFilter('');
        props.onSelect('');
    };

    const handleClickType = (event, newValue) => {
        setUSerType(newValue === null ? userType : newValue);
    };

    const onChangeFilter = (e) => {
        if (props.MultiVoterAuthrep) {
            props.setAuthrepNameEdit(false)
            props.addMultiVoters(e, props.index, 'name')
        }
        setSearchFilter(e.target.value)
    }

    return (
        <div>
            {props.roleSearch && <ToggleButtonGroup
                size="small"
                color='primary'
                value={value}
                style={{ float: 'right' }}
                exclusive
                onChange={handleClick}
                aria-label="text value"
                id='toggle'
            >
                <ToggleButton value="email" aria-label="left aligned" id='email'>
                    <Typography>email</Typography>
                </ToggleButton>
                <ToggleButton value="name" aria-label="centered" id="name">
                    <Typography>name</Typography>
                </ToggleButton>
            </ToggleButtonGroup>}
            <Autocomplete
                id="search-voter"
                open={open}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                size={props.size ? props.size : ''}
                fullWidth={props.fullWidth}
                style={{ marginTop: props.marginTop ? props.marginTop : '' }}
                disableClearable={props.clearButton === false ? false : true}
                onChange={(event, data) => {
                    setSelectedUser(data);
                }}
                getOptionLabel={(option) => option.name + ' (' + option.email + ') '}
                options={options}
                loading={loading}
                inputValue={props.searchFilterName ? props.searchFilterName : searchFilter}
                disabled={props.disable ? props.disable : false}
                renderInput={(params) => (
                    <TextField {...params}
                        onChange={onChangeFilter}
                        // size='small'
                        variant={props.variant ? props.variant : 'standard'}
                        required={props.required}
                        value={props.searchFilterName ? props.searchFilterName : searchFilter}
                        style={{ width: props.width ? props.width : '' }}
                        error={props.error}
                        placeholder={props.placeholder ? props.placeholder : ''}
                        onBlur={(e) => props.setName ? props.setName(e.target.value) : {}}
                        helperText={props.helperText ? (props.helperText === 'placeholder' ? '' : props.helperText) : value === 'email' ? t("Search_By_Email_For_Existing_Users") : t("Search_By_Name_For_Existing_Users")}
                        label={props.label ? props.label : t("User")}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {(loading) ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                            startAdornment: (
                                props.InputProps
                            )
                        }}
                    />
                )}
            />
        </div>
    )
}

export default SearchVoter;
