import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Btns from '../../common/Btns';
import EligoBackdrop from '../../common/EligoBackdrop';
import EligoConfirmDialog from '../../common/EligoConfirmDialog';
import EligoSnackBar from '../../common/EligoSnackBar';
import { Status } from '../../common/GenericCodes';
import { AxiosEvotingInterceptor } from '../../config/axios.interceptor';

const DeletePoll = (props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false)
    const [pollError, setPollError] = useState('');
    const [seekConfirmation, setSeekConfitmation] = useState({
        show: false,
        title: t("Confirmation"),
        message: '',
        onAgree: '',
        onDisAgree: ''
    })

    const deletePollRecord = () => {
        setLoading(true)
        resetConfirmation();
        AxiosEvotingInterceptor.patch(`poll/${props.pollDetails.id}/${Status.canceled.toUpperCase()}`, {}).then(response => {
            if (response) {
                props.setMessage({ show: false, message: '', severity: '' })
                props.setMessage({
                    show: true,
                    message: t("Poll_Canceled_Successfully"),
                    severity: "success"
                })
                if (props.pollLength.length === 1) {
                    props.setPage(props.page - 1)
                } else {
                    props.getPollList()
                }
                setLoading(false)
            }
        }).catch((error) => {
            setLoading(false)
            setPollError(error.message)
        })
    }

    const confirmaion = () => {
        setSeekConfitmation(prevState => ({
            ...prevState,
            show: true,
            message: <Fragment>{t('Are_You_Sure_That_You_Want_To')} <b>{t('Cancel')}</b> {t('poll')} <b>{props.pollDetails.title}</b> {t('?')}</Fragment>,
            onAgree: deletePollRecord,
            onDisAgree: resetConfirmation,
        }))
    }

    const resetConfirmation = () => {
        setSeekConfitmation(prevState => ({
            ...prevState,
            show: false,
            title: t("Confirmation"),
            message: '',
            onDisAgree: '',
            onAgree: ''
        }))
    }

    return (
        <Fragment>
            <Btns id='close' tooltip={t('Cancel')} label="delete-poll" color="secondary" style={{ float: 'right' }} btn={<><DeleteIcon /></>} click={confirmaion} />
            {loading && <EligoBackdrop show={true} />}
            {seekConfirmation.show && <EligoConfirmDialog show={seekConfirmation.show} ConfirmFor={true}
                title={seekConfirmation.title} message={seekConfirmation.message}
                onAgree={seekConfirmation.onAgree} onDisAgree={seekConfirmation.onDisAgree} />}
            {pollError !== '' && pollError !== undefined &&
                <EligoSnackBar show={true} message={pollError} severity="error" reset={() => setPollError(null)} />}
        </Fragment>
    )
}

export default DeletePoll

