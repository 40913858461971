var TelephoneCodes = [
    {countryName: 'India', countryCode: 'IN', telephoneCode: 91}
]

var GenericCodeNodeLabels =
    {pollCategory: 'poll_category'}

var GenericCodeNodeLablesList = [
    {label: 'poll_category', labelName: 'Poll Category'},
    {label: 'country', labelName: 'Country'},
    {label: 'language', labelName: 'Language'},
]

var Roles = {
    organizer: 'organizer',
    voter: 'voter',
    scrutinizer: 'scrutinizer',
    observer: 'observer'
}

var Status = {
    open: 'open',
    closed: 'closed',
    canceled: 'cancelled',
    upcoming: 'upcoming',
    new: 'new'
}

const DefaultTelephoneAreaCode = {
    CANADA: '416'
}

export {TelephoneCodes, GenericCodeNodeLabels, GenericCodeNodeLablesList, Roles, Status, DefaultTelephoneAreaCode};