import { Button, Container, makeStyles, Tooltip } from '@material-ui/core';
import { Stack } from '@mui/material';
import axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import EligoBackdrop from '../../../common/EligoBackdrop';
import EligoSnackBar from '../../../common/EligoSnackBar';
import '../CastVote.css';
import StepperView from '../StepperView';
import QuestionOption from './QuestionOption';

const useStyles = makeStyles((theme) => ({
    width: {
        maxWidth: '1500px !important'
    },
    btn: {
        boxShadow: '5px 5px 4px rgba(0,0,0,0.3)',
        color: 'blue',
        background: 'white',
        border: '1.8px solid blue',
    },
    cancel: {
        boxShadow: '5px 5px 4px rgba(0,0,0,0.3)',
        color: 'red',
        background: 'white',
        border: '1.5px solid red',
        marginRight: '0.8rem'
    },
}));

const getPollResopnseArray = (responseArray) => {
    let response = [];
    responseArray.map(e => {
        response.push({
            option: e.option,
            poll_question_response_id: e.poll_question_response_id
        })
    })
    return response;
}


const Questions = (props) => {
    const { pollDetails, onlyVoter, page, roles, pollType, otpLogin, userId, voterEdgeIdForSingleVoter, AuthrepIdForSingleVoter, VoterIdForsingleVoter,
        authRepId, voterEdgeId, voterIdInList, authorisedPersonId, cognitoUserId } = props
    let history = useHistory();
    const classes = useStyles()
    const { t } = useTranslation();
    const [questions, setQuestions] = useState([])
    const [questOptions, setOptions] = useState([])
    const [len, setLen] = useState(0)
    const [error, setError] = useState(false)
    const [scroll, setScroll] = useState(false)
    const [loading, setLoading] = useState(false)
    const [castVotes, setCastVote] = useState(false)
    const [optionId, setOptionId] = useState('')
    const [afterResp, setAfterResp] = useState(false)
    const [updatedQuest, setUpdatedQuest] = useState([])
    const [message, setMessage] = useState({
        showMsg: false,
        message: null,
        severity: null
    })

    const cancelVote = () => {
        props.voteVar(false)
        // history.push({
        //     pathname: "/poll/cast-vote",
        //     state: {
        //         // pollDetails:props.pollDetails,
        //         // currentPage: props.page,
        //         // roles: props.roles,
        //         pollDetails: pollDetails,
        //         otpLogin: otpLogin,
        //         userId: userId,
        //         authorisedPersonId: authorisedPersonId,

        //         authRepId: authRepId,
        //         voterEdgeId: voterEdgeId,
        //         voterIdInList: voterIdInList,
        //         cognitoUserId: cognitoUserId,
        //         voterEdgeIdForSingleVoter: voterEdgeIdForSingleVoter,
        //         AuthrepIdForSingleVoter: AuthrepIdForSingleVoter,
        //         VoterIdForsingleVoter: VoterIdForsingleVoter,
        //         onlyVoter: onlyVoter
        //     }
        // })
    }

    useEffect(() => {
        if (otpLogin) {
            setLoading(true)
            axios({
                url: `${process.env.REACT_APP_AWS_GATEWAY_POLL_QUESTION_API_URL}/questions/${pollDetails.poll_id}/${userId}`,
                method: 'GET'
            }).then((response) => {
                setLoading(false)
                setUpdatedQuest(response.data.pollQuestions);
                setAfterResp(false)
                if (!castVotes) {
                    setQuestions(response.data.pollQuestions)
                    setAllQuestions(response.data.pollQuestions)
                }
            }).catch((error) => {
            })
        } else {
            setLoading(true)
            axios({
                url: `${process.env.REACT_APP_AWS_GATEWAY_POLL_QUESTION_API_URL}/questions/${pollDetails.poll_id}/${onlyVoter ? cognitoUserId : voterIdInList ? voterIdInList : VoterIdForsingleVoter}`,
                method: 'GET'
            }).then((response) => {
                setLoading(false)
                setUpdatedQuest(response.data.pollQuestions);
                setAfterResp(false)
                if (!castVotes) {
                    setQuestions(response.data.pollQuestions)
                    setAllQuestions(response.data.pollQuestions)
                }
            }).catch((error) => {
            })
        }
    }, [])

    const setAllQuestions = (quest) => {
        quest.map(e => {
            setOptions(prevState => ([
                ...prevState,
                {
                    question: e.question,
                    poll_question_id: e.poll_question_id,
                    selected_response_id: e.selected_response_id,
                    question_description: e.question_description,
                    PollQuestionResponses: getPollResopnseArray(e.PollQuestionResponses),
                    threshold: e.threshold,
                    weightage: e.weightage
                }
            ]))
        })
    }

    const setReview = (e) => {
        setScroll(true)
        setTimeout(() => {
            setScroll(false)
        }, 1000)

        const response = []
        updatedQuest.map((value) => {
            return response.push(value.selected_response_id)

        })
        if (response.includes('')) {
            setError(true)
        } else {
            setError(false)
            props.setReviewVotes(true)
            // history.push({
            //     pathname: '/polls/details/review-vote',
            //     state: {
            //         limit: pollDetails.number_of_question,
            //         page: page,
            //         pollDetails: pollDetails,
            //         poll_id: pollDetails.poll_id,
            //         roles: roles,
            //         pollType: pollType,
            //         canModify: true,
            //         redirectToRounds: false,
            //         otpLogin: otpLogin,
            //         userId: userId,
            //         questionss: questions,
            //         questionsLength: pollDetails.number_of_question,
            //         response: response,

            //         authRepId: authRepId,
            //         VoterIdForsingleVoter: VoterIdForsingleVoter,
            //         AuthrepIdForSingleVoter: AuthrepIdForSingleVoter,
            //         voterEdgeIdForSingleVoter: voterEdgeIdForSingleVoter,
            //         cognitoUserId: cognitoUserId,
            //         onlyVoter: onlyVoter,
            //         voterIdInList: voterIdInList
            //     },
            // })
        }


    }

    const cast = (value) => {
        setOptionId(value)
        setCastVote(true)
        // resetConfirmation();
        if (otpLogin) {
            // setLoading(true);
            axios({
                url: `${process.env.REACT_APP_AWS_GATEWAY_POLL_VOTER_API_URL}/cast_vote/${value}/${userId}/${authorisedPersonId}`,
                method: 'POST'
            }).then((response) => {
                axios({
                    url: `${process.env.REACT_APP_AWS_GATEWAY_POLL_QUESTION_API_URL}/questions/${pollDetails.poll_id}/${userId}`,
                    method: 'GET'
                }).then((response) => {
                    setUpdatedQuest(response.data.pollQuestions);

                }).catch((error) => {
                })
                // setUpdatedQuest(response)
            }).catch((error) => {
                // setLoading(false);
                //TODO
            })
        } else {
            setCastVote(true)
            setLoading(true)
            // castVote({
            //     variables: {
            //         poll_question_response_id: value,
            //     }
            // })
            axios({
                url: `${process.env.REACT_APP_AWS_GATEWAY_POLL_VOTER_API_URL}/cast_vote/${value}/${onlyVoter ? cognitoUserId : voterIdInList ? voterIdInList : VoterIdForsingleVoter}/${onlyVoter ? 'None' : cognitoUserId}`,
                method: 'POST'
            }).then((response) => {
                axios({
                    url: `${process.env.REACT_APP_AWS_GATEWAY_POLL_QUESTION_API_URL}/questions/${pollDetails.poll_id}/${onlyVoter ? cognitoUserId : voterIdInList ? voterIdInList : VoterIdForsingleVoter}`,
                    method: 'GET'
                }).then((response) => {
                    setUpdatedQuest(response.data.pollQuestions)
                    // props.voted(true)
                    setLoading(false)

                }).catch((error) => {
                    console.log("error", error)
                })
                // setUpdatedQuest(response)
            }).catch((error) => {
                // setLoading(false);
                //TODO
            })
        }
    }

    return (
        <Container>
            {/* <StepperView questionStepper={true} details={pollDetails} pollDetails={pollDetails}
                title="Poll Ends In"
                date={pollDetails.end_date_time}
                otpLogin={otpLogin}
                userId={userId}
                setReview={setReview} /> */}
            {questions.length > 0 &&
                <Fragment>
                    <Stack direction={{ xs: 'column', sm: 'column', md: 'column', lg: 'row' }}
                        justifyContent='space-between' alignItems='center' spacing={2} width='100%'>
                        <strong className='matter'>{t('Matter_For_VOte')}  - {pollDetails.number_of_question}</strong>
                        <Stack direction={{ xs: 'row' }} height='fit-content' style={{ marginBottom: '10px' }}>
                            <Button size="small" className={classes.cancel} onClick={cancelVote}>
                                {t("Back")}
                            </Button>
                            {questions.length > 0 &&
                                <Tooltip title={t("Click_here_to_review_your_Votes_before_confirming")}>
                                    <Button size="small" className={classes.btn} onClick={setReview}>
                                        {t("Review_Your_Vote")}
                                    </Button>
                                </Tooltip>}
                        </Stack>
                    </Stack>
                    {
                        questOptions.map((selected, index) => {
                            return (
                                <QuestionOption selected={selected} index={index} poll_id={pollDetails.poll_id}
                                    poll_question_id={questions[len].poll_question_id}
                                    otpLogin={otpLogin}
                                    pollDetails={pollDetails}
                                    userId={userId}
                                    confirm={cast} setOptionId={setOptionId} selected_option={setOptionId}
                                    isError={error} setError={setError} scroll={scroll} setScroll={setScroll}
                                />
                            )
                        })
                    }
                    <Stack justifyContent='flex-end' alignItems='flex-end' spacing={2} direction='row' mb={2}>
                        <div className='vote-button'>
                            <Button size="small" className={classes.cancel} onClick={cancelVote}>
                                {t("Back")}
                            </Button>
                            {questions.length > 0 &&
                                <Tooltip title={t("Click_here_to_review_your_Votes_before_confirming")}>
                                    <Button size="small" className={classes.btn} onClick={setReview}>
                                        {t("Review_Your_Vote")}
                                    </Button>
                                </Tooltip>}
                        </div>
                    </Stack>

                </Fragment>
            }
            {
                message.showMsg && <EligoSnackBar show={message.showMsg} message={message.message}
                    severity={message.severity} />
            }
            {(loading) && <EligoBackdrop show={( loading)} invisible={false} />}
        </Container>
    )
}

export default Questions;

