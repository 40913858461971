import { Button, Chip, Dialog, Grid, IconButton, InputAdornment, Paper, TextField, Tooltip, styled } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EligoBackdrop from '../../common/EligoBackdrop';
import EligoReCaptcha from '../../common/EligoReCaptcha';
import EligoSnackBar from '../../common/EligoSnackBar';
import { UserContext } from '../../common/context/UserContext';
import * as Validators from '../../common/form-validators.js';
import { AuthAxiosInterceptor } from '../../config/axios.interceptor';
import Password from '../forgot-password/Password';
import ChangePasswordOtp from './ChangePasswordOtp';

const useStyles = makeStyles(() => ({
    card: {
        padding: '40px',
        margin: '30px',
        borderRadius: '10px',
        display: 'flex'
    }
}))

function ChangePassword(props) {
    const classes = useStyles();
    const context = useContext(UserContext);
    const { t } = useTranslation();
    const [open, setOpen] = useState(props.open)
    const [loading, setLoading] = useState(false)
    const [openPwd, setOpenPwd] = useState(false)
    const [pwdLength, setPwdLength] = useState(false);
    const [isUpper, setIsUpper] = useState(false);
    const [isLower, setIsLower] = useState(false);
    const [isNumber, setIsNumber] = useState(false);
    const [isSpecial, setIsSpecial] = useState(false);
    const [otpOpen, setOtpOpen] = useState(false)
    const [restPwd, setResetPwd] = useState({
        otpVal: '',
        errorMessage: '',
        showErrorMessage: false,
        showConfirmation: false,
        loading: false,
        showOldPassword: false,
        showNewPassword: false,
        showConfirmPassword: false,
        oldPassword: '',
        newPassword: '',
        confirmpassword: '',
        isValidForm: false
    })
    const [message, setMessage] = useState({
        showMsg: false,
        message: '',
        severity: ''
    })
    const [errors, setErrors] = useState({})
    const [captcha, setCaptcha] = useState('');
    const [isRefresh, setIsRefresh] = useState(false);

    const GreenChip = styled(Chip)(() => ({
        background: 'linear-gradient(195deg, rgb(102, 187, 106), rgb(67, 160, 71))',
        color: 'rgb(255, 255, 255)',
        padding: '20px',
        borderRadius: '50px',
        fontSize: '20px',
        width: '40%',
        margin: '10px',
        alignSelf: 'center',
        boxShadow: 'rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(64 64 64 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem !important',

    }))

    const validate = {
        newPassword: newPassword => Validators.requiredValidation('newPassword', newPassword),
        confirmpassword: confirmpassword => (Validators.requiredValidation('Confirm Password', confirmpassword)
            || Validators.equalValidation('Confirm Password', restPwd.newPassword, confirmpassword))
    };

    const NumberCheck = new RegExp(/[0-9]/);
    const UpperLetter = new RegExp(/[A-Z]/);
    const LowerLetter = new RegExp(/[a-z]/);
    const SpecialChar = new RegExp(/[^A-Z a-z 0-9]/);
    const MinPwdLength = 7;

    const handleClose = () => {
        setOpen(false)
        props.dialog(false)
    }

    const setData = (id, value) => {
        setResetPwd(prevState => ({
            ...prevState,
            [id]: value
        }))
    }

    const toggleShowNewPassword = () => {
        setData('showNewPassword', !restPwd.showNewPassword)
    }

    const toggleShowConfirmPassword = () => {
        setData('showConfirmPassword', !restPwd.showConfirmPassword)
    }

    const passwordChange = (id, pwdValue) => {
        setOpenPwd(true)
        setResetPwd(prevState => ({
            ...prevState,
            [id]: pwdValue
        }))
        setPwdLength(pwdValue.length > MinPwdLength ? true : false);
        setIsUpper(UpperLetter.test(pwdValue) ? true : false);
        setIsLower(LowerLetter.test(pwdValue) ? true : false);
        setIsNumber(NumberCheck.test(pwdValue) ? true : false);
        setIsSpecial(SpecialChar.test(pwdValue) ? true : false);
    }

    const onInputClose = () => {
        setOpenPwd(false)
    }

    const handlePasswordSubmit = (event) => {
        event.preventDefault();
        changePassword();
    }

    const changePassword = async () => {
        let newPassword = restPwd.newPassword;
        setMessage({ showMsg: false, message: '', severity: '' })
        setErrors({})
        const payload = {
            loginId: context.userSession.session.email,
            password: newPassword
        }
        validateForm().then(data => {
            if (Object.values(data.errors).length == 0) {
                if (!validateNewPwd(newPassword)) {
                    setLoading(true);
                    AuthAxiosInterceptor.patch(`user/request-otp?recaptcha=${captcha}&purpose=reset-password`, payload).then(response => {
                        setLoading(false);
                        setMessage({ showMsg: true, message: t('Otp_Has_Been_Sent_Successfully'), severity: 'success' });
                        setOtpOpen(true)
                    }).catch((error) => {
                        setLoading(false);
                        setIsRefresh(true);
                        setMessage({ showMsg: false, message: '', severity: '' });
                        setTimeout(() => {
                            setIsRefresh(false);
                        }, 1000);
                        if (error.message) {
                            setMessage({ showMsg: true, message: error.message, severity: 'error' });
                        }
                    })
                }
            }
        })
    }

    const validateForm = async () => {
        setErrors({})
        let formErrors = { errors: {}, touched: {} };
        Object.entries(restPwd).map(([key]) => {
            if (key === 'newPassword' || key === 'confirmpassword') {
                const newError = validate[key](restPwd[key]);
                const newTouched = { [key]: true };
                return formErrors = {
                    errors: {
                        ...formErrors.errors,
                        ...(newError && { [key]: newError }),
                    },
                    touched: {
                        ...formErrors.touched,
                        ...newTouched
                    }
                }
            }
        })
        setErrors(formErrors.errors);
        return formErrors;
    }

    const validateNewPwd = (newPassword) => {
        if (newPassword) {
            setMessage({ showMsg: false, message: '', severity: '' })
            setTimeout(() => {
                if (newPassword.length >= 8) {
                    if (LowerLetter.test(newPassword)) {
                        if (SpecialChar.test(newPassword)) {
                            if (NumberCheck.test(newPassword)) {
                                if (UpperLetter.test(newPassword)) {
                                    return null
                                } else {
                                    setMessage({ showMsg: true, message: 'Password must contain atleast one Uppercase character', severity: 'error' })
                                }
                            } else {
                                setMessage({ showMsg: true, message: 'Password must contain atleast one Number ', severity: 'error' })
                            }
                        } else {
                            setMessage({ showMsg: true, message: 'Password must contain atleast one Special character', severity: 'error' })
                        }
                    } else {
                        setMessage({ showMsg: true, message: 'Password must contain atleast one Lowercase character', severity: 'error' })
                    }
                } else {
                    setMessage({ showMsg: true, message: 'Password must contain atleast  8 characters', severity: 'error' })
                }
            }, 100)
        }
    }

    return (
        <div>
            <Dialog fullWidth open={open} PaperProps={{
                style: {
                    position: 'inherit'
                },
            }}>
                <GreenChip label='Change Password' />
                <Tooltip title='Close'>
                    <IconButton id='close-dialog' onClick={handleClose} style={{ marginLeft: "547px", marginTop: "-55px", padding: "10px", color: 'red' }}>
                        <CloseIcon />
                    </IconButton>
                </Tooltip>
                <Paper className={classes.card} elevation={2} >
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            <TextField id="newPassword" label='New Password' variant='outlined' fullWidth
                                value={restPwd.newPassword}
                                autoComplete="new-password"
                                onChange={(event) => passwordChange('newPassword', event.target.value)}
                                onBlur={onInputClose}
                                helperText={errors.newPassword}
                                error={errors.newPassword}
                                type={restPwd.showNewPassword ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={toggleShowNewPassword}>
                                                {restPwd.showNewPassword ? <Visibility id='visible' /> : <VisibilityOff id='visiblepwd' />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />&nbsp;<br />

                            {openPwd && <Password open={openPwd} pwdLength={pwdLength} isUpper={isUpper} isLower={isLower} isNumber={isNumber} isSpecial={isSpecial} />}

                            <TextField id='confirmpassword' label='Confirm Password' variant='outlined' fullWidth
                                value={restPwd.confirmpassword}
                                onChange={(event) => setData('confirmpassword', event.target.value)}
                                type={restPwd.showConfirmPassword ? 'text' : 'password'}
                                helperText={errors.confirmpassword}
                                error={errors.confirmpassword}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={toggleShowConfirmPassword} >
                                                {restPwd.showConfirmPassword ? <Visibility id='confimvisible' /> : <VisibilityOff id='visibleconfirmpwd' />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />&nbsp;<br />
                            <Button variant='contained' if='request-otp' color='primary' style={{ float: 'right' }} fullWidth onClick={handlePasswordSubmit}
                                disabled={!restPwd.newPassword || !restPwd.confirmpassword}>Request OTP</Button>
                        </Grid>
                    </Grid>
                </Paper>
                {loading && <EligoBackdrop show={loading} />}
                {message.showMsg && <EligoSnackBar show={true} message={message.message} severity={message.severity} />}
            </Dialog>
            {otpOpen && <ChangePasswordOtp open={otpOpen} closeOtp={setOtpOpen} newPassword={restPwd.newPassword} message={message} email={props.email} dialog={props.dialog} setMessage={props.setMessage} />}
            {!isRefresh && <EligoReCaptcha captcha={setCaptcha} refresh={setIsRefresh} />}
        </div>
    )
}

export default ChangePassword