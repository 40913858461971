import CryptoJS         from 'crypto-js';

let encryptedBase64Key = CryptoJS.enc.Utf8.parse(`0123456789abcdef`);

const encrypt = (stringToEncrypt) => {
    if (process.env.REACT_APP_ENV === 'production') {
        let text = CryptoJS.enc.Utf8.parse(stringToEncrypt);
        var encrypted = CryptoJS.AES.encrypt(text, encryptedBase64Key, { 
            mode: CryptoJS.mode.ECB, 
            padding: CryptoJS.pad.Pkcs7 
        }); 
        encrypted = encrypted.ciphertext.toString(CryptoJS.enc.Base64);
        return encrypted;
    } else {
        try {
            return JSON.parse(stringToEncrypt)
        } catch {
            return stringToEncrypt
        }
    }
    
}

const decrypt = (stringToDecrypt) => { 
    if (process.env.REACT_APP_ENV === 'production') {
        var decrypted = CryptoJS.AES.decrypt({ciphertext: CryptoJS.enc.Base64.parse(stringToDecrypt)}, 
                encryptedBase64Key, {
            mode: CryptoJS.mode.ECB, 
            padding: CryptoJS.pad.Pkcs7 
        })
        decrypted = decrypted.toString(CryptoJS.enc.Utf8);
        try {
            return JSON.parse(decrypted)
        } catch {
            return decrypted
        } 
    } else {
        try {
            return JSON.parse(stringToDecrypt)
        } catch {
            return stringToDecrypt
        }
    }
}

export { encrypt, decrypt }