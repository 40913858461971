import React, { useState, useContext } from 'react';
import {
    makeStyles,
    Card,
    Container,
    Typography,
    Grid,
    Button,
    Stepper,
    Step,
    StepLabel,
    Paper
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../common/context/UserContext';
import PaymentSuccess from '../payment-success/PaymentSuccess';
import Pricing from '../pricing/Pricing';
import GatewaySelection from '../payment-gateway/GatewaySelection';
import EligoConfirmDialog from '../../common/EligoConfirmDialog';
import EligoSnackBar from '../../common/EligoSnackBar';
const Razorpay = require("razorpay");

const cusotmBoxShadow =
    '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 275,
        paddingLeft: '5vw',
        paddingRight: '5vw',
        height: '70vh'
    },
    priceCard: {
        margin: '8px'
    },
    cardHeader: {
        padding: '0'
    },
    cardContent: {
        padding: '16px'
    },
    title: {
        fontSize: 24,
    },
    amount: {
        fontSize: 36,
    },
    gst: {
        fontSize: 12,
        color: 'grey'
    },
    amountTitle: {
        fontSize: 10
    },
    priceTitle: {
        fontSize: 10
    },
    slider: {
        margin: 24
    },
    header: {
        padding: '4px',
        background: '#3f51b5',
        color: 'white'
    },
    payBtn: {
        bottom: '54px',
        position: 'absolute'
    },
    cancelPayBtn: {
        bottom: '8px',
        position: 'absolute'
    },
    addressChangeBtn: {
        padding: '0px'
    },
    input: {
        width: 80,
    },
    nextBtn: {
        margin: theme.spacing(1),
        float: 'right'
    },
    backBtn: {
        margin: theme.spacing(1),
        float: 'left'
    }
}));

const getSteps = (t) => {
    return [t('Plan'), t('Checkout')];
}

const PlanDetails = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [voters, setVoters] = useState(props.paidNoOfVoters > 0 ? 1 : (props.noOfVoters > 100 ? props.noOfVoters : 100));
    const [currency] = useState('INR');
    const [grandTotal, setGrandTotal] = useState(0);
    const context = useContext(UserContext);
    const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
    const [invoiceUrl, setInvoiceUrl] = useState(null);
    const [customerId, setCustomerId] = useState('');
    const [activeStep, setActiveStep] = React.useState(0);
    const [paymentMethod, setPaymentMethod] = useState('razor-pay');
    const [billingAddress, setBillingAddress] = useState({
        id: '',
        attention: '',
        gstin: '',
        pan: ''
    })
    const [shippingAddress, setShippingAddress] = useState({
        id: ''
    })
    const [seekConfirmation, setSeekConfitmation] = useState({
        show: false,
        title: '',
        message: '',
        onAgree: '',
        onDisAgree: ''
    })
    const [message, setMessage] = useState({
        showMsg: false,
        message: '',
        severity: ''
    })
    const steps = getSteps(t);

    // const [createPaymentOrder, { loading: creatingPaymentOrder, error: errorOnCreatingPaymentOrder }] = useMutation(CREATE_PAYMENT_ORDER, {
    //     errorPolicy: 'all',
    //     fetchPolicy: 'network-only',
    //     onCompleted: (data) => {
    //         console.log(data.createOrder);
    //         if (data.createOrder != null) {
    //             payment(data.createOrder[0]);
    //         }
    //     }
    // });

    // const [sendRequestForOfflinePayment, { loading: sendingRequestForOfflinePayment, error: errorOnSendingRequestForOfflinePayment }] =
    //     useMutation(REQUEST_FOR_OFFLINE_PAYMENT, {
    //         errorPolicy: 'all',
    //         fetchPolicy: 'network-only',
    //         onCompleted: (data) => {
    //             setMessage({ showMsg: false, message: '', severity: '' });
    //             if (data.requestOfflinePayment) {
    //                 setMessage({ showMsg: true, message: t("Your_Offline_Payment_Request_is_Submitted"), severity: 'success' });
    //                 setTimeout(() => {
    //                     props.onSuccess();
    //                 }, 2000)
    //             }
    //             resetConfirmation();
    //         }
    //     });

    // const [createPayment, { loading: creatingPayment, error: errorOnCreatingPayment }] = useMutation(CREATE_PAYMENT, {
    //     errorPolicy: 'all',
    //     fetchPolicy: 'network-only',
    //     onCompleted: (data) => {
    //         if (data.createPayment != null) {
    //             setIsPaymentSuccess(true);
    //             setInvoiceUrl(data.createPayment);
    //             props.setPaidVoters(Number(props.paidNoOfVoters) + Number(voters));
    //         }
    //     }
    // });

    const createOrder = () => {
        // createPaymentOrder({
        //     variables: {
        //         data: [
        //             {
        //                 amount: grandTotal,
        //                 currency: currency,
        //                 poll_id: props.pollId,
        //                 paid_voter: voters,
        //                 billing_address_id: billingAddress.id,
        //                 shipping_address_id: shippingAddress.id,
        //                 bill_to: billingAddress.attention,
        //                 customer_type: "individual",
        //                 gst: billingAddress.gstin ? billingAddress.gstin : '',
        //                 pan: billingAddress.pan ? billingAddress.pan : '',
        //                 customer_id: billingAddress.customer_id ? billingAddress.customer_id : ''
        //             }
        //         ]
        //     }
        // })
    }

    const requestForOfflinePayment = () => {
        setSeekConfitmation({
            show: true,
            title: t('Success'),
            message: t('Are_you_sure_you_want_to_send_the_Offline_Payment_Request?'),
            onDisAgree: resetConfirmation,
            onAgree: sendRequest
        })

    }

    const sendRequest = () => {
        // sendRequestForOfflinePayment({
        //     variables: {
        //         data: {
        //             billing_address_id: billingAddress.id,
        //             customer_id: billingAddress.customer_id ? billingAddress.customer_id : '',
        //             poll_id: props.pollId,
        //             shipping_address_id: shippingAddress.id,
        //             ordered_voter: voters
        //         }
        //     }
        // })
    }

    const resetConfirmation = () => {
        setSeekConfitmation(prevState => ({
            ...prevState,
            show: false,
            title: '',
            message: '',
            onDisAgree: '',
            onAgree: ''
        }))
    }

    const setSelectedBillingAddress = (address) => {
        setBillingAddress({
            address_line_1: address.address_line_1,
            address_line_2: address.address_line_2,
            address_type: address.address_type,
            attention: address.attention,
            city_town: address.city_town,
            city_town_id: address.city_town_id,
            country: address.country,
            country_id: address.country_id,
            end_time: address.end_time,
            id: address.id,
            landmark: address.landmark,
            phone: address.phone,
            postal_code: address.postal_code,
            postal_code_id: address.postal_code_id,
            province: address.province,
            start_time: address.start_time,
            state_id: address.state_id,
            gstin: address.gstin,
            pan: address.pan,
            customer_id: address.customer_id
        })
    }

    const setSelectedShippingAddress = (address) => {
        setShippingAddress({
            address_line_1: address.address_line_1,
            address_line_2: address.address_line_2,
            address_type: address.address_type,
            attention: address.attention,
            city_town: address.city_town,
            city_town_id: address.city_town_id,
            country: address.country,
            country_id: address.country_id,
            end_time: address.end_time,
            id: address.id,
            landmark: address.landmark,
            phone: address.phone,
            postal_code: address.postal_code,
            postal_code_id: address.postal_code_id,
            province: address.province,
            start_time: address.start_time,
            state_id: address.state_id,
            gstin: address.gstin,
            pan: address.pan,
            customer_id: address.customer_id
        })
    }

    const payment = (order) => {
        let options = {
            key: process.env.REACT_APP_RAZORPAY_ID,
            amount: order.amount,
            currency: currency,
            name: 'Acta eVoting',
            description: 'Electronic Voting Platform',
            image: '',
            order_id: order.id,
            handler: function (response) {
                // createPayment({
                //     variables: {
                //         data: [
                //             {
                //                 poll_id: props.pollId,
                //                 razorpay_payment_id: response.razorpay_payment_id,
                //                 razorpay_order_id: response.razorpay_order_id,
                //                 razorpay_signature: response.razorpay_signature
                //             }
                //         ]
                //     }
                // })
            },
            prefill: {
                name: context.userSession.session.name,
                email: context.userSession.session.email,
                contact: context.userSession.session.phone_number
            },
            theme: {
                color: "#3399cc"
            }
        };
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    }

    const cancelPayment = () => {
        props.cancelPayment();
    }

    const getStepContent = (stepIndex) => {
        switch (stepIndex) {
            case 0:
                return (
                    <Grid direction="row" container justifyContent="center" alignItems="center">
                        <Grid item xs={2}></Grid>
                        <Grid item xs={8} style={{ padding: '10px', background: '#E7EBF0', borderRadius: '10px' }}>
                            <Pricing noOfVoters={voters > 0 ? voters : (props.paidNoOfVoters > 0 ? 1 : 100)}
                                setVoters={setVoters} setGrandTotal={setGrandTotal}
                                pollId={props.pollId} paidNoOfVoters={props.paidNoOfVoters}
                                customerId={customerId} defaultNoOfVoters={100}
                                noOfExitingVoters={props.noOfVoters} />
                        </Grid>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={12}>
                            <Button className={classes.nextBtn} id="next" type="submit" size="small" variant="contained"
                                color="primary" onClick={handleNext}>
                                {t('Next')}
                            </Button>
                            {/* <Button className={classes.nextBtn} type="submit" id='close' size="small" variant="contained"
                                onClick={cancelPayment}>
                                {t('Cancel')}
                            </Button> */}
                        </Grid>
                    </Grid>
                );
            case 1:
                return (
                    <Grid container>
                        <Grid item xs={12}>
                            <GatewaySelection setCustomerId={setCustomerId} setSelectedBillingAddress={setSelectedBillingAddress}
                                setSelectedShippingAddress={setSelectedShippingAddress}
                                paymentMethod={paymentMethod} setPaymentMethod={setPaymentMethod}
                                billingAddress={billingAddress} shippingAddress={shippingAddress} />
                        </Grid>
                        <Grid item xs={12}>
                            <Button className={classes.backBtn} id="back" size="small" variant="contained"
                                color="primary" onClick={handleBack}>
                                {t('Back')}
                            </Button>
                            {paymentMethod === 'razor-pay' && <Button variant="contained" color="primary" size="small" onClick={createOrder} className={classes.nextBtn}
                                disabled={shippingAddress.id == '' || billingAddress.id == '' || grandTotal == 0 }>
                                Pay {grandTotal.toLocaleString()}
                            </Button>}
                            {paymentMethod === 'offline' && <Button variant="contained" color="primary" size="small" onClick={requestForOfflinePayment}
                                disabled={shippingAddress.id == '' || billingAddress.id == '' }
                                className={classes.nextBtn}>
                                Send Request
                            </Button>}
                            {/* <Button className={classes.nextBtn} id='cancel' size="small" variant="contained"
                                onClick={cancelPayment} disabled={sendingRequestForOfflinePayment || creatingPaymentOrder || creatingPayment}>
                                {t('Cancel')}
                            </Button> */}
                        </Grid>
                    </Grid>
                );
            default:
                return null;
        }
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <div className='loading-container'>
            {!isPaymentSuccess && <Container maxWidth="md" className={classes.root}>
                {/* <div>
                    <Typography id='pricing' className={classes.title} align="center">{t('Payment')}</Typography>
                </div> */}
                {/* <Grid container> */}
                <Stepper activeStep={activeStep} alternativeLabel style={{ padding: '8px' }}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <div>
                    <Paper elevation={0}>
                        {getStepContent(activeStep)}
                    </Paper>
                </div>

                {/* <Grid xs={12} sm={6}>
                        <Pricing noOfVoters={props.paidNoOfVoters > 0 ? 1 : 100}
                                setVoters={setVoters} setGrandTotal={setGrandTotal}
                                pollId={props.pollId} paidNoOfVoters={props.paidNoOfVoters}
                                customerId={customerId}/>
                    </Grid> */}
                {/* <Grid xs={12} sm={6} style={{position: 'relative'}}>
                        <Card className={classes.priceCard}>
                            <div className={classes.cardContent}>
                                <AddressSelection showGSTIN={true} addressTypeLabel={'Bill To'} setSelectedAddress={setSelectedBillingAddress}
                                    setCustomerId={setCustomerId}/>
                            </div>
                        </Card>
                        <Card className={classes.priceCard}>
                            <div className={classes.cardContent}>
                                <AddressSelection showGSTIN={false} addressTypeLabel={'Ship To'} setSelectedAddress={setSelectedShippingAddress}/>
                            </div>
                        </Card>
                        <Grid xs={12}>
                            <Button variant="contained" color="primary" fullWidth onClick={createOrder}
                                className={classes.payBtn} disabled={shippingAddress.id == '' || billingAddress.id == '' || grandTotal == 0}>
                                    Pay {grandTotal.toLocaleString()}
                            </Button>
                            <Button id='button' variant="contained" fullWidth  className={classes.cancelPayBtn}
                                onClick={cancelPayment}>
                                    Cancel
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid xs={12}>
                        <GatewaySelection />
                    </Grid> */}
                {/* {(creatingPaymentOrder || creatingPayment || sendingRequestForOfflinePayment) && <EligoBackdrop show={creatingPaymentOrder || creatingPayment || sendingRequestForOfflinePayment} />} */}
                {/* {errorOnCreatingPaymentOrder && <GraphQLErrors error={errorOnCreatingPaymentOrder} show={false} />} */}
                {/* {errorOnCreatingPayment && <GraphQLErrors error={errorOnCreatingPayment} show={false} />} */}
                {/* {errorOnSendingRequestForOfflinePayment && <GraphQLErrors error={errorOnSendingRequestForOfflinePayment} show={false} />} */}
                {seekConfirmation.show && <EligoConfirmDialog show={seekConfirmation.show}
                    title={seekConfirmation.title} message={seekConfirmation.message}
                    onAgree={seekConfirmation.onAgree} onDisAgree={seekConfirmation.onDisAgree} />}
                {message.showMsg && <EligoSnackBar show={message.showMsg} message={message.message}
                    severity={message.severity} />}
                {/* </Grid> */}

            </Container>}
            {isPaymentSuccess && <PaymentSuccess onConfirm={props.onSuccess} invoiceUrl={invoiceUrl} />}
        </div>
    )
}

export default PlanDetails;
