import { Close } from "@material-ui/icons";
import { Button, Dialog, DialogActions, Fab, Grid, Stack, styled } from "@mui/material";
import { BsFillPersonCheckFill } from 'react-icons/bs';
import { Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

export const ReUploadButton = styled(Button)(() => ({
    textTransform: 'capitalize !important',
    fontWeight: 'bold !important',
    boxShadow: '0px 1px 5px -1px rgb(0 0 0 / 10%), 0 0 0 -8px rgb(0 0 0 / 4%) !important',
    '&: hover': {
        boxShadow: '0px 1.7px 2.2px rgba(0, 0, 0, 0.02), 0px 4px 5.3px rgba(0, 0, 0, 0.028), 0px 7.5px 10px rgba(0, 0, 0, 0.035), 0px 13.4px 17.9px rgba(0, 0, 0, 0.042), 0px 25.1px 33.4px rgba(0, 0, 0, 0.05), 0px 60px 80px rgba(0, 0, 0, 0.07) !important '
    },
}))

const useStyles = makeStyles((theme) => ({
    paper: {
        borderRadius: '12px !important', background: '#f7f9f9 !important',
        // animation: 'fadeup .5s .5s ease both',
        // transform: 'translateY(20px)',
    },
    closeFab: {
        position: 'absolute !important',
        top: '2% !important',
        left: '90% !important',
        float: 'right !important',
        boxShadow: 'none !important',
        color: `${theme.palette.error.main} !important`,
    },
    title: {
        background: theme.palette.background.paper,
        borderBottom: '1px solid rgb(240 241 241) !important'
    },
    titleIcon: {
        fontSize: '3rem !important', color: 'rgb(19 143 24) !important'
    },
    dialogActions: {
        padding: '10px 35px 32px 35px !important',
        columnGap: '1rem !important',
        justifyContent: 'center !important'
    },
    noBtn: {
        color: `${theme.palette.primary.main}!important`,
        border: '1px solid #eeeeee !important',
        background: `${theme.palette.background.paper}!important`,
        '&: hover': {
            color: `${theme.palette.primary.main}!important`,
            background: `${theme.palette.background.paper}!important`,
        }
    }
}))

const ConfirmationPopUp = (props) => {
    const { title, message, open, handleClose, agreeLabel, disagreeLabel, onAgree, onDisAgree} = props;
    const classes = useStyles();

    const onClose = () => {
        handleClose();
    }

    return (
        <Dialog open={open} PaperProps={{ className: classes.paper }}>
            <Grid item xs={12}>
                <Fab id="doc-pop-cancel" className={classes.closeFab} size='small' sx={{ backgroundColor: (theme) => theme.palette.background.paper }}
                    onClick={() => onClose()}>
                    <Close />
                </Fab>
            </Grid>
            <Stack>
                <Stack justifyContent='center' alignItems='center' px={3} py={3} spacing={1.5} className={classes.title}>
                    <BsFillPersonCheckFill className={classes.titleIcon} />
                    <Typography id="doc-pop-title" variant='h5'>{title}</Typography>
                </Stack>
                {/* <Divider flexItem /> */}
                <Stack justifyContent='center' alignItems='center' px={5} py={3}>
                    <Typography id="doc-pop-msg">{message}</Typography>
                </Stack>
            </Stack>
            <DialogActions className={classes.dialogActions}>
                <ReUploadButton id="doc-pop-no" variant='contained' className={classes.noBtn} onClick={onDisAgree}>
                    {disagreeLabel}
                </ReUploadButton>
                <ReUploadButton id="doc-pop-yes" variant='contained' color='primary' onClick={onAgree}>
                    {agreeLabel}
                </ReUploadButton>
            </DialogActions>
        </Dialog >
    )

}

export default ConfirmationPopUp;