import { Box, makeStyles } from "@material-ui/core";
import { styled } from "@material-ui/styles";

export const useStyles = makeStyles((theme) => ({
    dialogWrapper: {
        position: 'absolute',
        borderRadius: '15px',

    },
    loginInfo: {
        color: '#148044 !important',
        background: '#e0ffef !important',
        padding: '5px 10px !important',
        borderRadius: '0.45rem !important',
        margin: '-6px auto !important'
    },
    dialogTitle: {
        background: theme.palette.type === "light" ? 'linear-gradient(90deg, rgb(4 78 162) 35%, rgb(255 255 255) 100%)' :
            'linear-gradient(90deg, rgb(255 255 255) 35%, rgb(127 171 255) 100%)',
        color: theme.palette.type === "light" ? '#fff' : '#000000'
    },
    cross: {
        color: 'white',
        background: '#e32929',
        boxShadow: '3px 3px 3px grey',
        borderRadius: '4px'
    },
    checkFab: {
        width: '34px !important',
        height: '34px !important',
        margin: '-25px 15px !important',
        background: `${theme.palette.background.paper} !important`,
        '. icon': {
            fontSize: '1.25rem !important',
        },
        '&[disabled]': {
            pointerEvent: 'none',
            opacity: '0.7 !important',
            background: 'rgba(0, 0, 0, 0.20) !important'
        }
        // background: theme.palette.type === 'light' ? '#f0ecec !important' : '#3c3d3d !important'
    },
    closeFab: {
        width: '34px !important',
        height: '34px !important',
        margin: '-25px !important',
        background: `${theme.palette.background.paper} !important`,
        '. icon': {
            fontSize: '1.25rem !important',
        },
        // background: theme.palette.type === 'light' ? '#f0ecec !important' : '#3c3d3d !important'
    },
    editFab: {
        width: '34px !important',
        height: '34px !important',
        margin: '-25px !important',
        background: `${theme.palette.background.paper} !important`,
        // background: theme.palette.type === 'light' ? '#f0ecec !important' : '#3c3d3d !important',
        '. icon': {
            fontSize: '1.25rem !important',
        },
        '&[disabled]': {
            pointerEvent: 'none',
            opacity: '0.7 !important',
            background: 'rgba(0, 0, 0, 0.20) !important'
        }
    },
    deleteFab: {
        width: '34px !important',
        height: '34px !important',
        margin: '-25px 15px !important',
        background: `${theme.palette.background.paper} !important`,
        // background: theme.palette.type === 'light' ? '#f0ecec !important' : '#3c3d3d !important',
        '. icon': {
            fontSize: '1.25rem !important',
        },
        '&[disabled]': {
            pointerEvent: 'none',
            opacity: '0.7 !important',
            background: 'rgba(0, 0, 0, 0.20) !important'
        }
    }
}))

export const AuctionFieldSet = styled('fieldset')(({ theme }) => ({
    background: theme.palette.type === "light" ? '#fff' : '#1e1d1d',
    borderRadius: '4px',
    padding: '1rem',
    boxShadow: 'rgb(32 40 45 / 8%) 0px 2px 14px 0px',
    lineHeight: '0.3 !important',
    border: theme.palette.type === "light" ? '1px solid #eae7e7' : '1px solid #575757'
}))

export const RadioCard = styled(Box)(({ selected }) => ({
    flexDirection: 'column',
    cursor: 'pointer',
    borderRadius: 12,
    padding: '0.5rem',
    border: selected ? '2px solid #1976d2' : '2px solid #e1e2e7',
    background: selected ? '#def0ff' : 'inherit',
    margin: '0.5rem',
    '&: hover': {
        backgroundColor: '#def0ff',
        border: '2px solid #1976d2'
        // color: '#9e9e9e'
    },
    '& .icon:hover': {
        color: '#1976d2'
    },
    '& .text:hover': {
        color: '#1976d2'
    },
    '& .icon': {
        color: selected ? '#1976d2' : 'currentColor',
        lineHeight: '25px !important'
    },
    '& .text': {
        color: selected ? '#1976d2' : 'currentColor',
        textAlign: 'center !important'
    },
    '&[disabled]': {
        pointerEvents: 'none',
        opacity: 0.7
    }

    // boxShadow: 'inset 0 1px 1px 0 hsl(0deg 0% 100% / 10%), 0 50px 100px -20px rgb(50 50 93 / 25%), 0 30px 60px -30px rgb(0 0 0 / 30%)'
}))

export const AuctionLengend = styled('legend')(() => ({
    background: '#1976d2',
    color: '#fff',
    borderRadius: '10px',
    padding: '5px 10px',
    fontSize: '12px',
    lineHeight: '1 !important'
}))