
import { gql } from '@apollo/client';

const MUTATIONS = {
    CREATE_POLL_ROUND: gql`
        mutation PollRound ($poll_id: String!, $purpose: String!,
            $round_number: Int!, $start_date_time: AWSDateTime!, $end_date_time: AWSDateTime!
            $result_post_on_poll_close: Boolean!, $start_manually: Boolean!, $status: String,
            $alert_time: AWSDateTime) {
            createPollRound(data: {
                poll_id: $poll_id,
                purpose: $purpose,
                round_number: $round_number,
                start_date_time: $start_date_time,
                end_date_time: $end_date_time
                result_post_on_poll_close: $result_post_on_poll_close,
                start_manually: $start_manually,
                status: $status,
                alert_time: $alert_time
            }) {
                event_id
            }
        }
    `,

    CREATE_POLL_ROUND_QUESTION: gql`
        mutation PollRoundQuestion($data: PollQuestionInput!) {
            createPollQuestion(data: $data)
        }
    `,

    CREATE_POLL_ROUND_VOTER: gql`
        mutation PollRoundVoter($data: VoterInput!, $page: Int, $limit: Int) {
            createVoter(data: $data, page: $page, limit: $limit) {
                scannedCount
                voters {
                    cognito_user
                    email
                    voter_id
                    last_remainder_time
                    name
                    otp_verified_on
                    phone_number
                    poll_id
                    weightage
                }
            }
        }
    `,

    UPDATE_POLL_ROUND: gql`
        mutation PollRoundUpdate($data: PollRoundInput!) {
            updatePollRound(data: $data) {
                event_id
            }
        }
    `,

    UPDATE_POLL_ROUND_QUESTION: gql`
        mutation PollRoundQuestionUpdate($data: PollQuestionInput!, $poll_question_id: String) {
            updatePollQuestion(data: $data, poll_question_id: $poll_question_id)
        }
    `,

    UPDATE_POLL_ROUND_VOTER: gql`
        mutation PollRoundVoterUpdate ($data: VoterInput, $limit: Int, $page: Int, $voter_id: ID) {
            updateVoter(data: $data, limit: $limit, page: $page, voter_id: $voter_id) {
                voters {
                    cognito_user
                    email
                    voter_id
                    last_remainder_time
                    name
                    otp_verified_on
                    phone_number
                    poll_id
                    weightage
                }
                scannedCount
            }
        }
    `,

    UPLOAD_BULK_VOTERS: gql`
        mutation BulkCreateVoter($file: String!) {
            bulkCreateVoter(data: {s3_file_path: $file}) {
                event_id
            }
        }
    `,

    DELETE_POLL_ROUND: gql`
        mutation DeletePollRound($data: PollRoundInput!) {
            deletePollRound(data: $data) {
                event_id
            }
        }
    `,

    DELETE_POLL_QUESTION: gql`
        mutation DeletePollQuestion($poll_question_id: ID) {
            deletePollQuestion(poll_question_id: $poll_question_id)
        }
    `,

    DELETE_POLL_QUESTION_OPTION: gql`
        mutation DeletePollQuestionOption($poll_question_response_id: ID) {
            deletePollQuestionResponse(poll_question_response_id: $poll_question_response_id) 
        }
    `,

    DELETE_VOTER: gql`
        mutation DeleteVoter($voter_id: ID, $page: Int, $limit: Int) {
            removeVoter(voter_id: $voter_id, page: $page, limit: $limit) {
                scannedCount
                voters {
                    cognito_user
                    email
                    voter_id
                    last_remainder_time
                    name
                    otp_verified_on
                    phone_number
                    poll_id
                    weightage
                }
            }
        }
    `,

    CAST_VOTE: gql`
        mutation CaseVote($poll_question_response_id: String ) {
            castVote(
                data: {
                    poll_question_response_id: $poll_question_response_id,
                }
            )
        }
    `

}

const CREATE_POLL = gql`
    mutation Create ($data: PollInput) {
        createPoll(data: $data)
    }
`
const UPDATE_POLL =  gql `
    mutation Update ($data: PollInput, $poll_id: ID) {
        updatePoll(data: $data, poll_id: $poll_id)
    }
`
const OPEN_POLL_STATUS = gql`
    mutation openEballot (
        $eventId: ID
        $sequence: Int
        $status: String
    ){
        openEballot(
            data: { 
                event_id: $eventId, 
                sequence: $sequence, 
                status: $status
            }
            sequence: $sequence, 
        ){
            event_id
        }
    }
`
const CLOSE_POLL_STATUS = gql`
    mutation closeEballot (
        $eventId: ID
        $sequence: Int
        $status: String
    ){
        closeEballot(
            data: { 
                event_id: $eventId, 
                sequence: $sequence, 
                status: $status
            }
            sequence: $sequence, 
        ){
            event_id
        }
    }
`
const DELETE_POLL = gql`
   mutation DeletePoll(
        $poll_id: ID,
        $status: String,
        $role: String,
        $page: Int,
        $limit: Int)
{
    deletePoll(
        poll_id: $poll_id,
        status: $status,
        role:  $role,
        page: $page,
        limit: $limit
            
        ) 
        {
            scannedCount
        }
    }
    # {
    #     deletePoll (
    #         data: {
    #             event_id: $eventId
    #         }
    #     ){
    #         event_id
    #     }
    # }
`
const CREATE_GENERIC_CODE = gql`
    mutation MyMutation (
        $label: String!
        $propertyInput: PropertyInput
        ) {
            createGenericNode ( 
                data: {
                    label: $label, 
                    properties: [$propertyInput]
                }
        ) {
            event_id
        }
    }
`;

const UPDATE_GENERIC_CODE = gql`
    mutation MyMutation ( 
        $label: String!
        $propertyInput: PropertyInput
    ) {
        updateGenericNode (
            data: {
                label: $label, 
                properties: [$propertyInput]
            }
        ) {
            event_id
        }
    }
`;

const OPEN_POLL_ROUND_STATUS = gql`
    mutation openPollRound (
        $eventId: ID
        $sequence: Int
        $status: String
    ){
        openPollRound(
            data: {
                status: $status, 
                event_id: $eventId,
                sequence: $sequence
            }, 
            sequence: $sequence
        ){
            event_id
        }
    } 
`
const CLOSE_POLL_ROUND_STATUS = gql`
    mutation closePollRound (
        $eventId: ID
        $sequence: Int
        $status: String
    ){
        closePollRound(
            data: {
                status: $status, 
                event_id: $eventId,
                sequence: $sequence
            }, 
            sequence: $sequence
        ){
            event_id
        }
    } 
`

const ASSIGN_ROLE = gql`
    mutation assignRole($data: RoleInput, $page: Int, $limit: Int) {
        assignPollRole(data: $data, page: $page, limit: $limit) {
            userRoles {
                email
                name
                phone_number
                user_id
            }
            scannedCount
        }
    }
`
const CHANGE_POLL_ROUND_STATUS = gql`
    mutation changePollRoundStatus (
        $eventId: ID
        $sequence: Int
        $status: String
    ){
        changePollRoundStatus(
            data: {
                event_id: $eventId, 
                sequence: $sequence, 
                status: $status
            }, 
            sequence: $sequence
        ){
            event_id
        }
    }
`
const REVOKE_ROLE = gql`
    mutation revokeRole($role_id: ID, $page: Int, $limit: Int) {
        revokePollRole(role_id: $role_id, page: $page, limit: $limit) {
            scannedCount
            userRoles {
                email
                name
                phone_number
                user_id
            }
        }
    }
`
const CHANGE_POLL_STATUS = gql`
    mutation MyMutation(
        $eventId: ID
        $sequence: Int
        $status: String
    ){
        changePollStatus(
            data: {
                event_id: $eventId, 
                sequence: $sequence, 
                status: $status
            }, 
            sequence: $sequence
        ){
            event_id
        }
  } 
`

const CREATE_POLL_CATEGORY_CODE = gql`
    mutation MyMutation (
        $propertyInput: PropertyInput
        ) {
            createPollCategory ( 
                data: [$propertyInput]
        ) {
            event_id
        }
    }
`;

const UPDATE_POLL_CATEGORY_CODE = gql`
    mutation MyMutation ( 
        $propertyInput: PropertyInput
    ) {
        updatePollCategory (
            data: [$propertyInput]
        ) {
            event_id
        }
    }
`;

const REQUEST_OTP_TO_CONFIRM_VOTE = gql`
    mutation requestOTPToVerifyVote($data: verifyCastInput) {
        requestToVerifyCast(data: $data)
    }
`

const CONFIRM_VOTE = gql`
    mutation confirmVote($data: verifyCastInput) {
        verifyCast(data: $data)
    }
`

const CREATE_ADDRESS = gql`
    mutation CreateAddress($data: [AddressInput]) {
        addAddress(data: $data)
    }
`

const UPDATE_ADDRESS = gql`
    mutation UpdateAddress($data: [AddressInput]) {
        updateAddress(data: $data)
    }
`

const DELETE_ADDRESS = gql`
    mutation DeleteAddress($address_edge_id: ID) {
        deleteAddress(address_edge_id: $address_edge_id)
    }
`

const CREATE_PAYMENT_ORDER = gql`
    mutation CreatePaymentOrder($data: [OrderInput]) {
        createOrder(data: $data) {
            amount
            amount_due
            amount_paid
            attempts
            currency
            entity
            id
            offer_id
            receipt
            status
        }
    }
`

const CREATE_PAYMENT = gql`
    mutation CreatePayment($data: [PaymentInput]) {
        createPayment(data: $data)
    }
`

const CREATE_CUSTOMER = gql`
    mutation CreateCustomer($data: [UserClientInput]) {
        createUserClient(data: $data) {
            id
            name
        }
    }
`

const UPDATE_IDENTIFICATION = gql`
    mutation UpdateIdentification($data: [IdentificationInput]) {
        updateIdentification(data: $data) {
            id
            identification
            end_time
            identification_type
            start_time
            valid_from
            valid_to
        }
    } 
`

const UPDATE_CUSTOMER_DETAILS = gql`
    mutation UpdateCustomerDetails($data: [UserClientUpdateInput]) {
        updateUserClient(data: $data) {
            gst_treatment
            id
            name
            party_type
        }
    }
`

const UPDATE_USER_DETAILS = gql`
    mutation updateUserDetails($data: CognitoUserInput, $user_id: String) {
        updateCognitoUser(data: $data, user_id: $user_id)
    }
`
const UPDATE_GST_TREATMENT = gql`
    mutation MyMutation($gst_treatment: String, $user_id: ID) {
        updateGstTreatment(gst_treatment: $gst_treatment, user_id: $user_id)
    }
`
const DELETE_FILE = gql`
mutation deletefile($bucket_name:String, $Key:[String]) {
    deletePollFiles(bucket_name: $bucket_name, key: $Key)
}
`

// const  THEME_AND_COLOR = gql
// // mutation GetThemeAndColor($banner_color: String, $theme:String){
// //     themeAndColor(data: {banner_color:$banner_color, theme:$theme})
// //         banner_color
// //         theme
// // }`

const UPDATE_SPECIAL_PRICE = gql`
    mutation updateSpecialPrice($poll_id: ID, $special_price_voter: Int, $special_price: Float) {
        specialPrice(poll_id: $poll_id, special_price: $special_price, special_price_voter: $special_price_voter)
    }
`

const RECORD_OFFLINE_PAYMENT = gql`
    mutation recordOfflinePayment($data: PaymentDetailsInput) {
        recordOfflinePayment(data: $data)
    }
`

const REQUEST_FOR_OFFLINE_PAYMENT = gql`
    mutation requestForOfflinePayment($data: offlinePaymentInput) {
        requestOfflinePayment(data: $data)
    }
`

const REQUEST_FOR_PRE_CLOSURE = gql`
    mutation requestForPreClosure($poll_id: ID) {
        pollPreClose(poll_id: $poll_id)
    }
`

export {
    MUTATIONS, CREATE_POLL, UPDATE_POLL, DELETE_POLL, OPEN_POLL_STATUS, CLOSE_POLL_STATUS, 
    CREATE_GENERIC_CODE, UPDATE_GENERIC_CODE, CREATE_POLL_CATEGORY_CODE, UPDATE_POLL_CATEGORY_CODE, 
    OPEN_POLL_ROUND_STATUS, CLOSE_POLL_ROUND_STATUS, CHANGE_POLL_ROUND_STATUS, CHANGE_POLL_STATUS, 
    ASSIGN_ROLE, REVOKE_ROLE, REQUEST_OTP_TO_CONFIRM_VOTE, CONFIRM_VOTE, CREATE_ADDRESS, UPDATE_ADDRESS,
    DELETE_ADDRESS, CREATE_PAYMENT_ORDER, CREATE_PAYMENT, CREATE_CUSTOMER, UPDATE_IDENTIFICATION,
    UPDATE_CUSTOMER_DETAILS, UPDATE_USER_DETAILS, UPDATE_GST_TREATMENT, UPDATE_SPECIAL_PRICE, DELETE_FILE,
    RECORD_OFFLINE_PAYMENT, REQUEST_FOR_OFFLINE_PAYMENT, REQUEST_FOR_PRE_CLOSURE
}