import { Button, Typography } from '@material-ui/core';
import { Error } from '@material-ui/icons';
import { Stack } from '@mui/material';
import React, { Fragment } from 'react';
import { BrowserView, isBrowser } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Status, TypeOfPoll } from '../../../common/GenericCodes';
import PollRoundItem from '../poll-round-item/PollRoundItem';
import { useEffect, useState } from 'react';

const PollRoundList = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [pollStatus, setPollStatus] = useState('')
    let backgroundMap = new Map([
        [Status.open, 'linear-gradient(to bottom right, #e7f3c7 0%, #ffffff 100%)'],
        [Status.closed, 'linear-gradient(to bottom right, #ffd8d8 0%, #ffffff 100%)'],
        [Status.new, 'linear-gradient(to bottom right, #ffffe5 0%, #ffffff 100%)'],
        [Status.deleted, 'transparent'],
        [Status.scheduled, 'linear-gradient(to right bottom, #fbe0d1 0%, rgb(255, 255, 255) 100%)'],
        [Status.canceled, 'linear-gradient(to right bottom, rgb(187 180 180 / 31%) 0%, rgb(255, 255, 255) 100%)'],
        [Status.published, 'linear-gradient(to bottom right, #ffd8d8 0%, #ffffff 100%)']
    ])
    let borderMap = new Map([
        [Status.open, '2px solid #b6d852'],
        [Status.closed, '2px solid #ff6262'],
        [Status.new, '2px solid #ffff7f'],
        [Status.deleted, 'transparent'],
        [Status.scheduled, '2px solid #ea905d'],
        [Status.canceled, '2px solid rgb(16 16 16 / 54%)'],
        [Status.published, '2px solid #ff6262']
    ])

    const handleSetupPoll = (type) => {
        history.push({
            pathname: '/polls/setup',
            state: {
                currentPage: 1,
                // roles: props.location.state.roles,
                pollType: props.pollType,
                limit: 5,
                type: type
            }
        })
    }

    useEffect(() => {
        props.polls.map((value) => {
            if (value.status === Status.ready) {
                if (new Date() > new Date(value.endTime)) {
                    setPollStatus(Status.closed);
                } else if (new Date() > new Date(value.startTime)) {
                    setPollStatus(Status.open);
                } else {
                    setPollStatus(Status.upcoming);
                }
            } else if (value.status === Status.canceled) {
                setPollStatus(Status.canceled);
            } else {
                setPollStatus(Status.setup);
            }
        })

    }, [])

    return (
        <Fragment>
            {/* <Grid container>
                <Grid item xs={isBrowser ? 6 : 12}>
                    <Typography color="textPrimary">
                        {props.pollDetails.title.en} ({props.pollDetails.category_description})
                    </Typography>
                </Grid>
                {isBrowser && props.pollRounds.listPollRound?.pollRounds.length != 0 &&
                    <Grid item xs={6}>
                        <span style={{float:'right'}}>&nbsp;{t('Canceled')}&nbsp;&nbsp;&nbsp;&nbsp;</span><div className="round-status-canceled"></div>
                        <span style={{float:'right'}}>&nbsp;{t('Closed')}&nbsp;&nbsp;&nbsp;&nbsp;</span><div className="round-status-closed"></div>
                        <span style={{float:'right'}}>&nbsp;{t('Open')}&nbsp;&nbsp;&nbsp;&nbsp;</span><div className="round-status-open"></div>
                        <span style={{float:'right'}}>&nbsp;{t('Scheduled')}&nbsp;&nbsp;&nbsp;&nbsp;</span><div className="round-status-scheduled"></div>
                    <span style={{float:'right'}}>&nbsp;{t('In_Progress')}&nbsp;&nbsp;&nbsp;&nbsp;</span><div className="round-status-new"></div>
                </Grid>}
            </Grid> */}
            {props.polls?.length == 0 &&
                <Stack direction='column' justifyContent='center' alignItems='center' sx={{ pt: '10%' }} spacing={1}>
                    <img src={process.env.PUBLIC_URL + 'images/no-poll.svg'} width='15%'></img>
                    <Typography id='poll-not-found' className='polls-not-found'>
                        {props.pollClass === TypeOfPoll.SURVEY ? t('No_Surveys_Are_Available') : t('No_Polls_Are_Available')}
                    </Typography>
                    {isBrowser && <BrowserView>
                        <Stack direction='column' justifyContent='center' alignItems='center' spacing={1}>
                            <Stack direction='row' spacing={1} style={{ background: '#ffedcc', borderRadius: '5px', padding: '7px 10px', boxShadow: '0 0 8px #de9b0d' }}>
                                <Error style={{ color: '#c46910' }} />
                                <Typography className='polls-not-found' component='div' style={{ color: 'rgb(125 64 3)' }}>
                                    {t('Try_switching_between_orgainzer_and_voter_or_create_a_poll')}
                                </Typography>
                            </Stack>
                            <Button className='polls-not-found' variant='outlined' color='primary' 
                                onClick={() => handleSetupPoll(props.pollClass === TypeOfPoll.SURVEY ? 'SURVEY' : 'POLL' )}
                                style={{ textAlign: 'center' }}>
                                {props.pollClass === TypeOfPoll.SURVEY ? t('Setup_Survey') : t('Setup_Poll')}
                            </Button>
                        </Stack>
                    </BrowserView>}
                </Stack>
            }
            <div>
                {props.polls.map((poll) => {
                    return (
                        <Fragment key={poll.id}>
                            {poll.hasVoterRole ?
                                ((poll.roles).length > 1 ?
                                    (<PollRoundItem poll={poll} isView={props.isView}
                                        isEdit={((poll.status == Status.ready) && props.isEdit && props.pollType != Status.canceled)}
                                        page={props.page} roles={poll.roles} pollType={props.pollType} pollRoundType={poll.status} pollStatus={pollStatus}
                                        itemsPerPage={props.itemsPerPage} roleType={props.role} setPage={props.setPage} getPollList={props.getPollList}
                                    />
                                    ) :
                                    (
                                        <PollRoundItem poll={poll} isView={props.isView} isEdit={props.isEdit} pollStatus={pollStatus}
                                            page={props.page} roles={poll.roles} pollType={props.pollType} pollRoundType={poll.status}
                                            itemsPerPage={props.itemsPerPage} roleType={props.role} setPage={props.setPage}
                                        />
                                    ))
                                :
                                <PollRoundItem poll={poll} isView={false} setMessage={props.setMessage} pollLength={props.polls}
                                    isEdit={(poll.status == Status.new || poll.status == Status.scheduled)} pollStatus={pollStatus}
                                    page={props.page} roles={poll.roles} pollType={props.pollType} pollRoundType={poll.status}
                                    itemsPerPage={props.itemsPerPage} roleType={props.role} setPage={props.setPage} getPollList={props.getPollList}
                                />
                            }
                        </Fragment>
                    )

                })}
            </div>
        </Fragment>
    )
}

export default PollRoundList;
