import { Button, Paper, TablePagination } from '@material-ui/core'
import { DeleteOutline, GetAppOutlined } from '@material-ui/icons'
import { ButtonBase, Stack, Tooltip, Typography } from '@mui/material'
import axios from 'axios'
import { format } from 'date-fns'
import React, { Fragment, useEffect, useState } from 'react'
import { AiOutlineCloudUpload } from 'react-icons/ai'
import EligoBackdrop from '../../common/EligoBackdrop'
import EligoSnackBar from '../../common/EligoSnackBar'
import { FileIcon } from './FileIcon'
import FileTable from './FileTable'
import { FileAvatarDelete, FileAvatarDownload } from './styles/FileStyle'
import UploadDialog from './UploadDialog';
import { AxiosEvotingInterceptor, PublicAxiosInterceptor, AxiosEvotingUploadInterceptor } from '../../config/axios.interceptor';
import { useTranslation } from 'react-i18next';
import EligoConfirmDialog from '../../common/EligoConfirmDialog'
import { Status, TypeOfPollCategory } from '../../common/GenericCodes'

const FileUploadDownload = (props) => {
    const { t } = useTranslation();
    const { poll_id, poll_question_id,hasSystemAdmineOrSupportRole, isUpload, isDownload, title, deletePermission, handleUploadDone, handleUploadClose, 
            isMultiple, questionTitle, pollCategory } = props;
    const [dialogOpen, setDialogOpen] = useState((isUpload && !isDownload) ? true : false);
    const [uploadedFile, setUploadedFile] = useState([])
    const [countUrl, setCountUrl] = useState(0)
    const [uploading, setUploading] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([])
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [scannedCount, setScannedCount] = useState(0);
    const [fileDetail, setFileDetail] = useState([]);
    const [prevFileDetail, setPrevFileDetail] = useState([]);
    const [files, setFiles] = useState([])
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = React.useState({
        showMsg: false,
        message: null,
        severity: null
    })
    const [download, setDownload] = useState(false)
    const [seekConfirmation, setSeekConfitmation] = useState({
        show: false,
        title: t("Confirmation"),
        message: '',
        onAgree: '',
        onDisAgree: ''
    })

    const getPollFiles = (startingToken) => {
        setMessage({ showMsg: false, message: '', severity: '' });
        setLoading(true);
        AxiosEvotingInterceptor.get(`file/${poll_id}/${rowsPerPage}?startingToken=${startingToken}`).then(response => {
            setFileDetail([])
            if (response != null) {
                response.files.map(file => {
                    setLoading(true);
                    setFileDetail(prevState => ([
                        ...prevState,
                        {
                            file_name: file.s3ObjectFile.key.split('/').pop(),
                            lastModified: file.s3ObjectFile.lastModified,
                            size: file.s3ObjectFile.size,
                            key: file.s3ObjectFile.key,
                            preSignedUrl: file.presignedUrl,
                            matter: file.matter
                        }
                    ]))
                    setPrevFileDetail(prevState => ([
                        ...prevState,
                        {
                            file_name: file.s3ObjectFile.key.split('/').pop(),
                            lastModified: file.s3ObjectFile.lastModified,
                            size: file.s3ObjectFile.size,
                            key: file.s3ObjectFile.key,
                            preSignedUrl: file.presignedUrl,
                            matter: file.matter
                        }
                    ]))
                })
                setScannedCount(response.count)
                setLoading(false);
            } else {
                setScannedCount(0)
            }
        }).catch(error => {
            setLoading(false);
            if (error.message) {
                setMessage({ showMsg: true, message: error.message, severity: 'error' });
            }
        })
    }

    const getPollQuestionFiles = (startingToken) => {
        setMessage({ showMsg: false, message: '', severity: '' });
        setLoading(true);
        AxiosEvotingInterceptor.get(`file/${poll_id}/${poll_question_id}/${rowsPerPage}?startingToken=${startingToken}`).then(response => {
            setFileDetail([])
            if (response != null) {
                response.files.map(file => {
                    setFileDetail(prevState => ([
                        ...prevState,
                        {
                            file_name: file.s3ObjectFile.key.split('/').pop(),
                            lastModified: file.s3ObjectFile.lastModified,
                            size: file.s3ObjectFile.size,
                            key: file.s3ObjectFile.key,
                            preSignedUrl: file.presignedUrl,
                            matter: file.matter
                        }
                    ]))
                    setPrevFileDetail(prevState => ([
                        ...prevState,
                        {
                            file_name: file.s3ObjectFile.key.split('/').pop(),
                            lastModified: file.s3ObjectFile.lastModified,
                            size: file.s3ObjectFile.size,
                            key: file.s3ObjectFile.key,
                            preSignedUrl: file.presignedUrl,
                            matter: file.matter
                        }
                    ]))
                })
                setScannedCount(response.count)
            } else {
                setScannedCount(0)
            }
            setLoading(false);
        }).catch(error => {
            setLoading(false);
            if (error.message) {
                setMessage({ showMsg: true, message: error.message, severity: 'error' });
            }
        })
    }

    const handleClick = () => {
        setDialogOpen(true);
    }

    const handleChangePage = (event, newPage) => {
        if (page > newPage) {
            setPage(newPage);
            setFileDetail(prevFileDetail.slice(newPage * rowsPerPage, (newPage + 1) * rowsPerPage))
        } else {
            setFileDetail([])
            const index = fileDetail.length - 1;
            if (props.otpLogin) {
                getFilesForLinkUsers(fileDetail.length > 0 && newPage != 0 ? fileDetail[index].key : "''")
            } else {
                // const index = fileDetail.length - 1;
                if (poll_question_id) {
                    getPollQuestionFiles(fileDetail.length > 0 && newPage != 0 ? fileDetail[index].key : "''");
                } else {
                    getPollFiles(fileDetail.length > 0 && newPage != 0 ? fileDetail[index].key : "''");
                }
            }
            setPage(newPage);
        }
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const downloadFiles = (Key) => {
        setDownload(true)
        if (props.otpLogin) {
            downloadFileOtpLoginUsers({ fileName: Key, bucket: process.env.REACT_APP_EVOTING_S3_BUCKET_NAME, httpMethod: 'GET', versionId: '', pollId:poll_id })
        } else {
            getPreSignedUrlToDownload({ fileName: Key, bucket: process.env.REACT_APP_EVOTING_S3_BUCKET_NAME, httpMethod: 'GET', versionId: '', pollId:poll_id })
        }
    }

    const getPreSignedUrlToDownload = (payload) => {
        const newFile = payload.fileName.split('/').pop().split('.')[0]
        setMessage({ showMsg: false, message: '', severity: '' });
        setLoading(true);
        AxiosEvotingUploadInterceptor.post(`file/download-link`, payload).then(url => {
            if (url) {
                axios({
                    url: url,
                    method: 'GET',
                    responseType: 'blob'
                }).then((response) => {
                    // setLoading(false);
                    setTimeout(() => {
                        setDownload(false)
                    }, 1000)
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${newFile}.${payload.fileName.split('.').pop()}`);
                    document.body.appendChild(link);
                    link.click();
                }).catch((error) => {
                    setMessage({ showMsg: true, message: error, severity: 'error' })
                    // setLoading(false);
                    setDownload(false)
                })
                setLoading(false);
            }
        }).catch(error => {
            setLoading(false);
            if (error.message) {
                setMessage({ showMsg: true, message: error.message, severity: 'error' });
            }
        })
    }

    const downloadFileOtpLoginUsers = (payload) => {
        // setDownload(true)
        // const newFile = fileName.split('/').pop().split('.')[0]
        // PublicAxiosInterceptor.post(`${process.env.REACT_APP_AWS_GATEWAY_POLL_QUESTION_API_URL}/download_file`,
        //     {
        //         bucket: bucketName,
        //         file_name: fileName,
        //         file_event: fileEvent,
        //         version_id: versionId,
        //         poll_id: props.poll_id,
        //         poll_question_id: props.poll_question_id,
        //         user_id: props.userId
        //     }).then((response) => {
        //         const link = document.createElement('a');
        //         link.href = response.data;
        //         link.setAttribute('download', `${newFile}.${fileName.split('.').pop()}`);
        //         document.body.appendChild(link);
        //         link.click();
        //         // setLoading(false);
        //         setDownload(false)
        //     }).catch(() => {
        //         // setLoading(false);
        //         setDownload(false)
        //     });
        const newFile = payload.fileName.split('/').pop().split('.')[0]
        setMessage({ showMsg: false, message: '', severity: '' });
        setLoading(true);
        PublicAxiosInterceptor.post(`file/download-link`, payload).then(url => {
            if (url) {
                axios({
                    url: url,
                    method: 'GET',
                    responseType: 'blob'
                }).then((response) => {
                    // setLoading(false);
                    setTimeout(() => {
                        setDownload(false)
                    }, 1000)
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${newFile}.${payload.fileName.split('.').pop()}`);
                    document.body.appendChild(link);
                    link.click();
                }).catch((error) => {
                    setMessage({ showMsg: true, message: error, severity: 'error' })
                    // setLoading(false);
                    setDownload(false)
                })
                setLoading(false);
            }
        }).catch(error => {
            setLoading(false);
            if (error.message) {
                setMessage({ showMsg: true, message: error.message, severity: 'error' });
            }
        })
    }

    const deleteFile = (key) => {
        setMessage({ showMsg: false, message: '', severity: '' });
        setLoading(true)
        setLoading(true);
        AxiosEvotingInterceptor.delete(`file/delete-file/${poll_id}?fileName=${key}`)
            .then(response => {
                if (response) {
                    setPrevFileDetail([])
                    setMessage({ showMsg: false, message: '', severity: '' })
                    // setFileDetail([]);
                    if (poll_question_id) {
                        getPollQuestionFiles("''");
                    } else {
                        getPollFiles("''");
                    }
                    setPage(0)
                    setMessage({ showMsg: true, message: t("File_deleted_successfully"), severity: 'success' })
                    resetConfirmation()
                }
                // setLoading(false);
            }).catch(error => {
                setLoading(false);
                if (error.message) {
                    setMessage({ showMsg: true, message: error.message, severity: 'error' });
                }
            })
    }

    useEffect(() => {
        const index = fileDetail.length - 1;
        setFileDetail([]);
        setPrevFileDetail([])
        if (props.otpLogin) {
            getFilesForLinkUsers(fileDetail.length > 0 && page != 0 ? fileDetail[index].file_name : "''");
        } else {
            if (poll_question_id) {
                getPollQuestionFiles(fileDetail.length > 0 && page != 0 ? fileDetail[index].file_name : "''");
            } else {
                getPollFiles(fileDetail.length > 0 && page != 0 ? fileDetail[index].file_name : "''")
            }
        }
    }, [rowsPerPage]);

    const getFilesForLinkUsers = (startingToken) => {
        setMessage({ showMsg: false, message: '', severity: '' });
        setLoading(true);
        PublicAxiosInterceptor.get(poll_question_id ? `file/${poll_id}/${poll_question_id}/${rowsPerPage}?startingToken=${startingToken}` : 
        `file/${poll_id}/${rowsPerPage}?startingToken=${startingToken}`)
        .then(response => {
            setFileDetail([])
            if (response != null) {
                response.files.map(file => {
                    setLoading(true);
                    setFileDetail(prevState => ([
                        ...prevState,
                        {
                            file_name: file.s3ObjectFile.key.split('/').pop(),
                            lastModified: file.s3ObjectFile.lastModified,
                            size: file.s3ObjectFile.size,
                            key: file.s3ObjectFile.key,
                            preSignedUrl: file.presignedUrl,
                            matter: file.matter
                        }
                    ]))
                    setPrevFileDetail(prevState => ([
                        ...prevState,
                        {
                            file_name: file.s3ObjectFile.key.split('/').pop(),
                            lastModified: file.s3ObjectFile.lastModified,
                            size: file.s3ObjectFile.size,
                            key: file.s3ObjectFile.key,
                            preSignedUrl: file.presignedUrl,
                            matter: file.matter
                        }
                    ]))
                })
                setScannedCount(response.count)
                setLoading(false);
            } else {
                setScannedCount(0)
            }
        }).catch(error => {
            setLoading(false);
            if (error.message) {
                setMessage({ showMsg: true, message: error.message, severity: 'error' });
            }
        })
    }


    const onUploadClick = async (files) => {
        setSelectedFiles(files)
        /* uploading first file in files list */
    }

    useEffect(() => {
        if (files.length > 0) {
            uploadExcelFile(files[0].name);
        }
    }, [selectedFiles])


    const uploadExcelFile = async (fileName) => {
        setMessage({ showMsg: false, message: '', severity: '' });
        let payload = {
            bucket: process.env.REACT_APP_EVOTING_S3_BUCKET_NAME,
            fileName: poll_question_id === null ? process.env.REACT_APP_EVOTING_S3_FOLDER_NAME + '/' + poll_id + '/' + 'files' + '/' + fileName
                : process.env.REACT_APP_EVOTING_S3_FOLDER_NAME + '/' + poll_id + '/' + 'files' + '/' + poll_question_id + '/' + fileName,
            versionId: '',
            httpMethod: 'PUT',
            pollId: poll_id
        }
        AxiosEvotingUploadInterceptor.post(`file/upload-link/${poll_id}`, payload).then(response => {
            if (response) {
                onFileUpload(response, selectedFiles[countUrl], countUrl)
                // if (countUrl < (selectedFiles.length - 1)) {
                //     /* setting countUrl for next file Upload */
                //     setCountUrl(countUrl + 1)
                // }
            }
        }).catch(error => {
            if (error.message) {
                setMessage({ showMsg: true, message: error.message, severity: 'error' });
            }
        })
    }

    const onFileUpload = (presignedUrl, uploadEachFile, index) => {
        setMessage({ showMsg: false, message: '', severity: '' })
        axios.put(presignedUrl, uploadEachFile, {
            headers: {
                'Content-Type': uploadEachFile.type
            },
        }).then(function (response) {
                if (index < (selectedFiles.length - 1)) {
                    setCountUrl(index + 1)
                }
            // setPage(0)
            // setUploading(true);
            setUploadedFile(prevState => [
                ...prevState,
                uploadEachFile.path
            ])
            /* last index's file is uploaded */
            if (index == (selectedFiles.length - 1)) {
                // setUploading(true)
                setTimeout(() => {
                    setDialogOpen(false);
                    setUploadedFile([])
                    setUploading(false)
                    setFiles([])
                    if (typeof handleUploadDone !== 'undefined' && typeof handleUploadDone === 'function') {
                        handleUploadDone();
                    }
                    setMessage({ showMsg: true, message: `${'Poll'} files uploaded successfully`, severity: 'success' })
                    setFileDetail([]);
                    if (poll_question_id) {
                        getPollQuestionFiles("''");
                    } else {
                        getPollFiles("''")
                    }
                }, 1500)
               
            }
        }).catch(function (error) {
            setMessage({ showMsg: true, message: error.message, severity: 'error' })
            setUploading(false)
        });

    };

    useEffect(() => {
        if (countUrl != 0) {
            uploadExcelFile(selectedFiles[countUrl].name)
        }
    }, [countUrl]);

    const columns = [
        {
            id: 'file_name',
            label: 'File Name',
            minWidth: 250,
            renderCell: (value) => {
                return (<>
                    <Stack spacing={3} direction='row' alignItems='center'>
                        <Stack width={{ xs: '1%' }}><FileIcon fontSize='1.4rem' fileName={value} /></Stack>
                        <Stack width={{ xs: '95%' }}><Tooltip title={value} arrow>
                            <Typography color='textPrimary' style={{ marginLeft: '5px', maxWidth: '300px' }} noWrap>{value}</Typography>
                        </Tooltip></Stack>
                    </Stack>
                </>)
            }
        },
        {
            id: 'matter',
            label: `Related To ${pollCategory === TypeOfPollCategory.IBC ? t('Resolution') : 'Question'}`,
            minWidth: 130,
            align: 'left',
            renderCell: (value) => {
                return (<Typography color='textSecondary'>{(value === null ? `All ${questionTitle ? questionTitle : 'Question'}` : value)}</Typography>)
            }
        },
        {
            id: 'lastModified', label: 'Date Modified', minWidth: 170,
            renderCell: (value) => {
                return (
                    <Stack direction='row' spacing={1}>
                        <Typography color='textSecondary'>{format(new Date(value), 'dd MMM yyyy')}</Typography>
                        <Typography color='textSecondary'>{format(new Date(value), 'h:mm a')}</Typography>
                    </Stack>
                )
            }
        },
        {
            id: 'size',
            label: 'Size (KB)',
            minWidth: 130,
            align: 'left',
            renderCell: (value) => {
                return (<Typography color='textSecondary'>{(value / 1024).toFixed(2)}</Typography>)
            }
        },
        {
            id: 'actions',
            label: 'Actions',
            minWidth: 80,
            align: 'center',
            renderCell: (value, row) => {
                return (<Stack spacing={1} direction='row' justifyContent='center'>
                    <Tooltip title={t('Download_File')}>
                        <ButtonBase sx={{ borderRadius: '15px' }} id="download-file-btn" onClick={() => downloadFiles(row.key)} className={`float-right`}
                            disabled={!hasSystemAdmineOrSupportRole && (props.pollStatus === Status.closed || props.pollStatus === Status.canceled)}>
                            <FileAvatarDownload variant="rounded" id="download-file">
                                <GetAppOutlined stroke={1.5} size="1.3rem" id="download-icon" />
                            </FileAvatarDownload>
                        </ButtonBase>
                    </Tooltip>
                    {(deletePermission || hasSystemAdmineOrSupportRole) &&
                        <Tooltip title={t('Delete_File')}>
                            <ButtonBase sx={{ borderRadius: '15px' }} id="delete-file-btn" onClick={() => confirmaion(row.key)} className={`float-right`}
                                disabled={  !hasSystemAdmineOrSupportRole && (props.pollStatus === Status.closed || props.pollStatus === Status.canceled) }>
                                <FileAvatarDelete variant="rounded" id="delete-file">
                                    <DeleteOutline stroke={1.5} size="1.3rem" id="delete-icon" />
                                </FileAvatarDelete>
                            </ButtonBase>
                        </Tooltip>
                    }
                </Stack>)
            }
        },
    ];

    const confirmaion = (key) => {
        const myText = key.split('/').pop()
        setSeekConfitmation(prevState => ({
            ...prevState,
            show: true,
            message: <Fragment>{t('Are_You_Sure_That_You_Want_To')} <b>{t('Delete')}</b> {t('File')} <b>{myText}</b> {t('?')}</Fragment>,
            onAgree: () => deleteFile(key),
            onDisAgree: resetConfirmation,
        }))
    }

    const resetConfirmation = () => {
        setSeekConfitmation(prevState => ({
            ...prevState,
            show: false,
            title: t("Confirmation"),
            message: '',
            onDisAgree: '',
            onAgree: ''
        }))
    }
    return (
        <Fragment>
            {((isUpload && isDownload && (props.pollStatus != Status.closed && props.pollStatus != Status.canceled)) || hasSystemAdmineOrSupportRole) && <div style={{ display: 'flex', justifyContent: 'end' }}>
                <Button color='primary' id='Upload-file' variant='contained' onClick={handleClick} style={{ marginBottom: '10px' }}>
                    <AiOutlineCloudUpload size='1.5rem' /> &nbsp;Upload
                </Button></div>}
            <div>
                {dialogOpen && <UploadDialog
                    dialogOpen={dialogOpen}
                    title={title ? title : 'Upload Poll Files'}
                    t={t} setDialogOpen={setDialogOpen}
                    files={files}
                    isMultiple={isMultiple ? isMultiple : false}
                    setFiles={setFiles}
                    uploadedFile={uploadedFile}
                    onUploadClick={onUploadClick}
                    handleUploadClose={handleUploadClose ? handleUploadClose : null}
                />}
                {isDownload && <Paper>
                    <FileTable columns={columns} rows={fileDetail}
                        loading={loading} rowsPerPage={rowsPerPage} page={page} />
                    {fileDetail.length > 0 &&
                        <Stack padding={{ xs: 1 }}>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={scannedCount}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </Stack>}
                </Paper>}
                {(loading || uploading || download) && <EligoBackdrop show={(loading || uploading || download)} />}
                {message.showMsg && <EligoSnackBar show={message.showMsg} message={message.message}
                    severity={message.severity} />}
                {
                    seekConfirmation.show && <EligoConfirmDialog show={seekConfirmation.show}
                        title={seekConfirmation.title} message={seekConfirmation.message}
                        onAgree={seekConfirmation.onAgree} onDisAgree={seekConfirmation.onDisAgree} ConfirmFor={true} />
                }
            </div>
        </Fragment>
    )
}

export default FileUploadDownload;