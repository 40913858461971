import React from 'react';
import ContactUs from './ContactUs';
import { Button, Popover, Card, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    contactCard: {
        margin: '8px'
    }
})

const ContactPopup = () => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
        
    const open = Boolean(anchorEl);
    const id = open ? 'contact-us-popover' : undefined;

    return (
        <React.Fragment>
            <Button aria-label="contact-us" color="primary" className="float-right"
                    variant='outlined' style={{marginRight: '10px'}} onClick={handleClick}>
                Contact Us
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
                }}>
                    {/* <Card className={classes.contactCard}> */}
                        <ContactUs />
                    {/* </Card> */}
            </Popover>
        </React.Fragment>
    )
}

export default ContactPopup