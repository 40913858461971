import { Dialog, IconButton, makeStyles, Tooltip } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { DialogContent, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import EligoBackdrop from '../../../common/EligoBackdrop'
import EligoSnackBar from '../../../common/EligoSnackBar'
import { AxiosEvotingInterceptor } from '../../../config/axios.interceptor'
import MultiVoter from '../poll-round-voter-list/MultiVoter'
import MultipleAuthRep from '../poll-round-voter-list/MultipleAuthRep'

const useStyle = makeStyles(theme => ({
    headerBorder: {
        borderBottom: '1px solid #DEDEDE',
        background: '#EEF2F4',
    },
    name: {
        fontSize: '1.5rem !important',
        color: '#5D6F78',
        fontWeight: '300px',
    },
    button: {
        color: 'red',
        background: 'rgb(253, 245, 245)',
        margin: '5px 5px 5px 0px'
    },
}))
const AuthRepForExistingVoter = (props) => {
    console.log("setAuthsReps,.,.", props)
    const classes = useStyle()
    const { t } = useTranslation();
    const [authRepValues, setAuthRepValues] = useState({})
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState({
        show: false,
        message: '',
        severity: ''
    })
    const [page, setPage] = useState(1);
    const [itemsPerPage, setItemPerPage] = useState(5);
    const [newAuthRep, setNewAuthRep] = useState([{ name: '', email: [{ email: '' }], phoneNumber: [{ phoneNumber: '' }] }]);


    const handleClose = () => {
        props.setAuthReps(false)
    }

    const saveAuthRepForExisting = (pollRoundVoters) => {
        setLoading(true)
        const payload = pollRoundVoters
        AxiosEvotingInterceptor.post(`voter/auth-rep/${props.pollId}/${props.pollRoundVoter.voterId}?page=${page}&limit=${itemsPerPage}&asc=false`, payload).then(response => {
            props.refreshList.refreshVoter()
        }).catch((error) => {
            setLoading(false)
            setMessage({
                show: true,
                message: error.message,
                severity: 'error'
            })
        })
    }

    const setVoters = (voters) => {
        setAuthRepValues(voters)
    }

    return (
        <div>
            <Dialog open={props.open} onClose={handleClose}
                PaperProps={{
                    style: {
                        // backgroundColor: '#f9f9f9',
                        boxShadow: "none",
                        minWidth: '67%'
                    },
                }}>
                <Stack justifyContent='space-between' direction='row' style={{ borderBottom: '1px solid gray', background: '#f0fcfb', padding: '0px' }} >
                    <Stack direction='row' style={{ marginLeft: '1rem' }}>
                        <Typography style={{ fontSize: '20px', margin: '16px 0px 0px 15px' }}>{t("Add_authorized_Representative_For")} <b>{props.pollRoundVoter.name}</b></Typography>
                    </Stack>
                    <Stack>
                        <IconButton onClick={handleClose} className={classes.button}>
                            <Tooltip title='Close'>
                                <CloseIcon />
                            </Tooltip>
                        </IconButton>
                    </Stack>
                </Stack>
                <DialogContent>
                    {/* <MultiVoter pollRoundVoters={setVoters} handleClose={handleClose} AuthRepForExistingVoter={true} addAuthRepExistingVoter={saveAuthRepForExisting} /> */}

                    <MultipleAuthRep increaseAuthreps={newAuthRep} setNewAuthRep={setNewAuthRep} setLoading={setLoading} handleClose={handleClose} AuthRepForExistingVoter={true} addAuthRepExistingVoter={saveAuthRepForExisting} />
                </DialogContent>
                {loading && <EligoBackdrop show={true} />}
                {
                    message.show && <EligoSnackBar show={message.show} message={message.message} severity={message.severity} />
                }
            </Dialog>
        </div>
    )
}

export default AuthRepForExistingVoter