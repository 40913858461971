export const QuestionMenu = {
    matter: 'matter',
    option: 'option',
    settings: 'settings',
    files: 'files',
    preview: 'preview',
    result: 'result'
}

export const QuestionType = {
    SingleChoice: 'SINGLE_CHOICE',
    MultiChoice: 'MULTI_CHOICE',
    Ranking: 'RANKING',
    Rating: "RATING"
}

export const OptionType = {
    Text: 'TEXT',
    Image: 'IMAGE',
    TextAndImage: 'TEXT_AND_IMAGE',
}