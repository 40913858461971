import { Chip, Typography, styled } from "@material-ui/core";
import { Stack } from "@mui/material";

export const RootDiv = styled('div')(() => ({
    backgroundImage: `url(${process.env.PUBLIC_URL + '/images/mainpage.svg'})`,
    backgroundRepeat: 'no-repeat',
    padding: "8px",
    backgroundSize: 'cover',
    background: 'linear-gradient(45deg, #2a83ad, #f0f2f0)'
}))

export const ChipPollTitle = styled(Chip)(() => ({
    background: 'rgb(241 242 255)',
    color: 'rgb(24 32 123 / 87%)'
}))

export const ChipPollStatus = styled(Chip)(() => ({
    background: 'rgb(251 241 234);',
    color: 'rgb(196 104 13)'
}))

export const BorderDivider = styled('div')(() => ({
    borderTop: '1px solid blue',
    // borderBottom: '1px solid blue',
    background: '#FEFCFF',
    marginTop: '20px',
    paddingBottom: '10px'
}))