import { Tooltip, withStyles } from '@material-ui/core';
import { Help } from '@material-ui/icons';
import React, { Fragment } from 'react';

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(13)
    },
}))(Tooltip);

const FieldHelperText = ({ helperText }) => {
    return (
        <HtmlTooltip title={
            <Fragment>
                {helperText}
            </Fragment>
        }
            arrow
        >
            <Help style={{ width: '20px', color: '#a27004' }} />
        </HtmlTooltip>
    )
}

export default FieldHelperText