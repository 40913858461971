import { Button, Typography } from "@material-ui/core";
import { BottomNavigation, Card, CardContent, Stack } from "@mui/material";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import EligoBackdrop from "../../../common/EligoBackdrop";
import EligoSnackBar from "../../../common/EligoSnackBar";
import { Status, TypeOfPoll, TypeOfPollCategory } from "../../../common/GenericCodes";
import EligoPagination from "../../../common/pagination/EligoPagination";
import { APIEvotingAxiosInterceptor } from "../../../config/axios.interceptor";
import AddPollQuestion from "../add-poll-question/AddPollQuestion";
import PollQuestionGridView from "./poll-question-grid-view/PollQuestionGridView";
import PollQuestionListView from "./poll-question-list-view/PollQuestionListView";

const PollQuestionList = (props) => {
    const { pollId, hasPermission,hasSystemAdmineOrSupportRole, status, pollType, totalVoterCount, 
        noOfVotedNotVoted, startTime, endTime, realTimeResult, filePermission, pollClass } = props;
    const {t} = useTranslation();
    const [isListView, setIsListView] = useState(true);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [editQuestion, setEditQuestion] = useState({
        question: null,
        questionNumber: null
    });
    const [isEdit, setIsEdit] = useState(false);
    const [pollQuestionCount, setPollQuestionCount] = useState();
    const [pollQuestionList, setPollQuestionList] = useState([]);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState({
        showMsg: false,
        message: null,
        severity: null
    });

    const listQuestions = () => {
        setLoading(true);
        APIEvotingAxiosInterceptor.get(`question/${pollId}?page=${page}&size=${itemsPerPage}&asc=true`).then(
            (response) => {
                setPollQuestionList(response.questions);
                setPollQuestionCount(response.count);
                setLoading(false);
        }).catch(error => {
            setLoading(false);
            if (error.message) {
                setMessage({ showMsg: true, message: error.message, severity: 'error' });
            }
        })
    }

    const handleRefresh = () => {
        listQuestions();
    }

    const handleViewChange = (isList) => {
        setIsListView(isList);
    }

    const handleChangeItemsPerPage = (event) => {
        setItemsPerPage(event.target.value);
        setPage(1);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleQuestionEdit = (question, questionNumber, id) => {
        setEditQuestion({
            question: question,
            questionNumber: questionNumber,
            id: id,
        })
        setOpen(true);
        setIsEdit(true);
    }

    const handleClose = (message) => {
        setOpen(false);
        setMessage({
            showMsg: false,
            message: null,
            severity: null
        })
        setTimeout(() => {
            setMessage(message)
        }, 50)
        listQuestions();
        setIsEdit(false);
    }

    const handleCancel = () => {
        setOpen(false);
        setEditQuestion({
            question: null,
            questionNumber: null
        })
        listQuestions();
        setIsEdit(false);
    }

    const PollListViewModel = useCallback(() => {
        return (
            <Fragment>
                {
                    pollQuestionList.length > 0 && <Fragment>
                        {isListView && <Stack mt={2}>
                            <PollQuestionListView pollQuestionList={pollQuestionList.sort((a, b) => a.displaySequence - b.displaySequence)}
                                page={page} itemsPerPage={itemsPerPage}
                                setLoading={setLoading}
                                setMessage={setMessage}
                                handleRefresh={handleRefresh}
                                pollId={pollId}
                                handleQuestionEdit={handleQuestionEdit}
                                listTitle={pollClass === TypeOfPoll.POLL ? ((TypeOfPollCategory.IBC === pollType) ? t('R') : t('Q')) : t('SI')}
                                questionTitle={pollClass === TypeOfPoll.POLL ? ((TypeOfPollCategory.IBC === pollType) ? t('Resolution') : t('Question')) : t('Survey_Item')}
                                // listTitle={t('Q')}
                                totalVoterCount={totalVoterCount}
                                noOfVotedNotVoted={noOfVotedNotVoted}
                                hasPermission={hasPermission}
                                filePermission={filePermission}
                                pollQuestionCount={pollQuestionCount}
                                setPage={setPage}
                                status={status}
                                realTimeResult={realTimeResult}
                                hasSystemAdmineOrSupportRole={hasSystemAdmineOrSupportRole}
                            />
                        </Stack>}
                        {!isListView && <Stack>
                            <PollQuestionGridView pollQuestionList={pollQuestionList} />
                        </Stack>}
                    </Fragment>
                }
            </Fragment>
        )
    }, [pollQuestionList])

    useEffect(() => {
        setLoading(true);
        listQuestions();
    }, [page, itemsPerPage])

    return (
        <Card style={{ boxShadow: 'none', background: 'transparent', paddingBottom: '100px', position: 'relative' }}>
            <CardContent style={{ padding: '5px 15px', maxHeight: Status.new === status.toUpperCase() ? "440px": "calc(100vh - 265px)", overflowY: 'scroll' }}>
                <Stack direction='row' spacing={2} justifyContent='end'>
                    {/* {pollQuestionCount !== undefined && pollQuestionCount > 0 && <ButtonGroup variant="outlined" aria-label="text button group" size="small">
                    <Tooltip title={t('List_View')}>
                        <Button id="list-question" color="secondary" variant={isListView ? "contained" : "outlined"}
                            onClick={() => handleViewChange(true)}><ListAltRounded /></Button>
                    </Tooltip>
                    <Tooltip title={t('Grid_View')}>
                        <Button id="qrid-question" color="secondary" variant={!isListView ? "contained" : "outlined"}
                            onClick={() => handleViewChange(false)}><GridOn /></Button>
                    </Tooltip>
                </ButtonGroup>} */}
                    {(hasPermission || hasSystemAdmineOrSupportRole) && <Button id="add-matter" variant='contained' size='small' color='primary' onClick={() => setOpen(true)}>
                        {pollClass === TypeOfPoll.POLL && ((TypeOfPollCategory.IBC === pollType) ? t('Add_Resolution') : t('Add_Question'))}
                        {pollClass === TypeOfPoll.SURVEY && t('Add_Survey_Items')}
                    </Button>}
                </Stack>
                <PollListViewModel />
                {(!loading && pollQuestionCount !== undefined && pollQuestionCount === 0) &&
                    <Stack direction='column' justifyContent='center' alignItems='center' sx={{ pt: '10%' }} spacing={1}>
                        <img src={process.env.PUBLIC_URL + '/images/no-matter.svg'} width='15%'></img>
                        <Typography id='matter-not-found' className='polls-not-found'>
                            {pollClass === TypeOfPoll.POLL && ((TypeOfPollCategory.IBC === pollType) ? t('No_Resolutions_Are_Available') : t('No_Questions_Are_Available'))}
                            {pollClass === TypeOfPoll.SURVEY && t('No_Survey_Items_Are_Available')}
                        </Typography>
                        {(hasPermission || hasSystemAdmineOrSupportRole) &&
                            <Button className='polls-not-found' variant='outlined' color='primary' onClick={() => setOpen(true)}>
                                {/* {(TypeOfPoll.POLL === pollType) ? t('Add_Matter') : t('Add_Question') } */}
                                {pollClass === TypeOfPoll.POLL && ((TypeOfPollCategory.IBC === pollType) ? t('Add_Resolution') : t('Add_Question'))}
                                {pollClass === TypeOfPoll.SURVEY && t('Add_Survey_Items')}
                            </Button>
                        }
                    </Stack>
                }
                {!loading && pollQuestionCount !== undefined && pollQuestionCount > 5 &&
                    <BottomNavigation sx={{ position: 'fixed', bottom: '20px', background: '#fff', border: '1px solid #eeeeee', width: 'calc(100% - 52px)', paddingX: '10px', position: 'absolute' }}>
                        <EligoPagination rowsPerPage={itemsPerPage} page={page} totalItems={pollQuestionCount}
                            onRowsPerPageChange={handleChangeItemsPerPage} onPageChange={handleChangePage} />
                    </BottomNavigation>
                }
                {open && <AddPollQuestion
                    editQuestion={editQuestion.question}
                    title={pollClass === TypeOfPoll.POLL ? ((TypeOfPollCategory.IBC === pollType) ? t('Resolution') : t('Question')) : t('Survey_Item')}
                    // title={t('Question')}
                    hasPermission={hasPermission}
                    pollStatus={status}
                    startTime={startTime}
                    endTime={endTime}
                    pollType={pollType}
                    setIsEdit={setIsEdit}
                    isEdit={isEdit}
                    realTimeResult={realTimeResult}
                    totalVoterCount={totalVoterCount}
                    noOfVotedNotVoted={noOfVotedNotVoted}
                    filePermission={filePermission}
                    listTitle={(TypeOfPollCategory.IBC === pollType) ? t('R') : t('Q')}
                    hasSystemAdmineOrSupportRole={hasSystemAdmineOrSupportRole}
                    open={open} onClose={handleClose} onCancel={handleCancel} pollId={pollId}
                    questionNumber={isEdit ? editQuestion.questionNumber : pollQuestionCount !== undefined && pollQuestionCount === 0 ? 1 : pollQuestionCount + 1} 
                    pollClass={pollClass}/>}
                {message.showMsg && <EligoSnackBar show={message.showMsg} message={message.message} severity={message.severity} />}
                {loading && <EligoBackdrop show={loading} />}
            </CardContent>
        </Card>
    )

}

export default React.memo(PollQuestionList);