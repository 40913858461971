
import { Container, Typography, makeStyles } from '@material-ui/core';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import InfoIcon from '@material-ui/icons/Info';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { Stack } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsCheckCircleFill } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import EligoBackdrop from '../../common/EligoBackdrop';
import EligoSnackBar from '../../common/EligoSnackBar';
import { Status } from '../../common/GenericCodes';
import { UserContext } from '../../common/context/UserContext';
import './CastVote.css';
import CastVoteDetails from './CastVoteDetails';
import PollTimer from './PollTimer';
import Questions from './Questions/Questions';
import ReviewVotes from './ReviewVotes';
import VotersListForAuthrep from './VotersListForAuthrep';


const useStyles = makeStyles((theme) => ({
    width: {
        maxWidth: '1400px !important'
    }
}));


const StepperView = (props) => {
    const classes = useStyles()
    const { t } = useTranslation();
    const context = useContext(UserContext);
    let history = useHistory();
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState({
        showMsg: false,
        message: null,
        severity: null
    })

    const [isTimerTerminated, setIsTimerTerminated] = useState(false);
    const [voteVar, setVoteVar] = useState(false);
    const [reviewVotes, setReviewVotes] = useState(false)
    const [showVote, setShowVote] = useState(false)
    const [AuthRepPage, setAuthRepPage] = useState(props.location.state.AuthRepPage)

    const { pollDetails, onlyVoter, otpLogin, page, roles, origin, path, pollType, voterEdgeIdForSingleVoter, AuthrepIdForSingleVoter, VoterIdForsingleVoter, authRepVerified, votedAuthRep,
        authRepId, voterEdgeId, voterIdInList, authRepView, authRepVotersList, authorisedPersonId, voterName, onlyOneVoter } = props.location.state


    const reloadPollDetails = () => {
        setIsTimerTerminated(true);
    }

    // const instructionPage = () => {
    //     setVoteVar(false)
    //     setReviewVotes(false)
    //     // setAuthRepPage(true)
    // }
    // const questionPage = () => {
    //     setTimeout(() => {
    //         setMessage({ showMsg: false, message: null, severity: null });
    //     }, 3000)
    //     if (!authRepVerified || pollDetails.allow_vote_change == true) {
    //         setVoteVar(true)
    //         setReviewVotes(false)
    //     } else {
    //         setMessage({
    //             showMsg: true,
    //             message: t("You_are_not_allowed_to_recast"),
    //             severity: 'error'

    //         })
    //     }
    // }

    // const reviewPage = () => {
    //     setTimeout(() => {
    //         setMessage({ showMsg: false, message: null, severity: null });
    //     }, 3000)
    //     if (!authRepVerified || pollDetails.allow_vote_change == true) {
    //         if ((authRepVerified || votedAuthRep || voted)) {
    //             setReviewVotes(true)
    //             setVoteVar(false)
    //         }
    //         else {
    //             setMessage({ showMsg: true, message: t("Please_Cast_your_Vote_before_reviewing/confirming"), severity: 'error' });
    //         }

    //     } else {
    //         setMessage({
    //             showMsg: true,
    //             message: t("You_are_not_allowed_to_recast"),
    //             severity: 'error'

    //         })
    //     }
    // }
    // const multipleVotersForAauthRep = () => {
    //     setAuthRepPage(false)
    // }
    const instruction = 'icon indicator red-instruct';

    const voting = 'icon indicator orange-matter';

    const confirmVote = 'icon indicator green-review';

    return (
        <div >
            <Container className={classes.width}>
                {!showVote && !authRepView && pollDetails.status == Status.open && pollDetails.number_of_question > 0 && <Stack justifyContent='center' alignItems='center' spacing={1.5} pt={0.5} pb={2} px={1}
                    direction={{ xs: 'column', sm: 'row' }} >
                    <Stack className='navigation' direction='row' spacing={2}>
                        {/* onClick={multipleVotersForAauthRep} */}
                        {!otpLogin && !onlyOneVoter && !onlyVoter && <Stack justifyContent='space-around' alignItems='center' direction='row' >
                            <span className={(!voteVar && !reviewVotes && !AuthRepPage) && instruction} ><InfoIcon /></span> & nbsp;&nbsp;
                            <span className={(!voteVar && !reviewVotes && !AuthRepPage) ? 'texts' : 'text-not-selected'} >
                                {t("Authorized Representatives")}
                            </span>
                        </Stack>}
                        {/* onClick={instructionPage} */}
                        <Stack justifyContent='space-around' alignItems='center' direction='row' >
                            <span className={(!voteVar && !reviewVotes && (AuthRepPage || onlyOneVoter || onlyVoter || otpLogin)) && instruction} ><InfoIcon /></span>&nbsp;&nbsp;
                            <span className={(!voteVar && !reviewVotes && (AuthRepPage || onlyOneVoter || onlyVoter || otpLogin)) ? 'texts' : 'text-not-selected'} >
                                {t("Poll_Instructions")}
                            </span>
                        </Stack>
                        <span className='button'><KeyboardArrowRightIcon /></span>
                        {/* onClick={questionPage} */}
                        <Stack justifyContent='space-around' alignItems='center' direction='row' >
                            <span className={(voteVar && !reviewVotes) && voting} ><ContactSupportIcon /></span>&nbsp;&nbsp;
                            <span className={(voteVar && !reviewVotes) ? 'texts' : 'text-not-selected'} >
                                {t("Vote")}
                            </span>
                        </Stack>
                        <span className='button'><KeyboardArrowRightIcon /></span>
                        {/* onClick={reviewPage} */}
                        <Stack justifyContent='center' alignItems='center' direction='row' >
                            <span className={reviewVotes && confirmVote} ><BsCheckCircleFill fontSize='larger' /></span>&nbsp;&nbsp;
                            <span className={reviewVotes ? 'texts' : 'text-not-selected'} >
                                {t("Review_and_Confirm")}
                            </span>
                        </Stack>
                    </Stack>
                    {(pollDetails.status == Status.open || pollDetails.status == Status.closed) && <span style={{ marginTop: '3%' }}><PollTimer date={pollDetails.end_date_time}
                        terminated={reloadPollDetails} title={"Poll Ends In"} /></span>}
                </Stack>}
                <br />
                {(pollDetails.status == Status.open && pollDetails.number_of_question > 0) && <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold', textAlign: 'center' }}>
                    <span style={{ width: '30%' }}>{pollDetails.title}</span>
                </Typography>}
                {(pollDetails.status == Status.open && pollDetails.number_of_question > 0) && <Typography variant="h6" gutterBottom style={{ textAlign: 'center' }}>
                    {pollDetails.description}
                </Typography>}
                {/* //location={{ state: { pollDetails: pollDetails } }} */}
                {!AuthRepPage && !onlyOneVoter && !onlyVoter && !otpLogin &&
                    <VotersListForAuthrep authRepView={authRepView} setAuthRepPage={setAuthRepPage} authRepVotersList={authRepVotersList} authRepId={authRepId} pollDetails={pollDetails} />
                }

                {(!voteVar && !reviewVotes && (AuthRepPage || onlyOneVoter || onlyVoter || otpLogin)) && <CastVoteDetails pollDetails={pollDetails} origin={origin} path={path} setAuthRepPage={setAuthRepPage} voterName={voterName} authorisedPersonId={authorisedPersonId} onlyVoter={onlyVoter} page={page} roles={roles} pollType={pollType} voteVar={setVoteVar} userId={props.location.state.userId}
                    otpLogin={otpLogin} setReviewVotes={setReviewVotes} setShowVote={setShowVote} authRepVerified={authRepVerified} votedAuthRep={votedAuthRep} authRepVotersList={authRepVotersList}
                    voterEdgeIdForSingleVoter={voterEdgeIdForSingleVoter} AuthrepIdForSingleVoter={AuthrepIdForSingleVoter} VoterIdForsingleVoter={VoterIdForsingleVoter} cognitoUserId={props.location.state.userId ? props.location.state.userId : context.userSession.session.userId} />}

                {voteVar && !reviewVotes && <Questions pollDetails={pollDetails} authorisedPersonId={authorisedPersonId} otpLogin={props.location.state.otpLogin} onlyVoter={onlyVoter} page={page} roles={roles} pollType={pollType} userId={props.location.state.userId} voteVar={setVoteVar} setReviewVotes={setReviewVotes}
                    voterEdgeIdForSingleVoter={voterEdgeIdForSingleVoter} AuthrepIdForSingleVoter={AuthrepIdForSingleVoter} VoterIdForsingleVoter={VoterIdForsingleVoter} authRepVerified={authRepVerified} votedAuthRep={votedAuthRep}
                    authRepId={authRepId} voterEdgeId={voterEdgeId} voterIdInList={voterIdInList} cognitoUserId={props.location.state.userId ? props.location.state.userId : context.userSession.session.userId} />}
                {
                    (reviewVotes) && <ReviewVotes pollDetails={pollDetails} authorisedPersonId={authorisedPersonId} authRepView={authRepView} onlyVoter={onlyVoter} page={page} roles={roles} otpLogin={props.location.state.otpLogin} pollType={pollType} setReviewVotes={setReviewVotes} userId={props.location.state.userId} voteVar={setVoteVar} authRepVerified={authRepVerified}
                        voterEdgeIdForSingleVoter={voterEdgeIdForSingleVoter} AuthrepIdForSingleVoter={AuthrepIdForSingleVoter} VoterIdForsingleVoter={VoterIdForsingleVoter} showVote={showVote} setShowVote={setShowVote} canModify={pollDetails.verified ? false : true} votedAuthRep={votedAuthRep}
                        authRepId={authRepId} voterEdgeId={voterEdgeId} voterIdInList={voterIdInList} cognitoUserId={props.location.state.userId ? props.location.state.userId : context.userSession.session.userId} />
                }
            </Container>
            {
                message.showMsg && <EligoSnackBar show={message.showMsg} message={message.message}
                    severity={message.severity} />
            }
            {
                (loading) && <EligoBackdrop show={loading} invisible={false} />
            }
        </div >
    )
}

export default StepperView