import React, {
    useEffect,
    useState
}                                   from 'react';
import { 
    makeStyles,
    Grid,
    IconButton
}                                   from '@material-ui/core';
import {
    SortableContainer, 
    SortableElement, 
    arrayMove
}                                   from 'react-sortable-hoc';
import DragIndicatorIcon            from '@material-ui/icons/DragIndicator';
import KeyboardArrowDownIcon        from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon          from '@material-ui/icons/KeyboardArrowUp';

const useStyles = makeStyles((theme) => ({
    option: {
        background: 'white',
        borderRadius: '5px',
        padding: '5px',
        margin: '10px 0px',
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
        cursor: 'pointer'
    },
    selectedOption: {
        color: '#2a83ad',
        border: '1px solid #2a83ad',
        background: '#2a83ad0a'
    }
}));

const RankingOption = SortableElement(({value, optionIndex}) => {
    const classes = useStyles();
    return (
        <Grid container className={classes.option} tabIndex={0} direction="row" alignItems="center">
            <Grid item xs={12}> 
                {value.option} 
                {/* <IconButton style={{float: 'right'}} aria-label="delete">
                    <KeyboardArrowDownIcon />
                </IconButton>
                <IconButton style={{float: 'right'}} aria-label="delete">
                    <KeyboardArrowUpIcon />
                </IconButton> */}
                <DragIndicatorIcon style={{float: 'right'}}/> 
            </Grid>
        </Grid>
    )    
});

const RankingOptionList = SortableContainer(({options}) => {
    return (
        <div>
            {options.map((value, index) => {
                return <RankingOption key={`option-${value}`} index={index} value={value} optionIndex={index}/>
            })}
        </div>
    );
});

const RankingSelection = (props) => {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        props.options.map(option => {
            setOptions(prevState => [
                ...prevState,
                {
                    id: option.id,
                    optionType: option.optionType,
                    option: option.option,
                    rank: option.rank,
                    s3Location: option.s3Location,
                    ratingScale: option.ratingScale,
                    ratingIcon: option.ratingIcon,
                    nextQuestionId: option.nextQuestionId
                }
            ])
        })
    }, [])
    
    const onSortEnd = ({oldIndex, newIndex}) => {
        setOptions(prevItem => (arrayMove(prevItem, oldIndex, newIndex)));
    };

    useEffect(() => {
        props.onRankChange(options);
    }, [options])
    
    return (<RankingOptionList options={options} onSortEnd={onSortEnd} />);
}

export default RankingSelection;