import {
    Card,
    CardContent,
    Grid,
    Typography,
    withStyles
} from '@material-ui/core';
import { DateRangeRounded } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/EditOutlined';
import HowToVoteIcon from '@material-ui/icons/HowToVote';
import MoneyIcon from '@material-ui/icons/Money';
import PaymentIcon from '@material-ui/icons/Payment';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Stack } from '@mui/material';
import axios from 'axios';
import { format } from 'date-fns';
import React, { Fragment, useContext, useState } from 'react';
import { isBrowser } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Btns from '../../../common/Btns';
import EligoBackdrop from '../../../common/EligoBackdrop';
import { Roles, Status, UserRole } from '../../../common/GenericCodes';
import { UserContext } from '../../../common/context/UserContext';
import PaymentListModal from '../../../payment/record-payment/PaymentListModal';
import SpecialPrice from '../../../payment/special-price/SpecialPrice';
import DeletePoll from '../../poll-list/DeletePoll';
import PublishPoll from '../../poll-list/PublishPoll';
import './PollRoundItem.css';

export const CustomCard = withStyles({
    root: {
        boxShadow: 'rgb(32 40 45 / 8%) 0px 2px 14px 0px !important',
        border: '1px solid #eae7e7',
        // transition: "transform 0.15s ease-in-out",
        '&:hover': {
            cursor: 'pointer',
            // transform: "translateX(0.2%)",
            // boxShadow: '0px 15px 10px 2px rgb(0 0 0 /5%), 0px 0px 0px rgb(0 0 0 / 0%) !important',
            boxShadow: 'rgb(255 255 255 / 10%) 0px 5px 10px 0px inset, rgb(152 152 162 / 25%) 0px 30px 50px -20px, rgb(130 130 174 / 30%) 0px 30px 30px -30px !important'
        }
    }
})(Card);

const PollRoundItem = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const context = useContext(UserContext);
    const [openSpecialPrice, setOpenSpecialPrice] = useState({
        show: false,
        poll: ''
    });
    const [openRecordPayment, setOpenRecordPayment] = useState({
        show: false,
        poll: ''
    });
    const [loading, setLoading] = useState(false)
    const [pollRound] = useState({
        alertTime: props.poll.alertTime,
        anonymousVoting: props.poll.anonymousVoting,
        description: props.poll.description,
        discloseVoterInResult: props.poll.discloseVoterInResult,
        createdOn: props.poll.createdOn,
        endTime: props.poll.endTime,
        geoFencing: props.poll.geoFencing,
        geoTagging: props.poll.geoTagging,
        hasVoterRole: props.poll.hasVoterRole,
        numberOfQuestions: props.poll.numberOfQuestions,
        paidVoter: props.poll.paidVoter,
        paymentStatus: props.poll.paymentStatus,
        id: props.poll.id,
        preSignedUrl: props.poll.preSignedUrl,
        questionShuffle: props.poll.questionShuffle,
        referenceNumber: props.poll.referenceNumber,
        resultAnnouncementTime: props.poll.resultAnnouncementTime ? props.poll.resultAnnouncementTime : null,
        roles: props.poll.roles,
        sentResultToVoters: props.poll.sentResultToVoters,
        showResultsToVoters: props.poll.showResultsToVoters,
        sourceIp: props.poll.sourceIp,
        startTime: props.poll.startTime,
        startManually: props.poll.startManually,
        status: props.poll.status,
        title: props.poll.title,
        verified: props.poll.verified,
        voted: props.poll.voted,
        voterCount: props.poll.voterCount,
        voterEdgeId: props.poll.voterEdgeId,
        voterRoleSequence: props.poll.voterRoleSequence,
        websiteRedirect: props.poll.websiteRedirect,
        weightedVotes: props.poll.weightedVotes,
        sentStartAlert: props.poll.sentStartAlert,
        sentEndAlert: props.poll.sentEndAlert,
        announceResultAutomatically: props.poll.announceResultAutomatically,
        voteWithSignUp: props.poll.voteWithSignUp,
        allowVoteChange: props.poll.allowVoteChange,
        scrutinizerHasToOpen: props.poll.scrutinizerHasToOpen,
        organizer_list: [],
        displayOrganizerName: props.poll.displayOrganizerName,
        verifyWithOtp: props.poll.verifyWithOtp,
        pollType: props.poll.pollType,
        pollCategory: props.poll.pollCategory,
        noOfVoterNotVoted: props.poll.noOfVoterNotVoted,
        noOfVoterVoted: props.poll.noOfVoterVoted,
        realTimeResult: props.poll.realTimeResult,
        conductFor: {
            personId: props.poll.conductFor ? props.poll.conductFor.personId : null,
            name: props.poll.conductFor ? props.poll.conductFor.name : null
        },
        zoneId: props.poll.zoneId
    })

    const systemAdmineOrSupport = ((context.userSession.session.roles?.includes(UserRole.Role_Admin)) || (context.userSession.session.roles?.includes(UserRole.Role_Support)))

    const EditPollRound = (editProps) => {
        return (
            <Btns tooltip={t('Edit')} id='edit' label="edit-poll-round" color="primary" btn={<><EditIcon /></>} click={() => showPollRoundInfo(editProps)} />
        )
    }


    const ViewPollRound = (viewProps) => {
        return (
            <Btns tooltip={t('View')} id='visible' label="view-poll-round" color="primary" btn={<><VisibilityIcon /></>} click={() => showPollRoundInfo(viewProps)} />
        )
    }

    const CastVoteForRound = (castVoteProps) => {
        return (
            <Btns tooltip={t('Cast_Vote')} id='vote' label="cast-poll-round" color="primary" btn={<><HowToVoteIcon /></>} click={() => showVoting(castVoteProps)} />
        )
    }

    const ViewVotes = (viewProps) => {
        return (
            <Btns tooltip={t('View_Vote')} id='visibility' label="view-votes" color="primary" btn={<><VisibilityIcon /></>} click={() => showVoting(viewProps)} />
        )
    }

    const SetPrice = (priceProps) => {
        return (
            <Btns tooltip={t('Set_Price')} id='money' label="set-price" color="primary" btn={<><MoneyIcon /></>} click={() => setOpenSpecialPrice({ show: true, poll: priceProps.pollDetails })} />
        )
    }

    const RecordPaymentForPoll = (paymentProps) => {
        return (
            <Btns tooltip={t('Record_Payment')} id='pay' label="record-payment" color="primary" btn={<><PaymentIcon /></>} click={() => setOpenRecordPayment({ show: true, poll: paymentProps.pollDetails })} />
        )
    }

    const VotersListForAuth = (castVoteProps) => {
        setLoading(true)
        axios({
            url: `${process.env.REACT_APP_AWS_GATEWAY_POLL_VOTER_API_URL}/list-auth-rep-voters/${pollRound.poll_id}/${context.userSession.session.userId}`,
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            },
        }).then((response) => {
            const onlyVoter = (response.data.auth_rep_voter.length === 0) ? true : false
            const onlyOneVoter = (response.data.auth_rep_voter.length === 1) ? true : false
            if (response.data.auth_rep_voter.length === 1) {
                response.data.auth_rep_voter.map((value) => {
                    showPollAndRoundInfo(castVoteProps, onlyVoter, onlyOneVoter, castVoteProps.authRepView, response.data.auth_rep_voter, value.verified, value.voted, value.id, value.auth_rep_id, value.voter_edge_id)

                })
            } else if (response.data.auth_rep_voter.length === 0) {
                showPollAndRoundInfo(castVoteProps, onlyVoter, onlyOneVoter, castVoteProps.authRepView, response.data.auth_rep_voter)
            } else {
                showPollAndRoundInfo(castVoteProps, onlyVoter, onlyOneVoter, castVoteProps.authRepView, response.data.auth_rep_voter)
            }
        })
    }
    const showPollAndRoundInfo = (propsInfo, onlyVoter, onlyOneVoter, authRepView, authRepVotersList, authRepVerified, votedAuthRep, id, authRepId, voterEdgeId) => {
        context.setUserSession(prevState => ({
            ...prevState,
            color: 'white'
        })
        )
        history.push({
            pathname: "/poll/stepper-view",
            state: {
                pollDetails: pollRound,
                page: propsInfo.page,
                roles: props.roles,
                pollType: props.pollType,
                voterEdgeIdForSingleVoter: voterEdgeId,
                AuthrepIdForSingleVoter: authRepId,
                VoterIdForsingleVoter: id,
                authRepVerified: authRepVerified,
                votedAuthRep: votedAuthRep,
                onlyVoter: onlyVoter,
                onlyOneVoter: onlyOneVoter,
                authRepView: authRepView,
                authRepVotersList: authRepVotersList,
            }
        })
    }

    const showVoting = (propsInfo) => {
        history.push({
            pathname: "/poll/voting",
            state: {
                poll_id: pollRound.id,
                page: propsInfo.page,
                roles: props.roles,
                pollType: props.pollType
            }
        })
    }

    const showPollRoundInfo = (infoProps) => {
        console.log('infoProps', infoProps)
        history.push({
            pathname: '/polls/details',
            state: {
                pollDetails: infoProps.pollDetails,
                roles: props.roles,
                roleType: props.roleType,
                pollType: props.pollType,
                isEdit: infoProps.isEdit,
                page: infoProps.page,
                itemsPerPage: infoProps.itemsPerPage,
                systemAdmine: infoProps.systemAdmine
            }
        })
    }

    const viewVotes = (infoProps) => {
        history.push({
            pathname: '/polls/details/review-vote',
            state: {
                // pollRound: pollRound,
                limit: pollRound.numberOfQuestions,
                page: infoProps.page,
                pollDetails: infoProps.pollDetails,
                roles: props.roles,
                pollType: props.pollType,
                canModify: false,
                redirectToRounds: true,
                // pollRoundType: props.pollRoundType
            },
        })
    }

    let viewStatus = [Status.open, Status.closed, Status.canceled]

    return (
        <div>
            <CustomCard style={{ margin: "10px" }}>
                <CardContent style={{ background: props.bgStyle, border: props.borderStyle, cursor: 'default', padding: '0.5rem 0.8rem !important' }}>
                    <Grid container spacing={2} alignItems='center'>
                        <Grid item xs={9}>
                            <Grid item xs={12}>
                                <Typography id='poll-title' color="textSecondary" style={{ fontWeight: '600' }}>
                                    {pollRound.title}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: '8px' }}>
                                <Stack direction='row' spacing={1} alignItems='center'>
                                    <DateRangeRounded fontSize='small' style={{ margin: 'auto 0px', color: 'rgb(188 173 65 / 76%)' }} />
                                    <Typography variant='subtitle2' style={{ color: 'green' }}>{t('Start')}:</Typography>
                                    <Typography variant='subtitle1' color="textSecondary" id='poll-endDate'>
                                        {format(new Date(pollRound.startTime), process.env.REACT_APP_DATE_FORMAT_TO_DISPLAY)}
                                        {/* {format(new Date(pollRound.startTime), 'dd MMM yyyy hh:mm a')} */}
                                    </Typography>
                                    &nbsp;&nbsp;
                                    <Typography variant='subtitle2' style={{ color: 'maroon' }}>{t('End')}:</Typography>
                                    <Typography variant='subtitle1' color="textSecondary" id='poll-endDate'>
                                        {format(new Date(pollRound.endTime), process.env.REACT_APP_DATE_FORMAT_TO_DISPLAY)}
                                        {/* {format(new Date(pollRound.endTime), 'dd MMM yyyy hh:mm a')} */}
                                    </Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid item xs={3}>
                            {
                                props.pollStatus === Status.setup && ((props.roles.includes(Roles.organizer)) || systemAdmineOrSupport) && <PublishPoll pollDetails={pollRound} getPollList={props.getPollList}
                                    setPage={props.setPage} setMessage={props.setMessage} systemAdminOrSupport={systemAdmineOrSupport} insidePoll={false}/>
                            }
                            {pollRound.hasVoterRole && props.pollStatus == Status.open &&
                               props.roleType == Roles.voter
                                && <CastVoteForRound pollRoundId={pollRound.id} page={props.page} />
                            }
                            {pollRound.hasVoterRole && props.roleType == Roles.voter && (props.pollStatus != Status.canceled && props.pollStatus != Status.open) &&
                                <Fragment>
                                    <ViewVotes pollDetails={pollRound} pollRoundId={pollRound.poll_id} page={props.page} authRepView={true} />
                                </Fragment>
                            }
                            {isBrowser && props.pollStatus != Status.canceled && props.pollStatus != Status.closed && props.roleType != Roles.voter &&
                                props.roleType != Roles.observer && props.roleType != Roles.scrutinizer &&
                                < DeletePoll style={{ float: 'right' }} pollDetails={pollRound} itemsPerPage={props.itemsPerPage} page={props.page} roles={props.roleType}
                                    getPollList={props.getPollList} setMessage={props.setMessage} pollType={props.pollType} setPage={props.setPage} pollLength={props.pollLength} />
                            }
                            {/*This edit for only organizer that too upcoming and setup poll */}
                            {isBrowser &&  (props.roles.includes(Roles.organizer) && (props.pollStatus === Status.upcoming || props.pollStatus === Status.setup)) && !systemAdmineOrSupport
                                && props.roleType == Roles.organizer && <EditPollRound style={{ float: 'right' }} pollDetails={pollRound} isEdit={true} itemsPerPage={props.itemsPerPage} page={props.page} roles={props.roles} />
                            }
                            {/* when poll is is setup for organizer */}
                            {/* {isBrowser &&  (props.roles.includes(Roles.organizer) && (props.pollStatus === Status.setup))
                                && props.roleType == Roles.organizer && <EditPollRound style={{ float: 'right' }} pollDetails={pollRound} isEdit={true} itemsPerPage={props.itemsPerPage} page={props.page} roles={props.roles} />
                            } */}
                            {/* when poll is in live */}
                            {isBrowser && (props.pollStatus === Status.open) && (props.roles.includes(Roles.organizer) || props.roles.includes(Roles.observer) || props.roles.includes(Roles.scrutinizer))
                                && props.roleType != Roles.voter && !systemAdmineOrSupport &&
                                < ViewPollRound style={{ float: 'right' }} pollDetails={pollRound} isEdit={false} itemsPerPage={props.itemsPerPage} page={props.page} roles={props.roles} />
                            }
                            {/* when poll is cancelled in undersetup or upcomming  */}
                            {isBrowser && (props.pollStatus === Status.canceled) && (props.roles.includes(Roles.organizer) || props.roles.includes(Roles.observer) || props.roles.includes(Roles.scrutinizer)) && props.roleType != Roles.voter &&
                                < ViewPollRound style={{ float: 'right' }} pollDetails={pollRound} isEdit={false} itemsPerPage={props.itemsPerPage} page={props.page} roles={props.roles} />
                            }
                            {/* for  observer and scrutinizer view button*/}
                            {isBrowser && (props.pollStatus === Status.setup || props.pollStatus === Status.upcoming) && (props.roles.includes(Roles.observer) || props.roles.includes(Roles.scrutinizer)) && props.roleType != Roles.voter && props.roleType != Roles.organizer &&
                                < ViewPollRound style={{ float: 'right' }} pollDetails={pollRound} isEdit={false} itemsPerPage={props.itemsPerPage} page={props.page} roles={props.roles} />
                            }

                            {isBrowser &&  (props.roles.includes(Roles.organizer) || props.roles.includes(Roles.scrutinizer) ||
                                props.roles.includes(Roles.observer)) && props.pollStatus === Status.closed && props.roleType != Roles.voter && !systemAdmineOrSupport &&
                                // (props.roleType == Roles.organizer || props.roleType == Roles.scrutinizer || props.roleType == Roles.observer) &&
                                <ViewPollRound style={{ float: 'right' }} pollDetails={pollRound} isEdit={false} itemsPerPage={props.itemsPerPage} page={props.page} roles={props.roles} />
                            }

                            {isBrowser && (systemAdmineOrSupport)
                                && (props.pollStatus === Status.upcoming || props.pollStatus === Status.open) && <SetPrice style={{ float: 'right' }} pollDetails={pollRound} />
                            }

                            {isBrowser && (systemAdmineOrSupport)
                                && (props.pollStatus === Status.upcoming || props.pollStatus === Status.open) && <RecordPaymentForPoll style={{ float: 'right' }} pollDetails={pollRound} />
                            }
                            {isBrowser && (systemAdmineOrSupport) && props.pollStatus !== Status.canceled &&
                                <EditPollRound style={{ float: 'right' }} systemAdmine={true} pollDetails={pollRound} isEdit={true} itemsPerPage={props.itemsPerPage} page={props.page} roles={props.roles} />
                            }
                            {isBrowser && (systemAdmineOrSupport) && props.pollStatus === Status.canceled &&
                                <EditPollRound style={{ float: 'right' }} systemAdmine={true} pollDetails={pollRound} isEdit={true} itemsPerPage={props.itemsPerPage} page={props.page} roles={props.roles} />
                            }
                            {isBrowser && (systemAdmineOrSupport) && props.pollStatus === Status.canceled &&
                            <PublishPoll pollDetails={pollRound} getPollList={props.getPollList} setPage={props.setPage} 
                            setMessage={props.setMessage} systemAdminOrSupport={systemAdmineOrSupport} />
                            }
                        </Grid>
                    </Grid>
                </CardContent>
            </CustomCard>
            {openSpecialPrice.show && <SpecialPrice show={true} openSpecialPrice={openSpecialPrice} close={setOpenSpecialPrice} />}
            {openRecordPayment.show && <PaymentListModal show={true} openRecordPayment={openRecordPayment} close={setOpenRecordPayment} />}
            {loading && <EligoBackdrop show={true} />}
        </div>
    )
}

export default PollRoundItem;
