import React, {
    useEffect,
    useState,
    Fragment
}                                   from 'react';
import {
    Card,
    Grid
}                                   from '@material-ui/core';
import { APIEvotingAxiosInterceptor, AxiosEvotingInterceptor, PublicAPIAxiosInterceptor, PublicAxiosInterceptor }  from '../../../config/axios.interceptor';
import Question                     from './Question';
import EligoBackdrop                from '../../../common/EligoBackdrop';
import EligoSnackBar                from '../../../common/EligoSnackBar';

const PreviewVote = ({poll_id, voter_id, voteWithLink, showHistory, pollCategory}) => {
    const [questions, setQuestions] = useState([]);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [totalNumberOfQuestions, setTotalNumberOfQuestions] = useState(20);
    const [message, setMessage] = React.useState({
        showMsg: false,
        message: null,
        severity: null
    })
    const [loading, setLoading] = useState(false);

    const getQuestionWithVotes = () => {
        setLoading(true);
        setMessage({ showMsg: false, message: '', severity: '' });
        if (voteWithLink) {
            PublicAPIAxiosInterceptor.get(`question/${poll_id}/voter/${voter_id}?page=${page}&size=${rowsPerPage}&asc=true`)
                .then(response => {
                    setQuestions(response.questions);
                    setTotalNumberOfQuestions(response.count);
                    setLoading(false);
                })
                .catch(error => {
                    if (error.message) {
                        setMessage({ showMsg: true, message: error.message, severity: 'error' });
                    }
                    setLoading(false);
                })
        } else {
            APIEvotingAxiosInterceptor.get(`question/${poll_id}/voter/${voter_id}?page=${page}&size=${rowsPerPage}&asc=true`)
            .then(response => {
                setQuestions(response.questions);
                setTotalNumberOfQuestions(response.count);
                setLoading(false);
            })
            .catch(error => {
                if (error.message) {
                    setMessage({ showMsg: true, message: error.message, severity: 'error' });
                }
                setLoading(false);
            })
        }
    }

    useEffect(() => {
        getQuestionWithVotes();
    }, [])

    return (
        <Fragment>
            <Card>
                {questions.length > 0 && questions.map((question, index) => {
                    return (
                        <Grid key={index} container style={{padding: '16px'}}>
                            <Grid item xs={12}>
                                <Question poll_id={poll_id} voter_id={voter_id} key={question.id} question={question} view={true}
                                    showHistory={showHistory} showFiles={true} voteWithLink={voteWithLink}
                                    pollCategory={pollCategory}/>
                            </Grid>
                        </Grid>
                    )
                })}
            </Card>
            {message.showMsg && <EligoSnackBar show={message.showMsg} message={message.message}
                    severity={message.severity} />}
            {loading && <EligoBackdrop show={true} />}
        </Fragment>
    )
}

export default PreviewVote;