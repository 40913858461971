import React, { useState }                              from 'react';
import { Dialog, DialogTitle, Button, DialogContent,
    DialogContentText, DialogActions, Divider, Typography,
    Grid }                  from '@material-ui/core';



const TermsAndConditions = (props) => {
    const [ open, setOpen ] = useState(props.show);

    const acceptHandler = (isAccept) => {
        setOpen(false);
        props.acceptTermsAndConditions(isAccept);
    }

    return (
        <Dialog maxWidth="lg" open={open} scroll='paper' aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description">
            <DialogTitle id="scroll-dialog-title">Terms & Conditions</DialogTitle>
            <DialogContent dividers>
                <DialogContentText id="scroll-dialog-description">
                <p>
                    This website <strong>https://www.trustevote.com</strong> (“Site” which expression shall include any versions of the website designed for use on mobile phones and other devices and associated domain names, trademarks and mobile applications) and product/ services accessed through the Site (“<strong>Products</strong>”) are owned, operated and managed by Enkindle Technologies Pvt Ltd, hereinafter referred to as “<strong>ETPL</strong>”, “<strong>Company</strong>”, “<strong>We</strong>”, “<strong>Our</strong>” and “<strong>Us</strong>”. We provide a secure platform for our end-users/clients to conduct electronic voting/ surveys/ feedback/ questionnaires in any easy to manage environment.
                </p>
                <p>
                    The 'Users' of our Products are either Administrators (those who create and conduct the vote/ survey, etc.) or Voters (those who participate in the voting/ survey , etc.). Administrators pay Us to use our Products while the Voters do not have to pay Us to cast their vote/opinion. The Users have to agree to the “Terms of Service” (“Terms”) and “Privacy Policy” in order to register and avail services.
                </p>
                <p>
                    These Terms constitute a binding contract between ETPL and the Users. The User’s registration, use or access to the Products shall be governed by these Terms. If the User does not agree to these Terms, the User must not access or use the Site in any manner.
                </p>
                <p>
                    The User’s use of the Products and specifically, the User’s acceptance of these Terms shall be deemed to be a representation from the User that the User is fully able and competent to lawfully enter into contracts and abide by and comply with these Terms.
                </p>
                <p>
                    By the User’s use of the Site, User hereby gives unconditional consent to: (i) These Terms; (ii) The “Privacy Policy” can be viewed at the end of this document; (iii) Any other notice, disclaimer, policy, or term of use, by whatever name called, which may be applicable to the use of the Products from time to time.
                </p>
                <ul>
                    <li>
                        <p>
                            <strong>How Does it Work:</strong> Our Site provides a platform that offers secure, reliable and easy to use software that can be used for all forms of voting, surveys and customer feedback (“Platform”). Users (Administrators/ Voters) on successful registration on the Platform and subsequent verification will be given access to the application. Users  agree that any information provided towards registration is correct and accurate. Subject to these Terms and applicable laws, the Administrators can create polls with matters to vote, specifying threshold, upload their voter list, view results and decide on who can view the results. On making successful payment towards the poll, the administrator can start and conduct the online vote. Voters will be invited to register and cast their vote through notifications sent to them. The result of the online poll will be available to the administrator at the end of the poll.  Administrators will be responsible for the data and information shared on the platform and ensure that the same is accurate. Personal information of individuals shared by the User should be done so with the consent of the respective individual. The Administrator shall decide the nature of information required to be collected from Voters. Such information collected by the Administrator shall be protected by the them and not ETPL . The Users will be solely held liable for inaccuracy of data or misrepresentation of data provided by them. The Site shall keep a record of the User’s IP address to prevent fraud or abuse.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Privacy:</strong> The data and information provided by Users is not shared or sold with any third party. More details of our Privacy Policy is available on our website. By accepting the Terms, the Users by default also agree and accept  Our Privacy Policy.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Third Party Websites:</strong> Our Site may contain links to third party websites/ applications/cookies (“Linked Websites”) that are not under our control. The Company has no control over these third party website/applications/cookies and accepts no responsibility for them or for any loss or damage that may arise from User’s use of such Linked Websites. User’s use of the Linked Websites will be subject to the terms of use and service of such Linked Website. In the event you are redirected to a third-party website by clicking on the links, you shall be subjected to their privacy policy. We are not responsible for their content or privacy policies and are not liable for any issues that arise out of your use/access of those Linked Websites. Except for the Products explicitly provided, the Company does not provide, control or endorse any third-party information, products or services in any manner whatsoever, even if such third-party information, products or services are advertised on the Site.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Children’s Privacy:</strong> Our Site is not directed at anyone who we know to be under the age of 18, nor are the Users allowed to collect any personal information from anyone who we know to be under the age of 18. If you are under the age of 18, you should not use the Site and should not submit any personal information to us.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Fee:</strong> We reserve the right to change the fee structures for the Products, including existing services/ features and charge for current non-chargeable services/features. All taxes shall be charged extra at actuals and the Users shall pay the same.  Unless otherwise permitted by the Company or as per the Company’s Refund Policy, any charges payable in advance towards availing the Products shall be non-refundable. The Users shall refer to the Refund Policy for further information on refund of payments made to the Company. Non-payment of the fees, charges or any such amounts as applicable to avail the Products shall result in suspension or termination of these Terms and the User’s ability to use the Platform.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>User Account:</strong> User must complete the registration process by providing current, complete and accurate information as prompted by the applicable registration form. The account created belongs to the User and cannot at any time be transferred or assigned by the User. The User hereby represents and warrants that such information shared or provided by the User is up to date, correct and complete in all material respects, and does not infringe or violate any third party rights. The Company shall not be liable for any loss, damage, injury or any liability, whether in tort, contract or other theory of liability, resulting from any unauthorized use of the User’s account. The User shall be solely responsible to keep such information updated from time to time.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>User Password and Security:</strong> User is responsible for maintaining the confidentiality of their username and password that is associated with their account. All transactions/ actions undertaken under the user account is the sole responsibility of the User. User shall be held liable for any loss incurred by the Company or any other party on account of someone else using the User’s account or password. User should ensure logging out of their account on completion of their session and ensure that their username and passwords are not shared with other individuals.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Payments:</strong> All payments made on our Site is managed by a Third Party Service Provider. We only collect payment details of Users and transmit the same directly to them. All payments shall be made by credit card, debit card or such other method as the Company may specify from time to time. While availing any of the payment method/s available on the Site the Company will not be responsible or assume any liability, whatsoever in respect of any loss or damage arising directly or indirectly to the Users due to: (i) Lack of authorization for any transaction/s; or (ii) Payments exceeding the present limit mutually agreed by the Users and Third Party Service Providers; or (iii) Any payment issues arising out of the transaction; or (iv) Decline of transaction for any other reason/s. The User understands, accepts and agrees that the payment facility provided by Company is neither a banking nor financial service but is merely a facilitator providing an electronic, automated online electronic payment, receiving payment through collection and remittance facility for the transactions on the Company’s Site using the existing authorized banking infrastructure and credit card payment gateway networks.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Information Disclosure:</strong> We can share your information with our employees, affiliated parties or agents in order to provide quality services. Your information may also be shared with our trusted third-party service providers on a need-to-know basis to perform certain services on our behalf, including payment processing, data storage/management, web hosting, web analytics, fulfilment, marketing, mailing, and emailing. Your information may further be disclosed by us if required to do so by law, government or law enforcement agencies, court order or such circumstances. Please refer to Our Privacy Policy for information on Our usage of your information.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>User Obligations:</strong> The User represents and warrants that:
                        </p>
                        <ul>
                            <li>
                                {/* <p> */}
                                    Users shall provide complete and accurate information as required by the Site.
                                {/* </p> */}
                            </li>
                            <li>
                                {/* <p> */}
                                    Users are also required to abide by all laws and regulations that are applicable when using the Product.
                                {/* </p> */}
                            </li>
                            <li>
                                {/* <p> */}
                                    Users agree not to use any data mining, robots or similar data and/or image gathering and extraction methods in connection with the Product.
                                {/* </p> */}
                            </li>
                            <li>
                                {/* <p> */}
                                    User shall not use the Product to do anything that is contrary to the law, decency or morality.
                                {/* </p> */}
                            </li>
                            <li>
                                {/* <p> */}
                                    User shall not use the Product to make unsolicited offers, advertisements, proposals, or send junk mail or spam to others.
                                {/* </p> */}
                            </li>
                            <li>
                                {/* <p> */}
                                    User shall not take any action that would cause the Company to suffer any types of losses.
                                {/* </p> */}
                            </li>
                            <li>
                                {/* <p> */}
                                    User agrees not to collect or harvest from the Product any personally identifiable information, including account names, or to use the communication platforms provided by the Product (e.g., comments, email) for any commercial solicitation or other purposes.
                                {/* </p> */}
                            </li>
                            <li>
                                {/* <p> */}
                                    User shall not copy, reproduce, make available online or electronically transmit, publish, adapt, distribute, transmit, broadcast, display, sell, license, or otherwise exploit the content on the Site.
                                {/* </p> */}
                            </li>
                            <li>
                                {/* <p> */}
                                    User agrees not to hack, circumvent, disable, corrupt or otherwise interfere with security-related features of the Product or features that prevent or restrict use or copying of any content or enforce limitations on use of the Product or the content therein.
                                {/* </p> */}
                            </li>
                            <li>
                                {/* <p> */}
                                    User shall also not attempt to affect the performance or functionality of any facilities available on the Site.
                                {/* </p> */}
                            </li>
                            <li>
                                {/* <p> */}
                                    User shall not transmit or distribute a virus, trojan, worm, logic bomb or any other material which is malicious, technologically harmful, in breach of confidence or in any way offensive or obscene hack into any aspect of the Product.
                                {/* </p> */}
                            </li>
                            <li>
                                {/* <p> */}
                                    The User shall not create any voting/polls/surveys which violates or infringes any third party rights, including intellectual property rights.
                                {/* </p> */}
                            </li>
                            <li>
                                {/* <p> */}
                                    The User shall not create any voting/polls/surveys which promotes or invokes enmity between different groups on grounds of religion, race, place of birth, residence, language, etc. The User shall not do any acts which is prejudicial to maintenance of harmony.
                                {/* </p> */}
                            </li>
                            <li>
                                {/* <p> */}
                                    User acknowledges and understands that they are entirely responsible for all contents made available on the Site, and consent that they have the necessary rights and permissions required to use such information in connection with availing the Products on the Site.
                                {/* </p> */}
                            </li>
                        </ul>
                    </li>
                    <li>
                        <p>
                            <strong>Unlawful Use:</strong> If it is found that the User is conducting unlawful activity during use of the Site, such will be reported to the respective law enforcement agency/ regulators or officials as the case may be. In such cases disclosure of account related information of such Users to the respective regulatory/ enforcement agencies may be necessary.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Account Deactivation:</strong> The account of the User may be deactivated by  us on account of breach of the Terms or if required to do so by law. The User can also choose to deactivate their account. On the account getting deactivated, user will not have access to their account. If the Company reasonably concludes based on information available to the Company that (i) User’s actions and/or performance in connection with the Terms may result in a significant number of customer disputes, chargebacks or other claims in connection with the Site; (ii) the Users have given inaccurate or false information or details while registration or login or has failed to update their information, the Company may then either block, restrict, disable, suspend or terminate User’s access to the Site including the Products, in full or in parts, at any time, as per the Company’s discretion, without prior notice or liability to the User. Notwithstanding the foregoing, the Company may at its sole discretion, and at any given time, either suspend the User’s use of the Site, including the Products or terminate User’s contract under these Terms.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Indemnity:</strong> The Users agree to indemnify in full, defend and hold the Company, including its employees, directors, agents, service providers, advertisers, agents, attorneys and consultants who are harmless from any liability, claims, expense, penalties, fines, including legal fees that may arise out of: (i) any breach of Terms of Service / Agreement; (ii) User’s use of the Product; (iii) User’s violation of any third party right, including without limitation any intellectual property rights, property, or privacy right; (iv) violation of any applicable laws, rules or regulations. This indemnification obligation shall survive these Terms and use of the Products.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Security Protocols:</strong> All necessary security protocols are followed to ensure the process of voting is secure and data is safe. All information is stored in a cloud environment that secures all data from unauthorized access with encryption features and access management tools.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Disclaimer:</strong> The information contained in this Site has been prepared/compiled for the convenience and general information of Site visitors and we reserve the right to change (partly or completely) the information provided on the website without any prior intimation /notice.
                        </p>
                        <p>
                            All information or content provided on this Site is obtained by us from sources believed to be accurate and reliable. The Company shall not be liable in case of any unauthorised access. Access and use are a confirmation by the Users that they have understood and accepted the terms and conditions for using the Products.
                        </p>
                        <p>
                            While utmost care has been exercised to ensure the accuracy of compilation, correctness and completeness of the information provided on this Site, we do not warrant (whether expressed or implied) as to the quality, efficacy, completeness, performance, or accuracy of the information and disclaims all claims, liabilities, losses and damages arising out of the use of this information.
                        </p>
                        <p>
                            The information contained herein cannot form the basis for any dispute between Company, Administrators, Voters, and any other users of the Site. We will not be liable in any manner for any acts of the Administrator, including with respect to the outcome of results, results being downloaded by the Administrator from the Site and when the results are in the custody of the Administrator and being collated by the Administrator with the results of the votes received by the Administrator in physical form.
                        </p>
                        <p>
                            We will endeavour to keep all information updated. The formats, frequency of updating and the retention period of information will be decided at our sole discretion.
                        </p>
                        <p>
                            The information is provided on 'as is' basis without warranty of any kind and we shall not be liable for any damages, losses (direct or indirect) whatsoever, due to disruption or non-availability of any of services/facility/s due to maintenance/technical fault/error or any failure in telecommunication network or any error in any software or hardware systems. We do not make any representation and disclaims all express, implied and statutory warranties of any kind to the user and/or any third-party including warranties as to accuracy, timeliness, completeness, merchantability or fitness of the information for any particular purpose.
                        </p>
                        <p>
                            Unless expressly agreed herein in the Terms, all proprietary rights in the information received shall remain our property. Reproduction, redistribution and transmission of any information contained on the Site are strictly prohibited.
                        </p>
                        <p>
                            The Company shall not be responsible for the unavailability of the Site or any delay or failure resulting from any reason whatsoever, including but not limited to infrastructure issues, server uptime, network availability and connectivity.
                        </p>
                        <p>
                            The Company shall not be held responsible for non-availability of the Site during periodic maintenance operations or any unplanned suspension of access to the Site that may occur due to technical reasons or for any reason beyond the Company's control. User understands and agrees that any material and/or data downloaded or otherwise obtained through the Site is done entirely at the User’s own discretion and risk and the User will be solely responsible for any damage to their computer systems or loss of data that results from the download of such material and/or data.
                        </p>
                        <p>
                            The Company will not be liable for any loss or damage caused by distributed denial-of-service attacks, viruses or other technologically harmful material that may infect User’s computer equipment, computer programs, data or other proprietary material due to User’s use of the Site or download of any material from the Site.
                        </p>
                        <p>
                            The Company shall not be liable for the content developed by the Administrators, including the content for the votes/polls/survey or any images used therein. The Company shall also not be liable for any information required to be collected by the Administrator in addition to the registration by the Company. The Company shall further not be liable for the outcome of the results derived from the votes/polls/survey.
                        </p>
                        <p>
                            The Voters and users of the Site acknowledges that the Users or the Company may share the User’s votes/polls/survey or results on third-party platforms such as Facebook, Twitter, LinkedIn, etc. The Company shall not be liable for any violation of third party rights, including intellectual property rights, including privacy rights, if such information is shared on such third-party platforms.
                        </p>
                        <p>
                            Despite all possible security measures that the Company takes to keep Site free from hacking and other interference, the Site, like any other website, is not free from such risks. The Company  disclaims all liability on account of any loss or damage that any User may suffer or incur on account of any alteration or manipulation of any data or information accessed or downloaded by the User from the Site.
                        </p>
                        <p>
                            We do not and will not, at any time, request your credit card information or national identification numbers in a non-secure or unsolicited e-mail or telephone communication.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Limitation of Liability:</strong> We shall not be liable to the User for any transactions executed on our Platform and the User hereby fully indemnifies and holds us harmless against any action, suit, proceeding initiated against it or any loss, cost or damaged incurred by it as a result thereof.  We shall under no circumstance be liable to the User if the access is not available in the desired manner for reasons including but not limited to natural calamities, legal restraints, faults in the telecommunication network or network failure or any other reason beyond our control. Under no circumstance shall we be liable for damages whatsoever whether such damages are direct, indirect, incidental, consequential, punitive, special and/or loss of profit and irrespective whether any claim is based on loss of revenue, interruption of business, or any loss of any character or nature and whether sustained by the user or any other person. Notwithstanding the foregoing, the Company’s maximum liability under these Terms shall in no event exceed INR 500/- (Indian Rupees Five Hundred Only).
                        </p>
                        <p>
                            The User agrees that any illegal or improper use of our Site shall render the User liable for payment of financial charges as decided by Us and may result in suspension of the access to the Site.
                        </p>
                        <p>
                            User acknowledges and agrees that the warranty disclaimers and the limitations of liability set forth herein reflect a reasonable and fair allocation of risk between the User and the Company, and that the warranty disclaimers and the limitations of liability set forth herein form an essential basis of the bargain between User and Company. The Company would not be able to provide the Product to the User on an economically reasonable basis without these limitations.
                        </p>
                        <p>
                            The Products are controlled and offered by the Company from its facilities in India. if you are a User outside India, please take note the Company is subject only to Indian laws and only to the jurisdiction of Indian courts. The Company makes no representations that the products or services are appropriate or available for use in other locations. Therefore, if you are a User outside India, you may use the products or services solely on your own volition and at your own risk. You shall be solely responsible for compliance with local law.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Amendments:</strong> These Terms may be amended at any time by the Company. All such amendments shall be binding on the User effective 24 (twenty-four) hours after the amended Terms are made available on the Site. It shall be the User's sole responsibility to check this page regularly to take notice of any changes the Company may have made to these Terms. If the User does not agree with any amendment, the User should immediately stop accessing and using the Products on the Site with no liability from the Company to User except for such Products already purchased prior to the amendments.
                        </p>
                        <p>
                        Although the Company may attempt to notify the User when major changes are made to these Terms, it shall be solely the User's responsibility to read and understand the most up-to-date version of the Terms on the Site. Nothing in these Terms shall be deemed to confer any third-party rights or benefits.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Governing Law:</strong> These Terms are governed by and shall be construed in accordance with the laws of the Republic of India without respect to its conflict of laws provisions.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Assignment:</strong> The right to use the Products is personal to the User and is not transferable by assignment, sublicense, or any other method to any other person or entity.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Proprietary Rights:</strong> The User acknowledges that the Platform, including the software underlying e-Voting, source code or object code associated with the Platform, and any intellectual property rights associated therewith, is the sole and exclusive property of the Company. The permission given by the Company to access the Platform will not convey any proprietary or ownership rights in the above software. The User shall not attempt to modify, translate, disassemble, decompile or reverse engineer the Platform or any portions of the Site or create any derivative works thereof. The display of trademarks, logos, graphical or other images, layout, design, or any other content on this Site, unless otherwise specifically noted, are the property of the Company alone.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Removal/Modification of Content:</strong> The User acknowledges that ETPL shall review the contents submitted by you on the Site, including the voting/survey/polls, to determine whether it is illegal, or violates the Terms, or is unlawful, or violate any third-party IP. We may modify, prevent access to, delete or refuse to display your contents that We believe violates the law or these Terms. In the event your content includes third-party brands, logos, or other sources identifiers, we may require you to submit a statement of non-affiliation before you use them on the Site or in the Products. We have no obligation to monitor or review any content submitted on the Site.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Severability:</strong> If any provision of these Terms is prohibited or unenforceable, it shall be ineffective only to the extent of such prohibition or unenforceability, and such prohibition or unenforceability shall not invalidate the balance of such provision to the extent it is not prohibited or enforceable nor the remaining provisions hereof, nor render unenforceable such provision in any other jurisdiction. In the event any provisions of these Terms shall be held to be invalid, illegal or unenforceable, the parties hereto shall use their best efforts to substitute valid, legal and enforceable provisions which, insofar as practical, implement the purposes hereof.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Contact Us:</strong> If You have any concerns with your personal information, including that you would like us to update such information, please feel free to contact our Grievance Officer at:
                        </p>
                        <div>Enkindle Technologies Pvt. Ltd.,</div>
                        <div> 7/1 Aliasker Road,</div>
                        <div>Bengaluru 560052.</div>
                        <p>Our Grievance Officer shall direct you to the concerned administrator.</p>
                    </li>
                </ul>
                <Divider />
                <br/>

                <Typography variant="h6" gutterBottom align="center">
                    <strong>Privacy Policy</strong>
                </Typography>
                    <p>
                        Capitalized terms used herein and not defined shall have the meaning given to it in Customer Agreement published on the Platform: <strong>https://www.trustevote.com</strong>
                    </p>
                    <p>
                        The aim of this privacy policy statement is to inform the Customer or Authorised Personnel or Authorised User (collectively hereinafter referred to as <strong>“Personal Information Provider”</strong>) regarding Company’s practices regarding collection, use, disclosure and transfer of Personal Information that is either provided by Personal Information Provider or collected by the Company through Personal Information Provider’s use of the Platform (<strong>“Privacy Policy”</strong>).
                    </p>
                    <p>
                    Personal Information shall mean information that identifies Personal Information Provider (identifies an individual) and collected by the Company through Personal Information Provider’s use of the Platform or provided by Personal Information Provider in digital form.
                    </p>
                    <ul>
                        <li>
                            <p>
                                <strong>Collection, Consent</strong>
                            </p>
                            <ul>
                                <li>
                                    In order to utilize the Services, Personal Information Provider hereby consents to upload certain Personal Information including identity proofs and mobile number to verify such Personal Information Provider’s identity.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <p>
                                <strong>Purpose, Opt Out and Withdrawal</strong>
                            </p>
                            <ul>
                                <li>
                                    If the Personal Information Provider chooses to participate in any activity that is offered through the Platform or takes benefit of any feature that is offered through the Platform, the Personal Information Provider may be required to furnish some Personal Information to the Company.
                                </li>
                                <li>
                                    The Personal Information Provider may decline to provide Personal Information that is requested from the Personal Information Provider, but this may affect our ability to provide to the Personal Information Provider the Services requested, or to allow Personal Information Provider’s participation in any activity that is offered on the Platform.
                                </li>
                                <li>
                                    The Personal Information Provider may, at any time, by writing to the Company, at the email address provided below, withdraw his/her consent for collection, use, disclosure and transfer of Personal Information by the Company in accordance with this Privacy Policy.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <p>
                                <strong>Disclosure, Transfer</strong>
                            </p>
                            <ul>
                                <li>
                                    <p>
                                        Personal Information may be hosted with a third party data centre, and the servers may be located in jurisdictions outside India. The Personal Information Provider acknowledges that the data stored in data centres located outside India may be governed by the laws of the jurisdiction in which the data centre is located. The Personal Information Provider specifically consents to the transfer of Personal Information to the data centres or servers operated by the third party service providers located in territories outside India that may not have equivalent laws and regulations regarding protection of personal information.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        The Company may disclose Personal Information as follows:
                                    </p>
                                    <ul>
                                        <li>
                                            To Company’s affiliates that agree to treat it in accordance with this Privacy Policy.
                                        </li>
                                        <li>
                                            To service providers and other partners who support the business of the Company, provided however, all such third parties agree not to use the Personal Information for purposes other than for which it is disclosed.
                                        </li>
                                        <li>
                                            To a purchaser or successor entity in connection with the sale, merger, assignment, or other transfer of the Company or any of its affiliates, or a business division of the Company or any of its affiliates, or sale of substantially all of the assets of the Company or any of its affiliates.
                                        </li>
                                        <li>
                                            If the disclosure is required by law, rule, regulation, or order of a court of competent jurisdiction or governmental authority, or regulatory authority.
                                        </li>
                                        <li>
                                            To enforce this Privacy Policy.
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <p>
                                <strong>Security Practices</strong>
                            </p>
                            <ul>
                                <li>
                                    <p>
                                        The Company’s security practices with respect to the information collected by the Company is as set out below:
                                    </p>
                                    <ul>
                                        <li>
                                            As the Platform is accessed on the internet, Company uses Secured Socket Layers (SSL) for establishing an encrypted link between the web server on the Platform (website) and Personal Information Provider’s browser; however, the Internet is inherently insecure, therefore the Company cannot provide any assurance regarding the security of transmission of information that the Personal Information Provider communicate to the Company and the Personal Information Provider may do so at his/her own risk.
                                        </li>
                                        <li>
                                            Enkindle will secure the storage of Personal Information in compliance with the minimum security measures prescribed under the Information Technology Act, 2000, its regulation and standards, in the following manner:
                                            <ul>
                                                <li>
                                                    register those who have access to the storage;
                                                </li>
                                                <li>
                                                    control and limit access based on necessity;
                                                </li>
                                                <li>
                                                    maintain proper record of access and transfer of Personal Information;
                                                </li>
                                                <li>
                                                    ensure all employees of the Company protect confidentiality of the Personal Information;
                                                </li>
                                                <li>
                                                    conduct awareness programmes to educate employees on responsibility to protect Personal Information;
                                                </li>
                                                <li>
                                                    establish and enforce physical security procedures;
                                                </li>
                                                <li>
                                                    bind third parties involved in processing of Personal Information; and,
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <p>
                                <strong>How to Contact the Company / Grievance Officer</strong>
                            </p>
                            <ul>
                                <li>
                                    <p>
                                        If the Personal Information Provider has questions about this Privacy Policy or needs to contact the Company in connection with the use or disclosure of the Personal Information, please reach out to the Company by sending an email or a letter to:
                                    </p>
                                    <div>
                                        <div>ENKINDLE TECHNOLOGIES PRIVATE LIMITED</div>
                                        <div>Attention: U.Kamalesh Rao</div>
                                        <div>Tel: +91 80 41218544</div>
                                        <div>E-mail: kamalesh.rao@enkindletech.com</div>
                                        <p>
                                            Grievance Officer, as required under the Information Technology Act, 2000 is: U.Kamalesh Rao
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <p>
                                <strong>Updates</strong>
                            </p>
                            <ul>
                                <li>
                                    <p>
                                        The Company reserves the right to modify this Privacy Policy without the Personal Information Provider’s consent. The Company may inform the Personal Information Provider regarding modifications to the Privacy Policy by displaying a banner statement on the Platform or including a statement regarding modifications at the bottom of this Privacy Policy. If the Personal Information Provider continue to use the Platform after information regarding the modifications is published, you shall be deemed to have accepted the revised Privacy Policy.
                                    </p>
                                    <p>
                                        This Privacy Policy was last updated on 01 June, 2022.
                                    </p>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Grid container>
                    <Grid item xs={12} style={{textAlign: 'center'}}>
                        <Button id="disagree" size="small" variant="outlined" onClick={() => {acceptHandler(false)}} color="primary"
                                style={{margin: '4px'}}>
                            DISAGREE
                        </Button>
                        <Button id="agree" size="small" variant="contained" onClick={() => {acceptHandler(true)}} color="primary"
                                style={{margin: '4px'}}>
                            AGREE
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}

export default TermsAndConditions;