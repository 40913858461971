import React, {
    useState,
    useContext,
    Fragment
}                                   from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Slide,
    Card,
    CardContent,
    Typography,
    Grid,
    Radio,
    makeStyles,
    Chip
}                                   from '@material-ui/core';
import { useTranslation }           from 'react-i18next';
import CheckCircleIcon              from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles((theme) => ({
    option: {
        background: 'white',
        borderRadius: '5px',
        padding: '6px',
        margin: '7px 0px',
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)'
    },
    selectedOption: {
        color: '#2a83ad',
        border: '1px solid #2a83ad',
        background: '#2a83ad0a'
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const VoteOnBehalf = ({show, onSelect, voters, onCancel}) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(show != undefined ? show : false);
    const [selectedVoter, setSelectedVoter] = useState('');
    const classes = useStyles()

    const handleClose = () => {
        setOpen(false);
        onCancel()
    }

    const handleContinue = () => {
        setOpen(false);
        onSelect(selectedVoter);
    }

    const handleChange = (voter) => {
        setSelectedVoter(voter);
    }

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="vote-on-behalf-description"
                maxWidth="sm"
                fullWidth
                
            >
                <DialogTitle style={{background: '#f7f9fb'}}>{t('Cast_Vote_As')}</DialogTitle>
                <DialogContent style={{background: '#f7f9fb'}} dividers>
                    <DialogContentText id="vote-on-behalf-description">
                        {/* <TextField select onChange={(event) => setSelectedVoter(event.target.value)}
                                fullWidth required
                                inputProps={{
                                    id: 'select-voter'
                                }}>
                            {voters.map((voter, index) => (
                                <MenuItem key={index} value={voter}>
                                    {voter.name} ( {voter.email} )
                                </MenuItem>
                            ))}
                        </TextField> */}
                            {voters.map((voter, index) => {
                                return <Fragment>
                                    { voter.authorizedRepresentative.length === 0 && 
                                        <Fragment> {index === 0 && 
                                            <Typography color="textPrimary" variant="subtitle1" gutterBottom
                                            style={{fontWeight: 'bold'}}>{t('Self')}</Typography>}
                                            <Card style={{margin: '4px', cursor: 'pointer'}}
                                                onClick={() => handleChange(voter)} className={`${classes.option} ${selectedVoter.voterId === voter.voterId ? classes.selectedOption : ''}`}>
                                                <CardContent>
                                                    <Grid container direction='row' alignItems="center" justify="center">
                                                        <Grid item xs={10}>
                                                            <Typography color="textPrimary">
                                                                {voter.name} ( {t('ID')} : <span style={{color: 'gray'}}>{voter.voterId}</span> ) &nbsp;&nbsp;{voter.voteVerified && <Chip icon={<CheckCircleIcon />} label={t('Voted')} color="primary" size='small'/>}
                                                            </Typography>
                                                            <Typography color="textSecondary" gutterBottom>
                                                            <em>{voter.email}</em>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Radio
                                                                style={{float: 'right'}}
                                                                checked={selectedVoter.voterId === voter.voterId}
                                                                value={voter.voterId}
                                                                name={voter.name}
                                                                checkedIcon={<CheckCircleIcon />}
                                                                color='primary'
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                        </Card>
                                        </Fragment>
                                    } </Fragment>
                                })}
                            <br/>
                            
                            {voters.map((voter, index) => {
                                return <Fragment>
                                    {voter.authorizedRepresentative.length > 0 && 
                                        <Fragment>
                                            {index === 0 && <Typography color="textPrimary" variant="subtitle1" gutterBottom
                                            style={{fontWeight: 'bold'}}>{t('Authorized_Representive_Of')}</Typography>}
                                            <Card style={{margin: '8px', cursor: 'pointer'}} 
                                                onClick={() => handleChange(voter)} className={`${classes.option} ${selectedVoter.voterId === voter.voterId ? classes.selectedOption : ''}`}>
                                                <CardContent>
                                                    <Grid container direction='row' alignItems="center" justify="center">
                                                        <Grid item xs={10}>
                                                            <Typography color="textPrimary">
                                                                {voter.name} ( {t('ID')} : <span style={{color: 'gray'}}>{voter.voterId}</span> )&nbsp;&nbsp;{voter.voteVerified && <Chip icon={<CheckCircleIcon />} label={t('Voted')} color="primary" size='small'/>}
                                                            </Typography>
                                                            <Typography color="textSecondary" gutterBottom>
                                                                <em>{voter.email}</em>
                                                            </Typography>
                                                            
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Radio
                                                                style={{float: 'right'}}
                                                                checked={selectedVoter.voterId === voter.voterId}
                                                                value={voter.voterId}
                                                                name={voter.name}
                                                                checkedIcon={<CheckCircleIcon />}
                                                                color='primary'
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Fragment>
                                    }</Fragment>
                                })
                            }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button id="cancel-on-behalf-selection" onClick={handleClose} color='primary' variant='outlined' size="small">{t('Cancel')}</Button>
                    <Button id="confirm-on-behalf-selection" onClick={handleContinue} color='primary' variant='contained' size="small"
                            disabled={selectedVoter === ''}>{t('Continue')}</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default VoteOnBehalf;