import { Divider, List, ListItem, ListItemIcon, ListItemText, makeStyles, Paper, Typography } from '@material-ui/core';
import { Stack } from '@mui/material';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { ChipPollTitle } from '../../common/evoting-styles/EvotingStyle';
import './Rules.css'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { TypeOfPollCategory } from '../../common/GenericCodes';

const useStyles = makeStyles((theme) => ({
    details: {
        fontSize: '1.3rem',
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        padding: '0px 0px 0px 1rem'
    },
    name: {
        padding: '0.3rem 1rem 0.3rem 1rem'
    }


}));

const Rules = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    let question = props.pollCategory === TypeOfPollCategory.IBC ? <span style={{ fontWeight: 'bolder' }}>{t("Resolutions_Questions")}</span> : <span style={{ fontWeight: 'bolder' }}>{t("Questions")}</span>
    let questionCount = (<span style={{ fontWeight: 'bolder' }}>{props.length}</span>)
    return (
        <Fragment>
            <div>
                <Stack direction={{ xs: 'column', sm: 'column', md: 'column', lg: 'row' }}
                    spacing={2} width='100%' >
                    <List>
                        {/* <Stack direction='row' spacing={2}>
                            <ChipPollTitle
                                label={<Typography variant='subtitle2' style={{ fontWeight: 'bold', fontSize: '20px' }} noWrap>
                                    {t('Instructions')}
                                </Typography>}
                            />
                            {
                                props.voterName && <Typography style={{ fontWeight: 'bold', fontSize: '20px' }}>Voting For <b>{props.voterName}</b></Typography>
                            }
                        </Stack> */}

                        <Stack spacing={1} style={{ marginTop: '10px' }}>
                            <ListItem style={{ padding: '0px' }} key='point1'>
                                <ListItemIcon >
                                    <ArrowRightAltIcon style={{ color: 'black' }} fontSize='small' />
                                </ListItemIcon>
                                <ListItemText style={{ marginLeft: '-20px' }} primary={
                                    <Typography >
                                        {t('Each')} {props.pollCategory === TypeOfPollCategory.IBC ? t('Resolution') : t('Question')} {t('Has_A_Set_Of_Options_And_You_May_Choose_Any_Option')}.
                                    </Typography>
                                } />
                            </ListItem>
                            <ListItem style={{ padding: '0px' }} key='point2'>
                                <ListItemIcon >
                                    <ArrowRightAltIcon style={{ color: 'black' }} fontSize='small' />
                                </ListItemIcon>
                                <ListItemText style={{ marginLeft: '-20px' }} primary={
                                    <Typography >
                                        There are a total of {questionCount} {question}  on which you will be voting.
                                    </Typography>
                                } />
                            </ListItem>
                            {props.verify_with_otp === true && <ListItem style={{ padding: '0px' }} key='point4'>
                                <ListItemIcon >
                                    <ArrowRightAltIcon style={{ color: 'black' }} fontSize='small' />
                                </ListItemIcon>
                                <ListItemText style={{ marginLeft: '-20px' }} primary={
                                    <Typography >
                                        {t("Your_Vote_won't_be_registered_unless_you_confirm_your_Vote_with_OTP.")}
                                    </Typography>
                                } />
                            </ListItem>}
                            {props.verify_with_otp === false && <ListItem style={{ padding: '0px' }} key='point4'>
                                <ListItemIcon >
                                    <ArrowRightAltIcon style={{ color: 'black' }} fontSize='small' />
                                </ListItemIcon>
                                <ListItemText style={{ marginLeft: '-20px' }} primary={
                                    <Typography >
                                        {t("Your_Vote_wont_be_registered_unless_you_confirm_your_Vote")}
                                    </Typography>
                                } />
                            </ListItem>}
                            <ListItem style={{ padding: '0px' }} key='point3'>
                                <ListItemIcon >
                                    <ArrowRightAltIcon style={{ color: 'black' }} fontSize='small' />
                                </ListItemIcon>
                                <ListItemText style={{ marginLeft: '-20px' }} primary={
                                    <Typography >
                                        {t("Any_Documents_uploaded_by_the_Organizer_will_be_displayed_below,_which_can_be_downloadable.")}
                                    </Typography>
                                } />
                            </ListItem>
                        </Stack>
                    </List>


                    {props.showOrganizers && <Stack alignItems='center' justifyContent='center' direction='row' spacing={2}>
                        {
                            props.organizer_list.length > 0 &&
                            <Paper className='organizerPaper'>
                                <Typography className={classes.details}>
                                    {t('Oragnizer_Details')}
                                </Typography>
                                <Stack direction='row' p={1} spacing={5}>
                                    <Typography>
                                        <b>{t('Name')}:</b>
                                    </Typography>
                                    <Typography>
                                        {props.organizer_list[0].name} {props.organizer_list[0].restOfName}
                                    </Typography>
                                </Stack>
                                <Stack direction='row' p={1} spacing={5}>
                                    <Typography>
                                        <b>{t('Email')}:</b>
                                    </Typography>
                                    <Typography>
                                        {props.organizer_list[0].email}
                                    </Typography>
                                </Stack>
                                <Stack direction='row' p={1} spacing={1}>
                                    <Typography>
                                        <b>{t('Phone No')}:</b>
                                    </Typography>
                                    <Typography>
                                        {props.organizer_list[0].phoneNumber}
                                    </Typography>
                                </Stack>
                            </Paper>
                        }

                    </Stack>}

                </Stack>
            </div>
        </Fragment >
    )
}

export default Rules;