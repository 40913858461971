import React, {useState, Fragment} from 'react'
import { FormControlLabel,IconButton, TableRow, TableCell, Box, Collapse} from '@material-ui/core'
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { format } from 'date-fns';
import AddGenericCode from './AddGenericCode';
import { GenericCodeNodeLabels } from '../../common/GenericCodesValues'


const Btns = (props) => {
    const clickEvent = () => {
        props.click()
    }
    return <FormControlLabel
        aria-label={props.label} 
        className='float-right'
        onClick={clickEvent}
        control={<IconButton aria-label="edit-code" color={props.color} >
            {props.btn}
        </IconButton>}
    />
}

const EditCode = (props) => {
    const editCodeDeatils = () => {
        props.expand()
    }
    return (
        <Btns btn={<><EditIcon /></>} click={editCodeDeatils} label="edit" color="primary"/>
    )
}


const GenericCodeTableRow = (props) => {
    let codeResource = {
        description: props.row.description,
        abbreviation: props.row.abbreviation,
        start_date_time: props.row.start_date_time,
        end_date_time: props.row.end_date_time,
        event_id: props.row.event_id,
        // sequence: props.row.sequence + 1
    }
    const [open, setOpen] = useState(false);
    const setEdit = () => {
        setOpen(true)
    } 
    const collopaseRow = () => {
        setOpen(false)
    }
    const loadBackdrop = (e) => {
        props.loadBackdrop(e)
    }
    return (
        <Fragment>
            <TableRow hover role="checkbox" tabIndex={-1} >
                <TableCell >{props.row.abbreviation}</TableCell>
                <TableCell >{props.row.description}</TableCell>
                <TableCell >{format(new Date(props.row.start_date_time), process.env.REACT_APP_DATE_FORMAT_TO_DISPLAY)}</TableCell>
                <TableCell >{format(new Date(props.row.end_date_time), process.env.REACT_APP_DATE_FORMAT_TO_DISPLAY)}</TableCell>
                <TableCell>
                    {/* <DeleteCode codeResource={codeResource} label={props.label} loadBackdrop={(e) => loadBackdrop(e)}/> */}
                    <EditCode param={props.row} expand={setEdit}/>
                </TableCell>
            </TableRow>
            <TableRow >
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <AddGenericCode codeResource={codeResource} close={collopaseRow} label={props.label} />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    )
}

export default GenericCodeTableRow
