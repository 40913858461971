import { Button, Chip, Tooltip } from "@material-ui/core";
import { Add, CheckBox, CheckBoxOutlineBlank, Clear, Delete, DragIndicator, InfoRounded, PlusOne } from "@material-ui/icons";
import { Autocomplete, Avatar, AvatarGroup, Card, Collapse, FormControl, FormControlLabel, FormHelperText, IconButton, InputAdornment, InputLabel, MenuItem, Select, Slide, Stack, TextField, Typography } from "@mui/material";
import { Fragment, memo, useEffect, useRef, useState } from "react";
import { TransitionGroup } from "react-transition-group";
import { EvotingRowInputField, EvotingTextField } from "../../poll-question-components/PollQuestionComponents";
import { OptionType, QuestionType } from "../../QuestionStaticVar";
import { useTranslation } from "react-i18next";
import OptionFileUpload from "./OptionFileUpload";
import { AxiosEvotingInterceptor } from "../../../../config/axios.interceptor";
import ImagePreview from "./preview/ImagePreview";
import MultiOptionMinMaxInfo from "./MultiOptionMinMaxInfo";

const Options = (props) => {
    const { question, setQuestion, dependentQuestions, title, error, setError, pollId, setLoading, hasSystemAdmineOrSupportRole,
        setMessage, isEdit, hasPermission, deletedOption, setDeletedOption } = props;
    const [touched, setTouched] = useState([]);
    const [multiToolTip, setMultiToolTip] = useState({
        minFocus: false,
        maxFocus: false
    });
    const { t } = useTranslation();

    const handleTypeChange = (event) => {
        if (isEdit && deletedOption.options.length === 0) {
            setDeletedOption({
            options: question.options.map((opt) => ({
                ...opt,
                deleted: true
            }))
        });
        }
        setQuestion(prevState => ({
            ...prevState,
            optionType: event.target.value,
            options: prevState.options.map((opt) => ({
                ...opt,
                id: "",
                option: opt.option,
                optionType: event.target.value,
            }))
        }))
    }

    const handleOptionFocus = (arrayIndex) => {
        setTouched((prevState) => ({
            ...prevState,
            ['option' + arrayIndex]: true
        }))
    }

    const OnMultiBoundaryChange = (event) => {
        if (event.target.id === 'minSelectInMultiChoice' &&
            (event.target.value === "" || (/^[0-9]+$/.test(parseInt(event.target.value)) && parseInt(event.target.value) >=1 && parseInt(event.target.value) <= question.options.length))) {
            setQuestion(prevState => ({
                ...prevState,
                [event.target.id]: event.target.value === '' ? event.target.value : parseInt(event.target.value),
                maxSelectInMultiChoice: (parseInt(event.target.value) > prevState.maxSelectInMultiChoice) ? parseInt(event.target.value) : prevState.maxSelectInMultiChoice
            }))
        }
        if (event.target.id === 'maxSelectInMultiChoice' &&
            (event.target.value === "" || (/^[0-9]+$/.test(parseInt(event.target.value)) && parseInt(event.target.value) >= 1 && parseInt(event.target.value) <= question.options.length && parseInt(event.target.value) >= question.minSelectInMultiChoice))) {
            setQuestion(prevState => ({
                ...prevState,
                [event.target.id]: event.target.value === '' ? event.target.value : parseInt(event.target.value),
            }))
        }
    }

    const onInputChange = (event) => {
        setQuestion(prevState => ({
            ...prevState,
            [event.target.id]: event.target.value
        }))
    }

    const handleNextQuestion = (event, arrayIndex) => {
        setQuestion(prevState => ({
            ...prevState,
            options: prevState.options.map((opt, index) => index === arrayIndex ?
                {
                    ...opt,
                    nextQuestionId: event.target.value
                } :
                opt
            )
        }))
    }

    const handleClearNextQuestion = (arrayIndex) => {
        setQuestion(prevState => ({
            ...prevState,
            options: prevState.options.map((opt, index) => index === arrayIndex ?
                {
                    ...opt,
                    nextQuestionId: null
                } :
                opt
            )
        }))
    }

    const handleAddOption = () => {
        setQuestion(prevState => ({
            ...prevState,
            options: [...prevState.options, {
                nextQuestionId: null,
                option: "",
                id: "",
                optionType: question.optionType,
                files: null,
                s3Location: "",
                // sequence: 0
            }]
        }))
    }

    const handleDeleteOption = (option, index) => {
        if (option.id !== undefined && option.id !== '') {
            setLoading(true);
            AxiosEvotingInterceptor.delete(`question/${pollId}/${question.id}/${option.id}`)
                .then(response => {
                    if (response) {
                        let options = question.options;
                        options.splice(index, 1);
                        setQuestion(prevState => ({
                            ...prevState,
                            options: options,
                            minSelectInMultiChoice: prevState.minSelectInMultiChoice > options.length ? options.length : prevState.minSelectInMultiChoice,
                            maxSelectInMultiChoice: prevState.maxSelectInMultiChoice > options.length ? options.length : prevState.maxSelectInMultiChoice,
                        }))
                        setMessage({ showMsg: true, message: t('Option_Deleted_Successfully'), severity: 'success' });
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    if (error.message) {
                        setMessage({ showMsg: true, message: error.message, severity: 'error' });
                    }
                })
        } else {
            let options = question.options;
            options.splice(index, 1);
            setQuestion(prevState => ({
                ...prevState,
                options: options,
                minSelectInMultiChoice: prevState.minSelectInMultiChoice > options.length ? options.length : prevState.minSelectInMultiChoice,
                maxSelectInMultiChoice: prevState.maxSelectInMultiChoice > options.length ? options.length : prevState.maxSelectInMultiChoice,
            }))
        }

    }

    const onEnterOptionText = (event, arrayIndex) => {
        let value = event.target.value.trimStart();
        setQuestion(prevState => ({
            ...prevState,
            options: prevState.options.map(
                (el, index) => index === arrayIndex ? {
                    ...el, [event.target.id]: value
                } : el
            )
        }))
        if (value !== "") {
            setError((prevState) => ({
                ...prevState,
                ['option' + arrayIndex]: null
            }))
        }
    }

    const onSelectImage = (fileName, fileByteArray, arrayIndex) => {
        setQuestion(prevState => ({
            ...prevState,
            options: prevState.options.map((opt, index) => index === arrayIndex ?
                {
                    ...opt,
                    files: {
                        [fileName]: fileByteArray
                    },
                    s3Location: fileName
                } :
                opt
            )
        }))
    }

    const handleMultiFocus = (minValue, maxValue) => {
        if(question.minSelectInMultiChoice === '' && !minValue) {
            setQuestion(prevState => ({
                ...prevState,
                minSelectInMultiChoice: 1,
            }))
        }
        if (question.maxSelectInMultiChoice === '' && !maxValue) {
            setQuestion(prevState => ({
                ...prevState,
                maxSelectInMultiChoice: question.options.length,
            }))
        }
        setMultiToolTip({
            minFocus: minValue,
            maxFocus: maxValue,
        })
    }

    return (
        <Fragment>
            <Typography color="primary" sx={{ fontWeight: 'bold' }}>Options</Typography>
            <Stack direction="row" spacing={2} width="100%" alignItems='start' mt={3}>
                <Stack direction="row" width="55%" spacing={2}>
                    <TextField label={'Option Header'} id="optionHeader"
                        value={question.optionHeader} onChange={onInputChange}
                        size="small"
                        sx={{ width: '65%' }}
                        fullWidth
                        disabled={hasSystemAdmineOrSupportRole ? !hasSystemAdmineOrSupportRole : !hasPermission}
                        inputProps={{
                            style: { borderStyle: 'none' },
                        }} />
                    <EvotingRowInputField
                        label={t('Option_Type')}
                        component={<FormControl sx={{width: '60%'}}>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                size="small"
                                value={question.optionType}
                                onChange={handleTypeChange}
                                disabled={hasSystemAdmineOrSupportRole ? !hasSystemAdmineOrSupportRole : !hasPermission}
                            >
                                <MenuItem value={OptionType.Text}>Text</MenuItem>
                                <MenuItem value={OptionType.Image}>Image</MenuItem>
                                <MenuItem value={OptionType.TextAndImage}>Text and Image</MenuItem>
                            </Select>
                        </FormControl>} />
                </Stack>
                {question.questionType === QuestionType.MultiChoice && <Stack direction="column" width="45%">
                    <Stack direction="row" spacing={2}>
                        <Tooltip arrow open={multiToolTip.minFocus} placement="top-start"
                            title={`Enter value between ${1} and ${question.options.length}`}>
                            <TextField label={'Minimum Choice Required'} id="minSelectInMultiChoice"
                                value={question.minSelectInMultiChoice} onChange={OnMultiBoundaryChange} type="number"
                                fullWidth
                                disabled={hasSystemAdmineOrSupportRole ? !hasSystemAdmineOrSupportRole : !hasPermission}
                                onFocus={() => handleMultiFocus(true, false)}
                                onBlur={() => handleMultiFocus(false, false)}
                                inputProps={{
                                    // style: {width: '2%'},
                                    step: "any",
                                    min: 1,
                                    // max: 100,
                                    type: 'number',
                                    'aria-labelledby': 'minSelectInMultiChoice',
                                    style: { borderStyle: 'none', padding: '9px' },
                                }} />
                        </Tooltip>
                        <Tooltip arrow open={multiToolTip.maxFocus} placement="top-start"
                            title={`Enter value between ${question.minSelectInMultiChoice} and ${question.options.length}`}>
                            <TextField
                                label={'Maximum Choice Required'} id="maxSelectInMultiChoice"
                                value={question.maxSelectInMultiChoice} onChange={OnMultiBoundaryChange} type="number"
                                fullWidth
                                disabled={hasSystemAdmineOrSupportRole ? !hasSystemAdmineOrSupportRole : !hasPermission}
                                onFocus={() => handleMultiFocus(false, true)}
                                onBlur={() => handleMultiFocus(false, false)}
                                inputProps={{
                                    step: "any",
                                    min: 1,
                                    // max: 100,
                                    type: 'number',
                                    'aria-labelledby': 'maxSelectInMultiChoice',
                                    style: { borderStyle: 'none', padding: '9px' },
                                }} />
                        </Tooltip>
                    </Stack>
                    {QuestionType.MultiChoice === question.questionType &&
                        <MultiOptionMinMaxInfo minSelectOption={question.minSelectInMultiChoice} maxSelectOption={question.maxSelectInMultiChoice} isCastVote={false}/>}
                </Stack>}
            </Stack>
                {question.options.length > 0 && question.options.map((option, index) => (
                    // <Slide direction="up" in={true} container={containerRef.current} unmountOnExit>
                        <Stack direction="row" spacing={1} alignItems='center' width={'100%'} height='fit-content' mt={2} key={index}>
                            {OptionType.Image !== question.optionType &&
                                <Stack direction="row" alignItems='center' width={'100%'} height='fit-content' spacing={1}>
                                    <TextField id="option"
                                        value={option.option}
                                        // size='small'
                                        key={error[`option${index}`] !== undefined && error[`option${index}`] !== null}
                                        onFocus={() => handleOptionFocus(index)}
                                        autoFocus={(error[`option${index}`] !== undefined && error[`option${index}`] !== null ) || touched[`option${index}`] === true}
                                        multiline
                                        disabled={hasSystemAdmineOrSupportRole ? !hasSystemAdmineOrSupportRole : !hasPermission}
                                        variant="outlined" fullWidth
                                        placeholder={`Option ${index + 1}`}
                                        error={error[`option${index}`] !== undefined && error[`option${index}`] !== null}
                                        helperText={(error[`option${index}`] !== undefined && error[`option${index}`] !== null) && error[`option${index}`]}
                                        onChange={(event) => { onEnterOptionText(event, index) }}
                                        InputProps={{
                                            style: { borderStyle: 'none', padding: '12px' },
                                            // endAdornment: (
                                            //     <InputAdornment position="end">
                                            //         {<IconButton onClick={() => handleDeleteOption(index)} sx={{ float: 'right' }}>
                                            //             <Delete color="error" />
                                            //         </IconButton>}
                                            //     </InputAdornment>
                                            // ),
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    {<Typography color="textSecondary" style={{ fontWeight: 'bold' }}>{index + 1}.</Typography>}
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                    {OptionType.TextAndImage === question.optionType &&
                                        <Stack width={'40%'} alignItems='center'>
                                            {(hasPermission || hasSystemAdmineOrSupportRole) && <OptionFileUpload onSelectImage={onSelectImage} index={index} optionType={question.optionType}
                                                                optionFile={(option.files !== null && Object.values(option.files).length === 0) ? Object.entries(option.files) : option.files}
                                                                error={error} setError={setError} setMessage={setMessage}/>}
                                            {(!hasPermission && !hasSystemAdmineOrSupportRole) && <ImagePreview optionFile={option.files} />}
                                        </Stack>}
                                    {/* {(QuestionType.SingleChoice === question.questionType && ((!isEdit && dependentQuestions.length > 0) || (isEdit && dependentQuestions.length > 1))) && <FormControl fullWidth sx={{ width: '30%' }}>
                                        <InputLabel id="next-question-label">{t('Dependent')} {title}</InputLabel>
                                        <Select
                                            labelId="next-question-label"
                                            label={t('Dependent') + ' ' + title}
                                            id="next-question"
                                            size="small"
                                            disabled={!hasPermission}
                                            value={option.nextQuestionId}
                                            onChange={(event) => { handleNextQuestion(event, index) }}
                                            endAdornment={<IconButton sx={{ display: option.nextQuestionId === null ? "none" : "", marginRight: '5px' }} onClick={() => handleClearNextQuestion(index)}><Clear /></IconButton>}
                                            SelectDisplayProps={{
                                                style: { borderStyle: 'none', padding: '12px' },
                                            }}
                                        >
                                            {dependentQuestions.filter((dep) => dep.id !== question.id).map((dep) => {
                                                return (
                                                    <MenuItem value={dep.id}>{dep.question}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>} */}
                                </Stack>
                            }
                            {OptionType.Image === question.optionType &&
                                <Stack direction="row" width={'100%'} alignItems='center' spacing={2}>
                                    {(hasPermission || hasSystemAdmineOrSupportRole) && <OptionFileUpload onSelectImage={onSelectImage} index={index}
                                                                        optionType={question.optionType} error={error} setError={setError} setMessage={setMessage}
                                                                        optionFile={(option.files !== null && Object.values(option.files).length === 0) ? Object.entries(option.files) : option.files} />}
                                    {(!hasPermission && !hasSystemAdmineOrSupportRole ) && <ImagePreview optionFile={option.files}/>}
                                    {/* {(QuestionType.SingleChoice === question.questionType && ((!isEdit && dependentQuestions.length > 0) || (isEdit && dependentQuestions.length > 1))) && <FormControl fullWidth sx={{ width: '30%' }}>
                                        <InputLabel id="next-question-label">{t('Dependent')} {title}</InputLabel>
                                        <Select
                                            labelId="next-question-label"
                                            label={t('Dependent') + ' ' + title}
                                            id="next-question"
                                            size="small"
                                            disabled={!hasPermission}
                                            value={option.nextQuestionId}
                                            onChange={(event) => { handleNextQuestion(event, index) }}
                                            endAdornment={<IconButton sx={{ display: option.nextQuestionId === null ? "none" : "", marginRight: '5px' }} onClick={() => handleClearNextQuestion(index)}><Clear /></IconButton>}
                                            SelectDisplayProps={{
                                                style: { borderStyle: 'none', padding: '12px' },
                                            }}
                                        >
                                            {dependentQuestions.filter((dep) => dep.id !== question.id).map((dep) => {
                                                return (
                                                    <MenuItem value={dep.id}>{dep.question}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>} */}
                                </Stack>
                            }
                            {question.options.length > 2 && hasPermission && <IconButton onClick={() => handleDeleteOption(option, index)} sx={{
                                float: 'right',
                                paddingX: OptionType.Image === question.optionType ? '25px' : '10px',
                            }}>
                                <Delete color="error" />
                            </IconButton>}
                        </Stack>
                    // </Slide>
                ))}
            {(hasPermission || hasSystemAdmineOrSupportRole) && <Button variant="outlined" color="primary" startIcon={<Add />} onClick={handleAddOption} style={{width: 'fit-content', marginTop: '16px'}}>Add Option</Button>}
        </Fragment>
    )
}

export default memo(Options);