import { Image } from "@material-ui/icons";
import { Avatar } from "@mui/material";
import { Fragment, useCallback, useEffect } from "react";

const ImagePreview = ({optionFile}) => {

    const ImageShow = useCallback(() => {
        let thumbnail = null;
        if (optionFile !== null) {
            Object.entries(optionFile).map(([key, value]) => {
                thumbnail = {
                    preview: `data:image/png;base64,${value}`,
                }
            })
        }

        return (
            <Fragment>
                {
                    thumbnail !== null ? <Avatar src={thumbnail.preview} onLoad={() => { URL.revokeObjectURL(thumbnail.preview) }} sx={{ borderRadius: '6px', minWidth: '3em', minHeight: '3em' }} /> :
                        <Avatar sx={{ borderRadius: '6px', minWidth: '3em', minHeight: '3em' }}><Image /></Avatar>
                }
            </Fragment>
        )

    }, [optionFile])

    return (
        <ImageShow />
    )
}

export default ImagePreview;