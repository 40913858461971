import { Button, Card, Container, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import PublicPricing from '../pricing/PublicPricing';

const useStyles = makeStyles({
    priceCard: {
        margin: '8px'
    },
})

const Quote = () => {
    let history = useHistory();
    const classes = useStyles();

    const signIn = () => {
        history.push("/sign-in");
    }

    return <div style={{ backgroundImage: 'linear-gradient(45deg, #2a83ad, #f0f2f0)', padding: '8px' }}>
        <Button id='quiote' className="float-right" color="primary" onClick={signIn}
            variant='contained'> SIGN IN </Button>
        <Container maxWidth="xs">
            <Grid container spacing={0} alignItems="center" justify="center" style={{ minHeight: "98vh" }}>
                <Grid xs={12} sm={12}>
                    <Card className={`${classes.priceCard}`}>
                        <PublicPricing basePrice={4000} />
                    </Card>
                </Grid>
            </Grid>
        </Container>
    </div>
};

export default Quote;
