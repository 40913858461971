import { Close } from "@material-ui/icons";
import { Dialog, DialogContent, DialogTitle, Tooltip } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import EligoBackdrop from "../../../../common/EligoBackdrop";
import EligoSnackBar from "../../../../common/EligoSnackBar";
import { APIEvotingAxiosInterceptor } from "../../../../config/axios.interceptor";
import { OptionType } from "../../QuestionStaticVar";
import RealTimeResult from "../../poll-question-detail/question-section/real-time-result/RealTimeResult";

const RealTimeDialog = (props) => {
    const { open, setOpen, pollId, questionId, questionTitle, questionNumber, totalVoterCount, noOfVotedNotVoted } = props;
    const {t} = useTranslation();
    const [realTimeQuestion, setRealTimeQuestion] = useState();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({
        showMsg: false,
        message: null,
        severity: null
    });

    const getQuestion = () => {
        setMessage({ showMsg: false, message: null, severity: null });
        APIEvotingAxiosInterceptor.get(`question/${pollId}/${questionId}`).then(
            (response) => {
                if (response) {
                    setRealTimeQuestion({
                        ...response,
                        optionType: response.options.length !== 0 ? response.options[0].optionType : OptionType.Text
                    });
                }
                setLoading(false);
            }).catch(error => {
                setLoading(false);
                if (error.message) {
                    setMessage({ showMsg: true, message: error.message, severity: 'error' });
                }
            })
    }

    const handleQuestion = () => {
        setLoading(true);
        getQuestion();
    }

    useEffect(() => {
        handleQuestion();
    }, [])

    return (
        <Fragment>
            {
                open && <Dialog open={open} maxWidth='lg' fullWidth>
                    <DialogTitle>
                        {t('Real-Time Result')}
                        <Tooltip title='Close' arrow>
                            <Close onClick={() => setOpen(false)} size='large' color='error' style={{ float: 'right', cursor: 'pointer' }} />
                        </Tooltip>
                    </DialogTitle>
                    <DialogContent dividers sx={{minHeight: '200px'}}>
                        {realTimeQuestion && <RealTimeResult options={realTimeQuestion.options} question={realTimeQuestion.question}
                            totalVoterCount={totalVoterCount} noOfVotedNotVoted={noOfVotedNotVoted} questionNumber={questionNumber} questionTitle={questionTitle}
                            handleRefresh={handleQuestion} />}
                    </DialogContent>
                    {message.showMsg && <EligoSnackBar show={message.showMsg} message={message.message} severity={message.severity} />}
                    {loading && <EligoBackdrop show={loading} />}
                </Dialog>
            }
        </Fragment>
    )
}

export default RealTimeDialog;