import React, {
    useState,
    useRef
}                               from 'react';
import { useTranslation }       from 'react-i18next';
import {
    ButtonGroup,
    Button,
    Popper,
    Grow,
    Paper,
    MenuList,
    MenuItem,
    ClickAwayListener
}                               from '@material-ui/core';
import ArrowDropDownIcon        from '@material-ui/icons/ArrowDropDown';

const options = [{label: 'Setup_Poll', value: 'POLL'}, {label: 'Setup_Survey', value: 'SURVEY'}];

const SetupButton = ({setupPoll}) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const handleClick = () => {
        setupPoll(options[selectedIndex].value);
    };

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setOpen(false);
        setupPoll(options[index].value)
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
        }
        setOpen(false);
    };

    return (
        <React.Fragment>
        <ButtonGroup variant="contained" ref={anchorRef} aria-label="setup button" color='primary'>
            {/* <Button onClick={handleClick} id='setup-poll-btn'>{t(options[selectedIndex].label)}</Button> */}
            <Button
                size="small"
                aria-controls={open ? 'setup-button-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-label="select poll type"
                aria-haspopup="menu"
                onClick={handleToggle}
            >
                {t('Setup')} <ArrowDropDownIcon />
            </Button>
        </ButtonGroup>
        <Popper
            sx={{
            zIndex: 1000,
            }}
            style={{zIndex: 1}}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            placement={'bottom-start'}
        >
            {({ TransitionProps, placement }) => (
            <Grow
                {...TransitionProps}
                style={{
                transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}
            >
                <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="setup-button-menu" autoFocusItem>
                    {options.map((option, index) => (
                        <MenuItem
                        id={option.label}
                        key={option.label}
                        disabled={index === 2}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                        >
                        {t(option.label)}
                        </MenuItem>
                    ))}
                    </MenuList>
                </ClickAwayListener>
                </Paper>
            </Grow>
            )}
        </Popper>
        </React.Fragment>
    );
}

export default SetupButton;