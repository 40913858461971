import { InputAdornment, makeStyles, TextField, Tooltip } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Stack } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AxiosEvotingInterceptor } from '../../../config/axios.interceptor';

const useStyles = makeStyles({
    root: {
        "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'][class*='MuiOutlinedInput-marginDense'] .MuiAutocomplete-input": {
            position: 'relative',
            right: "14px",
            bottom: "1px",
        },
        '& .MuiAutocomplete-endAdornment': {
            display: 'none'
        },
        '& .MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"], .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
            paddingRight: '0px !important'
        },
        '& .MuiInputBase-fullWidth': {
            // width: '87%'
        }
        // '& .MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]' : {
        //     width: '25%',
        // },
        // '& .MuiAutocomplete-popupIndicator': {
        //     marginRight: '0px !importanat',
        //     marginTop: -2,
        // }
    },
    searchFiled: {
        "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
            background: '#fff',
            borderRadius: 18,
            height: 39,
            paddingRight: "0px !important",
            boxShadow: '#a6adab17 0px 13px 27px -5px, #ced3d8 0px 8px 16px -8px '
        }
    },

})

const PollSearch = (props) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [searchFilter, setSearchFilter] = useState('');
    const [options, setOptions] = useState([]);
    const classes = useStyles();
    const [touched, setTouched] = useState(false)
    const [selectedPoll, setSelectedPoll] = useState('');
    const [loading, setLoading] = useState([]);

    const getSearchedPoll = () => {
        setLoading(true)
        AxiosEvotingInterceptor.get(`poll/${props.pollType.toUpperCase()}/${props.role.toUpperCase()}?page=${1}&limit=${5}&asc=true&searchText=${searchFilter}`).then((response) => {
            setOptions([])
            setLoading(false)
            setOptions((response.polls).map((poll) => {
                return {
                    alertTime: poll.alertTime,
                    alerted: poll.alerted,
                    allowVoteChange: poll.allowVoteChange,
                    announceResultAutomatically: poll.announceResultAutomatically,
                    anonymousVoting: poll.anonymousVoting,
                    conductFor: poll.conductFor,
                    createdOn: poll.createdOn,
                    dateTimeInIndianZone: poll.dateTimeInIndianZone,
                    description: poll.description,
                    discloseVoterInResult: poll.discloseVoterInResult,
                    displayOrganizerName: poll.displayOrganizerName,
                    endTime: poll.endTime,
                    endTimeInIndianDateTime: poll.endTimeInIndianDateTime,
                    geoFencing: poll.geoFencing,
                    geoTagging: poll.geoTagging,
                    hasVoterRole: poll.hasVoterRole,
                    id: poll.id,
                    noOfVoterNotVoted: poll.noOfVoterNotVoted,
                    noOfVoterVoted: poll.noOfVoterVoted,
                    numberOfQuestions: poll.numberOfQuestions,
                    organizers: poll.organizers,
                    paidVoter: poll.paidVoter,
                    paymentStatus: poll.paymentStatus,
                    pollClosed: poll.pollClosed,
                    pollNumber: poll.pollNumber,
                    pollType: poll.pollType,
                    questionShuffle: poll.questionShuffle,
                    questions: poll.questions,
                    realTimeResult: poll.realTimeResult,
                    referenceNumber: poll.referenceNumber,
                    resultAnnouncementTime: poll.resultAnnouncementTime,
                    resultOpenTime: poll.resultOpenTime,
                    roles: poll.roles,
                    scrutinizerHasToOpen: poll.scrutinizerHasToOpen,
                    sentEndAlert: poll.sentEndAlert,
                    sentResultToVoters: poll.sentResultToVoters,
                    sentStartAlert: poll.sentStartAlert,
                    showResultsToVoters: poll.showResultsToVoters,
                    sourceIp: poll.sourceIp,
                    startTime: poll.startTime,
                    startTimeInIndianDateTime: poll.startTimeInIndianDateTime,
                    status: poll.status,
                    timeout: poll.timeout,
                    title: poll.title,
                    verified: poll.verified,
                    verifyWithOtp: poll.verifyWithOtp,
                    voteWithSignUp: poll.voteWithSignUp,
                    voted: poll.voted,
                    voterCount: poll.voterCount,
                    voterEdgeId: poll.voterEdgeId,
                    websiteRedirect: poll.websiteRedirectsetSelectedPoll,
                    pollCategory: poll.pollCategory,
                    zoneId: poll.zoneId
                }
            }));
        }).catch((error) => {
            setLoading(false)
        })
    }

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    useEffect(() => {
        if (selectedPoll != '' && selectedPoll != null) {
            setSearchFilter(selectedPoll.title);
            props.onSelect(selectedPoll);
        } else if(selectedPoll === null){
            props.onSelect('', touched);
            setOptions([])
        }
    }, [selectedPoll]);

    useEffect(() => {
        if (searchFilter == '') {
            props.onSelect(searchFilter, touched);
            setOptions([])
        }
        if (searchFilter != '' && open) {
            // props.onSelect('', touched);
            getSearchedPoll()
        }
    }, [searchFilter]);

    const onChangeSearch = (e) => {
        setSearchFilter(e.target.value)
        setTouched(true)
    }

    return (
        // <h1>i am search</h1>
        <Fragment>
            <Autocomplete
                freeSolo
                id="search-poll"
                className={classes.searchFiled}
                open={open}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                onChange={(event, data) => {
                    setSelectedPoll(data);
                }}
                disableClearable={false}
                getOptionLabel={(option) => option.title}
                options={options}
                loading={loading}
                // inputValue={searchFilter}
                disabled={props.disable ? props.disable : false}
                renderInput={(params) => (
                    <Tooltip title={"search is case-sensitive"}>
                        <TextField {...params}
                            onChange={onChangeSearch}
                            id='searchId'
                            variant='outlined'
                            size='small'
                            value={searchFilter}
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <SearchIcon style={{ marginBottom: '4px', marginRight: '6px' }} />
                                    </InputAdornment>
                                ),
                                onKeyDown: (e) => {
                                    if (e.key === 'Enter') {
                                      e.stopPropagation();
                                    }
                              },
                                // endAdornment: (
                                //     <React.Fragment>
                                //         {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                //         {params.InputProps.endAdornment}
                                //     </React.Fragment>
                                // ),
                            }}
                            placeholder={t('Search_Poll')}
                        />
                    </Tooltip>
                )}
            />
            {/* <span style={{fontSize: "11px", marginLeft: "31px", color: "tan"}}>{'Note: Poll is case-sensitive'}</span>  */}
        </Fragment>
    )
}

export default PollSearch;