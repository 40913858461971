import { Clear, Delete, Done, Edit } from '@material-ui/icons';
import { Fab, TextField, Tooltip } from '@mui/material';
import React, { Fragment, useState } from 'react';
import { ClientFields, IdentificationTypes } from '../ClientStaticVar';
import { AuctionFieldSet } from '../client-style/ClientStyle';
import { AxiosEvotingInterceptor } from '../../../config/axios.interceptor';
import EligoBackdrop from '../../../common/EligoBackdrop';
import EligoSnackBar from '../../../common/EligoSnackBar';
import EligoConfirmDialog from '../../../common/EligoConfirmDialog';

const ClientIdentification = (props) => {
    const { t, isEditID, isNew, setErrors, errors, classes, type, identificationKey, resetOtherEdits, loading, setLoading,
        setIsEditID, clientDetails, setClientDetails, validateInputOnChange, disabled, gstDisable, panDisable, onBlur } = props;

    const [message, setMessage] = useState({
        show: false,
        message: '',
        severity: ''
    })

    const [seekConfirmation, setSeekConfirmation] = useState({
        show: false,
        title: '',
        message: '',
        onAgree: '',
        onDisAgree: '',
        isCancel: true
    })

    const addIdentification = (clientIdentification) => {
        setLoading(true);
        let payload = {
            id: '',
            identificationType: clientIdentification.identificationType,
            identification: clientIdentification.identification
        }
        AxiosEvotingInterceptor.post(`identification/${clientDetails.personId}`, payload).then(response => {
            if (response) {
                setClientDetails(prevState => ({
                    ...prevState,
                    [identificationKey]: {
                        id: response.id,
                        identification: response.identification,
                        identificationType: response.identificationType,
                        previousIdentification: ''
                    }
                }))
                setIsEditID(false);
                if (IdentificationTypes.gstin === type) {
                    setMessage({ showMsg: true, message: t('Added_Client_GSTIN_Successfully'), severity: 'success' });
                } else if (IdentificationTypes.pan === type) {
                    setMessage({ showMsg: true, message: t('Added_Client_PAN_Successfully'), severity: 'success' });
                }
            }
            setLoading(false);
        }).catch(error => {
            if (error.message) {
                setMessage({ showMsg: true, message: error.message, severity: 'error' })
            } else {
                setMessage({ showMsg: true, message: t('Try_Again'), severity: 'error' })
            }
            setLoading(false);
        })
    }

    const updateIdentification = (clientIdentification) => {
        setLoading(true);
        let payload = {
            id: clientIdentification.id,
            identificationType: clientIdentification.identificationType,
            identification: clientIdentification.identification
        }
        AxiosEvotingInterceptor.put(`identification/update/${clientDetails.personId}`, payload).then(response => {
            if (response) {
                setClientDetails(prevState => ({
                    ...prevState,
                    [identificationKey]: {
                        id: response.id,
                        identification: response.identification,
                        identificationType: response.identificationType,
                        previousIdentification: ''
                    }
                }))
                setIsEditID(false);
                if (IdentificationTypes.gstin === type) {
                    setMessage({ showMsg: true, message: t('Updated_Client_GSTIN_Successfully'), severity: 'success' });
                } else if (IdentificationTypes.pan === type) {
                    setMessage({ showMsg: true, message: t('Updated_Client_PAN_Successfully'), severity: 'success' });
                }
            }
            setLoading(false);
        }).catch(error => {
            if (error.message) {
                setMessage({ showMsg: true, message: error.message, severity: 'error' })
            } else {
                setMessage({ showMsg: true, message: t('Try_Again'), severity: 'error' })
            }
            setLoading(false);
        })
    }

    const deleteIdentification = (id) => {
        setLoading(true);
        AxiosEvotingInterceptor.delete(`identification/delete/${clientDetails.personId}/${id}`).then(response => {
            if (response) {
                setClientDetails(prevState => ({
                    ...prevState,
                    [identificationKey]: {
                        id: '',
                        identification: '',
                        identificationType: identificationKey === 'identificationGst' ? IdentificationTypes.gstin : IdentificationTypes.pan,
                        previousIdentification: ''
                    }
                }))
                if (IdentificationTypes.gstin === type) {
                    setMessage({ showMsg: true, message: t('Deleted_Client_GSTIN_Successfully'), severity: 'success' });
                } else if (IdentificationTypes.pan === type) {
                    setMessage({ showMsg: true, message: t('Deleted_Client_PAN_Successfully'), severity: 'success' });
                }
            }
            setLoading(false);
        }).catch(error => {
            if (error.message) {
                setMessage({ showMsg: true, message: error.message, severity: 'error' })
            } else {
                setMessage({ showMsg: true, message: t('Try_Again'), severity: 'error' })
            }
            setLoading(false);
        })
    }

    const deleteConfirmation = (selectedId) => {
        setSeekConfirmation({
            show: true,
            title: t('Confirmation'),
            message:    <Fragment>{t('Are_You_Sure_That_You_Want_To_Delete')}&nbsp;
                            {identificationKey === 'identificationGst' ? t('GSTIN') : t('PAN')}{t('?')}
                        </Fragment>,
            onAgree: () => handleDelete(selectedId),
            onDisAgree: resetSeekConfirmation,
            isCancel: true
        })
    }

    const handleDelete = (selectedId) => {
        resetSeekConfirmation();
        if (selectedId) {
            deleteIdentification(selectedId)
        }
    }

    const resetSeekConfirmation = () => {
        setSeekConfirmation({
            show: false,
            title: '',
            message: '',
            onAgree: null,
            onDisAgree: null
        })
    }

    const onIdentificationChange = (event) => {
        let value = event.target.value.trim();
        setClientDetails(prevState => ({
            ...prevState,
            [identificationKey]: {
                ...prevState[identificationKey],
                identification: value.toUpperCase()
            }
        }))
        validateInputOnChange(identificationKey, value.toUpperCase(), clientDetails[identificationKey].id)
    }

    const setEditID = (id) => {
        setClientDetails(prevState => ({
            ...prevState,
            [identificationKey]: {
                ...prevState[identificationKey],
                previousIdentification: id
            }
        }))
        setIsEditID(true);
        resetOtherEdits(ClientFields[identificationKey]);
    }

    const cancelEditID = () => {
        setErrors({})
        setClientDetails(prevState => ({
            ...prevState,
            [identificationKey]: {
                ...prevState[identificationKey],
                identification: prevState[identificationKey].previousIdentification,
                previousIdentification: ''
            }
        }))
        setIsEditID(false);
    }

    const updateID = async (clientIdentification) => {
        setMessage({ showMsg: false, message: null, severity: null })
        setErrors({})
        if (clientDetails[identificationKey].id !== null && clientDetails[identificationKey].id !== undefined && clientDetails[identificationKey].id !== '') {
            setLoading(true);
            if (clientDetails.identificationGst.identification === '' && clientDetails.identificationPan.identification === '') {
                setMessage({ showMsg: false, message: '', severity: '' });
                setTimeout(() => {
                    setMessage({ showMsg: true, message: t('GSTIN_Or_PAN_Is_Mandatory'), severity: 'error' });
                    setLoading(false);
                }, 100)
            } else {
                updateIdentification(clientIdentification);
            }
        } else {
            addIdentification(clientIdentification);
        }
    }

    return (
        // <AuctionFieldSet style={{ width: '100%' }}>
        //     {!clientDetails.loginUser && !isEditID && !isNew &&
        //         <Fragment>
        //             <Tooltip title={t('Edit')}>
        //                 <Fab className={(clientDetails.identificationGst.id !== '' && clientDetails.identificationPan.id !== '') ?
        //                     classes.deleteFab : classes.editFab} id={`edit-${identificationKey}-client`} style={{ float: 'right' }} onClick={() => setEditID(clientDetails[identificationKey].identification)}
        //                     disabled={disabled}>
        //                     <Edit color='primary'  />
        //                 </Fab></Tooltip>
        //             {clientDetails.identificationGst.id !== '' && clientDetails.identificationPan.id !== '' && <Tooltip title={identificationKey === 'identificationGst' ? t('Remove_GSTIN') : t('Remove_PAN')}>
        //                 <Fab className={(clientDetails.identificationGst.id !== '' && clientDetails.identificationPan.id !== '') ?
        //                     classes.editFab : classes.deleteFab} style={{ float: 'right' }} onClick={() => deleteConfirmation(clientDetails[identificationKey].id)}
        //                     disabled={disabled}>
        //                     <Delete color='error'  />
        //                 </Fab>
        //             </Tooltip>}
        //         </Fragment>
        //     }
        //     {isEditID &&
        //         <>
        //             <Tooltip title={t('Save')}><Fab className={classes.checkFab} id={`save-${identificationKey}-client`} style={{ float: 'right' }} onClick={() => updateID(clientDetails[identificationKey])}
        //                 disabled={(errors[identificationKey] !== undefined && errors[identificationKey] !== '' && errors[identificationKey] !== null)
        //                     || (clientDetails[identificationKey].identification === '')}>
        //             <Done style={{color: 'green'}}  />
        //             </Fab></Tooltip>
        //             <Tooltip title={t('Cancel')}><Fab className={classes.closeFab} id={`cancel-${identificationKey}-client`} style={{ float: 'right' }} onClick={() => cancelEditID()}>
        //                 <Clear color='error'  /></Fab></Tooltip>
        //         </>
        //     }
        <div style={{ width: '100%' }}>
            <TextField id={identificationKey} label={t(clientDetails[identificationKey].identificationType)}
                value={clientDetails[identificationKey].identification}
                helperText={errors[identificationKey]}
                error={(errors[identificationKey] !== undefined && errors[identificationKey] !== null && errors[identificationKey] !== '')}
                onChange={onIdentificationChange}
                onBlur={onBlur}
                disabled={disabled || (gstDisable || panDisable )}
                fullWidth variant="standard"
            />
        </div>

        //     {(loading) && <EligoBackdrop show={(loading)} invisible={false} />}
        //     {message.showMsg && <EligoSnackBar show={message.showMsg} message={message.message}
        //         severity={message.severity} />}
        //     {seekConfirmation.show && <EligoConfirmDialog show={seekConfirmation.show}
        //         title={seekConfirmation.title} message={seekConfirmation.message} onAgree={seekConfirmation.onAgree}
        //         onDisAgree={seekConfirmation.onDisAgree} />}
        // </AuctionFieldSet>
    )
}

export default ClientIdentification
