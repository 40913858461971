import React, { Fragment, useEffect } from 'react';
import { useCountDown } from '../../common/hooks/useCountDown';
import { useTranslation } from 'react-i18next';
import { Card, styled, Typography } from "@material-ui/core";
import { Divider, Stack } from '@mui/material';

const TimerCard = styled(Card)(({ theme }) => ({
    borderLeft: '6px solid rgb(13, 186, 183)',
    boxShadow: 'rgb(173 221 208 / 9%) 0px 13px 27px -5px, rgb(177 210 201) 0px 8px 16px -8px !important',
    border: '1px solid rgb(173, 221, 212)',
    borderRadius: '10px',
    // marginLeft: '5px',
    // width: '100%'
    // cd0909
    // rgb(221 173 173 / 9 %) 0px 13px 27px - 5px, rgb(210 177 177) 0px 8px 16px - 8px
    //rgb(253 236 236)

    //rgb(186 13 13) borderLet
}))

const DisplayTime = ({ time, timeUnit }) => {
    const { t } = useTranslation();
    return (
        <div style={{ padding: '2px', backgroundColor: "white" }}>
            <div style={{ fontSize: '20px', fontWeight: "Bold" }}>
                {time > 9 ? time : '0' + time}
            </div>
            <div style={{ fontSize: '12px' }}>{t(timeUnit)}</div>
        </div>
    )
}

const PollTimer = ({ date, title, terminated }) => {
    const [days, hours, minutes, seconds] = useCountDown(date);

    useEffect(() => {
        if (seconds === 0 && days === 0 && hours === 0 && minutes === 0) {
            if (terminated) {
                terminated();
            }
        }
    }, [days, hours, minutes, seconds])


    return (
        <Fragment>
            {(days > 0 || hours > 0 || minutes > 0 || seconds > 0) && <div style={{ display: "flex", textAlign: "center" }}>
                <TimerCard>
                    <Stack direction='row' justifyContent='center' alignItems='center' spacing={1.5}>
                        <Typography>{title}</Typography>
                        {/* <Divider flexItem sx={{ borderRightWidth: 2 }} variant='middle' /> */}
                        <span style={{ display: 'flex' }}><DisplayTime time={days} timeUnit="Days" /></span>
                        {/* <Divider orientation="vertical" variant="middle" flexItem style={{margin: '6px'}} /> */}
                        <span style={{ display: 'flex' }}><DisplayTime time={hours} timeUnit="Hours" /></span>
                        {/* <Divider orientation="vertical" variant="middle" flexItem style={{margin: '6px'}}/> */}
                        <span style={{ display: 'flex' }}><DisplayTime time={minutes} timeUnit="Minutes" /></span>
                        {/* <Divider orientation="vertical" variant="middle" flexItem style={{margin: '6px'}}/> */}
                        <span><DisplayTime time={seconds} timeUnit="Seconds" /></span>
                    </Stack>
                </TimerCard>
            </div>}
        </Fragment>
    )
}

export default PollTimer;