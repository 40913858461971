import React from 'react'
import { FormControlLabel, IconButton, Tooltip } from '@material-ui/core';

const Btns = (props) => {
    const clickEvent = () => {
        props.click()
    }
    return (
        <FormControlLabel
            style={{ alignSelf: 'center' }}
            aria-label={props.label}
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
            control={
                <div>
                    {props.insidePoll ?
                        <IconButton aria-label={props.label} id={props.id} color={props.color} onClick={clickEvent} className='float-right'>
                            {props.btn}
                        </IconButton>
                        : <Tooltip title={props.tooltip} placement="bottom" arrow>
                            <IconButton aria-label={props.label} id={props.id} color={props.color} onClick={clickEvent} className='float-right'>
                                {props.btn}
                            </IconButton>
                        </Tooltip>}
                </div>
            }
        />
    )
}

export default Btns;
