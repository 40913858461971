import React, {useState, Fragment} from 'react'
import { Paper,
    CardContent,
    Card,
    CardActions,
    Button } from '@material-ui/core';
import * as Validators from '../../common/form-validators'
import  EligoBackDrop from '../../common/EligoBackdrop';
import ActaConfirmDialog from '../../common/EligoConfirmDialog';
import { useTranslation } from 'react-i18next';
import EligoSnackBar from '../../common/EligoSnackBar';
import { AxiosEvotingInterceptor } from '../../config/axios.interceptor';
import AddressDetails from './AddressDetails';

const AddressTab = (props) => {
    const { isLoginUser, sourceId, deletePermission } = props;
    const { t } = useTranslation();
    const [errors, setErrors] = useState({});
    const [touched, setTouched] = useState({});
    const [loading, setloading] = useState(false);
    const [addressError, setAddressError] = useState('');
    const [address, setAddress] = useState({
        addressId: props.data.addressId,
        attention: props.data.attention.replace(/^\s+|\s+$/gm,'').replace( /\s\s+/g, ' ' ),
        phone: props.data.phone,
        addressLine1: props.data.addressLine1,
        addressLine2: props.data.addressLine2,
        cityTownId: props.data.cityTownId,
        cityTown: props.data.cityTown,
        country: props.data.country,
        postalCode: props.data.postalCode,
        postalCodeId: props.data.postalCodeId,
        stateId: props.data.stateId,
        state: props.data.state,
        addressType: props.data.addressType,
        countryId: props.data.countryId,
        landmark: props.data.landmark,
        country: props.data.country,
    })

    const validate = {
        attention: attention => Validators.requiredValidation('Name', attention),
        addressLine1: addressLine1 => Validators.requiredValidation('Address Line 1', addressLine1),
        country: country => Validators.requiredValidation('Country', country),
        postalCode: postalCode => (Validators.requiredValidation('Pincode', postalCode) || Validators.postalCodeValidation('postalCode', postalCode, 6)),
        state: state => Validators.requiredValidation(t('State'), state),
        stateId: stateId => Validators.requiredValidation(t('State Id'), stateId),
        cityTown: cityTown => (Validators.requiredValidation(t('Town/City/Taluk'), cityTown) || Validators.cityTownValidation('Town/City/Taluk', cityTown, 50)),
        stateCode: stateCode => (Validators.requiredValidation('State Code', stateCode) || Validators.maxLengthValidation('State Code', stateCode, 5)),
        phone:  Validators.addressMobileNumberValidation
    };

    const [seekConfirmation, setSeekConfitmation] = useState({
        show: false,
        title: '',
        message: '',
        onAgree: '',
        isCancel: false,
        agreeBtnLabel: 'OK'
    })

    const [isEdit, setIsEdit] = useState(props.data.addressId === '' ? true : false);

    const enableEdit = () => {
        setIsEdit(!isEdit);
        props.edit(!isEdit)
        props.disabled(true)
    }

    const cancelAddress = () => {
        props.disabled(false)
        props.edit(false)
        setIsEdit(false);
        props.refreshlistAddress()
        props.closeModels(!isEdit);
    }

    const setJsonData = (event) => {
        setAddress(prevState => ({
            ...prevState,
            [event.target.id]: event.target.value
        }))
        props.setData(address)
    }

    const setJsonCountryStateCityData = (fieldName, value) => {
        setAddress(prevState => ({
          ...prevState,
          [fieldName]: value
        }))
        props.setData(address)
      }

    // const setStateData = (fieldName, event) => {
    //     setAddress(prevState => ({
    //         ...prevState,
    //         [fieldName]: event
    //     }))
    //     props.setData(address)
    // }
    // const setCityData = (fieldName, event) => {
    //     setAddress(prevState => ({
    //         ...prevState,
    //         [fieldName]: event
    //     }))
    //     props.setData(address)
    // }

    const setPhoneNumber = (fieldName, event) => {
        setAddress(prevState => ({
            ...prevState,
            [fieldName]: event
        }))
        props.setData(address)
    }

    const createAddress = () => {
        let url = isLoginUser ? `address/login-user` : `address/${sourceId}`;
        let payload = {...address}
        AxiosEvotingInterceptor.post(url, payload).then(response => {
            if (response) {
                setIsEdit(false)
                props.refreshlistAddress()
                props.disabled(false)
                props.edit(false);
                setloading(false);
            }
        }).catch(error => {
            if (error.message) {
                setAddressError(error.message);
            } else {
                setAddressError(t('Try_Again'));
            }
            setloading(false);
        })
    }

    const updateAddress = () => {
        let url = isLoginUser ? `address/update/login-user` : `address/update/${sourceId}`;
        let payload = { ...address }
        AxiosEvotingInterceptor.put(url, payload).then(response => {
            if (response) {
                setIsEdit(false)
                props.refreshlistAddress()
                props.disabled(false)
                props.edit(false)
            }
        }).catch(error => {
            if (error.message) {
                setAddressError(error.message);
            } else {
                setAddressError(t('Try_Again'));
            }
            setloading(false);
        })
    }

    const deleteAddress = () => {
        AxiosEvotingInterceptor.delete(`address/${props.data.addressId}`).then(response => {
            if (response) {
                setIsEdit(false)
                props.refreshlistAddress()
                props.setValue(0)
                props.disabled(false)
                props.edit(false)
                setloading(false);
            }
        }).catch(error => {
            if (error.message) {
                setAddressError(error.message);
            } else {
                setAddressError(t('Try_Again'));
            }
            setloading(false);
        })
    }

    const deleteAdd = () => {
        setSeekConfitmation(prevState => ({
            ...prevState,
            show: true,
            title: "Delete",
            message: "Are you sure you want to delete address of type ' " + address.addressType + " ' ?",
            onAgree: deleteAddressData,
            onDisAgree: resetConfirmation,
            agreeBtnLabel: "Yes",
            disAgreeBtnLabel: "No"
        }))
    }

    const deleteAddressData = () => {
        setloading(true);
        resetConfirmation();
        deleteAddress()
    }

    const resetConfirmation = () => {
        setSeekConfitmation(prevState => ({
            ...prevState,
            show: false,
            title: '',
            message: '',
            onAgree: '',
            isCancel: false,
            agreeBtnLabel: 'OK'
        }))
    }

    const saveAddress = async () => {
        await validateForm().then(data => {
            if(Object.values(data.errors).length == 0) {
                setloading(true);
                if (address.addressId == '') {
                    createAddress();
                } else {
                    updateAddress();
                }

            }
        })
    }
    const validateForm = async () => {
        setErrors({})
        setTouched({})
        let formErrors = { errors: {}};
        Object.entries(address).map(([key]) => {
            if (key === 'addressLine1' || key === 'country' || key === 'postalCode' || key === 'state'
                    || key === 'stateCode'  || key === 'cityTown') {
                const newError = validate[key](address[key])
                const newTouched = { [key]: true };
                formErrors = {
                    errors: {
                        ...formErrors.errors,
                        ...(newError && { [key]: newError }),
                    },
                    touched: {
                        ...formErrors.touched,
                        ...newTouched
                    }
                }
            }
        })
        setErrors(formErrors.errors);
        setTouched(formErrors.touched)
        return formErrors;
    }

    return (
        <Card style={{ padding: '12px' }} className="loading-container" elevation={3}>
            <CardContent>
                <Paper elevation={0} >
                {/* <Grid item xs={12} sx={{ marginTop: "1px" }}>
                        {isEdit &&
                        <Fragment>
                            <Tooltip title={t('Save')}>
                                <Fab id='saveUpdate' style={{ float: 'right',  width: '34px', height: '34px', margin: '12px 6px' }} color='success'
                                onClick={saveAddress} size="small">

                                <Check color={address.addressId == '' ? "Save": "Update"} />
                                </Fab>
                            </Tooltip>
                            <Tooltip title={t('Cancel')}>
                                <Fab id='cancel'  style={{ float: 'right',  width: '34px', height: '34px', margin: '12px 6px' }} color='error' onClick={() => cancelAddress()} size="small">
                                <Clear color='error' />
                                </Fab>
                            </Tooltip>
                        </Fragment>
                        }
                        {(address.addressId != '' && !isEdit) &&
                            <Fragment>
                                <Tooltip title={t('Edit')}>
                                    <Fab id='address-edit' onClick={enableEdit} style={{ float: 'right', width: '34px', height: '34px', margin: '12px 6px' }} color='#ffffff' size="small">
                                        <Edit color='primary' />
                                    </Fab>
                                </Tooltip>
                                <Tooltip title={t('Delete')}>
                                    <Fab id='address-delete' onClick={deleteAdd} style={{ float: 'right', width: '34px', height: '34px', margin: '12px 6px'}} color='#ffffff' size="small">
                                        <Delete color='error' />
                                    </Fab>
                                    </Tooltip>
                            </Fragment>
                        }
                    </Grid> */}
                    <AddressDetails data={address} errors={errors} touched={touched} isEdit={isEdit} setErrors={setErrors}
                            setJsonData={setJsonData} setPhoneNumber={setPhoneNumber} setJsonCountryStateCityData={setJsonCountryStateCityData}/>
                    {/* <Grid container spacing={3}>
                        <Grid item sm={6} xs={12}>
                            <TextField id="attention" label={t("Name")} required fullWidth
                                value={address.attention} onChange={setJsonData}
                                helperText={touched['attention'] && errors?.attention}
                                error={touched['attention'] && errors?.attention }
                                disabled={!isEdit}
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TextField id="addressLine1" label={t("addressLine1")} required fullWidth
                                value={address.addressLine1} onChange={setJsonData}
                                helperText={touched['addressLine1'] && errors?.addressLine1}
                                error={touched['addressLine1'] && errors?.addressLine1 }
                                disabled={!isEdit}
                            />
                        </Grid>
                        <Grid  item sm={6} xs={12}>
                            <TextField id="addressLine2" label={t("addressLine2")} fullWidth
                                value={address.addressLine2} onChange={setJsonData}
                                disabled={!isEdit}
                            />
                        </Grid>
                        <Grid  item sm={3} xs={6}>
                            <TextField id="town_city" label={t("Town_City")} fullWidth
                                value={address.town_city} onChange={setJsonData}
                                disabled={!isEdit}
                            />
                        </Grid>
                        <Grid  item sm={3} xs={6}>
                            <TextField id="state" label={t("state")} required fullWidth
                                value={address.state} onChange={setJsonData}
                                helperText={touched['state'] && errors?.state}
                                error={touched['state'] && errors?.state }
                                disabled={!isEdit}
                            />
                        </Grid>
                        <Grid  item sm={3} xs={6}>
                            <TextField id="stateCode" label={t("stateCode")} required fullWidth
                                value={address.stateCode} onChange={setJsonData}
                                helperText={touched['stateCode'] && errors?.stateCode}
                                error={touched['stateCode'] && errors?.stateCode }
                                disabled={!isEdit}
                            />
                        </Grid>
                        <Grid  item sm={3} xs={6}>
                            <TextField id="country" label={t("Country")} required fullWidth
                                value={address.country} onChange={setJsonData}
                                helperText={touched['country'] && errors?.country}
                                error={touched['country'] && errors?.country}
                                disabled={!isEdit}
                            />
                        </Grid>
                        <Grid  item sm={3} xs={6}>
                            <TextField id="postalCode" label={t("postalCode")} required fullWidth
                                value={address.postalCode} onChange={setJsonData}
                                helperText={touched['postalCode'] && errors?.postalCode}
                                error={touched['postalCode'] && errors?.postalCode }
                                disabled={!isEdit}
                            />
                        </Grid>
                        <Grid  item sm={3} xs={6}>
                            <MuiPhoneNumber
                                id="phone"
                                label="Mobile Number"
                                defaultCountry={"in"}
                                value={address.phone}
                                disabled={!isEdit}
                                disableDropdown={!isEdit}
                                onChange={(event) => setPhoneNumber('phone', event)}
                                error={touched['phone'] && errors['phone']}
                                helperText={touched['phone'] && errors['phone']}
                                autoFormat={false} fullWidth required
                            />
                        </Grid>
                    </Grid> */}
                </Paper>
            </CardContent>
            <CardActions style={{float: 'right'}}>
                {isEdit && <Fragment><Button id='cancel' color="inherit" variant="contained" onClick={cancelAddress} size="small">
                    Cancel
                </Button>
                <Button id='saveUpdate' variant="contained" onClick={saveAddress} color="primary" size="small">
                        {address.addressId == '' ? "Save": "Update"}
                </Button> </Fragment>}
                {(address.addressId != '' && !isEdit) &&
                    <Fragment>
                        {deletePermission && <Button id='deleteAddress' variant="outlined" onClick={deleteAdd}  color="secondary" size="small">
                            DELETE
                        </Button>}
                        <Button id='editAddress' variant="outlined" onClick={enableEdit} color="primary" size="small">
                            Edit
                        </Button>
                    </Fragment>}
            </CardActions>
            {loading && <EligoBackDrop show={loading} />}
            {addressError !== '' && addressError !== undefined ?
                <EligoSnackBar show={true} message={addressError} severity="error" handleResetOnClose={() => setAddressError('')} /> : null}
            {seekConfirmation.show && <ActaConfirmDialog show={seekConfirmation.show}
                title={seekConfirmation.title} message={seekConfirmation.message} agreeBtnLabel={seekConfirmation.agreeBtnLabel}
                onAgree={seekConfirmation.onAgree} disAgreeBtnLabel={seekConfirmation.disAgreeBtnLabel} onDisAgree={seekConfirmation.onDisAgree}/>}
        </Card>
    )
}

export default AddressTab;