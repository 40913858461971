import React, { useState } from 'react';
import {
    Grid, FormControlLabel, FormControl, FormLabel, RadioGroup, Radio,
    Card, CardContent, Button, CardHeader, Tooltip, IconButton, makeStyles, Typography
} from '@material-ui/core';
import SearchVoter from '../../poll-round-voter/search-voter/SearchVoter';
import EligoBackdrop from '../../../common/EligoBackdrop';
import EligoSnackBar from '../../../common/EligoSnackBar';
import { Status } from '../../../common/GenericCodes';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { AxiosEvotingInterceptor } from '../../../config/axios.interceptor';

const AssignRole = (props) => {
    const { t } = useTranslation();
    let accessPollStatus = [Status.open, Status.new, Status.scheduled]
    const [user, setUser] = useState({
        poll_id: '',
        user_id: '',
        role: 'organizer',
        sequence: '',
        name: '',
        email: ''
    })
    const [resetUser, setResetUser] = useState(true);
    const [message, setMessage] = useState({
        show: false,
        msg: '',
        severity: ''
    })
    const [loading, setLoading] = useState(false);

    const assignNewRoleMutation = () => {
        setMessage({ show: false, message: '', severity: '' });
        let payload = {
            pollId: props.pollId,
            userId: user.user_id,
            roleDetails: [
                {
                    role: user.role
                }
            ]
        }
        AxiosEvotingInterceptor.post(`role/${props.pollId}`, payload).then(response => {
            props.getPollRoleList()
            setLoading(false)
            setMessage({ show: true, message: t("Role_Assigned_Successfully"), severity: 'success' });
            setUser(prev => ({
                ...prev,
                poll_id: '',
                user_id: '',
                role: 'organizer',
                name: ''
            }))
            setResetUser(false);
            setTimeout(function () {
                setResetUser(true);
            }, 200);
        }).catch(error => {
            setMessage({ show: true, message: error.message, severity: 'error' });
            setLoading(false)
        })
    }

    const setSelectedUser = (selectedUesr) => {
        setUser(prevState => ({
            ...prevState,
            user_id: selectedUesr ? selectedUesr.id : '',
            name: selectedUesr ? selectedUesr.name : '',
            email: selectedUesr ? selectedUesr.email : '',
            role: 'organizer'
        }))
    }

    const handleRoleChange = (event) => {
        setUser(prevState => ({
            ...prevState,
            role: event.target.value
        }))
    }

    const assignNewRole = () => {
        setLoading(true)
        assignNewRoleMutation()
    }

    return (
        <div>
            <Card>
                <CardHeader title={t('Assign_Role')} />
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {resetUser && <SearchVoter onSelect={setSelectedUser} roleSearch={true}
                                // disable={!accessPollStatus.includes(props.pollStatus)} 
                                onlyCognitoUsers={true} />}
                        </Grid>
                        {user.name &&
                            <div style={{ marginBottom: '10px' }} id='name-div'>
                                <Typography id='name'><span style={{ fontWeight: 'bold', marginLeft: '10px' }}>{t("Names")}</span>&nbsp;{user.name}</Typography>
                                <Typography id='email'><span style={{ fontWeight: 'bold', marginLeft: '10px' }}>{t("Emails")}<span></span></span>&nbsp;{user.email}</Typography>
                            </div>
                        }
                        <Grid item xs={12}>
                            <FormControl component="fieldset">
                                <FormLabel id='role' component="legend">{t('Role')}</FormLabel>
                                <RadioGroup row aria-label="role" name="role" value={user.role} onChange={handleRoleChange}>
                                    <FormControlLabel id='organizer' value="organizer" control={<Radio color="primary" />}
                                        // disabled={!accessPollStatus.includes(props.pollStatus)}
                                        label={t("Organizer")} />
                                    {/* <Tooltip title={t('Under_Development')}> */}
                                    <FormControlLabel id='observer' value="observer" control={<Radio color="primary" />}
                                        // disabled={!accessPollStatus.includes(props.pollStatus)}
                                        // disabled={true}
                                        label={t("Observer")} />
                                    {/* </Tooltip> */}
                                    {/* <Tooltip title={t('Under_Development')}> */}
                                    <FormControlLabel id='scrutinizer' value="scrutinizer" control={<Radio color="primary" />}
                                        // disabled={!accessPollStatus.includes(props.pollStatus)}
                                        // disabled={true}
                                        label={t("Scrutinizer")} />
                                    {/* </Tooltip> */}
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        {/* {accessPollStatus.includes(props.pollStatus) && */}
                        <Grid item xs={12}>
                            <Stack justifyContent='end' alignItems='end'>
                                <Tooltip title={t("Select_An_User")} disableHoverListener={user.user_id != ''}>
                                    <span>
                                        <Button disabled={user.user_id == ''} variant='contained' color="primary" onClick={assignNewRole} id='button' size="small" >
                                            {t("Add")}
                                        </Button>
                                    </span>
                                </Tooltip>
                            </Stack>
                        </Grid>
                        {/* } */}
                    </Grid>
                </CardContent>
            </Card>
            {loading && <EligoBackdrop show={true} />}
            {message.show && <EligoSnackBar show={message.show} message={message.message} severity={message.severity} />}
            {/* {errorOnAssigningNewRole && <GraphQLErrors error={errorOnAssigningNewRole} show={false} />} */}
        </div>
    )
}

export default AssignRole;
