import {
    Button, Container, Grid, IconButton,
    Paper, Radio, RadioGroup, Tooltip, Typography,
    makeStyles
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Stack } from '@mui/material';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import EligoBackdrop from '../../common/EligoBackdrop';
import EligoConfirmDialog from '../../common/EligoConfirmDialog';
import EligoSnackBar from '../../common/EligoSnackBar';
import { Status } from '../../common/GenericCodes';
import { UserContext } from '../../common/context/UserContext';
import CaseHistoryModal from '../poll-question/cast-history/case-history-modal/CaseHistoryModal';
import RoundResult from '../poll-round/poll-round-result/round-result/RoundResult';
import ConfirmVoteOpt from './ConfirmVoteOpt';
import './ReviewVotes.css';

const useStyle = makeStyles((theme) => ({
    fullHeightCard: {
        height: "94%",
    },
    fullWidthCard: {
        width: '90%'
    },
    dialog: {
        width: '60% !important',
        marginLeft: '20% !important',
        marginTop: '4% !important',
        marginBottom: '2% !important'
    },
    width: {
        maxWidth: '1500px !important'
    },
    btn: {
        boxShadow: '5px 5px 4px rgba(0,0,0,0.3)',
        color: 'blue',
        background: 'white',
        border: '1.8px solid blue'
    },
    optionPaper: {
        padding: '10px',
        border: '1px solid #eae7e7',
        borderLeft: `4px solid ${theme.palette.primary.main}`,
        // boxShadow: 'rgb(32 40 45 / 8%) 0px 2px 14px 0px !important'
        boxShadow: '#a6adab17 0px 13px 27px -5px, #ced3d8 0px 8px 16px -8px !important'
    },
    cancel: {
        boxShadow: '5px 5px 4px rgba(0,0,0,0.3)',
        color: 'red',
        background: 'white',
        border: '1.5px solid red'
    },
    round: {
        border: '2px solid rgb(60, 161, 60)',
        borderRadius: '50%',
        padding: '2px 10px',
        width: 'fit-content',
        textAlign: 'center',
        fontWeight: '600',
        background: 'rgb(148, 238, 130)',
        color: 'rgb(60, 161, 60)',
        fontWeight: 'bold !important'
    }
}))
const ReviewVotes = (props) => {
    const { pollDetails, onlyVoter, page, roles, showVote, pollType, otpLogin, userId, voterEdgeIdForSingleVoter, AuthrepIdForSingleVoter, VoterIdForsingleVoter,
        authRepId, voterEdgeId, voterIdInList, authorisedPersonId, cognitoUserId, authRepView } = props
    let history = useHistory()
    const { t } = useTranslation();
    const classes = useStyle();
    const context = useContext(UserContext)
    const [questions, setQuestions] = useState([])
    const [showOtp, setShowOtp] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showResult, setShowResult] = useState(false)
    const [otp, setOpt] = useState(false)
    const [showVoteHistory, setShowVoteHistory] = useState({
        show: false,
        question: '',
        pollQuestionId: ''
    })
    const [seekConfirmation, setSeekConfitmation] = useState({
        show: false,
        title: '',
        message: '',
        onAgree: '',
        onDisAgree: ''
    })
    const [showStepper, setShowStepper] = useState(false);
    const [message, setMessage] = React.useState({
        showMsg: false,
        message: null,
        severity: null
    });




    const setAllQuestions = (quest) => {
        quest.map(e => {
            setQuestions(prevState => ([
                ...prevState,
                {
                    question: e.question,
                    poll_question_id: e.poll_question_id,
                    selected_response_id: e.selected_response_id,
                    PollQuestionResponses: getPollResopnseArray(e.PollQuestionResponses)
                }
            ]))
        })
    }
    const getPollResopnseArray = (responseArray) => {
        let response = [];
        responseArray.map(e => {
            response.push({
                option: e.option,
                poll_question_response_id: e.poll_question_response_id
            })
        })
        return response;
    }
    useEffect(() => {
        if (otpLogin) {
            setLoading(true);
            axios({
                url: `${process.env.REACT_APP_AWS_GATEWAY_POLL_QUESTION_API_URL}/questions/${pollDetails.poll_id}/${userId}`,
                method: 'GET'
            }).then((response) => {
                setLoading(false);
                setAllQuestions(response.data.pollQuestions)
            }).catch((error) => {
                //TODO
                setLoading(false);
            })
        } else {
            setLoading(true);
            axios({
                url: `${process.env.REACT_APP_AWS_GATEWAY_POLL_QUESTION_API_URL}/questions/${pollDetails.poll_id}/${onlyVoter ? cognitoUserId : voterIdInList ? voterIdInList : VoterIdForsingleVoter}`,
                method: 'GET'
            }).then((response) => {
                setLoading(false);
                setAllQuestions(response.data.pollQuestions)
            }).catch((error) => {
                //TODO
                setLoading(false);
            })
        }
    }, [])

    const getOpt = () => {
        setOpt(true)
        if (otpLogin) {
            setLoading(true);
            axios({
                url: `${process.env.REACT_APP_AWS_GATEWAY_POLL_API_URL}/request_to_verify_cast/${pollDetails.poll_id}/${authorisedPersonId === "None" ? pollDetails.voter_edge_id : pollDetails.auth_rep_edge_id}`,
                method: 'GET'
            }).then((response) => {
                setLoading(false);
                setShowOtp(true);
                resetConfirmation();
            }).catch((error) => {
                setLoading(false);
                resetConfirmation();
            })
        } else {
            setLoading(true);
            axios({
                url: `${process.env.REACT_APP_AWS_GATEWAY_POLL_API_URL}/request_to_verify_cast/${pollDetails.poll_id}/${onlyVoter ? pollDetails.voter_edge_id : authRepId ? authRepId : AuthrepIdForSingleVoter}`,
                method: 'GET'
            }).then((response) => {
                setLoading(false);
                setShowOtp(true);
                resetConfirmation();
            }).catch((error) => {
                setLoading(false);
                resetConfirmation();
            })
        }
    }

    const confirmVoteHandler = () => {
        setShowOtp(false);
        // backToPoll();
        successHandler();
    }

    const confirmVote = () => {
        setSeekConfitmation(prevState => ({
            ...prevState,
            show: true,
            title: t("Confirmation"),
            message: pollDetails.verify_with_otp === true ? 'Are you sure that you want to confirm vote by OTP?' : 'Are you sure that you want to confirm vote?',
            onDisAgree: resetConfirmation,
            onAgree: pollDetails.verify_with_otp === true ? getOpt : confirmWithoutOTP,
        }))
    }

    const resetConfirmation = () => {
        setSeekConfitmation(prevState => ({
            ...prevState,
            show: false,
            title: '',
            message: '',
            onDisAgree: '',
            onAgree: ''
        }))
    }


    const handleBack = () => {
        props.setReviewVotes(false)
    }

    const handleCancle = () => {
        if (pollDetails.status === Status.closed) {
            history.push({
                pathname: '/polls',
                state: {
                    pollType: pollType
                },
            })
        } else {
            props.voteVar(false)
            props.setShowVote(false)
            props.setReviewVotes(false)
        }

    }
    const backToPoll = () => {
        history.push({
            pathname: '/polls',
            state: {
                pollType: pollType,
                otpLogin: otpLogin,
                userId: userId,
                authRepId: authRepId
            },
        })
    }

    const successHandler = () => {
        history.push({
            pathname: '/polls/details/success-cast-vote',
            state: {
                pollType: pollType
            },
        })
    }

    const showQuestionHistory = (questionDetails) => {
        setShowVoteHistory({
            show: true,
            question: questionDetails.question,
            pollQuestionId: questionDetails.poll_question_id
        })
    }

    const resetShowQuestionHistory = () => {
        setShowVoteHistory({
            show: false,
            question: '',
            pollQuestionId: ''
        })
    }

    const confirmWithoutOTP = () => {
        let code = 'LPTEEV'
        setMessage({ showMsg: false, message: null, severity: null })
        if (otpLogin) {
            setLoading(true);
            axios({
                url: `${process.env.REACT_APP_AWS_GATEWAY_POLL_API_URL}/verify_cast/${pollDetails.poll_id}/${authorisedPersonId}/${code}`,
                method: 'GET'
            }).then((response) => {
                setLoading(false);
                confirmVoteHandler();
            }).catch((error) => {
                setLoading(false);
                if (error.response) {
                    if (error.response.data.Message) {
                        setMessage({ showMsg: true, message: error.response.data.Message, severity: 'error' })
                    }
                }
            })
        } else {
            axios({
                url: `${process.env.REACT_APP_AWS_GATEWAY_POLL_API_URL}/verify_cast/${pollDetails.poll_id}/${onlyVoter ? pollDetails.voter_edge_id : authRepId ? authRepId : AuthrepIdForSingleVoter}/${code}`,
                method: 'GET'
            }).then((response) => {
                setLoading(false);
                confirmVoteHandler();
            }).catch((error) => {
                setLoading(false);
                if (error.response) {
                    if (error.response.data.Message) {
                        setMessage({ showMsg: true, message: error.response.data.Message, severity: 'error' })
                    }
                }
            })
        }
    }

    return (
        <Container>
            <div style={{ maxWidth: '1280px', marginLeft: '5%' }}>
                {questions.length > 0 && pollDetails.status != Status.canceled &&
                    <Grid container style={{ paddingRight: '18px', marginTop: '10px' }}>
                        <Grid item xs="12">
                            {pollDetails.status != Status.closed && !showVote &&
                                <Tooltip title={pollDetails.verify_with_otp === true ? 'Click here and confirm your Vote with OTP. Only confirmed Votes are recorded' : 'Click here and confirm your Vote. Only confirmed Votes are recorded'}>
                                    <Button size="small" className={classes.btn} style={{ float: 'right', marginBottom: '15px', marginRight: '-15px' }} onClick={() => confirmVote()}>
                                        {t("Confirm Your Vote")}
                                    </Button>
                                </Tooltip>
                            }
                            {pollDetails.status != Status.closed && !showVote && <Button size="small" className={classes.cancel} style={{ float: 'right', marginRight: '1%' }} onClick={() => handleBack()}>
                                {t("Back To Questions")}
                            </Button>}
                            {/* <Stack alignItems='center' justifyContent='center'>
                                {(pollDetails.status === Status.closed || showVote || authRepView) && <Typography variant='h5'>
                                    {pollDetails.title}
                                </Typography>}
                                {(pollDetails.status === Status.closed || showVote || authRepView) && <Typography>
                                    {pollDetails.description}
                                </Typography>}
                            </Stack> */}


                            {(pollDetails.status === Status.closed || showVote || authRepView) && <Button size="small" className={classes.cancel} style={{ margin: '15px', marginLeft: '-3px' }} onClick={() => handleCancle()}>
                                {t("Home")}
                            </Button>}

                            {pollDetails.status === Status.closed && !showResult && pollDetails.result_announcement_time && pollDetails.result_announcement_time !== null && <Button size="small" className={classes.btn} onClick={() => setShowResult(true)}>{t("View_Result")}</Button>}
                            {(pollDetails.status === Status.closed && showResult) && <Button size="small" className={classes.btn} onClick={() => setShowResult(false)}>{t("My_Votes")}</Button>}
                        </Grid>
                    </Grid>}

                {!showResult && <Grid>
                    {questions.length > 0 && questions.map((e, index) => {
                        return (
                            <div key={index}>
                                <Paper elevation={8} className={classes.optionPaper}>
                                    <Tooltip title="Vote History">
                                        <IconButton onClick={() => showQuestionHistory(e)} style={{ float: 'right' }}>
                                            <VisibilityIcon color='primary' />
                                        </IconButton>
                                    </Tooltip>
                                    <Stack direction='row' spacing={2} alignItems='center' style={{ marginBottom: '10px' }}>
                                        <Typography id='box' component='div' className={classes.round}>
                                            {index + 1}
                                        </Typography>
                                        <Typography id='question' style={{ fontWeight: 'bold', fontSize: '20px' }}>
                                            {e.question}
                                        </Typography>
                                    </Stack>
                                    <RadioGroup aria-label="selected-vote" name="vote" value={e.selected_response_id} style={{ paddingLeft: '34px' }} className="vote-option">
                                        {e.PollQuestionResponses.map((opt, i) => {
                                            return <Stack direction='row' key={i} alignItems='center' spacing={2}>
                                                <Radio key={i} className='vote' value={opt.poll_question_response_id} disabled color="primary" />
                                                <Typography component='span'>{opt.option}</Typography>

                                            </Stack>
                                        })}
                                    </RadioGroup>
                                </Paper>
                                <br />
                            </div>
                        )
                    })}
                </Grid>}
            </div>

            {showResult && <Grid item xs={12}>
                <RoundResult pollRoundIds={pollDetails.poll_id}
                    resultWithVoters={pollDetails.disclose_voter_in_result}
                    otpLogin={otpLogin}
                    userId={userId} />
            </Grid>
            }

            {loading && <EligoBackdrop show={true} invisible={false} />}
            {seekConfirmation.show && <EligoConfirmDialog show={seekConfirmation.show}
                title={seekConfirmation.title} message={seekConfirmation.message}
                onAgree={seekConfirmation.onAgree} onDisAgree={seekConfirmation.onDisAgree} />}
            {showOtp && <ConfirmVoteOpt title={t("Vote_Confirmation")} pollDetails={pollDetails} authorisedPersonId={authorisedPersonId} show={showOtp} otpStepper={setOpt} authRepId={authRepId} AuthrepIdForSingleVoter={AuthrepIdForSingleVoter} voterIdInList={voterIdInList} onlyVoter={onlyVoter}
                voter_edge_id={pollDetails.voter_edge_id} setShowStepper={setShowStepper}
                onDisAgree={() => { setShowOtp(false) }} onAgree={confirmVoteHandler}
                otpLogin={otpLogin} userId={userId} />}
            {showVoteHistory.show && <CaseHistoryModal show={showVoteHistory.show} reset={resetShowQuestionHistory}
                question={showVoteHistory.question} pollQuestionId={showVoteHistory.pollQuestionId}
                otpLogin={otpLogin}
                voterId={otpLogin ? userId : onlyVoter ? cognitoUserId : voterIdInList ? voterIdInList : VoterIdForsingleVoter}></CaseHistoryModal>}
            {message.showMsg && <EligoSnackBar show={message.showMsg} message={message.message}
                severity={message.severity} ></EligoSnackBar>}
        </Container>
    )
}
export default ReviewVotes
