import { Card, Chip, styled } from "@material-ui/core";

export const MiniCard = styled(Card)(({ theme }) => ({
    padding: '1rem', boxShadow: 'rgb(0 0 0 / 5%) 0px 2px 24px',
    border: '1px solid #eeeeee', borderRadius: '12px',
    textAlign: 'center'
}))

export const MiniChipGreen = styled(Chip)(({ theme }) => ({
    background: '#d6fae6',
    color: '#014703',
    marginTop: '8px',
    fontSize: 'medium'
}))