import React from 'react'
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';

const DateAndTime = (props) => {
  const handleChange = (event) => {
    props.handleChange(event)
  }
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDateTimePicker
            fullWidth
            variant="inline"
            label={props.label}
            value={props.value}
            onChange={(event) => handleChange(event)}
            disablePast
            format={process.env.REACT_APP_DATE_FORMAT_TO_DATE_SELECTOR}
            required={props.required}
            disabled={props.disable}
            minDate={props.mindate}
            maxDate={props.maxdate ? props.maxdate : new Date('2100-01-01')}
            helperText={props.errMessage}
            error={props.err}
            onBlur={props.onBlur}
          />
      </MuiPickersUtilsProvider>
  );
}

export default DateAndTime
