import axios from 'axios';
import { InputAdornment, makeStyles, TextField, Tooltip } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EligoSnackBar from '../../../common/EligoSnackBar';
import { AxiosEvotingInterceptor } from '../../../config/axios.interceptor';

const useStyles = makeStyles({
    root: {
        "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'][class*='MuiOutlinedInput-marginDense'] .MuiAutocomplete-input": {
            position: 'relative',
            right: '14px',
            bottom: '2px',
        },
        '& .MuiAutocomplete-endAdornment': {
            display: 'none'
        },
        '& .MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"], .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
            paddingRight: '0px !important'
        },
    },
    searchFiled: {
        "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
            borderRadius: 18,
            height: 34,
            background: '#fff',
            paddingRight: "0px !important",
            boxShadow: '#a6adab17 0px 13px 27px -5px, #ced3d8 0px 8px 16px -8px '
        }
    },

})

const SearchVoterList = (props) => {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const [searchFilter, setSearchFilter] = React.useState('');
    const [options, setOptions] = React.useState([]);
    const classes = useStyles();
    const [selectedUser, setSelectedUser] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [message, setMessage] = React.useState({
        showMsg: false,
        message: null,
        severity: null
    })
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemPerPage] = useState(10);
    const [touched, setTouched] = useState(false);

    const getSearchedVoter = (searchFilter) => {
        setMessage({ showMsg: false, message: null, severity: '' });
        setLoading(true);
        AxiosEvotingInterceptor.get(`voter/search/${props.pollId}/${props.listBy}?page=${page + 1}&limit=${itemsPerPage}&asc=false&searchInput=${searchFilter}`).then(response => {
            console.log("response search", response)
            setOptions(Object.keys(response.voter).map((key) => {
                return {
                    name: response.voter[key].name,
                    phoneNumber: response.voter[key].phoneNumber,
                    email: response.voter[key].email,
                    weightage: response.voter[key].weightageString,
                    cognito_user: response.voter[key].cognito_user,
                    otpVerifiedOn: response.voter[key].otpVerifiedOn,
                    authorizedRepresentative: response.voter[key].authorizedRepresentative,
                    voterId: response.voter[key].voterId,
                    reminderCount: response.voter[key].reminderCount

                }
            }));
            setLoading(false);
        }).catch((error) => {
            console.log("inside response error", error.response)
            setLoading(false)
            setMessage({
                show: true,
                message: error.response,
                severity: 'error'
            })
        })
    }



    useEffect(() => {
        if (searchFilter == '') {
            props.onSelect(searchFilter, touched);
        }
        if (searchFilter != '' && open) {
            (async () => {
                getSearchedVoter(searchFilter, touched);
            })();
        }
    }, [searchFilter]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    useEffect(() => {
        if (selectedUser != '' && selectedUser != null) {
            setSearchFilter(selectedUser.name);
            props.onSelect(selectedUser, touched);
        }
    }, [selectedUser]);

    const onChangeFilter = (e) => {
        setSearchFilter(e.target.value)
        setTouched(true)
    }

    return (
        <Stack sx={{ ml: '1.5rem !important' }} style={{width:'290%'}}>
            <Autocomplete
                id="search-voter-list"
                className={classes.searchFiled}
                open={open}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                disableClearable={true}
                onChange={(event, data) => {
                    setSelectedUser(data);
                }}
                getOptionLabel={(option) => option.name + ' (' + option.email + ') '}
                options={options}
                loading={loading}
                inputValue={searchFilter}
                disabled={props.disable ? props.disable : false}

                renderInput={(params) => (
                    <Tooltip title={"search is case-sensitive"}>
                        <TextField className={`${classes.root}`} {...params}
                            onChange={onChangeFilter}
                            onBlur={(e) => props.setName ? props.setName(e.target.value) : {}}
                            variant='outlined'
                            size='small'
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        {/* <IconButton> */}
                                        <SearchIcon style={{ marginBottom: '4px', marginRight: '6px' }} />
                                        {/* </IconButton> */}
                                    </InputAdornment>
                                ),
                                // endAdornment: (
                                //     <React.Fragment>
                                //         {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                //         {params.InputProps.endAdornment}
                                //     </React.Fragment>
                                // ),
                            }}
                            placeholder={t('Search_Voter')}
                        />
                    </Tooltip>
                )}
            />
            {/* <span style={{fontSize: "11px", marginLeft: "31px", color: "tan"}}>{'Note: Name is case-sensitive'}</span>  */}
            {message.showMsg && <EligoSnackBar show={message.showMsg} message={message.message}
                severity={message.severity} />}

        </Stack>
    )
}

export default SearchVoterList;
