import { styled } from "@material-ui/styles";
import { List, ListItem, ListItemIcon, ListItemText, SvgIcon } from "@mui/material";

const imgUrl = process.env.PUBLIC_URL + '/images/sidebar5.svg'
export const SideNavBar = styled(List)(({ theme, topBarHeight, ismenucollapse }) => ({
    position: 'fixed !important',
    width: ismenucollapse ? '82px' : '230px',
    height: 'calc(100vh - 64px)',
    padding: '0px !important',
    transition: 'width 0.3s ease',
    zIndex: 100,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${imgUrl})`,
    backgroundColor: '#fff',
    boxShadow: '0px 5px 5px -3px rgba(0, 0, 0, 0.06),0px 8px 10px 1px rgba(0, 0, 0, 0.042),0px 3px 14px 2px rgba(0, 0, 0, 0.036)',
}))

export const SideNavHeader = styled('header')(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '10px'
}))

export const MenuSvgIcon = styled(SvgIcon)(({ theme, menucollapse }) => ({
    position: 'absolute',
    top: '2.5%',
    right: '-8px',
    transform: menucollapse ? 'translateY(-50%) rotate(0deg)' : 'translateY(-50%) rotate(180deg)',
    height: '20px',
    width: '20px',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '22px',
    cursor: 'pointer',
    transition: 'all 0.3s ease'
}))

export const MenuBar = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
    gap: '0.4rem',
    marginTop: '15px'
    // '&::-webkit-scrollbar': {
    //     display: 'none'
    // }
}))

export const MenuListItem = styled(ListItem)(({ theme, isselected }) => ({
    height: '100%',
    // display: 'flex',
    // alignItems: 'center',
    // width: '100%',
    // textDecoration: 'none',
    transition: 'all 0.6s ease',
    borderRadius: '5px !important',
    '&:hover': {
        backgroundColor: `${theme.palette.primary.main} !important`,
        '& .icon': {
            color: 'rgb(249 241 241) !important'
        },
        '& .text': {
            color: 'rgb(249 241 241) !important'
        }
    },
    '& .icon': {
        color: isselected ? 'rgb(249 241 241) !important' : 'inherit'
    },
    '& .text': {
        color: isselected ? 'rgb(249 241 241) !important' : 'inherit'
    },
    backgroundColor: isselected ? `${theme.palette.primary.main} !important` : 'transparent',
})
)

export const MenuListIcon = styled(ListItemIcon)(() => ({
    color: '#707070 !important',
    minWidth: '15px !important',
    margin: '4px 0px !important',
}))

export const MenuListText = styled(ListItemText)(() => ({
    fontWeight: 'bold !important',
    color: '#707070 !important',
    margin: '4px 0px !important',
}))

export const PollListContainer = styled('div')(({ iscollapse }) => ({
    position: 'absolute',
    left: iscollapse ? '82px' : '230px',
    width: iscollapse ? 'calc(100% - 82px)' : 'calc(100% - 230px )',
    transition: 'all 0.3s ease',
}))