import React from 'react';
import { Card,
  CardContent,
  Typography,
  Container,
  makeStyles,
  Button, Grid } from '@material-ui/core';
import HowToVoteIcon from '@material-ui/icons/HowToVote';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { TypeOfPollCategory } from '../../common/GenericCodes'

const useStyles = makeStyles((theme) => ({
    margin: {
      marginTop: theme.spacing(2),
    },
    title: {
        color: 'green'
    },
    okButton: {
        width: '10vw'
    }
}))

const SuccessfullCastVote = ({withOTP, pollCategory}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();

    const onConfirm = () => {
        history.push({
            pathname: '/polls',
            // state: {
            //     pollType: props.location.state.pollType
            // }
        });
    }

    return (
      <Grid container className={classes.margin}>
          <Grid item xs={12}>
              <Container maxWidth="md">
                  <Card>
                      <CardContent>
                          <Typography gutterBottom variant="h5" component="h2" className={classes.title} align="center">
                              {t("Voting_Successful")}
                          </Typography>
                          <Typography gutterBottom variant="h5" component="h2" align="center">
                              <HowToVoteIcon style={{ fontSize: 60, color: 'green' }}/>
                          </Typography>
                          <Typography variant="body2" color="textSecondary" component="p" align="center">
                              {withOTP ? t("Your_Vote_Was_Successful_Message") : t("Your_Vote_Was_Successful_Without_OTP_Message")} {pollCategory === TypeOfPollCategory.IBC ? t('Resolutions_Items') : 'Questions'}
                          </Typography>
                          {/* <Typography variant="body2" color="textSecondary" component="p" align="center">
                              {t("You_Will_Receive_Mail_As_Acknowledgement_of_Successful_Voting")}
                          </Typography> */}
                          <br/><br/>
                          <Typography align="center">
                              <Button variant="contained" color="primary" style={{backgroundColor: 'green', color: 'white'}}
                                      onClick={onConfirm} className={classes.okButton}>
                                  OK
                              </Button>
                          </Typography>
                      </CardContent>
                  </Card>
              </Container>
          </Grid>
      </Grid>
    )
}

export default SuccessfullCastVote;