import { FormControlLabel } from "@material-ui/core";
import { Grid, Typography } from "@mui/material";
import { Fragment, memo } from "react";
import { useTranslation } from "react-i18next";
import IOSSwitch from "../../../../common/Switch";
import { TypeOfPoll } from "../../../../common/GenericCodes";

const Setting = (props) => {
    const { question, setQuestion, hasPermission, title,hasSystemAdmineOrSupportRole, pollClass } = props;
    const {t} = useTranslation();

    const handleSkip = () => {
        setQuestion(prevState => ({
            ...prevState,
            allowQuestionSkip: !question.allowQuestionSkip
        }))
    }

    const handleAllShuffle = () => {
        setQuestion(prevState => ({
            ...prevState,
            shuffleAllOptions: !question.shuffleAllOptions,
            shuffleWithoutLastOption: false,
        }))
    }

    const handleLastShuffle = () => {
        setQuestion(prevState => ({
            ...prevState,
            shuffleWithoutLastOption: !question.shuffleWithoutLastOption,
            shuffleAllOptions: false
        }))
    }

    return (
        <Fragment>
            <Typography color="primary" sx={{ fontWeight: 'bold' }}>Settings</Typography>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={6}>
                    <Grid container direction="row" alignItems="center">
                        <Grid item xs={10}>
                            <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
                                {pollClass === TypeOfPoll.POLL ? t('Allow_Question_Skip') : t('Allow_Survey_Item_Skip')}</Typography>
                            <Typography variant="caption" display="block" style={{ color: 'gray' }}>
                                {/* {t('Allow_Question_Skip_Helper_Text')} */}
                                {`Allow voter to skip the ${title}`}
                                </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel checked={question.allowQuestionSkip} onChange={handleSkip} disabled={(hasSystemAdmineOrSupportRole ? !hasSystemAdmineOrSupportRole : !hasPermission)}
                                control={<IOSSwitch sx={{ m: 1 }} id="allowQuestionSkip" />}
                                style={{ float: 'right' }} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container direction="row" alignItems="center">
                        <Grid item xs={10}>
                            <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>{t('Shuffle_All_Options')}</Typography>
                            <Typography variant="caption" display="block" style={{ color: 'gray' }}>
                                {/* {t('Shuffle_All_Options_Helper_Text')} */}
                                {`This feature changes the order of the options/shuffles the options of the ${title} on a random basis for every respondent`}
                                </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel checked={question.shuffleAllOptions} onChange={handleAllShuffle} disabled={(hasSystemAdmineOrSupportRole ? !hasSystemAdmineOrSupportRole : !hasPermission)}
                                control={<IOSSwitch sx={{ m: 1 }} id="shuffleAllOptions" />}
                                style={{ float: 'right' }} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container direction="row" alignItems="center">
                        <Grid item xs={10}>
                            <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>{t('Shuffle_Without_Last_Option')}</Typography>
                            <Typography variant="caption" display="block" style={{ color: 'gray' }}>
                                {/* {t('Shuffle_Without_Last_Option_Helper_Text')} */}
                                {`This feature changes the order of the options/shuffles the options of the ${title} on a random basis for every respondent except last option`}
                                </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel checked={question.shuffleWithoutLastOption} onChange={handleLastShuffle} disabled={(hasSystemAdmineOrSupportRole ? !hasSystemAdmineOrSupportRole : !hasPermission)}
                                control={<IOSSwitch sx={{ m: 1 }} id="shuffleWithoutLastOption" />}
                                style={{ float: 'right' }} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default memo(Setting);