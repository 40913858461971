import { Button, Card, CardActions, CardContent, Fab, Link } from '@material-ui/core';
import { Assessment, AssessmentRounded, Close, Delete, DeleteOutlineRounded, Description, Edit, InsertDriveFile, RemoveRedEyeRounded } from '@material-ui/icons';
import { Dialog, DialogContent, DialogTitle, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IoDocument } from 'react-icons/io5';
import { AxiosEvotingInterceptor } from '../../../../config/axios.interceptor';
import { Fragment, useRef, useState } from 'react';
import EligoConfirmDialog from '../../../../common/EligoConfirmDialog';
import { CustomCard } from '../../../poll-round/poll-round-item/PollRoundItem';
import { PopoverOptions } from '../../poll-question-components/PollQuestionComponents';
import FileUploadDownload from '../../../file-upload-download/FileUploadDownload';
import RealTimeDialog from './RealTimeDialog';
import { Status } from '../../../../common/GenericCodes';

const PollQuestionRow = (props) => {
    const { question, questionNumber, setLoading, setMessage, handleRefresh, pollId,
        handleQuestionEdit, listTitle, hasPermission, questionTitle, filePermission, pollQuestionCount,
        itemsPerPage, page, setPage, totalVoterCount, noOfVotedNotVoted, status, realTimeResult, hasSystemAdmineOrSupportRole } = props;
    const {t} = useTranslation();
    const [openUpload, setOpenUpload] = useState(false);
    const [openResult, setOpenResult] = useState(false);
    const [seekConfirmation, setSeekConfirmation] = useState({
        show: false,
        title: t("Confirmation"),
        message: '',
        onAgree: '',
        onDisAgree: ''
    })

    const handleDelete = () => {
        setSeekConfirmation(prevState => ({
            ...prevState,
            show: true,
            message: <Fragment>{t('Are_You_Sure_That_You_Want_To')} <b>{t('Delete')}</b> {questionTitle} <b>{question.question}</b> {t('?')}</Fragment>,
            onAgree: deleteQuestion,
            onDisAgree: resetConfirmation,
        }))
    }

    const resetConfirmation = () => {
        setSeekConfirmation(prevState => ({
            ...prevState,
            show: false,
            title: t("Confirmation"),
            message: '',
            onDisAgree: '',
            onAgree: ''
        }))
    }

    const deleteQuestion = () => {
        setLoading(true);
        resetConfirmation();
        AxiosEvotingInterceptor.delete(`question/${pollId}/${question.id}`).then(response => {
            if (response) {
                setMessage({ showMsg: false, message: '', severity: '' })
                setMessage({
                    showMsg: true,
                    message: `Deleted ${questionTitle} ${questionNumber} successfully`,
                    severity: "success"
                })
                if (page !== 1) {
                    let lastPageRows = pollQuestionCount % itemsPerPage;
                    let noOfPage = ~~(pollQuestionCount / itemsPerPage) + (lastPageRows > 0 ? 1 : 0);
                    if (lastPageRows == 1 && page == noOfPage) {
                        setPage(1);
                        // handleRefresh();
                    } else {
                        handleRefresh();
                    }
                } else {
                    handleRefresh();
                }
                // setLoading(false);
            }
        }).catch((error) => {
            setLoading(false)
            setMessage({
                showMsg: true,
                message: error.message,
                severity: "error"
            })
        })
    }

    return (
        <CustomCard>
            <CardContent>
                <Stack direction='row' spacing={1} justifyContent='space-between' alignItems='center'>
                    <Stack direction='row' spacing={2} width='60%'>
                        <Typography>
                            {/* {listTitle} */}
                        {questionNumber}.</Typography>
                        <Typography>{question.question}</Typography>
                    </Stack>
                    <div style={{ float: 'right' }}>
                        {/* <div
                            onMouseOver={() => setOpenOpt(true)}
                            onMouseOut={() => setOpenOpt(false)}>
                            <Typography color='primary' variant='subtitle2' style={{ textTransform: 'capitalize' }}>
                                View options
                            </Typography>
                            {openOpt && <CustomCard>
                                {question.options[0].option}
                            </CustomCard>}
                        </div> */}
                        <PopoverOptions
                            title={'View Options'}
                            options={question.options.sort((a, b) => a.displaySequence - b.displaySequence)}
                        />
                        {(Status.open === status && realTimeResult) && <Tooltip title='Real-Time Result' arrow>
                            <IconButton onClick={() => setOpenResult(true)}>
                                <AssessmentRounded color='secondary' />
                            </IconButton>
                        </Tooltip>}
                        <Tooltip title={(hasPermission || hasSystemAdmineOrSupportRole) ? 'Edit' : 'View'} arrow>
                            <IconButton onClick={() => { handleQuestionEdit(question, questionNumber, question.id) }}>
                                {(hasPermission || hasSystemAdmineOrSupportRole) ? <Edit color='primary' /> : <RemoveRedEyeRounded color='primary' />}
                            </IconButton>
                        </Tooltip>
                        <Tooltip title='Files' arrow>
                            <IconButton onClick={() => setOpenUpload(true)}>
                                <Description color='primary' />
                            </IconButton>
                        </Tooltip>
                        {(hasPermission || hasSystemAdmineOrSupportRole) &&
                            <Tooltip title='Delete' arrow>
                                <IconButton onClick={handleDelete}>
                                    <Delete color='error' />
                                </IconButton>
                        </Tooltip>}
                    </div>
                </Stack>
                {
                    openUpload && <Dialog open={openUpload} maxWidth='lg'>
                        <DialogTitle>
                            {`${questionTitle}: ${questionNumber}`} {t('Files')}
                            <Tooltip title='Close' arrow>
                                <Close onClick={() => setOpenUpload(false)} size='large' color='error' style={{ float: 'right', cursor: 'pointer' }} />
                            </Tooltip>
                        </DialogTitle>
                        <DialogContent dividers>
                            <FileUploadDownload
                                t={t}
                                questionTitle={questionTitle}
                                title={`Upload ${t(questionTitle)}: ${questionNumber} ${t('Files') }`}
                                poll_id={pollId}
                                poll_question_id={question.id}
                                isUpload={filePermission}
                                isDownload={true}
                                isMultiple={true}
                                deletePermission={filePermission}
                            />
                        </DialogContent>
                    </Dialog>
                }
                {openResult && <RealTimeDialog open={openResult } setOpen={setOpenResult}
                    pollId={pollId} questionId={question.id} questionTitle={listTitle}
                    questionNumber={questionNumber} totalVoterCount={totalVoterCount} noOfVotedNotVoted={noOfVotedNotVoted} />}
                {seekConfirmation.show && <EligoConfirmDialog show={seekConfirmation.show}
                    title={seekConfirmation.title} message={seekConfirmation.message}
                    onAgree={seekConfirmation.onAgree} onDisAgree={seekConfirmation.onDisAgree} />}
            </CardContent>
        </CustomCard>
    )
}

export default PollQuestionRow;