import React, { useState } from 'react';
import {
    Container, Card, CardContent, Typography, TextField,
    makeStyles, CardActions, Grid, Button, CircularProgress, Tooltip,
    InputAdornment, Link
} from '@material-ui/core';
import InputMask from 'react-input-mask';
import { Auth } from 'aws-amplify';
import EligoSnackBar from '../common/EligoSnackBar'
import ConfirmationDialog from '../common/confirmationDialog/ConfirmationDialog';
import { useTranslation } from 'react-i18next';
import LoopIcon from '@material-ui/icons/Loop';
import { useHistory } from 'react-router-dom';
import EligoBackdrop from '../common/EligoBackdrop';
import { Loop, TramRounded } from '@material-ui/icons';
import EligoReCaptcha from '../common/EligoReCaptcha';
import { AuthAxiosInterceptor } from '../config/axios.interceptor';
import { JustifyBox, JustifyBetweenBox, ContentBox, RootDiv, IMG } from './AuthStyle';
import { Box, IconButton } from '@mui/material';

const ConfirmOtpVerification = (props) => {
    const history = useHistory();
    const otpPattern = /^([0-9\s]{11})$/;
    const [otpVal, setOtpVal] = useState('');
    const [phoneOtpVal, setPhoneOtpVal] = useState('');
    const { t } = useTranslation();
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [loading, setLoading] = useState(false)
    const [captcha, setCaptcha] = useState('');
    const [isRefresh, setIsRefresh] = useState(false);
    const setOtpValue = (e) => {
        setOtpVal(e.target.value)
    }

    const setPhoneOtpValue = (e) => {
        setPhoneOtpVal(e.target.value)
    }

    const validateOtp = async event => {
        let code = otpVal.replaceAll(' ', '')
        let phoneCode = phoneOtpVal.replaceAll(' ', '')
        event.preventDefault();
        if (props.location.state.emailid && props.location.state.phoneNumber) {
            setLoading(true);
            let payload = [];
            if (code != null && code != '') {
                payload.push(
                    {
                        contact: props.location.state.emailid,
                        otp: code
                    }
                )
            }
            if (phoneCode != null && phoneCode != '') {
                payload.push(
                    {
                        contact: props.location.state.phoneNumber,
                        otp: phoneCode
                    }
                )
            }
            setErrorMessage('');
            AuthAxiosInterceptor.post(`user/multiple-verify?recaptcha=${captcha}`, payload).then(response => {
                if (response === true) {
                    setErrorMessage('');
                    setShowConfirmation(true);
                }
                setLoading(false)
            }).catch(error => {
                setIsRefresh(true);
                setTimeout(() => {
                    setIsRefresh(false);
                }, 1000);
                if (error.message) {
                    setErrorMessage(error.message);
                } else {
                    setErrorMessage(t('Try_Again'));
                }
                setLoading(false)
            })
        }
        else {
            setLoading(true);
            let payload = {};
            if (props.location.state.emailid && code != null && code != '') {
                payload = {
                    contact: props.location.state.emailid,
                    otp: code
                }
            } else if (props.location.state.phoneNumber && phoneCode != null && phoneCode != '') {
                payload = {
                    contact: props.location.state.phoneNumber,
                    otp: phoneCode
                }
            }
            setErrorMessage('');
            AuthAxiosInterceptor.post(`user/verify?recaptcha=${captcha}`, payload).then(response => {
                if (response === true) {
                    setErrorMessage('');
                    setShowConfirmation(true);
                }
                setLoading(false)
            }).catch(error => {
                setIsRefresh(true);
                setTimeout(() => {
                    setIsRefresh(false);
                }, 1000);
                if (error.message) {
                    setErrorMessage(error.message);
                } else {
                    setErrorMessage(t('Try_Again'));
                }
                setLoading(false)
            })
        }
    }

    const resendOtp = async (contact) => {
        setLoading(true)
        setErrorMessage('');
        setSuccessMessage('')
        AuthAxiosInterceptor.patch(`user/request-otp?recaptcha=${captcha}&purpose=verify-signup`, {
            loginId: contact
        }).then(response => {
            setErrorMessage('');
            setSuccessMessage(t('OTP_Sent_Successfully'))
            setLoading(false);
            setIsRefresh(true);
            setTimeout(() => {
                setIsRefresh(false);
            }, 1000);
        }).catch((error) => {
            setLoading(false);
            setIsRefresh(true);
            setTimeout(() => {
                setIsRefresh(false);
            }, 1000);
            if (error.message) {
                setErrorMessage(error.message);
            }
        })
    }

    const login = () => {
        history.push("/sign-in")
    }

    return (
        <RootDiv>
            <Card >
                <Grid container>
                    {/* <Grid item lg={5} md={5} sm={5} xs={12}>
                        <JustifyBox p={4} height="100%">
                            <IMG
                                src={process.env.PUBLIC_URL + '/verifyotp.svg'}
                                alt="VerifyOTP"
                            />
                        </JustifyBox>
                    </Grid> */}
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box style={{ padding: '40px 32px' }}>
                        {/* <ContentBox> */}
                            <Typography component="h1" variant="h5" gutterBottom className="text-center">
                                {t('Verify_Your_Account')}
                            </Typography>
                            <br></br>
                            <Typography variant="body2" component="p" className="text-center">
                                <strong>{t('Please_enter_the_OTP_to_verify')}</strong><br />
                                {(props.location.state.emailid && props.location.state.phoneNumber) ?
                                    t('A_OTP_Has_Been_Sent_To_Your_Registered_Mobile_Number_And_Email_ID') :
                                    (props.location.state.emailid ? t('A_OTP_Has_Been_Sent_To_Your_Registered_Email_ID') : t('A_OTP_Has_Been_Sent_To_Your_Registered_Mobile_Number'))}
                            </Typography>
                            <br></br>
                            {props.location.state.emailid && <Typography className="text-center">
                                <InputMask
                                    mask="9 9 9 9 9 9"
                                    value={otpVal}
                                    onChange={setOtpValue}>
                                    {() => <TextField
                                        label={props.location.state.phoneNumber ? t('Email_OTP_Required') : t('OTP')}
                                        id='email-otp'
                                        margin="normal"
                                        variant="outlined"
                                        placeholder="OTP"
                                        autoComplete="new-password"
                                        inputProps={{ style: { textAlign: 'center', fontSize: '18px', fontWeight: 'bold' } }}
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <Tooltip title="Resend">
                                                    <InputAdornment position="end" className="pointer" style={{ minHeight: '1.8rem' }}>
                                                        <IconButton aria-label="resend-otp"
                                                            onClick={() => resendOtp(props.location.state.emailid)}>
                                                            <Loop style={{ color: 'blue' }} />
                                                        </IconButton>
                                                    </InputAdornment>
                                                </Tooltip>
                                            )
                                        }}
                                    />}
                                </InputMask>
                            </Typography>}
                            <br></br>
                            {props.location.state.phoneNumber && <Typography className="text-center">
                                <InputMask
                                    mask="9 9 9 9 9 9"
                                    value={phoneOtpVal}
                                    onChange={setPhoneOtpValue}>
                                    {() => <TextField
                                        label={props.location.state.emailid ? t('Phone_OTP_Optional') : t('OTP')}
                                        id='phone-otp'
                                        margin="normal"
                                        variant="outlined"
                                        placeholder="Phone OTP"
                                        autoComplete="new-password"
                                        inputProps={{ style: { textAlign: 'center', fontSize: '18px', fontWeight: 'bold' } }}
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <Tooltip title="Resend">
                                                    <InputAdornment position="end" className="pointer" style={{ minHeight: '1.8rem' }}>
                                                        <IconButton aria-label="resend-otp"
                                                            onClick={() => resendOtp(props.location.state.phoneNumber)}>
                                                            <Loop style={{ color: 'blue' }} />
                                                        </IconButton>
                                                    </InputAdornment>
                                                </Tooltip>
                                            )
                                        }}
                                    />}
                                </InputMask>
                            </Typography>}
                            <br /><br />
                            <Grid item xs={12} className="text-center">
                                <JustifyBetweenBox>
                                    {/* <Link onClick={login} style={{float:'left', fontSize:'12px',  cursor:'pointer'}}>{t('Verify_Later')}</Link> */}
                                    {props.location.state.isVerifyLater && <Button size="small" variant="outlined" color="primary" onClick={login}>
                                        {t('Verify_Later')}
                                    </Button>}
                                    {!props.location.state.isVerifyLater && <Link onClick={login} style={{ float: 'left', fontSize: '12px', cursor: 'pointer' }}>{t('Back_to_Sign_In')}</Link>}
                                    <Button size="small" variant="contained" color="primary" onClick={validateOtp}
                                        disabled={loading
                                            || ((props.location.state.emailid ) ? (otpVal === '' || otpVal === '_ _ _ _ _ _' || !(otpPattern.test(otpVal))) : false)
                                            || (props.location.state.emailid && props.location.state.phoneNumber ? ((props.location.state.phoneNumber) ? ((phoneOtpVal === '' || phoneOtpVal === '_ _ _ _ _ _') ? false : !(otpPattern.test(phoneOtpVal))) : false) :
                                            (props.location.state.phoneNumber ) ? ((phoneOtpVal === '' || phoneOtpVal === '_ _ _ _ _ _') ? true :  !(otpPattern.test(phoneOtpVal))) : false)
                                            }
                                    >
                                        {t('Verify')}
                                        {/* {loading && <CircularProgress style={{ position: 'absolute' }} size={14} thickness={4.5} />} */}
                                    </Button>
                                </JustifyBetweenBox>
                            </Grid>
                            </Box>
                        {/* </ContentBox> */}
                    </Grid>
                </Grid>
            </Card>
            {errorMessage !== '' && errorMessage !== undefined ?
                <EligoSnackBar show={true} message={errorMessage} severity="error" reset={() => setErrorMessage(null)}></EligoSnackBar> : null}
            {successMessage !== '' && successMessage !== undefined ?
                <EligoSnackBar show={true} message={successMessage} severity="success" reset={() => setSuccessMessage(null)}></EligoSnackBar> : null}
            {showConfirmation ? <ConfirmationDialog title={t('Verification_successful')} show={showConfirmation}
                description={[t('Your_'), <strong>{t('App_Name')}</strong>, t('_account_is_verified_successfully')]} redirect={true} redirectUrl="/sign-in" /> : null}
            {!isRefresh && <EligoReCaptcha captcha={setCaptcha} refresh={setIsRefresh} />}
            {loading && <EligoBackdrop show={true} invisible={false} />}
        </RootDiv>
    )
}

export default ConfirmOtpVerification;