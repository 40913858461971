import React,{useState} from 'react';
import {Card, Portal, Typography} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

function Password(props) {
   
  return (
    <div> {props.open ?
        <Card id='passwordCard' style={{position:"absolute",left: '40%',padding:'20px',zIndex: 4}} >
            Password must contain:<br></br><br></br>
            <div id='password' style={{textAlign:'left'}}>
              <div id='pwdlength' style={props.pwdLength ? {color:'green'} :{color:'red'}}>{props.pwdLength ? <CheckCircleIcon style={{fontSize:'small'}}/>:<CancelIcon id='isuppericon'style={{fontSize:'small'}}/>}  atleast  8 characters </div>
              <div id='isupper' style={props.isUpper ? {color:'green'} :{color:'red'}}>{props.isUpper ? <CheckCircleIcon style={{fontSize:'small'}}/>:<CancelIcon id='pwdlengtgicon'style={{fontSize:'small'}}/>}  atleast  one Uppercase letter </div>
              <div id='islower' style={props.isLower ? {color:'green'} :{color:'red'}}>{props.isLower ? <CheckCircleIcon style={{fontSize:'small'}}/>:<CancelIcon id='islowericon'style={{fontSize:'small'}}/>}  atleast  one Lowercase letter </div>
              <div id='idnumber'style={props.isNumber ? {color:'green'} :{color:'red'}}>{props.isNumber ? <CheckCircleIcon style={{fontSize:'small'}}/>:<CancelIcon id='isnumbericon'style={{fontSize:'small'}}/>}  atleast  one number  </div>
              <div id='isSpecial'style={props.isSpecial ? {color:'green'} :{color:'red'}}>{props.isSpecial ? <CheckCircleIcon style={{fontSize:'small'}}/>:<CancelIcon id='isSpecialicon'style={{fontSize:'small'}}/>}  atleast one special character </div><br />
              <div><span style={{color:'red'}}>Note:</span> Make sure your Password is Strong.<br />
                  <Typography style={{marginLeft:"42px"}}>Using Names, Date of Births, Spouse Name, Pet Name, Locations etc. is not advisable</Typography>
              </div>
            </div><br></br>
        </Card>:null}
    </div>
  )
}

export default Password