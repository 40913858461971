
import { Box, Button, Grid, makeStyles, Tab, Tabs, Tooltip, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddOutlined';
import { Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EligoBackdrop from '../../../common/EligoBackdrop';
import EligoSnackBar from '../../../common/EligoSnackBar';
import { AxiosEvotingInterceptor } from '../../../config/axios.interceptor';
import AddPollQuestion from '../add-poll-question/AddPollQuestion';
import './PollQuestions.css';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        // backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: '70vh',
    },
    tabs: {
        "& .MuiTabs-indicator": {
            backgroundColor: "rgb(123, 67, 151)",
            height: 3,
        },
        "& .MuiTab-root.Mui-selected": {
            color: 'rgb(123, 67, 151)',
            fontWeight: '600'
        }
    },
    margin: {
        marginTop: theme.spacing(2),
    },
    listIconMargin: {
        margin: theme.spacing(1),
    }
}));

function TabPanel(props) {
    const { children, value, index } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            style={{ width: '100%' }}
        >
            {value === index && (
                <Box className="poll-question-tab">
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function PollQuestions(props) {
    const { t } = useTranslation();
    const classes = useStyles();
    const [tab, setTab] = React.useState(null);
    const [page, setPage] = useState(1);
    const [itemsPerPage] = useState(100);
    const [pollQuestions, setPollQuestions] = useState([]);
    const [isAddNew, setIsAddNew] = useState(false);
    const [index, setIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = React.useState({
        showMsg: false,
        message: null,
        severity: null
    });

    const getPollQuestions = () => {
        setPollQuestions([]);
        setLoading(true);
        setMessage({ showMsg: false, message: '', severity: '' });
        AxiosEvotingInterceptor.get(`poll-question/${props.pollId}?page=${page}&size=${itemsPerPage}&asc=false`)
                .then(response => {
            if (response.pollQuestions.length > 0) {
                setTab(index);
                setTimeout(function () {
                    response.pollQuestions.map((pollQuestion) => {
                        setPollQuestions(prevState => ([
                            ...prevState,
                            {
                                poll_question_id: pollQuestion.id,
                                poll_id: props.pollId,
                                // poll_round_id: pollQuestion.poll_round_id,
                                question: pollQuestion.question,
                                question_description: pollQuestion.questionDescription,
                                // allow_vote_change: pollQuestion.allow_vote_change,
                                // display_organizer_name: pollQuestion.display_organizer_name,
                                // allow_multiple_vote: pollQuestion.allow_multiple_vote,
                                // allow_question_skip: pollQuestion.allow_question_skip,
                                // shuffle_options: pollQuestion.shuffle_options,
                                threshold: pollQuestion.threshold,
                                PollQuestionResponses: pollQuestion.pollQuestionResponses ? pollQuestion.pollQuestionResponses.map(pollQuestionResponses => {
                                    return {
                                        poll_question_response_id: pollQuestionResponses.id,
                                        option: pollQuestionResponses.option,
                                        // poll_question_id: pollQuestionResponses.poll_question_id,
                                    }
                                }) : []
                            }
                        ]))
                    })
                }, 200);
            }
            // This else is required when setup the poll
            else if (props.status === 'new' && response.pollQuestions.length === 0) {
                addPollQuestion();
            }
            setLoading(false);
        })
        .catch(error => {
            setLoading(false);
            if (error.message) {
                setMessage({ showMsg: true, message: error.message, severity: 'error' });
            }
        })
    }

    useEffect(() => {
        if (page === 1) {
            setPollQuestions([])
        }
        getPollQuestions();
    }, [page]);

    if (page === 0) {
        setTimeout(() => {
            setPage(page + 1);
        }, 1000);
    }

    const handleTabChange = (event, newValue) => {
        if (newValue != null) {
            setTab(newValue);
        } else {
            addPollQuestion();
        }
    };

    const addPollQuestion = () => {
        setIsAddNew(true);
        setPollQuestions(prevState => ([
            ...prevState,
            {
                poll_question_id: '',
                poll_id: '',
                question: '',
                question_description: '',
                allow_vote_change: true,
                allow_multiple_vote: false,
                allow_question_skip: false,
                shuffle_options: false,
                threshold: 0,
                PollQuestionResponses: [
                    {
                        poll_question_response_id: '',
                        option: t("Default_Option_1"),
                        // poll_question_id: '',
                    },
                    {
                        poll_question_response_id: '',
                        option: t("Default_Option_2"),
                        // poll_question_id: ''
                    },
                    {
                        poll_question_response_id: '',
                        option: t("Default_Option_3"),
                        poll_question_id: ''
                    }
                ]
            }
        ]))
        setTab(pollQuestions.length);
    }

    const handleCancel = (index) => {
        var questions = [...pollQuestions];
        if (index !== -1) {
            questions.splice(index, 1);
            setPollQuestions(questions);
            setIsAddNew(false);
            setTab(0)
        }
    }

    const refreshQuestions = (index) => {
        setIndex(index)
        getPollQuestions();
    }
    const refreshQuestionsDelete = (index) => {
        if (pollQuestions.length != 1) {
            getPollQuestions();
            setIndex(index)
        } else {
            setPollQuestions([])
        }
    }

    return (
        <Stack paddingX={1} paddingY={1}>
            <Grid container>
                <Grid item xs={12}>
                    {loading && <EligoBackdrop show={true} />}
                    <div>
                        <Grid container>
                            <Grid item xs={10} style={{ textAlign: 'center' }} className="loading-container">
                                <Tabs
                                    id='varticle'
                                    orientation="horizontal"
                                    variant="scrollable"
                                    textColor="secondary"
                                    indicatorColor="secondary"
                                    value={tab}
                                    TabIndicatorProps={{ style: { fontWeight: 'bolder' } }}
                                    onChange={handleTabChange}
                                    aria-label="Vertical tabs example"
                                    className={`${classes.tabs} vertical-question-tab`}
                                >
                                    {pollQuestions.length > 0 &&
                                        pollQuestions.map((pollQuestion, index) => {
                                            return <Tab id='tab' label={`${t('Q')}. #${index + 1}`} value={index} key={index} />
                                        })
                                    }
                                </Tabs>
                            </Grid>
                            {(props.hasPermission || props.systemAdmine) &&
                                <Grid item xs={2}>
                                    <Tooltip title={t('Add_Matter')} arrow>
                                        {/* <Fab style={{ float: 'right', cursor: 'pointer' }} size='medium' color="primary" aria-label="add" id="addressplus"
                                        onClick={(e) => handleTabChange(e, null)} disabled={isAddNew}>
                                        <Add />
                                    </Fab> */}
                                        <Button variant='contained' style={{
                                            marginRight: '18px ', paddingLeft: '0px', paddingTop: '7px', marginTop: '-10px',
                                            textTransform: 'none !important'
                                        }}
                                            id='add-poll-question' color="primary" size="small"
                                            className={`float-right`} onClick={(e) => handleTabChange(e, null)} disabled={isAddNew}>
                                            &nbsp;<AddIcon id='addicon' fontSize='small' />&nbsp; {t('Add_Matter')}
                                        </Button>
                                    </Tooltip>
                                </Grid>
                            }
                        </Grid>
                        {pollQuestions.length > 0 &&
                            pollQuestions.map((pollQuestion, index) => {
                                return <TabPanel value={tab} index={index} key={index} className='questions-tab'>
                                    <Grid item xs={12}>
                                        <AddPollQuestion index={index} pollId={props.pollId}
                                            pollRoundQuestionDetails={pollQuestion}
                                            systemAdmine={props.systemAdmine}
                                            handleCreatePollRoundQuestion={refreshQuestions}
                                            handleDeletePollQuestion={refreshQuestionsDelete}
                                            setIndex={setIndex}
                                            handleCancel={handleCancel} setIsAddNew={setIsAddNew}
                                            hasPermission={props.hasPermission}
                                            uploadPermission={props.uploadPermission}
                                            isAddNew={isAddNew} stepper={false} status={props.status} />
                                    </Grid>
                                </TabPanel>
                            })
                        }
                        {(!loading && pollQuestions.length === 0) &&
                            <Stack direction='column' justifyContent='center' alignItems='center' sx={{ pt: '10%' }} spacing={1}>
                                <img src={process.env.PUBLIC_URL + '/images/no-matter.svg'} width='15%'></img>
                                <Typography id='matter-not-found' className='polls-not-found'>
                                    {t('No_Matters_Are_Available')}
                                </Typography>
                                {/* <Typography className='polls-not-found' color='textSecondary'>
                                        {t('Tr')}
                                    </Typography> */}
                                {(props.status === 'scheduled' || props.status === 'new') && props.hasPermission &&
                                    < Button className='polls-not-found' variant='outlined' color='primary' onClick={(e) => handleTabChange(e, null)}>
                                        {t('Add Matter')}
                                    </Button>
                                }
                            </Stack>
                        }
                    </div>
                </Grid>
            </Grid>
            {message.showMsg && <EligoSnackBar show={message.showMsg} message={message.message}
                severity={message.severity}></EligoSnackBar>}
        </Stack >
    )
}

export default PollQuestions;