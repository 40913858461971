import { Button, Typography } from '@material-ui/core';
import { Close, CloudUpload, DoneAllRounded } from '@material-ui/icons';
import { Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Fab, LinearProgress, Slide, Stack, Tooltip } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { ImCheckmark, ImCross } from "react-icons/im";
import { FileIcon } from './FileIcon';
import { ButtonStyled, DashedCard, FileUploadCard, useStyles } from './styles/FileStyle';
import { collectionFileExtensionSet, executableExtensionSet, executableMimeTypeSet, restrictTypeCode } from './FileUploadStaticVar';
import EligoSnackBar from '../../common/EligoSnackBar';

const UploadDialog = (props) => {
    const { dialogOpen, title, t, setDialogOpen, uploadedFile, onUploadClick, files, setFiles, handleUploadClose, acceptableFiles, isMultiple } = props;
    const [progress, setProgress] = useState(false);
    const classes = useStyles();
    const containerRef = React.useRef(null);
    const handleClose = () => {
        setProgress(false)
        setDialogOpen(false)
        setFiles([])
        if (typeof handleUploadClose !== 'undefined' && typeof handleUploadClose === 'function') {
            handleUploadClose()
        }
    }

    function restrictExecutableType(file) {
        if (executableMimeTypeSet.includes(file.type) || executableExtensionSet.includes(file.name.split('.').pop())) {
            return {
                code: restrictTypeCode.executable
            };
        }
        if (collectionFileExtensionSet.includes(file.name.split('.').pop())) {
            return {
                code: restrictTypeCode.collection
            }
        }
        if (file.name === 'mvnw') {
            return {
                code: restrictTypeCode.executable
            }
        }
    }

    const onDrop = useCallback(acceptedFiles => {
        if (isMultiple) {
            let allFiles = [...acceptedFiles, ...files];
            let uniqueFiles = files.length === 0 ? acceptedFiles : [...new Map(allFiles.map(item => [item['path'], item])).values()]
            setFiles(uniqueFiles)
        } else {
            setFiles(acceptedFiles)
        }
    }, [files])

    const { getRootProps, getInputProps, isDragActive, open, fileRejections } = useDropzone(
        {
            onDrop, noClick: true, multiple: isMultiple,
            accept: acceptableFiles,
            validator: restrictExecutableType
        }
    )

    const removeSelectedFiles = (filePath) => {
        setFiles(files.filter((file) => file.path != filePath));
    }

    let i = 0;

    return (
        <Dialog
            open={dialogOpen}
            maxWidth='md'
        >
            <DialogTitle>
                {title}
                <Tooltip title={t('Close')} arrow>
                    <Close onClick={handleClose} size='large' id="close-button" color='error' style={{ float: 'right', cursor: 'pointer' }} />
                </Tooltip>
            </DialogTitle>
            <Divider />
            <DialogContent style={{ height: '320px' }}>
                <Stack spacing={3} direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row' }}>
                    <Stack>
                        <DashedCard id="dashed-card" elevation={0} {...getRootProps({ className: 'dropzone' })}>
                            {progress && uploadedFile.length != files.length &&
                                <Stack direction='column'>
                                    <CloudUpload style={{ color: 'rgb(191 217 241)', fontSize: '7rem' }} id='cloud-upload' />
                                    <Typography color='primary' variant='h5' className={classes.dotLoading}>{t('Uploading')}</Typography>
                                </Stack>}
                            {uploadedFile.length === files.length && uploadedFile.length != 0 &&
                                <Stack direction='column' spacing={1} justifyContent='center' alignItems='center'>
                                    <CloudUpload style={{ color: 'rgb(191 217 241)', fontSize: '7rem' }} id='cloud-icon' />
                                    <DoneAllRounded style={{ fontSize: '2rem', color: 'green' }} />
                                    <Typography variant='h5' style={{ color: 'green' }}>{t('Done')}</Typography>
                                </Stack>
                            }
                            {!progress && <>
                                <input type="file" id="input-field" className="input-zone" {...getInputProps()} />
                                <Stack spacing={1} width={{ xs: '100%' }} justifyContent='center' alignItems='center'>
                                    <CloudUpload style={{ color: 'rgb(191 217 241)', fontSize: '7rem' }} />
                                    {isDragActive ?
                                        <><Typography color='primary'>{t('Release_to_drop_the_files_here')}</Typography></> :
                                        <><Typography color='primary'>{t('Drag_&_Drop_your_Files_here')}</Typography>
                                            <Typography color='primary'>{t('OR')}</Typography>
                                            <Button variant='contained' id='browse-button' onClick={open} color='primary'>{t('Browse_Files_to_attach')}</Button></>}
                                </Stack>
                            </>}
                        </DashedCard>
                    </Stack>
                    {files.length > 0 &&
                        <Stack spacing={1} ref={containerRef}>
                            {files.map((file, index) => {
                                i = i + 100;
                                return (
                                    <Slide direction='up' in={true} timeout={300 + i} container={containerRef.current}>
                                        <FileUploadCard id="file-upload-card">
                                            <Stack spacing={1} direction='column'>
                                                <Stack spacing={1} direction='row'>
                                                    <Stack width={{ xs: '10%' }}><FileIcon fileName={file.name} /></Stack>
                                                    <Tooltip describeChild title={file.name}>
                                                        <Stack width={{ xs: '75%' }}><Typography gutterBottom noWrap>{file.name}</Typography></Stack>
                                                    </Tooltip>
                                                    <Stack width={{ xs: '10%' }} alignItems='end'>
                                                        {!(uploadedFile.includes(file.path)) && !progress && <Tooltip title={t('Remove_File')} arrow>
                                                            <Fab size='small'
                                                                onClick={() => { removeSelectedFiles(file.path) }}
                                                                style={{
                                                                    width: '25px',
                                                                    background: '#ffdddd',
                                                                    height: '25px', minHeight: '25px', boxShadow: '0px 3px 5px -1px rgb(185 10 10 / 20%), 0px 3px 8px 0px rgb(196 26 26 / 14%), 0px 1px 16px 0px rgb(224 111 111 / 12%)'
                                                                }}>
                                                                <ImCross color='rgb(204 22 22 / 69%)' fontSize='0.8em' />
                                                            </Fab>
                                                        </Tooltip>}
                                                        {(uploadedFile.includes(file.path)) && <ImCheckmark color='green' fontSize='1.5em' />}
                                                    </Stack>
                                                </Stack>
                                                {!(uploadedFile.includes(file.path)) && progress && <LinearProgress color='success' />}
                                            </Stack>
                                        </FileUploadCard>
                                    </Slide>
                                )
                            })}
                        </Stack>}
                </Stack>
            </DialogContent>
            <Divider />
            {files.length > 0 && !progress && <Collapse in={true} timeout={1000}>
                <DialogActions style={{ padding: '1rem', gap: '10px' }}>
                    <Typography color='primary' variant='h7' gutterBottom style={{ fontWeight: 'bold' }}>{t('Total')} {files.length} {files.length === 1 ? t('file') : t('files')}</Typography>
                    <Button variant='contained' id="upload-file" color='primary' onClick={() => { setProgress(true); onUploadClick(files) }}>{t('Upload_Files')}</Button>
                </DialogActions></Collapse>}
            {fileRejections.length > 0 && <EligoSnackBar show={fileRejections.length > 0 ? true : false}
                message={fileRejections.length > 1 ? `${fileRejections.length} ${t('Executable_Files_Are_Detected_Executable_Files_Cannot_Be_Uploaded')}` :
                    `${fileRejections[0].file.name} - ${t('Executable_File_Is_Detected_Executable_File_Cannot_Be_Uploaded')}`} severity={'error'} />}

        </Dialog >
    )
}

export default UploadDialog

