import React, {
    Fragment,
    useState
} from 'react';
import { 
    Accordion,
    Typography,
    AccordionSummary,
    AccordionDetails,
    Grid,
    Button
} from '@material-ui/core';
import { useTranslation } from 'react-i18next'; 
import { format } from 'date-fns';


const PollDetailsPage = ({pollDetails, expandPOllDetail}) => {
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState(expandPOllDetail != undefined ? expandPOllDetail : false);

    return (
        <Accordion square expanded={expanded} onChange={() => setExpanded(!expanded)}>
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" style={{background: '#80808014'}}>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                    {!expanded && <Fragment>
                        <Grid item xs={6} style={{fontWeight: 'bold'}}>
                            {pollDetails.title}
                        </Grid>
                        <Grid item xs={6}>
                            <Button size="small" color="primary" style={{float: 'right', textTransform: 'none'}}>{t('Show_More')}</Button>
                        </Grid>
                    </Fragment>}
                    {expanded && <Fragment>
                        <Grid item xs={3} style={{fontWeight: 'bold'}}>
                            {t('Poll_Details')}
                        </Grid>
                        <Grid item xs={9}>
                            <Button size="small" color="primary" style={{float: 'right', textTransform: 'none'}}>{t('Show_Less')}</Button>
                        </Grid>
                    </Fragment>}
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container direction="row">
                    <Grid container direction="row" style={{padding: '6px'}}>
                        <Grid item xs={3} style={{fontWeight: 'bold'}}>
                            {t('Title')}
                        </Grid>
                        <Grid item xs={9}>
                            :  {pollDetails.title}
                        </Grid>
                    </Grid>
                    <Grid container direction="row" style={{padding: '6px'}}>
                        <Grid item xs={3} style={{fontWeight: 'bold'}}>
                            {t('Begin_Date_&_Time')}
                        </Grid>
                        <Grid item xs={9}>
                            :  {format(new Date(pollDetails.startTime), process.env.REACT_APP_DATE_FORMAT_TO_DISPLAY)}
                        </Grid>
                    </Grid>
                    <Grid container direction="row" style={{padding: '6px'}}>
                        <Grid item xs={3} style={{fontWeight: 'bold'}}>
                            {t('End_Date_&_Time')}
                        </Grid>
                        <Grid item xs={9}>
                            :  {format(new Date(pollDetails.endTime), process.env.REACT_APP_DATE_FORMAT_TO_DISPLAY)}
                        </Grid>
                    </Grid>
                    <Grid container direction="row" style={{padding: '6px'}}>
                        <Grid item xs={3} style={{fontWeight: 'bold'}}>
                            {t('Description')}
                        </Grid>
                        <Grid item xs={9}>
                            :  {pollDetails.description}
                        </Grid>
                    </Grid>
                    {pollDetails.conductFor && <Grid container direction="row" style={{padding: '6px'}}>
                        <Grid item xs={3} style={{fontWeight: 'bold'}}>
                            {t('Client_Name')}
                        </Grid>
                        <Grid item xs={9}>
                            :  {pollDetails.conductFor.name}
                        </Grid>
                    </Grid>}
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}

export default PollDetailsPage;