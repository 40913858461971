import { AxiosEvotingInterceptor } from "../../../../config/axios.interceptor";

const PartyFieldSearch = (payload, setLoading, callback ) => {
    AxiosEvotingInterceptor.post(`search/user`, payload).then(response => {
        if(response && response.length == 1) {
            getUserDetails(response[0].id, setLoading, callback);
        } else {
            callback(null);
        }
        setLoading(false)
    }).catch(error => {
        console.log("execution search error", error)

    })
}

const getUserDetails = (userId, setLoading, callback) => {
    AxiosEvotingInterceptor.get(`user/${userId}`).then(response => {
        callback(response);
        setLoading(false);
    }).catch(error => {
        setLoading(false);
        console.log("get User details error", error)
        // if (error.message) {
        //     setErrorMessage(error.message);
        // } else {
        //     setErrorMessage(t('Try_Again'));
        // }
        // setLoading(false);
    })
}

export { PartyFieldSearch }