import React, { useEffect, useState } from 'react';
import {
    Dialog, DialogContent, DialogContentText,
    Grid, TextField, TableContainer, Table,
    TableHead, TableRow, TableCell, TableBody,
    withStyles, Typography, IconButton, Card, CardContent
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { ColoredHeader } from '../../file-upload-download/styles/FileStyle';
import { AxiosEvotingInterceptor } from '../../../config/axios.interceptor';
import EligoBackdrop from '../../../common/EligoBackdrop';
import EligoSnackBar from '../../../common/EligoSnackBar';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: 'red',
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

function RoleDetail(props) {
    const { t } = useTranslation();
    const [open, setOpen] = useState(props.show);
    const [user] = useState(props.user);
    const [role, setRole] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pollError, setPollError] = useState('');

    const columns = [
        { id: 'role', label: t('Role'), minWidth: 15 },
        { id: 'granted', label: t('Assigned'), minWidth: 30 },
        { id: 'revoked', label: t('Revoked'), minWidth: 30 },
        { id: 'remark', label: t('Remarks'), minWidth: 45 }
    ];

    const handleClose = () => {
        setOpen(false);
        props.setShowRoleDetails({
            show: false,
            user: ''
        });
    }

    const getRoles = () => {
        AxiosEvotingInterceptor.get(`role/${props.pollId}/${props.user.user_id}`).then(response => {
            setRole([])
            if (response != null) {
                setRole(response.roleDetails)
                setLoading(false)
            }
        }).catch((error) => {
            setLoading(false)
            setPollError(error.message)
        })
    }

    useEffect(() => {
        setLoading(true)
        getRoles()
    }, [])

    return (
        <Dialog onClose={handleClose} open={open} scroll='paper' aria-labelledby="role-detail-title" maxWidth='md'
            aria-describedby="role-detail-description">
            <DialogTitle id="role-detail-title" onClose={handleClose}><b>{t("Details_Of")} {props.userName}</b></DialogTitle>
            <DialogContent dividers>
                <DialogContentText id="role-detail-description">
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <TextField id="user-name" label={t("User")} value={user.name} fullWidth disabled />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField id="user-email" label={t("Email_Id")} value={user.email} fullWidth disabled />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField id="user-mobile-number" label={t("Mobile_Number")} value={user.phone_number} fullWidth disabled />
                        </Grid>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <TableContainer>
                                        <Table id='table' stickyHeader={true} aria-label="sticky-table-header">
                                            <ColoredHeader>
                                                <TableRow>
                                                    {columns.map((column, index) => (
                                                        <TableCell id='cell' key={index} style={{ width: column.minWidth + "%" }}>
                                                            {column.label}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </ColoredHeader>
                                            <TableBody>
                                                {role.length === 0 && <TableRow>
                                                    <TableCell id='norecord' colSpan={3}> {t('No_Record_Found')} </TableCell>
                                                </TableRow>
                                                }
                                                {role.length > 0 && role.map((role, index) => {
                                                    return (
                                                        <TableRow hover key={role.role_id}>
                                                            <TableCell id='role'>
                                                                {(role.role.charAt(0).toUpperCase() + role.role.slice(1))}
                                                            </TableCell>
                                                            <TableCell id='grantedby'>
                                                                {role.granter}
                                                                <div style={{ color: 'grey' }}><em>{format(new Date(role.startTime), process.env.REACT_APP_DATE_FORMAT_TO_DISPLAY)}</em></div>
                                                            </TableCell>
                                                            <TableCell id='revoke'>
                                                                {role.revoker}
                                                                <div style={{ color: 'grey' }}><em>{role.revoker === null ? '--' : format(new Date(role.endTime), process.env.REACT_APP_DATE_FORMAT_TO_DISPLAY)}</em></div>
                                                            </TableCell>
                                                            <TableCell id='remark'>
                                                                {role.remarks}
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </DialogContentText>
            </DialogContent>
            {loading && <EligoBackdrop show={true} />}
            {pollError !== '' && pollError !== undefined &&
                <EligoSnackBar show={true} message={pollError} severity="error" reset={() => setPollError(null)} />}
        </Dialog >
    )
}

export default RoleDetail;
