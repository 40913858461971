import { Tooltip } from '@material-ui/core';
import { Cancel, DeleteRounded, KeyboardArrowRightRounded, WatchLaterRounded } from '@material-ui/icons';
import { Stack, Typography, Button, ButtonGroup, Grid } from '@mui/material';
import React, { useState } from 'react';
import { IoOpen } from "react-icons/io5";
import { MenuBar, MenuListIcon, MenuListItem, MenuListText, MenuSvgIcon, SideNavBar, SideNavHeader } from './side-nav-style/SideNavStyle';
import UpdateIcon from '@material-ui/icons/Update';
import { MdUpcoming } from 'react-icons/md'
import { Roles } from '../../common/GenericCodes';
import { useTranslation } from 'react-i18next';


export const pollMenuItems = [
    { id: "open", title: 'Live', icon: <IoOpen style={{ fontSize: 'x-large' }} />, value: 'OPEN' },
    { id: "upcoming", title: 'Upcoming', icon: <MdUpcoming style={{ fontSize: 'x-large' }} />, value: 'SCHEDULED' },
    { id: "undersetup", title: 'Under Setup', icon: <UpdateIcon />, value: 'SETUP' },
    { id: "closed", title: 'Closed', icon: <Cancel />, value: 'CLOSED' },
    { id: "cancel", title: 'Cancelled', icon: <DeleteRounded />, value: 'CANCELLED' },
]

export const pollMenuItemsForVoter = [
    { id: "opens", title: 'Live', icon: <IoOpen style={{ fontSize: 'x-large' }} />, value: 'OPEN' },
    { id: "upcomings", title: 'Upcoming', icon: <MdUpcoming style={{ fontSize: 'x-large' }} />, value: 'SCHEDULED' },
    { id: "closeds", title: 'Closed', icon: <Cancel />, value: 'CLOSED' },
    { id: "cancels", title: 'Cancelled', icon: <DeleteRounded />, value: 'CANCELLED' },
]

const SideNav = props => {
    const { t } = useTranslation();
    const { selectedList, listChangeHandler, selectedRole, menuCollapse, setMenuCollapse, handleMenuCollapse,
        pollClass, setPollClass } = props;
    // const [selectedType, setSelectedType] = useState('all');

    const toggleMenuCollapse = () => {
        setMenuCollapse(!menuCollapse)
        handleMenuCollapse(!menuCollapse);
    }

    return (
        // <Slide direction="right" in={true} timeout={500} unmountOnExit>
        <SideNavBar ismenucollapse={menuCollapse}>
            <SideNavHeader>
                <MenuSvgIcon component={KeyboardArrowRightRounded} onClick={toggleMenuCollapse} menucollapse={menuCollapse} />
                {/* <Divider><KeyboardArrowRightRounded style={{ color: 'rgb(42, 131, 173)', background: 'rgb(242 242 242)' }} /></Divider> */}
            </SideNavHeader>
            {!menuCollapse && <Grid container direction="row" justifyContent="center" alignItems="center" sx={{textAlign: 'center', paddingTop: '12px'}}>
                <Grid item xs={12}>
                    <ButtonGroup variant="outlined" aria-label="filter-btn-group" size='small' color='primary'>
                        <Button variant={pollClass === 'All' ? "contained" : "outlined"}
                            onClick={() => {setPollClass('All')}}>{t('All')}</Button>
                        <Button variant={pollClass === 'POLL' ? "contained" : "outlined"}
                            onClick={() => {setPollClass('POLL')}}>{t('Polls')}</Button>
                        <Button variant={pollClass === 'SURVEY' ? "contained" : "outlined"}
                            onClick={() => {setPollClass('SURVEY')}}>{t('Surveys')}</Button>
                    </ButtonGroup>
                </Grid>
            </Grid>}
            <MenuBar>
                {selectedRole != Roles.voter && pollMenuItems.map((item, index) => (
                    <Tooltip title={item.title} disableHoverListener={!menuCollapse} arrow placement='right' key={index}>
                        <MenuListItem button id={item.id} style={menuCollapse ? { display: 'flex', justifyContent: 'center', alignItems: 'center' } : {}} key={index}
                            onClick={() => { listChangeHandler(item.value, item.title) }} isselected={selectedList === item.value ? true : false}>
                            <Stack direction={menuCollapse ? 'column' : 'row'} justifyContent='center' alignItems='center'>
                                <MenuListIcon className='icon'>
                                    {item.icon}
                                </MenuListIcon>
                                <MenuListText className='text'>
                                    <Typography style={menuCollapse ? { fontSize: '10px', margin: '0px' } : { margin: '0px 0px 0px 15px' }}>{item.title}</Typography>
                                </MenuListText>
                            </Stack>
                        </MenuListItem>
                    </Tooltip>
                ))}
                {selectedRole == Roles.voter && pollMenuItemsForVoter.map((item, index) => (
                    <Tooltip title={item.title} disableHoverListener={!menuCollapse} arrow placement='right' key={index}>
                        <MenuListItem button id={item.id} style={menuCollapse ? { display: 'flex', justifyContent: 'center', alignItems: 'center' } : {}} key={index}
                            onClick={() => { listChangeHandler(item.value, item.title) }} isselected={selectedList === item.value ? true : false}>
                            <Stack direction={menuCollapse ? 'column' : 'row'} justifyContent='center' alignItems='center'>
                                <MenuListIcon className='icon'>
                                    {item.icon}
                                </MenuListIcon>
                                <MenuListText className='text'>
                                    <Typography style={menuCollapse ? { fontSize: '10px', margin: '0px' } : { margin: '0px 0px 0px 15px' }}>{item.title}</Typography>
                                </MenuListText>
                            </Stack>
                        </MenuListItem>
                    </Tooltip>
                ))}
            </MenuBar>
        </SideNavBar>
        // </Slide>
    )
}

export default SideNav
