import { Divider, Stack, Typography } from "@mui/material";
import { Fragment, forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import EligoBackdrop from "../../../common/EligoBackdrop";
import EligoSnackBar from "../../../common/EligoSnackBar";
import { TypeOfPollCategory } from "../../../common/GenericCodes";
import { APIEvotingAxiosInterceptor, AxiosEvotingInterceptor } from "../../../config/axios.interceptor";
import Question from "../../cast-vote/v2/Question";
import { OptionType, QuestionMenu, QuestionType } from "../QuestionStaticVar";
import Options from "./question-section/Options";
import QuestionFile from "./question-section/QuestionFile";
import QuestionPart from "./question-section/QuestionPart";
import Setting from "./question-section/Setting";
import RankRealTimeResult from "./question-section/real-time-result/RankRealTimeResult";
import RealTimeResult from "./question-section/real-time-result/RealTimeResult";

const PollQuestionTabpanel = forwardRef((props, ref ) => {
    const { selectedMenu, questionRef, optionRef, settingsRef, filesRef, previewRef, resultRef, pollId, questionTitle,hasSystemAdmineOrSupportRole,
        onClose, editQuestion, isEdit, dependentQuestions, pollType, questionNumber, title, totalVoterCount,
        noOfVotedNotVoted, showResult, hasPermission, filePermission, listTitle, pollClass } = props;
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({ showMsg: false, message: null, severity: '' });
    const [error, setError] = useState({});
    const [deletedOption, setDeletedOption] = useState({
        options: []
    });
    const [question, setQuestion] = useState();

    const getQuestion = () => {
        setMessage({ showMsg: false, message: null, severity: null });
        APIEvotingAxiosInterceptor.get(`question/${pollId}/${editQuestion.id}`).then(
            (response) => {
                if (response) {
                    setQuestion({
                        ...response,
                        optionType: response.options.length !== 0 ? response.options[0].optionType : OptionType.Text
                    });
                }
                setLoading(false);
            }).catch(error => {
                setLoading(false);
                if (error.message) {
                    setMessage({ showMsg: true, message: error.message, severity: 'error' });
                }
            })
    }

    const questiontrigger = () => {
        let valid = true;
        let optionEmptyCount = 0;
        setMessage({ showMsg: false, message: '', severity: '' });
        if (question.question.trim() === "") {
            setError((prevState) => ({
                ...prevState,
                question: title + " is required"
            }))
            valid = false;
        }
        if ((OptionType.Text === question.optionType || OptionType.TextAndImage === question.optionType) && question.options.length > 0 ) {
            question.options.map((opt, index) => {
                if (opt.option === "") {
                    setError((prevState) => ({
                        ...prevState,
                        ['option' + index]: "Option cannot be empty"
                    }))
                    optionEmptyCount = optionEmptyCount + 1;
                    valid = false;
                }

            })
        }
        if (question.options.length > 0 && (OptionType.Image === question.optionType)) {
            question.options.map((opt, index) => {
                if (opt.files === null) {
                    setError((prevState) => ({
                        ...prevState,
                        ['file' + index]: `Please upload image to the option ${index+1}`
                    }))
                    valid = false;
                }
            })
        }
        let optionTextSet = uniqByKeepLast(question.options, it => it.option.toLowerCase(), false);
        let filesSet = uniqByKeepLast(question.options, it => JSON.stringify(it.files), true);
        if (question.options.length > 0 && ((optionEmptyCount < 2 && optionTextSet.length !== question.options.length && OptionType.Image !== question.optionType) || filesSet !== question.options.length)) {
            setMessage({ showMsg: true,
                message:
                    ((((optionEmptyCount < 2 && optionTextSet.length !== question.options.length) && OptionType.Image !== question.optionType) && filesSet !== question.options.length) && 'The Option Text and Images are duplicated') ||
                    ((optionEmptyCount < 2 && optionTextSet.length !== question.options.length && OptionType.Image !== question.optionType) && 'The Options are duplicated') ||
                    (filesSet !== question.options.length && 'The Option Images are duplicated'),
                severity: 'error' });
            valid = false;
        }
        return valid;
    }

    const uniqByKeepLast = (data, key, isFiles) => {
        if (isFiles) {
            let filesSet = new Set();
            data.map((opt, index) => {
                if(opt.files !== null) {
                    filesSet.add(Object.values(opt.files).toString())
                } else {
                    filesSet.add(`files${index}`)
                }
            })
            return filesSet.size;
        } else {
            return [...new Map(data.map(x => [key(x), x])).values()]
        }
    }

    const onMessageClose = () => {
        setMessage({ showMsg: false, message: '', severity: '' });
    }

    const createQuestion = () => {
        setMessage({ showMsg: false, message: '', severity: '' });
        setError({});
        let questionOptions = question.options.map((opt) => ({
            ...opt,
            id: null,
            option: OptionType.Image === opt.optionType ? "" : opt.option,
            files: OptionType.Text === opt.optionType ? null : opt.files,
            s3Location: null,
            deletedOption: opt.deletedOption,
            nextQuestionId: opt.nextQuestionId,
            optionType: opt.optionType,
        }))
        let payload = {
            ...question,
            options: [
                ...questionOptions,
            ]
        }
        let valid = questiontrigger();
        if (valid) {
            AxiosEvotingInterceptor.post(`question/${pollId}`, payload).then((response) => {
                if (response) {
                    setLoading(false);
                    onClose({ showMsg: true, message: `Added ${title} successfully`, severity: 'success' });
                }
            }).catch((error) => {
                if (error.message) {
                    setMessage({ showMsg: true, message: error.message, severity: 'error' });
                }
                setLoading(false);
            })
        } else {
            setLoading(false);
        }
    }

    const updateQuestion = () => {
        setMessage({ showMsg: false, message: '', severity: '' });
        setError({});
        let questionOptions = question.options.map((opt) => ({
            id: (opt.id !== "" && opt.id !== null) ? opt.id: null,
            option: OptionType.Image === opt.optionType ? "" : opt.option,
            files: OptionType.Text === opt.optionType ? null : opt.files,
            s3Location: null,
            deletedOption: opt.deletedOption,
            nextQuestionId: opt.nextQuestionId,
            optionType: opt.optionType,
        }))
        let payload = {
            ...question,
            options: [
                ...questionOptions,
                ...deletedOption.options
            ]
        }
        let valid = questiontrigger();
        if (valid) {
            AxiosEvotingInterceptor.put(`question/${pollId}/${question.id}`, payload).then((response) => {
                if (response) {
                    setLoading(false);
                    onClose({ showMsg: true, message: `Updated ${title} successfully`, severity: 'success' });
                }
            }).catch((error) => {
                if (error.message) {
                    setMessage({ showMsg: true, message: error.message, severity: 'error' });
                }
                setLoading(false);
            })
        } else {
            setLoading(false);
        }
    }

    const handleSave = () => {
        setError({});
        setLoading(true);
        setTimeout(() => {
            if (isEdit) {
                updateQuestion();
            } else {
                createQuestion();
            }
        }, 1)
    }

    useImperativeHandle(
        ref,
        () => ({
            handleSave() {
                handleSave()
            }
        }),
    )

    const handleRefresh = () => {
        setLoading(true);
        getQuestion();
    }

    useEffect(() => {
        if (isEdit ) {
            setLoading(true);
            getQuestion();
        } else {
            setQuestion({
                questionType: QuestionType.SingleChoice,
                question: "",
                questionDescription: "",
                threshold: "",
                allowQuestionSkip: false,
                shuffleAllOptions: false,
                shuffleWithoutLastOption: false,
                optionType: OptionType.Text,
                optionHeader: "Options",
                minSelectInMultiChoice: 1,
                maxSelectInMultiChoice: 1,
                options: [
                    {
                        option: (TypeOfPollCategory.IBC === pollType) ? "For" : "",
                        optionType: OptionType.Text,
                        id: "",
                        nextQuestionId: null,
                        files: null,
                        deleted: false,
                        s3Location: "",
                    },
                    {
                        option: (TypeOfPollCategory.IBC === pollType) ? "Against" : "",
                        optionType: OptionType.Text,
                        id: "",
                        nextQuestionId: null,
                        files: null,
                        deleted: false,
                        s3Location: "",
                    },
                    {
                        option: (TypeOfPollCategory.IBC === pollType) ? "Abstained" : "",
                        optionType: OptionType.Text,
                        id: "",
                        nextQuestionId: null,
                        files: null,
                        deleted: false,
                        s3Location: "",
                    },]
            })
        }
    }, [])

    return (
        <Fragment>
            {question && <Stack width='90%'>
                <Stack id="question" ref={questionRef} m={1} className={`poll-panel ${QuestionMenu.matter === selectedMenu ? 'poll-panel-active': ''}`}>
                    <QuestionPart question={question} setQuestion={setQuestion} pollType={pollType} title={title} hasSystemAdmineOrSupportRole={hasSystemAdmineOrSupportRole}
                        error={error} setError={setError} hasPermission={hasPermission} pollClass={pollClass}/>
                </Stack>
                <Divider flexItem sx={{marginY: 2}}/>
                <Stack id="option" ref={optionRef} minHeight={200} m={1} className={`poll-panel ${QuestionMenu.option === selectedMenu ? 'poll-panel-active' : ''}`}>
                    <Options question={question} setQuestion={setQuestion} dependentQuestions={dependentQuestions}
                        title={title} error={error} setError={setError} pollId={pollId } hasSystemAdmineOrSupportRole={hasSystemAdmineOrSupportRole}
                        setLoading={setLoading} setMessage={setMessage} isEdit={editQuestion !== null ? true : false}
                        hasPermission={hasPermission} deletedOption={deletedOption} setDeletedOption={setDeletedOption }/>
                </Stack>
                <Divider flexItem sx={{ marginY: 2 }} />
                <Stack id="setting" ref={settingsRef} minHeight={100} m={1} className={`poll-panel ${QuestionMenu.settings === selectedMenu ? 'poll-panel-active' : ''}`}>
                    <Setting question={question} setQuestion={setQuestion} error={error} hasPermission={hasPermission} title={title} 
                            hasSystemAdmineOrSupportRole={hasSystemAdmineOrSupportRole} pollClass={pollClass}/>
                </Stack>
                <Divider flexItem sx={{ marginY: 2 }} />
                {isEdit && <Fragment>
                    <Stack id="file" ref={filesRef} minHeight={200} m={1} className={`poll-panel ${QuestionMenu.files === selectedMenu ? 'poll-panel-active' : ''}`}>
                        <QuestionFile pollId={pollId} questionId={question.id} hasPermission={hasPermission} title={title} questionTitle={questionTitle }
                            questionNumber={questionNumber} filePermission={filePermission} hasSystemAdmineOrSupportRole={hasSystemAdmineOrSupportRole}/>
                    </Stack>
                    <Divider flexItem sx={{ marginY: 2 }} />
                </Fragment>
                }
                <Stack id="preview" ref={previewRef} minHeight={200} m={1} className={`poll-panel ${QuestionMenu.preview === selectedMenu ? 'poll-panel-active' : ''}`}>
                    <Typography color="primary" sx={{ fontWeight: 'bold', mb: 2 }}>{t('Preview')}</Typography>
                    <Question poll_id={pollId} voter_id={null} question={question} view={true} hasSystemAdmineOrSupportRole={hasSystemAdmineOrSupportRole}
                        showHistory={false} preview={true} showFiles={false}/>
                </Stack>
                {showResult && <Fragment>
                    <Divider flexItem sx={{ marginY: 2 }} />
                    <Stack id="result" ref={resultRef} minHeight={200} m={1} className={`poll-panel ${QuestionMenu.result === selectedMenu ? 'poll-panel-active' : ''}`}>
                        {QuestionType.Ranking !== question.questionType &&
                            <RealTimeResult options={question.options} question={question.question} totalVoterCount={totalVoterCount}
                            noOfVotedNotVoted={noOfVotedNotVoted} questionTitle={listTitle} questionNumber={questionNumber} isEditView={true} handleRefresh={handleRefresh} />}
                        {QuestionType.Ranking === question.questionType && <RankRealTimeResult
                            options={question.options.sort((a, b) => a.rank - b.rank)}
                            totalVoterCount={totalVoterCount} noOfVotedNotVoted={noOfVotedNotVoted} />}
                        {/* <Vertical /> */}
                    </Stack>
                </Fragment>}
            </Stack>}
            {loading && <EligoBackdrop show={loading}/>}
            {message.showMsg && <EligoSnackBar show={message.showMsg} message={message.message} severity={message.severity} handleResetOnClose={onMessageClose}/>}
        </Fragment>
    )
})

export default PollQuestionTabpanel;