import { Clear, Done, Edit } from '@material-ui/icons';
import { Fab, TextField, Tooltip } from '@mui/material';
import React, { Fragment, useState } from 'react';
import { ClientFields } from '../ClientStaticVar';
import { AuctionFieldSet } from '../client-style/ClientStyle';
import { AxiosEvotingInterceptor } from '../../../config/axios.interceptor';
import EligoSnackBar from '../../../common/EligoSnackBar';
import EligoBackdrop from '../../../common/EligoBackdrop';

const ClientEmail = (props) => {
    const { t, isEditEmail, isNew, setErrors, errors, classes, resetOtherEdits, loading, setLoading,
        setIsEditEmail, clientDetails, setClientDetails, validateInputOnChange, disabled, emailDisable, onBlur } = props;

    const [message, setMessage] = useState({
        show: false,
        message: '',
        severity: ''
    })

    const addEmail = () => {
        let payload = {
            id: '',
            email: clientDetails.email.email,
            preferred: true
        }
        AxiosEvotingInterceptor.post(`email/${clientDetails.personId}`, payload).then(response => {
            if (response) {
                setClientDetails(prevState => ({
                    ...prevState,
                    email: {
                        id: response.id,
                        email: response.email,
                        previousEmail: ''
                    }
                }))
                setIsEditEmail(false);
                setMessage({ showMsg: true, message: t('Added_Client_Email_Successfully'), severity: 'success' });
            }
            setLoading(false);
        }).catch(error => {
            if (error.message) {
                setMessage({ showMsg: true, message: error.message, severity: 'error' })
            } else {
                setMessage({ showMsg: true, message: t('Try_Again'), severity: 'error' })
            }
            setLoading(false);
        })
    }

    const updateEmail = () => {
        let payload = {
            id: clientDetails.email.id,
            email: clientDetails.email.email,
            preferred: true
        }
        AxiosEvotingInterceptor.put(`email/update/${clientDetails.personId}`, payload).then(response => {
            if (response) {
                setClientDetails(prevState => ({
                    ...prevState,
                    email: {
                        id: response.id,
                        email: response.email,
                        previousEmail: ''
                    }
                }))
                setIsEditEmail(false);
                setMessage({ showMsg: true, message: t('Updated_Client_Email_Successfully'), severity: 'success' });
            }
            setLoading(false);
        }).catch(error => {
            if (error.message) {
                setMessage({ showMsg: true, message: error.message, severity: 'error' })
            } else {
                setMessage({ showMsg: true, message: t('Try_Again'), severity: 'error' })
            }
            setLoading(false);
        })
    }

    const onEmailChange = (event) => {
        let value = event.target.value.trim();
        setClientDetails(prevState => ({
            ...prevState,
            email: {
                ...prevState.email,
                [event.target.id]: value.toLowerCase()
            }
        }))
        validateInputOnChange(event.target.id, value.toLowerCase(), clientDetails.email.id)
    }

    const setEditEmail = (email) => {
        setClientDetails(prevState => ({
            ...prevState,
            email: {
                ...prevState.email,
                previousEmail: email
            }
        }))
        setIsEditEmail(true);
        resetOtherEdits(ClientFields.email);
    }

    const cancelEditEmail = () => {
        setErrors({})
        setClientDetails(prevState => ({
            ...prevState,
            email: {
                ...prevState.email,
                email: prevState.email.previousEmail,
                previousEmail: ''
            }
        }))
        setIsEditEmail(false);
    }

    const updateClientEmail = async (clientEmail) => {
        if (errors.email === '' || errors.email === null) {
            setLoading(true);
            if (clientEmail.id !== null && clientEmail.id !== undefined && clientEmail.id !== '') {
                updateEmail();
            }
            else {
                addEmail();
            }
        }
    }

    return (
        // <AuctionFieldSet style={{ width: '100%' }}>
        //     {!clientDetails.loginUser && !isEditEmail && !isNew &&
        //         <Fragment>
        //             <Tooltip title={t('Edit')}>
        //                 <Fab className={classes.editFab} id='edit-email-client' style={{ float: 'right' }} onClick={() => setEditEmail(clientDetails.email.email)}
        //                     disabled={disabled}>
        //                     <Edit color='primary'  />
        //                 </Fab>
        //             </Tooltip>
        //             {/* <Tooltip title={t('Remove_Email')}>
        //                 <Fab className={classes.deleteFab} style={{ float: 'right', right: '-40px' }} onClick={() => deleteConfirmation(clientDetails.email)}>
        //                     <Delete color='error' />
        //                 </Fab>
        //             </Tooltip> */}
        //         </Fragment>
        //     }
        //     {isEditEmail &&
        //         <Fragment>
        //             <Tooltip title={t('Save')}><Fab className={classes.checkFab} id='save-email-client' style={{ float: 'right' }} onClick={() => updateClientEmail(clientDetails.email)}
        //                 disabled={errors.email !== undefined && errors.email !== '' && errors.email !== null}>
        //                 <Done style={{color: 'green'}}  />
        //             </Fab></Tooltip>
        //             <Tooltip title={t('Cancel')}><Fab className={classes.closeFab} id='cancel-email-client' style={{ float: 'right' }} onClick={() => cancelEditEmail()}>
        //                 <Clear color='error'  /></Fab></Tooltip>
        //         </Fragment>
        //     }
        <div style={{ width: '100%' }}>
            <TextField id="email" label={t('Email')}
                value={clientDetails.email.email}
                helperText={errors.email}
                error={errors.email !== undefined && errors.email !== null && errors.email !== ''}
                onChange={(event) => { onEmailChange(event) }}
                onBlur={onBlur}
                disabled={ disabled|| emailDisable}
                fullWidth variant="standard"
            />
        </div>

    //         {(loading) && <EligoBackdrop show={(loading)} invisible={false} />}
    //         {message.showMsg && <EligoSnackBar show={message.showMsg} message={message.message}
    //             severity={message.severity} />}
    //     </AuctionFieldSet>
    )
}

export default ClientEmail
