import { Card, CardContent, Divider, Link, makeStyles, Typography } from '@material-ui/core';
import { Stack } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaRupeeSign } from 'react-icons/fa';
import { GiVote } from 'react-icons/gi';
import { IoMdPricetag } from 'react-icons/io';
import ContactInfo from './ContactInfo';
import { MiniCard } from './styles/PricingStyle';

const useStyles = makeStyles(() => ({
    iconbg: {
        borderRadius: '50%', background: 'rgb(236 243 245)', padding: '1rem'
    },
    icon: {
        fontSize: '4rem', color: 'rgb(67 110 189)'
    },
    cardCal: {
        padding: '0.5rem', marginTop: '-10px !important',
        boxShadow: 'rgb(0 0 0 / 5%) 0px 2px 24px', border: '1px solid #eeeeee'
    },
    cardContact: {
        padding: '1rem', boxShadow: 'rgb(0 0 0 / 5%) 0px 2px 24px',
        border: '1px solid #eeeeee', borderRadius: '12px',
        textAlign: 'center'
    },
    contactChip: {
        background: '#d6fae6',
        color: '#014703',
        marginTop: '8px',
        fontSize: 'medium'
    }
}))

const PublicPricing = (props) => {
    const { basePrice } = props;
    const classes = useStyles();
    const [dialogOpen, setDialogOpen] = useState(false);
    const { t } = useTranslation();

    return (
        <Card>
            <CardContent>
                <Stack direction='row' alignItems='center' spacing={1}>
                    <IoMdPricetag style={{ fontSize: '1.5rem' }} />
                    <Typography variant='h5'>{'Price'}</Typography>
                </Stack>
                <Divider />
                <Stack justifyContent='center' alignItems='center' spacing={2}>
                    <div className={classes.iconbg}>
                        <GiVote className={classes.icon} />
                    </div>
                    <Typography variant='h6' style={{ fontWeight: '600' }}>
                        Base Price Plan
                    </Typography>
                    <Stack direction='row' alignItems='center' spacing={1}>
                        <FaRupeeSign style={{ fontSize: '1.5rem' }} />
                        <Typography variant='h3' style={{ color: 'rgb(3 56 153)' }}>{basePrice}<span>*</span></Typography>
                        <Typography color='textSecondary'>+ GST (18%)</Typography>
                    </Stack>
                    <Typography color='textPrimary' variant='subtitle1' style={{ marginTop: '0px' }}>For First 100 Voters</Typography>
                    <Divider />
                    <Card className={classes.cardCal}>
                        <Stack direction='row' justifyContent='space-between' spacing={15} p={1}>
                            <Typography>Amount: </Typography>
                            <Typography>&#8377; 4000</Typography>
                        </Stack>
                        <Stack direction='row' justifyContent='space-between' spacing={15} p={1}>
                            <Typography>GST: </Typography>
                            <Typography>&#8377; 720</Typography>
                        </Stack>
                        <Divider />
                        <Stack direction='row' justifyContent='space-between' spacing={15} p={1}>
                            <Typography>Grand Total: </Typography>
                            <Typography>&#8377; 4720</Typography>
                        </Stack>
                    </Card>
                    <MiniCard>
                        <Stack direction='row' justifyContent='center' alignItems='center' spacing={1}>
                            <Typography align="center" color='textPrimary' component='div'>
                                {t('Please')}
                                <Link onClick={() => setDialogOpen(true)} underline='always' style={{ cursor: 'pointer', padding: '5px' }}>
                                    {t('Contact_Us')}</Link>
                                {t('For_more_than_100_voters_special_pricing')}
                            </Typography>
                        </Stack>
                    </MiniCard>
                    {dialogOpen && <ContactInfo dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} />}
                </Stack>
            </CardContent>
        </Card>
    )
}

export default PublicPricing
