import { Typography } from "@material-ui/core";
import { Close, Search } from "@material-ui/icons";
import { Autocomplete, Avatar, Button, Stack, TextField } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { BsFillPersonFill } from "react-icons/bs";
import { FaAddressCard } from "react-icons/fa";
import { HiMiniReceiptPercent } from "react-icons/hi2";
import { ImPhone } from "react-icons/im";
import { MdEmail } from "react-icons/md";
import { AxiosEvotingInterceptor } from "../../config/axios.interceptor";
import { useOutsideClick } from "../hooks/useOutsideClick";
import './PartySearch.css';
import { useTranslation } from "react-i18next";

const searchList = {
    phone_number: {
        ui: 'Mobile Number',
        db: 'phone_number'
    },
    email: {
        ui: 'Email',
        db: 'email'
    },
    name: {
        ui: 'Name',
        db: 'name'
    },
    identificationGst: {
        ui: 'GSTIN',
        db: 'identificationGst'
    },
    identificationPan: {
        ui: 'PAN',
        db: 'identificationPan'
    },
}

const IconText = ({icon, text}) => {
    return (
        <Stack direction='row' spacing={2}>
            <Stack width='10%'>{icon}</Stack>
            <Stack>{text}</Stack>
        </Stack>
    )
}

const PartySearch = (props) => {
    const { registeredUsers, personType, onSelect, isDetails, clearDetails } = props;
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const [openSearch, setOpenSearch] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedSearchBy, setSelectedSearchBy] = useState(searchList.name.db)
    const [prevSelectedSearchBy, setPrevSelectedSearchBy] = useState(searchList.name.db)
    const [searchText, setSearchText] = useState('');
    const [options, setOptions] = useState([])
    const [selectedUser, setSelectedUser] = useState(null);

    const handleClickOutside = () => {
        setOpen(false);
    }

    const ref = useOutsideClick(handleClickOutside);

    const handleSelect = (event) => {
        if (selectedSearchBy !== event.target.getAttribute('value')) {
            setSearchText('');
        }
        setSelectedSearchBy(event.target.getAttribute('value'));
        setPrevSelectedSearchBy(selectedSearchBy);
    }

    const handleOnInputSearch = (event) => {
        let value = (selectedSearchBy === 'identificationGst' || selectedSearchBy === 'identificationPan') ?
                    event.target.value.toUpperCase() : event.target.value;
        if (value == '' || selectedSearchBy != 'phone_number' || (selectedSearchBy == 'phone_number' && /^[0-9+]+$/.test(value))) {
            setSearchText(value.trimStart())
        }
    }

    const handleClear = () => {
        setSelectedUser(null);
        setSearchText('');
        clearDetails();
    }

    const executeSearch = () => {
        let payload = {
            searchBy: selectedSearchBy.includes('identification') ? 'identification' : selectedSearchBy,
            searchText: searchText,
            loginUser: registeredUsers,
            individual: personType,
            identificationType: selectedSearchBy === 'identificationGst' && 'gstin' ||
                                selectedSearchBy === 'identificationPan' && 'pan' ||
                                ''
        }
        AxiosEvotingInterceptor.post(`search/user`, payload).then(response => {
            setOptions(response.map((res) => {
                return {
                    id: res.id,
                    name: res.name,
                    phoneNumber: res.phoneNumber,
                    email: res.email,
                    identification: res.identification
                }
            }));
            setLoading(false)
        }).catch(error => {
            console.log("execution search error", error)

        })
    }

    const getUserDetails = (userId) => {
        AxiosEvotingInterceptor.get(`user/${userId}`).then(response => {
            onSelect(response);
            setLoading(true);
        }).catch(error => {
            setLoading(true);
            console.log("get User details error", error)
            // if (error.message) {
            //     setErrorMessage(error.message);
            // } else {
            //     setErrorMessage(t('Try_Again'));
            // }
            // setLoading(false);
        })
    }

    const handleOnClose =() => {
        // setOptions(options);
        setOpenSearch(false);
    }

    useEffect(() => {
        if (searchText != '' && openSearch) {
            setLoading(true)
            executeSearch()
        }
    }, [searchText]);

    useEffect(() => {
        if (prevSelectedSearchBy !== selectedSearchBy || searchText == '') {
            setOptions([]);
        }
    }, [selectedSearchBy, searchText]);

    useEffect(() => {
        if (selectedUser != '' && selectedUser != null) {
            setSearchText(selectedSearchBy === 'name' && selectedUser.name ||
                selectedSearchBy === 'email' && selectedUser.email ||
                selectedSearchBy === 'phone_number' && selectedUser.phoneNumber ||
                selectedSearchBy === 'identificationGst' && selectedUser.identification ||
                selectedSearchBy === 'identificationPan' && selectedUser.identification);
            if (isDetails) {
                setLoading(true);
                getUserDetails(selectedUser.id)
            } else {
                onSelect(selectedUser);
            }
        }
    }, [selectedUser]);

    return (
        <Stack direction="row" alignItems="center" spacing={2}>
            <div className="wrapper">
                <div className="search_box">
                    <div className="dropdown" onClick={() => setOpen(!open)} ref={ref}>
                        <div className="default_option">{searchList[selectedSearchBy].ui}</div>
                        {open && <ul onClick={handleSelect}>
                            <li value={searchList.name.db}><BsFillPersonFill />{t('Name')}</li>
                            <li value={searchList.email.db}><MdEmail />{t('Email')}</li>
                            <li value={searchList.phone_number.db}><ImPhone />{t('Phone_Number')}</li>
                            <li value={searchList.identificationGst.db}><HiMiniReceiptPercent />{t('GSTIN')}</li>
                            <li value={searchList.identificationPan.db}><FaAddressCard />{t('PAN')}</li>
                        </ul>}
                    </div>
                    <div className="search_field">
                        {/* <input type="text" className="input" placeholder="Search" /> */}
                        <Autocomplete
                            id="party-search"
                            open={openSearch}
                            onOpen={() => {
                                setOpenSearch(true);
                            }}
                            onClose={handleOnClose}
                            inputValue={searchText}
                            onChange={(event, data) => {
                                if (data !== null) {
                                    setSelectedUser(data);
                                } else {
                                    setSearchText('');
                                }
                            }}
                            size='small'
                            forcePopupIcon={false}
                            loading={loading}
                            options={options}
                            getOptionLabel={(option) => option.name + ' (' + (selectedSearchBy == 'email' || selectedSearchBy == 'name' ?  option.email : (selectedSearchBy == 'phone_number') ?  option.phoneNumber : option.identification) +  ') '}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} style={{ borderBottom: '1px solid #eaebee'}}>
                                        <Fragment>
                                            {options.length > 0 && <Stack direction='row' spacing={1.5} alignItems='center'>
                                                <Avatar className="client-avatar">
                                                    {option.name.substr(0, 1).toUpperCase()}
                                                </Avatar>
                                                <Stack spacing={0.5}>
                                                    <Typography>{option.name}</Typography>
                                                    {(selectedSearchBy == 'email' || selectedSearchBy == 'name') && <Typography color='textSecondary'>{option.email}</Typography>}
                                                    {selectedSearchBy == 'phone_number' && <Typography color='textSecondary'>{option.phoneNumber}</Typography>}
                                                    {(selectedSearchBy == 'identificationGst' || selectedSearchBy == 'identificationPan' )&& <Typography color='textSecondary'>{option.identification}</Typography>}
                                                </Stack>
                                            </Stack>}
                                        </Fragment>
                                    </li>
                                )
                            }}
                            renderInput={(params) => (
                                <Fragment>
                                    <TextField {...params} className="input"
                                        placeholder={`${t('Search_By')} ${searchList[selectedSearchBy].ui}`}
                                        value={searchText}
                                        onChange={handleOnInputSearch}
                                        sx={{
                                            ".MuiOutlinedInput-notchedOutline": { border: 'none' },
                                        }}
                                        InputProps={{
                                            ...params.InputProps,
                                            style: { border: 'none' }
                                        }} />
                                    {/* {selectedSearchBy == 'phone_number' &&
                                        <PhoneInput
                                            defaultCountry='IN'
                                            {...params} className="input"
                                            placeholder={`Search By ${searchList[selectedSearchBy].ui}`}
                                            fullWidth
                                            value={searchText}
                                            onChange={(e) => setSearchText(e)}
                                            sx={{
                                                ".MuiOutlinedInput-notchedOutline": { border: 'none' },
                                            }}
                                            InputProps={{
                                                ...params.InputProps,
                                                style: { border: 'none' }
                                            }}
                                            inputComponent={CustomPhoneNumber}
                                        />
                                    } */}
                                </Fragment>
                                )}
                            />
                            <Search className="icon"/>
                    </div>
                </div>
            </div>
            <Button endIcon={<Close />} className="clear-button" style={{width: '20%'}} variant="outlined"
                onClick={handleClear} disabled={selectedUser === null }>
                {t('Clear_Details')}
            </Button>
        </Stack>
    )
}

export default PartySearch;