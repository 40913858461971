import {
    Dialog, DialogContent, DialogContentText, Grid,
    IconButton, Table,
    TableBody,
    TableCell,
    TableContainer, TableHead,
    TableRow,
    Typography,
    makeStyles,
    withStyles
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        // color: theme.palette.grey[500],
        color: 'white'
    },
});
const useStyle = makeStyles((theme) => ({
    dialog: {
        width: '60% !important',
        marginLeft: '20% !important',
        marginTop: '4% !important',
        marginBottom: '2% !important'
    }
}))

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography id='childern' variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton id='closeIcone' aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

function CaseHistoryModal(props) {
    const { t } = useTranslation();
    const classes = useStyle();
    const [open, setOpen] = useState(props.show);
    const [voteHistory, setVoteHistory] = useState([]);
    const columns = [
        { id: 'option', label: t('Option'), minWidth: 20 },
        { id: 'chosen_on', label: t('Chosen_On'), minWidth: 22 },
        { id: 'verified_on', label: t('Verified_On'), minWidth: 22 },
        { id: 'deleted_on', label: t('Recast_On'), minWidth: 22 },
        { id: 'source_ip', label: t('IP_Address'), minWidth: 25 },
        { id: 'source_ip', label: t('Voted By'), minWidth: 25 }
    ];

    useEffect(() => {
        if (props.otpLogin) {
            axios({
                url: `${process.env.REACT_APP_AWS_GATEWAY_POLL_QUESTION_API_URL}/cast_histories/${props.pollQuestionId}/${props.voterId}`,
                method: 'GET'
            }).then((response) => {
                setHistories(response.data);
            }).catch((error) => {
            })
        } else {
            axios({
                url: `${process.env.REACT_APP_AWS_GATEWAY_POLL_QUESTION_API_URL}/cast_histories/${props.pollQuestionId}/${props.voterId}`,
                method: 'GET'
            }).then((response) => {
                console.log("response history", response)
                setHistories(response.data);
            }).catch((error) => {
            })
        }
    }, []);

    const setHistories = (histories) => {
        histories.map((history) => {
            console.log("history...", history)
            setVoteHistory(prevState => ([
                ...prevState,
                {
                    option: history.option,
                    chosen_on: history.chosen_on,
                    verified_on: history.verified_on,
                    deleted_on: history.deleted_on,
                    source_ip: history.source_ip,
                    cast_by: history.cast_by
                }
            ]))
        })
    }

    const handleClose = () => {
        setOpen(false);
        props.reset();
    }

    return (
        <Dialog onClose={handleClose} id="vote-detail-description-open" maxWidth='lg' open={open} scroll='paper' aria-labelledby="vote-detail-title"
            aria-describedby="vote-detail-description" >
            <DialogTitle id="vote-detail-title" onClose={handleClose} style={{ color: 'white', background: '#7AB4AD', textAlign: 'center' }}><span style={{ fontSize: '24px', fontWeight: 'bold' }}>{t('Vote_History')}</span></DialogTitle>
            <DialogContent dividers>
                <DialogContentText id="vote-detail-description" style={{ fontWeight: 'bolder' }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <div >Matter: {props.question}</div>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer>
                                <Table id='table' stickyHeader={true} aria-label="sticky-table-header" >
                                    <TableHead id='tableHead' style={{ background: 'red' }}>
                                        <TableRow id='table' >
                                            {columns.map((column, index) => (
                                                <TableCell id='tabelCell' key={index} style={{ width: column.minWidth + "%", background: 'lightgray', fontWeight: 'bolder' }}>
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {voteHistory.length === 0 && <TableRow>
                                            <TableCell id='norecord' colSpan={7}> {t('No_Record_Found')} </TableCell>
                                        </TableRow>
                                        }
                                        {voteHistory.length > 0 && voteHistory.map((history, index) => {
                                            console.log("cast_by", history)
                                            return (
                                                <TableRow id='tableRow' hover key={index}>
                                                    <TableCell id='history'>
                                                        {history.option}
                                                    </TableCell>
                                                    <TableCell id='chosen'>
                                                        {history.chosen_on != null ? format(new Date(history.chosen_on), process.env.REACT_APP_DATE_FORMAT_TO_DISPLAY) : '-'}
                                                    </TableCell>
                                                    <TableCell id='verified'>
                                                        {history.verified_on != null ? format(new Date(history.verified_on), process.env.REACT_APP_DATE_FORMAT_TO_DISPLAY) : '-'}
                                                    </TableCell>
                                                    <TableCell id='delete'>
                                                        {history.deleted_on != null ? format(new Date(history.deleted_on), process.env.REACT_APP_DATE_FORMAT_TO_DISPLAY) : '-'}
                                                    </TableCell>
                                                    <TableCell id='source'>
                                                        {history.source_ip != null ? history.source_ip : '-'}
                                                    </TableCell>
                                                    <TableCell id='source'>
                                                        {history.cast_by != null ? history.cast_by : '-'}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    )
}

export default CaseHistoryModal;
