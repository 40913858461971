import React from 'react';
import './Error.css';

const Error = () => {
    return (
        <div id="notfound">
            <div class="notfound">
                <div class="notfound-404">
                    <h1>404</h1>
                </div>
                <h2>Oops! The requested Page is not found</h2>
                <p>The page you are looking for does not exist, have been removed. name changed or is temporarily unavailable</p>
                <a href="/">Go To Home</a>
            </div>
        </div>
    )
}

export default Error
