import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableContainer, 
    Table, 
    TableHead, 
    TableRow, 
    TableCell,
    TableBody,
    Button,
    Grid } from '@material-ui/core';
import {format} from 'date-fns';
import NumberFormat from 'react-number-format';
import RecordPayment from './RecordPayment';
import GraphQLErrors from '../../common/GraphQLErrors';
import EligoBackdrop from '../../common/EligoBackdrop';

const PaymentList = (props) => {
    const { t } = useTranslation();
    const columns = [
        { id: 'slNo', label: t('#'), minWidth: 3, textAlign: 'left'},
        { id: 'payment_date', label: t('Payment_Date'), minWidth: 10, textAlign: 'left' },
        { id: 'payment_ref', label: t('Payment_Reference'), minWidth: 12, textAlign: 'left'},
        { id: 'mode_of_payment', label: t('Mode_of_Payment'), minWidth: 12, textAlign: 'left'},
        { id: 'no_of_voters', label: t('No_of_Voters'), minWidth: 10, textAlign: 'left'},
        { id: 'service_charge', label: t('Service_Charge'), minWidth: 14, textAlign: 'left' },
        { id: 'tax', label: t('Tax'), minWidth: 10, textAlign: 'left' },
        { id: 'amount_paid', label: t('Amount_Paid'), minWidth: 14, textAlign: 'left' },
        { id: 'balance_amount', label: t('Balance_Amount'), minWidth: 14, textAlign: 'left' },
    ];
    const [payments, setPayments] = useState([
        // {
        //     payment_date: new Date(),
        //     payment_ref: '001',
        //     mode_of_payment: 'bank transfer',
        //     no_of_voters: 150,
        //     paid_amount: 3500
        // }
    ]);

    const [showRecordPayment, setShowRecordPayment] = useState(false);

    // const [ getOfflinePayments, { loading: gettingOfflinePayments, error: errorOnGettingOfflinePayments } ] 
    //         = useLazyQuery(LIST_OFFLINE_PAYMENTS, {
    //     errorPolicy: 'all', 
    //     fetchPolicy: 'network-only',
    //     notifyOnNetworkStatusChange: true,
    //     onCompleted: (data) => {
    //         setPayments(data.paymentsList)
    //     }
    // })

    // useEffect(() => {
    //     getOfflinePayments({
    //         variables: {
    //             poll_id: props.poll_id
    //         }
    //     })
    // }, [])
    

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Button className="float-right" color="primary" size="small" variant='contained' 
                        style={{margin: '6px'}}
                        onClick={() => { setShowRecordPayment(true)}}>
                        {t('Record_Payment')}
                    </Button>
                </Grid>
            </Grid>
            <TableContainer>
                <Table id='table' stickyHeader={true} aria-label="sticky-table-header">
                    <TableHead>
                        <TableRow>
                            {columns.map((column, index) => (
                                <TableCell id='tablecell' key={index} style={{ width: column.minWidth + "%", textAlign: column.textAlign }}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {payments.length === 0 && <TableRow>
                            <TableCell id='norecord' colSpan={9}> {t('No_Record_Found')} </TableCell>
                        </TableRow>
                        }
                        {payments.length > 0 && payments.map((payment, index) => {
                            return (
                                <TableRow hover key={index}>
                                    <TableCell>
                                        {index + 1}.
                                    </TableCell>
                                    <TableCell>
                                        {format(new Date(payment.payment_date), 'dd/MM/yyyy')}
                                    </TableCell>
                                    <TableCell>
                                        {payment.payment_reference_number}
                                    </TableCell>
                                    <TableCell>
                                        {payment.mode_of_payment}
                                    </TableCell>
                                    <TableCell>
                                        <NumberFormat value={payment.no_of_voters} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh"/>
                                    </TableCell>
                                    <TableCell>
                                        <NumberFormat value={Number(payment.service_charge).toFixed(2)} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh"/>
                                    </TableCell>
                                    <TableCell>
                                        <NumberFormat value={Number(payment.tax).toFixed(2)} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh"/>
                                    </TableCell>
                                    <TableCell>
                                        <NumberFormat value={Number(payment.amount_paid).toFixed(2)} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh"/>
                                    </TableCell>
                                    <TableCell>
                                        <NumberFormat value={((Number(payment.service_charge) + Number(payment.tax)) - Number(payment.amount_paid)).toFixed(2)}
                                            displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" style={{color:'red'}}/>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {showRecordPayment && <RecordPayment show={showRecordPayment} poll_id={props.poll_id} close={setShowRecordPayment}/>}
        </div>
    )
}

export default PaymentList