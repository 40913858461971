import { Fragment, useContext, useState } from "react";
import { UserContext } from "../../common/context/UserContext";
import { makeStyles, styled } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Box, FormControl, FormControlLabel, FormLabel, Paper, Radio, RadioGroup, Stack, Typography } from "@mui/material";
import { Business, Close, Email, Person, Phone } from "@material-ui/icons";
import MuiPhoneNumber from "material-ui-phone-number";
import { AxiosEvotingInterceptor, AuthAxiosInterceptor } from "../../config/axios.interceptor";
import { Button, Grid, IconButton, TextField, Tooltip } from "@material-ui/core";
import EligoSnackBar from "../../common/EligoSnackBar";
import EligoBackdrop from "../../common/EligoBackdrop";
import { KeyboardDateTimePicker, MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import EnableMFA from '../../authentication/mfa-verification/EnableMFA';
import { useHistory } from 'react-router-dom';
import EligoConfirmDialog from '../../common/EligoConfirmDialog'


const useStyles = makeStyles((theme) => ({
    checkFab: {
        width: '34px !important',
        height: '34px !important',
        margin: '-25px !important',
        background: theme.palette.type === 'light' ? '#f0ecec !important' : '#3c3d3d !important'
    },
    closeFab: {
        width: '34px !important',
        height: '34px !important',
        margin: '-25px 15px !important',
        background: theme.palette.type === 'light' ? '#f0ecec !important' : '#3c3d3d !important'
    },
    editFab: {
        width: '34px !important',
        height: '34px !important',
        margin: '-25px !important',
        background: theme.palette.type === 'light' ? '#f0ecec !important' : '#3c3d3d !important',
        '&[disabled]': {
            pointerEvent: 'none',
            opacity: '0.5'
        }
    },
    disableColor: {
        '& .MuiInputBase-root.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.7) !important'
        },
        '& .MuiFormLabel-root.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.7) !important'
        },
        '& .MuiIconButton-root.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.5) !important'
        },
        '& .MuiChip-root.Mui-disabled': {
            opacity: '0.9 !important'
        }
    }
}))

export const FormControlRadioLabel = styled(FormControlLabel)(({ selected }) => ({
    borderRadius: 25,
    border: selected ? '2px solid #1976d2' : '2px solid #e1e2e7',
    backgroundColor: selected ? '#def0ff' : 'inherit',
    marginLeft: '0px !important',
    '& .icon': {
        color: selected ? '#1976d2' : 'currentColor'
    },
    '& .text': {
        color: selected ? '#1976d2' : 'currentColor'
    },
    '&[disabled]': {
        pointerEvents: 'none !important',
        opacity: 0.7
    }
}))

const AccountInfo = (props) => {
    const history = useHistory();
    const {t} = useTranslation();
    const classes = useStyles();
    const userContext = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [isEdit, setIsEdit] = useState(false);
    const [openDatePicker, setOpenDatePicker] = useState(false);
    const [dateTrigger, setDateTrigger] = useState(1);
    const [message, setMessage] = useState({
        show: false,
        message: '',
        severity: ''
    })
    const [userInfo, setUserInfo] = useState({
        userId: userContext.userSession.session.userId,
        name: userContext.userSession.session.name,
        middleName: userContext.userSession.session.middleName,
        lastName: userContext.userSession.session.lastName,
        dateOfBirth: userContext.userSession.session.dateOfBirth ? userContext.userSession.session.dateOfBirth : null,
        email: userContext.userSession.session.email,
        phoneNumber: userContext.userSession.session.phone_number,
        individual: userContext.userSession.session.individual
    });
    const [showEnableTwoAuthDialog, setShowEnableTwoAuthDialog] = useState(false);
    const [seekConfirmation, setSeekConfitmation] = useState({
        show: false,
        title: '',
        message: '',
        onAgree: ''
    })

    const resetAll = () => {
        setIsEdit(false);
        setUserInfo({
            userId: userContext.userSession.session.userId,
            name: userContext.userSession.session.name,
            middleName: userContext.userSession.session.middleName,
            lastName: userContext.userSession.session.lastName,
            dateOfBirth: userContext.userSession.session.dateOfBirth,
            email: userContext.userSession.session.email,
            phoneNumber: userContext.userSession.session.phone_number,
            individual: userContext.userSession.session.individual
        })
        setErrors({});
        setDateTrigger(dateTrigger + 1);
    }

    // const isFutureDate = (value) => {
    //     const d_now = new Date();
    //     const d_inp = new Date(value)
    //     return d_now.getTime() <= d_inp.getTime();
    // }

    const onInputChange = (fieldName, value) => {
        setUserInfo((prevState) => ({
            ...prevState,
            [fieldName]: value
        }))
        if(fieldName === "name" && value !== "") {
            setErrors(prevState => ({
                ...prevState,
                name: null
            }))
        }
    }

    const onTypeChange = (e, value) => {
        setUserInfo((prevState) => ({
            ...prevState,
            individual: value
        }))
    }

    const checkValidFields = () => {
        let valid = true;
        if (userInfo.name === "") {
            setErrors({name: "Name is required"})
            valid = false;
        }
        if (errors?.dateOfBirth !== undefined && errors?.dateOfBirth !== null) {
            valid = false;
        }
        return valid;
    }

    const handleSave = () => {
        setMessage({ show: false, message: null, severity: null });
        setLoading(true);
        let valid = checkValidFields();
        if (valid) {
            setErrors({})
            let payload = {
                firstName: userInfo.name,
                middleName: userInfo.middleName,
                lastName:  userInfo.individual ? userInfo.lastName: '',
                dateOfBirth: userInfo.dateOfBirth,
                individual: userInfo.individual
            }
            AxiosEvotingInterceptor.put(`auth/update`,
                payload,
            ).then(response => {
                setMessage({ show: true, message: t('Your_Profile_Details_Are_Updated_Successfully'), severity: 'success' })
                setTimeout(() => {
                    userContext.setUserSession(prevState => ({
                        ...prevState,
                        isAuthenticated: true, session: {
                            ...prevState.session,
                            name: response.firstName,
                            middleName: response.middleName,
                            lastName: response.lastName,
                            dateOfBirth: response.dateOfBirth,
                            email: response.email,
                            phone_number: response.phoneNumber,
                            userId: response.loginUuid,
                            individual: response.individual,
                            isMfaEnabled: response.isMfaEnabled
                        }
                    }));

                    setLoading(false);
                }, 2000)
                setIsEdit(false);
            }).catch(error => {
                setLoading(false);
                if (error.message) {
                    setMessage({ show: true, message: error.message, severity: 'error' });
                } else {
                    setMessage({ show: true, message: t('Try_Again'), severity: 'error' });
                }
            })
        } else {
            setLoading(false);
        }
    }

    const handlePress = (event) => {
        event.preventDefault();
        return false
    }

    const confirmDisableMFA = () => {
        setSeekConfitmation({
            show: true,
            title: t('Confirmation'),
            message: t('Are_You_Sure_That_You_Want_To_Disable_Two_Step_Verification'),
            onAgree: () => disableMFA(),
            onDisAgree: () => resetSeekConfirmation(),
            isCancel: true
        })
    }

    const disableMFA = () => {
        setLoading(true);
        AuthAxiosInterceptor.delete(`mfa/${userContext.userSession.session.userId}`).then(response => {
            setLoading(false);
            resetSeekConfirmation();
            onDisableMFA();
        }).catch(error => {
            resetSeekConfirmation()
            if (error.message) {
                setMessage({ show: true, message: error.message, severity: 'error' });
            } else {
                setMessage({ show: true, message: t('Try_Again'), severity: 'error' });
            }
            setLoading(false);
        })
    }

    const onEnableMFA = () => {
        setSeekConfitmation({
            show: true,
            title: t('Information'),
            message: t('Two_Step_Verification_Enabled_Successfully'),
            onAgree: () => logOut(),
            agreeBtnLabel: 'Okay',
            isCancel: false
        })
    }

    const onDisableMFA = () => {
        setSeekConfitmation({
            show: true,
            title: t('Information'),
            message: t('Two_Step_Verification_Disabled_Successfully'),
            onAgree: () => logOut(),
            agreeBtnLabel: 'Okay',
            isCancel: false
        })
    }

    const logOut = () => {
        AxiosEvotingInterceptor.post('revoke', {
            token: sessionStorage.getItem('refreshToken')
        }).then(response => {
            sessionStorage.clear();
            history.push("/sign-in")
        }).catch((error) => {
            if (error.message) {
                setMessage({showMsg: true, message: error.message, severity: 'error'});
            } else {
                setMessage({showMsg: true, message: t('Try_Again'), severity: 'error'});
            }
        })
    }

    const resetSeekConfirmation = () => {
        setSeekConfitmation({
            show: false,
            title: '',
            message: '',
            onAgree: null,
            onDisAgree: null
        })
    }

    return (
        <div>
            <Stack spacing={3} sx={{ p: 1, width: { xs: '100%' }, margin: 'auto' }}>
                <Grid container>
                    <Grid item xs={12}>
                        {!userContext.userSession.session.isMfaEnabled &&
                            <Button style={{float: 'right'}} variant='outlined' color='primary'
                                    size='small' onClick={() => setShowEnableTwoAuthDialog(true)}>
                                {t('Enable_Two_Step_Verification')}
                            </Button>}
                        {userContext.userSession.session.isMfaEnabled &&
                            <Button style={{float: 'right'}} variant='outlined' color='primary'
                                    size='small' onClick={confirmDisableMFA}>
                                {t('Disable_Two_Step_Verification')}
                            </Button>}
                    </Grid>
                </Grid>
                <Stack direction='row' width='100%' spacing={2}>
                    <Paper elevation={4} sx={{ p: 2, width: '100%', boxShadow: '0px 1px 10px -1px rgb(0 0 0 / 10%), 0 0 0 -8px rgb(0 0 0 / 4%) !important' }}>
                        {/* {!isEdit.isEditEmail && !updateUserLoading && !loading &&
                        <Tooltip title={t('Edit')} disableHoverListener={isEdit.isEditName || isEdit.isEditPhone}><Fab className={classes.editFab} style={{ float: 'right' }} onClick={setEditEmail}
                            disabled={isEdit.isEditName || isEdit.isEditPhone}>
                            <Edit color='primary' />
                        </Fab></Tooltip>
                    }
                    {isEdit.isEditEmail && !updateUserLoading && !loading &&
                        <><Tooltip title={t('Cancel')}><Fab className={classes.closeFab} style={{ float: 'right' }} onClick={cancelEditEmail}>
                                <Clear color='error' />
                    </Fab></Tooltip>
                        <Tooltip title={t('Save')}><Fab className={classes.checkFab} style={{ float: 'right' }} onClick={handleSave}>
                            <Check color='success' />
                        </Fab></Tooltip>
                    </>
                    } */}
                        <Box style={{ display: 'flex' }}>
                            <Email style={{ marginRight: '10px', marginBottom: '0.35rem', marginTop: '18px' }} />
                            <TextField
                                value={userInfo.email}
                                id="email"
                                label={t('Email')}
                                type="text"
                                // onChange={onInputChange}
                                variant="standard"
                                fullWidth
                                required={isEdit.isEditEmail}
                                disabled={!(isEdit.isEditEmail)}
                                helperText={errors?.email?.message}
                            />
                        </Box>
                    </Paper>
                    <Paper elevation={4} sx={{ p: 2, width: '100%', boxShadow: '0px 1px 10px -1px rgb(0 0 0 / 10%), 0 0 0 -8px rgb(0 0 0 / 4%) !important' }}>
                        {/* {!isEdit.isEditPhone && !updateUserLoading && !loading &&
                        <Tooltip title={t('Edit')} disableHoverListener={isEdit.isEditName || isEdit.isEditEmail}><Fab className={classes.editFab} style={{ float: 'right' }} onClick={setEditPhone}
                            disabled={isEdit.isEditName || isEdit.isEditEmail}>
                            <Edit color='primary' />
                        </Fab></Tooltip>
                    }
                    {isEdit.isEditPhone && !updateUserLoading && !loading &&
                        <><Tooltip title={t('Cancel')}><Fab className={classes.closeFab} style={{ float: 'right' }} onClick={cancelEditPhone}>
                                <Clear color='error' />
                    </Fab></Tooltip>
                        <Tooltip title={t('Save')}><Fab className={classes.checkFab} style={{ float: 'right' }} onClick={handleSave}>
                            <Check color='success' />
                        </Fab></Tooltip>
                    </>
                    } */}
                        <Box style={{ display: 'flex' }}>
                            <Phone style={{ marginRight: '10px', marginBottom: '0.35rem', marginTop: '18px' }} />
                            <MuiPhoneNumber
                                id="myprofilephone"
                                value={userInfo.phoneNumber}
                                label={t('Phone_Number')}
                                defaultCountry={"in"}
                                // onChange={onMobileNumberChange}
                                disableDropdown={!isEdit.isEditPhone}
                                helperText={errors?.phoneNumber?.message}
                                autoFormat={false} fullWidth
                                required={isEdit.isEditPhone}
                                disabled={!(isEdit.isEditPhone)}
                                variant="standard"
                                countryCodeEditable={false}
                            />
                        </Box>
                    </Paper>
                </Stack>
                <Stack sx={{
                    px: 2, py: 3,
                    boxShadow: '0px 1px 10px -1px rgb(0 0 0 / 10%), 0 0 0 -8px rgb(0 0 0 / 4%) !important'
                }} spacing={2}>
                    <Typography>{t('User_Information')}</Typography>
                    <FormControl component="fieldset">
                        <FormLabel component="legend"><Typography variant='subtitle2'>{t('User_Type')}</Typography></FormLabel>
                        <RadioGroup value={userInfo.individual}>
                            <Stack direction='row' spacing={2}>
                                <FormControlRadioLabel
                                    value={false}
                                    selected={!userInfo.individual}
                                    disabled={!isEdit}
                                    onChange={(e) => onTypeChange(e, false)}
                                    checked={userInfo.individual === false}
                                    control={<Radio style={{ margin: 'auto' }} />}
                                    disableTypography
                                    label={<span style={{ paddingRight: '10px' }}>
                                        {/* <Business className="icon" /> */}
                                        {t('Institution')}</span>}
                                />
                                <FormControlRadioLabel
                                    value={true}
                                    selected={userInfo.individual}
                                    disabled={!isEdit}
                                    onChange={(e) => onTypeChange(e, true)}
                                    checked={userInfo.individual === true}
                                    control={<Radio style={{ margin: 'auto' }} />}
                                    disableTypography
                                    label={<span style={{ paddingRight: '10px' }}>
                                        {/* <Person className="icon" /> */}
                                        {t('Individual')}</span>}
                                />
                            </Stack>
                        </RadioGroup>
                    </FormControl>

                    <Stack direction="row" spacing={2} alignItems="center" >
                        <Stack sx={userInfo.individual ? {flexBasis: '33%',flexGrow: 1}: {flexBasis: '50%',flexGrow: 1}}>
                            <TextField
                                value={userInfo.name}
                                id="myprofilename"
                                label={userInfo.individual ? t('First_Name') : t('Name')}
                                onChange={(event) => {
                                    if (event.target.value === "") {
                                        setErrors({name: "Name is required"})
                                    }
                                    let value = event.target.value.trimStart();
                                    onInputChange('name', value.replace(/  +/g, ' '))
                                }}
                                type="text"
                                variant="standard"
                                fullWidth
                                required={isEdit}
                                disabled={!isEdit}
                                helperText={errors?.name !== undefined && errors?.name !== null && errors?.name}
                                error={errors?.name !== undefined && errors?.name !== null}
                            />
                        </Stack>
                        <Stack sx={userInfo.individual ? {flexBasis: '33%',flexGrow: 1}: {flexBasis: '0%',flexGrow: 1}}>
                            {userInfo.individual && <TextField
                                value={(!isEdit && userInfo.lastName === '') ? '-' : userInfo.lastName}
                                id="myprofilelastname"
                                label={t('Last_Name')}
                                onChange={(e) => onInputChange("lastName", e.target.value)}
                                type="text"
                                variant="standard"
                                fullWidth
                                disabled={!isEdit}
                                helperText={errors?.lastName?.message}
                            />}
                        </Stack>
                        <Stack sx={userInfo.individual ? {flexBasis: '33%',flexGrow: 1}: {flexBasis: '50%',flexGrow: 1}}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} key={dateTrigger}>
                                <DatePicker
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    clearLabel='Clear'
                                    clearable
                                    autoOk
                                    label={userInfo.individual ? t('Date_of_Birth') : t('Date_of_Incorporation')}
                                    value={userInfo.dateOfBirth}
                                    placeholder="Select the date"
                                    onChange={(event) => {
                                            if (event == 'Invalid Date') {
                                                setErrors(prevState => ({ ...prevState, dateOfBirth: "Invalid Date" }))
                                            } else {
                                                onInputChange('dateOfBirth', event);
                                                setErrors(prevState => ({ ...prevState, dateOfBirth: null }))
                                            }
                                        }}
                                    onAccept={(event) => onInputChange('dateOfBirth', event)}
                                    maxDate={new Date()}
                                    disableOpenPicker={!isEdit}
                                    disabled={!isEdit}
                                    id="dobDoi-picker"
                                    helperText={errors?.dateOfBirth !== undefined && errors?.dateOfBirth !== null && errors?.dateOfBirth}
                                    error={errors?.dateOfBirth !== undefined && errors?.dateOfBirth !== null}
                                    InputProps={{
                                        endAdornment:
                                            <Fragment>
                                                {(isEdit && userInfo.dateOfBirth !== null) &&
                                                    <Tooltip title={userInfo.individual ? t('Clear_Date_Of_Birth') : t('Clear_Date_Of_Incorporation')} arrow>
                                                        <IconButton onClick={() => {onInputChange('dateOfBirth', null);}} size='small'>
                                                            <Close color='primary' />
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                            </Fragment>
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Stack>
                    </Stack>
                    <Stack direction='row' justifyContent='center' alignItems='center' spacing={2}>
                        {!isEdit && <Button variant='contained' color='primary' onClick={() => { setIsEdit(true) }}>
                            {t('Edit')}
                        </Button>}
                        {isEdit && <Fragment>
                            <Button variant='outlined' color='primary' onClick={resetAll}>
                                {t('Cancel')}
                            </Button>
                            <Button variant='contained' color='primary' onClick={handleSave}>
                                {t('Save')}
                            </Button>
                        </Fragment>}
                    </Stack>
                </Stack>
            </Stack>
            {message.show && <EligoSnackBar show={message.show} message={message.message} severity={message.severity} />}
            {loading && <EligoBackdrop show={loading} invisible={false} />}
            {showEnableTwoAuthDialog && <EnableMFA show={showEnableTwoAuthDialog}
                    userId={userContext.userSession.session.userId} onClose={() => setShowEnableTwoAuthDialog(false)}
                    onSuccess={onEnableMFA}/>}
            {seekConfirmation.show && <EligoConfirmDialog show={seekConfirmation.show}
                title={seekConfirmation.title} message={seekConfirmation.message} onAgree={seekConfirmation.onAgree}
                onDisAgree={seekConfirmation.onDisAgree} isCancel={seekConfirmation.isCancel}
                agreeBtnLabel={seekConfirmation.agreeBtnLabel}
            />}
        </div>
    )
}

export default AccountInfo;