import React                            from 'react';
import {
    FormControlLabel,
    Grid,
    Typography
}                                       from '@material-ui/core';
import { useTranslation }               from 'react-i18next';
import IOSSwitch                        from '../../../common/Switch';

const PollSettings = (props) => {
    const { t } = useTranslation();

    const handleDataBoolean = (event) => {
        props.handleDataBoolean(event)
    }

    return (
        <Grid container spacing={2} style={{padding: '4px'}}>
            {/* <Grid item xs={6}>
                <Grid container direction="row" alignItems="center">
                    <Grid item xs={10}>
                        <Typography variant="subtitle2" style={{fontWeight: 'bold'}}>{t('Allow_Voter_to_Vote_With_SignUp')}</Typography>
                        <Typography variant="caption" display="block" style={{color: 'gray'}}>{t('Allow_Voter_to_Vote_With_SignUp_Helper_Text')}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControlLabel checked={props.pollDetail.voteWithSignUp} onChange={handleDataBoolean}
                            control={<IOSSwitch sx={{ m: 1 }}  id="voteWithSignUp"/>}
                            disabled={props.disabled} style={{float: 'right'}}/>
                    </Grid>
                </Grid>
            </Grid> */}
            {/* <Grid item xs={6}>
                <Grid container direction="row" alignItems="center">
                    <Grid item xs={10}>
                        <Typography variant="subtitle2" style={{fontWeight: 'bold'}}>{t('Allow_Recast')}</Typography>
                        <Typography variant="caption" display="block" style={{color: 'gray'}}>{t('Allow_Recast_Helper_Text')}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControlLabel checked={props.pollDetail.allowVoteChange} onChange={handleDataBoolean}
                            control={<IOSSwitch sx={{ m: 1 }}  id="allowVoteChange"/>}
                            disabled={props.disabled} style={{float: 'right'}}/>
                    </Grid>
                </Grid>
            </Grid> */}
            <Grid item xs={6}>
                <Grid container direction="row" alignItems="center">
                    <Grid item xs={10}>
                        <Typography variant="subtitle2" style={{fontWeight: 'bold'}}>{t('Show_organizer_details')}</Typography>
                        <Typography variant="caption" display="block" style={{color: 'gray'}}>{t('Display_Organizer_Details_Helper_Text')}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControlLabel checked={props.pollDetail.displayOrganizerName} onChange={handleDataBoolean}
                            control={<IOSSwitch sx={{ m: 1 }}  id="displayOrganizerName"/>}
                            disabled={props.disabled} style={{float: 'right'}}/>
                    </Grid>
                </Grid>
            </Grid>
            {/* <Grid item xs={6}>
                <Grid container direction="row" alignItems="center">
                    <Grid item xs={10}>
                        <Typography variant="subtitle2" style={{fontWeight: 'bold'}}>{t('Only_Scrutinizer_Should_Open_Result')}</Typography>
                        <Typography variant="caption" display="block" style={{color: 'gray'}}>{t('Scrutinizer_Has_To_Open_Helper_Text')}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControlLabel checked={props.pollDetail.scrutinizerHasToOpen} onChange={handleDataBoolean}
                            control={<IOSSwitch sx={{ m: 1 }}  id="scrutinizerHasToOpen"/>}
                            disabled={props.disabled} style={{float: 'right'}}/>
                    </Grid>
                </Grid>
            </Grid> */}
            <Grid item xs={6}>
                <Grid container direction="row" alignItems="center">
                    <Grid item xs={10}>
                        <Typography variant="subtitle2" style={{fontWeight: 'bold'}}>{t('Should_Verify_With_OTP')}</Typography>
                        <Typography variant="caption" display="block" style={{color: 'gray'}}>{t('Verify_With_OTP_Helper_Text')}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControlLabel checked={props.pollDetail.verifyWithOtp} onChange={handleDataBoolean}
                            control={<IOSSwitch sx={{ m: 1 }}  id="verifyWithOtp"/>}
                            disabled={props.disabled} style={{float: 'right'}}/>
                    </Grid>
                </Grid>
            </Grid>
            {/* <Grid item xs={6}>
                <Grid container direction="row" alignItems="center">
                    <Grid item xs={10}>
                        <Typography variant="subtitle2" style={{fontWeight: 'bold'}}>{t('Matter_Shuffling')}</Typography>
                        <Typography variant="caption" display="block" style={{color: 'gray'}}>{t('Matter_Shuffling_Helper_Text')}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControlLabel checked={props.pollDetail.questionShuffle} onChange={handleDataBoolean}
                            control={<IOSSwitch sx={{ m: 1 }}  id="questionShuffle"/>}
                            disabled={props.disabled} style={{float: 'right'}}/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={6}>
                <Grid container direction="row" alignItems="center">
                    <Grid item xs={10}>
                        <Typography variant="subtitle2" style={{fontWeight: 'bold'}}>{t('Show_Real_Time_Result')}</Typography>
                        <Typography variant="caption" display="block" style={{color: 'gray'}}>{t('Show_Real_Time_Result_Helper_Text')}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControlLabel checked={props.pollDetail.realTimeResult} onChange={handleDataBoolean}
                            control={<IOSSwitch sx={{ m: 1 }}  id="realTimeResult"/>}
                            disabled={props.disabled} style={{float: 'right'}}/>
                    </Grid>
                </Grid>
            </Grid> */}
        </Grid>
    )
}

export default PollSettings