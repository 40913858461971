import axios from "axios";
import { encrypt, decrypt } from './encrypt-decrypt';

const HttpStatus = {
    NOT_MODIFIED: 304
}

export const AuthAxiosInterceptor = axios.create({
    baseURL: process.env.REACT_APP_AUTHORIZATION_ENDPOINT,
    headers: { Accept: 'application/json' }
})

export const APIEvotingAxiosDownloadInterceptor = axios.create({
    baseURL: process.env.REACT_APP_API_EVOTING_ENDPOINT,
    responseType: 'arraybuffer'

})

export const AxiosEvotingUploadInterceptor = axios.create({
    baseURL: process.env.REACT_APP_EVOTING_ENDPOINT,
})

export const AxiosEvotingInterceptor = axios.create({
    baseURL: process.env.REACT_APP_EVOTING_ENDPOINT,
    headers: { Accept: 'application/json' }
})

export const PublicAxiosInterceptor = axios.create({
    baseURL: process.env.REACT_APP_EVOTING_ENDPOINT,
    // headers: { Accept: 'application/json' }
})

export const PublicAPIAxiosInterceptor = axios.create({
    baseURL: process.env.REACT_APP_API_EVOTING_ENDPOINT,
    // headers: { Accept: 'application/json' }
})

export const APIEvotingAxiosInterceptor = axios.create({
    baseURL: process.env.REACT_APP_API_EVOTING_ENDPOINT
})

export const LookUpAxiosEvotingInterceptor = axios.create({
    baseURL: process.env.REACT_APP_API_LOOK_UP_ENDPOINT,
    headers: { Accept: 'application/json' }
})

PublicAxiosInterceptor.interceptors.request.use((config) => {
    if (config.data) {
        config.data = encrypt(JSON.stringify(config.data));
    }
    return config
}, (error) => {
    return Promise.reject(error)
})

PublicAPIAxiosInterceptor.interceptors.request.use((config) => {
    return config
}, (error) => {
    return Promise.reject(error)
})

AxiosEvotingUploadInterceptor.interceptors.request.use((config) => {
    config.headers['Authorization'] = 'Bearer ' + sessionStorage.getItem('token');
    if (config.data) {
        config.data = encrypt(JSON.stringify(config.data));
    }
    return config
}, (error) => {
    return Promise.reject(error)
})

APIEvotingAxiosDownloadInterceptor.interceptors.request.use((config) => {
    config.headers['Authorization'] = 'Bearer ' + sessionStorage.getItem('token');
    return config
}, (error) => {
    return Promise.reject(error)
})

AxiosEvotingInterceptor.interceptors.request.use((config) => {
    config.headers['Authorization'] = 'Bearer ' + sessionStorage.getItem('token');
    config.headers['Content-Type'] = 'application/json';
    if (config.data) {
        config.data = encrypt(JSON.stringify(config.data));
    }
    return config
}, (error) => {
    return Promise.reject(error)
})

AuthAxiosInterceptor.interceptors.request.use((config) => {
    config.headers['Content-Type'] = 'application/json';
    if (config.data) {
        config.data = encrypt(JSON.stringify(config.data));
    }
    return config
}, (error) => {
    return Promise.reject(error)
})

AuthAxiosInterceptor.interceptors.response.use((response) => {
    return decrypt(response.data);
}, (error) => {
    error.response.data = decrypt(error.response.data);
    if (error.response.data?.message) {
        return Promise.reject({ message: error.response.data.message });
    } else {
        return Promise.reject(error);
    }
})

PublicAxiosInterceptor.interceptors.response.use((response) => {
    return decrypt(response.data);
}, (error) => {
    error.response.data = decrypt(error.response.data);
    if (error.config.url === 'token' && error.response.status === 401) {
        return Promise.reject({ message: 'refresh-token-expired' })
    } else if (error.response.data?.errorMessage) {
        return Promise.reject({ message: error.response.data.errorMessage });
    } else if (error.response.data?.message) {
        return Promise.reject({ message: error.response.data.message });
    } else {
        return Promise.reject(error);
    }
})

PublicAPIAxiosInterceptor.interceptors.response.use((response) => {
    return response.data;
}, (error) => {
    if (error.config.url === 'token' && error.response.status === 401) {
        return Promise.reject({ message: 'refresh-token-expired' })
    } else if (error.response.data?.errorMessage) {
        return Promise.reject({ message: error.response.data.errorMessage });
    } else if (error.response.data?.message) {
        return Promise.reject({ message: error.response.data.message });
    } else {
        return Promise.reject(error);
    }
})

AxiosEvotingUploadInterceptor.interceptors.response.use((response) => {
    return decrypt(response.data);
}, (error) => {
    error.response.data = decrypt(error.response.data);
    let originalRequest = error.config;
    if (error.response.status === 401) {
        PublicAxiosInterceptor.post('token', {
            'refresh_token': sessionStorage.getItem('refreshToken')
        }).then(response => {
            sessionStorage.setItem('token', response.access_token);
            originalRequest.headers['Authorization'] = 'Bearer ' + response.access_token;
            return AxiosEvotingUploadInterceptor(originalRequest);
        }).catch(error => {
            if (error.message && error.message === 'refresh-token-expired') {
                sessionStorage.clear();
                window.location.replace('/sign-in')
            }
        })
    } else if (error.response.status === HttpStatus.NOT_MODIFIED) {
        return Promise.reject({ message: "Not Modified" });
    } else if (error.response.data?.errorMessage) {
        return Promise.reject({ message: error.response.data.errorMessage });
    } else if (error.response.data?.message) {
        return Promise.reject({ message: error.response.data.message });
    } else {
        return Promise.reject(error);
    }
})


APIEvotingAxiosDownloadInterceptor.interceptors.response.use((response) => {
    return response.data;
}, (error) => {
    error.response.data = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(error.response.data)));
    let originalRequest = error.config;
    if (error.response.status === 401) {
        PublicAxiosInterceptor.post('token', {
            'refresh_token': sessionStorage.getItem('refreshToken')
        }).then(response => {
            sessionStorage.setItem('token', response.access_token);
            originalRequest.headers['Authorization'] = 'Bearer ' + response.access_token;
            return APIEvotingAxiosDownloadInterceptor(originalRequest);
        }).catch(error => {
            if (error.message && error.message === 'refresh-token-expired') {
                sessionStorage.clear();
                window.location.replace('/sign-in')
            }
        })
    } else if (error.response.status === HttpStatus.NOT_MODIFIED) {
        return Promise.reject({ message: "Not Modified" });
    } else if (error.response.data?.errorMessage) {
        return Promise.reject({ message: error.response.data.errorMessage });
    } else if (error.response.data?.message) {
        return Promise.reject({ message: error.response.data.message });
    } else {
        return Promise.reject(error);
    }
})

AxiosEvotingInterceptor.interceptors.response.use((response) => {
    return decrypt(response.data);
}, (error) => {
    error.response.data = decrypt(error.response.data);
    let originalRequest = error.config;
    if (error.response.status === 401) {
        PublicAxiosInterceptor.post('token', {
            'refresh_token': sessionStorage.getItem('refreshToken')
        }).then(response => {
            sessionStorage.setItem('token', response.access_token);
            originalRequest.headers['Authorization'] = 'Bearer ' + response.access_token;
            return AxiosEvotingInterceptor(originalRequest);
        }).catch(error => {
            if (error.message && error.message === 'refresh-token-expired') {
                sessionStorage.clear();
                window.location.replace('/sign-in')
            }
        })
    } else if (error.response.status === HttpStatus.NOT_MODIFIED) {
        return Promise.reject({ message: "Not Modified" });
    } else if (error.response.data?.errorMessage) {
        return Promise.reject({ message: error.response.data.errorMessage });
    } else if (error.response.data?.message) {
        return Promise.reject({ message: error.response.data.message });
    } else {
        return Promise.reject(error);
    }
})

APIEvotingAxiosInterceptor.interceptors.request.use((config) => {
    config.headers['Authorization'] = 'Bearer ' + sessionStorage.getItem('token');
    // config.headers['Content-Type'] = 'application/json';
    return config
}, (error) => {
    return Promise.reject(error)
})

LookUpAxiosEvotingInterceptor.interceptors.request.use((config) => {
    config.headers['Authorization'] = 'Bearer ' + sessionStorage.getItem('token');
    // config.headers['Content-Type'] = 'application/json';
    return config
}, (error) => {
    return Promise.reject(error)
})

APIEvotingAxiosInterceptor.interceptors.response.use((response) => {
    return response.data;
}, (error) => {
    let originalRequest = error.config;
    if (error.response.status === 401) {
        PublicAxiosInterceptor.post('token', {
            'refresh_token': sessionStorage.getItem('refreshToken')
        }).then(response => {
            sessionStorage.setItem('token', response.access_token);
            originalRequest.headers['Authorization'] = 'Bearer ' + response.access_token;
            return AxiosEvotingInterceptor(originalRequest);
        }).catch(error => {
            if (error.message && error.message === 'refresh-token-expired') {
                sessionStorage.clear();
                window.location.replace('/sign-in')
            }
        })
    } else if (error.response.status === HttpStatus.NOT_MODIFIED) {
        return Promise.reject({ message: "Not Modified" });
    } else if (error.response.data?.errorMessage) {
        return Promise.reject({ message: error.response.data.errorMessage });
    } else if (error.response.data?.message) {
        return Promise.reject({ message: error.response.data.message });
    } else {
        return Promise.reject(error);
    }
})

LookUpAxiosEvotingInterceptor.interceptors.response.use((response) => {
    return response.data;
}, (error) => {
    let originalRequest = error.config;
    if (error.response.status === 401) {
        PublicAxiosInterceptor.post('token', {
            'refresh_token': sessionStorage.getItem('refreshToken')
        }).then(response => {
            sessionStorage.setItem('token', response.access_token);
            originalRequest.headers['Authorization'] = 'Bearer ' + response.access_token;
            return AxiosEvotingInterceptor(originalRequest);
        }).catch(error => {
            if (error.message && error.message === 'refresh-token-expired') {
                sessionStorage.clear();
                window.location.replace('/sign-in')
            }
        })
    } else if (error.response.status === HttpStatus.NOT_MODIFIED) {
        return Promise.reject({ message: "Not Modified" });
    } else if (error.response.data?.errorMessage) {
        return Promise.reject({ message: error.response.data.errorMessage });
    } else if (error.response.data?.message) {
        return Promise.reject({ message: error.response.data.message });
    } else {
        return Promise.reject(error);
    }
})