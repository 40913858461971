import {
    Button,
    Container,
    Grid, InputAdornment,
    Link, Paper, Popover, TextField,
    Tooltip, Typography
} from '@material-ui/core';
import LoopIcon from '@material-ui/icons/Loop';
import { Auth } from 'aws-amplify';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import { useHistory } from 'react-router-dom';
import ContactUs from '../authentication/contact-us/ContactUs';
import { UserContext } from '../common/context/UserContext';
import EligoBackdrop from '../common/EligoBackdrop';
import EligoReCaptcha from '../common/EligoReCaptcha';
import EligoSnackBar from '../common/EligoSnackBar';
import PoweredBy from './PoweredBy';

const MFAVerification = (props) => {
    const history = useHistory();
    const { t } = useTranslation();
    const [veficationCode, setVeficationCode] = useState(null);
    const [mfaType] = useState(props.mfaType ? props.mfaType : '');
    const [user, setUser] = useState(props.user ? props.user : '');
    const [loading, setLoading] = useState(false);
    const context = useContext(UserContext)
    const [captcha, setCaptcha] = useState(false);
    const [isRefresh, setIsRefresh] = useState(false);
    const [message, setMessage] = useState({
        showMsg: false,
        message: null,
        severity: ''
    })

    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClose = () => {
        setAnchorEl(null);
    };

    const showContactUs = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'contact-us-popover' : undefined;

    const handleSubmit = async event => {
        event.preventDefault();
        let code = veficationCode.replaceAll(' ', '')
        setLoading(true);
        setMessage({ showMsg: false, message: null, severity: null });
        try {
            await Auth.confirmSignIn(user, code, mfaType).then(res => {
                setLoading(false)
                context.setUserSession({
                    isAuthenticated: true, session: {
                        accessToken: res.signInUserSession.idToken.jwtToken,
                        name: res.signInUserSession.idToken.payload.name,
                        email: res.signInUserSession.idToken.payload.email,
                        phone_number: res.signInUserSession.idToken.payload.phone_number,
                        userId: res.signInUserSession.idToken.payload.sub,
                        isNeedToToggleRole: true,
                        roleCounter: 0,
                    }
                });
                sessionStorage.setItem('token', res.signInUserSession.idToken.jwtToken);
                setLoading(true)
                setTimeout(() => {
                    history.push("/polls");
                }, 2000);
            })
        } catch (error) {
            let message = null;
            setLoading(false);
            if (error.message === 'Invalid code or auth state for the user.') {
                message = 'Invalid OTP';
            } else {
                message = error.message
            }
            setMessage({ showMsg: true, message: message, severity: 'error' })
        }
    }

    const login = () => {
        if (props.reset) {
            props.reset();
        }
    }

    const resendOtp = () => {
        setLoading(true)
        try {
            Auth.signIn(props.username, props.password, {
                captcha: captcha
            }).then(res => {
                setIsRefresh(true);
                setTimeout(() => {
                    setIsRefresh(false);
                }, 1000);
                setUser(res);
                setLoading(false);
            })
        } catch (error) {
            setLoading(false)
            setMessage({ showMsg: true, message: error.message, severity: 'error' })
        }
    }

    return (
        <div>
            <Container component="main" maxWidth="xs">
                <Grid container spacing={0} alignItems="center" justify="center" style={{ minHeight: "98vh" }}>
                    <Paper>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '30px 25px' }}>
                            <img id='evoting' src={process.env.PUBLIC_URL + '/evote-logo.svg'} style={{ width: '60%', padding: '6px' }} alt="ACTA Logo" />
                            <Grid container style={{ paddingTop: '24px', paddingBottom: '12px' }}>
                                <Grid item xs={12}>
                                    <Typography id='signin' component="h1" variant="h5" gutterBottom>
                                        {t('Two_Step_Verification')}
                                    </Typography>
                                    <br />
                                    <Typography id='account-verify' variant="caption" component="p">
                                        <strong id='otp-verify'>{t('Please_Enter_The_OTP_To_Verify')}</strong><br />
                                        {props.challengeParam && <span>{('An OTP has been sent to')} {props.challengeParam.CODE_DELIVERY_DESTINATION}</span>}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <InputMask
                                            id='inputmask'
                                            mask="9 9 9 9 9 9"
                                            value={veficationCode}
                                            onChange={(event) => setVeficationCode(event.target.value)}>
                                            {() => <TextField
                                                id='otp'
                                                margin="normal"
                                                variant="outlined"
                                                placeholder="OTP"
                                                fullWidth
                                                inputProps={{ style: { textAlign: 'center', fontSize: '18px', fontWeight: 'bold' } }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <Tooltip id='resentOtp' title={t("Resend_OTP")}>
                                                            <InputAdornment position="end" className="pointer">
                                                                <LoopIcon
                                                                    id='reset-otp'
                                                                    aria-label="resend-otp"
                                                                    color='primary'
                                                                    onClick={resendOtp}
                                                                // style={{color:'blue'}}
                                                                >
                                                                </LoopIcon>
                                                            </InputAdornment>
                                                        </Tooltip>
                                                    )
                                                }}
                                            />}
                                        </InputMask>
                                    </Grid>
                                    <br />
                                    <br />
                                    <Grid item xs={12}>
                                        <Button id="button" type="submit" size="medium" variant="contained" color="primary" style={{ margin: '8px 8px 8px 0px' }} fullWidth
                                            disabled={false}>
                                            {t('Verify')}
                                        </Button>
                                    </Grid>
                                    <br />
                                    <Grid item xs={12} container justify="center">
                                        <Typography id="sign-in" variant="body2">
                                            <Link id='back-to-sign' onClick={login} className="pointer">{t('Back_To_Sign_In')}</Link>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} container style={{ paddingTop: '24px' }}>
                                        <Typography id="" variant="body2">{t('Having_Trouble_In_Receiving_OTP')}
                                            <Link id='back-to-sign' onClick={showContactUs} className="pointer"> {t('Contact_Support')} </Link>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </form>
                        </div>
                        <PoweredBy />
                    </Paper>
                </Grid>
            </Container>
            {loading && <EligoBackdrop show={loading} />}
            {message.showMsg && <EligoSnackBar show={message.showMsg} message={message.message} severity={message.severity} />}
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}>
                <ContactUs />
            </Popover>
            {!isRefresh && <EligoReCaptcha captcha={setCaptcha} refresh={setIsRefresh} />}
        </div>
    )
}

export default MFAVerification;