import React, { useState } from 'react';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const EligoSnackBar = (props) => {
    const [open, setOpen] = useState(props.show);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        if (reason === 'timeout' && props.severity === 'error') {
            return;
        }
        if (props.handleResetOnClose) {
            props.handleResetOnClose();
        }
        setOpen(false);
    };

    return (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
            anchorOrigin={{ vertical: props.insidePoll ? 'center' : 'top', horizontal: props.insidePoll ? 'center' : 'right' }}>
            <Alert onClose={handleClose} severity={props.severity}>
                {props.message}
            </Alert>
        </Snackbar>
    )
}

export default EligoSnackBar;