import { Button, Dialog, DialogTitle, FormLabel, makeStyles, Paper, Radio, RadioGroup, Tooltip } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import ErrorIcon from '@material-ui/icons/Error';
import { Stack, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoDocuments } from "react-icons/io5";
import EligoSnackBar from '../../../common/EligoSnackBar';
import FileUploadDownload from '../../file-upload-download/FileUploadDownload';
import '../CastVote.css';

const useStyles = makeStyles((theme) => ({
    dividerColor: {
        '&:hover': {
            background: '#c9ebff'
        }
    },
    matterPaper: {
        padding: '10px',
        border: '1px solid #eae7e7',
        borderLeft: `4px solid ${theme.palette.primary.main}`,
        // boxShadow: 'rgb(32 40 45 / 8%) 0px 2px 14px 0px !important'
        boxShadow: '#a6adab17 0px 13px 27px -5px, #ced3d8 0px 8px 16px -8px !important'
    },
    weightage: {
        boxShadow: 'rgb(32 40 45 / 6%) 0px 2px 10px 0px !important',
        border: '1px solid #f2eeee',
        padding: '0.5rem 0.5rem 0.5rem 1rem',
    },
    optionPaper: {
        // boxShadow: 'rgb(32 40 45 / 6%) 0px 2px 10px 0px !important',
        border: '1px solid #f2eeee',
        padding: '0.5rem 0.2rem 0.5rem 1rem',
        cursor: 'pointer',
        '&:hover': {
            // backgroundColor: `${theme.palette.primary.main} !important`,
            background: '#e8ffef',
        }
    },
    round: {
        border: '2px solid rgb(225 173 127)',
        borderRadius: '50%',
        padding: '2px 10px',
        width: 'fit-content',
        textAlign: 'center',
        fontWeight: '600',
        background: '#f6dec4',
        color: '#854710',
        fontWeight: 'bold !important'
    }
}));


const QuestionOption = (props) => {
    const { t } = useTranslation();
    const classes = useStyles()
    const [openListFile, setOpenListFile] = useState(false);
    const ref = useRef(null)
    const [question, setQuestion] = useState({
        selected_response_id: props.selected.selected_response_id,
    })
    const [len, setLen] = useState(0)
    const [message, setMessage] = useState({
        showMsg: false,
        message: 'select an option to cast vote',
        severity: null
    })


    const handleChange = (event) => {
        setTimeout(() => {
            setQuestion({ selected_response_id: event.target.value })
            props.setOptionId(event.target.value)
            setLen(len + 1)
            props.selected_option(event.target.value)
        }, 100)

        props.confirm(event.target.value)
    }
    const scrollingBottom = () => {
        if (props.isError) {
            ref.current?.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "start",
            });
        }

    };

    useEffect(() => {
        scrollingBottom();
    }, [props.scroll === true]);
    return (
        <div style={{ paddingBottom: '20px' }}>
            <Paper elevation={0} className={classes.matterPaper} style={{ background: (props.isError && question.selected_response_id == '') ? '#FFCCCB' : '' }}>
                <Stack container spacing={2} justify='center'>
                    <Stack direction='row' spacing={2} alignItems='center'>
                        <Typography id='box' component='div' className={classes.round}>
                            {props.index + 1}
                        </Typography>
                        <Typography id='question' style={{ fontWeight: 'bold', fontSize: '20px' }}>
                            {props.selected.question}
                        </Typography>

                    </Stack>
                    <Typography id='description' style={{ fontSize: '15px' }}>
                        {props.selected.question_description}
                    </Typography>
                    <Stack direction='row' spacing={2} alignItems='center' justifyContent='start'>
                        {(props.isError && question.selected_response_id == '') && <div className="error-message" ref={ref}><ErrorIcon color="secondary" /> {t('Select_An_Option_To_Cast_Vote')}</div>}
                        {/* <Typography className={classes.weightage}>{t("Weightages")}: <b>{props.selected.weightage ? props.selected.weightage : 0}</b></Typography>
                        <Typography className={classes.weightage}>{t("Required_voting_share")}: <b>{props.selected.threshold}</b></Typography> */}
                        <Button id='button' color='primary' variant='outlined' onClick={() => setOpenListFile(true)}>
                            <IoDocuments style={{ color: '#2a83ad', fontSize: '1.2rem' }} />&nbsp; {t('View_Files')}
                        </Button>
                    </Stack>
                    {openListFile && <Dialog open={openListFile} maxWidth='md' id='dialog'>
                        <DialogTitle id='dialogtitle'>
                            {`${t('Matter')} - #${props.index + 1}`} {t('Files')}
                            <Tooltip title={t('Close')} arrow id='tool'>
                                <Close id='icon' onClick={() => setOpenListFile(false)} size='large' color='error' style={{ float: 'right', cursor: 'pointer' }} />
                            </Tooltip>
                        </DialogTitle>
                        <FileUploadDownload
                            t={t}
                            poll_id={props.poll_id}
                            poll_question_id={props.selected.poll_question_id}
                            isUpload={false}
                            isDownload={true}
                            deletePermission={false}
                            otpLogin={props.otpLogin}
                            userId={props.userId}
                        />
                    </Dialog>
                    }
                    <RadioGroup id='radio' aria-label="selected-vote" name="vote" value={question.selected_response_id} onChange={handleChange}>
                        <Stack>
                            {props.selected.PollQuestionResponses.map((e, i) => {
                                return (
                                    <FormLabel htmlFor={e.poll_question_response_id} >
                                        <Paper elevation={0} className={classes.optionPaper} key={e.poll_question_response_id}
                                            style={question.selected_response_id == e.poll_question_response_id ? {
                                                background: '#e8ffef', border: '2px solid #bcdacc'
                                            } : {}}>
                                            <Stack direction='row' justifyContent='space-between' alignItems='center' xs={8}>
                                                <Typography color='textPrimary' style={{ wordBreak: 'break-all' }}>{e.option}</Typography>
                                                <Radio key={i} className='vote' id={e.poll_question_response_id} value={e.poll_question_response_id} color="primary" />
                                            </Stack>
                                        </Paper>
                                    </FormLabel>
                                )
                            })
                            }
                        </Stack>
                    </RadioGroup>

                </Stack>
                {
                    props.isError && message.showMsg && <EligoSnackBar show={message.showMsg} message={message.message}
                        severity={message.severity} />
                }
            </Paper>
        </div>
    )
}
export default QuestionOption
