import React, { useEffect, useRef, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, Fab, Stack } from '@mui/material';
import AuditFilter from './AuditFilter';
import { AxiosEvotingInterceptor } from '../../config/axios.interceptor';
import { BottomNavigation, IconButton, Slide, Tooltip, Typography, makeStyles } from "@material-ui/core";
import EligoPagination from '../../common/pagination/EligoPagination';
import { useTranslation } from 'react-i18next';
import { Close, RefreshRounded } from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';
import ReceiptIcon from '@material-ui/icons/Receipt';
import EligoBackdrop from '../../common/EligoBackdrop';
import EligoSnackBar from '../../common/EligoSnackBar';
import { format } from 'date-fns';

const moment = require("moment-timezone");

const useStyles = makeStyles((theme) => ({
  filtermsg: {
    color: theme.palette.success.main,
    marginLeft: '1.5rem',
    borderRadius: '2rem',
    width: 'fit-content',
    float: 'right',
    border: `1px solid ${theme.palette.success.main} !important`,
    padding: "0px 5px"
  },
  clear: {
    padding: '5px !important',
    marginLeft: '0px !important'
  },
  searchText: {
    borderRight: `1px solid ${theme.palette.success.main} !important`,
    paddingRight: '5px !important',
    fontSize: '14px !important'
  },
  tableHead: {
    fontWeight: "bold !important",
    fontSize: "14px !important",
    padding: '10px 15px !important',
  },
  tableCell:
    { padding: '12px 15px !important', },

  pagination: {
    paddingLeft: '10px'
  }
}));

function AuditTrail(props) {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [rows, setRows] = useState([]);
  const [auditList, setAuditList] = useState([])
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10)
  const [auditListCount, setAuditListCount] = useState(0)
  const [generateRpt, setGenerateRpt] = useState(false)
  const { t } = useTranslation();
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const containerRef = useRef(null);
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState({
    show: false,
    message: '',
    severity: ''
  })
  const [storedEvent, setStoredEvent] = useState([])
  const [storedActivity, setStoredActivity] = useState([])
  const [storedStartDate, setStoredStartDate] = useState('')
  const [storedEndDate, setStoredEndDate] = useState(new Date())
  const [storedDescription, setStoredDescription] = useState([])
  const [storedPerform, setStoredPerform] = useState([])
  const [searchClick, setSearchClick] = useState(true)
  const [prevPage, setPrevPage] = useState(1)
  const [asending, setasending] = useState('asc')

  const handleChangePage = (event, newPage) => {
    setLoading(true)
    setSearchClick(true)
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setSearchClick(true)
    setItemPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const getAuditLists = (event) => {
    setLoading(true)
    AxiosEvotingInterceptor.get(`audit/list/${pollId}?page=${page}&size=${itemPerPage}&asc=${false}&zoneId=${zoneId}`).then(response => {
      if (response) {
        console.log("auditlist", response)
        setAuditList(response.audit)
        setAuditListCount(response.count)
      }
      setLoading(false)
    }).catch((error) => {
      console.log("error", error)
      if (error.message) {
        setLoading(false)
        setMessage({ show: true, message: error.message, severity: 'error' });
      }
      else {
        setMessage({
          show: true,
          message: t('Try_Again'),
          severity: 'error'
        });
      }
    })
    setIsFilterApplied(false)
  }



  const handleClickOpen = (value) => {
    setGenerateRpt(value)
    setDialogOpen(true);
    setSearchClick(false)
    setPrevPage(page)
    setPage(1)
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleClearFilter = () => {
    setIsFilterApplied(false)
    setSearchClick(true)
  }

  const onSuccess = (message) => {
    setMessage({ show: false, message: '', severity: 'error' });
    if (message) {
      setTimeout(() => {
        setMessage(message);
      }, 100);
    }
  }

  // useEffect(() => {
  //   if (isFilterApplied) {
  //     auditFilter(storedEvent, storedActivity, storedPerform, storedDescription, storedStartDate, storedEndDate, page)
  //   } else {
  //     if (isFilterApplied) {
  //       auditList()
  //     }
  //   }
  // }, [page, itemPerPage])

  // const auditFilter = (eventName, activityName, userName, selectedDescription, startDate, endDate, page) => {
  //   let payload = {
  //     activityCd:
  //       activityName
  //     ,
  //     rowCreatedBy: userName,
  //     description: selectedDescription,
  //     startRange: startDate,
  //     endRange: endDate,
  //     eventCd:
  //       eventName
  //     ,
  //     page: page,
  //     size: itemPerPage,
  //     asc: false
  //   }
  //   AxiosEvotingInterceptor.post(`audit/filter/${pollId}?page=${page}&size=${itemPerPage}&asc=${false}`, payload).
  //     then(response => {
  //       setAuditList(response.audit)
  //       setAuditListCount(response.count)
  //       setStoredEvent(eventName)
  //       setStoredActivity(activityName)
  //       setStoredPerform(selectedDescription)
  //       setStoredStartDate(startDate)
  //       setStoredEndDate(endDate)
  //       setIsFilterApplied(true)
  //     }).catch((error) => {
  //       console.log("error", error)
  //     })
  // }

  const auditFilterList = (eventName, activityName, userName, selectedDescription, startDate, endDate, sortOrder) => {
    let payload = {
      activityCd: activityName,
      rowCreatedBy: userName,
      description: selectedDescription,
      startRange: startDate,
      endRange: endDate,
      eventCd: eventName,
      page: page,
      size: itemPerPage,
      asc: sortOrder === 'asc' ? true : false,
      zoneId: zoneId
    }
    AxiosEvotingInterceptor.post(`audit/filter/${pollId}?page=${page}&size=${itemPerPage}&asc=${sortOrder === 'asc' ? true : false}`, payload).
      then(response => {
        setAuditList(response.audit)
        setAuditListCount(response.count)
        setStoredEvent(eventName)
        setStoredActivity(activityName)
        setStoredPerform(userName)
        setStoredDescription(selectedDescription)
        setStoredStartDate(startDate)
        setStoredEndDate(endDate)
        setasending(sortOrder)
        setIsFilterApplied(true)
      }).catch((error) => {
        console.log("error", error)
      })
  }

  useEffect(() => {
    if (isFilterApplied === true) {
      auditFilterList(storedEvent, storedActivity, storedPerform, storedDescription, storedStartDate, storedEndDate, asending)
    } else {
      getAuditLists();
    }
  }, [page, itemPerPage, isFilterApplied])

  const handleRefresh = () => {
    if(page === 1) {
      getAuditLists();
    } else {
      setTimeout(() => {
        setPage(1);
      }, 10)
    }
  }

  const pollCreateTime = props.pollDetails.createdOn
  const pollId = props.pollDetails.id
  const zoneId = props.pollDetails.zoneId

  const getShortTimeZone = () => {
    return moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('z');
  }

  return (
    <div style={{ margin: '10px' }}>
      <Stack direction='row' mb={1} justifyContent='end' alignItems='center' spacing={2}>
        {isFilterApplied && <Slide in={isFilterApplied} unmountOnExit direction='down' container={containerRef.current}>
          <div className={classes.filtermsg}>
            <Stack direction='row' justifyContent='center' alignItems='center' spacing={1}>
              <Typography className={classes.searchText} variant='body1' fontWeight='bold'>{t('Search_is_applied')}</Typography>
              <Tooltip title="Clear">
                <IconButton className={classes.clear} id='clear-filter'
                  color="secondary"
                  onClick={handleClearFilter}>
                  <Close id='close-type' color='error' />
                </IconButton>
              </Tooltip>
            </Stack>
          </div>
        </Slide>}
        <Tooltip title={t('Refresh')} arrow>
          {/* <Fab onClick={handleRefresh} size='small' sx={{background: '#fff'}}> */}
            <RefreshRounded onClick={handleRefresh} style={{ color: 'orangered', background:'white', borderRadius:'40px', padding:'10px',cursor:'pointer', 
                            boxShadow:'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }} />
          {/* </Fab> */}
        </Tooltip>
        <Button size='small' id='search-button' float='right' variant="contained" color='primary' onClick={() => handleClickOpen(false)}>
          <SearchIcon />&nbsp;
          {t('Search')}
        </Button>
        <Button size='small' id='report-button' float='right' variant="contained" color='primary' onClick={() => handleClickOpen(true)}>
          <ReceiptIcon style={{ fontSize: "21px" }} />&nbsp;
          {t('Generate_Report')}
        </Button>
      </Stack>
      {/* <Paper style={{ height: 'fit-content' }}> */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead id='tablecell' align="left" >
            <TableRow id='tablerow' style={{ backgroundColor: "#dae2ef" }}>
              <TableCell align="left" className={classes.tableHead}>{t('Activity_Event')}</TableCell>
              <TableCell align="left" className={classes.tableHead}>{t('Description')}</TableCell>
              <TableCell align="left" className={classes.tableHead}>{t('IP_Address')}</TableCell>
              <TableCell align="left" className={classes.tableHead}>{t('Performed_By')}</TableCell>
              <TableCell align="left" className={classes.tableHead}>{t('Event_Time')} ( {getShortTimeZone()} )</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { auditList.length > 0 ?
            auditList.map((row, index) => (
              <TableRow key={index}>
                <TableCell align='left' className={classes.tableCell} component="th" scope="row">{row.activityLocale}  /  {`${row.eventLocale}`} </TableCell>
                <TableCell align="left" className={classes.tableCell}>{row.description}</TableCell>
                <TableCell align='left' className={classes.tableCell}>{row.ipAddress}</TableCell>
                <TableCell align="left" className={classes.tableCell}>{row.rowCreatedByInString}</TableCell>
                <TableCell align="left" className={classes.tableCell}>{format(new Date(row.rowCreatedOn), process.env.REACT_APP_DATE_FORMAT_TO_DISPLAY)} 
                  {/* {row.rowCreatedOnInIndianZone} */}
                </TableCell>
              </TableRow>
            )) : <TableRow><TableCell id='colspan' colSpan={7}>{t('No_Record_Found')}</TableCell></TableRow>}
          </TableBody>
        </Table>
      </TableContainer>
      {message.show && <EligoSnackBar show={message.show} message={message.message} severity={message.severity} />}
      {auditList.length > 0 && <BottomNavigation className={classes.pagination}>
        <EligoPagination rowsPerPage={itemPerPage} page={page} totalItems={auditListCount}
          onRowsPerPageChange={handleChangeRowsPerPage} onPageChange={handleChangePage} />
      </BottomNavigation>
      }
      {/* </Paper> */}
      {loading && <EligoBackdrop show={true} />}
      {dialogOpen && <AuditFilter dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} pollCreateTime={pollCreateTime} pollId={pollId} generateRpt={generateRpt}
        auditList={auditList} setAuditList={setAuditList} auditListCount={auditListCount} setAuditListCount={setAuditListCount} onSuccess={onSuccess}
        prevPage={prevPage} setPrevPage={setPrevPage} setSearchClick={setSearchClick} storedStartDate={storedStartDate} asending={asending} storedPerform={storedPerform}
        page={page} setPage={setPage} auditFilterList={auditFilterList} isFilterApplied={isFilterApplied} setIsFilterApplied={setIsFilterApplied}
        storedActivity={storedActivity} storedDescription={storedDescription} storedEndDate={storedEndDate} storedEvent={storedEvent}
        zoneId={zoneId}/>}
    </div>
  );
}

export default AuditTrail