import { Button, styled } from "@material-ui/core";
import { Dialog, DialogTitle, Stack, Tooltip, Typography } from "@mui/material";
import { Fragment, memo, useState } from "react";
import { useTranslation } from "react-i18next";
import FileUploadDownload from "../../../file-upload-download/FileUploadDownload";
import { Close } from "@material-ui/icons";

export const FieldSet = styled('fieldset')(({ theme }) => ({
    background: theme.palette.background.paper,
    borderRadius: '10px',
    padding: '1rem 1.5rem',
    border: '1px solid rgb(220 215 215)',
    boxShadow: '0 2px 24px rgb(0 0 0 / 5%)'
}))

export const Legend = styled('legend')(({ theme }) => ({
    background: 'rgb(123 67 151 / 8%)',
    color: 'rgb(123, 67, 151)',
    borderRadius: '10px',
    padding: '5px 10px',
    fontSize: '12px'
}))

const QuestionFile = (props) => {
    const { pollId, questionId, hasPermission, title, questionNumber, filePermission } = props;
    const {t} = useTranslation();
    const [openUploadFile, setOpenUploadFile] = useState(false);
    const [openListFile, setOpenListFile] = useState(false);
    const [totalFiles, setTotalFile] = useState(0);

    const handleUploadDone = () => {
        setOpenUploadFile(false);
        // setMessage({ showMsg: true, message: `${t('Q')}.#${1} files uploaded successfully`, severity: 'success' })
    }

    const handleUploadClose = () => {
        setOpenUploadFile(false);
    }

    return (
        <Fragment>
            <Typography color="primary" sx={{ fontWeight: 'bold' }}>{title} Files</Typography>
            <Stack alignItems='center' justifyContent='center'>
                <FieldSet>
                    {/* <Legend>{`${t('M')}. #${props.index + 1}`} {t('Files')}</Legend> */}
                    <Stack spacing={2} direction='row' alignItems='center'>
                        <Button color='primary' style={{ paddingRight: '10px', paddingLeft: '10px' }} onClick={() => setOpenListFile(true)}>View</Button>
                        {/* {(props.status === 'open' || props.status === 'scheduled' || props.status === 'new') && */}
                        {filePermission && <Button variant='contained' size='small' color='primary' style={{ paddingRight: '18px', paddingLeft: '18px' }}
                            onClick={(e) => { e.preventDefault(); setOpenUploadFile(true); }}>Upload
                        </Button>}
                        {/* } */}
                    </Stack>
                </FieldSet>
            </Stack>
            {
                openUploadFile && <FileUploadDownload
                    t={t}
                    title={`${title}: ${questionNumber} Upload Files`}
                    poll_id={pollId}
                    poll_question_id={questionId}
                    isUpload={filePermission}
                    isDownload={false}
                    isMultiple={true}
                    handleUploadDone={handleUploadDone}
                    handleUploadClose={handleUploadClose}
                    setTotalFile={setTotalFile}
                />
            }
            {
                openListFile && <Dialog open={openListFile} maxWidth='lg'>
                    <DialogTitle>
                        {`${title}: ${questionNumber}`} {t('Files')}
                        <Tooltip title='Close' arrow>
                            <Close onClick={() => setOpenListFile(false)} size='large' color='error' style={{ float: 'right', cursor: 'pointer' }} />
                        </Tooltip>
                    </DialogTitle>
                    <FileUploadDownload
                        t={t}
                        poll_id={pollId}
                        questionTitle={title}
                        poll_question_id={questionId}
                        isUpload={false}
                        isDownload={true}
                        deletePermission={filePermission}
                    />
                </Dialog>
            }
        </Fragment>
    )
}

export default memo(QuestionFile);