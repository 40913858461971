import { Cancel, CheckCircle } from '@material-ui/icons';
import { Card, Stack, Typography } from '@mui/material';
import { blue, grey } from '@mui/material/colors';
import React, { Fragment } from 'react';

export const NumberCheck = new RegExp(/[0-9]/);
export const UpperLetter = new RegExp(/[A-Z]/);
export const LowerLetter = new RegExp(/[a-z]/);
export const SpecialChar = new RegExp(/[^A-Z a-z 0-9]/);
export const MinPwdLength = 7;

const PasswordPolicy = (props) => {
  return (
    <Fragment> {props.open ?
      <Card style={{ padding: '1rem', zIndex: 4, position: "absolute", left: props.left ? props.left : '40%' }} elevation={10}>
        Password requirements
        <div style={{ textAlign: 'left', marginTop: '0.5rem' }}>
          <div style={props.pwdLength ? { color: 'green' } : { color: 'red' }}>{props.pwdLength ? <CheckCircle style={{ fontSize: 'small' }} /> : <Cancel style={{ fontSize: 'small' }} />} Contains at least 8 characters </div>
          <div style={props.isUpper ? { color: 'green' } : { color: 'red' }}>{props.isUpper ? <CheckCircle style={{ fontSize: 'small' }} /> : <Cancel style={{ fontSize: 'small' }} />}  Contains at least 1 uppercase letter </div>
          <div style={props.isLower ? { color: 'green' } : { color: 'red' }}>{props.isLower ? <CheckCircle style={{ fontSize: 'small' }} /> : <Cancel style={{ fontSize: 'small' }} />}  Contains at least 1 lowercase letter</div>
          <div style={props.isNumber ? { color: 'green' } : { color: 'red' }}>{props.isNumber ? <CheckCircle style={{ fontSize: 'small' }} /> : <Cancel style={{ fontSize: 'small' }} />}  Contains at least 1 number</div>
          <div style={props.isSpecial ? { color: 'green' } : { color: 'red' }}>{props.isSpecial ? <CheckCircle style={{ fontSize: 'small' }} /> : <Cancel style={{ fontSize: 'small' }} />}  Contains at least 1 special character</div>
          <Stack style={{
            background: 'rgb(222, 235, 255)',
            color: 'rgb(0, 82, 204)',
            borderRadius: '3px',
            padding: '0.5rem',
            textAlign: 'left',
            marginTop: '10px'
          }} direction='column'>
            <Stack direction='row' spacing={0.5}>
              {/* <NoteAlt /> */}
              <span>Note:</span>
              <span style={{ color: grey[900] }}>Make sure your Password is Strong.</span>
            </Stack>
            <Typography style={{ color: grey[900], marginLeft: "71px" }}>Using Names, Date of Births, Locations etc. is not advisable</Typography>
          </Stack>
        </div>
      </Card> : <React.Fragment />}
    </Fragment>
  )
}

export default PasswordPolicy