import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton,
    TextField, Typography
} from "@material-ui/core";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import CloseIcon from '@material-ui/icons/Close';
import { AxiosEvotingInterceptor } from "../../../config/axios.interceptor";
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { useTranslation } from "react-i18next";
import EligoBackdrop from "../../../common/EligoBackdrop";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const ITEM_HEIGHT = 68;
const ITEM_PADDING_TOP = 2;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 350
        },
    },
};
const SearchForSmsAndEmail = (props) => {
    const { open, setOpen, pollDetail, value, prevPage, filterApplied, storedStatusCodeValue, storedEmail, storedInformationText, storedStartDate, storedEndDate } = props
    const [codeCc, setCodeCc] = useState([])
    const { t } = useTranslation();
    const [codeBcc, setCodeBcc] = useState([])
    const [codeTo, setCodeTo] = useState([])
    const [selectedEmail, setSelectedEmail] = useState(filterApplied ? storedEmail : [])
    const [selectedStatus, setSelectedStatus] = useState(filterApplied ? storedStatusCodeValue : [])
    const [status, setStatus] = useState([])
    const [startDate, setStartDate] = useState(filterApplied ? storedStartDate : pollDetail.createdOn)
    const [endDate, setEndDate] = useState(filterApplied ? storedEndDate : pollDetail.endTime)
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [informationText, setInformationText] = useState(filterApplied ? storedInformationText : '')

    const listEmailContact = (event) => {
        for (let i = 0; i <= 4; i++) {
            AxiosEvotingInterceptor.get(`audit/message-contacts/${pollDetail.id}/${i}/${true}`).
                then((response) => {
                    if (i === 1) {
                        setCodeTo(response[1])
                    } else if (i === 3) {
                        setCodeCc(response[3])
                    } else if (i === 4) {
                        setCodeBcc(response[4])
                    }
                }).catch((error) => {
                    console.log("error", error)
                })
        }
        AxiosEvotingInterceptor.get(`audit/message-cd/status`).
            then((response) => {
                response.map((value) => {
                    setStatus(prev => [
                        ...prev,
                        value.code
                    ])
                })
            }).catch((error) => {
                console.log("error", error)
            })
    }

    const listSmsContant = () => {
        AxiosEvotingInterceptor.get(`audit/message-contacts/${pollDetail.id}/${1}/${false}`).
            then((response) => {
                setCodeTo(response[1])
            }).catch((error) => {
                console.log("error", error)
            })
    }

    const handleClose = () => {
        props.setPage(prevPage)
        props.setSearchClick(true)
        setOpen(false)
    }

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedEmail(
            typeof value === 'string' ? value.split(',') : value,
        );
    }

    const handleChangeStatus = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedStatus(
            typeof value === 'string' ? value.split(',') : value,
        );
    }

    const handleDateChange = (event) => {
        setStartDate(event)
    }
    const handleEndDateChange = (event) => {
        setEndDate(event)
    }

    const searchEmails = () => {
        setLoading(true)
        const statusCodes = []
        props.setPage(1)
        const statusCodeValue = []
        if (value === 'email') {
            status.map((value, index) => {
                console.log("value..", value)
                if (selectedStatus.includes(value)) {
                    statusCodes.push(index)
                    statusCodeValue.push(value)
                }
            })
        }
        props.searchEmail(statusCodes, selectedEmail, informationText, startDate, endDate, page,statusCodeValue)
    }

    const handleInformationText = (e) => {
        setInformationText(e.target.value)
    }

    const resetFields = () => {
        setSelectedEmail([])
        setSelectedStatus([])
        setStartDate(pollDetail.createdOn)
        setEndDate(pollDetail.endTime)
        setInformationText('')
    }

    useEffect(() => {
        if (value === 'email') {
            listEmailContact()
        } else {
            listSmsContant()
        }
    }, [])

    return (
        <div>
            <Dialog open={open} onClose={handleClose} disableEnforceFocus>
                <Stack direction='row' justifyContent='space-between' style={{ padding: '5px 0px 5px 0px', background: 'rgb(231, 235, 240)' }}>
                    <Typography style={{ margin: '0.7rem 0rem 0rem 1rem', fontWeight: 'bold', fontSize: '19px' }}>{value === 'email' ? 'Email Search' : 'SMS Search'}</Typography>
                    <IconButton onClick={handleClose}>
                        <CloseIcon color="error" />
                    </IconButton>
                </Stack><br />
                <DialogContent>
                    <Stack direction='column' spacing={2}>
                        <Stack direction='row' spacing={2}>
                            <FormControl style={{ width: value === 'email' ? 300 : 700 }}>
                                <InputLabel id="emails" style={{ marginLeft: '1px' }}>To</InputLabel>
                                <Select
                                    labelId="emails"
                                    id="emailCheckbox"
                                    multiple
                                    value={selectedEmail}
                                    onChange={handleChange}
                                    input={<OutlinedInput label="To" width='40px' />}
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={MenuProps}
                                >
                                    {codeTo.map((name) => (
                                        <MenuItem key={name} value={name}>
                                            <Checkbox checked={selectedEmail.indexOf(name) > -1} />
                                            <ListItemText primary={name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            {value === 'email' && <FormControl style={{ width: 300 }}>
                                <InputLabel id="status" style={{ marginLeft: '10px' }}>Status</InputLabel>
                                <Select
                                    labelId="status"
                                    id="statusCheckbox"
                                    multiple
                                    value={selectedStatus}
                                    onChange={handleChangeStatus}
                                    input={<OutlinedInput label="Status" width='40px' />}
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={MenuProps}
                                >
                                    {status.map((name) => (
                                        <MenuItem key={name} value={name}>
                                            <Checkbox checked={selectedStatus.indexOf(name) > -1} />
                                            <ListItemText primary={name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>}
                        </Stack>
                        <Stack direction='row' spacing={2}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDateTimePicker
                                    fullWidth
                                    inputVariant="outlined"
                                    format={process.env.REACT_APP_DATE_FORMAT_TO_DISPLAY}
                                    id="startTime"
                                    label={t("Start_Date_&_Time")}
                                    value={startDate}
                                    defaultValue={pollDetail.createdOn}
                                    minDate={pollDetail.createdOn}
                                    maxDate={new Date()}
                                    onChange={(event) => handleDateChange(event)}
                                />
                            </MuiPickersUtilsProvider>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDateTimePicker
                                    fullWidth
                                    inputVariant="outlined"
                                    format={process.env.REACT_APP_DATE_FORMAT_TO_DISPLAY}
                                    id="endTime"
                                    label={t("End_Date_&_Time")}
                                    defaultValue={new Date()}
                                    value={endDate}
                                    maxDate={new Date()}
                                    minDate={startDate}
                                    onChange={(event) => handleEndDateChange(event)}
                                    helperText={endDate < startDate ? t("End_date_should_be_greater_than_Start_date") : ""}
                                    error={endDate < startDate}
                                />
                            </MuiPickersUtilsProvider>
                        </Stack>
                        <Stack direction='row' spacing={2}>
                            <TextField id="msg" variant="outlined" label='Message' value={informationText} fullWidth onChange={handleInformationText}>Message</TextField>
                        </Stack>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button id="reset" variant="outlined" onClick={resetFields}>reset</Button>
                    <Button variant="outlined" id="search" color="primary" disabled={endDate < startDate} onClick={searchEmails}>search</Button>
                </DialogActions>
                {loading && <EligoBackdrop show={true} />}
            </Dialog>
        </div >
    )
}

export default SearchForSmsAndEmail;