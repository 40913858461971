import { Button, Container, FormControlLabel, Grid, Link, Paper, TextField, Tooltip, Typography ,FormControl,
  FormHelperText,  Input,  InputAdornment,  InputLabel} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import EligoBackdrop from '../common/EligoBackdrop';
import EligoReCaptcha from '../common/EligoReCaptcha';
import EligoSnackBar from '../common/EligoSnackBar';
import { RootDiv } from '../common/evoting-styles/EvotingStyle';
import * as Validators from '../common/form-validators.js';
import ContactPopup from './contact-us/ContactPopup';
import PoweredBy from './PoweredBy';
import { useTranslation } from 'react-i18next';
import { AuthAxiosInterceptor } from '../config/axios.interceptor';
import MFAVerification from './mfa-verification/MFAVerification';
import { Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { MaterialUISwitch } from '../common/Switch';
import EmailIcon from "@material-ui/icons/Email";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import MuiPhoneNumber from "material-ui-phone-number";
import {MdAttachEmail} from "react-icons/md"
import ConfirmationPopUp from './ConfirmationPopUp';

function Login() {

    const { t } = useTranslation();
    let history = useHistory()
    const [details, setDetails] = useState({
        username: '',
        phonenumber:''
    })
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState('')
    const [captcha, setCaptcha] = useState('');
    const [isRefresh, setIsRefresh] = useState(false);
    const [isMFAEnabled, setIsMFAEnabled] = useState(false);
    const [showMFAModel, setShowMFAModel] = useState(false);

    const [value, setValue] = useState('email')
    const [message, setMessage] = useState({
        showMsg: false,
        message: null,
        severity: null
    })
    const [extension, setExtension] = useState('+91')
    const [seekConfirmation, setSeekConfirmation] = useState({
        show: false,
        title: '',
        message: '',
        onAgree: '',
        onDisAgree: '',
        isCancel: true,
        disagreeLabel: t('No'),
        agreeLabel: t('Yes')

    })

    const validate = {
        username: username => Validators.emailValidation(username),
        phonenumber: phonenumber => Validators.mobileNumberValidation(extension + phonenumber)
      }

    const handleLoginDetails = (event, newValue) => {
        setErrors('')
        setDetails({
          username:'',
          phonenumber:''
        })
        setValue(newValue === null ? value : newValue)
    }

    const onMobileNumberChange = (value) =>{
      setExtension(value)
    }

    const onInputMobileNumberChange =(event) =>{
        if (/^[0-9]+$/.test(event.target.value)) {
            setDetails(prev => ({
                ...prev,
                phonenumber: event.target.value
            }))
        } else if (event.target.value.length === 0) {
            setDetails(prev => ({
                ...prev,
                phonenumber: ''
            }))
        }
    }

    const handleSubmit = event => {
        event.preventDefault();
        validateForm().then(data => {
            if (Object.values(data.errors).length === 0) {
                checkMFA();
            } else {
                setLoading(false);
            }
        })
    }

    const checkMFA = () => {
        let payload = {
            loginId:value === 'mobile' ? extension + details.phonenumber :  details.username
        }
        setMessage({showMsg: false, message: '', severity: ''});
        setLoading(true);
        AuthAxiosInterceptor.post(`mfa/?recaptcha=${captcha}`, payload).then(response => {
            setIsMFAEnabled(response);
            setShowMFAModel(true);
            setLoading(false);
        }).catch(error => {
            refreshCaptcha();
            if (error.message == `Your account: ${details.username} is not verified...`) {
                handleNotVerified();
            } else if (error.message) {
                setMessage({ showMsg: true, message: error.message, severity: 'error' });
            } else {
                setMessage({showMsg: true, message: t('Try_Again'), severity: 'error'});
            }
            setLoading(false);
        })
    }

    const onInputChange = (event) => {
        let value = event.target.value;
        if (event.target.id === 'username') {
            value = value.trim().toLowerCase();
        }
        setDetails(prevState => ({
            ...prevState,
            [event.target.id]: value
        }));

    }

    const handleNotVerified = () => {
        setSeekConfirmation({
            show: true,
            title: t('Confirmation'),
            message: <Stack spacing={2} justifyContent='center' alignItems='center'>
                <Typography>{`${t('Your_Account')} ${details.username} ${t('Is_Not_Verified')}`}</Typography>
                <Typography>{t('Want_To_Verify')}</Typography>
            </Stack>,
            onAgree: () => verifyEmail(),
            onDisAgree: resetSeekConfirmation,
            isCancel: true,
            disagreeLabel: t('No_Skip'),
            agreeLabel: t('Yes_Verify')
        })
    }

    const resetSeekConfirmation = () => {
        setSeekConfirmation({
            show: false,
            title: '',
            message: '',
            onAgree: null,
            onDisAgree: null,
            disagreeLabel: t('No'),
            agreeLabel: t('Yes')
        })
    }

    const redirectToVerify = () => {

    }

    // const Updatevalidators = () => {
    //     validateForm().then(data => {
    //         if (Object.values(data.errors).length !== 0) {
    //             setLoading(false)
    //         } else {
    //             signIns()
    //         }
    //     })
    // }

    // const signIns = () => {
    //     setLoading(true);
    //     setMessage({ showMsg: false, message: null, severity: null });
    //     let payload = {
    //         userName: details.username,
    //         password: details.password
    //     }
    //     let user = null;
    //     if ((captcha != '')) {
    //             PublicAxiosInterceptor.post(`login?recaptcha=${captcha}`, payload).then(response => {
    //                 setLoading(false);
    //                 context.setUserSession({
    //                     isAuthenticated: true, session: {
    //                         accessToken: response.access_token,
    //                         refreshToken: response.refresh_token
    //                     }
    //                 });
    //                 sessionStorage.setItem('token', response.access_token);
    //                 sessionStorage.setItem('refreshToken', response.refresh_token);
    //                 user = decodeToken(response.access_token);
    //                 getUserInformation(user);
    //                 setLoading(true);
    //                 setTimeout(() => {
    //                     history.push("/polls");
    //                 }, 2000);
    //             }).catch((error) => {
    //                 // validateForm().then(data => {
    //                 //     if (Object.values(data.errors).length !== 0) {
    //                 //         setLoading(false)
    //                 //     } else {
    //                 //         setLoading(false)
    //                 //         setMessage({ showMsg: true, message: error.message, severity: 'error' })
    //                 //     }
    //                 // })
    //                 refreshCaptcha();
    //                 refreshRefreshPassCaptcha();
    //                 if (error.message) {
    //                     if (error.message.includes('User credentials have expired')) {
    //                         sendOTPToResetPassword()
    //                     } else if (error.message.includes('User account is locked')) {
    //                         resetUserAccount();
    //                     } else {
    //                         setMessage({ showMsg: true, message: error.message, severity: 'error' });
    //                     }
    //                 } else {
    //                     setMessage({ showMsg: true, message: t('Try_Again'), severity: 'error' });
    //                 }
    //                 setLoading(false);
    //             })
    //         } else {
    //             refreshCaptcha();
    //             refreshRefreshPassCaptcha();
    //             setMessage({ showMsg: false, message: null, severity: null });
    //             setTimeout(() => {
    //                 setLoading(false);
    //                 setMessage({ showMsg: true, message: t('Please_Check_Internet_Connection_And_Refresh_The_Page'), severity: 'error' })
    //             }, 1000);
    //         }
    // }

    const validateForm = async () => {
        setErrors(prevState => ({
            ...prevState,
            errors: {}
        }));
        let formErrors = { errors: {}, touched: {} };
        Object.entries(details).map(([key]) => {
            if ((value === 'email' && key === 'username') || (value === 'mobile' && key === 'phonenumber')) {
                const newError = validate[key](details[key])
                formErrors = {
                    errors: {
                        ...formErrors.errors,
                        ...(newError && { [key]: newError }),
                    }
                }
            }
        })
        setErrors(prevState => ({
            ...prevState,
            errors: formErrors.errors
        }));
        return formErrors;
    }

    const signUp = () => {
        history.push("/sign-up");
    }

    const getQuote = () => {
        history.push("/get-quote");
    }

    const verifyEmail = () => {
        history.push("/verify-Email")
    }

    const forgotPasssword = () => {
        history.push("/forgot-password")
    }

    useEffect(() => {
        refreshCaptcha();
    }, [])

    const refreshCaptcha = () => {
        setIsRefresh(true);
        setTimeout(() => {
            setIsRefresh(false);
        }, 1000);
    }

    const onCancelMFAVerification = () => {
        setIsMFAEnabled(false);
        setShowMFAModel(false);
        setDetails({
            username: '',
            phonenumber:''
        })
    }

    const handleLoginData = () => {
        setValue(!value)
    }

    return (
        <RootDiv>
            {/* <PricingPopOver /> */}
            <Button id='pricing' className="float-right" color="primary" onClick={getQuote} variant='contained'> PRICING </Button>
            <ContactPopup />
            {!showMFAModel && <Container component="main" maxWidth="xs">
                <Grid container spacing={0} alignItems="center" justify="center" style={{ minHeight: "98vh" }}>
                    <Paper>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '16px' }}>
                            <img id='evoting' src={process.env.PUBLIC_URL + '/evote-logo.svg'} style={{ width: '60%', padding: '6px' }} alt="TRUSTeVote Logo" />
                            {/* <Typography><span className='welcome-logo-font' style={{paddingBottom: '2rem'}}>eVoting</span></Typography> */}
                            <Grid container style={{ paddingTop: '24px', paddingBottom: '12px' }}>
                                <Grid item xs={12}>
                                    <Typography id='signin' component="h1" variant="h5" gutterBottom>
                                        Sign In
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} align="right" style={{ marginTop: "2rem" }}>
                                  <ToggleButtonGroup
                                    value={value}
                                    exclusive
                                    size="small"
                                    onChange={handleLoginDetails}
                                    aria-label="text alignment"
                                  >
                                    <Tooltip title="Login using Email">
                                      <ToggleButton
                                        value="email"
                                        aria-label="left aligned"
                                        style={{background: value === "email" ? "#2a83ad" : "",color: value === "email" ? "white" : "",
                                          borderRadius: "50px 0px 0px 50px",fontWeight: "bold", fontSize: '12px', paddingLeft: '1em'
                                        }}
                                      >
                                        <MdAttachEmail fontSize='large'/>
                                        &nbsp; {t("Email_Address")}
                                      </ToggleButton>
                                    </Tooltip>
                                    <Tooltip title="Login using Mobile Number">
                                      <ToggleButton
                                        value="mobile"
                                        aria-label="centered"
                                        style={{background: value === "mobile" ? "#2a83ad" : "",color: value === "mobile" ? "white" : "",
                                          borderRadius: "0px 50px 50px 0px", fontWeight: "bold", fontSize: '12px', paddingRight: '1em'
                                        }}
                                      >
                                        <PhoneIphoneIcon /> Mobile Number
                                      </ToggleButton>
                                    </Tooltip>
                                  </ToggleButtonGroup>
                                </Grid>
                            </Grid>
                            {/* <Grid item xs={12}>
                                <Stack direction='row'>
                                    <Typography style={{ color: value === false ? 'blue' : '' , fontWeight: value === false ? 'bold' : '' }}>Email</Typography>
                                    <Tooltip title={value === false ? 'Login With Email' : 'Login With Mobile Number'}>
                                        <FormControlLabel checked={value} onChange={handleLoginData} style={{margin:'-5px 0px 0px 0px'}}
                                            control={<MaterialUISwitch value={ value } id="loginUsing"/>}
                                            />
                                    </Tooltip>
                                    <Typography style={{color: value === true ? 'blue' : '', fontWeight: value === true ? 'bold' : '' }}>Mobile Number</Typography>
                                </Stack>
                            </Grid> */}
                            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                                <Grid container>
                                    <Grid item xs={12}>
                                    {value === "mobile" ? (
                                    <FormControl fullWidth sx={{ m: 1 }} variant="standard"
                                    style={{margin: "8px 8px 8px 0px"}}>
                                        <InputLabel htmlFor="phonenumber">
                                            {t('Username_Mobile_Number')}
                                        </InputLabel>
                                      <Input
                                        id="phonenumber"
                                        name="phonenumber"
                                        value={details.phonenumber}
                                        // helperText={errors['phonenumber']}
                                        error={errors !== "" && errors.errors.phonenumber}
                                        onChange={onInputMobileNumberChange}
                                        startAdornment={
                                          <InputAdornment
                                            position="start"
                                            style={{ width: extension.length > 3 ? extension.length === 4 ? '6.5rem' : extension.length === 5 ? '7.8rem' : '10.5rem' : '5.5rem' }}
                                            >
                                            <MuiPhoneNumber
                                              id="input-with-icon-adornment"
                                              name="extension"
                                              defaultCountry={"in"}
                                              value={extension}
                                              // helperText={errors !== '' && errors.errors.username}
                                              // error={errors !== '' && errors.errors.username}
                                              onChange={(event) => onMobileNumberChange(event)}
                                              autoFormat={false}
                                              style={{margin: "8px 0px", color: "black !important"}}
                                              required
                                              disableDropdown={loading}
                                              countryCodeEditable={false}
                                              disabled
                                            />
                                          </InputAdornment>
                                        }
                                        disabled={loading}
                                        autoComplete="new-password"
                                        aria-describedby="phonenumber-helper-text"
                                      />
                                      <FormHelperText
                                        id="phonenumber-helper-text"
                                        style={{ color: "red" }}
                                      >
                                        {errors !== "" && errors.errors.phonenumber}
                                      </FormHelperText>
                                    </FormControl>
                                  ) : (
                                    <TextField
                                      id="username"
                                      label={t("User_Id_(Email Address)")}
                                      style={{ margin: "8px 8px 8px 0px" }}
                                      fullWidth
                                      autoFocus
                                      value={details.username}
                                      onChange={onInputChange}
                                      error={errors !== "" && errors.errors.username}
                                      helperText={errors !== "" && errors.errors.username}
                                      autoComplete="off"
                                      disabled={loading}
                                    />
                                  )}
                                        {/* <TextField id="password" label="Password" type={showPassword ? "text" : "password"}
                                            style={{ margin: '8px 8px 8px 0px' }} fullWidth value={details.password}
                                            onChange={onInputChange}
                                            error={errors['password']}
                                            helperText={errors['password']}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            id="icon"
                                                            aria-label="toggle password visibility"
                                                            onClick={toggleShowPassword}
                                                        >
                                                            {showPassword ? <Visibility id="visible" /> : <VisibilityOff id="visibleOff" />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                            autoComplete="new-password" disabled={loading}
                                        /> */}
                                    </Grid>
                                    <Grid item xs={12} container justify="flex-end">
                                        <Typography id="forgotpwd" variant="body2">
                                            <Link id="forgotlink" onClick={forgotPasssword} className="pointer">Forgot Password?</Link>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} container justify="flex-end">
                                        <Typography id="notVerify" variant="body2">
                                            Not Verified? <Link id="verify" onClick={verifyEmail} className="pointer">Verify</Link>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button id="signin" type="submit" size="medium" variant="contained" color="primary" style={{ margin: '8px 8px 8px 0px' }} fullWidth
                                            disabled={!(details.username || details.phonenumber)}>
                                            {t('Continue')}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} container justify="center">
                                        <Typography id="donthaveaccount" variant="body2">
                                            Don't have an account? <Link onClick={signUp} className="pointer">Sign Up</Link>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </form>
                        </div>
                        <PoweredBy />
                    </Paper>
                </Grid>
            </Container>}
            {seekConfirmation.show && <ConfirmationPopUp open={seekConfirmation.show} title={seekConfirmation.title} handleClose={resetSeekConfirmation}
                message={seekConfirmation.message} onAgree={seekConfirmation.onAgree} onDisAgree={seekConfirmation.onDisAgree}
                agreeLabel={seekConfirmation.agreeLabel} disagreeLabel={seekConfirmation.disagreeLabel} />}
            {showMFAModel && <MFAVerification username={value === 'mobile' ? extension + details.phonenumber : details.username}
                    isMobile={value === 'mobile' ? true : false} isMFAEnabled={isMFAEnabled} onCancel={onCancelMFAVerification}/>}
            {loading && <EligoBackdrop show={loading} />}
            {message.showMsg && <EligoSnackBar show={message.showMsg} message={message.message}
                severity={message.severity} />}
            {!isRefresh && <EligoReCaptcha key="login" captcha={setCaptcha} refresh={setIsRefresh} />}
        </RootDiv>
    )
}

export default Login