import { Table, TableContainer, Typography } from '@material-ui/core';
import { Stack } from '@mui/material';
import React from 'react';
import FileTableBody from './mui-table/FileTableBody';
import FileTableHeader from './mui-table/FileTableHeader';
const rows = [
    createData('India.pdf', '2022-09-19T12:36:00.000Z', 1324171354, 3287263),
    createData('China.pdf', '2022-09-19T12:36:00.000Z', 1403500365, 9596961),
    createData('Italy.xls', '2022-09-19T12:36:00.000Z', 60483973, 301340),
    createData('United.doc', '2022-09-19T12:36:00.000Z', 327167434, 9833520),
    createData('Canada.mp3', '2022-09-19T12:36:00.000Z', 37602103, 9984670),
    createData('Australia.mp4', '2022-09-19T12:36:00.000Z', 25475400, 7692024),
];

/* Data key should match table header id*/
function createData(file_name, LastModified, population, size) {
    return { file_name, LastModified, population, size };
}

const FileTable = (props) => {

    const { columns, rows, loading, rowsPerPage, page } = props

    /* column id should match key of each cell of table body to map */


    return (
        <div>
            <TableContainer style={{ maxHeight: '75vh', minHeight: rows.length > 0 ? '' : 200 }}>
                <Table stickyHeader aria-label="sticky table">
                    <FileTableHeader columns={columns} />
                    {rows.length != 0 &&
                        <FileTableBody tableRows={rows} columns={columns} rowsPerPage={rowsPerPage} page={page} />
                    }
                </Table>
                {rows.length === 0 && !loading && <Stack justifyContent='center' alignItems='center' margin='3rem'>
                    <img id="nodocument" src={process.env.PUBLIC_URL + '/images/no-document.svg'} style={{ width: '8%' }} alt="NoDocumentImage" />
                    <Typography color='textSecondary'>No Files at the moment</Typography>
                </Stack>}
            </TableContainer>
        </div>
    )
}

export default FileTable

