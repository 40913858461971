import React, { useState, useEffect, Fragment } from 'react';
import {
    Grid,
    Typography,
    TextField,
    MenuItem
} from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';

const EligoPagination = ({ rowsPerPageText, rowsPerPage, totalItems, page, onRowsPerPageChange, onPageChange, children, rowsText }) => {
    const { t } = useTranslation();
    const [pageCount, setPageCount] = useState(0);
    const pages = [5, 10, 25, 100];

    const handleRowsPerPageChange = (event) => {
        onRowsPerPageChange(event);
    }

    const handlePageChange = (event, data) => {
        onPageChange(event, data);
    }

    useEffect(() => {
        setPageCount(Math.ceil(totalItems / rowsPerPage));
    }, [totalItems, rowsPerPage])

    return (
        <Fragment>
            <Stack direction={{xs: 'column', sm: 'column', md: 'row', lg: 'row'}} justifyContent='space-between' alignItems='center' width='100%' spacing={0.5}>
                <Typography variant="overline" display="block" gutterBottom
                    style={{ display: 'inherit' }}>{`${t('Total')}  ${totalItems} ${rowsText ? rowsText : t('Rows')}`}</Typography>
                    {children && <span style={{ float: 'right' }}>{children}</span>}
                    <Stack direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row' }} spacing={0.5} alignItems='center'>
                        <Stack direction="row" spacing={1} alignItems='center'>
                            <Typography variant="overline" display="block" gutterBottom>{rowsPerPageText ? rowsPerPageText : t('Rows_Per_Page')} &nbsp;:&nbsp;</Typography>
                            <TextField id="rows-per-page" select size="small" variant="standard" value={rowsPerPage ? rowsPerPage : 5}
                                onChange={handleRowsPerPageChange}>
                                {pages.map((page, index) => (
                                    <MenuItem key={index} value={page}>
                                        {page}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Stack>
                        <Pagination count={pageCount} color="primary" showFirstButton showLastButton
                            page={page} onChange={handlePageChange} style={{ float: 'right' }}
                            siblingCount={1} boundaryCount={2} size='small'/>
                    </Stack>
            </Stack>
        </Fragment>
    )
}

export default EligoPagination;