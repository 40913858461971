import React, { useEffect, useState } from 'react';
import { TextField, Grid,FormControl,InputLabel,Select, MenuItem ,FormHelperText, InputBase} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import MuiPhoneNumber from "material-ui-phone-number";
import Addresses from './Addresses';
import EligoBackDrop from '../../common/EligoBackdrop';
import { makeStyles } from '@material-ui/core/styles';
import { Stack, Box } from "@mui/material";
import Autocomplete, {createFilterOptions} from '@mui/material/Autocomplete';
import EligoSnackBar from '../../common/EligoSnackBar';
import { LookUpAxiosEvotingInterceptor } from '../../config/axios.interceptor';


const filter = createFilterOptions();

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(0),
        minWidth: 200,
    }
}));


const AddressDetails = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [loading, setLoading] = useState();
    const [countryId, setCountryId] = useState('')
    const [countryName, setCountryName] = useState(props.data.country ? props.data.country : '')
    const [state, setState] = useState([])
    const [stateId, setStateId] = useState(props.data.stateId ? props.data.stateId : '')
    const [city, setCity] = useState([])
    const [cityId, setCityId] = useState(props.data.cityTownId ? props.data.cityTownId : '')
    const [postalCode, setPostalCode] = useState([])
    const [postalValue, setPostalValue] = useState('')
    const [cityTownId, setCityTownId] = useState('')
    const [selectedCity, setSelectedCity] = useState(props.data?.cityTown ? props.data.cityTown : '')
    const [selectedState, setSelectedState] = useState(props.data?.state ? props.data.state : '')
    const [selectedPostalCode, setSelectedPostalCode] = useState(props.data?.postalCode ? props.data.postalCode : '')
    const [selectionstate, setSelectionState] = useState(props.data?.state ? props.data.state : '')
    const [selectedStateId, setSelectedStateId] = useState(props.data?.stateId ? props.data.stateId : '')
    const [addressError, setAddressError] = useState('');

    const ListCountry = () => {
        LookUpAxiosEvotingInterceptor.get(`geo/countries`).then(response => {
            if (response) {
                setLoading(false);
                response.map((value, index) => {
                    if ((value.name != null && value.name === 'India')) {
                        setCountryId(value.countryId)
                        setCountryName(value.name)
                        props.setJsonCountryStateCityData('country', value.name);
                        props.setJsonCountryStateCityData('countryId', value.countryId);
                    }
                })
            }
        }).catch(error => {
            if (error.message) {
                setLoading(false);
                setAddressError(error.message);
            } else {
                setAddressError(t('Try_Again'));
            }
            // setLoadingAddress(false)
        })
    }

    const ListStates = () => {
        LookUpAxiosEvotingInterceptor.get(`geo/country/${countryId}/states`).then(response => {
            if (response) {
                setLoading(false);
                setState(response);
            }
        }).catch(error => {
            if (error.message) {
                setAddressError(error.message);
            } else {
                setAddressError(t('Try_Again'));
            }
            // setLoadingAddress(false)
        })
    }

    const ListCities = () => {
        LookUpAxiosEvotingInterceptor.get(`geo/state/${selectedStateId}/cities`).then(response => {
            if (response) {
                setLoading(false);
                setCity(response);
            }
        }).catch(error => {
            if (error.message) {
                setAddressError(error.message);
            } else {
                setAddressError(t('Try_Again'));
            }
            // setLoadingAddress(false)
        })
    }

    const setJsonData = (event) => {
        props.setJsonData(event);
    }
    const setJsonPostal = (event) => {
        const filterPostalCode = postalCode.filter((value) => { return value.postalCode == event.target.value });
        if (filterPostalCode.length == 0) {
            setSelectedPostalCode(event.target.value)
            props.setJsonCountryStateCityData("postalCode", event.target.value);
            props.setJsonCountryStateCityData("postalCodeId", "");
            props.setJsonData(event);
        } else {
            setSelectedPostalCode(event.target.value)
            props.setJsonCountryStateCityData("postalCode", event.target.value);
            props.setJsonCountryStateCityData("postalCodeId", filterPostalCode[0].postalCodeId)

        }
    }
    const setJsonCity = (event) => {
        const filterCity = city.filter((value) => { return value.name == event.target.value });
        if (filterCity.length == 0) {
            setSelectedCity(event.target.value)
            setSelectedPostalCode('')
            props.setJsonCountryStateCityData("postalCode", '');
            props.setJsonCountryStateCityData("postalCodeId", '')
            props.setJsonData(event);
        } else {
            setSelectedCity(event.target.value)
            props.setJsonCountryStateCityData("cityTown", event.target.value);
            props.setJsonCountryStateCityData("cityTownId", filterCity[0].cityTownId)
        }
    }

    const setJsonState = (event) => {
        const filterState = state.filter((value) => { return value.name == event.target.value });
        if (filterState.length == 0) {
            setSelectedCity('')
            setCity([])
            setSelectedPostalCode('')
            props.setJsonCountryStateCityData("cityTown", '');
            props.setJsonCountryStateCityData("cityTownId", "");
            props.setJsonCountryStateCityData("postalCode", '');
            props.setJsonCountryStateCityData("postalCodeId", '')
            setSelectedState(event.target.value)
            props.setJsonData(event);
        } else {
            setSelectedState(event.target.value)
            setSelectedCity('')
            setSelectedPostalCode('')
            props.setJsonCountryStateCityData("state", event.target.value);
            props.setJsonCountryStateCityData("stateId", filterState[0].stateId)
        }
    }


    const setPhoneNumber = (fieldName, event) => {
        props.setPhoneNumber(fieldName, event)
    }

    const setJsonStateData = (event, newValue) => {
        if (newValue == null || newValue == '') {
            setSelectedCity('')
            setCity([])
            setSelectedState('')
            setSelectedStateId('')
            setSelectedPostalCode('')
            props.setJsonCountryStateCityData("state", newValue);
            props.setJsonCountryStateCityData("stateId", "");
            props.setJsonCountryStateCityData("cityTown", newValue);
            props.setJsonCountryStateCityData("cityTownId", '')
            props.setJsonCountryStateCityData("postalCode", newValue);
            props.setJsonCountryStateCityData("postalCodeId", '')
        }
        else {
            const filterState = state.filter((value) => { return newValue != null && value.name == newValue });
            if (filterState.length == 0) {
                setSelectedState(newValue)
                setCity([])
                setSelectedCity('')
                setSelectedState('')
                setSelectedPostalCode('')
                props.setJsonCountryStateCityData("state", newValue);
                props.setJsonCountryStateCityData("stateId", "");
            }
            else {
                setSelectedState(newValue)
                setSelectedCity('') // if state has been changed city should be cleard
                setSelectedPostalCode('')
                props.setJsonCountryStateCityData("cityTown", '');
                props.setJsonCountryStateCityData("cityTownId", '')
                props.setJsonCountryStateCityData("postalCode", '');
                props.setJsonCountryStateCityData("postalCodeId", '')
                props.setJsonCountryStateCityData("state", newValue);
                props.setJsonCountryStateCityData("stateId", filterState[0].stateId)

            }
        }
    }

    const setJsonCityData = (event, newValue) => {
        if (newValue == null || newValue == '') {
            setSelectedCity(newValue)
            setSelectedPostalCode('')
            setPostalCode([])
            props.setJsonCountryStateCityData("cityTown", newValue);
            props.setJsonCountryStateCityData("cityTownId", '')
            props.setJsonCountryStateCityData("postalCode", newValue);
            props.setJsonCountryStateCityData("postalCodeId", '')
        } else {
            const filterCity = city.filter((value) => { return newValue != null && value.name == newValue });
            if (filterCity.length == 0) {
                setSelectedCity(newValue)
                props.setJsonCountryStateCityData("cityTown", newValue);
                props.setJsonCountryStateCityData("cityTownId", "");
            } else {
                setSelectedCity(newValue)
                setSelectedPostalCode('')
                props.setJsonCountryStateCityData("postalCode", '');
                props.setJsonCountryStateCityData("postalCodeId", '')
                props.setJsonCountryStateCityData("cityTown", newValue);
                props.setJsonCountryStateCityData("cityTownId", filterCity[0].cityTownId)
            }
        }
    }

    const setJsonPostalData = (event, newValue) => {
        const filterPostalCode = postalCode.filter((value) => { return newValue != null && value.postalCode == newValue });
        if (filterPostalCode.length == 0) {
            setSelectedPostalCode(newValue)
            props.setJsonCountryStateCityData("postalCode", newValue);
            props.setJsonCountryStateCityData("postalCodeId", "");
        } else {
            setSelectedPostalCode(newValue)
            props.setJsonCountryStateCityData("postalCode", newValue);
            props.setJsonCountryStateCityData("postalCodeId", filterPostalCode[0].postalCodeId)
        }
    }

    useEffect(() => {
        state.map((value, index) => {
            if (selectedState === value.name) {
                setSelectedStateId(value.stateId)
            }
        })
    }, [selectedState, selectedCity, state])

    useEffect(() => {
        city?.filter((value) => selectedCity === value.name).map((value) => { setCityId(value.cityTownId) });
    }, [selectedCity])

    useEffect(() => {
        if (props.isEdit) {
            setLoading(true);
            ListCountry();
        }
    }, [props.isEdit])

    useEffect(() => {
        if (countryId !== '') {
            setLoading(true);
            ListStates();
        }
    }, [countryId])

    useEffect(() => {
        if (selectedStateId !== '') {
            setLoading(true);
            ListCities();
        }
    }, [selectedStateId])

    const stateChange = (e) =>{
        setSelectionState(e.target.value)
        state.map((statename) => {
            if (statename.name === e.target.value) {
                setSelectedStateId(statename.stateId)
                props.setJsonCountryStateCityData("state", statename.name);
                props.setJsonCountryStateCityData("stateId", statename.stateId);
            }
        })
        if (selectionstate != e.target.value) {
            setSelectedCity(null)
            // setSelectedPostalCode('')
            props.setJsonCountryStateCityData("cityTown", '');
            props.setJsonCountryStateCityData("cityTownId", '')
            props.setJsonCountryStateCityData("postalCode", '');
            props.setJsonCountryStateCityData("postalCodeId", '')
        }
    }

    return (
        <React.Fragment>
            <Box sx={{ flexGrow: 1 }}>
            <Stack spacing={2} direction={{ xs: 'column' }}>
            <Stack spacing={2}>
                <Stack spacing={2} direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row' }} sx={{ alignItems: 'center' }}>
                    <TextField id="attention" label={t("Name")} fullWidth
                        value={props.data.attention} onChange={setJsonData} inputProps={{ maxLength: 50 }}
                        helperText={props.touched['attention'] && props.errors?.attention}
                        error={props.touched['attention'] && props.errors?.attention}
                        disabled={!props.isEdit} variant="standard"
                    />
                    <MuiPhoneNumber
                        id="phone"
                        label="Mobile Number"
                        defaultCountry={"in"}
                        value={props.data.phone}
                        disabled={!props.isEdit}
                        disableDropdown={!props.isEdit}
                        onChange={(event) => setPhoneNumber('phone', event)}
                        error={props.touched['phone'] && props.errors['phone']}
                        helperText={props.touched['phone'] && props.errors['phone']}
                        autoFormat={false} fullWidth variant="standard"
                        countryCodeEditable={false}
                    />

                </Stack>
                <Stack spacing={2} justifyContent="space-evenly" direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row' }}
                alignItems={props.errors?.addressLine1 || props.errors?.addressLine1? '' : 'end'}>
                    <TextField id="addressLine1" label={t("Address_Line_1")} required={props.isEdit} fullWidth
                        value={props.data.addressLine1} onChange={setJsonData} inputProps={{ maxLength: 200 }} multiline
                        helperText={props.touched['addressLine1'] && props.errors?.addressLine1}
                        error={props.touched['addressLine1'] && props.errors?.addressLine1}
                        disabled={!props.isEdit} variant="standard"
                    />
                    <TextField id="addressLine2" label={t("Address_Line_2")} fullWidth multiline
                        value={props.data.addressLine2} onChange={setJsonData} inputProps={{ maxLength: 200 }}
                        disabled={!props.isEdit} variant="standard"
                    />
                </Stack>
                <Stack spacing={2} direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row' }}
                alignItems={props.errors?.state || props.errors?.cityTown? '' : 'end'}>
                    <TextField id="country" label={t("Country")} required={props.isEdit} fullWidth
                        value={countryName} variant="standard" disabled={props.data.id != '' && !props.isEdit}
                    />
                    {props.isEdit &&
                        <FormControl style={{ width: '100%' }}>
                            {/* <TextField id="demo-simple-select-helper-label">Age</TextField> */}
                            <InputLabel style={{ color: (props.touched['state'] || selectedState == null) && props.errors?.state ? 'red' : '' }}
                                required={props.isEdit}>State</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="state"
                                fullWidth
                                value={selectionstate}
                                onChange={stateChange}
                                style={{ marginTop: '16px', textAlign: 'start', marginBottom: '-0.19em' }}
                                error={(props.touched['state'] || selectionstate == null) && props.errors?.state}
                                disabled={props.data.addressId != '' && !props.isEdit} variant="standard"
                            >
                                {
                                    state.map((state) => {
                                        return <MenuItem value={state.name} style={{ width: '350px' }} >{state.name}</MenuItem>
                                    })
                                }
                            </Select>
                            <FormHelperText style={{ color: 'red' }}>{(props.touched['state'] || selectedState == null) && props.errors?.state}</FormHelperText>
                        </FormControl>
                    }
                    {!props.isEdit &&
                        <TextField label={t('State')} value={selectionstate} disabled={!props.isEdit} fullWidth />
                    }
                    <Autocomplete
                        id="cityTown"
                        style={{ marginTop: '5px' }}
                        // freeSolo
                        value={selectedCity} onChange={setJsonCityData}
                        filterOptions={(city, params) => {
                            const filtered = filter(city, params);
                            const { inputValue } = params;


                            const isExisting = city.some((option) => inputValue === option);
                            if (inputValue !== '' && !isExisting) {
                                filtered.push(inputValue);
                            }
                            return filtered;
                        }}
                        options={city?.map((option) => option.name)}
                        getOptionLabel={(option) => {

                            return option;
                        }}
                        fullWidth
                        disabled={props.data.id != '' && !props.isEdit}
                        renderInput={(params) => <TextField {...params} id="cityTown" label="Town/City/Taluk" onChange={setJsonCity}
                            helperText={(props.touched['cityTown'] || selectedCity == null) && props.errors?.cityTown}
                            required={props.isEdit}
                            fullWidth
                            error={(props.touched['cityTown'] || selectedCity == null) && props.errors?.cityTown}
                            disabled={!props.isEdit} variant="standard" />}
                    />
                </Stack>
                <Stack spacing={2} width='100%' direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row' }}
                 alignItems={props.errors?.postalCode ? '' : 'end'}>
                    <Autocomplete
                        id="postalCode"
                        freeSolo
                        value={selectedPostalCode} onChange={setJsonPostalData}
                        // onInputChange={( newInput) => { setPostalValue(newInput.target.value) }}
                        filterOptions={(postalCode, params) => {
                            const filtered = filter(postalCode, params);
                            const { inputValue } = params;

                            const isExisting = postalCode.some((option) => inputValue === option);
                            if (inputValue !== '' && !isExisting) {
                                filtered.push(inputValue);
                            }
                            // setSelectedPostalCode(inputValue)
                            return filtered;
                        }}
                        options={postalCode?.map((option) => option.postalCode)}
                        getOptionLabel={(option) => {
                            return option;
                        }}
                        fullWidth

                        disabled={props.data.id != '' && !props.isEdit}
                        renderInput={(params) => <TextField  {...params} id="postalCode" label={t("Pincode")} onChange={setJsonPostal}
                            helperText={(props.touched['postalCode'] || selectedPostalCode == null) && props.errors?.postalCode}
                            error={(props.touched['postalCode'] || selectedPostalCode == null) && props.errors?.postalCode} type='number'
                            disabled={!props.isEdit} variant="standard" required={props.isEdit} />}
                    />
                    <TextField id="landmark" label="Landmark" fullWidth style={{ marginTop: '-4px' }}
                        value={props.data.landmark} onChange={setJsonData} inputProps={{ maxLength: 100 }} multiline
                        disabled={!props.isEdit} variant="standard"
                    />
                </Stack>
            </Stack>
            {loading && <EligoBackDrop show={loading} />}
            {addressError !== '' && addressError !== undefined ?
                <EligoSnackBar show={true} message={addressError} severity="error" reset={() => setAddressError('')} /> : null}
            </Stack>
            </Box>
        </React.Fragment>
    )
}

export default AddressDetails;