import { FormLabel, Stack, Typography } from "@mui/material";
import "./PollQuestion.css"
import ImagePreview from "../poll-question-detail/question-section/preview/ImagePreview";
import { OptionType } from "../QuestionStaticVar";

export const EvotingTextField = ({ label, component }) => {
    return (
        <Stack direction="column" spacing={0.5}>
            <Typography variant="subtitle2" color="GrayText" sx={{fontWeight: 'bold'}}>{label}</Typography>
            {component}
        </Stack>
    )
}

export const EvotingRowInputField= ({ label, component }) => {
    return (
        <Stack direction="row" margin={'auto'} alignItems='center' width={'100%'} spacing={1}>
            <Typography variant="subtitle2" color="GrayText" sx={{ fontWeight: 'bold' }}>{label}</Typography>
            {component}
        </Stack>
    )
}

export const PopoverOptions = ({ title, options }) => {
    return (
        <div className="popover__wrapper">
            <Typography color="primary" variant="subtitle2">{title}</Typography>
            {options.length > 0 && <div class="popover__content">
                {options.map((opt, index) => {
                    return (
                        <Stack direction="row" spacing={2} mt={1} key={opt.id} alignItems='center'>
                            {(OptionType.Text === opt.optionType || OptionType.Image === opt.optionType) && <Typography color='textSecondary'>Option {index + 1}:</Typography>}
                            {OptionType.Text !== opt.optionType && <ImagePreview optionFile={opt.files} />}
                            <Typography>{opt.option}</Typography>
                        </Stack>
                    )
                })}
            </div>}
            {options.length === 0 && <div class="popover__content">
                <Typography color='textSecondary'>No Options are available</Typography>
            </div>}
        </div>
    )
}