import React, { Fragment } from 'react';
import {
    Typography, Accordion, AccordionSummary,
    AccordionDetails, Grid, Divider,
    TextField, Container, withStyles, Card
}
    from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Roles, Status } from '../../common/GenericCodesValues'
import {format} from 'date-fns'
import { useHistory } from 'react-router-dom';
import DeletePoll from './DeletePoll'
import UpdatePollStatus from './UpdatePollStatus'
import Btns from '../../common/Btns'
import HowToVoteIcon from '@material-ui/icons/HowToVote';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useTranslation } from 'react-i18next'; 

const CustomAccordion = withStyles({
    root: {
        // '&:hover': {
        //     backgroundColor: '#f5f5fc',
        //     borderColor: '#9d9fa2',
        //     boxShadow: 'none',
        // }
    },
})(Accordion);

const CustomCard = withStyles({
    root: {
      '&:hover': {
        backgroundColor: '#dadaea',
        borderColor: '#9d9fa2',
        boxShadow: 'none',
      }
    },
})(Card);

const EditPoll = (props) => {
    let history = useHistory()
    const getPollDetails = () => {
        history.push({
            pathname: '/polls/poll-rounds',
            state: { 
                pollDetails: props.pollDetails, 
                roles: props.pollDetails.roles,
                pollType: props.pollType
            }
        })
    }
    return (
        <Btns label="edit-poll" color="primary" btn={<><EditIcon/></>} click={getPollDetails} />
    )
}

const ViewPoll = (props) => {
    let history = useHistory()
    const getPollDetails = () => {
        history.push({
            pathname: '/polls/poll-rounds',
            state: { pollDetails: props.pollDetails, roles:  props.pollDetails.roles,
                pollType: props.pollType }
        })
    }
    return (
        <Btns label="edit-poll" color="primary" btn={(props.pollDetails.roles).includes(Roles.voter) 
            && (props.pollType !=  Status.canceled && props.pollType !=  Status.closed) ? 
            <><HowToVoteIcon/></>:<><VisibilityIcon/></>} click={getPollDetails} />
    )
}

// const PollDetail = (props) => {
//     return (
//         <Container maxWidth="lg">
//             <Grid container spacing={3}>
//                 <Grid item xs={6}>
//                     <TextField id="poll-category" label={t("Poll_Category")} value={props.pollDetails.category_description} fullWidth disabled />
//                 </Grid>
//                 <Grid item xs={6}></Grid>
//                 <Grid item xs={6}>
//                     <TextField id="poll-title" label={t("Poll_Title")} value={props.pollDetails.title.en} fullWidth disabled />
//                 </Grid>
//                 <Grid item xs={3}>
//                     <TextField id="poll-category" label={t("Begin_Date_&_Time")} fullWidth disabled
//                         value={format(new Date(props.pollDetails.start_date_time), 'dd/MM/yyyy HH:mm')}  />
//                 </Grid>
//                 <Grid item xs={3}>
//                     <TextField id="poll-category" label={t("Close_Date_&_Time")} fullWidth disabled
//                         value={format(new Date(props.pollDetails.end_date_time), 'dd/MM/yyyy HH:mm')}  />
//                 </Grid>
//             </Grid>
//             <Grid container spacing={3}>
//                 <Grid item xs={12}>
//                     <TextField id="poll-description" label={t("Poll_Description")} variant="outlined"
//                         multiline rows={4} value={props.pollDetails.purpose.en} fullWidth disabled
//                     />
//                 </Grid>
//             </Grid>
//         </Container>
//     )
// }

const PollRow = (props) => {
    const { t } = useTranslation();
    let isView = (props.poll.status != Status.scheduled)
    let isEdit = (props.poll.status == Status.scheduled && (props.poll.roles).includes(Roles.organizer))
    return (
        <CustomCard style={{margin: "10px"}}>
            <CustomAccordion expanded={false} >
                <AccordionSummary
                    // className="open-poll-color"
                    style={{background: props.bgStyle, border:props.borderStyle, cursor:'default'}}
                    id={`poll-${props.poll.event_id}`}>
                    <Grid container>
                        <Grid item xs={10}>
                            <Grid item xs={12}>
                                <Typography id='category_cd' color="textPrimary">
                                    {props.poll.title.en} &nbsp;{props.poll.category_cd != null && <span>({props.poll.category_description})</span>}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography id='end_date_time' color="textSecondary">
                                    {format(new Date(props.poll.start_date_time), process.env.REACT_APP_DATE_FORMAT_TO_DISPLAY)} - {format(new Date(props.poll.end_date_time), process.env.REACT_APP_DATE_FORMAT_TO_DISPLAY)}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    {isEdit && 
                        <Fragment>
                            {/* <UpdatePollStatus id={props.poll.event_id} sequence={props.poll.sequence} textColor="green" 
                                label="open-poll" color="primary" btn={t("Open")} value="open" title={props.poll.title.en} page={props.page} itemsPerPage={props.itemsPerPage}
                                action={t("open")}/> */}
                                <EditPoll pollDetails={props.poll} pollType={props.pollType}/>
                            <DeletePoll id={props.poll.event_id} sequence={props.poll.sequence} title={props.poll.title.en} 
                                pollType={props.pollType} page={props.page} itemsPerPage={props.itemsPerPage}/>
                        </Fragment>
                    }
                    {/* {(props.poll.status == Status.open && (props.poll.roles).includes(Roles.organizer)) && 
                        <UpdatePollStatus id={props.poll.event_id} sequence={props.poll.sequence} label="close-poll" 
                            color="secondary" btn={t("Close")} value="closed" title={props.poll.title.en} page={props.page} itemsPerPage={props.itemsPerPage}
                            action={t("close")}/>
                    } */}
                    {(isView || !isEdit) &&  <ViewPoll pollDetails={props.poll} pollType={props.pollType}/>}
                </AccordionSummary>
                <Divider />
                {/* <AccordionDetails>
                    <PollDetail pollDetails={props.poll} />
                </AccordionDetails> */}
                <Divider />
            </CustomAccordion>
        </CustomCard>
    )
}

export default PollRow;