import React, { useEffect, useState } from 'react';
import { Button, Card, IconButton, InputAdornment, makeStyles, Paper, Tooltip, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { TextField, Stack } from '@mui/material';
import EmailIcon from '@material-ui/icons/Email';
import PersonIcon from '@material-ui/icons/Person';
import { useTranslation } from 'react-i18next';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { AxiosEvotingInterceptor } from '../../../config/axios.interceptor';
import CustomPhoneNumber from '../poll-round-voter-row/CustomPhoneNumber';
import SearchVoter from '../search-voter/SearchVoter';
import MuiPhoneNumber from "material-ui-phone-number";
import './AuthRep.css';

const useStyle = makeStyles(theme => ({
    div: {
        alignSelf: 'center',
        padding: '1rem 0rem 0rem 0.5rem',
    },
    option: {
        background: 'rgb(237, 250, 237)',
        margin: '0rem 0rem 0rem 22rem',
        width: '20%',
        border: 'red'
    },
    save: {
        padding: '0rem 2rem 0rem 0rem',
        margin: '0rem 0rem 0rem 0rem',
    },
    button: {
        color: 'red',
        margin: '0rem 0rem 0rem 0rem !important',
        '&:hover': {
            background: 'none'
        },
    },
    Paper: {
        '&:hover': {
            boxShadow: '0 5px 27px -5px rgba(50,50,93,0.25),0 10px 16px -8px rgba(0,0,0,0.3)',
        },
        margin: '0rem 0rem 0.4rem -0.6rem !important',
        padding: '0.5rem 0rem 0.3rem 0.5rem',
        borderRadius: '10px',
    },
    CardStyle: {
        maxHeight: '400px',
        overflowY: 'scroll',
        margin: '10px'
    }
}))

const MultiVoter = (props) => {
    const [pollRoundVoters, setPollRoundVoters] = useState([{ name: '', email: [{ email: '' }], phoneNumber: [{ phoneNumber: '' }] }]);
    const classes = useStyle()
    const { t } = useTranslation();
    const [errors, setErrors] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [voterIdex, setvoterIndex] = useState(null)
    const [voterNameIdex, setvoterNameIndex] = useState(null)
    const [refreshAuth, setRefreshAuth] = useState(null)
    const [message, setMessage] = useState(false)
    const [phoneIndex, setvoterPhoneIndex] = useState(null)
    const [authRepNameEdit, setAuthrepNameEdit] = useState(false)

    const cancelRow = (id) => {
        setPhoneError(false)
        setMessage(false)
        setErrors(false)
        var voters = [...pollRoundVoters]
        setPollRoundVoters([])
        if (pollRoundVoters.length === 1) {
            if (props.AuthRepForExistingVoter) {
                props.handleClose(false)
            } else {
                props.closeMultiVoter(false)
            }
        }
        voters.splice(id, 1);
        setPollRoundVoters(voters)
        setRefreshAuth(true)
    }

    useEffect(() => {
        if (props.AuthRepForExistingVoter) {
            props.pollRoundVoters(pollRoundVoters)
        }
    }, [pollRoundVoters, refreshAuth])

    const addMultiVoters = (e, index, id, country) => {
        setvoterIndex(index)
        if (pollRoundVoters.length > 0) {
            if (id === 'name') {
                pollRoundVoters[index].name = e.target.value
                setErrors(false)
            } else if (id === 'email') {
                let value = e.target.value.trim().toLowerCase();
                pollRoundVoters[index].email[0].email = value;
                var pattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (value.match(pattern)) {
                    setMessage(false)
                } else {
                    setMessage(true)
                    setErrors(false)
                }
            } else if (id === 'phoneNumber') {
                setvoterPhoneIndex(index)
                if (e.slice(1) === country.dialCode) {
                    setPhoneError(false)
                    pollRoundVoters[index].phoneNumber[0].phoneNumber = ''
                } else {
                    pollRoundVoters[index].phoneNumber[0].phoneNumber = e
                    if (pollRoundVoters[index].phoneNumber[0].phoneNumber && isValidPhoneNumber(e)) {
                        setPhoneError(false)
                    } else {
                        setPhoneError(true)
                    }
                }
            }
        }
        setRefreshAuth(e)
    }

    useEffect(() => {
        setPollRoundVoters(pollRoundVoters)
        setAuthrepNameEdit(true) // for edit authrep name mannuvally
    }, [refreshAuth])

    const saveAuthRep = () => {
        setvoterIndex(null)
        setvoterNameIndex(null)
        setErrors(false)
        const emails = []
        const name = []
        const AuthRepsData = []
        pollRoundVoters.map((voters) => {
            AuthRepsData.push({ name: voters.name, email: voters.email, phoneNumber: (voters.phoneNumber[0].phoneNumber === '' || voters.phoneNumber[0].phoneNumber === null) ? null : voters.phoneNumber })
            voters.phoneNumber[0].phoneNumber === '' && (pollRoundVoters.phoneNumber = null)
            emails.push(voters.email[0].email)
            name.push(voters.name)
        })
        if (emails.includes('') || name.includes('')) {
            if (emails.includes('') && name.includes('')) {
                setvoterIndex(emails.indexOf(''))
                setvoterNameIndex(name.indexOf(''))
            } else {
                if (emails.indexOf('')) {
                    setvoterIndex(emails.indexOf(''))
                }
                setvoterNameIndex(name.indexOf(''))
            }
            setErrors(true)
        } else {
            if (errors == false && message == false && phoneError == false) {
                if (props.AuthRepForExistingVoter) {
                    props.addAuthRepExistingVoter(AuthRepsData)
                } else {
                    props.AddMultiVoter(AuthRepsData)
                }
            }
        }
    }

    const setSelectedVoter = (selectedVoter, index) => {
        if (selectedVoter != '') {
            AxiosEvotingInterceptor.get(`user/${selectedVoter.id}`).then((response) => {
                pollRoundVoters[index].name = response.name
                pollRoundVoters[index].email[0].email = response.preferredEmail
                pollRoundVoters[index].phoneNumber[0].phoneNumber = response.preferredPhoneNumber
                setRefreshAuth(selectedVoter.id)
            }).catch((error) => {
                console.log("error,,", error.message)
            })
        }
    }

    const AddAuthRep = () => {
        setPhoneError(false)
        setMessage(false)
        setErrors(false)
        setPollRoundVoters(prev => ([...prev, { name: '', email: [{ email: '' }], phoneNumber: [{ phoneNumber: '' }] }]))
    }

    return (
        <div >
            <Stack direction='row' justifyContent={!props.AuthRepForExistingVoter ? 'space-between' : 'flex-end'}>
                {!props.AuthRepForExistingVoter && <Typography style={{ fontSize: '20px', margin: '10px 0px 0px 17px' }}>{t("Authorized_Representative")}</Typography>}
                <Button id='delete' onClick={AddAuthRep} variant='outlined' color="primary" style={{ float: 'right', cursor: 'pointer', margin: '10px 15px 0px 0px' }}>{t("Add_authorized_Representative")}</Button>
            </Stack><br />
            <Card className={classes.CardStyle}>
                {pollRoundVoters.map((value, index) => {
                    return (
                        <Paper style={{ width: '98%', margin: '0px 10px 0px 10px' }}>
                            <Stack direction='row' spacing={4} style={{ margin: '1rem 0rem 1rem 0rem', padding: '0.6rem 0.6rem 0.6rem 0.6rem' }}>
                                <SearchVoter name={pollRoundVoters[index].name} onSelect={(e) => setSelectedVoter(e, index)} width={"300px"}
                                    MultiVoterAuthrep={true} setAuthrepNameEdit={setAuthrepNameEdit} searchFilterName={authRepNameEdit && pollRoundVoters[index].name}
                                    id="name" label={t('Auth_Rep_Name')} variant="standard" placeholder={`Auth-Rep ${index + 1}`} index={index}
                                    required={true} addMultiVoters={addMultiVoters}
                                    value={pollRoundVoters[index].name}
                                    roleSearch={false}
                                    error={voterNameIdex == index && errors} helperText={voterNameIdex == index && (errors ? t('Name_is_required') : '')}
                                    fullWidth={true}
                                    InputProps={
                                        <InputAdornment position="start">
                                            <PersonIcon />
                                        </InputAdornment>
                                    } />
                                <TextField id='email' label='Email' required variant='standard' value={pollRoundVoters[index].email[0].email}
                                    fullWidth
                                    error={voterIdex == index && (message || errors)}
                                    helperText={voterIdex == index && (message ? t('Enter_valid_email') : '' || errors ? t('Email_is_required') : '')}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start" id='emailcn'>
                                                <EmailIcon id='mailIcon' />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={(e) => addMultiVoters(e, index, 'email')} />
                                {/* <PhoneInput
                                    defaultCountry='IN'
                                    placeholder={t("Enter_Mobile_Number")}
                                    className={classes.disableColor}
                                    fullWidth
                                    style={{ marginBottom: '23px', width: "100%" }}
                                    value={pollRoundVoters[index].phoneNumber[0].phoneNumber}
                                    error={phoneIndex == index && phoneError}
                                    helperText={phoneIndex == index && phoneError && t('Invalid_Mobile_Number')}
                                    onChange={(e) => addMultiVoters(e, index, 'phoneNumber')}
                                    showLabel={true}
                                    inputComponent={CustomPhoneNumber}
                                /> */}
                                <MuiPhoneNumber
                                    id="phonenumber"
                                    name="phonenumber"
                                    label={t("Phone_Number")}
                                    defaultCountry={"in"}
                                    value={pollRoundVoters[index].phoneNumber[0].phoneNumber}
                                    helperText={phoneIndex == index && phoneError && t('Invalid_Mobile_Number')}
                                    error={phoneIndex == index && phoneError}
                                    onChange={(e, country) => addMultiVoters(e, index, 'phoneNumber', country)}
                                    autoFormat={false} fullWidth
                                    countryCodeEditable={false}
                                />
                                <Tooltip title='Close'>
                                    <IconButton id='close' onClick={() => cancelRow(index)} className={classes.button}>
                                        <CloseIcon id='closeIcon' style={{ border: '1px solid red', padding: '4px', borderRadius: '30px' }} />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        </Paper>
                    )
                })}
            </Card>
            <Button id='button' variant='contained' color='primary' style={{ float: 'right', margin: '0px 10px 10px 0px' }} onClick={() => saveAuthRep()}>save</Button>
        </div>
    )
}
export default MultiVoter