import React, { useEffect, useState } from 'react';
import { Button, Card, IconButton, InputAdornment, makeStyles, Paper, Tooltip, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { TextField, Stack, Slide } from '@mui/material';
import EmailIcon from '@material-ui/icons/Email';
import PersonIcon from '@material-ui/icons/Person';
import { useTranslation } from 'react-i18next';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { AxiosEvotingInterceptor } from '../../../config/axios.interceptor';
import CustomPhoneNumber from '../poll-round-voter-row/CustomPhoneNumber';
import { UserContext } from "../../../common/context/UserContext";
import SearchVoter from '../search-voter/SearchVoter';
import MuiPhoneNumber from "material-ui-phone-number";
import { DefaultTelephoneAreaCode } from '../../../common/GenericCodesValues';
import './AuthRep.css';

const useStyle = makeStyles(theme => ({
    div: {
        alignSelf: 'center',
        padding: '1rem 0rem 0rem 0.5rem',
    },
    option: {
        background: 'rgb(237, 250, 237)',
        margin: '0rem 0rem 0rem 22rem',
        width: '20%',
        border: 'red'
    },
    save: {
        padding: '0rem 2rem 0rem 0rem',
        margin: '0rem 0rem 0rem 0rem',
    },
    button: {
        color: 'red',
        margin: '0rem 0rem 0rem 0rem !important',
        '&:hover': {
            background: 'none'
        },
    },
    Paper: {
        '&:hover': {
            boxShadow: '0 5px 27px -5px rgba(50,50,93,0.25),0 10px 16px -8px rgba(0,0,0,0.3)',
        },
        margin: '0rem 0rem 0.4rem -0.6rem !important',
        padding: '0.5rem 0rem 0.3rem 0.5rem',
        borderRadius: '10px',
    },
    CardStyle: {
        maxHeight: '400px',
        overflowY: 'scroll',
        margin: '10px'
    }
}))

const MultipleAuthRep = (props) => {
    const { closeAddAuthrep, setNewAuthRep, setLoading, increaseAuthreps, setAuthRepErrors, setPhoneErrors,
        authRepEmailErrors, authRepNameErrors, setAuthRepEmailErrors, setAuthRepNameErrors } = props;
    const context = React.useContext(UserContext);  
    const [pollRoundVoters, setPollRoundVoters] = useState(increaseAuthreps);
    const classes = useStyle()
    const { t } = useTranslation();
    const [errors, setErrors] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [voterEmailIndex, setvoterEmailIndex] = useState(null)
    const [voterNameIdex, setvoterNameIndex] = useState(null)
    const [refreshAuth, setRefreshAuth] = useState(null)
    const [emailError, setEmailError] = useState(false)
    const [phoneIndex, setvoterPhoneIndex] = useState(null)
    const [authRepNameEdit, setAuthrepNameEdit] = useState(false)
    const [extension, setExtension] = useState(`+${context?.userSession?.session?.phoneExtension === 1 ? 
        context?.userSession?.session?.phoneExtension + DefaultTelephoneAreaCode.CANADA : context?.userSession?.session?.phoneExtension}`)

    const cancelRow = (id) => {
        setPhoneError(false)
        setEmailError(false)
        setAuthRepEmailErrors(false)
        setAuthRepNameErrors(false)
        var voters = [...increaseAuthreps]
        if (increaseAuthreps.length === 1) {
            if (props.AuthRepForExistingVoter) {
                props.handleClose(false)
                // closeAddAuthrep(false)
            }
            else {
                // props.closeMultiVoter(false)
                closeAddAuthrep()
            }
        } else {
            voters.splice(id, 1);
            setNewAuthRep(voters)
            setRefreshAuth(true)
        }
    }


    const addMultiVoters = (e, index, id, country) => {
        if (pollRoundVoters.length > 0) {
            if (id === 'name') {
                pollRoundVoters[index].name = e.target.value
            } else if (id === 'email') {
                let value = e.target.value.trim().toLowerCase();
                // setAuthRepEmailErrors(false)
                pollRoundVoters[index].email[0].email = value;
                var pattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (value.match(pattern)) {
                    setEmailError(false)
                    setAuthRepErrors(false)
                    setAuthRepEmailErrors(false)
                } else {
                    setvoterEmailIndex(index)
                    setEmailError(true)
                    setAuthRepEmailErrors(false)
                    setAuthRepErrors(true)
                }
            } else if (id === 'phoneNumber') {
                setvoterPhoneIndex(index)
                if (e != undefined && e.target.value != ''){
                    pollRoundVoters[index].phoneNumber[0].phoneNumber = e.target.value
                    pollRoundVoters[index].countryCode = extension
                    if (pollRoundVoters[index].phoneNumber[0].phoneNumber && isValidPhoneNumber(extension + e.target.value)) {
                        setPhoneError(false)
                        setPhoneErrors(false)
                    } else {
                        setPhoneErrors(true)
                        setPhoneError(true)
                    }
                }  else {
                    setAuthRepErrors(false)
                    setPhoneError(false)
                    setPhoneErrors(false)
                    pollRoundVoters[index].phoneNumber[0].phoneNumber = ''
                }
            }
        }
        setRefreshAuth(e)
    }

    useEffect(() => {
        setPollRoundVoters(pollRoundVoters)
        setAuthrepNameEdit(true) // for edit authrep name mannuvally
    }, [refreshAuth])

    const setSelectedVoter = (selectedVoter, index) => {
        if (selectedVoter != '') {
            setEmailError(false)
            setAuthRepErrors(false)
            setAuthRepEmailErrors(false)
            setPhoneError(false)
            setPhoneErrors(false)
            setLoading(true)
            AxiosEvotingInterceptor.get(`user/${selectedVoter.id}`).then((response) => {
                const fullName = response.name + " " + ((response.restOfName != null) ? response.restOfName : '')
                pollRoundVoters[index].name = fullName.trim()
                pollRoundVoters[index].email[0].email = response.preferredEmail
                pollRoundVoters[index].phoneNumber[0].phoneNumber = selectedVoter.nationalNumber
                pollRoundVoters[index].countryCode = "+" + selectedVoter.countryCode
                setRefreshAuth(selectedVoter.id)
                setLoading(false)
            }).catch((error) => {
                console.log("error,,", error.emailError)
            })
        }
    }

    useEffect(() => {
        setPollRoundVoters(props.increaseAuthreps)
    }, [props.increaseAuthreps])

    const onMobileNumberChange = (value, index) =>{
        pollRoundVoters[index].countryCode = value
        setExtension(value)
      }

    return (
        <div >
            {pollRoundVoters.map((value, index) => {
                return (
                    <Slide direction="left" id='slide' in={true} mountOnEnter unmountOnExit >
                        <Paper style={{ overflow: 'hidden', marginBottom: '10px' }}>
                            <Stack direction='row' spacing={2} style={{ padding: '0.6rem 0.6rem 0.1rem 0.6rem', marginBottom: '1rem' }} >
                                <SearchVoter name={pollRoundVoters[index].name} onSelect={(e) => setSelectedVoter(e, index)} width={"270px"}
                                    MultiVoterAuthrep={true} setAuthrepNameEdit={setAuthrepNameEdit} searchFilterName={authRepNameEdit && pollRoundVoters[index].name}
                                    id="name" label={t('Name')} variant="standard" index={index}
                                    addMultiVoters={addMultiVoters}
                                    value={pollRoundVoters[index].name}
                                    roleSearch={false}
                                    placeholder={t("Search_By_Email_For_Existing_User")}
                                    // error={(voterNameIdex != null ? voterNameIdex : props.voterNameIdex) == index && (authRepNameErrors)}
                                    helperText='placeholder'
                                    fullWidth={true} size='small'
                                    InputProps={
                                        <InputAdornment position="start">
                                            <PersonIcon />
                                        </InputAdornment>
                                    }
                                />
                                <TextField id='email' label='Email' required variant='standard' size='small' value={pollRoundVoters[index].email[0].email}
                                    fullWidth
                                    error={(voterEmailIndex != null ? voterEmailIndex : props.voterEmailIndex) == index && (emailError || (authRepEmailErrors))}
                                    placeholder={t("Enter_Email")}
                                    helperText={(voterEmailIndex != null ? voterEmailIndex : props.voterEmailIndex) == index && (emailError ? t('Enter_valid_email') : '' || (authRepEmailErrors) ? t('Email_is_required') : '')}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start" id='emailcn'>
                                                <EmailIcon id='mailIcon' />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={(e) => addMultiVoters(e, index, 'email')} />
                                <TextField 
                                    id="nationalNumber"
                                    name="nationalNumber"
                                    label={t("Phone_Number")}
                                    variant="standard" fullWidth
                                    size='small'
                                    error={phoneIndex == index && phoneError}
                                    helperText={phoneIndex == index && phoneError && t('Invalid_Mobile_Number')}
                                    value={pollRoundVoters[index].phoneNumber[0].phoneNumber}
                                    onChange={(e, country) => addMultiVoters(e, index, 'phoneNumber', country)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                            <MuiPhoneNumber
                                            id="input-with-icon-adornment"
                                            name="extension"
                                            defaultCountry={"in"}
                                            value={pollRoundVoters[index].countryCode}
                                            onChange={(event) => onMobileNumberChange(event, index)}
                                            autoFormat={false}
                                            style={{margin: "8px 0px", color: "black !important", 
                                            width: extension.length > 3 ? extension.length === 4 ? '4.8rem' : extension.length === 5 ? '5.3rem' : '6.3rem' : '4rem'}}
                                            required
                                            disableDropdown={false}
                                            countryCodeEditable={false}
                                            disabled
                                            InputProps={{
                                                disableUnderline: true,
                                            }}
                                    />
                                            </InputAdornment>
                                        ),
                                    }}
                                    />
                                {/* <MuiPhoneNumber
                                    id="phonenumber"
                                    name="phonenumber"
                                    label={t("Phone_Number")}
                                    defaultCountry={"in"}
                                    value={pollRoundVoters[index].phoneNumber[0].phoneNumber}
                                    helperText={phoneIndex == index && phoneError && t('Invalid_Mobile_Number')}
                                    error={phoneIndex == index && phoneError}
                                    onChange={(e, country) => addMultiVoters(e, index, 'phoneNumber', country)}
                                    autoFormat={false} fullWidth variant='standard' size='small'
                                    countryCodeEditable={false}
                                /> */}
                                <Tooltip title='Close'>
                                    <IconButton id='close' onClick={() => cancelRow(index)} className={classes.button}>
                                        <CloseIcon id='closeIcon' style={{ border: '1px solid red', padding: '4px', borderRadius: '30px' }} />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        </Paper>
                    </Slide>
                )
            })}
        </div>
    )
}
export default MultipleAuthRep