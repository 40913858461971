import { Button, Tooltip } from "@material-ui/core";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Fragment, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import PollQuestionDetail from "../poll-question-detail/PollQuestionDetail";
import { AxiosEvotingInterceptor } from "../../../config/axios.interceptor";
import EligoBackdrop from "../../../common/EligoBackdrop";
import { Close } from "@material-ui/icons";
import EligoSnackBar from "../../../common/EligoSnackBar";

const AddPollQuestion = (props) => {
    const { pollId, open, onCancel, onClose, questionNumber, editQuestion, isEdit, realTimeResult,hasSystemAdmineOrSupportRole,
        title, pollType, totalVoterCount, noOfVotedNotVoted, pollStatus, startTime, endTime, hasPermission, 
        filePermission, listTitle, pollClass } = props;
    const questionRef = useRef(); //reference for Polls Component to call list after select search
    const [dependentQuestions, setDependentQuestions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({
        showMsg: false,
        message: null,
        severity: null
    });

    const {t} = useTranslation();

    const listDependentQuestions = () => {
        AxiosEvotingInterceptor.get(`question/${pollId}?page=${1}&size=${200}&asc=false`).then(
            (response) => {
                if (response && response.questions.length > 0) {
                    response.questions.map((q) => {
                        setDependentQuestions(prevState => ([
                            ...prevState,
                            {
                                question: q.question,
                                id: q.id
                            }
                        ]))
                    })
                }
                setLoading(false);
            }).catch(error => {
                setLoading(false);
                // if (error.message) {
                //     setMessage({ showMsg: true, message: error.message, severity: 'error' });
                // }
            })
    }

    // useEffect(() => {
    //     setLoading(true);
        // listDependentQuestions();
    // }, [])

    return (
        <Fragment>
            {<Dialog open={open} maxWidth='xl' fullWidth>
                <DialogTitle>
                    {!isEdit ? `${t('Add')} ${title} : ${questionNumber}` : `${title} : ${questionNumber}`}
                    <Tooltip title='Close' arrow>
                        <Close onClick={onCancel} size='large' color='error' style={{ float: 'right', cursor: 'pointer' }} />
                    </Tooltip>
                </DialogTitle>
                <DialogContent dividers sx={{padding: '10px 14px'}}>
                    <PollQuestionDetail ref={questionRef} onClose={onClose} pollId={pollId} hasSystemAdmineOrSupportRole={hasSystemAdmineOrSupportRole}
                        editQuestion={isEdit ? editQuestion : null} isEdit={isEdit} dependentQuestions={dependentQuestions}
                        pollType={pollType} questionNumber={questionNumber} title={title} totalVoterCount={totalVoterCount }
                        noOfVotedNotVoted={noOfVotedNotVoted} pollStatus={pollStatus} startTime={startTime} endTime={endTime} hasPermission={hasPermission}
                        realTimeResult={realTimeResult} filePermission={filePermission} listTitle={listTitle}
                        pollClass={pollClass}/>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="primary" onClick={onCancel}>{(hasPermission || hasSystemAdmineOrSupportRole )? t('Cancel') : t('Close')}</Button>
                    {(hasPermission || hasSystemAdmineOrSupportRole) && <Button variant="contained" color="primary" onClick={() => questionRef.current.handleCreateUpdate()}>{isEdit ? t('Update') : t('Save')}</Button>}
                </DialogActions>
                {loading && <EligoBackdrop show={loading} />}
                {message.showMsg && <EligoSnackBar show={message.showMsg} message={message.message} severity={message.severity} />}
            </Dialog>}
        </Fragment>
    )
}

export default AddPollQuestion;