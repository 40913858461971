import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    switchCheckbox: {
        height: 0,
        width: 0,
        visibility: 'hidden',
        '&:checked + .slabel .sbutton': {
            left: 'calc(100% - 2px)',
            transform: 'translateX(-100%)'
        }
    },
    switchLabel: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
        width: '60px',
        height: '30px',
        background: 'grey',
        borderRadius: '100px',
        position: 'relative',
        transition: 'background-color 0.3s ease',
        '&:active .sbutton': {
            width: '60px'
        }
    },
    switchButton: {
        content: "",
        position: 'absolute',
        top: '2px',
        left: '2px',
        width: '25px',
        height: '25px',
        borderRadius: '45px',
        transition: 'all 0.3s ease',
        background: '#fff',
        boxShadow: '0 0 2px 0 rgba(10, 10, 10, 0.29)'
    }
}))

export const CustomSwitch = ({ isOn, handleToggle, colorOne, colorTwo }) => {
    const classes = useStyles();
    return (
        <>
            <input
                checked={isOn}
                onChange={handleToggle}
                className={classes.switchCheckbox}
                id={`switch`}
                type="checkbox"
            />
            <label
                style={{ background: isOn ? colorOne : colorTwo }}
                className={`${classes.switchLabel} slabel`}
                htmlFor={`switch`}
            >
                <span className={`${classes.switchButton} sbutton`} />
            </label>
        </>
    );
}
