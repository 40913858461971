import { Box, Button, Card, CardActions, CardContent, Divider, Grid, makeStyles, Paper, Step, StepLabel, Stepper, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import AddPollRound from '../add-poll-round/AddPollRound';
// import AddPollQuestion from '../../poll-question/add-poll-question/AddPollQuestion';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PollContext } from '../../../common/context/PollContext';
import { TypeOfPoll, TypeOfPollCategory } from '../../../common/GenericCodes';
import PollQuestionList from '../../poll-matter/poll-question-list/PollQuestionList';
import PollRoundVoterList from '../../poll-round-voter/poll-round-voter-list/PollRoundVoterList';
import './InitialPoll.css';
import FileUploadDownload from '../../file-upload-download/FileUploadDownload';

const useStyles = makeStyles((theme) => ({
    root: {
        // margin: theme.spacing(2)
    },
    paper: {
        borderRadius: '0px',
        // overflow: 'scroll'
    },
    cardContent: {
        borderRadius: '0px',
        minHeight: '530px',
        maxHeight: '530px',
        // overflowY: 'auto'
    },
    boxShadowCard: {
        boxShadow: 'rgb(255 255 255 / 10%) 0px 5px 10px 0px inset, rgb(152 152 162 / 25%) 0px 30px 50px -20px, rgb(130 130 174 / 30%) 0px 30px 30px -30px !important'
    },
    actions: {
        // backgroundColor: 'white'
    },
    nextBtn: {
        marginLeft: theme.spacing(1),
        float: 'right'
    },
    backBtn: {
        marginLeft: theme.spacing(1),
        float: 'left'
    }
}))

const InitiatePollRound = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const context = useContext(PollContext);
    const [activeStep, setActiveStep] = React.useState(0);
    const [pollDetails, setPollDetails] = React.useState({
        id: '',
        referenceNumber: '',
        title: '',
        description: '',
        showResultsToVoters: false,
        weightedVotes: false,
        geoTagging: false,
        geoFencing: false,
        questionShuffle: false,
        anonymousVoting: false,
        websiteRedirect: false,
        discloseVoterInResult: true,
        status: null,
        startTime: null,
        endTime: null,
        resultPostOnPollClose: false,
        startManually: false,
        alertTime: null,
        alerted: false,
        voteWithSignUp: false,
        announceResultAutomatically: false,
        allowVoteChange: false,
        displayOrganizerName: true,
        scrutinizerHasToOpen: false,
        verifyWithOtp: true,
        organizerPersonId: '',
        organizerName: '',
        organizerEmail: '',
        organizerPhoneNumber: '',
        pollType: props.location.state.type ? props.location.state.type : 'POLL',
        conductForPersonId: '',
        questionShuffle: '',
        realTimeResult: '',
        pollCategory: '',
        secreteBallot: false,
        zoneId: 'Asia/Kolkata',
        zoneDisplayName: ''
    });
    const [noOfVoters, setNoOfVoters] = React.useState(0);

    const [pollRoundQuestionDetails, setPollRoundQuestionDetails] = React.useState({
        poll_question_id: '',
        sequence: 0,
        poll_round_id: '',
        question: '',
        allow_vote_change: true,
        allow_multiple_vote: false,
        allow_question_skip: false,
        shuffle_options: false,
        threshold: 0,
        PollQuestionResponses: [
            {
                poll_question_response_id: '',
                option: t('Default_Option_1'),
                poll_question_id: '',
                sequence: 0
            },
            {
                poll_question_response_id: '',
                option: t('Default_Option_2'),
                poll_question_id: '',
                sequence: 0
            },
            {
                poll_question_response_id: '',
                option: t('Default_Option_3'),
                poll_question_id: '',
                sequence: 0
            }
        ]
    });

    const getSteps = (t) => {
        if (props.location.state.type === TypeOfPoll.POLL) {
            return [t('Poll_Details'), 'Questions', t('Voters'), t('Files')];
        } else {
            return [t('Survey_Details'), t('Survey_Questions'), t('Survey_Voters')];
        }
    }

    const steps = getSteps(t);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleCancel = () => {
        context.setPollDetails(prevState => ({
            ...prevState,
            selectedRole: 'organizer',
            selectedList: 'SETUP'
        }))
        props.history.push({
            pathname: '/polls',
            state: {
                pollType: props.location.state.pollType
            }
        })
        // props.history.push({
        //     pathname: '/polls/poll-rounds',
        //     state: {
        //         pollDetails: props.location.state.pollDetails,
        //         currentPage: props.location.state.currentPage,
        //         roles: props.location.state.roles,
        //         pollType: props.location.state.pollType
        //     }
        // });
    }

    const handleCreatePoll = (poll) => {
        setPollDetails(poll);
        handleNext();
    }

    const getStepContent = (stepIndex) => {
        switch (stepIndex) {
            case 0:
                return (
                    <AddPollRound pollDetails={pollDetails}
                        // nextPollRoundNumber={props.location.state.nextPollRound}
                        handleCancel={handleCancel} handleNext={handleNext}
                        handleCreatePoll={handleCreatePoll}
                        page={props.location.state.currentPage}
                        limit={props.location.state.limit}
                    />
                );
            case 1:
                return (
                    <Card className={classes.boxShadowCard}>
                        <CardContent className={classes.cardContent}>
                            {/* <PollQuestions pollId={pollDetails.id} hasPermission={true} uploadPermission={true} status='new'></PollQuestions> */}
                            <PollQuestionList pollId={pollDetails.id} hasPermission={true} pollType={pollDetails.pollCategory} realTimeResult={false}
                                filePermission={true} status="NEW" pollClass={pollDetails.pollType}/>
                        </CardContent>
                        <Divider />
                        <CardActions className={classes.actions}>
                            <Grid item xs={12}>
                                <Button id='back' type="submit" size="small" variant="outlined" color="primary"
                                    className={classes.backBtn} onClick={handleBack} >
                                    {t('Back')}
                                </Button>
                                {pollDetails.id !== '' && <Button id="next" type="submit" size="small" variant="contained" color="primary"
                                    className={classes.nextBtn} onClick={handleNext}>
                                    {t('Next Page')}
                                </Button>}
                                <Button type="submit" id='close' size="small" variant="contained"
                                    className={classes.nextBtn} onClick={handleCancel}>
                                    {t('Close')}
                                </Button>
                            </Grid>
                        </CardActions>
                    </Card>
                    // <AddPollQuestion pollRoundId={pollRoundDetails.event_id} stepper={true} isEdit={true}
                    //     pollRoundQuestionDetails={pollRoundQuestionDetails} handleCancel={handleCancel} handleNext={handleNext}
                    //     handleBack={handleBack} handleCreatePollRoundQuestion={handleCreatePollRoundQuestion} hasPermission={true}/>
                );
            case 2:
                return (
                    <Card className={classes.boxShadowCard}>
                        <CardContent className={classes.cardContent}>
                            <PollRoundVoterList pollId={pollDetails.id} hasPermission={true} pollDetails={pollDetails} paperHeight={430}
                                pollStatus={pollDetails.status ? pollDetails.status : null} setNoOfVoters={setNoOfVoters} 
                                pollClass={pollDetails.pollType}/>
                        </CardContent>
                        <Divider />
                        <CardActions className={classes.actions}>
                            <Grid item xs={12}>
                                <Button type="submit" id='backbutton' size="small" variant="outlined" color="primary"
                                    className={classes.backBtn} onClick={handleBack} >
                                    {t('Back')}
                                </Button>
                                {(pollDetails.id !== '' && props.location.state.type === TypeOfPoll.POLL) && <Button id="next" type="submit" size="small" variant="contained" color="primary"
                                    className={classes.nextBtn} onClick={handleNext}>
                                    {t('Next Page')}
                                </Button>}
                                <Button type="submit" id='closebutton' size="small" variant="contained"
                                    className={classes.nextBtn} onClick={handleCancel}>
                                    {t('Close')}
                                </Button>
                            </Grid>
                        </CardActions>
                    </Card>
                )
            case 3: 
                return (
                    <Card className={classes.boxShadowCard}>
                        <CardContent className={classes.cardContent} style={{overflow: 'scroll'}}>
                            <FileUploadDownload
                                t={t}
                                poll_id={pollDetails.id}
                                poll_question_id={null}
                                questionTitle={(TypeOfPollCategory.IBC === pollDetails.pollCategory) ? t('Resolution') : t('Question')}
                                isMultiple={true}
                                // hasSystemAdmineOrSupportRole={(context.userSession.session.roles?.includes(UserRole.Role_Admin)
                                //     || context.userSession.session.roles?.includes(UserRole.Role_Support))}
                                pollStatus={pollDetails.status}
                                isUpload={true}
                                isDownload={true}
                                deletePermission={true}
                            />
                        </CardContent>
                        <Divider />
                        <CardActions className={classes.actions}>
                            <Grid item xs={12}>
                                <Button type="submit" id='backbutton' size="small" variant="outlined" color="primary"
                                    className={classes.backBtn} onClick={handleBack} >
                                    {t('Back')}
                                </Button>
                                <Button type="submit" id='closebutton' size="small" variant="contained"
                                    className={classes.nextBtn} onClick={handleCancel}>
                                    {t('Close')}
                                </Button>
                            </Grid>
                        </CardActions>
                    </Card>
                )
            default:
                return null;
        }
    }

    return (
        <Stack paddingX={{ xs: '16px', sm: '16px', md: '24px', lg: '24px' }} direction='column'>
            <Paper className='stepper'>
                <Stack direction='row' justifyContent='space-around'>
                    <Typography variant='h6' style={{ marginTop: '1.5%', color: 'cornsilk' }}>
                        <strong>{pollDetails.pollType === TypeOfPoll.POLL ? t('Setup_Poll') : t('Setup_Survey')}</strong>
                    </Typography>
                    <Stepper activeStep={activeStep} alternativeLabel style={{ width: '75%', borderRadius: '90px 10px 90px 0px', padding: '5px 0px' }}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label === 'Questions' ? (pollDetails.pollCategory === TypeOfPollCategory.IBC ? t('Resolutions') : t(label)) : label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Stack>
            </Paper >
            <Box sx={{ mx: 5, mt: 1 }}>
                {getStepContent(activeStep)}
            </Box>
        </Stack >
    )
}

export default InitiatePollRound;