const ClientFields = {
    name: 'name',
    email: 'email',
    phoneNumber: 'phoneNumber',
    identificationGst: 'identificationGst',
    identificationPan: 'identificationPan'
}

const IdentificationTypes = {
    pan: 'pan',
    cin: 'cin',
    aadhar: 'aadhar',
    gstin: 'gstin',
    other: 'other'
}

export { ClientFields, IdentificationTypes };