import { Typography, makeStyles } from "@material-ui/core";
import { Button, Stack, Tooltip, MenuItem, IconButton, Grid,
    InputLabel, FormControl, Select, ListSubheader } from "@mui/material";
import React, { Fragment, useState, useEffect } from "react";
import RoundResult from "../round-result/RoundResult";
import { useTranslation } from "react-i18next";
import GenerateResult from "./GenerateResult";
import EligoSnackBar from "../../../../common/EligoSnackBar";
import { TbFileAlert } from "react-icons/tb";
import { 
    AxiosEvotingInterceptor,
    APIEvotingAxiosDownloadInterceptor 
} from "../../../../config/axios.interceptor";
import DownloadIcon                         from '@material-ui/icons/CloudDownload';
import DeleteIcon                           from '@material-ui/icons/Delete';
import EligoConfirmDialog                   from '../../../../common/EligoConfirmDialog';
import EligoBackdrop from "../../../../common/EligoBackdrop";

const useStyles = makeStyles(() =>({
    previewText:{
        textAlign:'center',
        fontWeight:'bold',
        marginTop:'10px'
    },
    resultPage:{
        background: 'white', 
        borderRadius: '5px', 
        padding: '7px 10px', 
        boxShadow: 'rgb(174 203 230) 0px 0px 8px'
    },
    pswProtect:{
        fontWeight: 'bold', 
        color: '#ed6c02', 
        textAlign: 'center', 
        alignItems: 'center', 
        display: 'flex'
    }

}))

const PreviewResult = (props) => {
    const classes = useStyles()
    const { t } = useTranslation();
    const [loadingReport, setLoadingReport] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const { resultWithVoters, userId, pollDetails, pollId} = props;
    const [openGenerateResultDialog, setOpenGenerateResultDialog] = useState(false);
    const [resultResponse, setResultResponse] = useState('');
    const [withNameReport, setWithNameReport] = useState([]);
    const [withoutNameReport, setWithoutNameReport] = useState([])
    const [selectedVersion, setSelectedVersion] = useState('')
    const [message, setMessage] = useState({
        show: false,
        message: '',
        severity: ''
    })
    const [seekConfirmation, setSeekConfitmation] = useState({
        show: false,
        title: '',
        message: '',
        onAgree: '',
        onDisAgree: '',
        isCancel: true
    })

    const setResultResponseWithPassword = (response, password) => {
        setResultResponse(response);
        getReportVersions(53).then(getReportVersions(52));
    }

    const getReportVersions = async (reportCd) => {
        setLoading(true);
        AxiosEvotingInterceptor.get(`report/list-versions/${pollId}/${reportCd}`).then(response => {
            setLoading(false);
            if (reportCd === 53) {
                let prevVersion = 0
                setWithoutNameReport(response.map(version => {
                    prevVersion++;
                    return {
                        deletedOn: version.deletedOn,
                        fileVersionId: version.fileVersionId,
                        numberOfPages: version.numberOfPages,
                        password: version.password,
                        reportId: version.reportId,
                        reportVersionId: version.reportVersionId,
                        rowCreatedOn: version.rowCreatedOn,
                        location: version.location,
                        versionNumber: (response.length + 1) - prevVersion
                    }
                }))
            } else if (reportCd === 52) {
                let prevVersion = 0
                setWithNameReport(response.map(version => {
                    prevVersion++;
                    return {
                        deletedOn: version.deletedOn,
                        fileVersionId: version.fileVersionId,
                        numberOfPages: version.numberOfPages,
                        password: version.password,
                        reportId: version.reportId,
                        reportVersionId: version.reportVersionId,
                        rowCreatedOn: version.rowCreatedOn,
                        location: version.location,
                        versionNumber: (response.length + 1) - prevVersion
                    }
                }));
            }
            if (response.length > 0) {
                setSelectedVersion(response[0].fileVersionId);
            }
        }).catch(error => {
            setLoading(false);
            console.log('error', error);
        })
    }

    const getReportByVersion = (fileVersionId, location) => {
        setMessage({show: false, message: '', severity: ''});
        setLoadingReport(true);
        APIEvotingAxiosDownloadInterceptor.post(`download-report/${pollId}/version/${fileVersionId}/false?key=${location}`, {}).then(res => {
            setResultResponse(res);
            setLoadingReport(false);
        }).catch(error => {
            if (error.message) {
                setMessage({show: true, message: error.message, severity: 'error'});
            } else {
                setMessage({show: true, message: t('Try_Again'), severity: 'error'});
            }
            setLoadingReport(false);
        })
    }

    const download = () => {
        setMessage({show: false, message: '', severity: ''});
        if(resultResponse) {
            try {
                const url = window.URL.createObjectURL(new Blob([resultResponse]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${pollDetails.title}.pdf`);
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(url); 
            } catch(error) {
                if (error.message) {
                    setMessage({show: true, message: error.message, severity: 'error'});
                } else {
                    setMessage({show: true, message: t('Try_Again'), severity: 'error'});
                }
            }
        }
    }

    const deleteConfiration = () => {
        let fileVersion;
        if (selectedVersion != null) {
            fileVersion = withoutNameReport.find(version => version.fileVersionId === selectedVersion);
            if (fileVersion === undefined) {
                fileVersion = withNameReport.find(version => version.fileVersionId === selectedVersion);
            }
        }
        if (fileVersion) {
            setSeekConfitmation({
                show: true,
                title: t('Confirmation'),
                message: t('Are_You_Sure_That_You_Want_To_Delete') + " " + t('Version') + " " + fileVersion.versionNumber + "?",
                onAgree: () => deleteVersion(fileVersion.reportVersionId),
                onDisAgree: () => resetSeekConfirmation(),
                isCancel: true
            })
        }
    }

    const resetSeekConfirmation = () => {
        setSeekConfitmation({
            show: false,
            title: '',
            message: '',
            onAgree: null,
            onDisAgree: null
        })
    }

    const deleteVersion = (selectedVersion) => {
        setMessage({show: false, message: '', severity: 'error'});
        setLoading(true);
        AxiosEvotingInterceptor.delete(`delete-version/${pollId}/version/${selectedVersion}`).then(response => {
            setMessage({show: true, message: t('Report_Version_Deleted_Successfully'), severity: 'success'});
            getReportVersions(53).then(getReportVersions(52));
        }).catch(error => {
            setLoading(false);
            if (error.message) {
                setMessage({show: true, message: error.message, severity: 'error'});
            } else {
                setMessage({show: true, message: t('Try_Again'), severity: 'error'});
            }
        })
        resetSeekConfirmation();
    }

    useEffect(() => {
        getReportVersions(53).then(getReportVersions(52));
    }, [])

    useEffect(() => {
        if (selectedVersion) {
            let fileVersion = withoutNameReport.find(version => version.fileVersionId === selectedVersion);
            if (fileVersion) {
                getReportByVersion(fileVersion.fileVersionId, fileVersion.location);
            } else {
                fileVersion = withNameReport.find(version => version.fileVersionId === selectedVersion);
                if (fileVersion) {
                    getReportByVersion(fileVersion.fileVersionId, fileVersion.location);
                }
            }
        }
    }, [selectedVersion])
    
    
    return(
        <Fragment>
            {(withNameReport.length > 0 || withoutNameReport.length > 0) ?
            <Fragment>
                    <div style={{marginBottom: '0.3rem'}}>
                        <Grid container style={{padding: '4px'}}>
                            <Grid item xs={4}>
                                {((withNameReport.length > 0 || withoutNameReport.length > 0)) && <FormControl size="small" sx={{minWidth: 120 }}>
                                    <InputLabel id="select-version-label">
                                        {t('Version')}
                                    </InputLabel>
                                    <Select labelId="select-version-label" id="select-version" value={selectedVersion}
                                        onChange={(event) => {setSelectedVersion(event.target.value)}} label={t('Version')}>
                                            {withoutNameReport.length > 0 && <ListSubheader>{t('Without_Voter_Name')}</ListSubheader>}
                                            {withoutNameReport.map((version, index) => (
                                                <MenuItem key={version.fileVersionId} value={version.fileVersionId}>{`${t('Version')} ${version.versionNumber}`}</MenuItem>
                                            ))}
                                            {withNameReport.length > 0 && <ListSubheader>{t('With_Voter_Name')}</ListSubheader>}
                                            {withNameReport.map((version1, index) => (
                                                <MenuItem key={version1.fileVersionId} value={version1.fileVersionId}>{`${t('Version')} ${version1.versionNumber}`}</MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>}
                                {selectedVersion && <Fragment><Tooltip title={t('Download')}>
                                    <IconButton id="download-button" color="primary" onClick={download} aria-label="download"
                                            style={{marginLeft: '6px'}}>
                                        <DownloadIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={t('Delete')}>
                                    <IconButton id="delete-button" color="error" onClick={deleteConfiration} aria-label="delete"
                                            style={{marginLeft: '6px'}}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                                </Fragment>}
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant='h6' className={classes.previewText}>Preview</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Button id="download-result" color='primary' size='small' variant='contained'
                                    onClick={() => setOpenGenerateResultDialog(true)} className="float-right">
                                    <img src={process.env.PUBLIC_URL + '/pdf-file (2).svg'}/>&nbsp; {(withNameReport.length > 0 || withoutNameReport.length > 0) ? t('Re_Generate_Result') : t('Generate_Result')}
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                    {(resultResponse && !loadingReport) && <RoundResult pollRoundIds={pollId} resultResponse={resultResponse}
                        resultWithVoters={resultWithVoters} />}
            </Fragment>
                :
                <Stack direction='column' spacing={2} justifyContent='center' alignItems='center' width='100%' height='75vh'>
                    <Stack direction='row' spacing={3} className={classes.resultPage}>
                        <img src={process.env.PUBLIC_URL + '/pdf-file.svg'}/>
                        <Stack direction="column" spacing={1} mt={1} alignItems='start' justifyContent='center'>
                            <Typography className='result' style={{ fontWeight: 'bold' , textAlign: 'center'}} color='textSecondary'>
                                {t('Generate_And_Download_Result_File_In_Pdf_It_Will_Be_Previewed')}
                            </Typography>
                        </Stack>
                    </Stack>
                    <Button id="download-result" color='primary' size='small' variant='contained'
                        onClick={() => setOpenGenerateResultDialog(true)} style={{ width: 'fit-content'}}>
                        <img src={process.env.PUBLIC_URL + '/pdf-file (2).svg'}/>&nbsp; {t('Generate_Result')}
                    </Button>
                </Stack>
            }
            {openGenerateResultDialog && <GenerateResult show={openGenerateResultDialog} pollId={pollId} userId={userId} onClose={setOpenGenerateResultDialog}
            pollDetails={pollDetails} setResultResponseWithPassword={setResultResponseWithPassword}/>}
            {message.show && <EligoSnackBar show={message.show} message={message.message} severity={message.severity} />}
            {seekConfirmation.show && <EligoConfirmDialog show={seekConfirmation.show}
                title={seekConfirmation.title} message={seekConfirmation.message}
                onAgree={seekConfirmation.onAgree} isCancel={true} onDisAgree={seekConfirmation.onDisAgree}/>}
            {(loadingReport || loading) && <EligoBackdrop show={(loadingReport || loading)}/>}
        </Fragment>
    )
}

export default PreviewResult