import { Avatar, AvatarGroup, Grid, Stack, Typography } from "@mui/material";
import { Fragment, useCallback, useState } from "react";
import "./RealTimeResult.css"
import { useTranslation } from "react-i18next";



const RankRealTimeResult = (props) => {
    const { options, totalVoterCount, noOfVoterVoted } = props;
    const { t } = useTranslation();
    let i = 0;
    let j = 0;
    const backgroundPyramid = [
        "#FA9189", "#FCAE7C", "#FFE699", "#F9FFB5", "#B3F5BC", "#D6F6FF", "#E2CBF7", "#D1BDFF",
        "#FA9189", "#FCAE7C", "#FFE699", "#F9FFB5", "#B3F5BC", "#D6F6FF", "#E2CBF7", "#D1BDFF",
        "#FA9189", "#FCAE7C", "#FFE699", "#F9FFB5", "#B3F5BC", "#D6F6FF", "#E2CBF7", "#D1BDFF",
        "#FA9189", "#FCAE7C", "#FFE699", "#F9FFB5", "#B3F5BC", "#D6F6FF", "#E2CBF7", "#D1BDFF",
        "#FA9189", "#FCAE7C", "#FFE699", "#F9FFB5", "#B3F5BC", "#D6F6FF", "#E2CBF7", "#D1BDFF",
        "#FA9189", "#FCAE7C", "#FFE699", "#F9FFB5", "#B3F5BC", "#D6F6FF", "#E2CBF7", "#D1BDFF"
    ]

    return (
        <Fragment>
            <Typography color="primary" sx={{ fontWeight: 'bold' }}>{t('Real-Time Result')}</Typography>
            <Grid container>
<Grid item xs={8}>
                    <div class="pyramid">
                        {options.map((opt, index) => {
                            if (backgroundPyramid.length > index && backgroundPyramid.length === i) i = 0;
                            else i = i + 1;
                            console.log('backgroundPyramid[i]', backgroundPyramid[i - 1])
                            return (
                                <div id="rank" style={{ background: backgroundPyramid[i - 1] }}>
                                    {/* <div><span class="arrowtext">{opt.option}</span></div> */}
                                </div>

                            )
                        })}
                    </div>

</Grid>
<Grid item xs={4}>
                    <Stack spacing={1}>
                        {options.map((opt, index) => {
                            if (backgroundPyramid.length > index && backgroundPyramid.length === j) j = 0;
                            else j = j + 1;
                            return (
                                <Stack direction="row" spacing={1}>
                                    <div style={{ width: '1.5em', height: '1.5em', background: backgroundPyramid[j - 1] }}> </div>
                                    <Typography variant="subtitle2" color="textSecondary">Rank {j}</Typography>
                                    <Typography variant="subtitle2" color="CaptionText">{opt.option}</Typography>
                                </Stack>

                            )
                        })}
                    </Stack>
</Grid>
            </Grid>
            <Stack mt={2} width="fit-content" direction="row" alignItems="center" spacing={2}>
                <AvatarGroup total={5} spacing='medium' max={3}>
                    <Avatar alt="Remy Sharp" />
                    <Avatar alt="Travis Howard" />
                </AvatarGroup>
                <Typography color="GrayText">{noOfVoterVoted} Voted out of {totalVoterCount} voters</Typography>
            </Stack>
        </Fragment>
    )
}

export default RankRealTimeResult;