import 'date-fns';
import React, { Component } from 'react';
import {
    Container, Typography, TextField, Button, Grid,
    Paper, FormControlLabel, Checkbox, Link, CircularProgress,
    InputAdornment, IconButton, FormGroup, Switch, Card, Portal, Divider, Box,
    FormControl, InputLabel, ButtonGroup, Input, FormHelperText
} from '@material-ui/core';
import { TrendingUpTwoTone, Visibility, VisibilityOff } from '@material-ui/icons';
import { Auth } from 'aws-amplify';
import EligoSnackBar from '../common/EligoSnackBar';
import TermsAndConditions from './TermsAndConditions';
import MuiPhoneNumber from "material-ui-phone-number";
import * as Validators from '../common/form-validators.js';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import EligoBackdrop from '../common/EligoBackdrop';
import Password from './forgot-password/Password';
import PoweredBy from './PoweredBy';
import { RootDiv } from '../common/evoting-styles/EvotingStyle';
import { ApplicationClientId } from '../common/GenericCodes';
import { AuthAxiosInterceptor } from '../config/axios.interceptor';
import { withTranslation } from 'react-i18next';
import EligoReCaptcha from '../common/EligoReCaptcha';

export class Register extends Component {
    constructor() {
        super();
        this.state = {
            name: "",
            username: "",
            email: "",
            // telephoneCode: "",
            extension: "+91",
            phonenumber: "",
            anchorEl: null,
            open: false,
            // helperText:false,
            password: "",
            pwdLength: false,
            isNumber: false,
            isLower: false,
            isUpper: false,
            isSpecial: false,
            confirmpassword: "",
            referredByNesl: "",
            acceptterms: false,
            loading: false,
            showPassword: false,
            showConfirmPassword: false,
            showTermsAndConditions: false,
            cognito: {
                showMsg: false,
                message: null,
                severity: null
            },
            errors: {
            },
            isValidForm: false,
            individual: true,
            refreshCaptcha: false,
            captcha: '',
        };
    }

    toggleShowPassword = () => this.setState({ showPassword: !this.state.showPassword });

    toggleShowConfirmPassword = () => this.setState({ showConfirmPassword: !this.state.showConfirmPassword });

    validate = {
        name: name => Validators.requiredValidation('Name', name),
        password: password => Validators.requiredValidation('Password', password),
        confirmpassword: confirmpassword => (Validators.requiredValidation('Confirm Password', confirmpassword)
            || Validators.equalValidation('Confirm Password', this.state.password, confirmpassword)),
        email: email => (Validators.requiredValidation('Username', email) || Validators.emailValidation(email)),
        phonenumber: phonenumber => (Validators.requiredValidation('Mobile Number', phonenumber) || Validators.loginMobileNumberValidation('Mobile Number', this.state.extension +''+ phonenumber))
    };

    handleSubmit = event => {
        event.preventDefault();
        this.validateForm().then(data => {
            if (Object.values(data.errors).length === 0) {
                this.signUp();
            }
        })
    }

    handlePopoverOpen = (event) => {
        this.setState({
            //  anchorEl:event.currentTarget,
            open: true,
            //  helperText:true
        }, () => {
            console.log(">>>>>>>>>", this.state.open)
        })
    };

    handlePopoverClose = () => {
        this.setState({
            anchorEl: null,
            open: false
        })
    };

    signUp = async () => {
        this.setState({ loading: true });
        this.setState({ cognito: { showMsg: false, message: null, severity: null } });
        const { password, email, name, phonenumber, referredByNesl, individual, captcha, extension } = this.state;
        let payload = {
            firstName: name.replace(/^\s+|\s+$/gm, '').replace(/\s\s+/g, ' '),
            email: email,
            phoneNumber: extension + '' + phonenumber,
            password: password,
            registeredClientId: ApplicationClientId.eVoting,
            authorities: ['SCOPE_read'],
            individual: individual
        }
        AuthAxiosInterceptor.post(`user/create-account-with-captcha?recaptcha=${captcha}`, payload).then(response => {
            this.setState({ loading: false });
            this.props.history.push({
                pathname: '/sign-up/success',
                state: { emailid: email, phoneNumber: extension + '' + phonenumber, isVerifyLater: true }
            })
        }).catch(error => {
            this.setState({
                loading: false,
                refreshCaptcha: true
            });
            setTimeout(() => {
                this.setState({
                    refreshCaptcha: false
                });
            }, 1000);
            this.setState({ loading: false });
            if (error.message) {
                this.setState({ cognito: { showMsg: true, message: error.message, severity: 'error' } });
            } else {
                this.setState({ cognito: { showMsg: true, message: 'Try again', severity: 'error' } });
            }
        })
        // try {
        //     Auth.signUp({
        //         username: email,
        //         password: password,
        //         attributes: {
        //             email: email,
        //             name: name.replace(/^\s+|\s+$/gm,'').replace( /\s\s+/g, ' ' ),
        //             phone_number: phonenumber,
        //             'custom:Referrer': referredByNesl ? 'nesl' : 'default'
        //         }
        //     }).then(res => {
        //         this.setState({ loading: false });
        //         this.props.history.push({
        //             pathname: '/sign-up/success',
        //             state: { emailid: email, phoneNumber: phonenumber }
        //         })
        //     }, error => {
        //         this.setState({ loading: false });
        //         this.setState({ cognito: { showMsg: true, message: error.message, severity: 'error' } });
        //     });
        // } catch (error) {
        //     this.setState({ loading: false });
        //     this.setState({ cognito: { showMsg: true, message: error.message, severity: 'error' } });
        // }
    }

    checkForNum = (string) => {
        let matchs = string.match(/\d+/g);
        this.setState({
            isNumber: matchs != null ? true : false
        })

    }
    checkForUpper = (string) => {
        let matchs = string.match(/[A-Z]/);
        this.setState({
            isUpper: matchs != null ? true : false
        })
    }
    checkForLower = (string) => {
        let matchs = string.match(/[a-z]/);
        this.setState({
            isLower: matchs != null ? true : false
        })
    }
    checkSpecialChar = (string) => {
        let symbol = new RegExp(/[^A-Z a-z 0-9]/);
        this.setState({
            isSpecial: symbol.test(string) ? true : false
        })
    }
    onInputChangePwd = (event) => {
        let field = event.target.value
        console.log("field...", field)
        this.checkForNum(field)
        this.checkForUpper(field)
        this.checkSpecialChar(field)
        this.checkForLower(field)
        this.setState({
            // [field] : event.target.value,
            password: field,
            pwdLength: field.length > 7 ? true : false,
            open: true
        })
    }
    onInputChange = (event) => {
        let value = event.target.value;
        if (event.target.id === 'email') {
            value = value.trim().toLowerCase();
        }
        this.setState({
            [event.target.id]: value
        })
    }

    onInputMobileNumberChange = (event) => {
        if (/^[0-9+]+$/.test(event.target.value)) {
            this.setState({
                [event.target.id]: event.target.value
            })
        } else if (event.target.value.length === 0){
            this.setState({
                [event.target.id]: ''
            })
        }
    }


    // onInputChangeEmail = (event) => {
    //     this.setState({
    //         email:event.target.value
    //     })
    // }

    onMobileNumberChange = (fieldName, value) => {
        this.setState({
            [fieldName]: value
        });
    }


    showTermsAndCond = (event) => {
        this.setState({
            showTermsAndConditions: true
        });
    }

    onCheckboxChange = (isAccept) => {
        this.setState({
            acceptterms: isAccept,
            showTermsAndConditions: false
        });
    }

    validateForm = async () => {
        this.setState(prevState => ({
            ...prevState,
            errors: {}
        }));
        let formErrors = { errors: {}, touched: {} };
        Object.entries(this.state).map(([key]) => {
            if (key === 'email' || key === 'name' || key === 'phonenumber' || key === 'password' || key === 'confirmpassword') {
                const newError = this.validate[key](this.state[key])
                formErrors = {
                    errors: {
                        ...formErrors.errors,
                        ...(newError && { [key]: newError }),
                    }
                }
            }
        })
        this.setState(prevState => ({
            ...prevState,
            errors: formErrors.errors
        }));
        return formErrors;
    }

    setReferredByNesl = () => {
        this.setState({
            referredByNesl: !this.state.referredByNesl
        });
    }

    signIn = () => {
        this.props.history.push("/sign-in");
    }

    onPartyTypeChange = (value) => {
        this.setState({
            individual: value
        });
    }

    setCaptcha = event => {
        this.setState({
            captcha: event
        });
    }

    render() {
        const { t } = this.props;
        let { pwdLength, isNumber, isUpper, isSpecial, open, isLower, password } = this.state
        return (
            <RootDiv>
                <Container component="main" maxWidth="xs">
                    <Grid container spacing={0} alignItems="center" justify="center" style={{ minHeight: "100vh" }}>
                        <Paper>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '16px' }}>
                                <img id="registerimg" src={process.env.PUBLIC_URL + '/evote-logo.svg'} style={{ width: '60%', padding: '6px' }} alt="ACTA logo" />
                                {/* <Typography><span className='welcome-logo-font' style={{paddingBottom: '2rem'}}>eVoting</span></Typography> */}

                                <Grid container style={{ paddingTop: '24px', paddingBottom: '12px' }}>
                                    <Grid item xs={12}>
                                        <Typography id="register" component="h1" variant="h5">
                                            Sign Up
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <form onSubmit={this.handleSubmit} noValidate style={{ width: '100%' }}>
                                    <Grid container className="text-center" style={{ padding: '12px 0px 0px 0px' }}>
                                        <Grid item xs={12}>
                                            <ButtonGroup variant="outlined" aria-label="text button group" size="small">
                                                <Button id="individual" color="primary" variant={this.state.individual ? "contained" : "outlined"}
                                                    onClick={() => this.onPartyTypeChange(true)}>{t('Individual')}</Button>
                                                <Button id="institute" color="primary" variant={!this.state.individual ? "contained" : "outlined"}
                                                    onClick={() => this.onPartyTypeChange(false)}>{t('Institution')}</Button>
                                            </ButtonGroup>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <TextField id="name" label="Name" style={{ margin: '8px 8px 8px 0px' }}
                                                fullWidth value={this.state.name} required
                                                onChange={this.onInputChange} error={this.state.errors['name'] !== undefined}
                                                helperText={this.state.errors['name']}
                                                disabled={this.state.loading}
                                            />
                                            <TextField id="email" label={t("User_Id_(Email Address)")}
                                                style={{ margin: '8px 8px 8px 0px' }} fullWidth value={this.state.email}
                                                onChange={this.onInputChange} helperText={this.state.errors['email']}
                                                error={this.state.errors['email'] !== undefined} autoComplete="new-password" required
                                                disabled={this.state.loading}
                                            />
                                            <FormControl fullWidth variant="standard">
                                                <InputLabel required htmlFor="phonenumber"
                                                    error={this.state.errors['phonenumber'] !== undefined}>Mobile Number</InputLabel>
                                                <Input
                                                    required
                                                    id="phonenumber"
                                                    name="phonenumber"
                                                    value={this.state.phonenumber}
                                                    // helperText={this.state.errors['phonenumber']}
                                                    error={this.state.errors['phonenumber'] !== undefined}
                                                    onChange={this.onInputMobileNumberChange}
                                                    startAdornment={<InputAdornment position="start"
                                                        style={{width: this.state.extension.length > 5 ? (this.state.extension.length * 8) + '%' : (this.state.extension.length * 10) + '%'}}>
                                                        <MuiPhoneNumber
                                                            id="input-with-icon-adornment"
                                                            name="extension"
                                                            defaultCountry={"in"}
                                                            required
                                                            value={this.state.extension}
                                                            helperText={this.state.errors['extension']}
                                                            error={this.state.errors['extension'] !== undefined}
                                                            onChange={(event) => this.onMobileNumberChange('extension', event)}
                                                            autoFormat={false}
                                                            style={{ margin: '8px 0px 8px 0px', color: 'black !important' }}
                                                            disableDropdown={this.state.loading}
                                                            countryCodeEditable={false}
                                                            disabled
                                                        />
                                                    </InputAdornment>}
                                                    disabled={this.state.loading}
                                                    autoComplete="new-password"
                                                    aria-describedby="phonenumber-helper-text"
                                                />
                                                <FormHelperText id="phonenumber-helper-text" style={{color: 'red'}}>{this.state.errors['phonenumber']}</FormHelperText>
                                            </FormControl>
                                            {/* <FormControl variant="standard">
                                                <InputLabel htmlFor="input-with-icon-adornment">
                                                Mobile Number
                                                </InputLabel>
                                                <MuiPhoneNumber
                                                    id="input-with-icon-adornment"
                                                    name="extension"
                                                    defaultCountry={"in"}
                                                    value={this.state.extension}
                                                    helperText={this.state.errors['extension']}
                                                    error={this.state.errors['extension'] !== undefined}
                                                    onChange={(event) => this.onMobileNumberChange('extension', event)}
                                                    autoFormat={false}
                                                    style={{ margin: '8px 8px 8px 0px' }}
                                                    required
                                                    disabled
                                                    disableDropdown={this.state.loading}
                                                    countryCodeEditable={false}
                                                    autoComplete="new-password"
                                                />
                                            </FormControl> */}

                                            <TextField id="password" label="Password" type={this.state.showPassword ? "text" : "password"}
                                                style={{ margin: '8px 8px 8px 0px' }} fullWidth value={this.state.password}
                                                onChange={(event) => this.onInputChangePwd(event)} helperText={pwdLength && isUpper && isLower && isSpecial && isNumber ? null : this.state.errors['password']}
                                                error={this.state.errors['password'] !== undefined}
                                                onBlur={this.handlePopoverClose}
                                                onClick={this.handlePopoverOpen}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={() => { this.handlePopoverClose(); this.toggleShowPassword() }}
                                                                onBlur={this.handlePopoverClose}
                                                            >
                                                                {this.state.showPassword ? <Visibility id="registervisible" /> : <VisibilityOff id="registerVisibieOff" />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                                autoComplete="new-password" required disabled={this.state.loading}
                                            />
                                            {/* <InputAdornment position="end">
                                                    <IconButton
                                                    id="signup"
                                                            style={{position:'relative',top:'-40px',left:'305px'}}
                                                            aria-label="toggle password visibility"
                                                            onClick={this.toggleShowPassword}
                                                            onBlur={this.handlePopoverClose}
                                                        >
                                                            {this.state.showPassword ? <Visibility id="registervisible"/> : <VisibilityOff id="registerVisibieOff"/>}

                                                    </IconButton>
                                                </InputAdornment> */}


                                            <Password open={open} pwdLength={pwdLength} isUpper={isUpper} isLower={isLower} isNumber={isNumber} isSpecial={isSpecial} />

                                            <TextField id="confirmpassword" label="Confirm Password" type={this.state.showConfirmPassword ? "text" : "password"}
                                                style={{ margin: '8px 8px 8px 0px' }} fullWidth value={this.state.confirmpassword}
                                                onChange={this.onInputChange} helperText={this.state.errors['confirmpassword']}
                                                error={this.state.errors['confirmpassword'] !== undefined}
                                                required disabled={this.state.loading}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={this.toggleShowConfirmPassword}
                                                            >
                                                                {this.state.showConfirmPassword ? <Visibility id="visible" /> : <VisibilityOff id="visibleoff" />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}

                                            />

                                            <br /><br />
                                            {/* <FormGroup row>
                                                <FormControlLabel
                                                    control={
                                                    <Switch
                                                    id="registerswitch"
                                                        checked={this.state.resultWithVoters}
                                                        onChange={this.setReferredByNesl}
                                                        name="referrer"
                                                        color="primary"
                                                    />
                                                    }
                                                    label="Referred by NeSL"
                                                />
                                            </FormGroup> */}
                                            <FormControlLabel control={
                                                <Checkbox onChange={this.showTermsAndCond}
                                                    id="accept-terms" checked={this.state.acceptterms} color="primary" style={{ margin: '8px 8px 8px 0px' }}
                                                />
                                            }
                                                label="Accept Terms & Conditions" disabled={this.state.loading}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button id="registersign-up" type="submit" size="medium" variant="contained" color="primary" style={{ margin: '8px 8px 8px 0px' }} fullWidth
                                                disabled={this.state.loading || !this.state.acceptterms}>
                                                Sign Up
                                                {/* {this.state.loading && <CircularProgress style={{ position: 'absolute' }} size={14} thickness={4.5} />} */}
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} container justify="center">
                                            <Typography id="backtosignin" variant="body2">
                                                Already have an account? <Link onClick={this.signIn} className="pointer">Sign in</Link>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </form>
                            </div>
                            <PoweredBy />
                        </Paper>
                    </Grid>
                </Container>
                {this.state.loading && <EligoBackdrop show={this.state.loading} />}
                {this.state.showTermsAndConditions &&
                    <TermsAndConditions show={this.state.showTermsAndConditions} acceptTermsAndConditions={this.onCheckboxChange}></TermsAndConditions>}
                {this.state.cognito.showMsg && <EligoSnackBar show={this.state.cognito.showMsg} message={this.state.cognito.message}
                    severity={this.state.cognito.severity}></EligoSnackBar>}
                {!this.state.refreshCaptcha && <EligoReCaptcha captcha={this.setCaptcha} refresh={() => this.setState({
                    refreshCaptcha: false
                })} />}
            </RootDiv>
        )
    }
}

export default withTranslation()(Register);