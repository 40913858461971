import React, {
    Fragment
}                                       from 'react';
import {
    FormControlLabel,
    Grid,
    Typography
}                                       from '@material-ui/core';
import { useTranslation }               from 'react-i18next';
import IOSSwitch                        from '../../../common/Switch';
import { Status, TypeOfPoll, TypeOfPollCategory }                     from '../../../common/GenericCodes';

const PollAdvanceSettings = (props) => {
    const { t } = useTranslation();

    const handleDataBoolean = (event) => {
        props.handleDataBoolean(event)
    }

    return (
        <Fragment>
            <Grid item xs={6}>
                <Grid container direction="row" alignItems="center">
                    <Grid item xs={10}>
                        <Typography variant="subtitle2" style={{fontWeight: 'bold'}}>{t('Allow_Recast')}</Typography>
                        <Typography variant="caption" display="block" style={{color: 'gray'}}>{t('Allow_Recast_Helper_Text')}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControlLabel checked={props.pollDetail.allowVoteChange} onChange={handleDataBoolean}
                            control={<IOSSwitch sx={{ m: 1 }}  id="allowVoteChange"/>}
                            disabled={props.disabled} style={{float: 'right'}}/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={6}>
                <Grid container direction="row" alignItems="center">
                    <Grid item xs={10}>
                        <Typography variant="subtitle2" style={{fontWeight: 'bold'}}>{t('Allow_Voter_to_Vote_With_SignUp')}</Typography>
                        <Typography variant="caption" display="block" style={{color: 'gray'}}>{t('Allow_Voter_to_Vote_With_SignUp_Helper_Text')}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControlLabel checked={props.pollDetail.voteWithSignUp} onChange={handleDataBoolean}
                            control={<IOSSwitch sx={{ m: 1 }}  id="voteWithSignUp"/>}
                            disabled={props.disabled || !props.isBeforePublish} style={{float: 'right'}}/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={6}>
                <Grid container direction="row" alignItems="center">
                    <Grid item xs={10}>
                        <Typography variant="subtitle2" style={{fontWeight: 'bold'}}>{t('Only_Scrutinizer_Should_Open_Result')}</Typography>
                        <Typography variant="caption" display="block" style={{color: 'gray'}}>{t('Scrutinizer_Has_To_Open_Helper_Text')}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControlLabel checked={props.pollDetail.scrutinizerHasToOpen} onChange={handleDataBoolean}
                            control={<IOSSwitch sx={{ m: 1 }}  id="scrutinizerHasToOpen"/>}
                            disabled={props.disabled} style={{float: 'right'}}/>
                    </Grid>
                </Grid>
            </Grid>
            {props.pollDetail.pollCategory !== TypeOfPollCategory.IBC && <Grid item xs={6}>
                    <Grid container direction="row" alignItems="center">
                        <Grid item xs={10}>
                            <Typography variant="subtitle2" style={{fontWeight: 'bold'}}>{t('Question_Shuffling')}</Typography>
                            <Typography variant="caption" display="block" style={{color: 'gray'}}>{t('Question_Shuffling_Helper_Text')}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel checked={props.pollDetail.questionShuffle} onChange={handleDataBoolean}
                                control={<IOSSwitch sx={{ m: 1 }}  id="questionShuffle"/>}
                                disabled={props.disabled} style={{float: 'right'}}/>
                        </Grid>
                    </Grid>
                </Grid>}
            <Grid item xs={6}>
                <Grid container direction="row" alignItems="center">
                    <Grid item xs={10}>
                        <Typography variant="subtitle2" style={{fontWeight: 'bold'}}>{t('Show_Real_Time_Result')}</Typography>
                        <Typography variant="caption" display="block" style={{color: 'gray'}}>{t('Show_Real_Time_Result_Helper_Text')}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControlLabel checked={props.pollDetail.realTimeResult} onChange={handleDataBoolean}
                            control={<IOSSwitch sx={{ m: 1 }}  id="realTimeResult"/>}
                            disabled={props.disabled} style={{float: 'right'}}/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={6}>
                <Grid container direction="row" alignItems="center">
                    <Grid item xs={10}>
                        <Typography variant="subtitle2" style={{fontWeight: 'bold'}}>
                            {props.pollType === TypeOfPoll.POLL ? t('Secret_Ballot') : t('Secret_Survey')}</Typography>
                        <Typography variant="caption" display="block" style={{color: 'gray'}}>
                            {props.pollType === TypeOfPoll.POLL ? t('Secret_Ballot_Helper_Text') : t('Secret_Survey_Helper_Text') }</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControlLabel checked={props.pollDetail.secreteBallot} onChange={handleDataBoolean}
                            control={<IOSSwitch sx={{ m: 1 }}  id="secreteBallot"/>}
                            disabled={props.disabled} style={{float: 'right'}}/>
                    </Grid>
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default PollAdvanceSettings;