import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Box, Drawer, Stack, Typography } from "@mui/material";
import { MenuBar, MenuListIcon, MenuListItem, MenuListText } from "../../nav-bar/side-nav/side-nav-style/SideNavStyle";
import { pollMenuItemsForVoter } from "../../nav-bar/side-nav/SideNav";

export const MobileSideNav = (props) => {
    const { openDrawer, setOpenDrawer, selectedList, listChangeHandler } = props
    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpenDrawer(open);
    };
    return (
        <Drawer
            anchor='left'
            open={openDrawer}
            onClose={toggleDrawer}
        >
            <Box
                sx={{ width: 250 }}
                role="presentation"
                onClick={toggleDrawer(false)}
                onKeyDown={toggleDrawer(false)}
            >
                <IconButton onClick={toggleDrawer(false)} style={{ float: 'right' }}>
                    <Close />
                </IconButton>
                <MenuBar>
                    {pollMenuItemsForVoter.map(item => (
                        <MenuListItem button
                            onClick={() => { listChangeHandler(item.value) }} isselected={selectedList === item.value ? true : false}>
                            <Stack direction={'row'} justifyContent='center' alignItems='center'>
                                <MenuListIcon className='icon'>
                                    {item.icon}
                                </MenuListIcon>
                                <MenuListText className='text'>
                                    <Typography style={{ margin: '0px 0px 0px 15px' }}>{item.title}</Typography>
                                </MenuListText>
                            </Stack>
                        </MenuListItem>
                    ))}
                </MenuBar>
            </Box>
        </Drawer >
    )
};