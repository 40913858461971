import { Button, Card, CardContent, Dialog, DialogTitle, FormLabel, Grid, IconButton, InputAdornment, makeStyles, Slider, styled, TextField, Tooltip, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/AddOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { Stack } from '@mui/material';
import { color } from '@mui/system';
import React, { Fragment, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EligoSnackBar from '../../../common/EligoSnackBar';
import FieldHelperText from '../../../common/FieldHelperText';
import FileUploadDownload from '../../file-upload-download/FileUploadDownload';
import './PollQuestionDetail.css';

const useStyles = makeStyles((theme) => ({
    actions: {
        backgroundColor: 'white'
    },
    cardContent: {
        borderRadius: '0px',
        padding: '10px !important'
        // overflow: 'hidden',
    },
    addOption: {
        float: 'right',
        padding: '0px'
    },
    nextBtn: {
        float: 'right',
        marginRight: "15px",
        marginTop: '2rem',
        color: 'white',
        background: 'rgb(123, 67, 151)',
        border: '1.8px solid rgb(123, 67, 151)',
        '&:hover': {
            background: 'rgb(123, 67, 151)',
            color: 'white',
        }

    },

    closeBtn: {
        float: 'right',
        marginRight: "15px",
        marginTop: '2rem',
        color: '#2a83ad;',
        background: 'white',
        border: '1.8px solid #2a83ad;',
        '&:hover': {
            background: 'white',
        }
    },

    deleteBtn: {
        float: 'right',
        marginRight: "15px",
        marginTop: '2rem',
        boxShadow: '5px 5px 4px rgba(0,0,0,0.3)',
        color: 'red',
        background: 'white',
        border: '1.8px solid red',
        '&:hover': {
            background: 'white',
        }
    },
    backBtn: {
        marginLeft: theme.spacing(0.6),
        float: 'left'
    },
    card: {
        minHeight: '140px',
        maxHeight: '140px',
        overflowY: 'auto',
        padding: '5px'
    },
    indexNum: {
        border: ' 1px solid black',
        borderRadius: '40px',
        padding: '5px 10px 5px 10px',
        margin: '0.2rem 0.5rem 0rem 0.5rem',
        background: 'rgb(220, 178, 241)'
    },
    index: {
        margin: '0.2rem 0rem 0rem 0rem'
    },
    options: {
        marginLeft: '1rem',
        color: '#AA336A',
        fontSize: '20px'
    }
}));

export const FieldSet = styled('fieldset')(({ theme }) => ({
    background: theme.palette.background.paper,
    borderRadius: '10px',
    padding: '1rem 1.5rem',
    border: '1px solid rgb(220 215 215)',
    boxShadow: '0 2px 24px rgb(0 0 0 / 5%)'
}))

export const Legend = styled('legend')(({ theme }) => ({
    background: 'rgb(123 67 151 / 8%)',
    color: 'rgb(123, 67, 151)',
    borderRadius: '10px',
    padding: '5px 10px',
    fontSize: '12px'
}))

const PollQuestionDetail = (props) => {
    const { t } = useTranslation();
    const ref = useRef(null);
    const classes = useStyles()
    const [openUploadFile, setOpenUploadFile] = useState(false);
    const [openListFile, setOpenListFile] = useState(false);
    const [totalFiles, setTotalFile] = useState(0)
    const [message, setMessage] = React.useState({
        showMsg: false,
        message: null,
        severity: null
    })

    const marks = [
        {
            value: 0,
            label: '0%',
        },
        {
            value: 100,
            label: '100%',
        },
    ];

    const handleAddOption = () => {
        props.handleAddOption();
    }

    const handleDeleteOption = (option, index) => {
        props.handleDeleteOption(option, index);
    }

    const onJsonInputChange = event => {
        props.onJsonInputChange(event);
    }

    const onJsonInputChangeArray = (event, index) => {
        props.onJsonInputChangeArray(event, index);
    }

    const handleDataBoolean = (event) => {
        props.handleDataBoolean(event);
    }

    const handleSlider = (event, newValue) => {
        props.handleSlider(event, newValue)
    }

    const handleInputChange = (event) => {
        props.onInputChange(event)
    }

    const handleBlur = (fieldName, value, index) => {
        props.handleBlur(fieldName, value, index);
    }

    const handleUploadDone = () => {
        setOpenUploadFile(false);
        setMessage({ showMsg: true, message: `${t('Q')}.#${props.index + 1} files uploaded successfully`, severity: 'success' })
    }

    const handleUploadClose = () => {
        setOpenUploadFile(false);
    }

    const handleScrollToEnd = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    }

    return (
        <div>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Stack alignItems='center' justifyContent='center' direction='row'>
                        <Stack width={{ xs: '14%' }}><FormLabel htmlFor='question' required={props.isEdit} size='small'>{t('Question')}:</FormLabel></Stack>
                        <Stack width={{ xs: '86%' }}>
                            <TextField id="question" size='small'
                                multiline rows={2} rowsMax={3} variant="outlined" value={props.pollRoundQuestionDetail.question}
                                error={props.touched['question'] && props.errors.question}
                                helperText={props.touched['question'] && props.errors.question}
                                onBlur={() => handleBlur('question', props.pollRoundQuestionDetail.question)}
                                onChange={handleInputChange} fullWidth disabled={!props.isEdit}
                            />
                        </Stack>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack alignItems='center' justifyContent='center' direction='row' my={1}>
                        <Stack width={{ xs: '14%' }}><FormLabel htmlFor='question_description' size='small'>{t('Question_Description')}:</FormLabel></Stack>
                        <Stack width={{ xs: '86%' }}>
                            <TextField id="question_description" size='small'
                                multiline rows={3} rowsMax={4} variant="outlined" value={props.pollRoundQuestionDetail.question_description}
                                error={props.touched['question_description'] && props.errors.question_description}
                                helperText={props.touched['question_description'] && props.errors.question_description}
                                onBlur={() => handleBlur('question_description', props.pollRoundQuestionDetail.question_description)}
                                onChange={handleInputChange} fullWidth disabled={!props.isEdit}
                                onClick={handleScrollToEnd}
                            />
                        </Stack>
                    </Stack>
                </Grid>
                {/* <Card style={{ padding: '5px' }} > */}
                <Grid item xs={12}>
                    <Stack direction='row' justifyContent='space-between'>
                        <Stack>
                            {props.isEdit && <Typography id='add' aria-label="add-poll-question-option" color="primary" className={classes.options} >
                                {t('Options')}
                            </Typography>}
                        </Stack>
                        <Stack>
                            {props.isEdit && <Button id='addOption' aria-label="add-poll-question-option" color="primary" className={classes.addOption}
                                onClick={handleAddOption}>
                                <AddIcon fontSize='small' /> {t('Options')}
                            </Button>}
                        </Stack>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardContent className={classes.card}>
                            {/* <div> */}
                            {
                                props.pollRoundQuestionDetail.PollQuestionResponses.map((option, index) => {
                                    return (
                                        <Grid item xs={12} key={index}>
                                            <Stack direction='row' pb={0.4} justifyContent='center' alignItems='center'>
                                                {/* <Stack width={{ xs: '10%' }}><FormLabel htmlFor={`option${index}`} required={props.isEdit} size='small'>{`${t('Option')} #${index + 1}`}:</FormLabel></Stack> */}
                                                <Stack className={classes.indexNum}>{index + 1}</Stack>
                                                <Stack width={{ xs: '100%' }}>
                                                    <TextField id="option" className={classes.index}
                                                        value={props.pollRoundQuestionDetail.PollQuestionResponses[index].option}
                                                        size='small'
                                                        multiline
                                                        // defaultValue={index + 1}
                                                        variant="outlined" fullWidth required={props.isEdit}
                                                        onChange={(event) => { onJsonInputChangeArray(event, index) }}
                                                        error={props.touched['option' + index] && props.errors['option' + index]}
                                                        helperText={props.touched['option' + index] && props.errors['option' + index]}
                                                        onBlur={() => handleBlur('option', props.pollRoundQuestionDetail.PollQuestionResponses[index].option, index)}
                                                        disabled={!props.isEdit}
                                                        InputProps={{
                                                            style: { borderStyle: 'none' },
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    {props.isEdit && <IconButton id='deleteIcone'
                                                                        aria-label="delete-option"
                                                                        onClick={() => { handleDeleteOption(option, index) }}
                                                                    >
                                                                        <DeleteIcon color="secondary" />
                                                                    </IconButton>}
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                </Stack>
                                            </Stack>
                                        </Grid>)
                                })
                            }
                            {/* </div> */}
                        </CardContent>
                    </Card>
                </Grid>
                {/* </Card> */}
            </Grid>

            {/* <br /> */}
            <Grid container spacing={1} style={{ marginTop: '10px' }}>
                {/* <Grid item xs={12} sm={4}>
                        <Chip
                            id="allow_multiple_vote"
                            label="Allow multiple vote"
                            color={props.pollRoundQuestionDetail.allow_multiple_vote ? "primary" : "default"}
                            variant="outlined"
                            clickable={props.pollRoundQuestionDetail.allow_multiple_vote}
                            onClick={handleDataBoolean}
                            icon={props.pollRoundQuestionDetail.allow_multiple_vote ? <CheckBox /> : <CheckBoxOutlineBlank /> }
                        />
                    </Grid> */}
                {/* <Grid item xs={12} sm={4}>
                        <Chip
                            id="allow_question_skip"
                            label="Allow question skip"
                            color={props.pollRoundQuestionDetail.allow_question_skip ? "primary" : "default"}
                            variant="outlined"
                            clickable={props.pollRoundQuestionDetail.allow_question_skip}
                            onClick={handleDataBoolean}
                            icon={props.pollRoundQuestionDetail.allow_question_skip ? <CheckBox /> : <CheckBoxOutlineBlank />}
                        />
                    </Grid> */}
                {/* <Grid item xs={12} sm={3}>
                        <Chip
                            id="allow_vote_change"
                            label={t("Allow_Vote_Change")}
                            color={props.pollRoundQuestionDetail.allow_vote_change ? "primary" : "default"}
                            variant="outlined"
                            clickable
                            onClick={handleDataBoolean} disabled={!props.isEdit}
                            icon={props.pollRoundQuestionDetail.allow_vote_change ? <CheckBox /> : <CheckBoxOutlineBlank />}
                        />
                    </Grid> */}
                {/* <Grid item xs={12} sm={4}>
                        <Chip
                            id="shuffle_options"
                            label="Shuffle options"
                            color={props.pollRoundQuestionDetail.shuffle_options ? "primary" : "default"}
                            variant="outlined"
                            clickable={props.pollRoundQuestionDetail.shuffle_options}
                            onClick={handleDataBoolean}
                            icon={props.pollRoundQuestionDetail.shuffle_options ? <CheckBox /> : <CheckBoxOutlineBlank />}
                        />
                    </Grid> */}
                <Grid item xs={5}>
                    <FieldSet>
                        <Legend>{t('Threshold')}</Legend>
                        <Stack direction='row' spacing={3}>
                            <Fragment>
                                {/* <FormLabel htmlFor="threshold">{t('Threshold')}</FormLabel> */}
                                <Slider
                                    id="threshold"
                                    aria-labelledby="poll-question-threshold-slider"
                                    step={1}
                                    color='secondary'
                                    valueLabelDisplay="auto"
                                    onChange={handleSlider}
                                    value={props.pollRoundQuestionDetail.threshold}
                                    marks={marks} disabled={!props.isEdit}
                                    size='small'
                                    style={{ marginBottom: '10px' }}
                                />
                            </Fragment>
                            <Fragment>
                                <TextField className="threshold-input" type="number" id="threshold" label=""
                                    ref={ref}
                                    variant="outlined" value={props.pollRoundQuestionDetail.threshold}
                                    error={props.touched['threshold'] && props.errors.threshold}
                                    helperText={props.touched['threshold'] && props.errors.threshold}
                                    onChange={handleInputChange}
                                    onBlur={() => handleBlur('threshold', props.pollRoundQuestionDetail.threshold)}
                                    disabled={!props.isEdit}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                %
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                <FieldHelperText helperText={t('Threshold_Helper_Text')} />
                            </Fragment>
                        </Stack>
                    </FieldSet>
                </Grid>
                {/* {(props.pollRoundQuestionDetail.poll_question_id != null && props.pollRoundQuestionDetail.poll_question_id != undefined && props.pollRoundQuestionDetail.poll_question_id != '') && */}
                <Grid item xs={3}>
                    <FieldSet>
                        <Legend>{`${t('Q')}. #${props.index + 1}`} {t('Files')}</Legend>
                        <Stack spacing={2} direction='row' alignItems='center'>
                            <Button color='primary' style={{ width: '100%' }} onClick={() => setOpenListFile(true)} disabled={!props.isEdit}>View</Button>
                            {(props.status === 'open' || props.status === 'scheduled' || props.status === 'new') &&
                                <Button variant='contained' size='small' color='primary' style={{ width: '100%' }} disabled={!props.isEdit}
                                    onClick={(e) => { e.preventDefault(); setOpenUploadFile(true); }}>Upload
                                </Button>
                            }
                        </Stack>
                    </FieldSet>
                </Grid>
                <Grid item xs={4}>
                    {props.hasPermission &&
                        <Grid item xs={12}>
                            {props.pollRoundQuestionDetail.poll_question_id === '' && !props.stepper && <Button id="create-poll-round-question" type="submit" size="small" variant="contained" color="primary"
                                className={classes.nextBtn} onClick={props.createPollRoundQuestion}>
                                {t('Save')}
                            </Button>}
                            {/* {props.pollRoundQuestionDetail.poll_question_id === '' && props.stepper && <Button id="create-poll-round-question-save" type="submit" size="small" variant="contained" color="primary"
                                className={classes.nextBtn} onClick={props.createPollRoundQuestion}>
                                {t('Save_&_Next')}
                            </Button>} */}
                            {props.isEdit && props.pollRoundQuestionDetail.poll_question_id !== '' && !props.stepper && <Button id="update-poll-round-question" type="submit" size="small" variant="contained" color="primary"
                                className={classes.nextBtn} onClick={props.updatePollRoundQuestion}>
                                {t('Update')}
                            </Button>}
                            {!props.isEdit && props.pollRoundQuestionDetail.poll_question_id !== '' && !props.stepper && <Button id="edit-poll-round-question"
                                type="submit" size="small" variant="contained" color="primary"
                                className={classes.nextBtn} onClick={props.editPollRoundQuestion}>
                                {t('Edit')}
                            </Button>}
                            {/* {props.pollRoundQuestionDetail.poll_question_id !== '' && props.stepper && <Button id="update-poll-round-question-next" type="submit" size="small" variant="contained" color="primary"
                                className={classes.nextBtn} onClick={props.updatePollRoundQuestion}>
                                {t('Update_&_Next')}
                            </Button>}
                            {props.pollRoundQuestionDetail.poll_question_id !== '' && props.stepper && <Button id="next" type="submit" size="small" variant="contained" color="primary"
                                className={classes.nextBtn} onClick={props.handleNext}>
                                {t('Next')}
                            </Button>}
                            {props.pollId !== '' && props.stepper && <Button type="submit" size="small" variant="contained" id='closePage'
                                className={classes.deleteBtn} onClick={props.handleClose}>
                                {t('Close')}
                            </Button>
                            } */}
                            {(props.isEdit || (props.isAddNew && props.pollRoundQuestionDetail.poll_question_id === '')) && props.pollId != '' && !props.stepper && <Button id='cancelPage' type="submit" size="small" variant="outlined" color='primary'
                                className={classes.closeBtn} onClick={props.handleCancel}>
                                {t('Cancel')}
                            </Button>
                            }
                            {/* {props.stepper && <Button id="back" type="submit" size="small" variant="contained"
                                className={classes.nextBtn} onClick={props.handleBack} >
                                {t('Back')}
                            </Button>} */}
                            {/* {props.stepper && <Button id="back" type="submit" size="small" variant="contained"
                                className={classes.nextBtn} onClick={props.handleBack} >
                                {t('Back')}
                            </Button>} */}
                            {!props.isEdit && props.pollRoundQuestionDetail.poll_question_id !== '' && !props.stepper && < Button id='deletePoll' type="submit" size="small" variant="contained" color="secondary"
                                className={classes.deleteBtn} onClick={props.confirmDeletePollQuestion} >
                                {t('Delete')}
                            </Button>}
                        </Grid>
                    }
                </Grid>
                {/* } */}
            </Grid>

            {
                openUploadFile && <FileUploadDownload
                    t={t}
                    title={`${t('Q')}. #${props.index + 1} Upload Files`}
                    poll_id={props.pollId}
                    poll_question_id={props.pollRoundQuestionDetail.poll_question_id}
                    isUpload={true}
                    isDownload={false}
                    handleUploadDone={handleUploadDone}
                    handleUploadClose={handleUploadClose}
                    setTotalFile={setTotalFile}
                />
            }
            {
                openListFile && <Dialog open={openListFile} maxWidth='md'>
                    <DialogTitle>
                        {`${t('Q')}. #${props.index + 1}`} {t('Files')}
                        <Tooltip title='Close' arrow>
                            <Close onClick={() => setOpenListFile(false)} size='large' color='error' style={{ float: 'right', cursor: 'pointer' }} />
                        </Tooltip>
                    </DialogTitle>
                    <FileUploadDownload
                        t={t}
                        poll_id={props.pollId}
                        poll_question_id={props.pollRoundQuestionDetail.poll_question_id}
                        isUpload={false}
                        isDownload={true}
                        deletePermission={(props.status === 'open' || props.status === 'scheduled' || props.status === 'new') && props.uploadPermission}
                    />
                </Dialog>
            }
            {
                message.showMsg && <EligoSnackBar show={message.showMsg} message={message.message}
                    severity={message.severity} />
            }
        </div >
        // </div>
    )
}

export default PollQuestionDetail;
