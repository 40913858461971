import React, {
    useState
}                               from 'react';
import {
    Button,
    Container,
    Grid,
    TextField,
    Typography,
    MenuItem,
    InputAdornment,
    Box
}                               from '@material-ui/core';
import { useTranslation }       from 'react-i18next';
import MuiPhoneNumber           from "material-ui-phone-number";
import { AxiosEvotingInterceptor } from '../../../config/axios.interceptor';
import EligoSnackBar            from '../../../common/EligoSnackBar';
import EligoBackdrop            from '../../../common/EligoBackdrop';
import { PersonType }           from '../../../common/GenericCodes';
import * as Validators          from '../../../common/form-validators.js';

const AddOrganizer = ({onCreate, cancel,setSuccessMessage}) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = React.useState({});
    const [touched, setTouched] = React.useState({});
    const [message, setMessage] = React.useState({
        showMsg: false,
        message: null,
        severity: null
    });
    const [oraganizerDetails, setOrganizerDetails] = useState({
        name: '',
        email: '',
        nationalNumber: '',
        individual: true
    })
    const [extension, setExtension] = useState('+91')

    const validate = {
        name: name => Validators.requiredValidation(t('Name'), name),
        email: email => Validators.requiredValidation(t('Email'), email) || Validators.emailValidation(email),
        nationalNumber: nationalNumber => Validators.requiredValidation(t('Mobile_Number'), nationalNumber) || Validators.mobileNumberValidation(extension + nationalNumber),
    };

    const onInputChange = (event) => {
        setOrganizerDetails(prevState => ({
            ...prevState,
            [event.target.id]: event.target.value
        }))
    }

    const handleMobileNumberChange = (fieldName, event) => {
        setOrganizerDetails(prevState => ({
            ...prevState,
            [fieldName]: event.target.value
        }))
    }

    const validateForm = async () => {
        setErrors({})
        setTouched({})
        let formErrors = { errors: {}, touched: {} };
        Object.entries(oraganizerDetails).map(([key]) => {
            if (key === 'name' || key === 'email' || key === 'nationalNumber') {
                const newError = validate[key](oraganizerDetails[key]);
                const newTouched = { [key]: true };
                return formErrors = {
                    errors: {
                        ...formErrors.errors,
                        ...(newError && { [key]: newError }),
                    },
                    touched: {
                        ...formErrors.touched,
                        ...newTouched
                    }
                }
            }
        })
        setErrors(formErrors.errors);
        setTouched(formErrors.touched);
        return formErrors;
    }

    const handleSave = () => {
        validateForm().then(data => {
            if (Object.values(data.errors).length === 0) {
                let payload = {
                    individual: oraganizerDetails.individual,
                    name: oraganizerDetails.name,
                    email: [{
                        email: oraganizerDetails.email,
                        preferred: true
                    }],
                    phoneNumber: [{
                        phoneNumber:extension + oraganizerDetails.nationalNumber,
                        preferred: true
                    }]
                }
                setSuccessMessage({ showMsg: false, message: '', severity: '' });
                setMessage({ showMsg: false, message: '', severity: '' });
                setLoading(true);
                AxiosEvotingInterceptor.post('poll/create-party/organizer', payload)
                    .then(response => {
                        setLoading(false);
                        setSuccessMessage({ showMsg: true, message: t('Added_Organizer_Successfully'), severity: 'success' });
                        onCreate({
                            id: response.personId,
                            name: oraganizerDetails.name,
                            email: oraganizerDetails.email,
                            phone_number:extension + oraganizerDetails.nationalNumber
                        })
                        handleCancel();
                    })
                    .catch(error => {
                        if (error.message) {
                            setMessage({ showMsg: true, message: error.message, severity: 'error' });
                        }
                        setLoading(false);
                    })
            }
        })
    }

    const handleCancel = () => {
        cancel();
    }

    const onMobileNumberChange = (value) =>{
        setExtension(value)
      }

    return (
        <Container maxWidth='xl' style={{padding: '24px'}}>
            <Typography variant="h6" gutterBottom>{t('Add_Organizer')}</Typography>
            <Grid container spacing={1}>
                {/* <Grid item xs={3}>
                    <TextField id="individual" select size="small" value={oraganizerDetails.individual}
                        onChange={onInputChange} label={t('Type')}>
                        {PersonType.map((type, index) => (
                            <MenuItem key={index} value={type.value}>
                                {type.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid> */}
                <Grid item xs={4}>
                    <TextField id="name" label={t("Name")} required
                        value={oraganizerDetails.name} onChange={onInputChange}
                        error={touched['name'] && errors['name']}
                        helperText={touched['name'] && errors.name}
                        // onBlur={() => handleBlur('organizerName', pollDetail.organizerName)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField id="email" label={t("Email_Address")}
                        value={oraganizerDetails.email} onChange={onInputChange}
                        error={touched['email'] && errors['email']}
                        helperText={touched['email'] && errors.email} required
                        // onBlur={() => handleBlur('organizerEmail', pollDetail.organizerEmail)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}> 
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <TextField 
                    id="nationalNumber"
                    name="nationalNumber"
                    label={t("Phone_Number")}
                    variant="standard" fullWidth
                    error={(errors.nationalNumber)} required
                    helperText={errors.nationalNumber}
                    value={oraganizerDetails.nationalNumber ? oraganizerDetails.nationalNumber : (oraganizerDetails.nationalNumber === '' ? '' : oraganizerDetails.phoneNumber)}
                    onChange={(event, country) => {
                            handleMobileNumberChange('nationalNumber', event, country)
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                            <MuiPhoneNumber
                            id="input-with-icon-adornment"
                            name="extension"
                            defaultCountry={"in"}
                            value={extension}
                            onChange={(event) => onMobileNumberChange(event)}
                            autoFormat={false}
                            style={{margin: "8px 0px", color: "black !important", 
                            width: extension.length > 3 ? extension.length === 4 ? '4.8rem' : extension.length === 5 ? '5.3rem' : '6.3rem' : '4rem'}}
                            required
                            disableDropdown={loading}
                            countryCodeEditable={false}
                            disabled
                            InputProps={{
                                disableUnderline: true,
                            }}
                    />
                            </InputAdornment>
                        ),
                    }}
                    />
                </Box>
                    {/* <MuiPhoneNumber
                        id="phoneNumber"
                        name="phoneNumber"
                        label={t("Mobile_Number")}
                        defaultCountry={"in"}
                        value={oraganizerDetails.phoneNumber}
                        helperText={touched['phoneNumber'] && errors.phoneNumber}
                        error={touched['phoneNumber'] && errors.phoneNumber}
                        onChange={(event) => onMobileNumberChange('phoneNumber', event)}
                        autoFormat={false}
                        fullWidth required
                        countryCodeEditable={false}
                    /> */}
                </Grid>
            </Grid>
            <Grid container spacing={3} style={{marginTop: '16px'}}>
                <Grid item xs={12}>
                    <Button size="small" variant="contained" color="primary" style={{float: 'right', margin: '6px'}}
                            onClick={handleSave}>
                        {t('Save')}
                    </Button>
                    <Button size="small" variant="outlined" color="primary" style={{float: 'right', margin: '6px'}}
                            onClick={handleCancel}>
                        {t('Cancel')}
                    </Button>
                </Grid>
            </Grid>
            {message.showMsg && <EligoSnackBar show={message.showMsg} message={message.message}
                severity={message.severity}></EligoSnackBar>}
            {loading && <EligoBackdrop show={loading} />}
        </Container>
    )
}

export default AddOrganizer;