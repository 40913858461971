import React, {Fragment, useState} from 'react'
import { Grid, TextField,AccordionActions,
    Paper, Accordion, Container, Button} from '@material-ui/core'
import { CREATE_GENERIC_CODE, UPDATE_GENERIC_CODE } from '../../GraphQL/Mutations'
import { GET_GENERIC_CODE_BY_NODE } from '../../GraphQL/Queries';
import { useMutation } from '@apollo/client'
import GraphQLErrors from '../../common/GraphQLErrors'
import DateAndTime from '../../common/DateAndTime'
import { makeStyles } from '@material-ui/core/styles';
import EligoBackdrop from '../../common/EligoBackdrop'
import EligoSnackBar from '../../common/EligoSnackBar'
import * as Validators from '../../common/generic-code-validators'


const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2)
    },
    margin: {
        marginTop: '20px'
    }
  }));

const AddGenericCode = (props) => {
    const classes = useStyles();
    const [errors, setErrors] = useState({});
    const [touched, setTouched] = useState({});
    const [codeResource, setCodeResource]= useState(props.codeResource)

    const validate = {
        // code: code => Validators.requiredValidation('Code', code.toString()),
        description: description => Validators.requiredValidation('Description', description),
        abbreviation: abbreviation => Validators.requiredValidation('Abbreviation', abbreviation),
        start_date_time: start_date_time =>
            Validators.requiredValidation('Start Date & Time', start_date_time == null ? '' : start_date_time),
    };

    const handleInputChange = (event) => {
        setCodeResource(prevState => ({
            ...prevState,
            [event.target.id]: event.target.value
        }))
        setTouched(prevState => ({
            ...prevState,
            [event.target.id]: true,
        }))
    }

    const resetData= () => {
        setCodeResource(props.codeResource)
        props.close();
        setTouched({});
        setErrors({});
    }
    
    const handleDateAndTime = (event, id) => {
        console.log(event)
        console.log(id)
        setCodeResource(prevState => ({
            ...prevState,
            [id]: event
        }))
        if(id=="start_date_time") {
            setTouched(prevState => ({
                ...prevState,
                [id]: true,
            }))
        }
    }

    // const [createGenericCode, {loading, error: errorOnCreatingCode}] = useMutation(CREATE_GENERIC_CODE, { errorPolicy: 'all',
    //     onCompleted: (data) => {
    //         if (data.createGenericNode != null) {
    //             resetData();
    //         }
    //     },
    //     refetchQueries:[{
    //         query: GET_GENERIC_CODE_BY_NODE,
    //         variables: { label: props.label }
    //     }],
    // });

    // const [updateGenericCode, {loading:loading1, error: errorOnUpdatingCode}] = useMutation(UPDATE_GENERIC_CODE, { errorPolicy: 'all', 
    //     onCompleted : (data) => {
    //         if (data.updateGenericNode != null) {
    //             resetData();
    //         }
    //     },
    //     refetchQueries:[{
    //         query: GET_GENERIC_CODE_BY_NODE,
    //         variables: { label: props.label }
    //     }],
    // });
    
    const saveCodeDeatils = () => {
        validateForm().then(data => {
            console.log(data)
            if (Object.values(data.errors).length == 0) {
                if(codeResource.end_date_time == null) {
                    codeResource.end_date_time = new Date('9999-12-31')
                }
                if(codeResource.event_id == null) {
                    // createGenericCode({
                    //     variables: {
                    //         label: props.label,
                    //         propertyInput: codeResource
                    //     }
                    // })
                } else {
                    // updateGenericCode({
                    //     variables: {
                    //         label: props.label,
                    //         propertyInput: codeResource
                    //     }
                    // })
                }
                // if (error || error1) {
                //     return <GraphQLErrors error={error} show={false} />
                // }        
            }
        })
    }

    const handleBlur = (name, value) => {
        setErrors(prevState => ({
            ...prevState,
            [name]: '',
        }))
        setTouched(prevState => ({
            ...prevState,
            [name]: true,
        }))
        const errorMsg = validate[name](value);
        setErrors(prevState => ({
            ...prevState,
            ...(errorMsg && { [name]: touched[name] && errorMsg }),
        }))
    }

    const validateForm = async () => {
        setErrors({})
        setTouched({})
        let formErrors = { errors: {}, touched: {} };
        Object.entries(codeResource).map(([key]) => {
            console.log('key----', key)
            if (key === 'description' || key === 'abbreviation' || key === 'start_date_time') {
                const newError = validate[key](codeResource[key]);
                const newTouched = { [key]: true };
                return formErrors = {
                    errors: {
                        ...formErrors.errors,
                        ...(newError && { [key]: newError }),
                    },
                    touched: {
                        ...formErrors.touched,
                        ...newTouched
                    }
                }
            }
        })
        setErrors(formErrors.errors);
        setTouched(formErrors.touched);
        return formErrors;
    }

    return (
        <Fragment>
            <Container className={classes.margin}>
                <Accordion defaultExpanded>
                    <Paper className={classes.root} style={{position:'relative'}}>
                        <Grid container spacing={3}>
                        {/* <Grid item xs={2}>
                                <TextField id="code" required label="Code" value={codeResource.code} fullWidth 
                                    onChange={handleInputChange}  type="number"
                                    error={touched['code'] && errors.code}
                                    helperText={touched['code'] && errors.code}
                                    onBlur={() => handleBlur('code', codeResource.code)}/>
                            </Grid> */}
                            <Grid item xs={6}>
                                <TextField id="abbreviation" required label="Abbreviation" value={codeResource.abbreviation} fullWidth 
                                    onChange={handleInputChange}  
                                    error={touched['abbreviation'] && errors.abbreviation}
                                    helperText={touched['abbreviation'] && errors.abbreviation}
                                    onBlur={() => handleBlur('abbreviation', codeResource.abbreviation)}/>
                            </Grid>
                            <Grid item sm={3}>
                                <DateAndTime label="Start Date & Time" value={codeResource.start_date_time} 
                                    handleChange={(e) => handleDateAndTime(e, "start_date_time")} required={true} 
                                    err={touched['start_date_time'] && errors.start_date_time}
                                    errMessage={touched['start_date_time'] && errors.start_date_time}
                                    onBlur={() => handleBlur('start_date_time', codeResource.start_date_time)}/>
                            </Grid>
                            <Grid item sm={3}>
                                <DateAndTime label="End Date & Time" value={codeResource.end_date_time} 
                                    handleChange={(e) => handleDateAndTime(e, "end_date_time")}
                                    disable={codeResource.start_date_time == null}
                                    mindate={codeResource.start_date_time} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField id="description" rows={2} variant="outlined" multiline required label="Description" 
                                    value={codeResource.description} fullWidth 
                                    onChange={handleInputChange}  
                                    error={touched['description'] && errors.description}
                                    helperText={touched['description'] && errors.description}
                                    onBlur={() => handleBlur('description', codeResource.description)}/>
                            </Grid>
                            <Grid item xs={12}>
                                <AccordionActions className="float-right">
                                    <Button size="small" onClick={resetData}>Cancel</Button>
                                    <Button size="small" color="primary" onClick={() => saveCodeDeatils()}>
                                        {codeResource.event_id == null ? "Save" : "Update" }
                                    </Button>
                                </AccordionActions>
                            </Grid>
                        </Grid>        
                    </Paper>
                </Accordion>
            </Container>
        </Fragment>
    )
}

export default AddGenericCode;
