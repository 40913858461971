import React, { Fragment } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        position: "absolute",
        backgroundColor: "rgba(255, 255, 255, 0.26)"
    },
}));

const EligoBackdrop = (props) => {
    const classes = useStyles();

    return (
        <Fragment>
            <Backdrop className={classes.backdrop} open={props.show} invisible={props.invisible}
                    style={props.position ? {position : props.position} : {} }>
                <CircularProgress />
            </Backdrop>
        </Fragment>
    );
}

export default EligoBackdrop;