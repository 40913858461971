import {
    Divider, Grid, IconButton, InputAdornment, Select, TextField,
    FormControlLabel, Checkbox, Stack
} from '@mui/material';
import Button from '@mui/material/Button';
import { makeStyles } from "@material-ui/core";
import React, { useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
// import { Visibility, VisibilityOff } from '@mui/icons-material';
import MenuItem from '@mui/material/MenuItem';
import { APIEvotingAxiosDownloadInterceptor } from '../../config/axios.interceptor';
import EligoBackdrop from '../../common/EligoBackdrop';
import EligoSnackBar from '../../common/EligoSnackBar'

const useStyles = makeStyles((theme) => ({
    dialogActions: {
        padding: '8px 25px !important',
        justifyContent: 'space-between !important'
    },
    button: {
        float: 'right',
        margin: '0px 4px 0px 4px !important'
    },
    divider: {
        margin: '0px -25px !important',
        padding: '0px',
    }
}));

function AuditReport(props) {
    const { onClose, open, setOpen, auctionId, eventName, activityName, startDate, endDate,
        userName, selectedDescription, page, itemPerPage, onSuccess, onClearFields, pollId, zoneId } = props;
    const classes = useStyles();
    const [showPassword, setShowPassword] = useState(false);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({
        message: '',
        show: false,
        severity: ''
    });

    const handleClose = () => {
        onClose(false);
    }

    const [reportDetails, setReportDetails] = useState({
        reportType: 'PDF_A',
        password: '',
        watermark: '',
        opaque: '0.3',
        digitalSign: false
    })

    const handleChange = (fieldName, event) => {
        setReportDetails(prevState => ({
            ...prevState,
            [fieldName]: event.target.value
        }))
    }

    const handleBooleanChange = (fieldName, event) => {
        setReportDetails(prevState => ({
            ...prevState,
            [fieldName]: event.target.checked
        }))
    }

    console.log("userName", userName)
    const generateReport = () => {
        onSuccess({ showMsg: false, message: '', severity: '' })
        setLoading(true)
        setMessage({ show: false, message: '', severity: 'error' });
        let payload = {
            activityCd: activityName,
            rowCreatedBy: userName,
            description: selectedDescription,
            startRange: startDate,
            endRange: endDate,
            eventCd: eventName,
            page: page,
            size: itemPerPage,
            asc: false,
            report: {
                reportOf: pollId,
                reportType: reportDetails.reportType,
                appName: "VOTING",
                template: "votingAuditTrial",
                barCode: false,
                reportCd: 57,
                applicationId: 1,
                digitalSign: true,
                versionId: null,
                presignedUrl: false,
                password: reportDetails.password,
                watermark: {
                    text: reportDetails.watermark, opaque: '0.3',
                },
            },
            zoneId: zoneId
        }
        APIEvotingAxiosDownloadInterceptor.post(`audit/report/${pollId}`, payload).then((response) => {
            let reportType = (reportTypes.find(reportType => reportType.value === reportDetails.reportType)).fileType;
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Audit Trail Report.${reportType}`);
            document.body.appendChild(link);
            link.click();
            setLoading(false);
            onSuccess({ showMsg: true, message: t('Report_Generated_Successfully'), severity: 'success' })
            // onSuccess({ show: true, message: t('Report_Generated_Successfully'), severity: 'success' });
        }).catch((error) => {
            if (error.message) {
                setLoading(false)
                setMessage({
                    show: true,
                    message: error.message,
                    severity: 'error'
                });
            }
            else {
                setMessage({
                    show: true,
                    message: t('Try_Again'),
                    severity: 'error'
                });
            }
        })
    }

    const reportTypes = [
        { name: 'PDF', value: 'PDF_A', fileType: 'pdf' },
        // { name: 'PDF_UA', value: 'PDF_UA', fileType: 'pdf' },
        // { name: 'EXCEL', value: 'EXCEL', fileType: 'xls' },
        // { name: 'DOCX', value: 'DOCX', fileType: 'docx' },
        // { name: 'DOC', value: 'DOC', fileType: 'doc' }
    ]

    const toggleShowPassword = () => setShowPassword(!showPassword);

    const handleDataBoolean = (fieldName, event) => {
        setReportDetails(prevState => ({
            ...prevState,
            [fieldName]: event.target.checked
        }))
    }

    const resetReportFields = () => {
        onClearFields()
        setReportDetails(prev => ({
            ...prev,
            password: '',
            watermark: ''
        }))

    }

    return (
        <React.Fragment>
            <Stack direction='column' spacing={2}>
                <Stack >
                    <Select id="reportType" name="report-type" value={reportDetails.reportType}
                        onChange={(event) => handleChange('reportType', event)} variant="outlined"  fullWidth>
                        {
                            reportTypes.map((reportType, index) => {
                                return <MenuItem value={reportType.value} key={index}>{t(reportType.name)}</MenuItem>
                            })
                        }
                    </Select>
                </Stack>
                {/* {(reportDetails.reportType === 'PDF_A' || reportDetails.reportType === 'PDF_UA') && <Fragment> */}
                <Stack direction='row' spacing={2}>
                    <Grid  item xs={6}>                        
                        <TextField id="password" label={t('Password')} variant="outlined"  fullWidth
                            type={showPassword ? "text" : "password"}
                            value={reportDetails.password} onChange={(event) => handleChange('password', event)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            id='eyepwdvisibility'
                                            aria-label="toggle password visibility"
                                            onClick={toggleShowPassword}
                                        >
                                            {/* {showPassword ? <Visibility /> : <VisibilityOff />} */}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            autoComplete="new-password"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField id="watermark" label={t('Watermark')} variant="outlined"  fullWidth
                            value={reportDetails.watermark} onChange={(event) => handleChange('watermark', event)}/>
                    </Grid>
                {/* </Fragment>} */}
                </Stack>

                <Stack>
                        <FormControlLabel control={<Checkbox checked={reportDetails.digitalSign} onChange={(event) => handleBooleanChange('digitalSign', event)}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />} label={t('With_Digital_Signature')} />
                    </Stack>
            </Stack>
            <Divider className={classes.divider} />
            <Grid container style={{ paddingTop: '10px' }}>
                <Grid item xs={12}>
                    <Button id='resetButton'  styles={{ float: "left" }} variant='outlined' onClick={resetReportFields}>
                        {t("Reset")}
                    </Button>
                    <Button id='generate-report-btn' type="submit" size="small" className={classes.button} variant="contained" styles={{ float: "right" }}
                        onClick={generateReport}>
                        {t('Generate')}
                    </Button>
                </Grid>
            </Grid>
            {loading && <EligoBackdrop show={loading} invisible={false} />}
            {message.show && <EligoSnackBar show={message.show} message={message.message} severity={message.severity} ></EligoSnackBar>}
        </React.Fragment>
    );
}
export default AuditReport







