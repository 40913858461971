import React, { useState } from 'react';
import { RadioGroup, 
    makeStyles, 
    FormControlLabel, 
    Radio, 
    Grid,
    Card, 
    Divider} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import AddressSelection from '../plan-details/AddressSelection';
import './GatewaySelection.css'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        minHeight: '60vh'
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
    priceCard: {
        margin: '8px'
    },
    cardContent: {
        padding: '16px'
    }
}));

const GatewaySelection = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [paymentMethod, setPaymentMethod] = useState(props.paymentMethod ? props.paymentMethod : 'razor-pay');

    const handlePaymentMethodChange = (event) => {
        setPaymentMethod(event.target.value);
        if (props.setPaymentMethod) props.setPaymentMethod(event.target.value);
    }

    return (
        <div>
            <Grid container>
                <Grid item xs={6}>
                    <Grid item xs={12}>
                        <Card className={classes.priceCard}>
                            <div style={{textAlign: 'center', padding: '8px', color: 'darkgray'}}>
                                <strong id='price'> Billing Address </strong>
                            </div>
                            <Divider/>
                            <div>
                                <Card style={{margin: '4px', padding: '4px'}}>
                                    <AddressSelection showGSTIN={true} setSelectedAddress={props.setSelectedBillingAddress}
                                            setCustomerId={props.setCustomerId}
                                            address={props.billingAddress}
                                            />
                                </Card>
                            </div>
                        </Card>
                    </Grid>
                    <br/>
                    <Grid item xs={12}>
                        <Card className={classes.priceCard}>
                            <div style={{textAlign: 'center', padding: '8px', color: 'darkgray'}}>
                                <strong id='price'> Shipping Address </strong>
                            </div>
                            <Divider/>
                            <div>
                                <Card style={{margin: '4px', padding: '4px'}}>
                                    <AddressSelection showGSTIN={false} setSelectedAddress={props.setSelectedShippingAddress}
                                            address={props.shippingAddress}/>
                                </Card>
                            </div>
                        </Card>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid item xs={12}>
                        <Card className={classes.priceCard}>
                            <div style={{textAlign: 'center', padding: '8px', color: 'darkgray'}}>
                                <strong id='price'> {t('Payment_Method')} </strong>
                            </div>
                            <Divider/>
                            <RadioGroup row aria-label="payment_method" name="role" value={paymentMethod} 
                                    onChange={handlePaymentMethodChange}>
                                <Grid item xs={12}>
                                    <Card style={{margin: '4px', padding: '4px', display: 'flex', flexDirection: 'row'}}
                                            className={paymentMethod === 'razor-pay' ? 'selected-payment-method' : ''}>
                                        <div style={{width: '40%'}}>
                                            <FormControlLabel value="razor-pay" control={<Radio color="primary"/>} label={t("Razor_Pay")} />
                                        </div>
                                        <div style={{width: '60%', textAlign: 'right'}}>
                                            <img id="rupay" src={process.env.PUBLIC_URL + '/rupay.png'} style={{ width: '15%'}} alt="" />
                                            <img id="master-card" src={process.env.PUBLIC_URL + '/mastercard.svg'} style={{ width: '15%'}} alt="" />
                                            <img id="visa" src={process.env.PUBLIC_URL + '/visa.svg'} style={{ width: '15%'}} alt="" />
                                        </div>
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Card style={{margin: '4px', padding: '4px', display: 'flex', flexDirection: 'row'}}
                                            className={paymentMethod === 'offline' ? 'selected-payment-method' : ''}>
                                        <div style={{width: '30%'}}>
                                            <FormControlLabel value="offline" control={<Radio color="primary"/>} label={t("Offline")} />
                                        </div>
                                        <div style={{width: '70%'}}>
                                            <span style={{fontSize: '12px', color: 'gray'}}>Use offline payment to avoid convenience fee of payment gateway</span>
                                        </div>
                                    </Card>
                                </Grid>
                            </RadioGroup>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default GatewaySelection;