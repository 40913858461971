import React, { useState, useEffect } from 'react';
import { APIEvotingAxiosDownloadInterceptor } from '../../../../config/axios.interceptor';
import PDFViewer from '../../../../common/PDFViewer';
import { Grid } from '@mui/material';
import EligoBackdrop from '../../../../common/EligoBackdrop';


function RoundResult(props) {
    const [loading, setLoading] = useState(false);
    const [pdf, setPdf] = useState(null);

    const generateResult = () => {
        setLoading(true)
        let payload = {
            reportType: 'PDF_A',
            appName: "VOTING",
            template: "resultBrief",
            barCode: false,
            digitalSign: false,
            reportOf: props.pollRoundIds,
            applicationId: 1,
            presignedUrl: false,
            reportCd: 53,
            versionId: null
        }

        APIEvotingAxiosDownloadInterceptor.post(`poll/result/${props.pollRoundIds}/generate-result-by/false/expose-voter-name/false`,
                payload).then((response) => {
            setPdf(response);
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
            console.log(error);
        })
    }

    useEffect(() => {
        setPdf(props.resultResponse)
        // generateResult();
    }, [])
    

    return (
        <Grid container spacing={3} style={{padding: '6px'}}>
            <Grid item xs={12} p={2}>
            {pdf && <PDFViewer pdfData={pdf} />}
            {/* <br />
            <br />
            {
                <div>
                    <Grid spacing={3} alignItems="left" justify="left">
                        <img id='img' src={process.env.PUBLIC_URL + '/evote-logo.svg'} style={{ width: '12%' }} alt="" />
                    </Grid>
                    <Grid container spacing={3} alignItems="center" justify="center">
                        <Typography component="h1" variant="h5" gutterBottom>
                            <span id='result-poll' style={{ color: "#172a93" }}> {t('Poll_Result')} </span>
                        </Typography>
                    </Grid>
                    <Grid container spacing={3} alignItems="center" justify="center">
                        <Typography id='title' variant="h6" gutterBottom>
                            {result.title}
                        </Typography>
                    </Grid>
                </div>
            }
            <br />
            <br />
            {
                result.poll &&
                <Fragment>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <PollRoundSummaryResult pollRound={result.poll} />
                        </Grid>
                        <div>
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                        </div>
                        {result.poll.poll_questions.map((question, index) => {
                            return <Grid item xs={12}> <PollRoundQuestionResult key={index} questionNumber={index + 1} question={question}
                                resultWithVoters={props.resultWithVoters} /></Grid>
                        })}
                    </Grid>
                    <br />
                    <br />
                </Fragment>
            } */}
            </Grid>
            {( loading) && <EligoBackdrop show={true} />}
        </Grid>
    )
}

export default RoundResult;
