import React, {
    useState,
    useContext,
    useEffect
}                               from 'react';
import { useHistory }           from 'react-router-dom';
import { useTranslation }       from 'react-i18next';
import {
    Container,
    Grid,
    Paper,
    Typography,
    TextField,
    InputAdornment,
    IconButton,
    Link,
    Button
}                               from '@material-ui/core';
import { RootDiv }              from '../../common/evoting-styles/EvotingStyle';
import InputMask                from 'react-input-mask';
import {
    Visibility,
    VisibilityOff
}                               from '@material-ui/icons';
import { UserContext }          from '../../common/context/UserContext';
import PoweredBy                from '../PoweredBy';
import * as Validators          from '../../common/form-validators.js';
import {
    PublicAxiosInterceptor,
    AuthAxiosInterceptor
}                               from '../../config/axios.interceptor';
import EligoReCaptcha           from '../../common/EligoReCaptcha';
import EligoBackdrop            from '../../common/EligoBackdrop';
import EligoSnackBar            from '../../common/EligoSnackBar';

const MFAVerification = ({ username, isMobile, isMFAEnabled, onCancel}) => {
    const { t } = useTranslation();
    let history = useHistory();
    const context = useContext(UserContext)
    const [loading, setLoading] = useState(false);
    const [userDetails, setUserDetails] = useState({
        password: '',
        otp: ''
    })
    const [errors, setErrors] = useState('')
    const [showPassword, setShowPassword] = useState(false);
    const [message, setMessage] = useState({
        showMsg: false,
        message: null,
        severity: null
    })
    const [captcha, setCaptcha] = useState('');
    const [isRefresh, setIsRefresh] = useState(false);
    const [resetPassCaptcha, setResetPassCaptcha] = useState('');
    const [isRefreshResetPassCaptcha, setIsRefreshResetPassCaptcha] = useState(false);

    const toggleShowPassword = () => setShowPassword(!showPassword);

    const validate = {
        password: password => Validators.requiredValidation('Password', password),
        otp: otp => isMFAEnabled ? Validators.requiredValidation(t('Two_Step_Verification_Code'), otp) : null
    }

    const forgotPasssword = () => {
        history.push("/forgot-password")
    }

    const onInputChange = (fieldName, event) => {
        setUserDetails(prevState => ({
            ...prevState,
            [fieldName]: event.target.value
        }));
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        validateForm().then(data => {
            if (Object.values(data.errors).length === 0) {
                signIn()
            }
        })
    }

    const signIn = () => {
        let code = userDetails.otp.replaceAll(' ', '')
        setLoading(true);
        setMessage({ showMsg: false, message: null, severity: null });
        let payload = {
            userName: username,
            password: userDetails.password,
            otp: code
        }
        let user = null;
        if (captcha != '') {
            PublicAxiosInterceptor.post(`login?recaptcha=${captcha}`, payload).then(response => {
                setLoading(false);
                context.setUserSession({
                    isAuthenticated: true, session: {
                        accessToken: response.access_token,
                        refreshToken: response.refresh_token
                    }
                });
                sessionStorage.setItem('token', response.access_token);
                sessionStorage.setItem('refreshToken', response.refresh_token);
                user = decodeToken(response.access_token);
                getUserInformation(user);
                setLoading(true);
                setTimeout(() => {
                    history.push("/polls");
                }, 2000);
            }).catch((error) => {
                refreshCaptcha();
                refreshRefreshPassCaptcha();
                if (error.message) {
                    if (error.message.includes('User credentials have expired') || error.message.includes('Need to reset password')) {
                        // sendOTPToResetPassword()
                        history.push({
                            pathname: "/change-password",
                            state: { username: username }
                        })
                    } else if (error.message.includes('User account is locked')) {
                        resetUserAccount();
                    } else {
                        setMessage({ showMsg: true, message: error.message, severity: 'error' });
                    }
                } else {
                    setMessage({ showMsg: true, message: t('Try_Again'), severity: 'error' });
                }
                setLoading(false);
            })
        } else {
            refreshCaptcha();
            refreshRefreshPassCaptcha();
            setMessage({ showMsg: false, message: null, severity: null });
            setTimeout(() => {
                setLoading(false);
                setMessage({ showMsg: true, message: t('Please_Check_Internet_Connection_And_Refresh_The_Page'), severity: 'error' })
            }, 1000);
        }
    }

    const getUserInformation = (user) => {
        if (user.sub != null) {
            AuthAxiosInterceptor.get(`user/get-account/${user.sub}`).then(response => {
                context.setUserSession(prevState => ({
                    ...prevState,
                    isAuthenticated: true, session: {
                        ...prevState.session,
                        name: response.firstName,
                        middleName: response.middleName !== undefined && response.middleName !== null ? response.middleName : '',
                        lastName: response.lastName !== undefined && response.lastName !== null ? response.lastName : '',
                        dateOfBirth: response.dateOfBirth !== undefined ? response.dateOfBirth : null,
                        individual: response.individual,
                        email: response.email,
                        phone_number: response.phoneNumber,
                        userId: response.loginUuid,
                        roles: user.roles,
                        isMfaEnabled: user.isMfaEnabled,
                        phoneExtension: response.phoneDetail?.countryCode
                    }
                }));
            }).catch(error => {
                if (error.message) {
                    setMessage({ showMsg: true, message: error.message, severity: 'error' });
                } else {
                    setMessage({ showMsg: true, message: t('Try_Again'), severity: 'error' });
                }
            })
        }
    }

    const sendOTPToResetPassword = () => {
        setLoading(true);
        AuthAxiosInterceptor.patch(`user/request-otp?recaptcha=${resetPassCaptcha}&purpose=reset-password`, {
            loginId: username
        }).then(response => {
            if (response) {
                history.push({
                    pathname: "/reset-password", state: {
                        phone: '',
                        username: username
                    }
                })
            }
            setLoading(false)
        }).catch((error) => {
            setLoading(false);
            refreshRefreshPassCaptcha();
            if (error.message) {
                setMessage({ showMsg: true, message: error.message, severity: 'error' });
            } else {
                setMessage({ showMsg: true, message: t('Try_Again'), severity: 'error' });
            }
        })
    }

    const resetUserAccount = () => {
        history.push({pathname:'/forgot-password', state:{
            resetUserAccount: true
        }})
    }

    const decodeToken = (token) => {
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }

    const validateForm = async () => {
        setErrors(prevState => ({
            ...prevState,
            errors: {}
        }));
        let formErrors = { errors: {}, touched: {} };
        Object.entries(userDetails).map(([key]) => {
            if (key === 'password' || key === 'otp') {
                const newError = validate[key](userDetails[key])
                formErrors = {
                    errors: {
                        ...formErrors.errors,
                        ...(newError && { [key]: newError }),
                    }
                }
            }
        })
        setErrors(prevState => ({
            ...prevState,
            errors: formErrors.errors
        }));
        return formErrors;
    }

    useEffect(() => {
        refreshCaptcha();
        refreshRefreshPassCaptcha();
    }, [])

    const refreshCaptcha = () => {
        setIsRefresh(true);
        setTimeout(() => {
            setIsRefresh(false);
        }, 1000);
    }

    const refreshRefreshPassCaptcha = () => {
        setIsRefreshResetPassCaptcha(true);
        setTimeout(() => {
            setIsRefreshResetPassCaptcha(false);
        }, 1000);
    }

    return (
        <RootDiv>
            <Container component="main" maxWidth="xs">
                <Grid container spacing={0} alignItems="center" justify="center" style={{ minHeight: "98vh" }}>
                    <Paper>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '16px' }}>
                            <img id='evoting' src={process.env.PUBLIC_URL + '/evote-logo.svg'} style={{ width: '60%', padding: '6px' }} alt="ACTA Logo" />
                            <Grid container style={{ paddingTop: '24px', paddingBottom: '12px' }}>
                                <Grid item xs={12}>
                                    <Typography id='signin' component="h1" variant="h5" gutterBottom>
                                        Sign In
                                    </Typography>
                                </Grid>
                            </Grid>
                            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <TextField id="username" variant="standard" label={isMobile ? t('Username_Mobile_Number') : t('User_Id_(Email Address)')} style={{ margin: '8px 8px 8px 0px' }}
                                            fullWidth value={username} autoComplete="off" disabled
                                        />
                                        <TextField id="password" variant="standard" label="Password" type={showPassword ? "text" : "password"}
                                            style={{ margin: '8px 8px 8px 0px' }} fullWidth value={userDetails.password}
                                            onChange={(event) => onInputChange('password', event)}
                                            error={errors !== '' && errors.errors.password}
                                            helperText={errors !== '' && errors.errors.password}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            id="icon"
                                                            aria-label="toggle password visibility"
                                                            onClick={toggleShowPassword}
                                                        >
                                                            {showPassword ? <Visibility id="visible" /> : <VisibilityOff id="visibleOff" />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                            autoComplete="off" disabled={loading}
                                        />
                                    </Grid>
                                    <br/>
                                    {isMFAEnabled && <Grid item xs={12} className="text-center">
                                        <Typography className="text-center">
                                            <InputMask
                                                mask="9 9 9 9 9 9"
                                                value={userDetails.otp}
                                                onChange={(event) => onInputChange('otp', event)}>
                                                {() => <TextField
                                                    label={t('Two_Step_Verification_Code')}
                                                    id='otp'
                                                    margin="normal"
                                                    variant="standard"
                                                    placeholder="OTP"
                                                    autoComplete="off"
                                                    helperText={errors !== '' && errors.errors.otp}
                                                    error={errors !== '' && errors.errors.otp}
                                                    inputProps={{ style: { textAlign: 'center', fontSize: '18px', fontWeight: 'bold' } }}
                                                    fullWidth
                                                    // InputProps={{
                                                    //     endAdornment: (
                                                    //         <Tooltip title="Resend">
                                                    //             <InputAdornment position="end" className="pointer" style={{ minHeight: '1.8rem' }}>
                                                    //                 <IconButton aria-label="resend-otp"
                                                    //                     onClick={() => {}}>
                                                    //                     <Loop style={{ color: 'blue' }} />
                                                    //                 </IconButton>
                                                    //             </InputAdornment>
                                                    //         </Tooltip>
                                                    //     )
                                                    // }}
                                                />}
                                            </InputMask>
                                        </Typography>
                                    </Grid>}
                                    <br/>
                                    <Grid item xs={12} container justify="flex-end">
                                        <Typography id="forgotpwd" variant="body2">
                                            <Link id="forgotlink" onClick={forgotPasssword} className="pointer">Forgot Password?</Link>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button id="signin" type="submit" size="medium" variant="contained" color="primary" style={{ margin: '8px 8px 8px 0px' }} fullWidth>
                                            {t('Sign_In')}
                                        </Button>
                                        <Button id="cancel" size="medium" variant="outlined" style={{ margin: '8px 8px 8px 0px' }} fullWidth
                                                onClick={onCancel}>
                                            {t('Cancel')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </div>
                        <PoweredBy />
                    </Paper>
                </Grid>
            </Container>
            {!isRefresh && <EligoReCaptcha key="login" captcha={setCaptcha} refresh={setIsRefresh} />}
            {!isRefreshResetPassCaptcha && <EligoReCaptcha key="reset-password" captcha={setResetPassCaptcha} refresh={setIsRefreshResetPassCaptcha} />}
            {loading && <EligoBackdrop show={loading} />}
            {message.showMsg && <EligoSnackBar show={message.showMsg} message={message.message} severity={message.severity}/>}
        </RootDiv>
    )
}

export default MFAVerification