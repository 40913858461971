import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {Tabs, Grid, Tab, Box, Container} from '@material-ui/core';
import GenericCodes from './GenericCodes/GenericCodes'
import {GenericCodeNodeLablesList} from '../common/GenericCodesValues'

const TabPanel = (props) => {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    minHeight: '600px',
    marginTop: '20px'
    
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

const Dashboard = (props) => {
  console.log('Dashboard--', props)
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container maxWidth="lg">
      <div className={classes.root}>
          <Tabs
              orientation="vertical"
              variant="standard"
              value={value}
              onChange={handleChange}
              className={classes.tabs}
          >
              {/* <Tab label="Dashboard"  /> */}
              <Tab label="Generic Code" />
          </Tabs>
          {/* <TabPanel value={value} index={0}>
            Dashboard
          </TabPanel> */}
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TabPanel value={value} index={0}>
                  {GenericCodeNodeLablesList.map((e, index)=> (
                      <Fragment key={index}>
                        <GenericCodes key={index} label={e.label} labelName={e.labelName}/><br/>
                      </Fragment>
                  ))}
              </TabPanel>
            </Grid>
          </Grid>
      </div>
    </Container>
  );
}

export default Dashboard;