import React, {
    useState,
    useEffect
}                                           from 'react';
import DateFnsUtils                         from '@date-io/date-fns';
import { AdapterDayjs }                     from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider }             from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker }                   from '@mui/x-date-pickers/DateTimePicker';
import { DemoContainer }                    from '@mui/x-date-pickers/internals/demo';
import { format }                           from 'date-fns';
import {
    Grid,
    TextField,
    MenuItem,
    Divider,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box,
    Button,
    Tooltip,
    CardHeader,
    CardContent,
    Card
}                                           from '@material-ui/core';
import ExpandMoreIcon                       from '@material-ui/icons/ExpandMore';
import SettingsIcon                         from '@material-ui/icons/Settings';
import {
    KeyboardDateTimePicker,
    MuiPickersUtilsProvider
}                                           from '@material-ui/pickers';
import { useTranslation }                   from 'react-i18next';
import { 
    AxiosEvotingInterceptor,
    LookUpAxiosEvotingInterceptor 
}                                           from '../../../config/axios.interceptor';
import PollSettings                         from '../poll-settings/PollSettings';
import PollAdvanceSettings                  from '../poll-settings/PollAdvanceSettings';
import {
    PollCategory,
    SurveyCategory
}                                           from '../../../common/GenericCodes';
import ClientDetails                        from '../../../profile/client/client-details/ClientDetails';
import { IdentificationTypes }              from '../../../profile/client/ClientStaticVar';
import dayjs                                from 'dayjs';

const TYPE_POll = 'POLL';
const TYPE_SURVEY = 'SURVEY';

const PollRoundDetail = (props) => {
    const { t } = useTranslation();
    const systemTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const [clients, setClients] = useState([]);
    const [timeZones, setTimeZones] = useState([])
    const [showClientDetails, setShowClientDetails]= useState({
        show: false,
        isNew: false
    })

    const getTimeZones = () => {
        LookUpAxiosEvotingInterceptor.get('timezone').then(response => {
            setTimeZones(response);
        }).catch(error => {
            console.log('error', error);
        })
    }

    const getClientNames = () => {
        AxiosEvotingInterceptor.get('client/list?page=1&size=100&asc=false').then(response => {
            setClients(response.client);
        }).catch(error => {
            console.log('error', error);
        })
    }

    const addClient = () => {
        setShowClientDetails({
            show: true,
            isNew: true,
            ClientDetails: {
                name: '',
                personId: '',
                individual: true,
                loginUser: false,
                identificationGst: {
                    id: null,
                    identification: '',
                    identificationType: IdentificationTypes.gstin,
                    previousIdentification: ''
                },
                identificationPan: {
                    id: null,
                    identification: '',
                    identificationType: IdentificationTypes.pan,
                    previousIdentification: ''
                },
                email: {
                    id: null,
                    email: '',
                },
                phoneNumber: {
                    id: null,
                    phoneNumber: '',
                },
                address: {
                    addressLine1: '',
                    addressLine2: '',
                    addressType: 'billing',
                    attention: '',
                    cityTown: '',
                    country: '',
                    addressId: '',
                    landmark: '',
                    phone: '',
                    postalCode: '',
                    state: '',
                }
            }
        })
    }

    const handleDataBoolean = (event) => {
        props.handleDataBoolean(event);
    }

    const handleDateChange = (fieldName, selectedDate) => {
        props.handleDateChange(fieldName, dayjs.utc(selectedDate));
    };

    const onJsonInputChange = event => {
        props.onJsonInputChange(event);
    }

    const onInputChange = event => {
        props.onInputChange(event);
    }

    const handleBlur = (fieldName, value) => {
        props.handleBlur(fieldName, value);
    }

    const onInputChangeByName = (fieldName, value) => {
        props.onInputChangeByName(fieldName, value);
    }

    useEffect(() => {
        getClientNames();
        getTimeZones();
    }, []);

    const resetShowClientDetails = () => {
        setShowClientDetails({
            show: false,
            isNew: false
        })
        getClientNames();
    }

    const handleCancel = () => {
        setShowClientDetails({
            show: false,
            isNew: false
        })
    }

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={2}>
                    <TextField select label={t("Category")} value={props.pollDetail.pollCategory}
                            onChange={(event) => onInputChangeByName("pollCategory", event.target.value)}
                            error={props.touched['pollCategory'] && props.errors['pollCategory']}
                            helperText={props.touched['pollCategory'] && props.errors.pollCategory}
                            onBlur={() => handleBlur('pollCategory', props.pollDetail.pollCategory)} fullWidth required
                            inputProps={{
                                id: 'pollCategory'
                            }}>
                        {props.pollDetail.pollType === TYPE_POll && PollCategory.map((type, index) => (
                            <MenuItem key={index} value={type.type}>
                                {type.type}
                            </MenuItem>
                        ))}
                        {props.pollDetail.pollType === TYPE_SURVEY && SurveyCategory.map((type, index) => (
                            <MenuItem key={index} value={type.type}>
                                {type.type}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={7}>
                    <TextField id="title" label={props.pollDetail.pollType === TYPE_POll ? t("Title_Of_Poll") : t("Title_Of_Survey")}
                        placeholder={props.pollDetail.pollType === TYPE_POll ? t('Corporate_Debtor_Name_Meeting_Number_&_Meeting_Date') : null}
                        value={props.pollDetail.title} onChange={onInputChange}
                        error={props.touched['title'] && props.errors['title']}
                        helperText={props.touched['title'] && props.errors.title}
                        onBlur={() => handleBlur('title', props.pollDetail.title)}
                        fullWidth required
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextField id="referenceNumber" label={t("Reference_Number")}
                        value={props.pollDetail.referenceNumber} onChange={onInputChange}
                        error={props.touched['referenceNumber'] && props.errors['referenceNumber']}
                        helperText={props.touched['referenceNumber'] && props.errors.referenceNumber}
                        onBlur={() => handleBlur('referenceNumber', props.pollDetail.referenceNumber)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                        <TextField select label={t("Client_Name")} value={props.pollDetail.conductForPersonId}
                                onChange={(event) => onInputChangeByName("conductForPersonId", event.target.value)}
                                error={props.touched['conductForPersonId'] && props.errors['conductForPersonId']}
                                helperText={props.touched['conductForPersonId'] && props.errors.conductForPersonId}
                                // onBlur={() => handleBlur('conductForPersonId', props.pollDetail.conductForPersonId)}
                                fullWidth
                                inputProps={{
                                    id: 'conductForPersonId'
                                }}>
                            {clients.map((client, index) => (
                                <MenuItem key={index + 1} value={client.personId}>
                                    {client.name + (client.restOfName === null ? '' : ` ${client.restOfName}`)} {client.preferredEmail ? ( `( ${client.preferredEmail} )` ) : null}
                                </MenuItem>
                            ))}
                            {props.pollDetail.conductForPersonId !== '' && <MenuItem key={0} value=''>
                                <Typography color='primary'><em>{t('Clear')}</em></Typography>
                            </MenuItem>}
                        </TextField>
                        {<Tooltip title={t('Add_Client')}>
                            <Button id='add-client' variant='outlined' size='small' color="primary" style={{ margin: '6px' }}
                                onClick={addClient}>{t('Add')}</Button>
                        </Tooltip>}
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} style={{paddingTop: '16px'}}>
                <Card sx={{boxShadow: 'rgb(0 0 0 / 6%) 0px 3px 3px -2px, rgb(0 0 0 / 4%) 0px 3px 4px 0px, rgb(0 0 0 / 4%) 0px 1px 8px 0px !important',
        borderRadius: '8px'}}>
                        <CardHeader style={{padding: '12px 0px 0px 20px'}} subheader={t('Poll_Time')}/>
                        <CardContent>
                            <Grid container spacing={2}>
                            {/* {refresh && <PollSettings pollDetail={pollRoundDetail} handleDataBoolean={handleDataBoolean} disabled={!isEdit || pollStatus == Status.open && !(isEdit && hasSystemAdmineOrSupportRole)}/>} */}
                            <Grid item sm={3}>
                                <TextField select label={t("Time_Zone")} value={props.pollDetail.zoneId}
                                        style={{paddingTop: '8px'}}
                                        onChange={(event) => onInputChangeByName("zoneId", event.target.value)}
                                        error={props.touched['zoneId'] && props.errors['zoneId']}
                                        helperText={props.touched['zoneId'] && props.errors.zoneId}
                                        // onBlur={() => handleBlur('zoneId', props.pollDetail.zoneId)} 
                                        fullWidth
                                        inputProps={{
                                            id: 'zoneId'
                                        }}
                                    >
                                    {timeZones.map((timeZone, index) => (
                                        <MenuItem key={timeZone.timezoneId} value={timeZone.javaZoneId}>
                                            {timeZone.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                {/* {systemTimeZone !== props.pollDetail.zoneId && <Typography variant="caption" display="block" gutterBottom>
                                    <span style={{color: 'gray'}}>System Time Zone : </span><em>{systemTimeZone}</em>
                                </Typography>} */}
                            </Grid>
                            <Grid item sm={3}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DateTimePicker']} sx={{overflow: 'visible'}}>
                                        <DateTimePicker
                                            label={t("Begin_Date_&_Time")}
                                            fullWidth
                                            format={process.env.REACT_APP_DATE_FORMAT_TO_DATE_SELECTOR}
                                            id="startTime"
                                            value={props.pollDetail.startTime}
                                            minDateTime={!(props.hasSystemAdmineOrSupportRole) ? dayjs.utc(new Date()) : dayjs.utc(new Date('1970-01-01Z00:00:00:001'))}
                                            maxDateTime={!(props.hasSystemAdmineOrSupportRole) && (props.pollDetail.endTime != null && props.pollDetail.endTime != '') ?
                                                props.pollDetail.endTime : dayjs.utc(new Date('2100-01-01'))}
                                            onChange={(event) => handleDateChange('startTime', event)}
                                            onAccept={(event) => handleBlur('startTime', event)}
                                            onBlur={(event) => handleBlur('startTime', event.target.value)}
                                            timezone={props.pollDetail.zoneId}
                                            required
                                            slotProps={{ textField: { 
                                                variant: 'standard', 
                                                required: true,
                                                helperText: props.touched['startTime'] && props.errors.startTime,
                                                error: props.touched['startTime'] && props.errors['startTime'],
                                                fullWidth: true,
                                                InputProps: {
                                                    readOnly: true,
                                                },
                                                onKeyDown:(e) => {
                                                    e.preventDefault();
                                                }
                                            } }}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                                {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDateTimePicker
                                        fullWidth
                                        variant="inline"
                                        format="dd/MM/yyyy hh:mm a"
                                        id="startTime"
                                        label={t("Begin_Date_&_Time")}
                                        value={props.pollDetail.startTime ? new Date(props.pollDetail.startTime).toLocaleString("en-US", {timeZone: props.pollDetail.zoneId}) : props.pollDetail.startTime}
                                        // value={props.pollDetail.startTime}
                                        helperText={props.touched['startTime'] && props.errors.startTime}
                                        error={props.touched['startTime'] && props.errors['startTime']}
                                        minDate={!(props.hasSystemAdmineOrSupportRole) && new Date()}
                                        maxDate={!(props.hasSystemAdmineOrSupportRole) && (props.pollDetail.endTime != null && props.pollDetail.endTime != '') ?
                                            props.pollDetail.endTime : new Date('2100-01-01')}
                                        onChange={(event) => handleDateChange('startTime', event)}
                                        onAccept={(event) => handleBlur('startTime', event)}
                                        onBlur={(event) => handleBlur('startTime', event.target.value)}
                                        required
                                    />
                                </MuiPickersUtilsProvider> */}
                                {/* {systemTimeZone !== props.pollDetail.zoneId && <Typography variant="caption" display="block" gutterBottom>
                                    <span style={{color: 'gray'}}>As per System Time Zone : </span>
                                    <em>{props.pollDetail.startTime ? format(new Date(props.pollDetail.startTime), process.env.REACT_APP_DATE_FORMAT_TO_DISPLAY) : null}</em>
                                </Typography>} */}
                            </Grid>
                            <Grid item sm={3}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DateTimePicker']} sx={{overflow: 'visible'}}>
                                        <DateTimePicker 
                                            label={t("Close_Date_&_Time")}
                                            fullWidth
                                            variant="inline"
                                            format={process.env.REACT_APP_DATE_FORMAT_TO_DATE_SELECTOR}
                                            id="endTime"
                                            value={props.pollDetail.endTime}
                                            minDateTime={!(props.hasSystemAdmineOrSupportRole) ? props.pollDetail.startTime : dayjs.utc(new Date('1970-01-01Z00:00:00:001'))}
                                            onChange={(event) => handleDateChange('endTime', event)}
                                            onAccept={(event) => handleBlur('endTime', event)}
                                            onBlur={(event) => handleBlur('endTime', event.target.value)}
                                            required
                                            timezone={props.pollDetail.zoneId}
                                            slotProps={{ textField: { 
                                                variant: 'standard', 
                                                required: true,
                                                // disabled: true,
                                                helperText: props.touched['endTime'] && props.errors.endTime,
                                                error: props.touched['endTime'] && props.errors['endTime'],
                                                fullWidth: true,
                                                InputProps: {
                                                    readOnly: true,
                                                },
                                                onKeyDown:(e) => {
                                                    e.preventDefault();
                                                }
                                            } }}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                                {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDateTimePicker
                                        fullWidth
                                        variant="inline"
                                        format="dd/MM/yyyy hh:mm a"
                                        id="endTime"
                                        label={t("Close_Date_&_Time")}
                                        value={props.pollDetail.endTime ? new Date(props.pollDetail.endTime).toLocaleString("en-US", {timeZone: props.pollDetail.zoneId}) : props.pollDetail.endTime}
                                        // value={props.pollDetail.endTime}
                                        helperText={props.touched['endTime'] && props.errors.endTime}
                                        error={props.touched['endTime'] && props.errors['endTime']}
                                        minDate={!(props.hasSystemAdmineOrSupportRole) && props.pollDetail.startTime}
                                        // maxDate={pollDetails.endTime}
                                        onChange={(event) => handleDateChange('endTime', event)}
                                        onAccept={(event) => handleBlur('endTime', event)}
                                        onBlur={(event) => handleBlur('endTime', event.target.value)}
                                        required
                                    />
                                </MuiPickersUtilsProvider> */}
                                {/* {systemTimeZone !== props.pollDetail.zoneId && <Typography variant="caption" display="block" gutterBottom>
                                    <span style={{color: 'gray'}}>As per System Time Zone : </span>
                                    <em>{props.pollDetail.endTime ? format(new Date(props.pollDetail.endTime), process.env.REACT_APP_DATE_FORMAT_TO_DISPLAY) : null}</em>
                                </Typography>} */}
                            </Grid>
                            {/* </Grid>
                            <Grid item xs={12} sm={4}> */}
                            <Grid item sm={3}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DateTimePicker']} sx={{overflow: 'visible'}}>
                                        <DateTimePicker 
                                            label={t("Alert_Date_&_Time")}
                                            fullWidth
                                            variant="inline"
                                            format={process.env.REACT_APP_DATE_FORMAT_TO_DATE_SELECTOR}
                                            id="alertTime"
                                            value={props.pollDetail.alertTime}
                                            minDateTime={props.hasSystemAdmineOrSupportRole ?  dayjs.utc(new Date('1970-01-01Z00:00:00:001')) : dayjs.utc(new Date())}
                                            maxDateTime={props.hasSystemAdmineOrSupportRole ? dayjs.utc(new Date('9999-01-01Z00:00:00:001')) : props.pollDetail.startTime}
                                            onChange={(event) => handleDateChange('alertTime', event)}
                                            onAccept={(event) => handleBlur('alertTime', event)}
                                            onBlur={(event) => handleBlur('alertTime', event.target.value)}
                                            disabled={props.pollDetail.startTime == null || props.pollDetail.endTime == null}
                                            required
                                            timezone={props.pollDetail.zoneId}
                                            slotProps={{ textField: { 
                                                variant: 'standard', 
                                                required: true,
                                                // disabled: true,
                                                InputProps: {
                                                    readOnly: true,
                                                },
                                                onKeyDown:(e) => {
                                                    e.preventDefault();
                                                },
                                                helperText: props.touched['alertTime'] && props.errors.alertTime,
                                                error: props.touched['alertTime'] && props.errors['alertTime'],
                                                fullWidth: true
                                            } }}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                                {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDateTimePicker
                                        fullWidth
                                        variant="inline"
                                        format="dd/MM/yyyy hh:mm a"
                                        id="alertTime"
                                        label={t("Alert_Date_&_Time")}
                                        value={props.pollDetail.alertTime ? new Date(props.pollDetail.alertTime).toLocaleString("en-US", {timeZone: props.pollDetail.zoneId}) : props.pollDetail.alertTime}
                                        // value={props.pollDetail.alertTime}
                                        helperText={props.touched['alertTime'] && props.errors.alertTime}
                                        error={props.touched['alertTime'] && props.errors['alertTime']}
                                        minDate={props.hasSystemAdmineOrSupportRole ?  new Date('1970-01-01Z00:00:00:001') : new Date()}
                                        maxDate={props.hasSystemAdmineOrSupportRole ? new Date('9999-01-01Z00:00:00:001') : props.pollDetail.startTime}
                                        onChange={(event) => handleDateChange('alertTime', event)}
                                        onAccept={(event) => handleBlur('alertTime', event)}
                                        onBlur={(event) => handleBlur('alertTime', event.target.value)}
                                        disabled={props.pollDetail.startTime == null || props.pollDetail.endTime == null}
                                        required
                                    />
                                </MuiPickersUtilsProvider> */}
                                {/* {systemTimeZone !== props.pollDetail.zoneId && <Typography variant="caption" display="block" gutterBottom>
                                    <span style={{color: 'gray'}}>As per System Time Zone : </span>
                                    <em>{props.pollDetail.alertTime ? format(new Date(props.pollDetail.alertTime), process.env.REACT_APP_DATE_FORMAT_TO_DISPLAY) : null}</em>
                                </Typography>} */}
                            </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
        

                <Grid item xs={12} sm={12}>
                    <TextField id="description" label={props.pollDetail.pollType === TYPE_POll  ? t("Description_Of_Poll") : t("Description_Of_Survey")}
                        multiline rows={4} variant="outlined" value={props.pollDetail.description} onChange={onInputChange}
                        error={props.touched['description'] && props.errors['description']}
                        helperText={props.touched['description'] && props.errors.description}
                        onBlur={() => handleBlur('description', props.pollDetail.description)}
                        fullWidth
                    // required
                    />
                </Grid>

                <Grid item xs={12} sm={12}>
                    <Divider/>
                        <br/>
                        <PollSettings pollDetail={props.pollDetail} handleDataBoolean={handleDataBoolean}/>
                        <br/>
                    <Divider/>
                </Grid>
                {/* </Grid> */}
            </Grid>
            <Grid container spacing={5} style={{padding: '4px'}}>
                <Grid item xs={12}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="settings-pannel"
                            id="settings-header"
                        >
                            <SettingsIcon style={{padding: '3px'}} />&nbsp;
                            <Typography variant="h6" style={{display: 'flex'}}>{t('Advance_Settings')}</Typography>
                            {/* <Typography variant="body2" gutterBottom style={{color: 'gray'}}>{t('Choose_The_Following_Poll_Settings')}</Typography> */}
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2} style={{padding: '4px'}}>
                                <PollAdvanceSettings pollDetail={props.pollDetail} handleDataBoolean={handleDataBoolean} isBeforePublish={true}
                                    pollType={props.pollDetail.pollType}/>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>

                </Grid>
            </Grid>
            {/* <Grid container spacing={5}>
                <Grid item xs={12} sm={3}>
                    <Chip
                        id="voteWithSignUp"
                        label={t("Allow_Voter_to_Vote_With_SignUp")}
                        color={props.pollDetail.voteWithSignUp ? "primary" : "default"}
                        variant="outlined"
                        clickable={props.pollDetail.voteWithSignUp}
                        onClick={handleDataBoolean}
                        icon={props.pollDetail.voteWithSignUp ? <CheckBox /> : <CheckBoxOutlineBlank />}
                    />
                    <FieldHelperText helperText={t('Allow_Voter_to_Vote_With_SignUp_Helper_Text')} />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Chip
                        id="allowVoteChange"
                        label={t("Allow_Recast")}
                        color={props.pollDetail.allowVoteChange ? "primary" : "default"}
                        variant="outlined"
                        clickable={props.pollDetail.allowVoteChange}
                        onClick={handleDataBoolean}
                        icon={props.pollDetail.allowVoteChange ? <CheckBox /> : <CheckBoxOutlineBlank />}
                    />
                    <FieldHelperText helperText={t('Allow_Recast_Helper_Text')} />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Chip
                        id="displayOrganizerName"
                        label={t("Show_organizer_details")}
                        color={props.pollDetail.displayOrganizerName ? "primary" : "default"}
                        variant="outlined"
                        clickable={props.pollDetail.displayOrganizerName}
                        onClick={handleDataBoolean}
                        icon={props.pollDetail.displayOrganizerName ? <CheckBox /> : <CheckBoxOutlineBlank />}
                    />
                    <FieldHelperText helperText={t('Display_Organizer_Details_Helper_Text')} />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Chip
                        id="scrutinizerHasToOpen"
                        label={t("Only_Scrutinizer_Should_Open_Result")}
                        color={props.pollDetail.scrutinizerHasToOpen ? "primary" : "default"}
                        variant="outlined"
                        clickable={props.pollDetail.scrutinizerHasToOpen}
                        onClick={handleDataBoolean}
                        icon={props.pollDetail.scrutinizerHasToOpen ? <CheckBox /> : <CheckBoxOutlineBlank />}
                    />
                    <FieldHelperText helperText={t('Scrutinizer_Has_To_Open_Helper_Text')} />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Chip
                        id="verifyWithOtp"
                        label={t("Should_Verify_With_OTP")}
                        color={props.pollDetail.verifyWithOtp ? "primary" : "default"}
                        variant="outlined"
                        clickable={props.pollDetail.verifyWithOtp}
                        onClick={handleDataBoolean}
                        icon={props.pollDetail.verifyWithOtp ? <CheckBox /> : <CheckBoxOutlineBlank />}
                    />
                    <FieldHelperText helperText={t('Verify_With_OTP_Helper_Text')} />
                </Grid>
            </Grid> */}
            <br /><br />
            {showClientDetails.show && <ClientDetails open={showClientDetails.show} handleClose={resetShowClientDetails}
                // setListMessage={setMessage}
                isNew={showClientDetails.isNew} client={showClientDetails.ClientDetails} listClient={clients}
                handleCancel={handleCancel}/> }
        </div>
    )
}

export default PollRoundDetail;
