import React, { useContext, useState, useEffect, Fragment } from 'react';
import { makeStyles, Grid, FormControl, Select, MenuItem, 
    Button, Typography, Popover, List, ListItem, ListItemIcon, Radio,
    ListItemText, InputLabel } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import EligoBackdrop from '../../common/EligoBackdrop';

import { UserContext } from '../../common/context/UserContext';

const useStyles = makeStyles({
    addressChangeBtn: {
        padding: '0px'
    }
})

const AddressSelection = (props) => {
    const context = useContext(UserContext);
    const { t } = useTranslation();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [userAddresses, setUserAddresses] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState('');
    const [selectedCustomerId, setSelectedCustomerId] = useState(props.address?.customer_id ? props.address.customer_id : '');
    const [showGSTIN] = useState(props.showGSTIN ? props.showGSTIN : false);
    const [selectedAddress, setSelectedAddress] = useState(props.address ? {
        address_line_1: props.address.address_line_1,
        address_line_2: props.address.address_line_2,
        address_type: props.address.address_type,
        attention: props.address.attention,
        city_town: props.address.city_town,
        city_town_id: props.address.city_town_id,
        country: props.address.country,
        country_id: props.address.country_id,
        end_time: props.address.end_time,
        id:props.address.id,
        landmark: props.address.landmark,
        phone: props.address.phone,
        postal_code: props.address.postal_code,
        postal_code_id: props.address.postal_code_id,
        province: props.address.province,
        start_time: props.address.start_time,
        state_id: props.address.state_id,
        gstin: props.address.gstin,
        pan: props.address.pan,
        customer_id: props.address.customer_id
    } : {
        address_line_1: '',
        address_line_2: '',
        address_type: '',
        attention: '',
        city_town: '',
        city_town_id: '',
        country: '',
        country_id: '',
        end_time: '',
        id:'',
        landmark: '',
        phone: '',
        postal_code: '',
        postal_code_id: '',
        province: '',
        start_time: '',
        state_id: '',
        gstin: '',
        pan: '',
        customer_id: ''
    })

    const handleClose = () => {
        setAnchorEl(null);
    };
        
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const getIdentificationByType = (identificationType, identifications) => {
        let identification = null;
        identifications.forEach(element => {
            if ((element.identification_type).toUpperCase() == identificationType ) {
                identification = {
                    id: element.id,
                    identification: element.identification,
                    identification_type: element.identification_type,
                    valid_from: element.valid_from,
                    valid_to: element.valid_to
                }
            }
        });

        if (identification == null) {
            return {
                id:'',
                identification: '',
                identification_type: identificationType,
                valid_from: new Date(),
                valid_to: new Date()
            }
        } else {
            return identification;
        }
    }

    const setAddress = (address, gstin, pan, customer_id) => {
        return {
            address_line_1: address.address_line_1,
            address_line_2: address.address_line_2,
            address_type: address.address_type,
            attention: address.attention,
            city_town: address.city_town,
            city_town_id: address.city_town_id,
            country: address.country,
            country_id: address.country_id,
            end_time: address.end_time,
            id: address.id,
            landmark: address.landmark,
            phone: address.phone,
            postal_code: address.postal_code,
            postal_code_id: address.postal_code_id,
            province: address.province,
            start_time: address.start_time,
            state_id: address.state_id,
            gstin: gstin,
            pan: pan,
            customer_id: customer_id
        }
    }

    const resetAddress = () => {
        setSelectedAddress({
            address_line_1: '',
            address_line_2: '',
            address_type: '',
            attention: '',
            city_town: '',
            city_town_id: '',
            country: '',
            country_id: '',
            end_time: '',
            id:'',
            landmark: '',
            phone: '',
            postal_code: '',
            postal_code_id: '',
            province: '',
            start_time: '',
            state_id: '',
            gstin: '',
            pan: '',
            customer_id: ''
        })
        props.setSelectedAddress({
            address_line_1: '',
            address_line_2: '',
            address_type: '',
            attention: '',
            city_town: '',
            city_town_id: '',
            country: '',
            country_id: '',
            end_time: '',
            id:'',
            landmark: '',
            phone: '',
            postal_code: '',
            postal_code_id: '',
            province: '',
            start_time: '',
            state_id: '',
            gstin: '',
            pan: '',
            customer_id: ''
        });
    }

    const getAddressByUserId = (event) => {
        setSelectedCustomer(customers.find(customer => customer.id == event.target.value));
        setSelectedCustomerId(event.target.value);
        if (props.setCustomerId) {
            props.setCustomerId(event.target.value);
        }
        resetAddress();
        if (event.target.value) {
            // setSelectedCustomer(event);
            // listAddress({
            //     variables: {
            //         user_id: event.target.value
            //     }
            // });
        }
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleAddressChange = (address) => {
        setSelectedAddress(address);
        props.setSelectedAddress(address);
    }


    return (
        <div>
            <div>
                <Grid container>
                    <Grid xs={12}>
                        {/* <strong id="addressTypeLabel"> {props.addressTypeLabel}, </strong> */}
                    </Grid>
                    <Grid xs={12}>
                        <FormControl fullWidth>
                            <br/>
                            {/* {customers.length > 0 && <Autocomplete
                                id="search-customer"
                                options={customers}
                                getOptionLabel={(option) => option.name}
                                disableClearable={true}
                                renderInput={(params) => <TextField {...params} placeholder={t("Search_Customer")}/>}
                                onChange={(event, data) => {
                                    getAddressByUserId(data);
                                }}
                            />} */}
                            <InputLabel id="select-address-label">Select Address</InputLabel>
                            <Select
                                labelId="select-address-label"
                                id="select-address"
                                onChange={getAddressByUserId}
                                value={selectedCustomerId}
                            >
                                <MenuItem value={context.userSession.session.userId}>Self</MenuItem>
                            {
                                customers.map(cusotmer => {
                                    return <MenuItem key={cusotmer.id} value={cusotmer.id}>{cusotmer.name}</MenuItem>
                                })
                            }
                            </Select>
                        </FormControl >
                    </Grid>
                </Grid>
                <br/>
                { selectedAddress.id && <Button id='change' color="primary" className={`${classes.addressChangeBtn} float-right`}
                        onClick={(event) => handleClick(event)}>
                    Change
                </Button>}
            </div>
            <span>
                { userAddresses.length == 0 && selectedCustomerId   &&<Typography id="select-address" style={{textAlign: 'center'}}>
                    <span style={{color: 'red', fontSize: '14px'}}>{t('Please_Add_Address')}</span>
                </Typography>}
                <Typography id="name" variant="body2" gutterBottom>
                    <b>{selectedAddress.attention}</b>
                </Typography>
                <Typography id="address-line-1" variant="body2" gutterBottom>
                    {selectedAddress.address_line_1}
                </Typography>
                <Typography id="address-line-2" variant="body2" gutterBottom>
                    {selectedAddress.address_line_2}
                </Typography>
                <Typography id="city-county" variant="body2" gutterBottom>
                    {selectedAddress.city_town} {selectedAddress.province} {selectedAddress.country} {selectedAddress.postal_code}
                </Typography>
                { (selectedAddress.gstin || selectedAddress.pan ) && showGSTIN && <Typography id="gstin" variant="body2" gutterBottom>
                    { selectedAddress.gstin && <span id="gstin"> <b>{t("GSTIN")}</b> : {selectedAddress.gstin} </span>}
                    { selectedAddress.pan && <span id="pan"> <b> {t("PAN")}</b> : {selectedAddress.pan} </span>}
                </Typography>}
            </span>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
                }}>
                <Grid container>
                    <Grid xs={12}>
                        <List id='list' component="nav" aria-label="addresses">
                        {userAddresses.map((address, index) => {
                            return <ListItem button key={index}>
                                <ListItemIcon>
                                    <Radio
                                        id='listradio'
                                        edge="start"
                                        value={address.id}
                                        name="radio-button-demo"
                                        onChange={() => handleAddressChange(address)}
                                        inputProps={{ 'aria-label': 'A' }}
                                        checked={selectedAddress.id == address.id}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={address.attention}
                                    secondary={
                                        <Fragment>
                                            <Typography id="address-line1" variant="body2" gutterBottom>
                                                {address.address_line_1}
                                            </Typography>
                                            <Typography id="address-line2" variant="body2" gutterBottom>
                                                {address.address_line_2}
                                            </Typography>
                                            <Typography id="city-country" variant="body2" gutterBottom>
                                                {address.city_town} {address.province} {address.country} {address.postal_code}
                                            </Typography>
                                            <Typography id="gstin" variant="body2" gutterBottom>
                                                { address.gstin && <span id="gstin"> <b>{t("GSTIN")}</b> : {address.gstin} </span>}
                                                { address.pan && <span id="pan"> <b> {t("PAN")}</b> : {address.pan} </span>}
                                            </Typography>
                                        </Fragment>
                                    }>
                                </ListItemText>
                            </ListItem>
                        })}
                        </List>
                    </Grid>
                </Grid>
            </Popover>
            {/* {( fetchingAddress) && <EligoBackdrop show={ fetchingAddress}  invisible={true}/>} */}
        </div>
    )
}

export default AddressSelection