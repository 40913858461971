import React, { useState, useEffect } from 'react'
import { AccordionSummary, Grid, Accordion,
    Typography, Divider} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GenericCodeTable from './GenericCodeTable';
import GraphQLCodeService from '../../poll/GraphQLCodeService';
import { useLazyQuery } from '@apollo/client';
import { GET_GENERIC_CODE_BY_NODE } from '../../GraphQL/Queries';
import EligoBackdrop from '../../common/EligoBackdrop';
import GraphQLErrors from '../../common/GraphQLErrors';

const GenericCodes = (props) => {
    const [codeCdList, setCodeCdList] = useState(null)

    // const [getGenericCodes, { loading, error }] = useLazyQuery(GET_GENERIC_CODE_BY_NODE, {variables: {label: props.label },
    //     errorPolicy: 'all',
    //     fetchPolicy: 'network-only',
    //     notifyOnNetworkStatusChange: true,
    //     onCompleted: (data) => {
    //         if (data.getGenericCodes != null) {
    //             setCodeCdList(data.getGenericCodes);
    //         }
    //     }
    // })

    const resetList = () => {
        // getGenericCodes({
        //     variables: { label: props.label }
        // })
    }

    return (
        <div className='loading-container'>
            {/* {codeCdList == null && <GraphQLCodeService labelName={props.label} codeCds={setCodeCds} /> } */}
            <Grid container>
                <Grid item xs={12}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-label="Expand">
                            <Grid container spacing={3}>
                                <Grid item xs={10}>
                                    <Grid item xs={12}>
                                        <Typography color="textPrimary">
                                            {props.labelName}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <Divider />
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <AccordionSummary>
                                    <GenericCodeTable codeCds={codeCdList} label={props.label} refresh={resetList} />
                                    {/* <Container maxWidth="lg">   
                                        {codeCdList.length == 0 ? null :
                                            codeCdList.properties.map((n, index) => (
                                                <Grid item xs={12} key={index}>
                                                    <GenericCodeRows property={n} /><br/>
                                                </Grid>
                                            
                                        ))}
                                    </Container> */}
                                </AccordionSummary>
                            </Grid>
                        </Grid>
                    </Accordion>
                </Grid>
            </Grid>
            {/* {loading && <EligoBackdrop show={loading} />}
            {error && <GraphQLErrors error={error} show={false} />} */}
        </div>
    )
}

export default GenericCodes;
