import { Fragment, useRef } from 'react';
import PollQuestionRow from './PollQuestionRow';
import { Slide, Stack } from '@mui/material';
import { Card, CardContent, Typography } from '@material-ui/core';

const PollQuestionListView = (props) => {
    const { pollQuestionList, page, itemsPerPage, setLoading, setMessage, handleRefresh,
        pollId, handleQuestionEdit, listTitle, totalVoterCount, noOfVotedNotVoted, hasPermission, questionTitle,
        filePermission, pollQuestionCount, setPage, status, realTimeResult, hasSystemAdmineOrSupportRole } = props;

    return (
        <Stack spacing={1.3}>
            {pollQuestionList.map((question, index) => {
                return (
                    // <Slide direction='up' in={true} timeout={300 + index} key={question.id}>
                        <PollQuestionRow
                        key={question.id}
                        question={question} questionNumber={((page - 1) * itemsPerPage) + (index + 1)}
                        setLoading={setLoading}
                        setMessage={setMessage}
                        handleRefresh={handleRefresh }
                        pollId={pollId }
                        handleQuestionEdit={handleQuestionEdit}
                        listTitle={listTitle}
                        questionTitle={questionTitle}
                        hasPermission={hasPermission}
                        totalVoterCount={totalVoterCount }
                        noOfVotedNotVoted={noOfVotedNotVoted}
                        filePermission={filePermission }
                        pollQuestionCount={pollQuestionCount }
                        page={page }
                        itemsPerPage={itemsPerPage}
                        setPage={setPage}
                        status={status}
                        realTimeResult={realTimeResult}
                        hasSystemAdmineOrSupportRole={hasSystemAdmineOrSupportRole}
                        />
                    // </Slide>
                )
            })}
        </Stack>
    )
}

export default PollQuestionListView;