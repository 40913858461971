import React from 'react';
import { Card, 
    CardContent, 
    Typography,
    Container,
    makeStyles,
    Button } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const useStyles = makeStyles({
    root: {
        
    },
    title: {
        color: 'green'
    },
    okButton: {
        width: '10vw'
    }
})

function PaymentSuccess(props) {
    const { t } = useTranslation();
    const classes = useStyles();

    const onConfirm = () => {
        props.onConfirm();
    }

    const downloadInvoice = (invoiceUrl) => {
        axios({
            url: invoiceUrl.substring(1, invoiceUrl.length - 1),
            method:'GET',
            responseType: 'blob'
        }).then((response) => {
            const url = window.URL .createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Invoice.pdf');
            document.body.appendChild(link);
            link.click();
        })
    }

    return (
        <Container maxWidth="sm">
            <Card>
                <CardContent>
                    <Typography id="payment" gutterBottom variant="h5" component="h2" className={classes.title} align="center">
                        {t("Payment_Successful")}
                    </Typography>
                    <Typography id="pay" gutterBottom variant="h5" component="h2" align="center">
                        <CheckCircleOutlineIcon style={{ fontSize: 60, color: 'green' }}/>
                    </Typography>
                    <Typography id="succuss" variant="body2" color="textSecondary" component="p" align="center">
                        {t("Your_Payment_Was_Successful")}
                    </Typography>
                    {/* <Typography variant="body2" color="textSecondary" component="p" align="center">
                        {t("Now_You_Are_Allowed_Schedule_Poll_Round")}
                    </Typography> */}
                    <br/><br/>
                    <Typography align="center">
                        <Button id="download" variant="outlined" color="primary" onClick={() => downloadInvoice(props.invoiceUrl)}
                                size="small">
                            {t("Download_Invoice")}
                        </Button>
                    </Typography>
                    <br/><br/>
                    <Typography align="center">
                        <Button id="paymentOk" variant="contained" color="primary" style={{backgroundColor: 'green', color: 'white'}}
                                onClick={onConfirm} className={classes.okButton}>
                            OK
                        </Button>
                    </Typography>
                </CardContent>
            </Card>
        </Container>
    )
}

export default PaymentSuccess;
