const requiredValidation = (fieldName, fieldValue) => {
    if (fieldValue instanceof Date) {
        if (fieldValue === '' || fieldValue === null) {
            return `${fieldName} is required`;
        }
    } else if (fieldValue.trim() === '') {
        return `${fieldName} is required`;
    }
    return null;
};

export { requiredValidation };