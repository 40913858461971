import { Tooltip } from "@material-ui/core";
import { HelpRounded } from "@material-ui/icons";
import { FormControl, MenuItem, Select, Slider, Stack, TextField, Typography, createFilterOptions } from "@mui/material";
import { Fragment, memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { OptionType, QuestionType } from "../../QuestionStaticVar";
import { EvotingTextField } from "../../poll-question-components/PollQuestionComponents";
import { TypeOfPoll } from "../../../../common/GenericCodes";

const filter = createFilterOptions();

const QuestionPart = (props) => {
    const { question, setQuestion, title, error, setError, hasPermission, hasSystemAdmineOrSupportRole, pollClass } = props;
    const [touched, setTouched] = useState({
        question: true
    });
    const { t } = useTranslation();

    const marks = [
        {
            value: 0,
            label: '0%',
        },
        {
            value: 100,
            label: '100%',
        },
    ];

    // const handleBlur = () => {
    //     if (value < 0) {
    //         setQuestion(prevState => ({
    //             ...prevState,
    //             threshold: newValue
    //         }))
    //     } else if (value > 100) {
    //         setQuestion(prevState => ({
    //             ...prevState,
    //             threshold: newValue
    //         }))
    //     }
    // };

    const handleTypeChange = (event) => {
        setQuestion(prevState => ({
            ...prevState,
            questionType: event.target.value,
            options: prevState.options.length > 0 ? prevState.options :
            [
                {
                    option: "",
                    optionType: OptionType.Text,
                    files: null,
                    nextQuestionId: null
                },
                {
                    option: "",
                    optionType: OptionType.Text,
                    files: null,
                    nextQuestionId: null
                },
            ]
        }))
    }

    function handleKeyPress(event) {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);
        if (/\D/.test(keyValue) && keyValue === '-' || keyValue === '+'){
            event.preventDefault();
        }
    }

    const handleInputChange = (event) => {
        let regex = /^-\d+$/
        let isNegative = regex.test(event.target.value);
        if (Number(event.target.value) <= 100 && !isNegative) {
            setQuestion(prevState => ({
                ...prevState,
                threshold: event.target.value === '' ? '' : Number(event.target.value)
            }))
        }
    }

    const handleSlider = (event, newValue) => {
        setQuestion(prevState => ({
            ...prevState,
            threshold: newValue
        }))
    }

    const handleOptionFocus = () => {
        setTouched({ question: true })
    }

    const onInputChange = (event) => {
        let value = event.target.value.trimStart();
        //Restrict input of question (1000 char) and description(10000 char)
        if (hasSystemAdmineOrSupportRole || (event.target.id === "question" && value.length <= 5000) || (event.target.id === "questionDescription" && value.trim().length <= 10000)) {
            setQuestion(prevState => ({
                ...prevState,
                [event.target.id]: value
            }))
        }
        if (event.target.id === "question" && value !== "") {
            setError((prevState) => ({
                ...prevState,
                [event.target.id]: null
            }))
        }
    }

    return (
        <Fragment>
            <Typography color="primary" sx={{ fontWeight: 'bold' }}>{title}</Typography>
            <Stack spacing={2} direction='row' mt={1}>
                <Stack spacing={6} direction='column' width={'30%'}>
                    <EvotingTextField
                        label={title + ' ' + t('Type')}
                        component={<FormControl fullWidth>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                size="small"
                                value={question.questionType}
                                onChange={handleTypeChange}
                                disabled={(hasSystemAdmineOrSupportRole ? !hasSystemAdmineOrSupportRole : !hasPermission)}
                            >
                                <MenuItem value={QuestionType.SingleChoice}>{t('Single_Select')}</MenuItem>
                                <MenuItem value={QuestionType.MultiChoice}>{t('Multi_Select')}</MenuItem>
                                {/* {TypeOfPoll.POLL !== pollType && <MenuItem value={QuestionType.Ranking}>Ranking</MenuItem>} */}
                                {/* {TypeOfPoll.POLL !== pollType && <MenuItem value={QuestionType.Rating}>Rating</MenuItem>} */}
                            </Select>
                        </FormControl>}
                    />
                    {pollClass === TypeOfPoll.POLL && <EvotingTextField
                        label={<Fragment>{`${t('Required Voting Share')} - ${question.threshold === null ? '' : question.threshold }%`}
                            <Tooltip title={t('Requried_Voting_Share_Helper_Text')} placement="right" arrow>
                                <HelpRounded style={{color: '#a88206', fontSize: '1.34rem', paddingLeft: '5px'}}/></Tooltip></Fragment>}
                        component={
                            <Stack direction="row" spacing={3} sx={{mt: '30px !important', ml: '14px !important'}}>
                            <Slider
                                id="threshold"
                                step={1}
                                color='secondary'
                                valueLabelDisplay="on"
                                onChange={handleSlider}
                                value={question.threshold}
                                marks={marks}
                                disabled={(hasSystemAdmineOrSupportRole ? !hasSystemAdmineOrSupportRole : !hasPermission)}
                                size='medium'
                                aria-labelledby="input-slider"
                                style={{ marginBottom: '10px', width: '100%' }}
                            />
                            <TextField
                                value={question.threshold}
                                size="small"
                                onChange={handleInputChange}
                                onKeyPress={handleKeyPress}
                                // onBlur={handleBlur}
                                placeholder={hasPermission ? "Enter" : ""}
                                disabled={(hasSystemAdmineOrSupportRole ? !hasSystemAdmineOrSupportRole : !hasPermission)}
                                inputProps={{
                                    // style: {width: '2%'},
                                    step: "any",
                                    min: 0,
                                    max: 100,
                                    type: 'number',
                                    'aria-labelledby': 'input-slider',
                                }}
                                />
                            </Stack>}
                    />}
                </Stack>
                <Stack spacing={1} direction='column' width={'70%'}>
                    <EvotingTextField
                        label={title}
                        component={<TextField id='question' value={question.question}
                            required
                            key={error.question !== undefined && error.question !== null}
                            onFocus={handleOptionFocus}
                            autoFocus={error.question !== undefined && error.question !== null || touched.question === true}
                            disabled={(hasSystemAdmineOrSupportRole ? !hasSystemAdmineOrSupportRole : !hasPermission)}
                            error={error.question !== undefined && error.question !== null}
                            helperText={(error.question !== undefined && error.question !== null)&& error.question}
                            multiline minRows={2} onChange={onInputChange} />}
                    />
                    <EvotingTextField
                        label={t('Additional Information')}
                        component={<TextField id='questionDescription' value={question.questionDescription}
                            multiline minRows={2} onChange={onInputChange} disabled={(hasSystemAdmineOrSupportRole ? !hasSystemAdmineOrSupportRole : !hasPermission)}/>}
                    />
                </Stack>
            </Stack>
        </Fragment>
    )
}

export default memo(QuestionPart);