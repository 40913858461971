import React                    from 'react';
import Rules                    from '../Rules';
import {
    Grid,
    Chip
}                               from '@material-ui/core';
import {
    Divider
}                               from '@mui/material';
import FileUploadDownload       from '../../file-upload-download/FileUploadDownload';
import { useTranslation }       from 'react-i18next';
import { TypeOfPollCategory } from '../../../common/GenericCodes';

const PollInstructions = ({otpLogin, userId, pollDetails}) => {
    const { t } = useTranslation();
    return (
        <Grid>
            <Grid container justify='center' alignItems='center'>
                <Grid item xs={12}>
                    <Rules length={pollDetails.numberOfQuestions} voterName={'Sunil'} showOrganizers={pollDetails.displayOrganizerName}
                        allow_vote_change={pollDetails.allowVoteChange} organizer_list={pollDetails.organizers}
                        verify_with_otp={pollDetails.verifyWithOtp} pollCategory={pollDetails.pollCategory}/>
                </Grid>
            </Grid>
            <br/>
            {/* <Divider textAlign="left">
                <Chip label={t('Files')} />
            </Divider> */}
            <br/>
            <Grid container justify='center' alignItems='center'>
                <Grid item xs={12}>
                    {<FileUploadDownload
                        poll_id={pollDetails.id}
                        poll_question_id={null}
                        pollCategory={pollDetails.pollCategory}
                        questionTitle={pollDetails.pollCategory === TypeOfPollCategory.IBC ? t('Resolution') : t('Question')}
                        isUpload={false}
                        isDownload={true}
                        deletePermission={false}
                        otpLogin={otpLogin}
                        userId={userId}
                    />}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default PollInstructions