import React, { useState } from 'react'
import {
    Dialog, DialogTitle, DialogContent, Typography, TextField,
    DialogActions, Button, InputAdornment, Tooltip
} from '@material-ui/core'
import InputMask from 'react-input-mask';
import { useTranslation } from 'react-i18next';
import EligoBackdrop from '../../common/EligoBackdrop';
import EligoConfirmDialog from '../../common/EligoConfirmDialog';
import LoopIcon from '@material-ui/icons/Loop';
import EligoSnackBar from '../../common/EligoSnackBar';
import axios from 'axios';

const ConfirmVoteOpt = (props) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(props.show);
    const [loading, setLoading] = useState(false);
    const [otpVal, setOtpVal] = useState('');
    const [errorMsg, setErrorMsg] = useState('');

    const [seekConfirmation, setSeekConfitmation] = useState({
        show: false,
        title: '',
        message: '',
        onAgree: '',
        onDisAgree: ''
    })

    const setOtpValue = (e) => {
        setOtpVal(e.target.value)
    }

    const handleDisAgree = () => {
        props.setShowStepper(false)
        setOpen(false);
        props.otpStepper(false)
        props.onDisAgree();
    };

    const handleAgree = () => {
        confirmVoteHandler(otpVal)
    };

    const confirmVoteHandler = (otp) => {
        let code = otp.replaceAll(' ', '')
        setErrorMsg('');
        if (props.otpLogin) {
            setLoading(true);
            axios({
                url: `${process.env.REACT_APP_AWS_GATEWAY_POLL_API_URL}/verify_cast/${props.pollDetails.poll_id}/${props.authorisedPersonId === "None" ? props.pollDetails.voter_edge_id : props.pollDetails.auth_rep_edge_id}/${code}`,
                method: 'GET'
            }).then((response) => {
                setLoading(false);
                setOpen(false);
                otpSuccessHandler();
            }).catch((error) => {
                setLoading(false);
                if (error.response) {
                    if (error.response.data.Message) {
                        setErrorMsg(error.response.data.Message);
                    }
                }
            })
        } else {
            axios({
                url: `${process.env.REACT_APP_AWS_GATEWAY_POLL_API_URL}/verify_cast/${props.pollDetails.poll_id}/${props.onlyVoter ? props.voter_edge_id : props.authRepId ? props.authRepId : props.AuthrepIdForSingleVoter}/${code}`,
                method: 'GET'
            }).then((response) => {
                setLoading(false);
                setOpen(false);
                otpSuccessHandler();
            }).catch((error) => {
                setLoading(false);
                if (error.response) {
                    if (error.response.data.Message) {
                        setErrorMsg(error.response.data.Message);
                    }
                }
            })
        }
    }

    const getOpt = () => {
        if (props.otpLogin) {
            setLoading(true);
            axios({
                url: `${process.env.REACT_APP_AWS_GATEWAY_POLL_API_URL}/request_to_verify_cast/${props.authRepIdSignIn}`,
                method: 'GET'
            }).then((response) => {
                setLoading(false);
                //TODO
            }).catch((error) => {
                setLoading(false);
                //TODO
            })
        } else {
            setLoading(true);
            axios({
                url: `${process.env.REACT_APP_AWS_GATEWAY_POLL_API_URL}/request_to_verify_cast/${props.onlyVoter ? props.voter_edge_id : props.authRepId ? props.authRepId : props.AuthrepIdForSingleVoter}`,
                method: 'GET'
            }).then((response) => {
                setLoading(false);
                //TODO
            }).catch((error) => {
                setLoading(false);
                //TODO
            })
        }
    }

    const otpSuccessHandler = () => {
        setSeekConfitmation({
            show: true,
            title: t('Success'),
            message: t('Your_Votes_Verified_Successfully'),
            onAgree: props.onAgree()
        })
    }

    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='loading-container'
            >
                <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
                <DialogContent>
                    <Typography variant="body2" component="p" className="text-center">
                        <strong>{t('Please_Enter_The_OTP_To_Confirm')}</strong><br />
                        {t('A_OTP_Has_Been_Sent_To_Your_Registered_Mobile_Number_And_Email_ID')}
                        {/* {(Array.from(props.location.state.phoneNumber)).map((e, index) => 
                            (index < ((props.location.state.phoneNumber).length) - 4 ? "*" : e))
                        } */}
                    </Typography>
                    <br />
                    <Typography className="text-center">
                        <InputMask
                            mask="9 9 9 9 9 9"
                            value={otpVal}
                            onChange={setOtpValue}>
                            {() => <TextField
                                id='otp'
                                margin="normal"
                                variant="outlined"
                                placeholder={t("OTP")}
                                inputProps={{ style: { textAlign: 'center', fontSize: '18px', fontWeight: 'bold' } }}
                                InputProps={{
                                    style: { textAlign: 'center', fontSize: '18px', fontWeight: 'bold' },
                                    endAdornment: (
                                        <Tooltip title="Resend">
                                            <InputAdornment position="end" className="pointer">
                                                <LoopIcon
                                                    aria-label="resend-otp"
                                                    style={{ color: 'blue' }}
                                                    onClick={getOpt}
                                                >
                                                </LoopIcon>
                                            </InputAdornment>
                                        </Tooltip>
                                    )
                                }}
                            />}
                        </InputMask>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDisAgree} color="primary" autoFocus>
                        {t("Cancel")}
                    </Button>
                    <Button onClick={handleAgree} color="primary">
                        {t("Confirm")}
                    </Button>
                </DialogActions>
                {(loading) && <EligoBackdrop show={true} />}
            </Dialog>
            {errorMsg && <EligoSnackBar show={true} message={errorMsg} severity="error" />}
            {seekConfirmation.show && <EligoConfirmDialog show={seekConfirmation.show}
                title={seekConfirmation.title} message={seekConfirmation.message}
                onAgree={seekConfirmation.onAgree} isCancel={false} agreeBtnLabel={t('Ok')} />}
        </div>
    )
}

export default ConfirmVoteOpt
