import React, { Fragment, useState } from "react";
import ExitToAppSharpIcon from '@material-ui/icons/ExitToAppSharp';
import EligoBackdrop from "../../common/EligoBackdrop";
import EligoConfirmDialog from "../../common/EligoConfirmDialog";
import EligoSnackBar from "../../common/EligoSnackBar";
import Btns from "../../common/Btns";
import { useTranslation } from "react-i18next";
import { AxiosEvotingInterceptor } from "../../config/axios.interceptor";
import { Status } from "../../common/GenericCodes";
import { MdUpcoming } from 'react-icons/md'
import { useHistory } from 'react-router-dom';
import { Stack } from "@mui/system";
import './PollList.css'
import { Tooltip, Button, Chip } from "@mui/material";
import SendIcon from '@material-ui/icons/Send';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import { Badge } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    flashingButton: {
      animation: '$flash 1s infinite, $scale 1s infinite',
    },
    '@keyframes flash': {
        '0%, 49%, 100%': {
            opacity: 1,
        },
        '50%, 99%': {
            opacity: 0.8,
        },
    },
    '@keyframes scale': {
        '0%, 100%': {
            transform: 'scale(1)',
        },
        '50%': {
            transform: 'scale(1.03)',
        },
    },
}));

const PublishPoll = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [loading, setLoading] = useState(false)
    const [pollError, setPollError] = useState('');
    const [seekConfirmation, setSeekConfirmation] = useState({
        show: false,
        title: t("Confirmation"),
        message: '',
        onAgree: '',
        onDisAgree: ''
    })
    const history = useHistory();

    const navigateToPolls = () => {
        history.push("/polls")
    }

    const PublishPoll = () => {
        setPollError('')
        props.setMessage({ showMsg: false, message: '', severity: '' })
        setLoading(true)
        AxiosEvotingInterceptor.patch(`poll/${props.pollDetails.id}/${Status.ready}`, {}).then(response => {
            if (response) {
                if (props.insidePoll) {
                    // if(props.systemAdminOrSupport){
                    // }
                    props.setMessage({ showMsg: true, message: t("Successfully_Sent_Request_To_SYSTEM_ADMIN/SUPPORT"), severity: 'success' })
                    setLoading(false)
                    props.setRefresh(true)
                    resetConfirmation();
                    setTimeout(() => {
                        navigateToPolls();
                    }, 400);
                } else {
                    props.setMessage({ show: false, message: '', severity: '' })
                    props.setMessage({ show: true, message: props.systemAdminOrSupport ? t("Poll_Published_Successfully") : t("Successfully_Sent_Request_To_SYSTEM_ADMIN/SUPPORT"), severity: 'success' })
                    props.getPollList()
                    setLoading(false)
                    resetConfirmation()
                }
            }
        }).catch((error) => {
            setLoading(false)
            if (props.insidePoll) {
            props.setMessage({ showMsg: true, message: error.message, severity: 'error' })
            setTimeout(() =>{
                props.setMessage({ showMsg: false, message: '', severity: '' })
            }, 7000)
                
            } else {
            setPollError(error.message)                
            }
            resetConfirmation()
        })
    }

    const confirmation = () => {
        setSeekConfirmation(prevState => ({
            ...prevState,
            show: true,
            message: <Fragment> {t(props.systemAdminOrSupport ? "Are_You_Sure_That_You_Want_To" : "Are_You_Sure_That_You_Want_To_Send_The_Publish_Request_To_System_Admin/Support?")} 
            <b>{t(props.systemAdminOrSupport ? 'Publish' : '')} </b> {t(props.systemAdminOrSupport ? 'poll' : '')}
            <b>{props.pollDetails.title}</b>{t('?')} 
            {props.insidePoll && props.systemAdminOrSupport && <>{t("And_It_Is_Redirect_To_List_Polls")}</>}</Fragment>,
            onAgree: PublishPoll,
            onDisAgree: resetConfirmation,
        }))
    }

    const resetConfirmation = () => {
        setSeekConfirmation(prevState => ({
            ...prevState,
            show: false,
            title: t("Confirmation"),
            message: '',
            onDisAgree: '',
            onAgree: ''
        }))
    }

// Poll got canceled by organizer and before got cancel organizer sent request or not?
    // let systemAdminInCanceledPoll = ( props.systemAdminOrSupport && props.pollDetails.status === Status.canceled )  ? 
    //     <Badge color="secondary" badgeContent="1">
    //         <MdUpcoming /> 
    //     </Badge> : <MdUpcoming /> 

    return (
        <Fragment>
                {props.insidePoll ?
                    <Fragment>
                        {props.pollDetails.status === Status.setup && <Tooltip title={t(props.systemAdminOrSupport ? "Publish_Poll" : 'Send_Request')} placement="bottom" arrow>
                            <Stack onClick={confirmation}>
                                <Stack direction='row'>
                                    <Button color='warning' variant={props.pollDetails.status === Status.publish_request ? 'outlined' : 'contained'} 
                                        sx={{marginRight: '10px', borderRadius: '20px', fontWeight: 'bold'}}
                                        endIcon={<RecordVoiceOverIcon />} className={classes.flashingButton}>
                                        {t(props.systemAdminOrSupport ? "Publish_Poll" : "Request_To_Publish")}
                                    </Button>
                                </Stack>
                            </Stack>
                        </Tooltip>}
                        {props.pollDetails.status === Status.publish_request &&
                            <Stack onClick={confirmation}>
                                <Stack direction='row'>
                                    {props.systemAdminOrSupport && <Button color='primary' variant='contained'
                                        sx={{marginRight: '10px'}}
                                        endIcon={<Badge color="secondary" badgeContent="1">
                                            <RecordVoiceOverIcon /> 
                                        </Badge>}
                                    >
                                        {t("Publish_Poll")}
                                    </Button>}
                                    {!props.systemAdminOrSupport && 
                                        <Chip variant='outlined' label={t('Publish_Request_Sent')} sx={{marginRight: '10px'}} color='warning' size='small'/>
                                    }
                                </Stack>
                            </Stack>
                        }    
                    </Fragment>
                : 
                    <Fragment>
                        {props.pollDetails.status === Status.setup &&
                            <Btns id='publish' tooltip={t(props.systemAdminOrSupport ? "Publish_Poll" : 'Send_Request')} label="publish-poll" 
                                placement="bottom" color="primary" style={{ float: 'right' }} 
                                btn={<>{<RecordVoiceOverIcon />}</>} click={confirmation} />
                        }
                        {props.pollDetails.status === Status.publish_request &&
                            <Fragment>
                                {props.systemAdminOrSupport && 
                                    <Btns id='publish' tooltip={t("Publish_Poll")} label="publish-poll" placement="bottom" color="primary" 
                                    style={{ float: 'right' }} 
                                    btn={<>{<Badge color="secondary" badgeContent="1">
                                        <RecordVoiceOverIcon /> 
                                    </Badge>}</>} click={confirmation} />
                                }
                                {!props.systemAdminOrSupport && 
                                    <Chip label={t('Publish_Request_Sent')} sx={{marginTop: "10px", position: 'absolute'}}
                                        color='warning' size='small' variant='outlined'/>
                                }
                            </Fragment>
                            }
                    </Fragment>
                }
            {loading && <EligoBackdrop show={true} />}
            {seekConfirmation.show && <EligoConfirmDialog show={seekConfirmation.show} ConfirmFor={true} showGreen={true}
                title={seekConfirmation.title} message={seekConfirmation.message}
                onAgree={seekConfirmation.onAgree} onDisAgree={seekConfirmation.onDisAgree} />}
            {pollError !== '' && pollError !== undefined &&
                <EligoSnackBar show={true} message={pollError} severity="error" reset={() => setPollError(null)} insidePoll={props.insidePoll} />}
        </Fragment>
    )
}

export default PublishPoll