import React, { useState, useEffect } from 'react';
import { Grid, Button, Popover, Typography, TextField, Box, Tabs, Tab } from '@material-ui/core';
import AddressTab from './AddressTab';
import EligoBackDrop from '../../common/EligoBackdrop';
import { AxiosEvotingInterceptor } from '../../config/axios.interceptor';
import { useTranslation } from 'react-i18next';
import EligoSnackBar from '../../common/EligoSnackBar';


const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 0.5 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const Addresses = (props) => {
    const { isLoginUser, address, sourceId, atlestOneMandatory, isClient } = { ...props, atlestOneMandatory: props.atlestOneMandatory || false, };
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [Error, setError] = useState(false)
    const [newAddressType, setNewAddressType] = useState('');
    const [loadingAddress, setLoadingAddress] = useState(false);
    const [userAddresses, setUserAddresses] = useState([]);
    const [value, setValue] = React.useState(0);
    const [disabled, setDisabled] = useState(false)
    const [tabRestrict, setTabRestrict] = useState(false)
    const [addressError, setAddressError] = useState('');
    const opens = Boolean(anchorEl);
    const id = opens ? 'add-address-popper' : undefined;
    const listAddress = () => {
        AxiosEvotingInterceptor.get(`${isLoginUser ? `address/list/login-user?page=${1}&limit=${50}&asc=false` :
            `address/list/${sourceId}?page=${1}&limit=${50}&asc=false`
            }`).then(response => {
                if (response) {
                    setUserAddressDetails(response.address);
                    setLoadingAddress(false);
                }
            }).catch(error => {
                if (error.message) {
                    setAddressError(error.message);
                } else {
                    setAddressError(t('Try_Again'));
                }
                setLoadingAddress(false);
            })
    }
    useEffect(() => {
        setLoadingAddress(true);
        listAddress();
    }, [])

    const setUserAddressDetails = (addresses) => {
        setUserAddresses([]);
        addresses.map((address) => {
            if (new Date(address.endTime) > new Date()) {
                setUserAddresses(prevState => ([
                    ...prevState,
                    {
                        addressId: address.addressId,
                        addressLine1: address.addressLine1,
                        addressLine2: address.addressLine2,
                        country: address.country,
                        countryId: address.countryId,
                        addressType: address.addressType,
                        postalCode: address.postalCode,
                        state: address.state,
                        stateId: address.stateId,
                        cityTown: address.cityTown,
                        cityTownId: address.cityTownId,
                        postalCode: address.postalCode,
                        postalCodeId: address.postalCodeId,
                        landmark: address.landmark,
                        attention: address.attention,
                        phone: address.phone,
                        endTime: address.endTime
                    }
                ]))
            }
        })
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClosed = () => {
        setAnchorEl(null);
        setError(false)
    }

    const addNewAddress = async () => {
        if (newAddressType.replace(/ /g, '') == '') {
            setError(true)
            setNewAddressType('')
        } else {
            handleClosed()
            setUserAddresses(prevState => ([
                ...prevState,
                {
                    addressLine1: '',
                    addressLine2: '',
                    addressType: newAddressType,
                    country: '',
                    countryId: '',
                    postalCode: '',
                    postalCodeId: '',
                    state: '',
                    stateId: '',
                    cityTown: '',
                    cityTownId: '',
                    addressId: '',
                    attention: '',
                    landmark: '',
                    phone: '',
                    endTime: ''

                }
            ]))
            setDisabled(true)
            setTabRestrict(true)
            setValue(userAddresses.length)
            setNewAddressType('')
            setError(false)
        }
    }

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    }

    const setAddressTab = (event) => {
        let addressData = { ...userAddresses[value] }
        addressData.addressLine1 = event.addressLine1
        addressData.addressLine2 = event.addressLine2
        addressData.country = event.country
        addressData.countryId = event.countryId
        addressData.addressType = event.addresssType
        addressData.postalCode = event.postalCode
        addressData.postalCodeId = event.postalCodeId
        addressData.state = event.state
        addressData.stateId = event.stateId
        addressData.cityTown = event.cityTown
        addressData.cityTownId = event.cityTownId
        addressData.landmark = event.landmark
        addressData.attention = event.attention
        addressData.phone = event.phone
        addressData.endTime = event.endTime
        userAddresses[value] = addressData
        setUserAddresses(userAddresses)
    }

    const deletNewAddress = async (e) => {
        let addressListses = userAddresses
        if (addressListses.at(-1).id == '') {
            addressListses.splice(-1, 1)
            setValue(0)
            setUserAddresses(addressListses);
        }
    }

    const refreshlistAddress = (index) => {
        setLoadingAddress(true)
        listAddress()
        if (index != null) {
            setValue(index);
        }
    }
    return (
        <React.Fragment>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {/* <div>
                        <Fab style={{ float: 'right' }} size='medium' color="primary" aria-label="add" id="addressplus"
                            onClick={handleClick} ><Add /></Fab>
                    </div> */}

                {/* <Fab style={{ float: 'right' }} size='medium' color="primary" aria-label="add" id="addressplus"
                onClick={handleClick} ><Add /></Fab> */}

                    <Button id='addAddress' disabled={disabled} color="primary" variant="outlined" className="float-right" onClick={handleClick}
                        style={{ marginRight: '30px' }} size="small" >
                        Add Address
                    </Button>
                    <Popover
                        id={id}
                        open={opens}
                        anchorEl={anchorEl}
                        onClose={handleClosed}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left'
                        }}
                        className='addressTypePopper'>
                        <Typography sx={{ margin: 2 }} component="div">
                            <Grid item xs={12} >
                                <TextField id="address-type" label="Address Type" variant="outlined" placeholder='Shipping/Billing' value={newAddressType}
                                    inputProps={{ maxLength: 25 }}
                                    onChange={(event) => setNewAddressType(event.target.value)} error={newAddressType ? false : Error} helperText={Error && "Please enter Address Type"} fullWidth />
                            </Grid>
                            <Grid item xs={12} style={{ paddingTop: '5px', paddingBottom: '15px' }}>
                                <Button id='addnewAddress' color="primary" variant="contained" className="float-right" onClick={addNewAddress}>
                                    Add
                                </Button>
                            </Grid>
                        </Typography>
                    </Popover>
                </Grid>
            </Grid>
            <br />
            <Grid item xs={12} style={{ textAlign: 'center' }} className="loading-container">
                <Box sx={isClient && userAddresses.length > 4? { bgcolor: 'background.paper', display: 'grid' }: { bgcolor: 'background.paper'}} justifyContent="center">
                    <Tabs
                        id='tabs'
                        value={value}
                        onChange={handleTabChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="address tabs"

                    >
                        {!loadingAddress && userAddresses.map((e, index) => {
                            return <Tab disabled={tabRestrict} label={e.addressType} key={index} value={index} />
                        })}
                    </Tabs>
                    {!loadingAddress && userAddresses.map((e, index) => {
                        return <TabPanel key={index} value={value} index={index} >

                            <AddressTab data={e} setData={setAddressTab} closeModels={deletNewAddress} isLoginUser={isLoginUser} setValue={setValue}
                                sourceId={sourceId} refreshlistAddress={refreshlistAddress} disabled={setDisabled} edit={setTabRestrict}
                                deletePermission={(atlestOneMandatory ? (atlestOneMandatory && userAddresses.length > 1) : true)}/>
                        </TabPanel>
                    })}
                </Box>
            </Grid>
            {loadingAddress && <EligoBackDrop show={loadingAddress} />}
            {addressError !== '' && addressError !== undefined ?
                <EligoSnackBar show={true} message={addressError} severity="error" reset={() => setAddressError('')} /> : null}
        </React.Fragment>

    )
}

export default Addresses;