import { Button, Chip, Stack, Typography, alpha } from "@mui/material";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { Fragment, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OptionType } from '../../../QuestionStaticVar';
import ImagePreview from '../preview/ImagePreview';
import './RealTimeResult.css';
import { RefreshRounded } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#3182ce' : '#3182ae',
    },
}));

const useStyles = makeStyles(() => ({
    refresh: {
        background: '#e65100 !important',
        textTransform: 'capitalize !important',
        fontWeight: 'bold !important',
        '&:hover': {
            background: '#db540a !important',
            '& .refresh-icon': {
                animationName: '$spin',
                animationDuration: '2000ms',
                animationIterationCount: 'infinite',
                animationTimingFunction: 'linear'
            }
        }
    },
    refreshIcon: {
        fontSize: '1.3rem !important'
    },
    '@keyframes spin': {
        'from': {
            transform: 'rotate(0deg)'
        },
        'to': {
            transform: 'rotate(360deg)'
        },
    },
}))

// const getDarkColor = () => {
//     var color = '#';
//     for (var i = 0; i < 6; i++) {
//         color += Math.floor(Math.random() * 15);
//     }
//     return color;
// }

const makeNumArr = num => new Array(num).fill("").map((_, i) => i + 1);

const RealTimeResult = (props) => {
    const { options, question, totalVoterCount, noOfVotedNotVoted, questionTitle, questionNumber, isEditView, handleRefresh } = props;
    const { t } = useTranslation();
    const classes = useStyles();
    const [totalAvatar, setTotalAvatar] = useState(makeNumArr(noOfVotedNotVoted));
    return (
        <Fragment>
            <Stack spacing={2} mb={1}>
                {isEditView && <Typography color="primary" sx={{ fontWeight: 'bold' }}>{t('Real-Time Result')}</Typography>}
                <Typography sx={{ fontWeight: 'bold' }}>{questionTitle}{questionNumber}.&nbsp;{question}</Typography>
                <Stack justifyContent={'end'} mt={'-2px !important'} alignItems='end'>
                    <Button className={classes.refresh} color='warning' startIcon={<RefreshRounded className={`${classes.refreshIcon} refresh-icon`} />} onClick={handleRefresh}
                        variant="contained" size='small'>
                        {t('Refresh')}
                    </Button>
                </Stack>
            </Stack>
            {options.map((opt, index) => {
                let randomColor = Math.floor(Math.random() * 16777215).toString(16).substring(-6);
                randomColor = randomColor.length < 6 ? '3182ce' : randomColor;
               return (
                   <Stack direction="row" spacing={2} alignItems='center' mt={1.6} key={opt.id} className='result-card'
                       sx={opt.voteCount > 0 ? {"&:hover": { border: `2px solid #${randomColor}`}} : {}}
                   >
                       <Typography>{index + 1}.</Typography>
                       <Stack direction="row" width={'100%'} spacing={2}>
                           {(OptionType.Image === opt.optionType || OptionType.TextAndImage === opt.optionType) && <ImagePreview optionFile={opt.files} />}
                           <Stack width={'100%'} spacing={1}>
                               <Stack direction='row' justifyContent='space-between'>
                                   <Typography>
                                       {opt.option}
                                   </Typography>
                                   <Stack direction="row" spacing={1.5} alignItems='center' width={'35%'} justifyContent='end'>
                                       <Chip label={`${opt.voteCount} ${opt.voteCount > 1 ? 'votes' : 'vote '}`} sx={{
                                           background: alpha(`#${randomColor}`, 0.1),
                                           color: "rgb(53, 52, 52)",
                                           fontSize: '14px',
                                           fontWeight: 'bold'
                                       }}/>
                                       <Typography variant='h6' fontWeight='bold'>
                                           {Number(opt.totalWeightage)}%
                                       </Typography>
                                   </Stack>
                               </Stack>
                               <BorderLinearProgress variant="determinate" value={Number(opt.totalWeightage)}
                                   sx={{
                                       '& .MuiLinearProgress-bar': {
                                           backgroundColor: `#${randomColor}`
                                       }
                                   }} />
                           </Stack>
                       </Stack>
                   </Stack>
               )
            })}
            {/* <Stack alignItems="center" justifyContent='center'>
                <Stack mt={2} width="fit-content" direction="row" alignItems="center" justifyContent='center' spacing={2} className='votes-card'>
                    <Typography color="primary" fontWeight='bold'>{noOfVotedNotVoted.voted} Voted out of {totalVoterCount} voters</Typography>
                </Stack>
            </Stack> */}
        </Fragment>
    )
}

export default memo(RealTimeResult);