import React, {
    useState,
    useEffect
}                                   from 'react';
import CastVote                     from './CastVote';
import {
    Container,
    Grid
}                                   from '@material-ui/core';
import { AxiosEvotingInterceptor }  from '../../../config/axios.interceptor';
import EligoBackdrop                from '../../../common/EligoBackdrop';
import EligoSnackBar                from '../../../common/EligoSnackBar';

const VoteMainPage = (props) => {
    const [pollDetails, setPollDetails] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = React.useState({
        showMsg: false,
        message: null,
        severity: null
    })

    const getPoll = () => {
        if (props.location.state.vote_via_link) {
            setPollInformation(props.location.state.poll_details);
        } else {
            setLoading(true);
            setMessage({ showMsg: false, message: '', severity: '' });
            AxiosEvotingInterceptor.get(`poll/${props.location.state.poll_id}`).then((response) => {
                setPollInformation(response)
                setLoading(false);
            })
            .catch((error) => {
                if (error.message) {
                    setMessage({ showMsg: true, message: error.message, severity: 'error' });
                }
                setLoading(false);
            })
        }
    }

    const setPollInformation = (poll) => {
        setPollDetails({
            id: poll.id,
            pollNumber: poll.pollNumber,
            pollType: poll.pollType,
            title: poll.title,
            description: poll.description,
            status: poll.status,
            referenceNumber: poll.referenceNumber,
            showResultsToVoters: poll.showResultsToVoters,
            weightedVotes: poll.weightedVotes,
            geoTagging: poll.geoTagging,
            geoFencing: poll.geoFencing,
            questionShuffle: poll.questionShuffle,
            anonymousVoting: poll.anonymousVoting,
            websiteRedirect: poll.websiteRedirect,
            discloseVoterInResult: poll.discloseVoterInResult,
            startManually: poll.startManually,
            startTime: poll.startTime,
            endTime: poll.endTime,
            timeout: poll.timeout,
            roles: poll.roles,
            resultAnnouncementTime: poll.resultAnnouncementTime,
            numberOfQuestions: poll.numberOfQuestions,
            hasVoterRole: poll.hasVoterRole,
            voterEdgeId: poll.voterEdgeId,
            voterRoleSequence: poll.voterRoleSequence,
            voterCount: poll.voterCount,
            voted: poll.voted,
            verified: poll.verified,
            preSignedUrl: poll.preSignedUrl,
            pollQuestions: poll.pollQuestions,
            paymentStatus: poll.paymentStatus,
            paidVoter: poll.paidVoter,
            sourceIp: poll.sourceIp,
            alertTime: poll.alertTime,
            sentStartAlert: poll.sentStartAlert,
            sentEndAlert: poll.sentEndAlert,
            sentResultToVoters: poll.sentResultToVoters,
            voteWithSignUp: poll.voteWithSignUp,
            announceResultAutomatically: poll.announceResultAutomatically,
            allowVoteChange: poll.allowVoteChange,
            scrutinizerHasToOpen: poll.scrutinizerHasToOpen,
            displayOrganizerName: poll.displayOrganizerName,
            verifyWithOtp: poll.verifyWithOtp,
            createdOn: poll.createdOn,
            alerted: poll.alerted,
            conductFor: poll.conductFor,
            voterName: poll.voterName,
            organizers: poll.organizers.map(organizer => {
                return {
                    personId: organizer.personId,
                    name: organizer.name,
                    restOfName: organizer.restOfName,
                    loginId: organizer.loginId,
                    email: organizer.email.find(e => e.preferred == true).email,
                    phoneNumber: organizer.phoneNumber.find(p => p.preferred == true).phoneNumber,
                }
            }),
            dateTimeInIndianZone: poll.dateTimeInIndianZone,
            pollCategory: poll.pollCategory,
            secreteBallot: poll.secreteBallot,
            zoneId: poll.zoneId
        });
    }

    useEffect(() => {
        getPoll();
    }, [])

    return (
        <Container maxWidth='lg' style={{paddingBottom: '60px'}}>
            <Grid container>
                <Grid item xs={12}>
                    {pollDetails.id && <CastVote pollDetails={pollDetails} poll_id={pollDetails.id}
                        voteWithLink={props.location.state.vote_via_link}
                        voter_id={props.location.state.voter_id} auth_rep_id={props.location.state.auth_rep_id}
                        setLoading={setLoading}/>}
                </Grid>
            </Grid>
            {message.showMsg && <EligoSnackBar show={message.showMsg} message={message.message}
                    severity={message.severity} />}
            {loading && <EligoBackdrop show={true} position='fixed'/>}
        </Container>
    )
}

export default VoteMainPage;