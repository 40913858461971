import { Dialog, DialogContent, DialogTitle, IconButton, Tooltip, Typography } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { Stack } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaPhoneAlt } from 'react-icons/fa'
import { IoCopyOutline } from 'react-icons/io5'
import { MdEmail } from 'react-icons/md'
import { MiniCard, MiniChipGreen } from './styles/PricingStyle'

export const ContactInfo = (props) => {
    const { dialogOpen, setDialogOpen } = props
    const { t } = useTranslation();

    return (
        <div>
            {dialogOpen && <Dialog open={dialogOpen}>
                <DialogTitle style={{ padding: '5px 15px 5px 20px' }}>
                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                        <Typography variant="h6" component="div" style={{ flexGrow: 1, fontWeight: '600' }}>{t('Contact Info')}</Typography>
                        <Tooltip title="Close">
                            <IconButton
                                color="secondary"
                                onClick={() => setDialogOpen(false)}>
                                <Close />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                </DialogTitle>
                <DialogContent style={{ paddingBottom: '15px' }}>
                    <MiniCard>
                        {/* <Typography>In case of IPE / For More than 100 Voters / Special Pricing Please Contact</Typography> */}
                        <Stack direction='row' spacing={1} alignItems='center' justifyContent='center'>
                            <MiniChipGreen label={'kamalesh.rao@enkindletech.com'} icon={<MdEmail />} />
                            <Tooltip title='Copy to clipboard' arrow>
                                <IconButton size='small' onClick={() => { navigator.clipboard.writeText('kamalesh.rao@enkindletech.com') }}>
                                    <IoCopyOutline />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                        <Stack direction='row' spacing={1} alignItems='center' justifyContent='center'>
                            <MiniChipGreen label={'+918904179220'} icon={<FaPhoneAlt />} />
                            <Tooltip title='Copy to clipboard' arrow>
                                <IconButton size='small' onClick={() => { navigator.clipboard.writeText('+918904179220') }}>
                                    <IoCopyOutline />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    </MiniCard>
                </DialogContent>
            </Dialog>}
        </div>
    )
}

export default ContactInfo
