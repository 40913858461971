import { Stack } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { QuestionMenu } from "../QuestionStaticVar";
import PollQuestionDetailTab from "./PollQuestionDetailTab";
import PollQuestionTabpanel from "./PollQuestionTabpanel";
import "./PollQuestionDetail.css";
import { Status } from "../../../common/GenericCodes";

const PollQuestionDetail = forwardRef((props, ref) => {
    const { onClose, pollId, editQuestion, isEdit, dependentQuestions, pollType, title,hasSystemAdmineOrSupportRole,
        questionNumber, totalVoterCount, noOfVotedNotVoted, pollStatus, startTime, endTime, 
        hasPermission, realTimeResult, filePermission, listTitle, pollClass } = props;
    const [selectedMenu, setSelectedMenu] = useState(QuestionMenu.matter);

    const questionDetailRef = useRef(null);
    const questionRef = useRef(null);
    const optionRef = useRef(null);
    const settingsRef = useRef(null);
    const filesRef = useRef(null);
    const previewRef = useRef(null);
    const resultRef = useRef(null);

    // checking viewPort
    let questionVP = useIsInViewport(questionRef);
    let optionVP = useIsInViewport(optionRef);
    let settingsVP = useIsInViewport(settingsRef);
    let filesVP = useIsInViewport(filesRef);
    let previewVP = useIsInViewport(previewRef);
    let resultVP = useIsInViewport(resultRef);

    function useIsInViewport(ref) {
        const [intersectionRatio, setIntersectionRatio] = useState();

        const observer = useMemo(
            () =>
                new IntersectionObserver(([entry]) =>
                    setIntersectionRatio(entry.intersectionRatio.toFixed(3)),
                ),
            [],
        );

        useEffect(() => {
            if (ref !== null && ref.current !== null) {
                observer.observe(ref.current);

                return () => {
                    observer.disconnect();
                };
            }
        }, [ref, observer]);

        return intersectionRatio;
    }

    const handleMenuChange = (value) => {
        switch (value) {
            case QuestionMenu.matter: questionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' })
                break;
            case QuestionMenu.option: optionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' })
                break;
            case QuestionMenu.settings: settingsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' })
                break;
            case QuestionMenu.files: filesRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' })
                break;
            case QuestionMenu.preview: previewRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' })
                break;
            case QuestionMenu.result: resultRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' })
                break;
            default: questionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' })
                break;
        }
    }

    useEffect(() => {
        if (questionVP != 0) {
            setSelectedMenu(QuestionMenu.matter)
        } else if (optionVP != 0) {
            setSelectedMenu(QuestionMenu.option)
        } else if (settingsVP != 0) {
            setSelectedMenu(QuestionMenu.settings)
        } else if (filesVP != 0) {
            setSelectedMenu(QuestionMenu.files)
        } else if (previewVP != 0) {
            setSelectedMenu(QuestionMenu.preview)
        } else if (resultVP != 0) {
            setSelectedMenu(QuestionMenu.result)
    }
    }, [questionVP, optionVP, settingsVP, filesVP, previewVP, resultVP])

    const handleCreateUpdate = () => {
        questionDetailRef.current.handleSave();
    }

    useImperativeHandle(
        ref,
        () => ({
            handleCreateUpdate() {
                handleCreateUpdate()
            }
        }),
    )

    return (
        <Stack direction='row' spacing={1.5}>
            <PollQuestionDetailTab setSelectedMenu={setSelectedMenu} selectedMenu={selectedMenu} handleMenuChange={handleMenuChange} title={title}
                showMatterFiles={isEdit} questionTitle={title} hasSystemAdmineOrSupportRole={hasSystemAdmineOrSupportRole}
                showResult={Status.open === pollStatus
                    // Status.ready === pollStatus && (new Date(startTime) <= new Date && new Date(endTime) >= new Date)
                && realTimeResult}/>
            <PollQuestionTabpanel selectedMenu={selectedMenu} ref={questionDetailRef} onClose={onClose} pollId={pollId} isEdit={isEdit}
                questionRef={questionRef} optionRef={optionRef} settingsRef={settingsRef} filesRef={filesRef} hasSystemAdmineOrSupportRole={hasSystemAdmineOrSupportRole}
                previewRef={previewRef} resultRef={resultRef} editQuestion={editQuestion} questionNumber={questionNumber}
                dependentQuestions={dependentQuestions} pollType={pollType} title={title} totalVoterCount={totalVoterCount }
                noOfVotedNotVoted={noOfVotedNotVoted} showResult={Status.open === pollStatus && realTimeResult}
                hasPermission={hasPermission} questionTitle={title} filePermission={filePermission} listTitle={listTitle}
                pollClass={pollClass}/>
        </Stack>
    )
})

export default PollQuestionDetail;