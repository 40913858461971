import { Button, Divider, IconButton, makeStyles, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,Tooltip, Typography, withStyles } from "@material-ui/core";
import { Stack } from "@mui/system";
import React, { useEffect, useState, useContext, Fragment } from "react";
import { useTranslation } from "react-i18next";
import EligoPagination from "../../../common/pagination/EligoPagination";
import { AxiosEvotingInterceptor } from "../../../config/axios.interceptor";
import { ColoredHeader } from "../../file-upload-download/styles/FileStyle";
import { format } from 'date-fns';
import { Fab, ToggleButton, ToggleButtonGroup } from "@mui/material";
import EligoBackdrop from "../../../common/EligoBackdrop";
import SearchForSmsAndEmail from "./SearchForSmsAndEmail";
import StatusTable from "./StatusTable";
import VisibilityIcon from '@material-ui/icons/Visibility';
import CloseIcon from '@material-ui/icons/Close';
import EligoSnackBar from "../../../common/EligoSnackBar";
import { RefreshRounded } from "@material-ui/icons";
// import SendIcon from '@mui/icons-material/Send';
import SendIcon from '@material-ui/icons/Send';
import { UserContext } from "../../../common/context/UserContext";
import EligoConfirmDialog from "../../../common/EligoConfirmDialog";

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 450,
    },
    paper: {
        padding: '10px'
    },
    divider: {
        margin: theme.spacing(1, 0.5),
    },
    emailList: {
        margin: 'auto 0rem',
        fontWeight: 'bold',
        fontSize: '19px',
        background: '#e7f5fe',
        padding: '5px 10px 5px 10px',
        borderRadius: '30px'
    },
    filterButton: {
        background: '#d3ffd8',
        borderRadius: '30px',
        padding: '5px 10px 5px 10px',
        border: '1px solid green'
    },
    filterText: {
        marginRight: '10px',
        fontWeight: 'bold',
        fontSize: '18px',
        color: 'green'
    }
}));

const AuditSmsEmail = (props) => {
    const [page, setPage] = useState(1)
    const { t } = useTranslation();
    const [itemsPerPage, setitemsPerPage] = useState(10)
    const classes = useStyles();
    const [responseCount, setResponseCount] = useState(0)
    const [EmailOrSmsList, setEmailOrSmsList] = useState([])
    const [value, setValue] = useState('email');
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [filterApplied, setFilterApplied] = useState(false)
    const [storedEmail, setStoredEmail] = useState([])
    const [storedCode, setStoredCode] = useState([])
    const [storedStartDate, setStoredStartDate] = useState('')
    const [storedEndDate, setStoredEndDate] = useState(new Date())
    const [storedInformationText, setStoredInformationText] = useState(null)
    const [storedStatusCodeValue, setStatusCodeValue] = useState([])
    const [showStatusHistory, setShowStatusHistory] = useState({ show: false })
    const [message, setMessage] = React.useState({
        showMsg: false,
        message: null,
        severity: null
    })
    const [searchApplied, setSearchApplied] = useState(false)
    const [searchClick, setSearchClick] = useState(true)
    const [prevPage, setPrevPage] = useState(1)
    const context = useContext(UserContext);
    const [seekConfirmation, setSeekConfirmation] = useState({
        show: false,
        title: t("Confirmation"),
        message: '',
        onAgree: '',
        onDisAgree: ''
    })

    const ListSmsOrEmail = () => {
        setLoading(true)
        if (value === 'email') {
            AxiosEvotingInterceptor.get(`audit/email-list/${props.pollDetail.id}?page=${page}&size=${itemsPerPage}&asc=false`).then(response => {
                setResponseCount(response.count)
                setEmailOrSmsList(response.emails)
                setLoading(false)
            }).catch(error => {
                if (error.message) {
                    setMessage({ showMsg: true, message: error.message, severity: 'error' });
                }
            })
        } else {
            AxiosEvotingInterceptor.get(`audit/sms-list/${props.pollDetail.id}?page=${page}&size=${itemsPerPage}&asc=false`).then(response => {
                setResponseCount(response.count)
                setEmailOrSmsList(response.messages)
                setLoading(false)
            }).catch(error => {
                if (error.message) {
                    setMessage({ showMsg: true, message: error.message, severity: 'error' });
                }
            })
        }
    }

    const systemAdminOrSupport = (context.userSession.session.roles.includes("ROLE_ADMIN") || context.userSession.session.roles.includes("ROLE_SUPPORT"))

    const columns = [
        { id: 'sender', label: t('Sender') },
        { id: 'receiver', label: t('Receiver') },
        { id: 'subject', label: t('Subject') },
        { id: 'sent_On', label: t('Sent_On') },
        { id: 'status', label: t('Status') },
        { id: 'action', label:systemAdminOrSupport && t('Action') }
    ];

    // if (context.userSession.session.roles.includes("ROLE_ADMIN") || context.userSession.session.roles.includes("ROLE_SUPPORT")) {
    //     columns.push({ id: 'Action', label: t('Action') });
    // }

    const handleClick = (event, newValue) => {
        if (newValue === null) {
            setValue(value)
        } else {
            setEmailOrSmsList([])
            setSearchClick(true)
            // setSearchApplied(false)
            setFilterApplied(false)
            setPage(1)
            setValue(newValue)
        }
    };

    const handleChangeRowsPerPage = (event) => {
        setSearchClick(true)
        setitemsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };

    const openSearchDialog = () => {
        setPrevPage(page)
        setSearchClick(false)
        setPage(1)
        setOpen(true)
    }

    const handleChangePage = (event, newPage) => {
        setSearchClick(true)
        setPage(newPage);
    }

    const searchEmail = (statusCodes, selectedEmail, informationText, startDate, endDate, searchPage,statusCodeValue) => {
        setStoredCode(statusCodes)
        setStoredEmail(selectedEmail)
        setStoredInformationText(informationText)
        setStoredEndDate(endDate)
        setStoredStartDate(startDate)
        setStatusCodeValue(statusCodeValue)
        setLoading(true)
        const payload = {
            receiver: selectedEmail.length > 0 ? {
                1: selectedEmail
            } : {},
            statusCds: statusCodes,
            information: informationText,
            startRange: startDate,
            endRange: endDate,
            email: value === 'email' ? true : false,
            page: searchPage,
            size: itemsPerPage,
            asc: true
        }

        AxiosEvotingInterceptor.post(value === 'email' ? `audit/email-filter/${props.pollDetail.id}` : `audit/sms-filter/${props.pollDetail.id}`, payload).
            then((response) => {
                if (value === 'email') {
                    setEmailOrSmsList(response.emails)
                    setLoading(false)
                } else {
                    setEmailOrSmsList(response.messages)
                    setLoading(false)
                }
                setResponseCount(response.count)
                setFilterApplied(true)
                // setSearchApplied(true)
                setOpen(false)
            }).catch((error) => {
                if (error.message) {
                    setMessage({ showMsg: true, message: error.message, severity: 'error' });
                }
            })
    }

    useEffect(() => {
        if (!filterApplied && searchClick) {
            ListSmsOrEmail()
        }
        if (filterApplied && searchClick) {
            searchEmail(storedCode, storedEmail, storedInformationText, storedStartDate, storedEndDate, page,storedStatusCodeValue)
            // }
        }
    }, [page, itemsPerPage, value, filterApplied])

    const handleRefresh = () => {
        if (page === 1) {
            ListSmsOrEmail();
        } else {
            setTimeout(() => {
                setPage(1);
            }, 10)
        }
    }

    const closeFilterApplied = () => {
        setFilterApplied(false)
        setSearchApplied(false)
        setSearchClick(true)
        setPage(1)
    }

    const confirmation = (data) => {
        setSeekConfirmation(prevState => ({
            ...prevState,
            show: true,
            message:<Fragment>{t('Do_You_Want_Resend_Message_For')} <b>{data.receiver.length > 1 ? t("All_Organizers") : data.receiver[0]}</b>{t("?")}</Fragment> ,
            onAgree: () => resendMessageForUser(data),
            onDisAgree: resetConfirmation,
        }))
    }

    const resetConfirmation = () => {
        setSeekConfirmation(prevState => ({
            ...prevState,
            show: false,
            title: t("Confirmation"),
            message: '',
            onDisAgree: '',
            onAgree: ''
        }))
    }

      const resendMessageForUser = (data) => {
        setLoading(true)
        setMessage({ showMsg: false, message: '', severity: '' });
        const messageId = data.messageId;
        AxiosEvotingInterceptor.get(`audit/${value}/resend/${messageId}`)
          .then(response => {
            if (response === true) {
                setMessage({ showMsg: true, message: t('Message_Sent_Successfully'), severity: 'success' });
                resetConfirmation()
                setLoading(false)
              } 
          })
          .catch(error => {
            setLoading(false)
            setMessage({ showMsg: true, message: error.message, severity: 'error' });
          })
      }
   
    return (
        <div style={{ margin: '10px' }}>
            <Stack direction='row' justifyContent='space-between' mb={1.2}>
                <Typography id='emailList' className={classes.emailList}>
                    {value === 'email' ? t("Email_List") : t("Sms_List")}
                </Typography>
                <Stack direction='row' spacing={2} alignItems='center'>
                    <Tooltip title={t('Refresh')} arrow>
                        {/* <Fab onClick={handleRefresh} size='small' sx={{ background: '#fff' }}> */}
                        <RefreshRounded style={{ color: 'orangered', background:'white', borderRadius:'40px', padding:'10px',cursor:'pointer', 
                            boxShadow:'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }} onClick={handleRefresh}/>
                        {/* </Fab> */}
                    </Tooltip>
                    <Paper elevation={0} style={{ height: 'fit-content', width: 'fit-content', padding: '5px' }} >
                        <ToggleButtonGroup
                            id="togglButton"
                            size="small"
                            value={value}
                            color='primary'
                            border='none'
                            exclusive
                            onChange={handleClick}
                            aria-label="text value"
                        >
                            <ToggleButton id='emailValue' value="email" aria-label="left aligned">
                                <Typography>{t("Email")}</Typography>
                            </ToggleButton>
                            <ToggleButton id='smsValue' value="sms" aria-label="centered">
                                <Typography>{t("Sms")}</Typography>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Paper>
                    {filterApplied && <div style={{ marginTop: '5px' }}>
                        <IconButton onClick={closeFilterApplied} className={classes.filterButton}>
                            <Typography className={classes.filterText}>{t("Filter_Applied")}</Typography>
                            <CloseIcon id='closeIcon' style={{ color: 'red' }} />
                        </IconButton>
                    </div>}
                    <div style={{ marginTop: '5px' }}>
                        <Button id='btn' variant="contained" color="primary" onClick={openSearchDialog}>search</Button>
                    </div>
                </Stack>
            </Stack>
            <Paper style={{ height: 'fit-content' }}>
                <TableContainer className={classes.container} style={{maxHeight: 550}}>
                    <Table id='table' stickyHeader={true} aria-label="sticky-table-header" >
                    {/* <ColoredHeader> */}
                        <TableHead>
                            <TableRow>
                                {columns.map((column, index) => (
                                    <TableCell id='tablecell' key={index} style={{ backgroundColor: 'rgb(231, 235, 240)', fontWeight: 'bold', fontSize: '16px'}}>
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                         </TableHead>
                        {/* </ColoredHeader> */}
                        <TableBody>
                            {EmailOrSmsList.length === 0 &&
                                <TableRow>
                                    <TableCell id='colspan' colSpan={7} style={{ marginTop: '4%' }}> {t('No_Record_Found')} </TableCell>
                                </TableRow>}
                            {EmailOrSmsList.length > 0 && EmailOrSmsList.map((data) => {
                                return (
                                    <TableRow hover={true} >
                                        <TableCell id='sender' style={{width:'22%'}}>{data.sender}</TableCell>
                                        {data.receiver.length > 0 && 
                                            <TableCell style={{wordBreak: 'break-word'}}>
                                                <Stack direction="column" spacing={0.3}>
                                                {data.receiver.map((emailSms) => {
                                                    return (
                                                        <Typography variant='subtitle2'>{emailSms}</Typography>
                                                    )
                                                })}
                                                </Stack>
                                            </TableCell>
                                        }
                                        <TableCell id='info' style={{width:'18%'}}>{value === 'email' ? data.informationDetail.subject : data.information}</TableCell>
                                        <TableCell id='date' style={{width:'15%'}}>{format(new Date(data.rowCreatedOn), process.env.REACT_APP_DATE_FORMAT_TO_DISPLAY)}</TableCell>
                                        <TableCell id='status' style={{width:'15%'}}>
                                             <Tooltip title={t('View_Status_History')}>
                                                <IconButton aria-label="action" onClick={() => setShowStatusHistory({ show: true, messageId: data.messageId })}>
                                                    <VisibilityIcon align="left" className={classes.icons} id='visible' color='primary' />
                                                </IconButton>
                                            </Tooltip>
                                        {data.statusLocale}</TableCell>
                                        {systemAdminOrSupport ? 
                                        <TableCell id='action' style={{width:'5%'}}>
                                            <Tooltip title="Resend">
                                                <IconButton aria-label="action" onClick={() => confirmation(data)}>
                                                    <SendIcon align="center" className={classes.icons} color='primary' />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell> : <TableCell></TableCell>
                                     } 
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                    
                    {seekConfirmation.show && <EligoConfirmDialog show={seekConfirmation.show} ConfirmFor={true} showGreen={true}
                    title={seekConfirmation.title} message={seekConfirmation.message}
                    onAgree={seekConfirmation.onAgree} onDisAgree={seekConfirmation.onDisAgree} />}
                    {showStatusHistory.show && <StatusTable show={showStatusHistory.show} setShowStatusHistory={setShowStatusHistory} messageId={showStatusHistory.messageId} pollId={props.pollDetail.id} />}
                </TableContainer>
                {<Stack padding={{ xs: 1 }}>
                    <EligoPagination rowsPerPage={itemsPerPage} page={page} totalItems={responseCount}
                        onRowsPerPageChange={handleChangeRowsPerPage} onPageChange={handleChangePage}
                        rowsText={t('Rows')}>
                    </EligoPagination>
                </Stack>}
            </Paper>
            {open && <SearchForSmsAndEmail open={open} setOpen={setOpen} filterApplied={filterApplied} prevPage={prevPage} setPrevPage={setPrevPage} setSearchClick={setSearchClick}
                pollDetail={props.pollDetail} value={value} searchEmail={searchEmail} storedCode={storedCode} storedEmail={storedEmail} setPage={setPage}
                storedInformationText={storedInformationText} storedStartDate={storedStartDate} storedEndDate={storedEndDate} storedStatusCodeValue={storedStatusCodeValue} />}
            {loading && <EligoBackdrop show={true} />}
            {message.showMsg && <EligoSnackBar show={message.showMsg} message={message.message}
                severity={message.severity} />}
        </div>

    )
}
export default AuditSmsEmail;




