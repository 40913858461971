import React, { useState } from 'react';
import { Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogContentText, 
    DialogTitle } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PaymentList from './PaymentList';

const PaymentListModal = (props) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(props.show);

    const handleDisAgree = () => {
        props.close({
            poll: '',
            show: false
        })
        setOpen(false);
    };

    return (
        <div>
            <Dialog open={open} aria-labelledby="payment-list-model-title"
                    aria-describedby="payment-list-model-description" fullWidth maxWidth='lg'>
                <DialogTitle id="payment-list-model-title">{t('Payments')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="payment-list-model-description">
                        <PaymentList poll_id={props.openRecordPayment.poll.poll_id}/>
                    </DialogContentText>
                    </DialogContent>
                <DialogActions>
                    <Button onClick={handleDisAgree} color="primary">
                        {t('Close')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default PaymentListModal;